import React from 'react'

const IconDot = props => {
  return (
    <svg className="icon-dot">
      <circle cx="5" cy="9" r="3.5" />
    </svg>
  )
}
export default IconDot
