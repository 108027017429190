// general: 1/6 of the customer component - child of customer dash - (4th).

// TODO:
// 1. mobile support
// 2. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// core files
import CustomerActions from '../../../core/actions/users/customeractions'
// redux
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { CUSTOMER_FORM_MODAL } from '../../../core/_constants/modalTypes'
// images
import IconTrash from '../../../core/assets/images/icons/icon-trash'
import runtimeEnv from '../../../core/config/envConstants'

// css styles
import '../../styles/universalstyles.scss'
import '../styles/customers.scss'

type Props = {
  DJtoken: string, // token to send to get the data.
  customerId: number, // sent for the customer's data.
  showModal: boolean,
}

export class CustomersViewComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '', // email default blank sting.
      firstname: '', // firstname default blank sting.
      lastname: '', // lastname default blank sting.
      phone: '', // phone default blank sting.
      mobile: '', // mobile default blank sting.
      pendingDeletion: false,
      deleted: false,
    }

    this._CustomersViewShowModals = this.CustomersViewShowModals.bind(this)
    this._DeleteCustomer = this.DeleteCustomer.bind(this)
  }

  state: {
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    mobile: string,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetCustomerInfo()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    this.setState({
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      mobile: '',
      pendingDeletion: false,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetCustomerInfo
  onMountGetCustomerInfo() {
    // props
    const { customerId, DJtoken } = this.props

    if (customerId !== null && customerId !== undefined) {
      CustomerActions.getSingleContact(customerId, DJtoken)
        .then(json => {
          if (json.customer_id !== null && json.customer_id !== undefined) {
            this.setState({
              email: json.email,
              firstname: json.first_name,
              lastname: json.last_name,
              phone: json.phone_number,
              mobile: json.mobile_number,
              customer: json,
            })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*DELETE*A*CUSTOMER************************
  // CustomersViewShowModals
  DeleteCustomer() {
    const { customerId, DJtoken } = this.props
    const { pendingDeletion } = this.state
    console.log('deleting')

    if (!pendingDeletion) {
      this.setState({ pendingDeletion: true })
    } else {
      CustomerActions.putDeleteSingleContact(customerId, DJtoken)
        .then(json => {
          console.log(json)

          window.location.href = '/customers'
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*DELETE*A*CUSTOMER************************

  renderConfirmDelete() {
    return (
      <div className="customer-confirm-delete">
        <p> Are you sure you want to delete this contact? </p>
        <button
          className="confirm-delete-yes"
          onClick={() => {
            this._DeleteCustomer()
          }}
        >
          {' '}
          Yes{' '}
        </button>
        <button
          className="confirm-delete-no"
          onClick={() => {
            this.setState({ pendingDeletion: false })
          }}
        >
          {' '}
          No{' '}
        </button>
      </div>
    )
  }

  // ***********************LETS*CALL*MODAL*SETUP***********************
  // CustomersViewShowModals
  CustomersViewShowModals() {
    this.props.showModal(CUSTOMER_FORM_MODAL, {
      customer: this.state.customer,
      customerId: this.state.customer.customer_id,
      displayFullForm: true,
    })
  }
  // ***********************LETS*CALL*MODAL*SETUP***********************

  props: Props
  _CustomersViewShowModals: Function // function

  render() {
    // states
    const { email, firstname, lastname, phone, mobile, pendingDeletion } = this.state

    return (
      <div align="center" className="customersViewBox">
        <h2>Customer Information</h2>
        <hr style={{ width: '80%' }} />
        <table>
          <tbody>
            <tr>
              <td align="right">
                <b>Name</b>
              </td>
              <td>:</td>
              <td align="left">
                {firstname} {lastname}
              </td>
            </tr>

            <tr>
              <td align="right">
                <b>Email</b>
              </td>
              <td>:</td>
              <td align="left">{email}</td>
            </tr>

            <tr>
              <td align="right">
                <b>Main Phone</b>
              </td>
              <td>:</td>
              <td align="left">{phone}</td>
            </tr>

            <tr>
              <td align="right">
                <b>Mobile Phone</b>
              </td>
              <td>:</td>
              <td align="left">{mobile}</td>
            </tr>
          </tbody>
        </table>

        <button className="customer-edit-button" onClick={this._CustomersViewShowModals}>
          Edit Information
        </button>
        {!runtimeEnv.isProd ? (
          <button className="customer-delete-button" onClick={this._DeleteCustomer}>
            <IconTrash /> Delete Contact
          </button>
        ) : null}
        {pendingDeletion ? this.renderConfirmDelete() : null}
      </div>
    )
  }
}

// ***********************LETS*EXPORT*CustomerView***********************

const CustomersView = CustomersViewComponent
export default connect(null, {
  hideModal,
  showModal,
})(CustomersView)
