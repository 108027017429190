import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logoutUser } from '../../core/actions/users/useractions'
import './logoutPage.scss'
import AIPLogo from '../../core/assets/images/iPacketLogo.png'

export default function LogoutPage() {
  useEffect(() => {
    logoutUser(true)
  }, [])
  return (
    <div className="logout-page">
      {/*
      <div className="logout-page-form">
        <img src={AIPLogo} alt="iPacket Brand Logo" />
        <h1>You are Logged Out</h1>
        <Link to="/login?force_logout=true">Click Here to Login</Link>
      </div>
      */}
    </div>
  )
}
