// general: redux action file for all things 'packetview' data.

// constants
import { PACKET_VIEW_TYPE } from '../_constants/packetviewTypes'

// packetViewActive
function packetViewActive(view_type: string) {
  return function(dispatch: boolean) {
    dispatch({ type: PACKET_VIEW_TYPE, view_type })
  }
}

export { packetViewActive }
