import React from 'react'

const IPacketRingLogo = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <g>
        <path
          class="iPacketRingLogoPath"
          d="M199.84,54.63c38.73,0,75.14,15.08,102.53,42.47c27.39,27.39,42.47,63.8,42.47,102.53
    		s-15.08,75.14-42.47,102.53c-27.39,27.39-63.8,42.47-102.53,42.47s-75.14-15.08-102.53-42.47c-27.39-27.39-42.47-63.8-42.47-102.53
    		S69.92,124.48,97.31,97.1C124.69,69.71,161.11,54.63,199.84,54.63 M199.84,49.63c-82.84,0-150,67.16-150,150s67.16,150,150,150
    		s150-67.16,150-150S282.68,49.63,199.84,49.63L199.84,49.63z"
        />
      </g>
      <g>
        <path
          class="iPacketRingLogoPath"
          d="M283.37,154.89h-4.42v-1.78c0-1.86-1.5-3.36-3.36-3.36h-84.62c-2.03,0.02-3.69-1.62-3.7-3.65
    		c0-0.01,0-0.02,0-0.03v-5.23c0.02-2.12-1.69-3.86-3.82-3.88c-0.02,0-0.04,0-0.06,0h-53.55c-2.14-0.05-3.91,1.65-3.96,3.79
    		c0,0.03,0,0.06,0,0.09v5.37c0,2.03-1.65,3.68-3.68,3.68h-0.78c-1.86,0-3.36,1.5-3.36,3.36c0,0.01,0,0.02,0,0.03l0.01,1.61h-1.39
    		c-0.07,0-0.14,0-0.21,0.01c-2.15,0.11-3.81,1.95-3.7,4.1l5.17,100.27c0.11,2.08,1.82,3.71,3.91,3.7h155.28
    		c2.07,0,3.78-1.61,3.91-3.68l6.23-100.24c0.01-0.09,0.01-0.18,0.01-0.27C287.27,156.63,285.52,154.89,283.37,154.89z"
        />
      </g>
    </svg>
  )
}
export default IPacketRingLogo
