// general: action file for all things 'packet send' data being passed back and forth.

// API(S)
import {
  DJangoPacketSSDetailAPIURL,
  DJangoPacketSSPostAPIURL,
  DJangoPacketSSPutAPIURL,
  DJangoPacketMessageAPIURL,
  DJangoPacketSendEmailPreview,
  DJangoPacketSendPreviousAttachments,
  DJangoPacketSendPreviousAttachmentDetails,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

// PACKET SEND - FUNCTIONS REFERENCED IN THE 'EXPORT'
const FormDataPacketSSPostConst = (data: Object) => {
  const params = new FormData() // eslint-disable-line no-undef
  params.append('vehicle_id', data.vehicle_id)
  params.append('message', data.message)
  params.append('module_ids', data.module_ids.join())
  params.append('show_price', data.show_price.toString())
  params.append('platform_id', data.platform_id)
  return params
}

const FormDataPacketSSPutConst = (data: Object) => {
  const params = new FormData() // eslint-disable-line no-undef
  params.append('posted', data)
  return params
}

// ----------EXPORT----------

export default {
  // PACKET SEND - API - SOCIAL SHARE
  putPacketSocialShare: (params: Object, token: string) => {
    // v1/packets/info/social/
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketSSPostAPIURL}`, {
      body: FormDataPacketSSPostConst(params),
      method: 'PUT',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  postPacketSocialShare: (shareUuid: number, params: Object, token: string) => {
    // v1/packets/info/social/:social_share_uuid
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketSSPutAPIURL}${shareUuid}`, {
      method: 'POST',
      headers,
      body: FormDataPacketSSPutConst(params),
    }).then(response => {
      return response.json()
    })
  },

  // --- stalk the user
  getPacketSocialShareDetail: (shareUuid: number, token: string) => {
    // v1/packets/info/social/:share_uuid
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketSSDetailAPIURL}${shareUuid}}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getPacketSendDefaults: (legacy_user_id: number, token: string) => {
    // v1/users/packet_send_message/legacy/:legacy_user_id

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketMessageAPIURL}${legacy_user_id}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getEmailPreview: (vehicle_ids: array, token: string) => {
    // v1/packets/email-preview/:vehicle_id
    const paramArray = vehicle_ids.map(vehicle_id => {
      if (vehicle_ids.indexOf(vehicle_id) === 0) {
        return `?vehicle_id=${vehicle_id}`
      } else {
        return `&vehicle_id=${vehicle_id}`
      }
    })
    const paramString = paramArray.join('')

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'text/html',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketSendEmailPreview}${paramString}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getPreviousAttachments: (username, token, page) => {
    let pathParameters = `&page=${page}&items_per_page=10`

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoPacketSendPreviousAttachments}${username}${pathParameters}`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  getPreviousAttachmentDetails: (attachment_id, token) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoPacketSendPreviousAttachmentDetails}${attachment_id}?s3_download=True/`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },
}
