import { STASH_URL_HISTORY } from '../_constants/url_infoTypes'

function stashUrlHistory(userHistory) {
  return function(dispatch) {
    dispatch({
      type: STASH_URL_HISTORY,
      user_history: userHistory,
    })
  }
}

export { stashUrlHistory }
