// general: action file for all things 'contact' data being passed back and forth.

// API(s)
import { DJangoPriceChangeConfigAPIURL } from '../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

// HELPERS
import Helper from '../helpers/platformhelper'

export default {
  getPriceChangeSettings: (jwt_string: string) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPriceChangeConfigAPIURL}${jwt_string}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  updatePriceChangeSettings: (jwt_string: string, vehicle_id: integer, alertValue: boolean) => {
    const paramsForAPI = new FormData() // eslint-disable-line no-undef
    paramsForAPI.append('vehicle_id', vehicle_id)
    paramsForAPI.append('price_change_alerts', alertValue)

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPriceChangeConfigAPIURL}${jwt_string}`, {
      method: 'POST',
      headers,
      body: paramsForAPI,
    }).then(response => {
      return response.json()
    })
  },
}
