import React from 'react'

const CancelIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <polygon points="49.97 7.34 42.66 0.03 25 17.68 7.32 0 0 7.32 17.68 25 0.03 42.66 7.34 49.97 25 32.31 42.63 49.94 49.94 42.63 32.31 25 49.97 7.34" />
    </svg>
  )
}
export default CancelIcon
