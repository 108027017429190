// general:

export default {
  // tools
  // default populate empty objects - used in production.
  finance_table_cpo: [
    {
      cpo_id: 0,
      company_store_id: 0,
      make_id: 0,
      make: '',
      model_id: 0,
      model: '',
      cpo_rate_id: 0,
      begin_model_year: '',
      end_model_year: '',
      month_24_36: 0,
      month_37_48: 0,
      month_49_66: 0,
      effective_start_date: '',
      effective_end_date: '',
    },
  ],

  finance_buttons: [
    {
      title: 'CPO',
      id: 'cpo',
      type: 'cpo',
      edit: false,
    },
    {
      title: 'Edit',
      id: 'cpo',
      type: 'cpo-edit',
      edit: true,
    },
    {
      title: 'Non-CPO',
      id: 'non-cpo',
      type: 'non-cpo',
      edit: false,
    },
    {
      title: 'Edit',
      id: 'non-cpo',
      type: 'non-cpo-edit',
      edit: true,
    },
  ],

  finance_headers_cpo: [
    {
      name: 'Make',
      id: 'make',
      bonus: '',
      type: 'string',
      bloop: 'cpo_year_selection',
      dropdown: false,
      description: '',
    },
    {
      name: 'Model',
      id: 'model',
      bonus: '',
      type: 'string',
      bloop: 'cpo_year_selection',
      dropdown: false,
      description: '',
    },
    {
      name: 'Begin',
      id: 'begin_model_year',
      bonus: '',
      type: 'date',
      bloop: 'cpo_year_selection',
      dropdown: true,
      description: 'Year',
    },
    {
      name: 'End',
      id: 'end_model_year',
      bonus: '',
      type: 'date',
      bloop: 'cpo_year_selection',
      dropdown: true,
      description: 'Year',
    },
    {
      name: '24 - 36 Months',
      id: 'month_24_36',
      bonus: '',
      type: 'date',
      bloop: 'cpo_year_selection',
      dropdown: false,
      description: '',
    },
    {
      name: '37 - 48 Months',
      id: 'month_37_48',
      bonus: '',
      type: 'date',
      bloop: 'cpo_year_selection',
      dropdown: false,
      description: '',
    },
    {
      name: '49 - 66 Months',
      id: 'month_49_66',
      bonus: '',
      type: 'date',
      bloop: 'cpo_year_selection',
      dropdown: false,
      description: '',
    },
    {
      name: 'Valid',
      start_id: 'effective_start_date',
      end_id: 'effective_end_date',
      id: 'effective_dates',
      bonus: 'effective_end_date',
      type: 'date_range',
      bloop: 'cpo_year_selection',
      dropdown: false,
      description: '',
    },
  ],

  finance_headers_noncpo: [
    {
      name: 'Year',
      id: 'year',
      bonus: '',
      type: 'date',
      bloop: 'noncpo_selection',
      dropdown: false,
      description: '',
    },
    {
      name: 'Less than 60,000 Miles',
      id: 'rate_60',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: false,
      description: '',
    },

    {
      name: 'Term 1',
      id: 'term_60',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: true,
      description: 'Term',
    },
    {
      name: '60,000 - 79,999 Miles',
      id: 'rate_60_80',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: false,
      description: '',
    },

    {
      name: 'Term 2',
      id: 'term_60_80',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: true,
      description: 'Term',
    },
    {
      name: '80,000 - 99,999 Miles',
      id: 'rate_80_100',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: false,
      description: '',
    },

    {
      name: 'Term 3',
      id: 'term_80_100',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: true,
      description: 'Term',
    },
    {
      name: 'Greater than 100,000 Miles',
      id: 'rate_100',
      bonus: '',
      type: 'number',
      bloop: 'noncpo_selection',
      dropdown: false,
      description: '',
    },
  ],

  cpo_year_selection: [
    {
      number: 2014,
    },
    {
      number: 2015,
    },
    {
      number: 2016,
    },
    {
      number: 2017,
    },
    {
      number: 2018,
    },
    {
      number: 2019,
    },
  ],

  noncpo_selection: [
    {
      number: 36,
    },
    {
      number: 48,
    },
    {
      number: 54,
    },
    {
      number: 60,
    },
  ],
}
