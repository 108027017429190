import React, { Component } from 'react'
import Modal from '../../layouts/modal'
import { connect } from 'react-redux'
import IPacketEmployee from '../../../core/assets/images/ipacket_employee.jpg'
import '../styles/wordtrackmodal.scss'
import ChatIcon from '../../../core/assets/images/icons/chat-icon'

class WordTrackModal extends Component {
  render() {
    return (
      <Modal>
        <div className="word-track-modal">
          <div className="word-track-modal-title">
            {this.props.isMSRP ? (
              <React.Fragment>MSRP Word Track</React.Fragment>
            ) : (
              <React.Fragment>Service Word Track</React.Fragment>
            )}
            <span>
              <ChatIcon color="#3cb54a" />
            </span>
          </div>
          <div className="word-track-modal-employee">
            <div className="word-track-modal-employee-image">
              <img src={IPacketEmployee} />
            </div>
            <div className="word-track-modal-employee-name">iPacket Charlie</div>
          </div>
          <div className="word-track-modal-text">
            {this.props.isMSRP ? (
              <React.Fragment>
                <p>
                  It’s a common misconception that the optional equipment listed on a dealer’s
                  website is 100% accurate, and that is not always the case.
                </p>
                <p>
                  Even as industry professionals, it’s challenging for us to know every option on
                  every vehicle.
                </p>
                <p>
                  The only way you can verify a vehicle’s optional equipment is by reviewing the OEM
                  Window Sticker or Build-sheet.
                </p>
                <p>
                  This document is especially important because a single option can affect the value
                  of a vehicle by thousands of dollars.
                </p>
                <p>
                  Make sure that no matter where you’re shopping, to ask the dealer to provide you
                  the original OEM Window Sticker or Build-sheet, if they have done their research
                  as we have, they will have the document readily available for you to review.
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>
                  Just because a vehicle has a clean history report does NOT mean that the vehicle
                  is in good mechanical condition.
                </p>
                <p>
                  The only way you can verify the mechanical condition is to review the receipt of
                  the reconditioning service work that was completed by the dealer.
                </p>
                <p>
                  Make sure that no matter where you’re shopping, to ask the dealer to provide you
                  the service receipts; if they did the work - there will be a receipt.
                </p>
                <p>
                  Because purchasing a vehicle without seeing the service receipts can be an
                  expensive mistake.
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  isMSRP: state.modal.props.isMSRP,
})
export default connect(mapStateToProps, null)(WordTrackModal)
