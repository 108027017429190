// general: wrapper file that gets the local user info from storage,
// makes it a state, and then passes it down as a propery in any
// component that is 'wrapped' by this .js file.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserActions from '../actions/users/useractions'
import Helper from '../helpers/platformhelper'

export default function UserInfoWrapper(Element) {
  const mapStateToProps = state => ({
    userInfo: state.authentication.userInfo,
  })
  const mapDispatchToProps = null
  return connect(mapStateToProps, mapDispatchToProps)(Element)
}

// export class UserInfoWrapperComponent extends Component {
//   constructor(props: Object) {
//     super(props)
//     console.log('UserInfoWrapper Props',props)
//   }
//
//   render() {
//     return <div {...this.state} {...this.props} />
//   }
// }
// const mapStateToProps = state => ({
//   userInfo: state.authentication.userInfo,
// })
// const mapDispatchToProps = null
// connect(mapStateToProps, mapDispatchToProps)(UserInfoWrapperComponent)
//
// export default function(Element: Object) {
//   return class extends UserInfoWrapperComponent {
//     render() {
//       return <Element {...this.state} {...this.props} />
//     }
//   }
// }
