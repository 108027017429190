import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import IconEyeOpen from '../../../core/assets/images/icons/icon-eye-open'
import UserActions, { userChangePassword } from '../../../core/actions/users/useractions'
import debounce from 'lodash.debounce'

import '../styles/passwordform.scss'

const convertComplianceNumber = complianceNumber => {
  const compliance = {
    text: '',
    class: '',
  }
  if (complianceNumber <= 33) {
    compliance.text = 'Poor'
    compliance.class = 'password-compliance-poor'
  } else if (complianceNumber > 33 && complianceNumber <= 66) {
    compliance.text = 'Poor'
    compliance.class = 'password-compliance-good'
  } else if (complianceNumber > 66 && complianceNumber <= 99) {
    compliance.text = 'Great'
    compliance.class = 'password-compliance-great'
  } else if (complianceNumber >= 100) {
    compliance.text = 'Perfect'
    compliance.class = 'password-compliance-perfect'
  }
  return compliance
}

const PasswordForm = props => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [passwordCompliance, setPasswordCompliance] = useState(0)
  const [passwordRequirements, setPasswordRequirements] = useState([])
  const [passwordStrength, setPasswordStrength] = useState(0)

  const debounceInput = debounce(e => {
    if (e.target.value.length) {
      UserActions.postPasswordStrengthDJAPI(e.target.value).then(res => {
        const { aip_standards } = res
        if (aip_standards) {
          setPasswordCompliance(aip_standards.aip_percentage)
          setPasswordRequirements(aip_standards.aip_pw_rules)
          setPasswordStrength(aip_standards.normalized_strength)
        }
      })
    }
  }, 1000)

  const handleSubmit = e => {
    e.preventDefault()
    if (newPassword === confirmPassword && passwordCompliance === 100) {
      userChangePassword(newPassword, confirmPassword, props.token)
        .then(response => {
          if (response.status === 200) {
            return response.json()
          }
        })
        .then(res => {
          if (res) {
            toast(res)
            setNewPassword('')
            setConfirmPassword('')
            props.closeForm()
          }
        })
    }
  }

  return (
    <form onSubmit={handleSubmit} className="password-form-container">
      <div className="password-form-row">
        <div className="individual-form-container">
          <label htmlFor="newPassword">New Password</label>
          <input
            id="newPassword"
            name="newPassword"
            type={passwordVisibility ? 'text' : 'password'}
            onChange={e => {
              setNewPassword(e.target.value)
              debounceInput.call({}, e)
            }}
            value={newPassword}
          />
        </div>
        <div className="individual-form-container">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type={passwordVisibility ? 'text' : 'password'}
            onChange={e => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </div>
        <div className="button-and-control-wrapper">
          <div className="password-controls">
            <div
              className={`password-eye ${passwordVisibility ? 'password-eye-selected' : ''}`}
              onClick={() => {
                setPasswordVisibility(!passwordVisibility)
              }}
            >
              <IconEyeOpen />
            </div>
          </div>
          <button
            type="submit"
            disabled={
              newPassword !== confirmPassword ||
              passwordCompliance < 100 ||
              newPassword.length === 0
            }
          >
            Save
          </button>
        </div>
      </div>
      <div className={`${newPassword.length === 0 ? ' hidden' : 'password-feedback-container'}`}>
        <div className="password-compliance-grade-wrapper">
          <label>{convertComplianceNumber(passwordCompliance).text}</label>
          <div
            className={`password-compliance-grade ${
              convertComplianceNumber(passwordCompliance).class
            }`}
          />
        </div>
        <br />
        <div className="password-tips">
          <ul>
            {passwordRequirements.map(rq => (
              <li key={rq}>{rq}</li>
            ))}
          </ul>
        </div>
      </div>
    </form>
  )
}
export default PasswordForm

PasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
}
