// general:

// constants
import { PACKET_VIEW_TYPE } from '../_constants/packetviewTypes'

const initState = { view_type: 'general_info' }

export function packetview(state, action) {
  if (!state) {
    state = initState
  }

  switch (action.type) {
    case PACKET_VIEW_TYPE:
      return {
        ...state,
        view_type: action.view_type,
      }
    default:
      return state
  }
}
