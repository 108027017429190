// general:

// @flow
import React, { Component } from 'react'
// node packages
// --- redux
import { connect } from 'react-redux'
import { hideModal } from '../../../core/_actions/modalActions'
import { deleteModalAction } from '../../../core/_actions/packetstashActions'
// components
import Modal from '../../layouts/modal'

type Props = {
  hideModal: Function,
  removeItem: string,
  deleteModalAction: Function,
}

class DeleteModal extends Component {
  constructor(props) {
    super(props)
    this._onClose = this.onClose.bind(this)
    this._removeFunction = this.removeFunction.bind(this)
  }

  // onClose
  onClose() {
    this.props.hideModal()
    this.props.deleteModalAction(false)
  }

  // removeFunction
  removeFunction() {
    this.props.deleteModalAction(true)
    this.props.hideModal()
  }

  props: Props
  _onClose: Function // function
  _removeFunction: Function // function

  render() {
    const style = { padding: '5px 40px', fontWeight: 700 }
    return (
      <Modal>
        <div
          align="center"
          id="report-recipient-delete"
          style={{ maxWidth: '500px', minWidth: '250px' }}
        >
          <h3>
            Are you sure you wish to remove:
            <br />
            <br />
            <span
              style={{
                display: 'inline-block',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'no-wrap',
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',
              }}
            >
              <b>{this.props.removeItem}?</b>
            </span>
          </h3>
          <br />
          <div className="flexRowSpace" style={{ justifyContent: 'space-around' }}>
            <button onClick={this._onClose} style={style}>
              No
            </button>
            <button onClick={this._removeFunction} style={style}>
              Yes
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

// ***********************LETS*EXPORT*MODAL***********************
function mapStateToProps(state) {
  return { removeItem: state.modal.props.removeItem }
}

export default connect(mapStateToProps, {
  hideModal,
  deleteModalAction,
})(DeleteModal)
