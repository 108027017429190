import React, { Component } from 'react'
import ls from 'local-storage'
import { toast } from 'react-toastify'

// redux
import { connect } from 'react-redux'
import { hideModal } from '../../../core/_actions/modalActions'
import { stashCovideo } from '../../../core/_actions/packetstashActions'

// components
import Modal from '../../layouts/modal'
import LoadingSpinner from '../../helpers/loadingspinner'

// style
import '../styles/covideomodal.scss'

// helpers
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../../core/config/aipDjapi'
import Paginator from '../../helpers/paginator'

// assets
import PlayIcon from '../../../core/assets/images/icons/play-icon'
import IconEyeOpen from '../../../core/assets/images/icons/icon-eye-open'
import IconExpand from '../../../core/assets/images/icons/icon-expand'
import Covideo from '../../../core/assets/images/covideo_logo.png'
import CovideoIcon from '../../../core/assets/images/icons/covideo-icon'

class CovideoModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accessToken: JSON.parse(localStorage.getItem('covideo-access-token')),
      activeTab: 1,
      username: '',
      password: '',
      userVideos: [],
      isLoading: false,
      loginFailed: false,
      videoToSend: null,
      recording: false,
      recorderLoading: false,
      playing: -1,
      passwordHidden: true,
      searchValue: '',
      page: 1,
      count: 0,
      loadingVideo: false,
      viewingVideo: false,
      viewingSource: '',
      search: '',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.covideoRecorder = this.covideoRecorder.bind(this)
  }

  componentDidMount() {
    const { accessToken } = this.state

    const url = 'https://api.covideo.com/videos/?start=0&limit=5&search='

    if (accessToken) {
      this.setState({ loadingVideo: true })

      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          console.log(response.status)
          if (response.status == 401) {
            ls.set('covideo-access-token', null)
            ls.set('covideo-refresh-token', null)
          } else {
            return response.json()
          }
        })
        .then(res => {
          if (res) {
            this.setState({
              userVideos: res.videos,
              count: res.count,
              loadingVideo: false,
            })
          }
        })
    }

    if (this.props.covideoToSend) {
      this.setState({ videoToSend: this.props.covideoToSend })
    }
  }

  async handleSubmit(e) {
    this.setState({
      isLoading: true,
    })
    e.preventDefault()
    const covideoUrl = 'https://api.covideo.com/auth'
    const { username, password } = this.state
    const params = { username: username, password: password }

    var head = document.getElementsByTagName('head')[0]

    console.log('Logging IN')

    let covideoCreds = await fetch(covideoUrl, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(params),
    }).then(res => {
      return res.json()
    })

    console.log(covideoCreds)

    if (covideoCreds.accessToken) {
      ls.set('covideo-access-token', covideoCreds.accessToken)
      ls.set('covideo-refresh-token', covideoCreds.refreshToken)

      this.setState({ accessToken: covideoCreds.accessToken })

      const covideoMainScript = document.createElement('script')

      covideoMainScript.src = 'https://code.covideo.com/recorder/js/init.js'

      const covideoTokenScript = document.createElement('script')

      covideoTokenScript.innerHTML = `Covideo.init('${covideoCreds.accessToken}')`

      head.appendChild(covideoMainScript)

      const url = `https://api.covideo.com/videos/?start=0&limit=5`

      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${covideoCreds.accessToken}`,
        },
      })
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res) {
            console.log(res)

            this.setState({
              userVideos: res.videos,
              count: res.count,
              loadingVideo: false,
            })
          }
        })

      this.setState({
        isLoading: false,
        loginFailed: false,
      })
    } else {
      this.setState({
        isLoading: false,
        loginFailed: true,
      })
    }
  }

  getCovideos() {
    let userVideosCall = Covideo.getVideos().then(videos => {
      return videos
    })

    userVideosCall.then(res => {
      this.setState({
        userVideos: res,
      })
    })
  }

  covideoRecorder() {
    Covideo.loadRecorder('covideo-container')

    setTimeout(
      this.setState({
        recorderLoading: false,
      }),
      3000,
    )

    Covideo.onVideoReady = videoId => {
      console.log(`Video with id ${videoId} uploaded successfully!`)
    }
    Covideo.onVideoError = error => {
      console.log('Video failed to upload: ', error)
    }
  }

  startRecorder() {
    this.setState({
      recording: true,
      recorderLoading: true,
    })

    setTimeout(this.covideoRecorder, 2000)
  }
  requestNewCovideoAccount() {
    AIP_DJAPI(AIP_DJAPI_URLS.DjangoIntercomCovideoRequest, {
      method: 'PUT',
      headers: {
        Authorization: `Token ${this.props.token}`,
      },
    }).then(res => {
      if (res && res.status === 200) {
        toast('Your new Covideo account request has been received!')
      } else {
        toast('Please Call to request your new Covideo account.')
      }
    })
  }
  logout() {
    this.setState({ accessToken: null })
    ls.set('covideo-access-token', null)
    ls.set('covideo-refresh-token', null)
    toast('Successfully logged out of Covideo')
  }

  handleSearch(e, start, end) {
    this.setState({ search: e })
    let begin = start
    let stop = end

    if (!start && !end) {
      begin = 0
      stop = 5
      this.setState({ page: 1 })
    }

    this.updateResults(e, begin, stop)
  }

  toggleVisibility() {
    var password = document.getElementById('password')

    this.setState({
      passwordHidden: !this.state.passwordHidden,
    })

    if (password.type === 'password') {
      password.type = 'text'
    } else {
      password.type = 'password'
    }
  }

  updatePage = page => {
    this.setState({ page: page })

    let start = 0
    let end = 5

    if (page === 1) {
      start = 0
      end = 5
    } else {
      start = page * 5 - 5
      end = page * 5
    }

    const { search } = this.state
    this.handleSearch(search, start, end)
  }

  updateResults(e, begin, stop) {
    this.setState({ loadingVideo: true })
    const url = `https://api.covideo.com/videos/?start=${begin}&limit=${stop}&search=${e}`
    const { accessToken } = this.state

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res) {
          console.log(res)

          this.setState({
            userVideos: res.videos,
            count: res.count,
            loadingVideo: false,
          })
        }
      })
  }

  handleCovideoStartAndStop(event, action) {
    var playPromise = event.target.play()

    if (action == 'stop') {
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            event.target.pause()
          })
          .catch(error => {})
      }
    }

    if (playPromise !== undefined) {
      playPromise.then(_ => {}).catch(error => {})
    }
  }

  render() {
    let { videoToSend, page, count } = this.state
    return (
      <Modal>
        <div
          align="center"
          className={this.state.viewingVideo ? 'main-holder viewing' : 'main-holder'}
        >
          {!this.state.accessToken && !this.state.isLoading ? (
            <div className="covideo-login-page">
              <div className="covideo-logo">
                <img src={Covideo} />
              </div>
              <div className="covideo-form">
                <form onSubmit={this.handleSubmit}>
                  {this.state.loginFailed && (
                    <div className="error-message">
                      <i>Login failed, username or password incorrect</i>
                    </div>
                  )}
                  <div className="covideo-input username">
                    <input
                      type="text"
                      onChange={e =>
                        this.setState({
                          username: e.target.value,
                        })
                      }
                      placeholder="Login"
                    />
                  </div>
                  <div className="covideo-input password">
                    <input
                      type="password"
                      onChange={e =>
                        this.setState({
                          password: e.target.value,
                        })
                      }
                      placeholder="Password"
                      id="password"
                    />
                    <div
                      className={
                        this.state.passwordHidden
                          ? 'covideo-password-toggle hidden'
                          : 'covideo-password-toggle'
                      }
                      onClick={() => this.toggleVisibility()}
                    >
                      <IconEyeOpen />
                    </div>
                  </div>
                  <div className="covideo-request-account">
                    Don't have a Covideo Account?{' '}
                    <span onClick={() => this.requestNewCovideoAccount()}>
                      Click Here to Request an Account
                    </span>
                  </div>
                  <input type="submit" value="Login" />
                </form>
              </div>
            </div>
          ) : !this.state.accessToken && this.state.isLoading ? (
            <div className="covideo-login-page">
              <div className="covideo-logo">
                <img src="https://www.covideo.com/wp-content/themes/covideo/img/covideo-logo.svg" />
              </div>
              <div className="covideo-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="covideo-input">
                    <input
                      type="text"
                      onChange={e =>
                        this.setState({
                          username: e.target.value,
                        })
                      }
                      placeholder="Login"
                    />
                  </div>
                  <div className="covideo-input">
                    <input
                      type="password"
                      onChange={e =>
                        this.setState({
                          password: e.target.value,
                        })
                      }
                      placeholder="Password"
                    />
                  </div>
                  <input type="submit" value="Login" />
                </form>
                <LoadingSpinner loading wheel />
              </div>
            </div>
          ) : (
            <div>
              {!this.state.viewingVideo ? (
                <div className="covideo-content">
                  <div className="covideo-header">
                    <div className="covideo-logo">
                      <CovideoIcon height="30px" />
                    </div>
                    <div>
                      <button
                        className="covideo-button"
                        onClick={() => {
                          //this.startRecorder()
                          window.open(
                            `https://www.covideo.com/api/login/oauth_callback.php?token=${this.state.accessToken}`,
                          ),
                            this.props.hideModal()
                        }}
                      >
                        Record +
                      </button>
                      <button
                        className="covideo-button logout"
                        style={{ marginLeft: '2px' }}
                        onClick={() => {
                          this.logout(), this.props.hideModal()
                        }}
                      >
                        Logout
                      </button>
                    </div>
                  </div>

                  <div className="search-bar-holder">
                    <input
                      type="text"
                      className="search-bar"
                      onChange={e => this.handleSearch(e.target.value)}
                      placeholder="Search Covideos"
                      value={this.state.search}
                    />
                  </div>

                  <div className="covideo-videos">
                    {this.state.userVideos && !this.state.loadingVideo ? (
                      this.state.userVideos.map(video => (
                        <div className="user-video" style={{ cursor: 'pointer' }} key={video.id}>
                          <div
                            className={
                              this.state.playing === video.id
                                ? 'expand-video showing'
                                : 'expand-video'
                            }
                            onClick={() => {
                              this.setState({
                                viewingSource: video.videoSource,
                                viewingVideo: true,
                              })
                            }}
                          >
                            <IconExpand />
                          </div>
                          <video
                            className="thumbnail"
                            id="videovideo"
                            onMouseOver={event => (
                              this.handleCovideoStartAndStop(event, 'start'),
                              this.setState({
                                playing: video.id,
                              })
                            )}
                            onMouseOut={event => (
                              this.handleCovideoStartAndStop(event, 'stop'),
                              this.setState({
                                playing: -1,
                              })
                            )}
                            poster={video.autogeneratedThumbnail}
                            onClick={() => {
                              this.setState({
                                viewingSource: video.videoSource,
                                viewingVideo: true,
                              })
                            }}
                            preload="none"
                          >
                            <source src={video.videoSource} type="video/mp4" />
                          </video>
                          <div
                            className="video-overlay"
                            style={{ display: this.state.playing === video.id ? 'none' : 'block' }}
                          >
                            <PlayIcon />
                          </div>
                          <div>{video.title}</div>
                          <input
                            style={{ cursor: 'pointer' }}
                            type="checkbox"
                            checked={videoToSend && videoToSend.id && videoToSend.id === video.id}
                            onChange={e => {
                              if (e.target.checked) {
                                this.setState({
                                  videoToSend: { ...video, accessToken: this.state.accessToken },
                                })
                              } else {
                                this.setState({ videoToSend: null })
                              }
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <LoadingSpinner loading />
                    )}
                  </div>

                  <div className="covideo-footer">
                    <Paginator
                      page={page}
                      updatePage={this.updatePage}
                      numPages={Math.ceil(count / 5)}
                    />
                    {this.state.videoToSend && (
                      <button
                        className="covideo-button"
                        onClick={() => {
                          this.props.stashCovideo(this.state.videoToSend), this.props.hideModal()
                        }}
                      >
                        Attach Selected Video
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="covideo-content">
                  <div className="covideo-header">
                    <div className="covideo-logo">
                      <img src="https://www.covideo.com/wp-content/themes/covideo/img/covideo-logo.svg" />
                    </div>
                    <div>
                      <button
                        className="covideo-button"
                        onClick={() => {
                          this.setState({
                            viewingVideo: false,
                          })
                        }}
                      >
                        Back to All Recordings
                      </button>
                    </div>
                  </div>
                  <div>
                    <video width="auto" height="auto" controls className="large-covideo">
                      <source src={this.state.viewingSource} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  covideoToSend: state.packetstash.covideo,
})

export default connect(mapStateToProps, {
  hideModal,
  stashCovideo,
})(CovideoModal)
