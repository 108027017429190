import React, { useState, useRef } from 'react'
import LoadingSpinner from '../helpers/loadingspinner'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import ToolTip from '../helpers/tooltip'
import DownloadBoxIcon from '../../core/assets/images/icons/download-box_icon'
import OpenNewIcon from '../../core/assets/images/icons/open-pdf-new'
import './styles/documentViewer.scss'

function DocumentViewer(props) {
  let { module, downloadIcon } = props
  const [numPages, setNumPages] = useState(null)
  const [scaleIndex, setScaleIndex] = useState(7)
  const [initialPdfWidth, setInitialPdfWidth] = useState(null)
  const [showHideSticker, setShowHideSticker] = useState(true)
  const modulePdfElement = useRef(null)
  const setupPdfSizing = view => {
    const originalPDFWidth = view[2]
    const originalPDFHeight = view[3]

    if (modulePdfElement.current.clientWidth / modulePdfElement.current.clientHeight > 1) {
      if (originalPDFWidth / originalPDFHeight > 1) {
        setInitialPdfWidth(modulePdfElement.current.clientWidth * 0.9)
      } else {
        setInitialPdfWidth(modulePdfElement.current.clientWidth * 0.6)
      }
    } else {
      setInitialPdfWidth(modulePdfElement.current.clientWidth)
    }
  }

  const scaleValues = [
    0.25,
    0.33,
    0.5,
    0.67,
    0.75,
    0.8,
    0.9,
    1,
    1.1,
    1.25,
    1.5,
    1.75,
    2,
    2.5,
    3,
    4,
    5,
  ]
  const decreaseScale = () => {
    if (scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1)
    }
  }
  const increaseScale = () => {
    if (scaleIndex < scaleValues.length - 1) {
      setScaleIndex(scaleIndex + 1)
    }
  }
  const downloadPdf = () => {
    const link = document.createElement('a')
    link.href = module.url
    link.download = `${module.name}.${module.content_type}`
    link.click()
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
    }, 100)
  }
  const openNewTab = () => {
    const pdfWindow = window.open(module.url, '_blank')
    setTimeout(() => {
      pdfWindow.document.title = `${module.name}-pdf`
    }, 100)
  }
  return (
    <div className="module-pdf" ref={modulePdfElement}>
      <div className="module-viewer-container">
        <div className="module-viewer-header">
          <div className="module-viewer-buttons">
            <div className="download-icon" onClick={openNewTab}>
              <ToolTip onHover tip={<React.Fragment>Open in new tab</React.Fragment>}>
                <OpenNewIcon />
              </ToolTip>
            </div>
            <div className="download-icon" onClick={downloadPdf}>
              <ToolTip onHover tip={<React.Fragment>Download Sticker</React.Fragment>}>
                {downloadIcon ? downloadIcon : <DownloadBoxIcon />}
              </ToolTip>
            </div>
          </div>
          <div className="module-viewer-header-name">{module.label}</div>
          <div className="module-viewer-header-showHide">
            <button
              onClick={() => {
                setShowHideSticker(!showHideSticker)
              }}
            >
              {showHideSticker ? 'Hide Sticker' : 'Show Sticker'}
            </button>
          </div>
          <div className="module-viewer-header-tools">
            <button
              onClick={() => {
                decreaseScale()
              }}
            >
              -
            </button>
            <div>{`${Math.round(scaleValues[scaleIndex] * 100)} %`}</div>
            <button
              onClick={() => {
                increaseScale()
              }}
            >
              +
            </button>
          </div>
        </div>
        <div
          className={
            showHideSticker
              ? `module-viewer-content-container open`
              : `module-viewer-content-container closed`
          }
        >
          <Document
            file={module.url}
            loading={<LoadingSpinner loading />}
            onLoadSuccess={pdf => {
              setNumPages(pdf.numPages)
              pdf.getPage(1).then(page => {
                setupPdfSizing(page.view)
              })
            }}
          >
            <Pages scale={scaleValues[scaleIndex]} numPages={numPages} width={initialPdfWidth} />
          </Document>
        </div>
      </div>
    </div>
  )
}
function Pages(props) {
  const { numPages, scale, width } = props

  let pages = []
  for (let i = 1; i < numPages + 1; i++) {
    pages.push(<Page scale={scale} width={width} pageNumber={i} loading={<React.Fragment />} />)
  }
  return <React.Fragment>{pages}</React.Fragment>
}

export default DocumentViewer
