import React, { useEffect } from 'react'
import { swapRedirectToken, logoutUser } from '../../core/actions/users/useractions'

export default function AuthPage() {
  useEffect(() => {
    let pendingLogin = localStorage.getItem('pendingLogin')
    if (pendingLogin) {
      pendingLogin = JSON.parse(pendingLogin)
    } else {
      logoutUser(true)
    }
    const urlParams = new URLSearchParams(window.location.search)
    const token_param = urlParams.get('token')
    if (pendingLogin && token_param) {
      localStorage.clear()
      swapRedirectToken(token_param, pendingLogin.pkce_unhashed)
    }
  }, [])
  return <React.Fragment />
}
