import React, { PureComponent } from 'react'
import throttle from 'lodash.throttle'

class Paginator extends PureComponent {
  nextPage = () => {
    this.handleUpdate(this.props.page + 1)
  }

  prevPage = () => {
    this.handleUpdate(this.props.page - 1)
  }
  updatePage = newPage => {
    let {} = this.props
    if (newPage >= 1 && newPage <= this.props.numPages) {
      this.props.updatePage(newPage)
    }
  }
  handleUpdate = throttle(this.updatePage, 750)

  render() {
    const { page, numPages } = this.props
    if (page && numPages) {
      return (
        <div className="paginator" style={{ animation: ` 1000ms fadeIn` }}>
          <div className="paginator-control" onClick={this.prevPage}>
            Previous
          </div>
          {page > 1 && (
            <div
              className="paginator-control"
              id="1"
              onClick={() => {
                this.handleUpdate(1)
              }}
            >
              1...
            </div>
          )}
          {page > 2 && (
            <div
              className="paginator-control"
              id={page - 1}
              onClick={() => {
                this.handleUpdate(page - 1)
              }}
            >
              {page - 1}
            </div>
          )}
          <div className="paginator-active-page">{page}</div>

          {page < numPages && (
            <div
              className="paginator-control"
              id={page + 1}
              onClick={() => {
                this.handleUpdate(page + 1)
              }}
            >
              {page + 1}
            </div>
          )}
          {page < numPages - 1 && (
            <div
              className="paginator-control"
              id={numPages}
              onClick={() => {
                this.handleUpdate(numPages)
              }}
            >
              ...{numPages}
            </div>
          )}
          <div className="paginator-control" onClick={this.nextPage}>
            Next
          </div>
        </div>
      )
    }

    return <div className="paginator" />
  }
}
export default Paginator
