import { GET_USER_STORE, UPDATE_ACTIVE_STORE } from '../_constants/toolsTypes.js'

const initState = {
  activeStoreId: -1,
  activeStoreName: '',
  activeStore: null,
  stores: [],
}

export function tools(state, action) {
  if (!state) {
    state = initState
  }
  switch (action.type) {
    case GET_USER_STORE:
      return {
        ...state,
        stores: [...action.stores],
      }
    case UPDATE_ACTIVE_STORE:
      return {
        ...state,
        activeStoreId: action.activeStoreId,
        activeStoreName: action.activeStoreName,
        activeStore: action.activeStore,
      }
    default:
      return state
  }
}
