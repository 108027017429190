// general: 1/3 of the tools component - child of tools - (2nd).

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import StoreSelect from './storeselect'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import cloneDeep from 'lodash/cloneDeep'
import DatePicker from 'react-datepicker'
import { addDays, subDays } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'

// core files
// --- actions
import ToolsActions, {
  getFinanceTablesNonCPO,
  getFinanceTablesCPO,
  putFinanceTablesCPO,
  putFinanceTablesNonCPO,
} from '../../../core/actions/features/toolsactions'
import ProfileActions from '../../../core/actions/users/profileactions'
// --- data
import AIPSeedsData from '../../../core/data/FinanceSeeds'
// images
import IconDealership from '../../../core/assets/images/icons/icon-dealership'
// helpers
import { ReactTableOptionsStatic } from '../../helpers/reusables'
import Paginator from '../../helpers/paginator'
import LoadingSpinner from '../../helpers/loadingspinner'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/tools.scss'

const hash = require('object-hash')

type Props = {
  DJtoken: string, // token to send to get the data.
  storeId: number, // sent for the store's data.
  userId: number,
  isAdmin: boolean,
  storeName: string,
}

class FinanceTables extends Component {
  constructor(props) {
    super(props)

    this.state = {
      results: [],
      editedResults: [],
      count: 0,
      page: 1,
      storeId: this.props.storeId,
      loading: true,
      editing: false,
      showCPO: true,
    }
    this.updatePage = this.updatePage.bind(this)
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    const { showCPO, page } = this.state
    const { storeId, DJtoken } = this.props
    if (storeId > -1) {
      this.getFinanceTables()
    }
  }
  componentDidUpdate(prevProps) {
    const { showCPO, page } = this.state
    const { storeId, DJtoken } = this.props
    if (prevProps.storeId !== storeId && storeId > -1) {
      this.getFinanceTables()
    }
  }
  getFinanceTables() {
    const { showCPO, page } = this.state
    const { storeId, DJtoken } = this.props
    this.setState({
      loading: true,
    })
    if (showCPO) {
      getFinanceTablesCPO(storeId, page, DJtoken).then(res => {
        if (res && res.results) {
          this.setState({
            results: res.results,
            editedResults: cloneDeep(res.results),
            count: res.count,
            loading: false,
          })
        } else {
          this.setState({
            results: [],
            editedResults: [],
            count: 0,
            loading: false,
          })
        }
      })
    } else {
      getFinanceTablesNonCPO(storeId, page, DJtoken).then(res => {
        // res.count = 4
        // res.results = [
        //   {
        //     company_store_id: 111,
        //     year: '2020',
        //     term_60: null,
        //     rate_60: null,
        //     term_60_80: null,
        //     rate_60_80: null,
        //     term_80_100: null,
        //     rate_80_100: null,
        //     term_100: null,
        //     rate_100: null,
        //   },
        //   {
        //     company_store_id: 111,
        //     year: '2017',
        //     term_60: 60,
        //     rate_60: '2.74',
        //     term_60_80: 60,
        //     rate_60_80: '2.99',
        //     term_80_100: 48,
        //     rate_80_100: '2.99',
        //     term_100: 48,
        //     rate_100: '3.99',
        //   },
        //   {
        //     company_store_id: 111,
        //     year: '2016',
        //     term_60: 60,
        //     rate_60: '2.74',
        //     term_60_80: 60,
        //     rate_60_80: '2.99',
        //     term_80_100: 48,
        //     rate_80_100: '2.99',
        //     term_100: 48,
        //     rate_100: '3.99',
        //   },
        //   {
        //     company_store_id: 111,
        //     year: '2015',
        //     term_60: 60,
        //     rate_60: '2.74',
        //     term_60_80: 60,
        //     rate_60_80: '2.99',
        //     term_80_100: 48,
        //     rate_80_100: '2.99',
        //     term_100: 48,
        //     rate_100: '2.99',
        //   },
        // ]
        if (res && res.results) {
          this.setState({
            results: res.results,
            editedResults: cloneDeep(res.results),
            count: res.count,
            loading: false,
          })
        } else {
          this.setState({
            results: [],
            editedResults: [],
            count: 0,
            loading: false,
          })
        }
      })
    }
  }
  putFinanceTables() {
    const { showCPO, page, results, editedResults } = this.state
    const { storeId, DJtoken } = this.props
    let defaultStartDate = new Date()
    let defaultEndDate = new Date()
    defaultStartDate = defaultStartDate.setDate(defaultStartDate.getDate() + 1)
    defaultEndDate = defaultEndDate.setDate(defaultEndDate.getDate() + 2)

    if (showCPO) {
      const updatedFields = []
      editedResults.map((entry, i) => {
        if (hash(results[i]) !== hash(entry)) {
          updatedFields.push({
            ...entry,
            begin_model_year: entry.begin_model_year
              ? entry.begin_model_year
              : `${moment().year() - 6}`,
            end_model_year: entry.end_model_year ? entry.end_model_year : `${moment().year()}`,
            month_24_36: entry.month_24_36 ? parseFloat(entry.month_24_36) : 2.99,
            month_37_48: entry.month_37_48 ? parseFloat(entry.month_37_48) : 2.99,
            month_49_66: entry.month_49_66 ? parseFloat(entry.month_49_66) : 2.99,
            effective_start_date: entry.effective_start_date
              ? entry.effective_start_date
              : moment(defaultStartDate).format('YYYY-MM-DD'),
            effective_end_date: entry.effective_end_date
              ? entry.effective_end_date
              : moment(defaultEndDate).format('YYYY-MM-DD'),
          })
        }
      })
      putFinanceTablesCPO(storeId, page, DJtoken, updatedFields).then(res => {
        console.log(res)
        this.setState(
          () => {
            return {
              results: [],
              editedResults: [],
              count: 0,
              page: 1,
              storeId: this.props.storeId,
              loading: true,
              editing: false,
            }
          },
          () => {
            this.getFinanceTables()
          },
        )
      })
    } else {
      const updatedFields = []
      editedResults.map((entry, i) => {
        if (hash(results[i]) !== hash(entry)) {
          updatedFields.push({
            ...entry,
            term_60: entry.term_60 ? parseInt(entry.term_60) : 60,
            rate_60: entry.rate_60 ? parseFloat(entry.rate_60) : 2.99,
            term_60_80: entry.term_60_80 ? parseInt(entry.term_60_80) : 60,
            rate_60_80: entry.rate_60_80 ? parseFloat(entry.rate_60_80) : 2.99,
            term_80_100: entry.term_80_100 ? parseInt(entry.term_80_100) : 60,
            rate_80_100: entry.rate_80_100 ? parseFloat(entry.rate_80_100) : 2.99,
            term_100: entry.term_100 ? parseInt(entry.term_100) : 60,
            rate_100: entry.rate_100 ? parseFloat(entry.rate_100) : 2.99,
          })
        }
      })
      putFinanceTablesNonCPO(storeId, page, DJtoken, updatedFields).then(res => {
        console.log(res)
        this.setState(
          () => {
            return {
              results: [],
              editedResults: [],
              count: 0,
              page: 1,
              storeId: this.props.storeId,
              loading: true,
              editing: false,
            }
          },
          () => {
            this.getFinanceTables()
          },
        )
      })
    }
  }

  emptyCheck(text) {
    return text ? text : 'N/A'
  }

  toggleCPO() {
    console.log('toggling')
    this.setState(
      prevState => {
        return {
          showCPO: !prevState.showCPO,
          results: [],
          editedResults: [],
          count: 0,
          loading: false,
        }
      },
      () => {
        this.getFinanceTables()
      },
    )
  }
  toggleEditing() {
    console.log('toggling')
    this.setState(prevState => {
      return { editing: !prevState.editing }
    })
  }
  renderEditToggle() {
    const { editing } = this.state
    return (
      <div className="finance-table-edit-toggle">
        <div className="privacySlide" style={{ animation: ` 1500ms fadeIn`, alignItems: 'center' }}>
          <span className="packetSendItalicStyle" style={{ fontSize: '1.0em' }}>
            Edit Mode
          </span>
          <label className="switch">
            <input
              onChange={() => {
                this.toggleEditing()
              }}
              type="checkbox"
              checked={editing}
            />
            <span className="slider round" />
          </label>
        </div>
      </div>
    )
  }
  renderFinanceTableSelectors() {
    const { showCPO } = this.state
    return (
      <div className="finance-table-selectors">
        <div
          className={`finance-table-selector ${showCPO ? 'finance-table-selector-active' : ''}`}
          onClick={() => {
            if (!showCPO) {
              this.toggleCPO()
            }
          }}
        >
          CPO
        </div>
        <div
          className={`finance-table-selector ${!showCPO ? 'finance-table-selector-active' : ''}`}
          onClick={() => {
            if (showCPO) {
              this.toggleCPO()
            }
          }}
        >
          Non-CPO
        </div>
      </div>
    )
  }
  updatePage(page) {
    this.setState(
      () => {
        return { page }
      },
      () => {
        this.getFinanceTables()
      },
    )
  }
  renderFinanceTableControls() {
    const { page, count } = this.state
    return (
      <div className="finance-table-controls">
        <Paginator page={page} updatePage={this.updatePage} numPages={Math.ceil(count / 15)} />
      </div>
    )
  }
  renderFinanceTableCPODate(i, startDateAccessor, endDateAccessor) {
    const { results, editedResults } = this.state
    let startDate = new Date()
    let endDate = new Date()

    if (editedResults[i][startDateAccessor]) {
      startDate = moment(editedResults[i][startDateAccessor], 'YYYY-MM-DD').toDate()
    } else {
      startDate.setDate(startDate.getDate() + 1)
    }
    if (editedResults[i][endDateAccessor]) {
      endDate = moment(editedResults[i][endDateAccessor], 'YYYY-MM-DD').toDate()
    } else {
      endDate.setDate(endDate.getDate() + 2)
    }
    return (
      <React.Fragment>
        <div className="finance-table-date-picker">
          <DatePicker
            selected={startDate}
            onChange={date => {
              editedResults[i][startDateAccessor] = moment(date).format('YYYY-MM-DD')
              this.setState({ editedResults })
            }}
            maxDate={subDays(endDate, 1)}
          />
        </div>
        <div className="finance-table-date-picker">
          <DatePicker
            selected={endDate}
            onChange={date => {
              editedResults[i][endDateAccessor] = moment(date).format('YYYY-MM-DD')
              this.setState({ editedResults })
            }}
            minDate={addDays(startDate, 1)}
          />
        </div>
      </React.Fragment>
    )
  }
  renderFinanceTableCPORate(i, accessor) {
    const { results, editedResults } = this.state
    return (
      <span>
        <input
          type="number"
          value={editedResults[i][accessor] ? editedResults[i][accessor] : 2.99}
          onChange={e => {
            editedResults[i][accessor] = e.target.value
            this.setState({ editedResults })
          }}
        />
        %
      </span>
    )
  }
  renderFinanceTableYearSlider(i) {
    const { results, editedResults } = this.state
    const endYear = parseInt(moment().year())
    const startYear = results[i].begin_model_year
      ? parseInt(results[i].begin_model_year)
      : endYear - 6

    return (
      <InputRange
        maxValue={endYear}
        minValue={2014}
        value={{
          min: editedResults[i].begin_model_year ? editedResults[i].begin_model_year : startYear,
          max: editedResults[i].end_model_year ? editedResults[i].end_model_year : endYear,
        }}
        onChange={value => {
          editedResults[i].begin_model_year = value.min
          editedResults[i].end_model_year = value.max
          this.setState({ editedResults })
        }}
      />
    )
  }
  renderFinanceTableNonCPOTermRate(i, term_accessor, rate_accessor) {
    const { results, editedResults } = this.state
    return (
      <React.Fragment>
        <select
          value={editedResults[i][term_accessor] ? editedResults[i][term_accessor] : '60'}
          onChange={e => {
            editedResults[i][term_accessor] = e.target.value
            this.setState({ editedResults })
          }}
        >
          <option key={`${i + term_accessor}36`} value="36">
            36
          </option>
          <option key={`${i + term_accessor}48`} value="48">
            48
          </option>
          <option key={`${i + term_accessor}54`} value="54">
            54
          </option>
          <option key={`${i + term_accessor}60`} value="60">
            60
          </option>
        </select>
        <input
          type="number"
          value={editedResults[i][rate_accessor] ? editedResults[i][rate_accessor] : 2.99}
          onChange={e => {
            editedResults[i][rate_accessor] = e.target.value
            this.setState({ editedResults })
          }}
        />
        %
      </React.Fragment>
    )
  }
  renderFinanceTableHeader() {
    const { showCPO, editing } = this.state
    if (showCPO) {
      return (
        <div
          className={`finance-table-header-row ${
            editing ? 'finance-table-row-cpo-edit' : 'finance-table-row-cpo'
          }`}
        >
          <div title="Make" className="finance-table-header">
            Make
          </div>
          <div title="Model" className="finance-table-header">
            Model
          </div>
          <div title="Model Years" className="finance-table-header">
            Model Years
          </div>
          <div title="24-36 Mo" className="finance-table-header">
            24-36 Mo
          </div>
          <div title="37-48 Mo" className="finance-table-header">
            37-48 Mo
          </div>
          <div title="49-66 Mo" className="finance-table-header">
            49-66 Mo
          </div>
          <div title="Valid Period" className="finance-table-header">
            Valid Period
          </div>
        </div>
      )
    }
    return (
      <div
        className={`finance-table-header-row ${
          editing ? 'finance-table-row-non-cpo-edit' : 'finance-table-row-non-cpo'
        }`}
      >
        <div title="Year" className="finance-table-header">
          Year
        </div>
        <div title="Under 60k mi" className="finance-table-header">
          Under 60k mi
        </div>
        <div title="60k- 80k mi" className="finance-table-header">
          60k- 80k mi
        </div>
        <div title="80k to 100k mi" className="finance-table-header">
          80k to 100k mi
        </div>
        <div title="Over 100k mi" className="finance-table-header">
          Over 100k mi
        </div>
      </div>
    )
  }
  renderFinanceTableRows() {
    const { showCPO, editing, results } = this.state
    const { emptyCheck } = this
    if (showCPO) {
      if (editing) {
        return (
          <React.Fragment>
            {results.map((entry, i) => {
              return (
                <div style={{ animation: ` 1500ms fadeIn` }}>
                  <div
                    className="finance-table-row finance-table-row-cpo-edit"
                    style={{ animation: `slideInUp 250ms ${i * 15}ms` }}
                  >
                    <div title={emptyCheck(entry.make)} className="finance-table-row-item">
                      {emptyCheck(entry.make)}
                    </div>
                    <div title={emptyCheck(entry.model)} className="finance-table-row-item">
                      {emptyCheck(entry.model)}
                    </div>
                    <div className="finance-table-row-item">
                      {this.renderFinanceTableYearSlider(i)}
                    </div>
                    <div className="finance-table-row-item">
                      {this.renderFinanceTableCPORate(i, 'month_24_36')}
                    </div>
                    <div className="finance-table-row-item">
                      {this.renderFinanceTableCPORate(i, 'month_37_48')}
                    </div>
                    <div className="finance-table-row-item">
                      {this.renderFinanceTableCPORate(i, 'month_49_66')}
                    </div>
                    <div className="finance-table-row-item">
                      {this.renderFinanceTableCPODate(
                        i,
                        'effective_start_date',
                        'effective_end_date',
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          {results.map((entry, i) => {
            return (
              <div style={{ animation: ` 1500ms fadeIn` }}>
                <div
                  className="finance-table-row finance-table-row-cpo"
                  style={{ animation: `slideInUp 250ms ${i * 15}ms` }}
                >
                  <div title={emptyCheck(entry.make)} className="finance-table-row-item">
                    {emptyCheck(entry.make)}
                  </div>
                  <div title={emptyCheck(entry.model)} className="finance-table-row-item">
                    {emptyCheck(entry.model)}
                  </div>
                  <div
                    title={`${emptyCheck(entry.begin_model_year)} - ${emptyCheck(
                      entry.end_model_year,
                    )}`}
                    className="finance-table-row-item"
                  >
                    {`${emptyCheck(entry.begin_model_year)} - ${emptyCheck(entry.end_model_year)}`}
                  </div>
                  <div title={emptyCheck(entry.month_24_36)} className="finance-table-row-item">
                    {emptyCheck(entry.month_24_36)}
                  </div>
                  <div title={emptyCheck(entry.month_37_48)} className="finance-table-row-item">
                    {emptyCheck(entry.month_37_48)}
                  </div>
                  <div title={emptyCheck(entry.month_49_66)} className="finance-table-row-item">
                    {emptyCheck(entry.month_49_66)}
                  </div>
                  <div
                    title={`${emptyCheck(entry.effective_start_date)} - ${emptyCheck(
                      entry.effective_end_date,
                    )}`}
                    className="finance-table-row-item"
                  >
                    {`
                        ${emptyCheck(
                          entry.effective_start_date
                            ? moment(entry.effective_start_date).format('L')
                            : '',
                        )} -
                        ${emptyCheck(
                          entry.effective_end_date
                            ? moment(entry.effective_end_date).format('L')
                            : '',
                        )}
                      `}
                  </div>
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    }
    if (editing) {
      return (
        <React.Fragment>
          {results.map((entry, i) => {
            return (
              <div style={{ animation: ` 1500ms fadeIn` }}>
                <div
                  className="finance-table-row finance-table-row-non-cpo-edit"
                  style={{ animation: `slideInUp 250ms ${i * 15}ms` }}
                >
                  <div title={entry.year} className="finance-table-row-item">
                    {entry.year}
                  </div>
                  <div className="finance-table-row-item">
                    {this.renderFinanceTableNonCPOTermRate(i, 'term_60', 'rate_60')}
                  </div>
                  <div className="finance-table-row-item">
                    {this.renderFinanceTableNonCPOTermRate(i, 'term_60_80', 'rate_60_80')}
                  </div>
                  <div className="finance-table-row-item">
                    {this.renderFinanceTableNonCPOTermRate(i, 'term_80_100', 'rate_80_100')}
                  </div>
                  <div className="finance-table-row-item">
                    {this.renderFinanceTableNonCPOTermRate(i, 'term_100', 'rate_100')}
                  </div>
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {results.map((entry, i) => {
          return (
            <div style={{ animation: ` 1500ms fadeIn` }}>
              <div
                className="finance-table-row finance-table-row-non-cpo"
                style={{ animation: `slideInUp 250ms ${i * 15}ms` }}
              >
                <div title={entry.year} className="finance-table-row-item">
                  {entry.year}
                </div>
                <div
                  title={`${emptyCheck(entry.term_60)} months at ${emptyCheck(entry.rate_60)}%`}
                  className="finance-table-row-item"
                >
                  {`${emptyCheck(entry.term_60)} months at ${emptyCheck(entry.rate_60)}%`}
                </div>
                <div
                  title={`${emptyCheck(entry.term_60_80)} months at ${emptyCheck(
                    entry.rate_60_80,
                  )}%`}
                  className="finance-table-row-item"
                >
                  {`${emptyCheck(entry.term_60_80)} months at ${emptyCheck(entry.rate_60_80)}%`}
                </div>
                <div
                  title={`${emptyCheck(entry.term_80_100)} months at ${emptyCheck(
                    entry.rate_80_100,
                  )}%`}
                  className="finance-table-row-item"
                >
                  {`${emptyCheck(entry.term_80_100)} months at ${emptyCheck(entry.rate_80_100)}%`}
                </div>
                <div
                  title={`${emptyCheck(entry.term_100)} months at ${emptyCheck(entry.rate_100)}%`}
                  className="finance-table-row-item"
                >
                  {`${emptyCheck(entry.term_100)} months at ${emptyCheck(entry.rate_100)}%`}
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }
  renderFinanceTable() {
    const { showCPO, editing, results, loading } = this.state
    console.log(this.state)
    if (results && results.length) {
      return (
        <div className="finance-table" style={{ animation: ` 1500ms fadeIn` }}>
          {this.renderFinanceTableHeader()}
          {this.renderFinanceTableRows()}
        </div>
      )
    } else if (loading) {
      return (
        <div className="finance-table">
          <LoadingSpinner loading={true} />
        </div>
      )
    }
    return (
      <div className="finance-table" style={{ animation: ` 1500ms fadeIn` }}>
        <div className="finance-table-no-results">No Results Found</div>
      </div>
    )
  }
  renderUpdateButton() {
    const { editing, results, editedResults } = this.state
    let fieldChanged = false
    if (hash(results) !== hash(editedResults)) {
      fieldChanged = true
    }
    if (editing && results.length) {
      return (
        <div className="finance-table-update-button-container">
          <button
            onClick={() => {
              this.putFinanceTables()
            }}
            className="finance-table-update-button"
            disabled={!fieldChanged}
          >
            Update Rates
          </button>
        </div>
      )
    }
  }
  render() {
    const cpoBoolean =
      this.state.financetablesAPIType === 'cpo' || this.state.financetablesAPIType === 'cpo-edit'
    return (
      <div className="finance-table-container">
        {this.renderUpdateButton()}
        <div className="finance-table-title" style={{ padding: '0 20px' }}>
          <h1>Finance Tables</h1>
        </div>
        <div className="finance-table-store-select">
          <StoreSelect />
        </div>
        {this.renderFinanceTableSelectors()}
        {this.state.count > 0 ? this.renderEditToggle() : null}
        {this.renderFinanceTableControls()}
        {this.renderFinanceTable()}
        {this.renderFinanceTableControls()}
        {this.renderUpdateButton()}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  storeId: state.tools.activeStoreId,
})
export default connect(mapStateToProps, null)(FinanceTables)
