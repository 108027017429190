import React from 'react'
import IPacketIcon from '../core/assets/images/icons/iPacketIcon'
import './styles/universalstyles.scss'
import './styles/whitescreenError.scss'

const WhitescreenError = ({ error }) => {
  function logout() {
    localStorage.removeItem('user')
    window.location.reload()
  }

  return (
    <div className="error-container">
      <IPacketIcon />
      <span className="error-text">{error.message}</span>
      <span className="error-link" onClick={() => logout()}>
        Click Here to Return to Login
      </span>
    </div>
  )
}

export default WhitescreenError
