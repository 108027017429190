// node modules
import React from 'react'
import { useSelector } from 'react-redux'
// Components
import TabNav from './components/tabnav'
import ChangeStore from './components/changestore'

const Search = () => {
  const isSuperUser = useSelector(state => state.authentication.user.user.is_staff)
  return (
    <React.Fragment>
      <div>
        <TabNav />
        <div className="page-content">
          <div className="staff-tools">
            <ChangeStore isSuperUser={isSuperUser} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Search
