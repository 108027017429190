import React, { Component } from 'react'
import Chart from 'chart.js'
import Helper from '../../../core/helpers/platformhelper'

export default class PriceHistoryChart extends Component {
  chartRef = React.createRef()

  componentDidMount() {
    const { priceHistoryObj, aggregate_data } = this.props
    const myChartRef = this.chartRef.current.getContext('2d')

    /* Data Handling */

    let xDates = []
    let yPrices = []
    let highestPrice = 0

    for (const item in priceHistoryObj) {
      xDates.push(Helper.FormatDateUSA(priceHistoryObj[item].date_changed))
      yPrices.push(priceHistoryObj[item].price)
      if (priceHistoryObj[item].price > highestPrice) {
        highestPrice = priceHistoryObj[item].price
      }
    }

    console.log(xDates)
    console.log(yPrices)
    console.log(highestPrice)

    new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: xDates,
        datasets: [
          {
            data: yPrices,
            backgroundColor: ['#ffffff', '#ffffff'],
            borderColor: ['#ffffff'],
            fill: false,
            lineTension: 0,
            pointRadius: 0,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 10,
            right: 25,
            left: 17,
            bottom: 8,
          },
        },
        tooltips: {
          custom: function(tooltip) {
            if (!tooltip) return
            // disable displaying the color box;
            tooltip.displayColors = false
          },
          callbacks: {
            // use label callback to return the desired label
            label: function(tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index]
              if (parseInt(value) >= 1000) {
                return (
                  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                  ' on ' +
                  tooltipItem.xLabel
                )
              } else {
                return value + ' on ' + tooltipItem.xLabel
              }
            },
            // remove title
            title: function(tooltipItem, data) {
              return
            },
          },
          intersect: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
                unitStepSize: 1,
                displayFormats: {
                  month: 'M/YY',
                },
              },
              scaleLabel: {
                display: false,
                labelString: 'TIME',
                fontColor: ['#ffffff'],
                fontStyle: 'bold',
              },
              ticks: {
                display: true,
                fontColor: ['#ffffff'],
                padding: 10,
              },
              gridLines: {
                color: '$transparent-white-10',
                tickMarkLength: 0,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: false,
                labelString: 'PRICE',
                fontColor: ['#ffffff'],
                fontStyle: 'bold',
              },
              ticks: {
                display: true,
                fontColor: ['#ffffff'],
                suggestedMin: 0,
                suggestedMax: highestPrice,
                padding: 10,
                userCallback: function(value, index, values) {
                  value = value.toString()
                  value = value.split(/(?=(?:...)*$)/)
                  value = value.join(',')
                  return value
                },
              },
              gridLines: {
                color: '$transparent-white-10',
                tickMarkLength: 0,
              },
            },
          ],
        },
      },
    })
  }
  render() {
    return (
      <div>
        <canvas id="priceHistoryChart" ref={this.chartRef} />
      </div>
    )
  }
}
