import React from 'react'

const TrainingIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <ellipse cx="11.15" cy="10.21" rx="5.15" ry="4.89" />
      <path d="M27,18.27H10A3.88,3.88,0,0,0,6,22v5.83a2.13,2.13,0,0,0,0,.25s0,.08,0,.13V43.15a1.58,1.58,0,0,0,1.61,1.54H8a1.58,1.58,0,0,0,1.61-1.54c.27-3.84.54-7.11.81-11a.77.77,0,0,1,.75-.57.74.74,0,0,1,.73.57c.35,3.85.69,7.14,1,11a1.59,1.59,0,0,0,1.64,1.5H15a1.57,1.57,0,0,0,1.58-1.56l-.3-14.91s0-.08,0-.12,0-.14,0-.22V23.51a2.29,2.29,0,0,1,1.93-1.8H27a1.57,1.57,0,0,0,1.61-1.53V19.8A1.58,1.58,0,0,0,27,18.27Z" />
      <path
        className="b"
        d="M42.32,8.06h-12a1.81,1.81,0,0,0-3.61,0L20,8v3.28l18.74,0A1.66,1.66,0,0,1,40.47,13V26.13a1.66,1.66,0,0,1-1.7,1.61H20V31H22.6L19.72,42.1A1.71,1.71,0,0,0,21,44.16l.13,0a1.82,1.82,0,0,0,2.18-1.26l3-11.76a1.81,1.81,0,0,0,0-.23h4.1c0,.05,0,.1,0,.15l3,11.76a1.81,1.81,0,0,0,2.17,1.25l.14,0A1.72,1.72,0,0,0,37.21,42L34.35,31h8A1.67,1.67,0,0,0,44,29.34V9.67A1.67,1.67,0,0,0,42.32,8.06Z"
      />
    </svg>
  )
}
export default TrainingIcon
