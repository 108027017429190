// general:

// @flow
import React, { Component } from 'react'
// node packages
// --- redux
import { connect } from 'react-redux'
// core files
// --- helpers
import Helper from '../../../../core/helpers/platformhelper'
// component children
import DescriptionChild from './children/descriptionchild'
import ImagesChild from './children/imageschild'
// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'

// global(s)
// --- mobile
const isMobile = Helper.CheckMobileScreenOrientation()

type Props = {
  DJtoken: string,
  stashed_content: Object,
}

class SendView extends Component {
  constructor(props: Props) {
    super(props)
    this.state = { packet_info: null }
  }

  state: { packet_info: Object }

  // componentDidMount
  // - set mount to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountCheckStash()
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ packet_info: null })
  }

  //
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountCheckStash
  onMountCheckStash() {
    const stash = this.props.stashed_content
    this.setState({ packet_info: stash.vehicle_info })
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  props: Props

  render() {
    // props/states
    const { DJtoken } = this.props
    const { packet_info } = this.state

    if (packet_info !== null && packet_info !== undefined) {
      const trimSuffix =
        packet_info.trim != undefined && packet_info.trim.length > 0 ? ' ' + packet_info.trim : ''
      // styles
      let width = '50%'
      let color = '#4d4d4d'

      console.log(this.props.darkModeState)

      if (this.props.darkModeState == true) {
        color = 'white'
      }

      if (isMobile) {
        width = '100%'
      }

      const inlineOverTakeRowSpaceStyle = {
        alignItems: 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
      }

      return (
        <span>
          <div style={inlineOverTakeRowSpaceStyle}>
            <div className="packetSendMiddleInnerSideStyle left">
              <ImagesChild DJtoken={DJtoken} vehicleId={packet_info.vehicle_id} />
            </div>

            <div className="packetSendMiddleInnerSideStyle right">
              <div className="packetSendTitleStyle">
                {packet_info.year} {packet_info.make} {packet_info.model} {trimSuffix}
              </div>
              <h2>
                {packet_info.vehicle_condition_label ? packet_info.vehicle_condition_label : null}
              </h2>
              <DescriptionChild DJtoken={DJtoken} />
            </div>

            <div />
          </div>
        </span>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  stashed_content: state.packetstash,
  darkModeState: state.userSettings.dark_mode.enabled,
})
export default connect(mapStateToProps, {})(SendView)
