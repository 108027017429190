// general: action file for all things 'reports' data being passed back and forth.

// API(s)
import {
  ReportsActivityListGetAPIURL,
  ReportsActivityGetAPIURL,
  ReportsStatusGetAPIURL,
  ReportsUploadGetAPIURL,
  ReportsWebButtonAPIURL,
  ReportsActivityGenerateMonthly,
  ReportsActivityGenerateDateRange,
  getListOfAvailableUsageReportTypes,
  getCustomReportUserHistory,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

export default {
  // REPORTS - API - STATUS
  getReportsStatus: (numORurl: string, token: string, search: string, storeId: number) => {
    // v1/reports/vehicle-module-status-report/{store_id}

    let pathParameters = `${storeId}?page=${numORurl}&items_per_page=15`

    if (search !== null && search !== undefined && search !== '') {
      pathParameters += `&search=${search}`
    }

    if (numORurl !== '1') {
      pathParameters = numORurl
    }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsStatusGetAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getReportsActivityDownload: (type: string, token: string, activityReportId: number) => {
    // v1/reports/activity-reports/{activity_report_id}/{type}
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsActivityGetAPIURL}${activityReportId}/${type}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  //
  //
  //
  // REPORTS - API - USER UPLOAD
  getReportsUpload: (token: string, storeId: number) => {
    // v1/reports/user-upload-report/{store_id}
    let headers = {
      Authorization: `Token ${token}`,
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsUploadGetAPIURL}${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.text()
    })
  },
}
function getActivityReports(token: string, storeId: number, page: number) {
  const pathParameters = `${storeId}?page=${page}&items_per_page=15`
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsActivityListGetAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getReportsActivityDownload(type: string, token: string, activityReportId: number) {
  // v1/reports/activity-reports/{activity_report_id}/{type}
  let headers = {
    Authorization: `Token ${token}`,
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsActivityGetAPIURL}${activityReportId}/${type}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.text()
  })
}
function getStatusReports(token: string, storeId: number, page: number, signal: Object) {
  const pathParameters = `${storeId}?page=${page}&items_per_page=15`
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsStatusGetAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getWebButtonCSVReports(token, lot_id, page) {
  const pathParameters = `${lot_id}`
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.ReportsWebButtonAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function generateMonthlyActivityReports(token, storeId) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${ReportsActivityGenerateMonthly}${storeId}/`, {
    headers,
  })
}

function getAvailableReportTypes(token, storeId) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${getListOfAvailableUsageReportTypes}`, {
    headers,
  })
}

function getCustomReportRequestHistory(token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${getCustomReportUserHistory}`, {
    headers,
  })
}

function generateCustomActivityReport(token, storeId, reportType, startDate, endDate, reportName) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${ReportsActivityGenerateDateRange}${reportType}/`, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      jwt_payload: {
        object_ids: [storeId],
        start_date: startDate,
        end_date: endDate,
        label: reportName,
      },
    }),
  })
}
function generateExecutiveReport(token, reportInfo) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.ReportsCompanyUserPacketShare}${reportInfo.company_id}/?start_date=${reportInfo.start_date}&end_date=${reportInfo.end_date}`,
    {
      headers,
    },
  )
}
export {
  getActivityReports,
  getReportsActivityDownload,
  getStatusReports,
  getWebButtonCSVReports,
  generateMonthlyActivityReports,
  getAvailableReportTypes,
  generateCustomActivityReport,
  getCustomReportRequestHistory,
  generateExecutiveReport,
}
