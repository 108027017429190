// general
import React, { Component, useState } from 'react'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

// --- actions
import PacketActions from '../../../../core/actions/packets/packetactions'

// css styles
import '../../../../../web/content/styles/universalstyles.scss'
import '../../../../content/packetview/styles/packetsend.scss'

//SLIDESHOW STYLE 1 IS THE IMMEDIATE CHANGE OF IMAGE
//SLIDESHOW STYLE 2 IS THE SLIDING EFFECT

class VehicleSlideshow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      packet_images: [],
      index: 0,
      slideshowActive: false,
    }
    //Binding for slideshow style 1
    this.changeImage = this.changeImage.bind(this)
    this.resetImage = this.resetImage.bind(this)
  }

  //Slideshow style 1 functions
  //Change image index every 3 seconds and change state for classname change
  changeImage() {
    let { vehicleID } = this.props
    let { packet_images, index, slideshowActive } = this.state
    if (slideshowActive) {
      if (vehicleID && packet_images.length === 0) {
        this.interval = setTimeout(() => {
          this.getPacketImages(vehicleID)
        }, 500)
      } else if (packet_images.length === 1) {
        this.setState({ index: 0 })
      } else {
        const vehicleId = this.props.vehicleID
        this.interval = setTimeout(() => {
          if (index < packet_images.length) {
            this.setState({ index: this.state.index + 1 }, () => {
              this.changeImage()
            })
          } else {
            this.setState({ index: 0 }, () => {
              this.changeImage()
            })
          }
        }, 2000)
      }
    }
  }

  //Reset slideshow to index 0 and clear interval
  resetImage() {
    console.log('Reset Image Called')
    clearTimeout(this.interval)
    this.setState({ index: 0 })
  }

  async getPacketImages() {
    const vehicleId = this.props.vehicleID
    if (vehicleId !== null && vehicleId !== undefined) {
      const token = this.props.userInfo.dj_token
      if (token !== null && token !== undefined) {
        const d = await PacketActions.getPacketSendImages(vehicleId, token)
        this.setState({ packet_images: d.packet_images }, () => {
          this.changeImage()
        })
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
    this.setState({
      packet_images: [],
      index: 0,
    })
  }

  render() {
    //React Transition Group Slideshow style
    return (
      <TransitionGroup
        className="slideshow-transition"
        onMouseEnter={() => {
          this.setState({ slideshowActive: true }, () => {
            this.changeImage()
          })
        }}
        onMouseLeave={() => {
          this.setState({ slideshowActive: false }, () => {
            this.resetImage()
          })
        }}
      >
        <CSSTransition key={this.state.index} classNames="fade" timeout={2000}>
          <img src={this.state.packet_images[this.state.index]} />
        </CSSTransition>
      </TransitionGroup>
    )
  }
}

export default VehicleSlideshow
