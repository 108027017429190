import React from 'react'

const TopDash = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        className="a"
        d="M39.21,15.81a1.67,1.67,0,0,0,.37-1,1.68,1.68,0,0,0-2.75-1.31h0L22.19,23.62h0a5,5,0,1,0,7,6.6l0,0Z"
      />
      <path d="M12.49,38.58,13.68,38a1.61,1.61,0,0,0,.72-2.15l-.15-.31a1.61,1.61,0,0,0-2.15-.72l-1.7.84A16.71,16.71,0,0,1,9.25,21.8l.18.11,1.89.94a1.61,1.61,0,0,0,2.15-.72l.15-.3a1.61,1.61,0,0,0-.72-2.16L11,18.74l-.24-.1a16.8,16.8,0,0,1,12.57-7.93,1.84,1.84,0,0,0,0,.33v2.11a1.61,1.61,0,0,0,1.61,1.6h.19a1.6,1.6,0,0,0,1.6-1.6V11a1.87,1.87,0,0,0,0-.34,16.42,16.42,0,0,1,6.21,1.85,2.89,2.89,0,0,0,3-.15h0a1.17,1.17,0,0,0,0-1.94,20.31,20.31,0,0,0-27.34,29,1.14,1.14,0,0,0,1.43.34Z" />
      <path d="M42.21,16.83a1.1,1.1,0,0,0-1.84,0h0A3.1,3.1,0,0,0,40.18,20a16.78,16.78,0,0,1-.44,15.75l-1.86-.92a1.61,1.61,0,0,0-2.15.72l-.15.3A1.61,1.61,0,0,0,36.3,38l1.33.66L40,39.8a1.21,1.21,0,0,0,1.49-.36A20,20,0,0,0,45.18,30,20.29,20.29,0,0,0,42.21,16.83Z" />
    </svg>
  )
}
export default TopDash
