import React from 'react'

const ArrowDownIcon = props => {
  return (
    <svg className="arrow-down-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50.03">
      <polygon points="49.78 16.15 42.65 9.02 25.08 26.58 7.51 9.02 0.22 16.3 17.79 33.87 17.78 33.88 24.91 41.02 24.92 41 24.93 41.02 32.22 33.73 32.21 33.72 49.78 16.15" />
    </svg>
  )
}
export default ArrowDownIcon
