import React, { Component } from 'react'
import ls from 'local-storage'
import { connect } from 'react-redux'
import QRCode from 'qrcode.react'
import ReactToPrint from 'react-to-print'
import { hideModal } from '../../../core/_actions/modalActions'
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import Modal from '../../layouts/modal'
import QRCodePrint from '../../packetview/columns/components/qrcodeprint'

import QRIcon from '../../../core/assets/images/icons/qr-icon'
import AIPRingLogo from '../../../core/assets/images/AutoiPacketYloRingDrk.png'

import '../styles/reportissuemodal.scss'
import '../styles/resizeqrmodal.scss'

type Props = {
  vehicle: Object,
  type: string,
}

class ResizeQRModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      size: 270,
      vehicle: this.props.vehicle,
      type: this.props.type,
    }
  }

  // Check local storage has QRCode size or no.
  componentDidMount() {
    if (JSON.parse(ls('QRCodeSize')) != null) {
      this.setState({ size: JSON.parse(ls('QRCodeSize')) })
    }
  }

  // Function to get QRCodeSize from the localStorage.
  GetLocalStorageQRCSize() {
    const QRCSize = JSON.parse(ls('QRCodeSize'))
    if (QRCSize === null) {
      return 270
    }
    return QRCSize
  }

  handleChange(newSize) {
    this.setState({ size: newSize })
    // add newSize to local storage
    ls.set('QRCodeSize', JSON.stringify(newSize))
  }

  render() {
    const { size, type, vehicle } = this.state

    let url = `https://www.ipacket.info/`
    if (window.location.hostname !== 'dpapp.autoipacket.com') {
      url = 'http://dev.carpacket.info/'
    }
    url += vehicle.vin
    url += '?source=aipdpqrc'

    const renderSimpleQR = () => {
      return (
        <QRCode
          size={Math.abs(parseInt(size) / 5.75)}
          imageSettings={{
            src: AIPRingLogo,
            x: null,
            y: null,
            height: 0,
            width: 0,
            excavate: true,
            alt: 'qr-print-code',
          }}
          value={url}
          alt="qr-print-code"
        />
      )
    }

    const renderWindowStickerQR = () => {
      return (
        <div className={`window-sticker-resize-container ws-${size}`}>
          <div className="text-placeholder first" />
          <div className="text-placeholder second" />
          <QRCode
            size={Math.abs(parseInt(size) / 9)}
            imageSettings={{
              src: AIPRingLogo,
              x: null,
              y: null,
              height: 0,
              width: 0,
              excavate: true,
              alt: 'qr-print-code',
            }}
            value={url}
            alt="qr-print-code"
          />
        </div>
      )
    }

    return (
      <Modal>
        <div>
          <div className="report-issue-container">
            <h1>Resize QR Code</h1>
            <div className="resize-qr-page-background">
              {type == 'simple' ? renderSimpleQR() : renderWindowStickerQR()}
            </div>
            <div className="report-issue-field resize-slider">
              <input
                type="range"
                min={270}
                max={810}
                onChange={e => this.handleChange(e.target.value)}
                defaultValue={this.GetLocalStorageQRCSize()}
                step={135}
              />
              <div className="sizing-guide">
                <span>2"</span>
                <span>3"</span>
                <span>4"</span>
                <span>5"</span>
                <span>6"</span>
              </div>
            </div>
            <ReactToPrint
              trigger={() => {
                return (
                  <div className="packetSendButtonsStyle print-simple-btn">
                    <span>
                      <QRIcon />
                      {type == 'simple' ? 'Print Simple QR' : 'Print Window Sticker'}
                    </span>
                  </div>
                )
              }}
              content={() =>
                type == 'simple' ? this.printSimpleQRCodeRef : this.printWindowStickerQRCodeRef
              }
            />
            <div style={{ display: 'none' }}>
              <QRCodePrint
                type={type}
                size={parseInt(size)}
                vehicle={vehicle}
                ref={el =>
                  type == 'simple'
                    ? (this.printSimpleQRCodeRef = el)
                    : (this.printWindowStickerQRCodeRef = el)
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  size: state.modal.props.size,
  vehicle: state.modal.props.vehicle.vehicle_info,
  type: state.modal.props.type,
})
const ResizeQRModal = UserInfoWrapper(ResizeQRModalComponent)

export default connect(mapStateToProps, {
  hideModalConnect: hideModal,
})(ResizeQRModal)
