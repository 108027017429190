// constants for the emailTemplates reducer

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const EDIT_EMAIL_TEMPLATE = 'EDIT_EMAIL_TEMPLATE'
export const REMOVE_EMAIL_TEMPLATE = 'REMOVE_EMAIL_TEMPLATE'

export const GET_COMPANY_TEMPLATES = 'GET_COMPANY_TEMPLATES'
export const GET_STORE_TEMPLATES = 'GET_STORE_TEMPLATES'
export const GET_USER_TEMPLATES = 'GET_USER_TEMPLATES'

export const CHANGE_TEMPLATE_TYPE = 'CHANGE_TEMPLATE_TYPE'

export const SELECT_EMAIL_TEMPLATE = 'SELECT_EMAIL_TEMPLATE'
