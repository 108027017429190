// general: 1/6 of the admin settings component - parent - (1st).

// @flow
import React, { Component } from 'react'
// redux
import { connect } from 'react-redux'
// node packages
import { NavLink } from 'react-router-dom'
// core files
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// components
import Overview from './components/overview'
import TeamMembers from './components/teammembers'
import Reporting from './components/reporting'
import EmailTemplates from './components/emailTemplates'
import ChangeStore from '../layouts/changestoredropdown'
import StoreSelect from '../tools/components/storeselect'
import DealershipIcon from '../../core/assets/images/icons/dealership-icon'

// css styles
import '../styles/universalstyles.scss'
import '../login/styles/login.scss'

type Props = {
  userInfo: Object,
}

export class AdminSettingsComponent extends Component {
  constructor(props: Props) {
    super(props)
  }

  // GetAccountSettings
  GetAccountSettings() {
    // states
    const { userInfo } = this.props
    return (
      <div style={{ animation: ` 1500ms fadeIn` }}>
        {/* {console.log(userInfo)} */}
        <div className="loc-select-box admin-page" align="center">
          <div className="loc-select-header">
            <DealershipIcon />
            <span className="box-label">Selected Location:</span>
          </div>
          <StoreSelect />
        </div>

        {this.AdminSettingsViewCollection()}

        {this.AdminSettingsViewSwitcher()}
      </div>
    )
  }

  // ***********************LETS*SETUP*CONTAINERS***********************
  // AdminSettingsViewColleciton
  AdminSettingsViewCollection() {
    // states
    const { userInfo } = this.props

    if (userInfo && userInfo.user_id !== null && userInfo.user_id !== undefined) {
      return (
        <div className="subnav-bar">
          <div className="subnav-content">
            <div className="subnav-center">
              <NavLink to={`/adminsettings/basics`}>Basics</NavLink>

              <NavLink to={`/adminsettings/teammembers`}>Team</NavLink>

              <NavLink to={`/adminsettings/emailtemplates`}>Email Templates</NavLink>

              <NavLink to={`/adminsettings/reporting`}>Reporting</NavLink>
            </div>
          </div>
        </div>
      )
    }
  }

  // AdminSettingsViewSwitcher
  AdminSettingsViewSwitcher() {
    // states
    const { userInfo, activeStoreId } = this.props

    // get page focus
    // eslint-disable-next-line no-undef
    const pagefocusFirstHalf = window.location.href.split('adminsettings/')[1]
    // eslint-disable-next-line no-undef
    const pageOn = pagefocusFirstHalf

    // variables
    let adminSettingsView = <Overview DJtoken={userInfo.dj_token} storeId={activeStoreId} />

    if (pageOn === 'teammembers') {
      adminSettingsView = (
        <TeamMembers
          userId={userInfo.user_id}
          DJtoken={userInfo.dj_token}
          storeId={activeStoreId}
        />
      )
    } else if (pageOn === 'reporting') {
      adminSettingsView = <Reporting DJtoken={userInfo.dj_token} storeId={activeStoreId} />
    } else if (pageOn === 'emailtemplates') {
      adminSettingsView = <EmailTemplates />
    }

    return adminSettingsView
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    // states
    const { userInfo } = this.props
    if (userInfo && userInfo.user_id !== null && userInfo.user_id !== undefined) {
      return this.GetAccountSettings()
    }
    return <React.Fragment />
  }
}
const mapStateToProps = state => ({
  defaultStore: state.authentication.user.user.profile.default_company_store,
  activeStore: state.tools.activeStore,
  activeStoreId: state.tools.activeStoreId,
  stores: state.tools.stores,
  isAdmin: state.authentication.userInfo.is_admin,
  token: state.userSettings.settings && state.userSettings.settings.dj_token,
  userId: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  scheduledPackets: state.packetstash.scheduled_packets,
})
export default connect(mapStateToProps, null)(UserInfoWrapper(AdminSettingsComponent))
