import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
// redux
import { useSelector, useDispatch } from 'react-redux'
import { onChangeTimeInterval } from '../../../core/_actions/reactadminactions'

const TimeSwitcher = ({ isLoading }) => {
  // set initial dates to null for datepicker custom setup
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isCustom, setIsCustom] = useState(false)
  const dispatch = useDispatch()
  const timePeriod = useSelector(state => state.reactAdmin.title)
  /**
   * Creates a button container based on title
   * @param {string} buttonTitle text of button and redux interval
   */
  function textContainer(buttonTitle) {
    return (
      <button
        key={buttonTitle}
        className={`statistics-button ${buttonTitle === timePeriod ? 'selected' : ''}`}
        onClick={() => dispatch(onChangeTimeInterval(buttonTitle))}
        disabled={isLoading}
      >
        {buttonTitle}
      </button>
    )
  }

  const timeButtonTitles = ['Last Week', 'Last Month', 'Last Year', 'All Time']
  return (
    <div className="time-buttons">
      {timeButtonTitles.map(title => textContainer(title, 'time'))}
      <button
        className={`statistics-button ${isCustom || timePeriod === 'Custom' ? 'selected' : ''}`}
        onClick={() => setIsCustom(!isCustom)}
        disabled={isLoading}
      >
        Custom
      </button>
      {isCustom ? (
        <div className="picker-containers">
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            className="datePicker"
            // the start date must be at least today
            maxDate={new Date()}
            placeholderText="Select start date"
          />
          {/* Only show end date picker if first date has been selected */}
          {startDate ? (
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              className="datePicker"
              // latest date is today , and the selected date must be at least the start date
              minDate={startDate}
              maxDate={new Date()}
              placeholderText="Select end date"
            />
          ) : null}
          <button
            className="healthTimeSubmitButton"
            onClick={() => dispatch(onChangeTimeInterval('Custom', startDate, endDate))}
          >
            Submit
          </button>
        </div>
      ) : null}
    </div>
  )
}
export default TimeSwitcher

TimeSwitcher.propTypes = {
  isLoading: PropTypes.bool,
}
