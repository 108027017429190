// general:

// constants
import {
  STASH_VEHICLE_IDS,
  STASH_VEHICLE_IMAGES,
  STASH_RECIPIENTS,
  STASH_MESSAGE,
  STASH_MODULES,
  STASH_PRICE,
  STASH_ATTACHMENTS,
  STASH_LEADS,
  IS_CREATING_CUSTOMER,
  UPDATE_CUSTOMERS_TO_SEND,
  STASH_SINGLE_MODULE,
  STASH_DEFAULT_STORE_ID,
  STASH_MODULE_VIDEOS,
  STASH_DELETE_MODAL,
  STASH_SCHEDULED_PACKETS,
  STASH_PREVIOUS_ATTACHMENTS,
  STASH_IMG_SRC,
  EMPTY_PREVIOUS_ATTACHMENTS,
  STASH_COVIDEO,
  EMPTY_COVIDEO,
} from '../_constants/packetviewTypes'
import { COVIDEO_LOGOUT } from '../_constants/covideoTypes'

const initState = {
  vehicle_ids: [],
  vehicle_info: null,

  vehicle_images: [],
  vehicle_image_thumb: null,

  price: true,

  recipient_ids: [],
  recipient_info: [],

  message: '',

  module_ids: [],
  module_info: [],
  single_module_info: null,
  module_videos: [],

  is_creating_customer: false,
  has_recently_created_customer: false,
  customers_to_send: [],

  attachment_files: [],

  lead_source: 'none',

  default_store_id: null,

  deletemodal: false,

  scheduled_packets: [],

  previous_attachments: [],
  img_src: [],

  covideo: null,
}

export function packetstash(state, action) {
  if (!state) {
    state = initState
  }

  switch (action.type) {
    // --- vehicle information
    case STASH_VEHICLE_IDS:
      return {
        ...state,
        vehicle_ids: action.vehicle_ids,
        vehicle_info: action.vehicle_info,
      }

    // --- vehicle images
    case STASH_VEHICLE_IMAGES:
      return {
        ...state,
        vehicle_images: action.vehicle_images,
        vehicle_image_thumb: action.vehicle_image_thumb,
      }

    case STASH_DELETE_MODAL:
      return { ...state, deletemodal: action.deletemodal }

    // --- price information
    case STASH_PRICE:
      return { ...state, price: action.price }

    // --- recipient information
    case STASH_RECIPIENTS:
      return {
        ...state,
        recipient_ids: action.recipient_ids,
        recipient_info: action.recipient_info,
      }

    // --- message information
    case STASH_MESSAGE:
      return { ...state, message: action.message }

    // --- modules information
    case STASH_MODULES:
      return {
        ...state,
        module_ids: action.module_ids,
        module_info: action.module_info,
      }

    // --- single modules information
    case STASH_SINGLE_MODULE:
      return {
        ...state,
        single_module_info: action.single_module_info,
      }

    // --- attachments
    case STASH_ATTACHMENTS:
      return { ...state, attachment_files: action.attachment_files }

    // --- leads
    case STASH_LEADS:
      return { ...state, lead_source: action.lead_source }

    // --- is_creating_customer
    case IS_CREATING_CUSTOMER:
      return { ...state, is_creating_customer: action.is_creating_customer }

    // --- default store id
    case STASH_DEFAULT_STORE_ID:
      return { ...state, default_store_id: action.default_store_id }

    // --- module videos
    case STASH_MODULE_VIDEOS:
      return { ...state, module_videos: action.module_videos }

    // --- UPDATE_CUSTOMERS_TO_SEND
    case UPDATE_CUSTOMERS_TO_SEND:
      return {
        ...state,
        customers_to_send: Array.isArray(action.customers_to_send)
          ? action.customers_to_send
          : state.customers_to_send.concat(action.customers_to_send),
      }

    case STASH_SCHEDULED_PACKETS:
      return {
        ...state,
        scheduled_packets: action.scheduled_packets,
      }

    case STASH_PREVIOUS_ATTACHMENTS:
      return {
        ...state,
        previous_attachments: action.previous_attachments,
      }

    case STASH_IMG_SRC:
      return {
        ...state,
        img_src: action.img_src,
      }

    case EMPTY_PREVIOUS_ATTACHMENTS:
      return {
        ...state,
        previous_attachments: [],
        img_src: [],
      }

    case STASH_COVIDEO:
      return {
        ...state,
        covideo: action.covideo,
      }
    case COVIDEO_LOGOUT:
      return {
        ...state,
        covideo: null,
      }
    case EMPTY_COVIDEO:
      return {
        ...state,
        covideo: null,
      }

    default:
      return state
  }
}
