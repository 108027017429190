import { SHOW_MODAL, HIDE_MODAL, HIDE_ALL_MODALS } from '../_constants/modalTypes.js'

function showModal(type, props) {
  return function(dispatch) {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        type,
        props,
      },
    })
  }
}

function hideModal() {
  return function(dispatch) {
    dispatch({
      type: HIDE_MODAL,
    })
  }
}

function hideAllModals() {
  return function(dispatch) {
    dispatch({
      type: HIDE_ALL_MODALS,
    })
  }
}

export { showModal, hideModal, hideAllModals }
