// packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import debounce from 'lodash.debounce'
import { toast } from 'react-toastify'
import Link from 'react-router-dom'
import PropTypes from 'prop-types'
import FileDrop from 'react-file-drop'
import RichTextEditor from 'react-rte'
import BottomScrollListener from 'react-bottom-scroll-listener'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  setHours,
  setMinutes,
  setSeconds,
  addDays,
  getDay,
  addHours,
  getDate,
  addMinutes,
  min,
} from 'date-fns'
// constants
import {
  PREVIOUS_ATTACHMENTS_MODAL,
  SCHEDULED_PACKET_MODAL,
  EMAIL_PREVIEW,
  SELECT_EMAIL_TEMPLATE,
  COVIDEO_MODAL,
} from '../../../core/_constants/modalTypes'
// reducer actions
import {
  searchCustomers,
  sendPacket,
  schedulePacketSend,
  getModules,
  getVehicle,
  getShareTypes,
} from '../../../core/actions/sendpacketactions'
import { emptyBasket } from '../../../core/actions/inventoryactions.js'
import packetActions from '../../../core/actions/packets/packetsendactions'
import CustomerActions, {
  getRecentCustomers,
  getFavoriteCustomers,
} from '../../../core/actions/users/customeractions'
import {
  updateCustomersToSend,
  emptyPreviousAttachments,
  emptyCovideo,
} from '../../../core/_actions/packetstashActions'
import { renderEmailTemplate } from '../../../core/actions/emailTemplateActions'
import { hideModal, showModal } from '../../../core/_actions/modalActions'

// helpers
import Helper from '../../../core/helpers/platformhelper'
import PacketSendAnimation from '../../helpers/packetsendanimation'
import ToolTip from '../../helpers/tooltip'
//modals
import WebLinkDisplay from './weblinkdisplay'
import SocialShare from '../../layouts/socialshare'
import CustomerForm from '../customers_modals/customerform.js'
import Modal from '../../layouts/modal'
import ExpandedEmailPreview from './emailPreview'
import PreviousAttachmentsModal from './previousattachments'
// icons
import IconCancel from '../../../core/assets/images/icons/icon-x'
import IconEmail from '../../../core/assets/images/icons/icon-email'
import IconExpand from '../../../core/assets/images/icons/icon-expand'
import IconPhone from '../../../core/assets/images/icons/icon-phone'
import IconMobile from '../../../core/assets/images/icons/icon-mobile'
import IconAddItem from '../../../core/assets/images/icons/icon-additem'
import EditIcon from '../../../core/assets/images/icons/editicon'
import PendingIcon from '../../../core/assets/images/icons/pending-icon'
import SendIcon from '../../../core/assets/images/icons/send-icon'
import CogIcon from '../../../core/assets/images/icons/cog-icon'
import IconChevronLeft from '../../../core/assets/images/icons/icon-chevron-left'
import PlayIcon from '../../../core/assets/images/icons/play-icon'
import SearchIconGoogle from '../../../core/assets/images/icons/search-icon-google'
import Covideo from '../../../core/assets/images/covideo_logo.png'
// components
import AddFileIcon from '../../../core/assets/images/icons/addfileicon.js'
import LoadingSpinner from '../../helpers/loadingspinner'
import Car from '../../inventory/components/car'
import Basket from '../../inventory/components/basket'
import EmailTemplates from './emailTemplatesModal'
// wrappers
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
// Sentry
import SentryHelper from '../../../core/helpers/sentryHelper'
// Styles
import '../styles/sendPacketModal.scss'
import { ThemeProvider } from 'styled-components'
import { tr } from 'date-fns/locale'

// PLEASE READ!!
// This file is very large and complicated so some order must be maintained. Look below for usage tips
// USAGE:
//------------------------------------------------------------------------------------------------------------------
// Single Send Mode: provide either vehicleId prop, vehicle prop, or have 1 vehicle in inventory.basket in redux state
// Multi Send Mode: Make sure atleast 2 vehicles in inventory.basket in redux
// CHECKING MULTI VS SINGLE SEND MODE:
// The Source of truth for checking multi vs single send mode is this.state.vehicles.length === 1 (SINGLE) this.state.vehicles.length >1 (MULTI)
// If the length is equal to one then it is single mode. If it is greater than 1 then it is Multi.
// PLEASE use this as the source of truth for conditional rendering between the two modes
//
// This component currently depends of it's own state and props passed to it directly.
// If it takes anything from redux it first passes it into it's state.
// The reasoning is that it can recieve vehicles in multiple ways(passed by props, redux, fetched from api itself)
// so by consolidating all vehicles to this.state.vehicles. There is one source of data for internal rendering when looking for vehicles.
//
// This component currently has no requirement to share it's personal data members with any outside sources.
// If for example an external component had a need for the 'customers' field of this component's state then this field could be moved to Redux.
// I would ask that at that moment in time all references to the this.state.customers field in this component be changed to reference the redux version instead.
// Making sure those working in this file have one source of truth for each of it's data members is important for keeping it organized.

//
let CustomerAbortController

class PacketSendModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customers: [],
      duplicateCustomer: null,
      vehicles: [],
      activeVehicle: 0,
      modules: [],
      expandMessage: false,

      customerSearch: '',
      foundCustomers: [],
      recentCustomers: [],
      favoriteCustomers: [],
      buttonType: '',
      pagNum: 1,
      isBottom: false,
      endOfResults: false,
      customerSearchStatus: 'loading',
      creatingCustomer: false,
      editingCustomer: -1,
      retainedCustomerBeingEdited: null,
      message: RichTextEditor.createEmptyValue(),
      isMessageEdited: false,
      fetchedMessage: '',
      awaitingAPI: false,

      hasError: false,
      errorMessage: '',

      packetSent: false,
      sendAttempted: false,
      showSocialShare: false,
      customerShares: [],
      vdpBaseURL: null,
      webLinkNames: [],

      attachments: [],
      previousAttachmentsModal: false,
      imgSrc: [],
      preview: [],
      covideo: null,
      playing: -1,
      showPrice: true,
      shareTypes: {
        email: {
          disabled: false,
          active: true,
        },
        text: {
          disabled: false,
          active: false,
        },
        link: {
          disabled: false,
          active: false,
        },
        social: {
          disabled: false,
          active: false,
        },
      },
      socialTypes: {
        facebook: true,
        linkedin: false,
        twitter: false,
      },
      showEmailPreview: false,
      expandEmailPreview: false,
      expandTemplateOptions: false,
      selectedEmailTemplate: null,
      showScheduleModal: false,
      scheduledTime: setMinutes(setSeconds(new Date(), 0), 30),
      packetScheduled: false,
      minTime: null,
      favoritedButton: false,
      recentButton: false,
    }
    this.handleButtonBoxToggle = this.handleButtonBoxToggle.bind(this)
    this.previewAttachment = this.previewAttachment.bind(this)
    this.selectAttachments = this.selectAttachments.bind(this)
    this.scheduleOnChangeFunction = this.scheduleOnChangeFunction.bind(this)
    this.setMinTime = this.setMinTime.bind(this)
    this.wrapperRef = React.createRef()
    this.showButtonBoxRef = React.createRef(null)
  }
  // Checks for if this is a singlesend or multisend
  componentDidMount() {
    const { vehicle, vehicleId, basket, userInfo } = this.props

    this.setState({
      minTime: setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours()),
      scheduledTime: addHours(this.state.scheduledTime, 1),
    })
    this.setMinTime()
    document.addEventListener('mousedown', this.handleClickOutside)
    if (vehicle) {
      this.setState({ vehicles: [vehicle] })
      this.modules(vehicle.vehicle_id)
    } else if (vehicleId) {
      getVehicle(vehicleId, userInfo.dj_token).then(res => {
        if (res) {
          this.setState({ vehicles: [res] })
          this.modules(res.vehicle_id)
        }
      })
    } else if (basket && basket.length) {
      this.shareTypes()
      this.setState({ vehicles: basket })
      if (basket.length === 1) {
        this.modules(basket[0].vehicle_id)
      }
    } else {
      if (this.props.modalList <= 1) {
        this.props.hideModalConnect()
      }
    }
    packetActions
      .getPacketSendDefaults(userInfo.user_id, userInfo.dj_token)
      .then(res => {
        if (res.packet_send_message != null && res.packet_signature != null) {
          const newMessage = `${res.packet_send_message}<p><br>${res.packet_signature}</p>`
          this.setState({
            message: RichTextEditor.createValueFromString(
              newMessage.replace(/\n/g, '<br />'),
              'html',
            ),
          })
        } else if (res.packet_send_message != null && res.packet_signature == null) {
          this.setState({
            message: RichTextEditor.createValueFromString(
              res.packet_send_message.replace(/\n/g, '<br />'),
              'html',
            ),
          })
        } else if (res.packet_send_message == null && res.packet_send_signature != null) {
          this.setState({
            message: RichTextEditor.createValueFromString(
              res.packet_signature.replace(/\n/g, '<br />'),
              'html',
            ),
          })
        } else {
          throw "Bug or change in 'users/packet_send_message/legacy/'"
        }
      })
      .catch(error => {
        console.log(error.toString())
      })
    this.getEmailPreview()
  }

  componentDidUpdate(prevProps) {
    const { basket, vehicle, vehicleId, hideModalConnect, previousAttachments } = this.props
    const { sendAttempted, packetSent, shareTypes, imgSrc, attachments } = this.state
    // Checks if basket has changed in size to indicate both that
    // we're in multisend mode and that the state may need to be updated
    if (basket.length !== prevProps.basket.length && !vehicle && !vehicleId) {
      if (basket.length === 0 && !(sendAttempted && packetSent)) {
        if (this.props.modalList <= 1) {
          this.props.hideModalConnect()
        }
      } else {
        this.setState({
          vehicles: basket,
          activeVehicle: 0,
        })
      }
    }
    if (prevProps.basket.length > 1 && basket.length === 1) {
      this.modules(basket[0].vehicle_id)
    }
    if (prevProps.basket !== basket) {
      this.getEmailPreview()
    }
    if (prevProps.vehicle !== vehicle) {
      this.getEmailPreview()
    }

    if (this.props.imgSrc.length > 0 && this.props.imgSrc.length !== prevProps.imgSrc.length) {
      this.setState({
        imgSrc: imgSrc.concat(this.props.imgSrc),
        attachments: attachments.concat(previousAttachments),
      })

      this.props.emptyPreviousAttachmentsConnect()
    }
  }

  // Clears the state and aborts any current requests
  componentWillUnmount() {
    // clear time check for schedule packet send
    this.clearCheckTime()

    // reset state
    this.setState({})

    // abort all current requests
    this.props.abortController.abort()

    // remove event listener for closing schedule packet send on mouse click
    document.removeEventListener('mousedown', this.handleClickOutside)

    // empty all covideos selected
    this.props.emptyCovideoConnect()
  }

  // Takes a vehicleId paramter and sets the module list in the state
  shareTypes = () => {
    const { userInfo, abortSignal, abortController } = this.props
    getShareTypes({
      token: userInfo.dj_token,
      store_id: userInfo.default_store_id,
    }).then(res => {
      if (res) {
        this.setState(prevState => ({
          shareTypes: {
            ...prevState.shareTypes,
            text: {
              disabled: !res.sms_share_enabled,
              active: false,
            },
            social: {
              disabled: !res.facebook_enabled && !res.linked_in_enabled,
              active: false,
            },
          },
        }))
      }
    })
  }
  modules = vehicle_id => {
    const { userInfo, abortSignal, abortController } = this.props
    getModules({
      token: userInfo.dj_token,
      vehicle_id,
      signal: abortSignal,
    }).then(res => {
      if (res && res.modules) {
        this.setState(prevState => ({
          shareTypes: {
            ...prevState.shareTypes,
            text: {
              disabled: !res.text_enabled,
              active: false,
            },
            social: {
              disabled: !res.social_enabled,
              active: false,
            },
          },
          modules: res.modules.map(module => {
            module.checked = module.default_on_packet_send && module.enabled
            return module
          }),
        }))
      }
    })
  }
  // Update the Vehicle in MultiSend Mode of the Modal
  // Passed as a callback to Basket
  updateVehicle = index => {
    this.setState({ activeVehicle: index })
  }
  // Updates the Send Methods Selectors
  updateShareTypes = e => {
    const { shareTypes } = this.state
    // Mimicing Radio Button Behavior without rewriting multiple send method selection in preparation for future
    Object.keys(shareTypes).map(type => (shareTypes[type].active = false))
    shareTypes[e.target.id].active = !shareTypes[e.target.id].active
    this.setState({ shareTypes })
  }
  updateEmailTextShareTypes = e => {
    const { shareTypes } = this.state
    // Mimicing Radio Button Behavior without rewriting multiple send method selection in preparation for future
    Object.keys(shareTypes).map(type => {
      if (type === 'text' || type === 'email') {
      } else {
        shareTypes[type].active = false
      }
    })
    if (shareTypes.email.active && shareTypes.text.active) {
      shareTypes[e.target.id].active = false
    } else {
      shareTypes[e.target.id].active = true
    }
    this.setState({ shareTypes })
  }
  // Updates the Social Types Selectors
  updateSocialTypes = e => {
    const { socialTypes } = this.state
    socialTypes[e.target.id] = !socialTypes[e.target.id]
    this.setState({ socialTypes })
  }

  // Updates The text in the Customer Search Field
  updateCustomerSearch = customerSearch => {
    const { pagNum, customerSearchStatus } = this.state
    const { userInfo } = this.props
    if (this.CustomerAbortController) {
      this.CustomerAbortController.abort()
      this.setState({ pagNum: 1 })
      this.CustomerAbortController = new AbortController()
    } else {
      this.CustomerAbortController = new AbortController()
    }

    if (customerSearch !== '') {
      this.setState({
        customerSearchStatus: 'loading',
      })
    } else {
      this.setState({
        customerSearchStatus: 'resolved',
      })
    }

    if (customerSearch === null || customerSearch === '') {
      this.setState({
        foundCustomers: [],
      })
    } else {
      searchCustomers({
        token: userInfo.dj_token,
        search: customerSearch,
        store_id: userInfo.default_store_id,
        page: pagNum,
        per_page: 10,
        signal: this.CustomerAbortController.signal,
      }).then(res => {
        if (res && this.state.customerSearchStatus !== 'cleared') {
          this.setState({
            foundCustomers: res.results,
            customerSearchStatus: 'resolved',
            endOfResults: false,
          })
        } else {
          this.setState({ customerSearchStatus: 'resolved' })
        }
      })
    }
  }
  getRecentCustomers = () => {
    const { userInfo, customerSearch } = this.props
    this.setState({
      customerSearchStatus: 'loading',
    })
    getRecentCustomers({ token: userInfo.dj_token }).then(res => {
      if (res && res.results) {
        this.setState({
          recentCustomers: res.results,
          customerSearchStatus: 'resolved',
        })
        this.getFavoriteCustomers()
      }
    })
  }
  getFavoriteCustomers = () => {
    const { userInfo, customerSearch } = this.props
    const customerArray = []
    this.setState({
      customerSearchStatus: 'loading',
    })
    getFavoriteCustomers({ token: userInfo.dj_token }).then(res => {
      if (res && res.results) {
        // This is done because the favorite_customers endpoint
        res.results.forEach(customerObject => {
          customerArray.push(customerObject.customer)
        })
        this.setState({
          favoriteCustomers: customerArray,
          customerSearchStatus: 'resolved',
        })
      }
    })
  }
  // Limits the number of customer search api call made to one per the specified amount of time
  debounceCustomerSearch = debounce(this.updateCustomerSearch, 500)
  handleCustomerSearch = customerSearch => {
    this.setState({
      customerSearch,
      pagNum: 1,
    })
    this.debounceCustomerSearch(customerSearch)
  }
  // Clears the Customer Search Field
  clearCustomerSearchResults = () => {
    this.setState({
      customerSearch: '',
      foundCustomers: [],
      customerSearchStatus: 'cleared',
      pagNum: 1,
      isBottom: false,
    })
  }
  // Adds new Customer and checks to make sure customer does not already exist
  addCustomer = customer => {
    const { customers, selectedEmailTemplate } = this.state
    if (
      !customers.some(c => {
        return c.customer_id === customer.customer_id
      })
    ) {
      customers.push(customer)
      this.setState({
        customers,
      })
    }
    if (selectedEmailTemplate !== null) {
      this.getSelectedEmailTemplate(selectedEmailTemplate)
    }
    this.clearCustomerSearchResults()
  }
  // Removes customer from this.state.customer.
  // Requires index of specified customer
  removeCustomer = i => {
    const { customers, selectedEmailTemplate } = this.state
    customers.splice(i, 1)
    this.setState({ customers })
    if (selectedEmailTemplate !== null && customers.length > 0) {
      this.getSelectedEmailTemplate(selectedEmailTemplate)
    }
  }
  // Callback function passed to <CustomerForm/> to submit New/Edited Customer
  submitCustomer = (customer, customerId, message) => {
    const { userInfo } = this.props
    const { customers, editingCustomer, retainedCustomerBeingEdited } = this.state
    this.setState({ duplicateCustomer: null })
    if (customerId) {
      CustomerActions.putUpdateSingleContact(
        customerId,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.phone_number,
        customer.mobile_number,
        customer.address1,
        customer.city,
        customer.state,
        customer.postal_code,
        userInfo.dj_token,
      ).then(res => {
        if (res.msg || res.detail || res.non_field_errors) {
          const errorMessage =
            res.detail != '' ? res.detail : res.msg != '' ? res.msg : res.non_field_errors
          this.setState({ hasError: true, errorMessage })
          if (errorMessage != undefined) {
            toast(errorMessage)
          }
        } else {
          customers[editingCustomer] = res
          this.setState({
            editingCustomer: -1,
            retainedCustomerBeingEdited: null,
            customers,
          })
          toast(`Successfully Edited Customer ${customer.first_name}`)
        }
      })
    } else {
      CustomerActions.putCreateSingleContact(
        userInfo.company_id,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.phone_number,
        customer.mobile_number,
        customer.address1,
        customer.city,
        customer.state,
        customer.postal_code,
        userInfo.dj_token,
      ).then(res => {
        try {
          if (res.message && res.existing_customer && res.requested_customer) {
            if (this.customerEqualEnough(res.existing_customer, res.requested_customer)) {
              customers.push(res.existing_customer)
              // temporarily overriding API response while we work on duplication comparitor
              res.message = 'This customer already exists! Adding existing customer to recipients.'
              toast(res.message)
            } else {
              customers.push(res.existing_customer)
              this.setState({
                customers,
                duplicateCustomer: res.requested_customer,
                editingCustomer: customers.length - 1,
                retainedCustomerBeingEdited: _.clone(res.existing_customer),
                hasError: false,
                errorMessage: '',
              })
            }
            this.setState({ creatingCustomer: false, customers })
          } else if (res.msg || res.detail || res.non_field_errors) {
            const errorMessage =
              res.detail != '' ? res.detail : res.msg != '' ? res.msg : res.non_field_errors

            this.setState({ hasError: true, errorMessage })

            if (errorMessage != undefined) {
              toast(errorMessage)
            }
          } else {
            customers.push(res)
            this.setState({ creatingCustomer: false, customers })
            toast(`${message ? message : `Successfully Created Customer ${res.first_name}`}`)
          }
        } catch (e) {
          console.log(e)
          toast('There was an issue creating customer. Please try again')
        }
      })
    }
  }
  customerEqualEnough(existing, entered) {
    existing.mobile_number = existing.mobile_number ? existing.mobile_number : ''
    existing.email = existing.email ? existing.email : ''

    const equal =
      (existing.email === entered.email &&
        (entered.mobile_number.replace(/[- )(]/g, '') ===
          existing.mobile_number.replace(/[- )(]/g, '') ||
          entered.mobile_number.replace(/[- )(]/g, '').length === 0)) ||
      (existing.mobile_number.replace(/[- )(]/g, '') ===
        entered.mobile_number.replace(/[- )(]/g, '') &&
        (entered.email === existing.email || entered.email === ''))
    return equal
  }
  // Callback function passed to <CustomerForm/> so that it can close the popup
  cancelCustomer = () => {
    // needs to abort changes to customers by overwriting customer being edited
    // with the retained customer on cancel
    const resetCustomers = _.clone(this.state.customers)
    resetCustomers[this.state.editingCustomer] = this.state.retainedCustomerBeingEdited
    this.setState({
      duplicateCustomer: null,
      customers: resetCustomers,
      creatingCustomer: false,
      editingCustomer: -1,
      retainedCustomerBeingEdited: null,
    })
  }

  // onClick functon on the modal to reset favoriteButton and recentButton
  handleButtonBoxToggle = e => {
    if (e.target !== this.showButtonBoxRef) {
      if (this.state.recentButton === true) {
        this.setState({
          recentButton: false,
        })
      } else if (this.state.favoritedButton === true) {
        this.setState({
          favoritedButton: false,
        })
      }
    }
  }
  // Clears the Customer Search Field
  handleMessageExpand = () => {
    this.setState({ expandMessage: !this.state.expandMessage })
  }
  // Updates Message Field
  updateMessage = message => {
    const { isMessageEdited } = this.state
    this.setState({
      message,
    })
    if (!isMessageEdited) {
      this.setState({ isMessageEdited: true })
    }
  }
  // Updates the Show Price Selector found in renderCustomizations()
  updateShowPrice = () => {
    this.setState(prevState => ({
      showPrice: !prevState.showPrice,
    }))
  }
  // Updates the Modules Selectors found in renderCustomizations()
  updateModules = index => {
    const { modules } = this.state
    modules[index].checked = !modules[index].checked
    this.setState({ modules })
  }
  // Returns a boolean value that ensures a packet is able to be sent properly based on current form data
  allowSend = () => {
    const { customers, shareTypes, message, vehicles, modules } = this.state
    let messageString = message.toString('html')
    const allHaveEmail =
      customers.length > 0 &&
      customers.every(customer => {
        return customer.email !== null && customer.email !== undefined && customer.email.length > 0
      })
    const allHavePhone =
      customers.length > 0 &&
      customers.every(customer => {
        return (
          customer.mobile_number !== null &&
          customer.mobile_number !== undefined &&
          customer.mobile_number.length > 0
        )
      })
    return (
      vehicles.length &&
      ((modules && modules.length > 0) || (vehicles.length > 1 && modules)) &&
      ((shareTypes.email.active &&
        !shareTypes.text.active &&
        allHaveEmail &&
        customers.length > 0 &&
        messageString.length > 0) ||
        (shareTypes.text.active &&
          !shareTypes.email.active &&
          allHavePhone &&
          customers.length > 0 &&
          messageString.length > 0) ||
        (shareTypes.email.active &&
          shareTypes.text.active &&
          allHavePhone &&
          allHaveEmail &&
          customers.length > 0 &&
          messageString.length > 0) ||
        (shareTypes.link.active && customers.length > 0) ||
        (shareTypes.social.active && this.state.socialTypes.facebook === true) ||
        this.state.socialTypes.linkedin === true)
      /* || (vehicles.length > 1 && (shareTypes.email.active && allHaveEmail && message.length > 0)) */
    )
  }
  sendPacket = () => {
    const {
      message,
      modules,
      customers,
      shareTypes,
      showPrice,
      socialTypes,
      vehicles,
      activeVehicle,
      awaitingAPI,
      attachments,
      covideo,
      webLinkNames,
    } = this.state
    const { userInfo } = this.props
    // Preparing for sending multiple packet types at the same time
    const share_types = []

    var covideoID = this.props.covideo ? this.props.covideo.id : null
    var accessToken = this.props.covideo ? this.props.covideo.accessToken : null

    if (!awaitingAPI) {
      if (this.allowSend()) {
        if (shareTypes.social.active && (socialTypes.facebook || socialTypes.linkedin)) {
          this.setState({ showSocialShare: true })
        } else {
          this.setState({ awaitingAPI: true })
          shareTypes.email.active && share_types.push('email')
          shareTypes.text.active && share_types.push('text')
          shareTypes.link.active && share_types.push('link')
          sendPacket({
            token: userInfo.dj_token,
            share_types,
            vehicle_ids: vehicles.map(vehicle => {
              return vehicle.vehicle_id
            }),
            module_ids: modules
              .filter(module => {
                return module.checked
              })
              .map(module => {
                return module.module_id
              }),
            customer_ids: customers.map(c => {
              return c.customer_id
            }),
            show_price: showPrice,
            files: attachments,
            message: message.toString('html'),
            covideo_id: covideoID,
            accessToken: accessToken,
          }).then(res => {
            this.setState({
              sendAttempted: true,
              awaitingAPI: false,
            })
            if (res && res.customers && res.customers.length > 0) {
              try {
                const namesMappedToShareRes = this.addInfoToCustomers(
                  res.customers,
                  customers,
                  vehicles,
                )
                this.setState({
                  vdpBaseURL: res.vdp_url_base,
                  packetSent: true,
                  customerShares: res.customers,
                  webLinkNames:
                    namesMappedToShareRes.length > 0 ? namesMappedToShareRes : res.customers,
                })
                this.props.emptyBasketConnect()
                this.props.emptyCovideoConnect()
                if (!shareTypes.link.active) {
                  console.log('Successfully sent packet!')
                  toast('Successfully sent packet!')
                }
              } catch (err) {
                console.error(err)
                this.setState({
                  hasError: true,
                  errorMessage: 'An error has occured',
                })
              }
            } else {
              console.error(res)
              if (res.detail) {
                this.setState({
                  hasError: true,
                  errorMessage: res.detail,
                })
              } else {
                this.setState({
                  hasError: true,
                  errorMessage: res,
                })
              }
            }
          })
        }
      }
    }
  }

  // matches names and vehicle info to share uuid's for web link share
  addInfoToCustomers = (shareResponse, customersData, shareVehicles) => {
    let finalArr = []
    let updatedCustomersArray = shareResponse.map((apiCustomer, apiCustomerIndex) => {
      let shares = shareVehicles.map((vehicle, index) => {
        return { ...vehicle, shares: apiCustomer.share_uuids[index] }
      })
      let updatedCustomer = { ...apiCustomer, ...customersData[apiCustomerIndex], shares }
      finalArr.push(updatedCustomer)
    })
    return finalArr
  }

  schedulePacketSend = () => {
    const {
      message,
      modules,
      customers,
      shareTypes,
      showPrice,
      socialTypes,
      vehicles,
      activeVehicle,
      awaitingAPI,
      attachments,
      scheduledTime,
    } = this.state
    const { userInfo } = this.props

    var covideoID = this.props.covideo ? this.props.covideo.id : null
    var accessToken = this.props.covideo ? this.props.covideo.accessToken : null

    // Preparing for sending multiple packet types at the same time
    const share_types = []
    const formattedDate = scheduledTime
      .toLocaleString('en-ZA')
      .replace(',', '')
      .replaceAll('/', '-')

    if (!awaitingAPI) {
      if (this.allowSend()) {
        if (shareTypes.social.active && (socialTypes.facebook || socialTypes.linkedin)) {
          this.setState({ showSocialShare: true })
        } else {
          this.setState({ awaitingAPI: true })
          shareTypes.email.active && share_types.push('email')
          shareTypes.text.active && share_types.push('text')
          shareTypes.link.active && share_types.push('link')
          schedulePacketSend({
            token: userInfo.dj_token,
            share_types,
            vehicle_ids: vehicles.map(vehicle => {
              return vehicle.vehicle_id
            }),
            module_ids: modules
              .filter(module => {
                return module.checked
              })
              .map(module => {
                return module.module_id
              }),
            customer_ids: customers.map(c => {
              return c.customer_id
            }),
            scheduled_time: formattedDate,
            show_price: showPrice,
            message: message.toString('html'),
            covideo_id: covideoID,
            accessToken: accessToken,
          }).then(res => {
            this.setState({
              sendAttempted: true,
              awaitingAPI: false,
            })
            if (res && res.customers && res.customers.length > 0) {
              this.setState({
                vdpBaseURL: res.vdp_url_base,
                packetSent: true,
                customerShares: res.customers,
              })
              this.props.emptyBasketConnect()
              console.log(`Successfully scheduled packet send for ${scheduledTime}!`)
              toast(`Successfully scheduled packet send for ${scheduledTime}!`)
            } else {
              console.error(res)
              this.setState({
                hasError: true,
                errorMessage: res && res.detail ? res.detail : null,
              })
            }
          })
        }
      }
    }
  }

  selectAttachments = fileList => {
    let { attachments, imgSrc } = this.state

    const MAX_FILE_SIZE = 3145728
    // FileList is not a normally iterable object hence using lodash
    _.map(fileList, file => {
      if (file.size > MAX_FILE_SIZE) {
        toast(`"${file.name}" is too large`)
      }
      if (attachments.length > 2) {
        toast('Attachments Full')
      }
      if (file.size < MAX_FILE_SIZE + 1 && attachments.length < 3) {
        attachments.push(file)
        var reader = new FileReader()

        reader.addEventListener('load', () => {
          imgSrc.push(reader.result)
          this.forceUpdate()
        })

        reader.readAsDataURL(file)
      }
    })
    attachments = [...new Set(attachments)]

    imgSrc = [...new Set(imgSrc)]

    this.setState({
      attachments,
      imgSrc,
    })

    // this resets the file input after every file grab
    this.docDialog.value = ''
  }

  removeAttachment(i) {
    const { attachments, imgSrc } = this.state
    attachments.splice(i, 1)
    imgSrc.splice(i, 1)
    this.setState({ attachments })
    this.setState({ imgSrc })
  }

  previewAttachment(i) {
    const viewPreview = this.state.preview.slice()

    viewPreview[i] = !viewPreview[i]

    this.setState({
      preview: viewPreview,
    })
  }

  attachmentClassnames(i) {
    var attachmentClassname = 'send-modal-attachments-enlarged-image-' + i
    return attachmentClassname
  }

  handleAttachmentDrop = (files, event) => {
    this.selectAttachments(files)
  }

  closePrevAttachmentsModal() {
    this.setState({
      previousAttachmentsModal: false,
    })
  }

  // Preview of Car that is being sent at top of modal
  renderCar = () => {
    const { vehicles, activeVehicle } = this.state
    if (vehicles && vehicles.length) {
      return <Car vehicle={vehicles[activeVehicle]} isPrivate miniMode />
    }
  }
  renderBasket = () => {
    const { vehicles } = this.state
    if (vehicles && vehicles.length > 1) {
      return (
        <div className="send-modal-basket">
          <Basket selectVehicle={this.updateVehicle} miniMode />
        </div>
      )
    }
  }
  // Shows the four Send Methods (Email, Text, Web Link, Social Share)
  renderShareOptions = () => {
    const { vehicles, shareTypes } = this.state

    if (vehicles) {
      return (
        <React.Fragment>
          <div className="whatFix-hook-sendMethod">
            <h2>
              <span>Select Send Method</span>
              {vehicles.length > 1 && (
                <ToolTip
                  onHover
                  tip={
                    <React.Fragment>
                      Sending More Than 3 Vehicles
                      <br />
                      May See Diminished Engagement
                    </React.Fragment>
                  }
                />
              )}
            </h2>
            <div className="send-modal-share-options">
              {/* EMAIL SEND METHOD */}
              <div
                className={`send-modal-share-option${
                  shareTypes.email.disabled ? ' send-modal-share-disabled' : ''
                }`}
              >
                <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                  <input
                    type={shareTypes.email.hidden ? 'hidden' : 'checkbox'}
                    checked={shareTypes.email.active}
                    disabled={shareTypes.email.disabled || vehicles.length > 5}
                    id="email"
                    onChange={this.updateEmailTextShareTypes}
                  />
                  {shareTypes.email.hidden ? '' : 'Email'}
                </label>
              </div>
              {/* TEXT SEND METHOD */}
              <div
                className={`send-modal-share-option${
                  shareTypes.text.disabled ? ' send-modal-share-disabled ' : ''
                }${
                  !shareTypes.text.disabled && vehicles.length > 3
                    ? ' send-modal-share-disabled-cart-full-text '
                    : ''
                }`}
              >
                <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                  <input
                    type={shareTypes.text.hidden ? 'hidden' : 'checkbox'}
                    checked={shareTypes.text.active}
                    disabled={shareTypes.text.disabled || vehicles.length > 3}
                    id="text"
                    onChange={this.updateEmailTextShareTypes}
                  />
                  {shareTypes.text.hidden ? '' : 'Text'}
                </label>
              </div>
              {/* WEBLINK SEND METHOD */}
              <div
                className={`send-modal-share-option${
                  shareTypes.link.disabled ? ' send-modal-share-disabled' : ''
                }`}
              >
                <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                  <input
                    type={shareTypes.link.hidden ? 'hidden' : 'radio'}
                    checked={shareTypes.link.active}
                    disabled={shareTypes.link.disabled}
                    id="link"
                    onChange={this.updateShareTypes}
                  />
                  {shareTypes.link.hidden ? '' : 'Web Link'}
                </label>
              </div>
              {/* SOCIAL SHARE SEND METHOD */}
              <div
                className={`send-modal-share-option${
                  shareTypes.social.disabled ? ' send-modal-share-disabled ' : ''
                }${
                  !shareTypes.social.disabled && vehicles.length > 1
                    ? ' send-modal-share-disabled-cart-full-social '
                    : ''
                }`}
              >
                <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                  <input
                    type={shareTypes.social.hidden ? 'hidden' : 'radio'}
                    checked={shareTypes.social.active}
                    disabled={shareTypes.social.disabled || vehicles.length > 1}
                    id="social"
                    onChange={this.updateShareTypes}
                  />
                  {shareTypes.social.hidden ? '' : 'Social Share'}
                </label>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }
  // Renders various warnings depending on which Send Method (shareType) is selected
  renderShareWarnings = () => {
    const { customers, shareTypes, vehicles } = this.state
    const allHaveEmail =
      customers.length > 0 &&
      customers.every(customer => {
        return customer.email !== null && customer.email !== undefined && customer.email.length > 0
      })
    const allHavePhone =
      customers.length > 0 &&
      customers.every(customer => {
        return (
          customer.mobile_number !== null &&
          customer.mobile_number !== undefined &&
          customer.mobile_number.length > 0
        )
      })
    if (vehicles && vehicles.length === 1) {
      return (
        <React.Fragment>
          {!(
            shareTypes.text.active ||
            shareTypes.email.active ||
            shareTypes.link.active ||
            shareTypes.social.active
          ) && <p className="send-modal-highlight-text">*No send method selected</p>}
          {shareTypes.email.active && !allHaveEmail && (
            <p className="send-modal-highlight-text">
              *Select a contact with an <b>email address</b> or select a different send method.
            </p>
          )}
          {shareTypes.text.active && !allHavePhone && (
            <p className="send-modal-highlight-text">
              *Select a contact with a <b>phone number</b> or select a different send method.
            </p>
          )}
        </React.Fragment>
      )
    }
  }
  // Renders the Social Types Selector whe shareTypes.social.active is set to true
  renderSocialTypes = () => {
    const { socialTypes, vehicles, shareTypes } = this.state
    if (vehicles && shareTypes.social.active) {
      return (
        <React.Fragment>
          <div className="send-modal-platform-options">
            <h2>Select Platform(s)</h2>
            <div className="send-modal-share-option">
              <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                <input
                  type="checkbox"
                  checked={socialTypes.facebook}
                  onChange={this.updateSocialTypes}
                  id="facebook"
                />
                Facebook
              </label>
            </div>
            <div className="send-modal-share-option">
              <label style={{ cursor: 'pointer', userSelect: 'none' }}>
                <input
                  type="checkbox"
                  checked={socialTypes.linkedin}
                  onChange={this.updateSocialTypes}
                  id="linkedin"
                />
                LinkedIn
              </label>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }
  // Renders the customers found when searching for customers
  renderFoundCustomers = () => {
    const {
      foundCustomers,
      customerSearch,
      recentCustomers,
      favoriteCustomers,
      customerSearchStatus,
      buttonType,
      pagNum,
      isBottom,
      endOfResults,
    } = this.state
    const { userInfo } = this.props

    let uniqueFoundCustomers = []

    const handleScroll = () => {
      if (!endOfResults) {
        this.setState({ isBottom: true })
        onScroll()
      }
    }

    // Scroll function for the customers search results
    const onScroll = () =>
      searchCustomers({
        token: userInfo.dj_token,
        search: customerSearch,
        store_id: userInfo.default_store_id,
        page: pagNum + 1,
        per_page: 10,
        signal: this.CustomerAbortController.signal,
      })
        .then(res => {
          const tem = foundCustomers.concat(res.results)
          if (res && this.state.customerSearchStatus !== 'cleared') {
            this.setState({
              foundCustomers: tem.slice(),
              customerSearchStatus: 'resolved',
              pagNum: pagNum + 1,
              isBottom: false,
            })
            if (res.results.length < 10 || res.count < 10) {
              this.setState({
                endOfResults: true,
              })
            }
          } else {
            this.setState({ customerSearchStatus: 'resolved' })
          }
        })
        .catch(() => {
          this.setState({ customerSearchStatus: 'resolved', isBottom: false })
        })

    // Check what button has been clicked
    switch (buttonType) {
      case 'search':
        /*
          Filtering duplicates out of found customers. This
          is important as a recent customer can also be a favorite
        */
        uniqueFoundCustomers = foundCustomers.reduce((accumulator, currentValue) => {
          const newArray = accumulator.find(item => item.customer_id === currentValue.customer_id)
          if (!newArray) {
            return accumulator.concat([currentValue])
          }
          return accumulator
        }, [])
        break
      case 'recent':
        uniqueFoundCustomers = recentCustomers.slice()
        break
      case 'favorite':
        uniqueFoundCustomers = favoriteCustomers.slice()
        break
      default:
    }
    if (uniqueFoundCustomers.length === 0 && customerSearchStatus === 'resolved') {
      return <div className="no-resluts">No results found</div>
    } else if (uniqueFoundCustomers.length > 0 && buttonType === 'search') {
      return (
        <BottomScrollListener onBottom={handleScroll}>
          {scrollRef => (
            <div ref={scrollRef} className="send-modal-customers-found">
              {uniqueFoundCustomers.slice(0, uniqueFoundCustomers.length).map((customer, i) => (
                <div
                  className="send-modal-customers-found-entry"
                  key={customer.customer_id}
                  id={i}
                  onClick={() => {
                    this.addCustomer(customer)
                  }}
                >
                  <span>
                    {customer.is_favorite === 'Not Supported On This Service' ||
                    customer.is_favorite ? (
                      <svg className="star" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path
                            id="svg_1"
                            d="m33,13.408001l-11.056,-1.607l-4.944,-10.018l-4.944,10.018l-11.056,1.607l8,7.798l-1.889,11.011l9.889,-5.199l9.889,5.199l-1.889,-11.011l8,-7.798z"
                          />
                        </g>
                      </svg>
                    ) : (
                      ''
                    )}
                    <b>{`${customer.first_name} ${customer.last_name}`}</b>
                  </span>
                  <br />
                  <span
                    className={`customer-email ${
                      customer.email && customer.email.length > 0 ? '' : 'faded'
                    }`}
                  >
                    <IconEmail />{' '}
                    {customer.email && customer.email.length > 0 ? customer.email : 'N/A'}
                  </span>
                  <div className="single-line-spans">
                    <span
                      className={`customer-phone ${
                        customer.phone_number && customer.phone_number.length > 0 ? '' : 'faded'
                      }`}
                    >
                      <IconPhone />{' '}
                      {customer.phone_number && customer.phone_number.length > 0
                        ? Helper.prettyPhoneNumber(customer.phone_number)
                        : 'N/A'}
                    </span>
                    <span
                      className={`customer-mobile ${
                        customer.mobile_number && customer.mobile_number.length > 0 ? '' : 'faded'
                      }`}
                    >
                      <IconMobile />{' '}
                      {customer.mobile_number && customer.mobile_number.length > 0
                        ? Helper.prettyPhoneNumber(customer.mobile_number)
                        : 'N/A'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </BottomScrollListener>
      )
    } else if (buttonType === 'recent' || 'favorite') {
      if (customerSearchStatus === 'loading') {
        return <div className="no-resluts">Loading</div>
      } else {
        return (
          <div className="send-modal-customers-found">
            {uniqueFoundCustomers.slice(0, uniqueFoundCustomers.length).map((customer, i) => (
              <div
                className="send-modal-customers-found-entry"
                key={customer.customer_id}
                id={i}
                onClick={() => {
                  this.addCustomer(customer)
                }}
                ref={this.showButtonBoxRef}
              >
                <span>
                  {customer.is_favorite === 'Not Supported On This Service' ||
                  customer.is_favorite ? (
                    <svg className="star" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          id="svg_1"
                          d="m33,13.408001l-11.056,-1.607l-4.944,-10.018l-4.944,10.018l-11.056,1.607l8,7.798l-1.889,11.011l9.889,-5.199l9.889,5.199l-1.889,-11.011l8,-7.798z"
                        />
                      </g>
                    </svg>
                  ) : (
                    ''
                  )}
                  <b>{`${customer.first_name} ${customer.last_name}`}</b>
                </span>
                <br />
                <span
                  className={`customer-email ${
                    customer.email && customer.email.length > 0 ? '' : 'faded'
                  }`}
                >
                  <IconEmail />{' '}
                  {customer.email && customer.email.length > 0 ? customer.email : 'N/A'}
                </span>
                <div className="single-line-spans">
                  <span
                    className={`customer-phone ${
                      customer.phone_number && customer.phone_number.length > 0 ? '' : 'faded'
                    }`}
                  >
                    <IconPhone />{' '}
                    {customer.phone_number && customer.phone_number.length > 0
                      ? Helper.prettyPhoneNumber(customer.phone_number)
                      : 'N/A'}
                  </span>
                  <span
                    className={`customer-mobile ${
                      customer.mobile_number && customer.mobile_number.length > 0 ? '' : 'faded'
                    }`}
                  >
                    <IconMobile />{' '}
                    {customer.mobile_number && customer.mobile_number.length > 0
                      ? Helper.prettyPhoneNumber(customer.mobile_number)
                      : 'N/A'}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )
      }
    }
    return <React.Fragment />
  }

  // Renders the customers that have been selected from the search field and/or the Create Customer button
  renderCustomers = () => {
    const { customers, shareTypes } = this.state
    return (
      <div className="send-modal-customers-list">
        {customers.map((customer, i) => (
          <div
            className="send-modal-customers-item"
            className={`
              send-modal-customers-item
              ${
                shareTypes.email.active && !(customer.email && customer.email.length > 0)
                  ? 'send-modal-customers-item-missing-email'
                  : ''
              }
              ${
                shareTypes.text.active &&
                !(customer.mobile_number && customer.mobile_number.length > 0)
                  ? 'send-modal-customers-item-missing-mobile'
                  : ''
              }
            `}
            key={customer.customer_id}
            id={i}
          >
            <div className="send-modal-customers-name">
              {customer.last_name.length > 3
                ? `${customer.first_name} ${customer.last_name[0]}${customer.last_name[1]}${customer.last_name[2]}...`
                : `${customer.first_name} ${customer.last_name}`}
            </div>
            <div
              title={customer.email && customer.email.length ? customer.email : 'Not Available'}
              className={`send-modal-customers-icon ${
                customer.email && customer.email.length > 0
                  ? ''
                  : 'send-modal-customers-icon-missing'
              }`}
            >
              <IconEmail />
            </div>
            <div
              title={
                customer.mobile_number && customer.mobile_number.length
                  ? customer.mobile_number
                  : 'Not Available'
              }
              className={`send-modal-customers-icon ${
                customer.mobile_number && customer.mobile_number.length > 0
                  ? ''
                  : 'send-modal-customers-icon-missing'
              }`}
            >
              <IconMobile />
            </div>
            <div
              className="send-modal-customers-edit-icon"
              onClick={() => {
                this.setState(
                  () => {
                    return {
                      editingCustomer: i,
                      retainedCustomerBeingEdited: _.clone(customer),
                      hasError: false,
                      errorMessage: '',
                    }
                  },
                  () => {},
                )
              }}
            >
              <EditIcon />
            </div>
            <div
              className="send-modal-customers-del-icon"
              onClick={e => {
                this.removeCustomer(i)
              }}
            >
              <IconCancel />
            </div>
          </div>
        ))}
      </div>
    )
  }
  closeCustomerForm = () => {
    this.setState({ creatingCustomer: false, editingCustomer: -1 })
  }
  // Renders the Select Customer search field, found customers dropdown with renderFoundCustomers() and the Selected Customers with renderCustomers()
  renderSelectCustomer = () => {
    const {
      message,
      customers,
      foundCustomers,
      customerSearchStatus,
      customerSearch,
      creatingCustomer,
      editingCustomer,
      shareTypes,
      hasError,
      errorMessage,
      duplicateCustomer,
      favoritedButton,
      recentButton,
      buttonType,
      isBottom,
    } = this.state
    const handleToggleFavoritedBtn = () => {
      this.setState({ recentButton: false })
      this.setState({ favoritedButton: !favoritedButton })
      this.setState({ buttonType: 'favorite' })
      this.getFavoriteCustomers()
    }

    const handleToggleRecentBtn = () => {
      this.setState({ favoritedButton: false })
      this.setState({ recentButton: !recentButton })
      this.setState({ buttonType: 'recent' })
      this.getRecentCustomers()
    }

    if (shareTypes.email.active || shareTypes.text.active || shareTypes.link.active) {
      return (
        <React.Fragment>
          <div className="whatFix-hook-recipients">
            <h2>Select Recipient(s) </h2>
            {creatingCustomer && (
              <div className="send-modal-add-block">
                <CustomerForm
                  submit={this.submitCustomer}
                  cancel={this.cancelCustomer}
                  hasError={hasError}
                  errorMessage={errorMessage}
                />
              </div>
            )}
            {editingCustomer >= 0 && (
              <div className="send-modal-add-block">
                <CustomerForm
                  customer={customers[editingCustomer]}
                  duplicateCustomer={duplicateCustomer}
                  customerId={customers[editingCustomer].customer_id}
                  submit={this.submitCustomer}
                  cancel={this.cancelCustomer}
                  hasError={hasError}
                  errorMessage={errorMessage}
                />
              </div>
            )}
            <div
              className={`send-modal-messaging-info${
                message.length > 0 && customers.length > 0 ? '' : ' send-modal-highlight-input'
              }`}
            >
              {customers.length > 0 && this.renderCustomers()}
              <div className="send-modal-messaging-info-innerBox">
                <div className="send-modal-two-button-container">
                  <button onClick={() => handleToggleFavoritedBtn()}>Favorited</button>
                  <button onClick={() => handleToggleRecentBtn()}>Recent</button>
                  {recentButton === true || favoritedButton === true
                    ? this.renderFoundCustomers()
                    : null}
                </div>
                <div className="send-modal-options-customer">
                  <div className="send-modal-SearchIcon-container">
                    <SearchIconGoogle label="submit-search" />
                  </div>
                  <div className="send-modal-input-container">
                    <input
                      type="text"
                      className={
                        this.props.customerSearchStatus === 'loading'
                          ? `send-modal-customer-search loading`
                          : `send-modal-customer-search`
                      }
                      placeholder="Search Existing Contacts"
                      onChange={e => {
                        this.handleCustomerSearch(e.target.value)
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          this.clearCustomerSearchResults()
                        }, 500)
                      }}
                      onFocus={() => {
                        ;(customerSearch === null || customerSearch === '') &&
                          this.setState({
                            buttonType: 'search',
                            favoritedButton: false,
                            recentButton: false,
                          })
                      }}
                      value={customerSearch}
                    />
                    {(customerSearchStatus === 'loading' && customerSearch.length > 0) ||
                    isBottom ? (
                      <span className="customer-search-input-icon spinner" />
                    ) : null}
                    {customerSearchStatus === 'resolved' &&
                    customerSearch.length > 0 &&
                    !isBottom ? (
                      <span
                        className="customer-search-input-icon clear"
                        onClick={() => this.clearCustomerSearchResults()}
                      >
                        <IconCancel />
                      </span>
                    ) : null}

                    {buttonType === 'search' &&
                    customerSearchStatus === 'resolved' &&
                    customerSearch !== ''
                      ? this.renderFoundCustomers()
                      : null}
                  </div>
                </div>
                <div className="send-modal-add-customer-btn-box">
                  <button
                    className="send-modal-create-customer-btn"
                    onClick={() => {
                      this.setState({ creatingCustomer: true })
                    }}
                  >
                    <IconAddItem /> Create New Contact
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }
  // a request callback to get the email preview html
  getEmailPreview = () => {
    const { userInfo, basket, vehicle } = this.props
    const vehicle_ids = []

    if (basket.length > 0) {
      basket.map(vehicle => {
        vehicle_ids.push(vehicle.vehicle_id)
      })
      packetActions
        .getEmailPreview(vehicle_ids, userInfo.dj_token)
        .then(res => {
          if (res) {
            this.setState({ fetchedMessage: res })
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else if (vehicle !== undefined) {
      vehicle_ids.push(vehicle.vehicle_id)
      packetActions
        .getEmailPreview(vehicle_ids, userInfo.dj_token)
        .then(res => {
          if (res) {
            this.setState({ fetchedMessage: res })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  // Callback passed to the modal container to close email preview if open
  closeEmailPreview = () => {
    const { expandEmailPreview } = this.state
    if (expandEmailPreview) {
      this.toggleEmailPreview()
    }
  }
  // Callback passed to the emailPreview modal
  toggleEmailPreview = () => {
    const { expandEmailPreview } = this.state
    this.setState({ expandEmailPreview: !expandEmailPreview })
  }
  // Renders an email preview
  renderEmailPreview = () => {
    const { message, fetchedMessage } = this.state

    const messageString = message.toString('html')
    let modifiedMessage = fetchedMessage.replace('{{message}}', messageString)

    modifiedMessage = modifiedMessage.replace(
      "font-family: 'Avenir', 'Lato', 'Tahoma' !important;",
      '',
    )

    return (
      <React.Fragment>
        <div className="send-modal-email-preview-header">
          <h2>
            Email Preview
            <ToolTip
              onHover
              tip={
                <React.Fragment>
                  This is only a preview
                  <br />
                  It only applies for <em>email</em> packet sends
                </React.Fragment>
              }
            />
          </h2>
        </div>
        <div className="send-modal-email-preview">
          <div className="expand-icon-container">
            <button
              onClick={() => {
                this.props.showModalConnect(EMAIL_PREVIEW, {
                  message: modifiedMessage,
                })
              }}
            >
              <IconExpand />
            </button>
          </div>
          <div className="email-body" dangerouslySetInnerHTML={{ __html: modifiedMessage }}></div>
        </div>
      </React.Fragment>
    )
  }
  // Callback passed to close email template options if open
  closeEmailTemplateOptions = () => {
    const { expandTemplateOptions } = this.state
    if (expandTemplateOptions) {
      this.setState({ expandTemplateOptions: false })
    }
  }
  // Callback passed to the email templates modal to retrieve the selectedTemplate
  getSelectedEmailTemplate = selectedTemplate => {
    const { customers, vehicles } = this.state
    const { userInfo } = this.props

    this.setState({ selectedEmailTemplate: selectedTemplate })

    const vehicleIds = vehicles.map(vehicle => {
      return vehicle.vehicle_id
    })
    const customerIds = customers.map(customer => {
      return customer.customer_id
    })
    const message_options = { vehicle_id: vehicleIds, send_to: customerIds }

    renderEmailTemplate(userInfo.dj_token, selectedTemplate.id, message_options)
      .then(res => {
        if (res) {
          this.setState({
            message: RichTextEditor.createValueFromString(res.replace(/\n/g, '<br />'), 'html'),
            isMessageEdited: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  // Renders the message field
  renderMessage = () => {
    const {
      message,
      fetchedMessage,
      shareTypes,
      showEmailPreview,
      expandEmailPreview,
      expandTemplateOptions,
      customers,
      isMessageEdited,
    } = this.state

    const messageString = message.toString('html')
    let modifiedMessage = fetchedMessage.replace('{{message}}', messageString)
    const buttonText = showEmailPreview ? 'Hide Email Preview' : 'Show Email Preview'

    if (!shareTypes.link.active && !shareTypes.social.active) {
      return (
        <React.Fragment>
          <div className="send-modal-options-message">
            <div className="email-options">
              <div className="send-modal-email-preview-button">
                {shareTypes.email.active && (
                  <div
                    className="send-modal-create-customer-btn"
                    onClick={() =>
                      this.props.showModalConnect(EMAIL_PREVIEW, {
                        message: modifiedMessage,
                      })
                    }
                  >
                    <IconAddItem /> {buttonText}
                  </div>
                )}
              </div>

              <div className="send-modal-email-preview-button">
                <button
                  className="send-modal-create-customer-btn"
                  onClick={() =>
                    this.props.showModalConnect(SELECT_EMAIL_TEMPLATE, {
                      getEmailTemplate: this.getSelectedEmailTemplate,
                      isMessageEdited: isMessageEdited,
                    })
                  }
                >
                  <CogIcon /> Select Email Template
                </button>
              </div>
            </div>

            <span className="send-modal-options-message-header">
              <h2 align="left">Customize Message</h2>
            </span>

            <RichTextEditor
              value={message}
              className={`text-box`}
              onChange={value => {
                this.updateMessage(value)
              }}
            />
            {/* {shareTypes.email.active && showEmailPreview && this.renderEmailPreview()}
            {expandEmailPreview && (
              <div>
                <ExpandedEmailPreview
                  message={modifiedMessage}
                  togglePreview={this.toggleEmailPreview}
                />
              </div>
            )} */}
          </div>
        </React.Fragment>
      )
    }
  }
  renderShowPrice = () => {
    const { showPrice } = this.state
    return (
      <div className="send-modal-options-price">
        <h2 align="left">Customize Packet</h2>
        <label style={{ cursor: 'pointer', userSelect: 'none' }}>
          <input type="checkbox" checked={showPrice} id="price" onChange={this.updateShowPrice} />
          Show Vehicle Price
        </label>
      </div>
    )
  }
  // Renders the Modules
  renderModules = () => {
    const { vehicles, modules, showPrice } = this.state
    if (vehicles.length === 1) {
      return (
        <React.Fragment>
          {modules && (
            <div className="send-modal-options-modules">
              <h2>Selected Modules</h2>
              <div className="send-modal-module-types" style={{ animation: ` 1500ms fadeInUp` }}>
                {modules.map((module, i) => (
                  <div
                    key={module.module_id}
                    className={`send-modal-module-type ${module.checked ? 'active ' : ''}${
                      !module.enabled ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      this.updateModules(i)
                    }}
                    disabled={!module.enabled}
                  >
                    <input
                      type="checkbox"
                      checked={module.checked && module.enabled}
                      id={module.module_id}
                      key={module.module_id}
                      disabled={!module.enabled}
                      readOnly
                    />
                    {module.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </React.Fragment>
      )
    }
  }

  renderAttachments = () => {
    const { attachments, shareTypes, imgSrc } = this.state

    if (shareTypes.email.active && !shareTypes.text.active) {
      return (
        <div className="send-modal-attachments">
          <h2>
            <span>Attachment(s) ({attachments.length}/3)</span>
            <ToolTip
              onHover
              tip={
                <React.Fragment>
                  Max File Size 3MB
                  <br />
                  .pdf, .jpg, .png, .gif
                </React.Fragment>
              }
            />
          </h2>
          <input
            className="doc-upload-input"
            type="file"
            accept=".pdf,.jpg,.png,.gif"
            multiple
            ref={ref => (this.docDialog = ref)}
            onChange={e => {
              this.selectAttachments(e.target.files)
            }}
          />
          {attachments.length < 3 && (
            <div className="send-modal-attachments-add-item-container">
              <button
                className="send-modal-attachments-add-item"
                onClick={() => {
                  this.docDialog.click()
                }}
              >
                <AddFileIcon />
                Add Attachment
              </button>
              <div
                className="send-modal-attachments-add-item"
                onClick={() => {
                  this.props.showModalConnect(PREVIOUS_ATTACHMENTS_MODAL, {
                    username: this.props.username,
                    token: this.props.userInfo.dj_token,
                    newAttachmentsLength: attachments.length,
                    attachments: attachments,
                  })
                }}
              >
                <AddFileIcon />
                Add From Previous Attachments
              </div>
            </div>
          )}
          {attachments.length < 1 ? (
            <div
              className="send-modal-attachments-list-empty"
              onClick={() => {
                this.docDialog.click()
              }}
            >
              <AddFileIcon />
            </div>
          ) : (
            <div className="send-modal-attachments-list">
              {attachments.map((file, i) => (
                <div className="send-modal-attachments-item" title={file.name} key={file.name}>
                  <div className="send-modal-attachments-item-name">{file.name}</div>

                  {file.type.includes('image') && (
                    <div
                      className="send-modal-attachments-image"
                      onMouseOver={() => {
                        this.previewAttachment(i)
                      }}
                      onMouseOut={() => {
                        this.previewAttachment(i)
                      }}
                      key={i}
                    >
                      {imgSrc[i] !== null ? <img src={imgSrc[i]} /> : ''}
                    </div>
                  )}

                  <div
                    className="send-modal-attachments-del-icon"
                    onClick={() => {
                      this.removeAttachment(i)
                    }}
                  >
                    <IconCancel />
                  </div>
                  {file.type.includes('image') && this.state.preview[i] && (
                    <div className={this.attachmentClassnames(i)}>
                      {imgSrc[i] !== null ? <img src={imgSrc[i]} /> : ''}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )
    }
  }

  handleCovideoStartAndStop(event, action) {
    var playPromise = event.target.play()

    if (action == 'stop') {
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            event.target.pause()
          })
          .catch(error => {})
      }
    }

    if (playPromise !== undefined) {
      playPromise.then(_ => {}).catch(error => {})
    }
  }

  // Render Covideo Integration
  renderCovideoIntegration = () => {
    return (
      <React.Fragment>
        {this.state.shareTypes.email.active || this.state.shareTypes.text.active ? (
          !this.props.covideo ? (
            <div className="covideo-section">
              <div
                className="add-covideo-button"
                onClick={() => this.props.showModalConnect(COVIDEO_MODAL, {})}
              >
                <img src={Covideo} />

                <div className="add-covideo-button-hover-icon">
                  <IconAddItem />
                </div>
                {/* <div className='arrow-right'>
                <IconChevronLeft />
              </div> */}
              </div>
            </div>
          ) : (
            <div className="covideo-section">
              <div className="covideo-header">
                <h2>Covideo</h2>
                <div className="covideo-button-holder">
                  <div
                    className="covideo-button"
                    onClick={() => this.props.showModalConnect(COVIDEO_MODAL, {})}
                  >
                    Change Covideo
                  </div>
                  <div
                    className="covideo-button remove"
                    onClick={() => this.props.emptyCovideoConnect()}
                  >
                    Remove Covideo
                  </div>
                </div>
              </div>

              <div className="covideo-details" key={this.props.covideo.id}>
                <video
                  className="thumbnail"
                  id="videovideo"
                  onMouseOver={event => (
                    this.handleCovideoStartAndStop(event, 'start'),
                    this.setState({
                      playing: this.props.covideo.id,
                    })
                  )}
                  onMouseOut={event => (
                    this.handleCovideoStartAndStop(event, 'stop'),
                    this.setState({
                      playing: -1,
                    })
                  )}
                  poster={this.props.covideo.autogeneratedThumbnail}
                >
                  <source src={this.props.covideo.videoSource} type="video/mp4" />
                </video>
                <div
                  className="video-overlay"
                  style={{
                    display: this.state.playing === this.props.covideo.id ? 'none' : 'block',
                  }}
                >
                  <PlayIcon />
                </div>
                <div className="covideo-title">{this.props.covideo.title}</div>
              </div>
            </div>
          )
        ) : (
          <div></div>
        )}
      </React.Fragment>
    )
  }

  // Set MinTime
  setMinTime() {
    const date = this.state.scheduledTime
    const { minTime } = this.state

    if (getDate(date) > getDate(new Date())) {
      this.setState({
        minTime: setHours(setMinutes(new Date(), 0), 7),
      })
    } else {
      const setMinTimeMinutes = new Date(minTime).getMinutes()
      const minutes = new Date().getMinutes()
      const hours = new Date().getHours()
      let nearest15 = ((((minutes + 7.5) / 15) | 0) * 15) % 60

      if (minutes == 1 && nearest15 == 0 && setMinTimeMinutes !== 15) {
        this.setState({
          minTime: setHours(setMinutes(new Date(), 15), hours),
        })
      }

      if (minutes == 16 && nearest15 == 15 && setMinTimeMinutes !== 30) {
        this.setState({
          minTime: setHours(setMinutes(new Date(), 30), hours),
        })
      }

      if (minutes == 31 && nearest15 == 30 && setMinTimeMinutes !== 45) {
        this.setState({
          minTime: setHours(setMinutes(new Date(), 45), hours),
        })
      }

      if (minutes == 46 && nearest15 == 45 && setMinTimeMinutes !== 0) {
        this.setState({
          minTime: setHours(setMinutes(new Date(), 0), hours + 1),
        })
      }
    }
  }

  // Schedule Date onChange function with setInterval to update time available
  checkTime = () => {
    this.intervalID = setInterval(this.setMinTime, 1000)
  }

  clearCheckTime() {
    clearInterval(this.intervalID)
  }

  scheduleOnChangeFunction(date) {
    this.setState({ scheduledTime: date })
  }

  // Renders the Packet Send button that is disabled or enabled depending on allowSend()
  renderSendButton = () => {
    const { scheduledTime, showScheduleModal, shareTypes } = this.state

    return (
      <React.Fragment>
        <div className="modal-send-packet-container">
          {(shareTypes.email.active || shareTypes.text.active) && (
            <div className="modal-schedule-packet-container">
              <div
                className={`modal-send-packet car-send-packet schedule ${
                  !this.allowSend() || this.state.awaitingAPI ? ' disabled' : ''
                }`}
                onClick={() => {
                  if (this.allowSend()) {
                    if (!this.state.awaitingAPI) {
                      this.checkTime()
                      this.setState({
                        showScheduleModal: !showScheduleModal,
                      })
                    }
                  }
                }}
              >
                <span>
                  <PendingIcon color="$white-color" /> Schedule Packet Send
                </span>
              </div>
              <div
                className={
                  showScheduleModal ? 'schedule-packet-send-box' : 'schedule-packet-send-box hidden'
                }
                ref={this.wrapperRef}
              >
                <div className="finance-table-date-picker">
                  <DatePicker
                    selected={scheduledTime}
                    onChange={date => this.scheduleOnChangeFunction(date)}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                    minTime={this.state.minTime}
                    timeIntervals={15}
                    maxTime={setHours(setMinutes(new Date(), 30), 23)}
                    inline
                  />
                </div>
                <div className="selected-date">
                  Selected Date: <b>{scheduledTime.toLocaleString()}</b>
                </div>
                <div
                  className={`modal-send-packet car-send-packet schedule confirm ${
                    !this.allowSend() || this.state.awaitingAPI ? ' disabled' : ''
                  }`}
                  onClick={() => {
                    this.setState({
                      packetScheduled: true,
                    })
                    this.schedulePacketSend()
                  }}
                >
                  <span>
                    <SendIcon /> Confirm Scheduled Packet Send
                  </span>
                </div>
                <div className="arrow-down">
                  <div className="inner-arrow"></div>
                </div>
              </div>
            </div>
          )}
          <div
            className={`modal-send-packet car-send-packet ${
              !this.allowSend() || this.state.awaitingAPI ? ' disabled' : ''
            }`}
            onClick={() => {
              this.sendPacket()
            }}
          >
            <span>
              <SendIcon />{' '}
              {shareTypes.link.active
                ? 'Create Web Link'
                : shareTypes.social.active
                ? 'Create Social Share'
                : 'Send Packet Now'}
            </span>
          </div>
        </div>
      </React.Fragment>
    )
  }

  // Renders the Social Share Component to handle that packet send
  renderSocialShare = () => {
    const { socialTypes, vehicles, showPrice, modules } = this.state
    return (
      <div>
        <SocialShare
          facebook={socialTypes.facebook}
          linkedin={socialTypes.linkedin}
          vehicleId={vehicles[0].vehicle_id}
          showPrice={showPrice}
          moduleIds={modules
            .filter(module => {
              return module.checked
            })
            .map(module => {
              return module.module_id
            })}
        />
      </div>
    )
  }
  // Renders Success Animation For Successfule Email and Text Packet Send
  renderSuccess = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)

    const { shareTypes, customers } = this.state
    return (
      <div className="send-modal-success" style={{ animation: `500ms jackInTheBox` }}>
        <PacketSendAnimation />
        <div className="packet-success-message">
          <br />
          {customers.length > 1 ? `Packets successfully sent to:` : `Packet successfully sent to:`}
          <br />
        </div>
        {customers.map((customer, index) => {
          return (
            <div
              className="packet-sent-to"
              key={index}
              style={{ animation: `250ms ${500 + (index + 1) * 30}ms pulse` }}
            >
              <span className="sent-name">{`${customer.first_name} ${customer.last_name}`}</span>
              {shareTypes.email.active ? (
                <span className="sent-email">{customer.email}</span>
              ) : null}
              {shareTypes.text.active ? (
                <span className="send-text">{customer.mobile_number}</span>
              ) : null}
            </div>
          )
        })}
      </div>
    )
  }

  // Renders Success Animation For Successful Email and Text Packet Send Scheduling
  renderScheduleSuccess = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)

    const { shareTypes, customers, scheduledTime } = this.state
    return (
      <div className="send-modal-success" style={{ animation: `500ms jackInTheBox` }}>
        <PacketSendAnimation />
        <div className="packet-success-message">
          <br />
          {customers.length > 1
            ? `Packets successfully scheduled to send ${scheduledTime.toLocaleString()} to:`
            : `Packet successfully scheduled to send ${scheduledTime.toLocaleString()} to:`}
          <br />
        </div>
        {customers.map((customer, index) => {
          return (
            <div
              className="packet-sent-to"
              key={index}
              style={{ animation: `250ms ${500 + (index + 1) * 30}ms pulse` }}
            >
              <span className="sent-name">{`${customer.first_name} ${customer.last_name}`}</span>
              {shareTypes.email.active ? (
                <span className="sent-email">{customer.email}</span>
              ) : null}
              {shareTypes.text.active ? (
                <span className="send-text">{customer.mobile_number}</span>
              ) : null}
            </div>
          )
        })}
      </div>
    )
  }

  // Renders Failure Div
  renderFailure = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)

    const { sendAttempted, hasError, errorMessage } = this.state

    if (sendAttempted) {
      return (
        <div className="send-modal-failure">
          There was a problem sending your packet:
          {}
          <div>
            <br />
            {hasError && errorMessage != '' ? (
              <span className="send-modal-failure-message">{errorMessage}</span>
            ) : null}
          </div>
        </div>
      )
    }
  }
  renderAddAttachmentsOverlay = () => {
    return (
      <div className="send-modal-add-attachments-overlay">
        <AddFileIcon color="#78b955" />
      </div>
    )
  }
  // Only the top level divs will be shown
  // Any part of the form that needs logic will have it's own render method that will be called
  render() {
    const {
      vehicles,
      creatingCustomer,
      editingCustomer,
      showSocialShare,
      sendAttempted,
      packetSent,
      customerShares,
      vdpBaseURL,
      shareTypes,
      awaitingAPI,
      expandEmailPreview,
      expandTemplateOptions,
      previousAttachmentsModal,
      packetScheduled,
      showScheduleModal,
      webLinkNames,
    } = this.state
    if (showSocialShare) {
      return <Modal>{this.renderSocialShare()}</Modal>
    }
    if (sendAttempted && packetSent) {
      if (shareTypes.link.active) {
        return (
          <Modal>
            <WebLinkDisplay
              vdpBaseURL={vdpBaseURL}
              customerShares={customerShares}
              webLinkNames={webLinkNames}
            />
          </Modal>
        )
      }
      if (packetScheduled) {
        return <Modal>{this.renderScheduleSuccess()}</Modal>
      } else {
        return <Modal>{this.renderSuccess()}</Modal>
      }
    } else if (sendAttempted) {
      return <Modal>{this.renderFailure()}</Modal>
    } else if (awaitingAPI) {
      return (
        <Modal>
          {' '}
          <LoadingSpinner loading wheel />{' '}
        </Modal>
      )
    }
    return (
      <Modal>
        <FileDrop onDrop={this.handleAttachmentDrop}>
          <div
            className={`send-modal-container ${
              creatingCustomer ||
              editingCustomer >= 0 ||
              expandEmailPreview ||
              expandTemplateOptions ||
              previousAttachmentsModal
                ? 'dimmed'
                : ''
            }`}
            onClick={this.handleButtonBoxToggle}
          >
            <div className="send-modal">
              <div className="send-modal-heading">
                <div className="send-modal-heading-vehicle">
                  {this.renderBasket()}
                  {this.renderCar()}
                </div>
              </div>
              <div className="send-modal-menu">
                <div className="send-modal-options-share" style={{ animation: ` 1500ms fadeIn` }}>
                  {this.renderShareOptions()}
                </div>
                {this.renderSocialTypes()}
                {this.renderSelectCustomer()}
                {this.renderMessage()}
                {this.renderAttachments()}
                {this.renderCovideoIntegration()}
                {this.renderShowPrice()}
                {this.renderModules()}
                {this.renderShareWarnings()}
              </div>
              {this.renderSendButton()}
              {this.renderAddAttachmentsOverlay()}
            </div>
          </div>
        </FileDrop>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  vehicleId: state.modal.props.vehicleId,
  vehicle: state.modal.props.vehicle,
  basket: state.inventory.basket,
  username: state.authentication.user.user.username,
  modalList: state.modal.modalList,
  previousAttachments: state.packetstash.previous_attachments,
  imgSrc: state.packetstash.img_src,
  covideo: state.packetstash.covideo,
})
const PacketSendModal = UserInfoWrapper(PacketSendModalComponent)
export default connect(mapStateToProps, {
  showModalConnect: showModal,
  hideModalConnect: hideModal,
  emptyBasketConnect: emptyBasket,
  emptyPreviousAttachmentsConnect: emptyPreviousAttachments,
  emptyCovideoConnect: emptyCovideo,
})(PacketSendModal)
