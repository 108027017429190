import React, { Component } from 'react'
// node packages
import { toast } from 'react-toastify'
import { range } from 'lodash'
import { connect } from 'react-redux'
// core files
import AdminActions from '../../../core/actions/users/adminactions'
import { hideModal } from '../../../core/_actions/modalActions'
// wrappers
// components
import Modal from '../../layouts/modal'
import LoadingSpinner from '../../helpers/loadingspinner'
// styles
import '../styles/customerModal.scss'
import '../styles/scheduledReportModal.scss'

type Props = {
  title: string,
  token: string,
  hideModal: boolean,
  recipients: Array,
  report: Object,
  user_id: Number,
  store_id: Number,
}

export class ScheduledReportsModal extends Component {
  constructor(props) {
    super(props)

    const report = this.props.report
    this.state = {
      subject: report && report.subject.length > 0 ? report.subject : '',
      dayVal:
        report && report.scheduled_cron_task.day_of_month !== '*'
          ? report.scheduled_cron_task.day_of_month
          : '1',
      freqStr: report && report.scheduled_cron_task.day_of_week == '*' ? 'month' : 'week',
      message: report && report.message.length > 0 ? report.message : '',
      weekday:
        report && report.scheduled_cron_task.day_of_week !== '*'
          ? report.scheduled_cron_task.day_of_week
          : '2',

      errorMessage: '',
      isLoading: false,
      success: report ? true : false,
      anyFieldUpdated: false,

      requiredInputs: {
        subject: true,
        dayVal: true,
        freqStr: true,
        message: true,
        weekday: true,
      },
      formFailures: {
        subject: true,
        dayVal: false,
        freqStr: false,
        message: true,
        weekday: false,
      },
    }

    this._onClose = this.onClose.bind(this)
    this._onUpdateField = this.onUpdateField.bind(this)
    this._onSubmit = this.onSubmit.bind(this)
  }

  state: {
    subject: string,
    dayVal: string,
    freqStr: string,
    message: string,
    weekday: String,
    errorMessage: String,
    success: boolean,
    isLoading: Boolean,
  }

  componentWillUnmount() {
    this.mounted = false

    this.setState({
      subject: '',
      dayVal: '',
      freqStr: '',
      message: '',
      weekday: '',

      errorMessage: '',
      isLoading: false,
      success: true,
      formFailures: {
        subject: true,
        dayVal: false,
        freqStr: false,
        message: false,
        weekday: false,
      },
      requiredInputs: {
        subject: true,
        dayVal: true,
        freqStr: true,
        message: false,
        weekday: true,
      },
    })
  }

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  onClose() {
    this.props.hideModal()
    window.location.reload()
  }

  onUpdateField(field, value) {
    this.setState({ errorMessage: '', success: true, anyFieldUpdated: true })

    const newState = {}
    newState[field] = value.target.value
    this.setState(newState)

    if (!this.state.subject.length > 0 || !this.state.message.length > 0) {
      this.setState({ success: false })
    }
  }

  // submit for either edit or create depending on if(report)
  onSubmit() {
    const { token, store_id, report } = this.props
    const { subject, dayVal, freqStr, message, weekday } = this.state

    this.setState({ isLoading: true })
    let dayValToSubmit
    freqStr == 'month' ? (dayValToSubmit = dayVal) : (dayValToSubmit = '*')
    let weekdayToSubmit
    freqStr == 'week' ? (weekdayToSubmit = weekday) : (weekdayToSubmit = '*')

    if (report) {
      AdminActions.updateCustomScheduledReport(
        report.id,
        token,
        store_id,
        subject,
        dayValToSubmit,
        message,
        weekdayToSubmit,
      )
        .then(res => {
          console.log('Update Report', res)
          if (res == undefined) {
            toast('An Error Occurred, Try Again Later.')
            this.onClose()
          }
          if (res.msg || res.detail || res.non_field_errors) {
            const errorMessage =
              res.detail != ''
                ? res.detail
                : res.msg != ''
                ? res.msg
                : res.non_field_errors
                ? res.non_field_errors
                : 'An Error Occurred, Try Again Later.'
            this.setState({ errorMessage, success: false, isLoading: false })
            console.log(res.detail)
            if (errorMessage !== undefined) {
              toast(errorMessage ? errorMessage : 'An Error Occurred, Try Again Later.')
              this.onClose()
            }
          }
          if (!subject.length > 0 || !message.length > 0) {
            this.setState({
              errorMessage: 'Please review required fields.',
              success: false,
              isLoading: false,
            })
            toast('Please review required fields.')
          } else {
            toast('Successfully Updated Report')
            this.setState({ errorMessage: '', success: true, isLoading: false })
            this.onClose()
          }
        })
        .catch(error => {
          console.log(error.toString())
          this.setState({ isLoading: false })
        })
    } else {
      AdminActions.createCustomScheduledReport(
        token,
        store_id,
        subject,
        dayValToSubmit,
        message,
        weekdayToSubmit,
      )
        .then(res => {
          console.log('Create Report', res)
          if (res == undefined) {
            toast('An Error Occurred, Try Again Later.')
            this.onClose()
          }
          if (res.msg || res.detail || res.non_field_errors) {
            const errorMessage =
              res.detail != ''
                ? res.detail
                : res.msg != ''
                ? res.msg
                : res.non_field_errors
                ? res.non_field_errors
                : 'An Error Occurred, Try Again Later.'
            this.setState({ errorMessage, success: false, isLoading: false })
            console.log(res.detail)
            if (errorMessage !== undefined) {
              toast(errorMessage ? errorMessage : 'An Error Occurred, Try Again Later.')
              this.onClose()
            }
          }
          if (!subject.length > 0 || !message.length > 0) {
            this.setState({
              errorMessage: 'Please review required fields.',
              success: false,
              isLoading: false,
            })
            toast('Please review required fields.')
          } else {
            toast('Successfully Created Report')
            this.setState({ errorMessage: '', success: true, isLoading: false })
            this.onClose()
          }
        })
        .catch(error => {
          console.log(error.toString())
          this.setState({ isLoading: false })
        })
    }
  }

  ScheduledReportInputContainers() {
    const { requiredInputs, formFailures, subject, message, freqStr, dayVal, weekday } = this.state
    const { recipients } = this.props

    const weekdayArr = [
      { abbr: 'S', long: 'sunday', value: '1' },
      { abbr: 'M', long: 'monday', value: '2' },
      { abbr: 'T', long: 'tuesday', value: '3' },
      { abbr: 'W', long: 'wednesday', value: '4' },
      { abbr: 'T', long: 'thursday', value: '5' },
      { abbr: 'F', long: 'friday', value: '6' },
      { abbr: 'S', long: 'saturday', value: '7' },
    ]
    const dayNumberArr = range(1, 29)

    return (
      <div className="scheduled-report-container">
        <label className="customer-form-label" htmlFor="form-subject">
          Title
          {requiredInputs.subject ? <span className="required">*</span> : null}
          <input
            ref={text => {
              this._inputAddReport = text
            }}
            id="form-subject"
            type="text"
            name="subject"
            placeholder="Title"
            className={formFailures.subject ? `form-control error` : `form-control`}
            value={subject}
            onChange={text => {
              this._onUpdateField('subject', text)
            }}
            autoFocus
          />
        </label>

        <label className="customer-form-label" htmlFor="form-message">
          Message
          {requiredInputs.message ? <span className="required">*</span> : null}
          <textarea
            ref={text => {
              this._inputAddReport = text
            }}
            id="form-message"
            type="text"
            name="message"
            placeholder="Message"
            className={formFailures.message ? `form-control error` : `form-control`}
            value={message}
            onChange={text => {
              this._onUpdateField('message', text)
            }}
          />
        </label>

        <div className="repeats-container">
          <label htmlFor="form-freqStr">
            Repeats every
            <select
              id="form-freqStr"
              name="freqStr"
              value={freqStr}
              onChange={val => {
                this._onUpdateField('freqStr', val)
              }}
            >
              <option>week</option>
              <option>month</option>
            </select>
          </label>

          <label htmlFor="form-dayVal" className={freqStr == 'week' ? `hidden` : ''}>
            Day of the Month
            <select
              disabled={freqStr == 'week'}
              id="form-dayVal"
              name="dayVal"
              value={dayVal}
              onChange={val => {
                this._onUpdateField('dayVal', val)
              }}
            >
              {dayNumberArr.map((day, i) => (
                <option key={i++}>{day}</option>
              ))}
            </select>
          </label>
        </div>

        <label htmlFor="form-weekday" className={freqStr == 'month' ? `hidden` : ''}>
          Weekday
          <div className="weekday-selector">
            <div>
              {weekdayArr.map((day, i) => (
                <span
                  key={i++}
                  role="button"
                  aria-hidden="true"
                  value={day.value}
                  onClick={() => this.setState({ weekday: day.value })}
                  className={day.value == weekday ? `selected-weekday` : ''}
                >
                  {day.abbr}
                </span>
              ))}
            </div>
          </div>
        </label>

        <label className="recipients-container">
          Recipients
          <div className="recipients-list">
            <ul>
              {recipients.length
                ? recipients.map((recipient, i) => <li key={i++}>{recipient}</li>)
                : null}
            </ul>
          </div>
        </label>
      </div>
    )
  }

  props: Props
  _onClose: Function
  _onUpdateField: Function
  _onSubmit: Function
  _inputAddReport: Function // ref

  render() {
    const { success, errorMessage, isLoading, anyFieldUpdated } = this.state

    return (
      <Modal>
        {isLoading && <LoadingSpinner loading wheel />}
        <div className="customer-info-box scheduled-report-modal">
          <h3>{this.props.title}</h3>
          <hr />

          {this.ScheduledReportInputContainers()}
          <div className="text-failure-tip">{!success ? errorMessage : null}</div>

          {!this.props.report ? (
            <button disabled={!success} onClick={this._onSubmit}>
              Submit
            </button>
          ) : (
            <button disabled={!anyFieldUpdated} onClick={this._onSubmit}>
              Update
            </button>
          )}
          <button onClick={this._onClose}>Cancel</button>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    token: state.modal.props.token,
    recipients: state.modal.props.recipients,
    report: state.modal.props.report,
    store_id: state.modal.props.store_id,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(ScheduledReportsModal)
