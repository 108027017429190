import React, { useState } from 'react'
import { connect } from 'react-redux'

import TabNav from './components/tabnav'
import VehicleTimelineService from '../packetview/columns/components/vehicleTimelineService'

import CancelIcon from '../../core/assets/images/icons/cancel-icon'

import './styles/vehicleHistory.scss'

function VehicleHistory(props) {
  const { token } = props

  const [vinInput, setVinInput] = useState('')
  const [isComponentLoaded, setIsComponentLoaded] = useState(false)
  const [isVinSubmitted, setIsVinSubmitted] = useState(false)
  const [rerenderTimeline, setRerenderTimeline] = useState(false)
  const [submittedVin, setSubmittedVin] = useState('')

  const handleVinInput = e => {
    setVinInput(e.target.value)

    const vin = e.target.value

    if (vin.length !== 17) {
      e.target.setCustomValidity('Please enter a valid 17 character VIN.')
      isVinSubmitted && setIsVinSubmitted(false)
    } else {
      e.target.setCustomValidity('')
    }
  }

  const handleClearVinInput = () => {
    setVinInput('')
    setIsVinSubmitted(false)
  }

  const handleVinSubmit = () => {
    !isVinSubmitted && setIsVinSubmitted(true)
    !isComponentLoaded && setIsComponentLoaded(true)
    setSubmittedVin(vinInput)
    setRerenderTimeline(!rerenderTimeline)
  }

  const renderVehicleTimeline = () => {
    return <VehicleTimelineService vin={submittedVin} token={token} />
  }

  return (
    <div className="vehicle-history">
      <TabNav />
      <div className="page-content">
        <div className="staff-tools">
          <h1>Vehicle History Search</h1>
          <h2>Search for a vehicle by VIN number and view its history</h2>
          <div className="search-submit">
            <div className="vin-input-search">
              <input
                className="vin-input"
                placeholder="ENTER A VALID VIN"
                value={vinInput}
                onChange={e => handleVinInput(e)}
                minLength="17"
                type="text"
                maxLength="17"
                autoFocus
              />

              <div
                className={vinInput.length > 0 ? 'cancel-icon' : 'cancel-icon-hidden'}
                onClick={() => handleClearVinInput()}
              >
                {`(${vinInput.length}/17)  `}
                <CancelIcon />
              </div>
            </div>

            <button
              className="vin-submit"
              disabled={vinInput.length != 17}
              onClick={() => handleVinSubmit()}
            >
              Submit
            </button>
          </div>

          {submittedVin.length > 0 && (
            <div className="current-vin-display">
              Current VIN Search: <b>{submittedVin}</b>
            </div>
          )}

          {isComponentLoaded && renderVehicleTimeline()}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.authentication.user.token,
})

export default connect(mapStateToProps, null)(VehicleHistory)
