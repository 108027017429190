import {
  DjangoCompanyStoreSearchAPIURL,
  DjangoChangeCompanyStoreAPIURL,
  DjangoCompanyStoresListAPIURL,
  DjangoStoreAuditMSRPAPIURL,
  DjangoStoreAuditBrochureAPIURL,
  DjangoStoreAuditCarfaxAPIURL,
  DjangoStoreAuditAutocheckAPIURL,
  DjangoStoreAuditPhotoAPIURL,
  DjangoStoreAuditSocialAPIURL,
  DjangoStoreAuditTextAPIURL,
  DjangoStoreAuditAvailabilityAPIURL,
  DjangoStoreAuditPhoneAPIURL,
  DjangoPacketsSentAPIURL,
  DjangoPacketsViewedAPIURL,
  DjangoWebClicksAPIURL,
  DjangoIndirectClicksAPIURL,
  DjangoSocialSharesAPIURL,
} from '../config/autoipacket_end_commands'
import UserActions from './users/useractions'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

function searchCompanyStores(args) {
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DjangoCompanyStoreSearchAPIURL}?page=${args.page}&search=${args.search}`,
    {
      method: 'GET',
      headers,
    },
  ).then(response => {
    return response.json()
  })
}
function changeCompanyStore(args) {
  const params = new FormData()
  console.log(args)
  params.append('company_store_id', args.company_store_id)
  params.append('entire_company_access', args.entire_company_access)

  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoChangeCompanyStoreAPIURL}`, {
    method: 'POST',
    headers,
    body: params,
  })
    .then(response => {
      if (response.status === 204) {
        // UserActions.AIPByeBye(args.token)
        args.history.push('/login')
        window.location.reload()
        return null
      } else {
        return response.json()
      }
    })
    .then(res => {
      if (res) {
        console.log('Mission Failed', res)
        return res
      }
    })
}

export { searchCompanyStores, changeCompanyStore }

export default {
  getCompanyStoresList: (companyName: string, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoCompanyStoresListAPIURL}${companyName}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditMSRP: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditMSRPAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditBrochure: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditBrochureAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditCarfax: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditCarfaxAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditAutocheck: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditAutocheckAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditPhoto: (storeLotId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditPhotoAPIURL}?store_lot_id=${storeLotId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditSocial: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditSocialAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditText: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditTextAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditAvailability: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditAvailabilityAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAuditPhone: (storeId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoStoreAuditPhoneAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getPacketsSent: (storeId: number, dateRanges: object, token: string) => {
    const params = { ranges: dateRanges }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoPacketsSentAPIURL}${storeId}/packets-sent/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  getPacketsViewed: (storeId: number, dateRanges: object, token: string) => {
    const params = { ranges: dateRanges }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoPacketsViewedAPIURL}${storeId}/packets-viewed/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  getWebClicks: (storeId: number, dateRanges: object, token: string) => {
    const params = { ranges: dateRanges }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoWebClicksAPIURL}${storeId}/web-clicks/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  getIndirectClicks: (storeId: number, dateRanges: object, token: string) => {
    const params = { ranges: dateRanges }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoIndirectClicksAPIURL}${storeId}/indirect-clicks/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  getSocialShares: (storeId: number, dateRanges: object, token: string) => {
    const params = { ranges: dateRanges }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoSocialSharesAPIURL}${storeId}/social-shares/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
}
