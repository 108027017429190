// packages
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes, { number } from 'prop-types'
import format from 'date-fns/format'
// icon
import OpenNewIcon from '../../../core/assets/images/icons/open-pdf-new.js'
// helpers
import Helper from '../../../core/helpers/platformhelper'
import runtimeEnv from '../../../core/config/envConstants'
import Paginator from '../../helpers/paginator'
import LoadingSpinner from '../../helpers/loadingspinner'
import ToolTip from '../../helpers/tooltip'
// api
import { getStickerPullHistory, downloadSticker } from '../../../core/actions/stickerpullactions'
/**
 *    TODO:
 *      - Extra sticker pull features?
 */

function stickerPdfHandler(vin, token) {
  downloadSticker(vin, token).then(res => {
    const newBlob = new Blob([res], { type: 'application/pdf;base64' })
    const data = window.URL.createObjectURL(newBlob)
    const pdfWindow = window.open(data, '_blank')
    setTimeout(() => {
      pdfWindow.document.title = `${vin}-pdf`
    }, 100)
  })
}

const StickerPullHistory = props => {
  const [successHistory, setSuccessHistory] = useState([])
  const [loading, setLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const rowsPerPage = 10

  const { success } = props

  useEffect(() => {
    getStickerPullHistory('True', props.token, rowsPerPage, currentPage)
      .then(setLoading(true))
      .then(res => {
        if (res) {
          setLoading(false)
          setSuccessHistory(res.results)
          setTotalPages(Math.ceil(res.count / rowsPerPage))
        }
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
      })
  }, [success, currentPage])

  const updatePage = page => {
    setCurrentPage(page)
  }

  if (successHistory.length === 0) {
    return null
  }
  return (
    <div className="pull-history-container">
      <h2>Recent Sticker Pulls</h2>
      <div className="pull-history-table-wrapper ">
        <table className="pull-history-table">
          {loading && <LoadingSpinner wheel loading />}
          <thead>
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Year</th>
              <th>VIN</th>
              <th>Date ({runtimeEnv.timezoneAbbr})</th>
              <th>Time ({runtimeEnv.timezoneAbbr})</th>
              <th>Sticker</th>
            </tr>
          </thead>
          <tbody>
            {successHistory.map(pull => {
              return (
                <tr key={pull.request_ts}>
                  <td>{pull.make}</td>
                  <td>{pull.model}</td>
                  <td>{pull.year}</td>
                  <td>{pull.vin}</td>
                  <td>{format(Helper.localizeTime(pull.request_ts), 'M/d/yyyy')}</td>
                  <td>{format(Helper.localizeTime(pull.request_ts), 'h:mm a')}</td>
                  <td>
                    <div
                      style={{ display: 'inline' }}
                      className="pull-history-table-download"
                      href={pull.download}
                      onClick={() => {
                        props.viewSticker(pull.vin)
                      }}
                    >
                      View Sticker
                    </div>
                    <div
                      style={{ display: 'none', paddingLeft: 10, fill: 'green' }}
                      className="pull-history-table-download"
                      onClick={() => {
                        stickerPdfHandler(pull.vin, props.token)
                      }}
                    >
                      <ToolTip onHover tip={<React.Fragment>New tab</React.Fragment>}>
                        <OpenNewIcon />
                      </ToolTip>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="stickerpull-paginator">
          <Paginator page={currentPage} updatePage={updatePage} numPages={totalPages} />
        </div>
      </div>
    </div>
  )
}

StickerPullHistory.propTypes = {
  token: PropTypes.string.isRequired,
}
function mapStateToProps(state: Object, ownProps: Object) {
  const { success } = ownProps
  return {
    token: state.authentication.user.token,
    success: success,
  }
}
export default connect(mapStateToProps, null)(StickerPullHistory)
