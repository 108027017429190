import packageJson from '../../../../package.json'
import * as AIP_DJAPI_URLS from './autoipacket_end_commands'
import moment from 'moment-timezone'
import AIPDjapiIntercepts from './aipDjapiIntercepts'
import { logoutUser, refreshToken } from '../../core/actions/users/useractions'

//USAGE
//This is a wrapper function for fetch that automatically applies some headers and gives access to add middleware
// AIP_DJAPI() can be used the same was you use fetch()
/*
  1. Add the endpoint urls you need to use to autoipacket_end_commands.js
  2. Import this file using
    import AIP_DJAPI, { AIP_DJAPI_URLS } from './aipDjapi'
  3. Make a call to the api the same way you would use fetch
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
    return AIP_DJAPI(AIP_DJAPI_URLS.Example_GET_Endpoint, {
      method: 'GET',
      headers,
    })
*/
const AIP_DJAPI_HEADERS = {
  'AIP-Application-Type': 3,
  'AIP-Application-Version': packageJson.version,
  'AIP-Time-Zone': moment.tz.guess(true),
}

let refreshPending = false

export default async function AIP_DJAPI(url, params, useToken = true, retryCount = 0) {
  let authentication = JSON.parse(localStorage.getItem('authentication'))
  if (params) {
    params.headers = {
      ...AIP_DJAPI_HEADERS,
      ...params.headers,
    }
  } else {
    params = {
      headers: AIP_DJAPI_HEADERS,
    }
  }

  if (useToken && authentication && authentication.access_token) {
    params.headers = {
      ...params.headers,
      Authorization: `bearer ${authentication.access_token}`,
    }
  }
  if (refreshPending) {
    // console.log('Refresh is Pending (Outer)', url)
    await new Promise(done => setTimeout(() => done(), 1000))
    if (retryCount < 6) {
      return AIP_DJAPI(url, params, useToken, retryCount + 1)
    }
  } else {
    return fetch(url, params).then(async response => {
      if (response.status === 401 || response.status === 403) {
        let clonedResponse = response.clone()
        let json = await clonedResponse.json()
        if (json.error_code === 'invalid_token') {
          if (refreshPending) {
            // console.log('Refresh is Pending (Inner)', url)
            await new Promise(done => setTimeout(() => done(), 1000))
            if (retryCount < 6) {
              return AIP_DJAPI(url, params, useToken, retryCount + 1)
            }
          } else {
            refreshPending = true
            // console.log('Set Refresh Pending to true', url)
            let refreshReturn = await refreshToken(authentication.refresh_token)
            // console.log('Refresh Return Obj', refreshReturn)
            if (refreshReturn && refreshReturn.json && refreshReturn.json.access_token) {
              // console.log('Refresh Return Check Successful, Setting Refresh Pending to False')
              refreshPending = false
              let newParams = {
                ...params,
                headers: {
                  ...params.headers,
                  Authorization: `bearer ${refreshReturn.json.access_token}`,
                },
              }
              // console.log('Retrying Request that set off Refresh with these params')
              // console.log('Old Params', params)
              // console.log('New Params',newParams)
              let newResponse = await fetch(url, newParams)
              return newResponse
              try {
                AIPDjapiIntercepts(newResponse)
              } catch (e) {
                console.log(e)
              }
            } else {
              // console.log('Refresh Return Check Failed, Logging Out User')
              logoutUser()
              return response
            }
          }
        }
        if (json.issue == 'is_company_store_unauthorized') {
          // 403 return to userActions and store error in redux
          return response
        }
      } else {
        try {
          AIPDjapiIntercepts(response)
        } catch (e) {
          console.log(e)
        }
        return response
      }
    })
  }
}

export { AIP_DJAPI_URLS, AIP_DJAPI_HEADERS }
