// general: 1/6 of the customer component - child of customer dash - (5th).

// TODO:
// 1. server error 500 on PUT v1/packets/{share_uuid}
// 3. mobile support
// 4. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import ReactTable from 'react-table'
import 'react-table/react-table.css'
// core files
import AIPSeedsData from '../../../core/data/AIPSeedsData'
import PacketActions from '../../../core/actions/packets/packetactions'
import Helper from '../../../core/helpers/platformhelper'
import GreenCheck from '../../../core/assets/images/icons/icon-green-checkmark.png'
// helpers
import { ReactTableOptionsStatic } from '../../helpers/reusables'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/customers.scss'

// global variable
let setTimeoutId

type Props = {
  DJtoken: string, // token to send to get the data.
  customerId: number, // sent for the customer's data.
  storeId: number, // sent for the store's data.
}

export default class CustomersHistory extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      entries: [], // customer table json.results, if returned results.length > 0
      // ^else, remains empty.
      pagePrevious: null, // customer table json.previous
      pageNext: '1', // customer table json.next
      count: 0, // customer table json.count
      pageNum: 0, // react-table's page number variable that either gets -- or ++ based on
      // ^this.state.count status

      loading: true, // react-table's option's loading value
      loadingText: 'Loading...', // react-table's option's loading text value
    }
  }

  state: {
    entries: Object,
    pagePrevious: string,
    pageNext: string,
    count: number,
    pageNum: number,

    loading: boolean,
    loadingText: string,
  }

  componentDidMount() {
    this.mounted = true // set 'mounted' to true.

    if (this.mounted) {
      // sets dummy data onmount into the table for row init
      this.onMountSetDataSourceToLoading()

      // calls the initial customer action to load the real default data from the API
      this.CustomerHistoryGetAPIData('1')
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    // clear global timeout variable
    clearTimeout(setTimeoutId)

    // reset the states on unmount
    this.setState({
      entries: [],
      pagePrevious: null,
      pageNext: '1',
      count: 0,
      pageNum: 0,

      loading: true,
      loadingText: 'Loading...',
    })
  }

  // onMountSetDataSourceToLoading
  // sets dummy data onmount into the table for row init;
  // called once onmount
  onMountSetDataSourceToLoading() {
    //const entries = AIPSeedsData.customer_packet_history
    //this.setState({ entries })
  }

  // ***********************LETS*CALL*API***********************
  // CustomerHistoryGetAPIData
  // sends manipulated states to the API as the correct request params to get the
  // returned customer data
  CustomerHistoryGetAPIData(pageDirection: string) {
    // on entry set the loading states
    this.setState({ loading: true, loadingText: 'Loading...' })

    // props
    const { DJtoken, customerId } = this.props

    PacketActions.getCustomerHistoryPackets(customerId, pageDirection, DJtoken)
      .then(json => {
        if (json.results !== undefined && json.results && json.results.length > 0) {
          this.CustomerConfigureStates(json, pageDirection)
        } else {
          this.setState({ count: 0, loading: false, loadingText: 'No results found.' })
        }
      })
      .catch(error => {
        this.CustomerConfigureStatesFailed()
        console.log(error.toString())
      })
  }
  // ***********************LETS*CALL*API***********************

  // ***********************LETS*CONFIGURE*THE*API***********************
  // CustomerConfigureStates
  CustomerConfigureStates(json: Object, pageDirection: string) {
    this.setState({
      entries: json.results,
      pagePrevious: json.previous !== null ? json.previous.split('query/packets/')[1] : null,
      pageNext: json.next !== null ? json.next.split('query/packets/')[1] : null,
      pageNum: pageDirection === '1' ? 0 : this.state.pageNum,
      count: json.count,
      loading: false,
    })
  }

  // CustomerConfigureStatesFailed
  CustomerConfigureStatesFailed() {
    this.setState({
      count: 0,
      loading: true,
      loadingText: 'There was an error loading.',
    })
  }

  translateSource(sourceName) {
    switch (sourceName) {
      case 'packet-share-price-change':
        return 'Price Change Alert'
        break
      case 'packet-share-email':
        return 'Packet Share (email)'
        break
      case 'packet-share-mms':
        return 'Packet Share (text)'
        break
      case 'packet-share-link':
        return 'Packet Share (weblink)'
        break
      default:
        return sourceName
    }
  }
  // ***********************LETS*CONFIGURE*THE*API***********************

  // ***********************LETS*SETUP*COLUMNS***********************
  // CustomerColumnsContainer
  // renders the table and columns fully for the imported react-table
  CustomerColumnsContainer() {
    // props/states
    const { DJtoken, customerId } = this.props
    const { entries } = this.state

    // arrays
    const columnsArray = []

    if (entries && entries.length > 0) {
      columnsArray.push({
        columns: [
          {
            Header: 'Vehicle',
            id: 'vehicle_id',
            accessor: d => `${d.vehicle_id.year} ${d.vehicle_id.make} ${d.vehicle_id.model}`,
          },
          {
            Header: 'Sent by User',
            id: 'team_member',
            accessor: d => `${d.user_id_fk.first_name} ${d.user_id_fk.last_name}`,
          },
          {
            Header: 'Date Sent',
            id: 'date_created',
            accessor: d => Helper.FormatDateUSA(d.packet_history.date_created),
          },
          {
            Header: 'Version',
            id: 'share_uuid',
            accessor: d => this.translateSource(d.source.label),
          },
          {
            Header: 'Viewed',
            width: 80,
            id: 'packet_view',
            accessor: d =>
              d.viewed_dt != null ? <img style={{ width: '20px' }} src={GreenCheck} /> : '',
          },
          {
            Header: 'Alerts',
            width: 60,
            id: 'disable_pc_alerts',
            accessor: d => {
              return (
                <button
                  className="alerts-switch-btn"
                  onClick={() => {
                    PacketActions.postUpdateCustomerPacketAlert(
                      customerId,
                      d.packet_history.vehicle_id,
                      !d.disable_pc_alerts,
                      d.share_uuid,
                      DJtoken,
                    )
                      .then(json => {
                        if (d.disable_pc_alerts != json.disable_pc_alerts) {
                          d.disable_pc_alerts = json.disable_pc_alerts
                          this.forceUpdate()
                        }
                      })
                      .catch(error => {
                        console.log(error.toString())
                      })
                  }}
                >
                  {d.disable_pc_alerts ? 'Off' : 'On'}
                </button>
              )
            },
          },
        ],
      })
    }

    return columnsArray
  }
  // ***********************LETS*SETUP*COLUMNS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // CustomerTableContainer
  // renders the body of the table using the data returned from 'CustomerColumnsContainer'
  CustomerTableContainer() {
    // states
    const { entries, pageNext, pagePrevious, count, loading, loadingText, pageNum } = this.state

    // options
    const ReactTableOptions = {
      className: '-striped -highlight customerTable',
      loading,
      sortable: false,
      loadingText,
      noDataText: 'No packets have been sent to this contact.',
    }

    // variables
    return (
      <div className="customer-table customer-table-box">
        <h3 align="center">Packets Sent to this Contact ({count})</h3>
        <ReactTable
          {...ReactTableOptions}
          {...ReactTableOptionsStatic}
          noDataText="No packets have been sent to this contact."
          data={entries}
          resolveData={data => data.map(row => row)}
          columns={this.CustomerColumnsContainer()}
          page={pageNum}
          pages={Math.ceil(count / 50)}
          PreviousComponent={() => {
            const pagePrevBoolean = pageNum != 0 && count > 50

            return (
              <button
                style={{
                  color: pagePrevBoolean ? 'white' : 'grey',
                  backgroundColor: pagePrevBoolean ? null : 'transparent',
                  cursor: pagePrevBoolean ? 'pointer' : 'auto',
                }}
                onClick={() => {
                  if (pagePrevBoolean) {
                    console.log(pageNum)
                    this.CustomerHistoryGetAPIData(pageNum)
                    this.setState({ pageNum: pageNum - 1 })
                  }
                }}
              >
                Back
              </button>
            )
          }}
          NextComponent={() => {
            const pageNextBoolean = count > 50

            return (
              <button
                style={{
                  color: pageNextBoolean ? 'white' : 'grey',
                  backgroundColor: pageNextBoolean ? null : 'transparent',
                  cursor: pageNextBoolean ? 'pointer' : 'auto',
                }}
                onClick={() => {
                  if (pageNextBoolean) {
                    console.log('PAGE NUM')
                    console.log(pageNum)
                    this.CustomerHistoryGetAPIData(pageNum + 2)
                    this.setState({ pageNum: pageNum + 1 })
                  }
                }}
              >
                Next
              </button>
            )
          }}
        />
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    // states
    const { entries } = this.state

    return (
      <div className="customersMainStyle" align="center">
        {this.CustomerTableContainer()}
      </div>
    )
  }
}
