// general: price history modal.

// TODO:
// 1. mobile support

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import PriceHistoryChart from '../../inventory/components/pricehistorychart.js'
// core files
// --- actions
import PacketActions from '../../../core/actions/packets/packetactions'
// --- other core
import Helper from '../../../core/helpers/platformhelper'
import { hideModal } from '../../../core/_actions/modalActions'
// components
import LoadingSpinner from '../../helpers/loadingspinner'

import Modal from '../../layouts/modal'

import PriceHistory from '../../../core/assets/images/icons/pricehistorybtn.js'

type Props = {
  title: string,
  vehicleId: number,
  token: string,
  hideModal: boolean,
}

export class PriceHistoryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      priceHistoryObj: null,
      isLoading: true,
      isEmpty: false,
      aggregate_data: null,
    }

    this._onClose = this.onClose.bind(this)
  }

  state: {
    priceHistoryObj: Object,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetPriceHistory(this.props.vehicleId)
    }
  }

  componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.

    // newProps
    const newSingleVehicleId = newProps.vehicleId
    if (newSingleVehicleId !== this.props.vehicleId) {
      this.onMountGetPriceHistory(newSingleVehicleId)
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    this.setState({ priceHistoryObj: null })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetPriceHistory
  onMountGetPriceHistory(vehicleId: number) {
    // props
    const { token } = this.props

    // Retrieve price change data from API
    if (vehicleId !== null && vehicleId !== undefined) {
      PacketActions.getPriceDeltaPackets(vehicleId, token)
        .then(json => {
          if (json.price_changes && json.price_changes.length > 1) {
            this.setState({
              priceHistoryObj: json.price_changes,
              aggregate_data: json.aggregate_data,
              isLoading: false,
            })
          } else {
            this.setState({
              priceHistoryObj: json.price_changes,
              isLoading: false,
              isEmpty: true,
            })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.props.hideModal()
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  loadingOrEmpty() {
    if (this.state.isLoading) {
      return <LoadingSpinner loading wheel />
    } else if (!this.state.isLoading && this.state.isEmpty) {
      return (
        <div style={{ padding: '15px 25px 10px', lineHeight: '1em' }}>
          This vehicle has no price changes in its history.
        </div>
      )
    }
  }

  // PriceHistoryContainer
  PriceHistoryContainer() {
    // states
    const { priceHistoryObj, aggregate_data } = this.state
    const currency_symbol = this.props.currency_unit.symbol ? this.props.currency_unit.symbol : '$'

    // arrays
    const array = []

    // counters
    let counter = 0

    if (priceHistoryObj && priceHistoryObj.length > 0) {
      for (const entry of priceHistoryObj) {
        // styles
        let color = '#fdbc4d'
        let otherColor = '#fdbc4d'

        // containers
        const change = entry.delta * entry.direction
        let textNumber = Helper.FormatMoneyUSA(change)

        if (entry.original) {
          textNumber = 'orig.'
        } else if (change > 0) {
          color = 'white'
          otherColor = '#fdbc4d'
        } else if (change < 0) {
          color = 'white'
          otherColor = '#3CB54A'
        }

        array.push(
          <tr key={counter++} className="glowy">
            <td style={{ color }}>{Helper.FormatDateUSA(entry.date_changed)}</td>
            <td style={{ color: otherColor }}>{`${
              change < 0 ? '-' : ''
            }${currency_symbol}${new Intl.NumberFormat('en-US', { style: 'decimal' }).format(
              Math.abs(change),
            )}`}</td>
            <td style={{ color }}>
              {currency_symbol}
              {new Intl.NumberFormat('en-US', { style: 'decimal' }).format(entry.price)}
            </td>
          </tr>,
        )
      }
    }

    return (
      <div id="pricehist-header">
        <PriceHistoryChart
          currency_unit={this.props.currency_unit}
          priceHistoryObj={priceHistoryObj}
          aggregate_data={aggregate_data}
        />
        <table>
          <thead>
            <tr>
              <td>
                <b>Date</b>
              </td>
              <td>
                <b>Change</b>
              </td>
              <td>
                <b>Price</b>
              </td>
            </tr>
          </thead>
        </table>
        <table className="pricehist-prices">
          <tbody>{array.reverse()}</tbody>
        </table>
      </div>
    )
  }

  props: Props
  _onClose: Function // function

  render() {
    // states
    const { priceHistoryObj, aggregate_data, isLoading } = this.state
    const { vin, stockNum, currency_unit } = this.props
    const currency_symbol = currency_unit.symbol ? currency_unit.symbol : '$'
    const difference = priceHistoryObj
      ? priceHistoryObj[priceHistoryObj.length - 1].price - priceHistoryObj[0].price
      : 0
    return (
      <Modal>
        <div align="center" id="price-history-box">
          <h1>
            <PriceHistory />
          </h1>

          {!isLoading && (
            <React.Fragment>
              <div className="table-pre-pre-header">
                VIN : {vin && <span className="price-history-id vin">{` ${vin}  `}</span>}
                <br />
                STOCK# :{' '}
                {stockNum && <span className="price-history-id stock">{` ${stockNum}`}</span>}
              </div>
              <table className="pricehist-pretable">
                <tbody>
                  {priceHistoryObj != undefined ? (
                    <tr align="right">
                      <td className="table-title" align="right">
                        Original
                      </td>
                      <td align="center" className="table-colon">
                        :
                      </td>
                      <td align="right" className="table-price">
                        {`${currency_symbol}${new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                        }).format(priceHistoryObj[0].price)}`}
                      </td>
                      <td align="center" className="table-on">
                        on
                      </td>
                      <td align="right">
                        <b>{Helper.FormatDateUSA(priceHistoryObj[0].date_changed)}</b>
                      </td>
                    </tr>
                  ) : null}
                  {priceHistoryObj != undefined ? (
                    <tr align="right">
                      <td className="table-title" align="right">
                        Current
                      </td>
                      <td align="center" className="table-colon">
                        :
                      </td>
                      <td align="right" className="table-price">
                        {`${currency_symbol}${new Intl.NumberFormat('en-US', {
                          style: 'decimal',
                        }).format(priceHistoryObj[priceHistoryObj.length - 1].price)}`}
                      </td>
                      <td align="center" className="table-on">
                        {priceHistoryObj && priceHistoryObj.length > 1 ? 'on' : null}
                      </td>
                      <td align="right">
                        <b>
                          {priceHistoryObj && priceHistoryObj.length > 1
                            ? Helper.FormatDateUSA(
                                priceHistoryObj[priceHistoryObj.length - 1].date_changed,
                              )
                            : null}
                        </b>
                      </td>
                    </tr>
                  ) : null}
                  {priceHistoryObj && priceHistoryObj.length > 1 ? (
                    <tr>
                      <td className="table-title" align="right">
                        Difference
                      </td>
                      <td align="center" className="table-colon">
                        :
                      </td>
                      <td align="right" className="table-price">
                        <span style={{ fontSize: '1em' }}>
                          {`${difference < 0 ? '-' : '+'}${currency_symbol}${new Intl.NumberFormat(
                            'en-US',
                            {
                              style: 'decimal',
                            },
                          ).format(Math.abs(difference))}`}
                        </span>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </React.Fragment>
          )}
          {priceHistoryObj !== null &&
          priceHistoryObj !== undefined &&
          priceHistoryObj.length > 1 &&
          !isLoading
            ? this.PriceHistoryContainer()
            : this.loadingOrEmpty()}
          {priceHistoryObj && priceHistoryObj.length > 1 && !isLoading ? (
            <div className="pricehistory-tooltip">
              <span>Price History is not displayed in sent packets.</span>
            </div>
          ) : null}
        </div>
      </Modal>
    )
  }
}

// ***********************LETS*EXPORT*MODAL***********************
function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    vehicleId: state.modal.props.vehicle_id,
    vin: state.modal.props.vin,
    stockNum: state.modal.props.stock_num,
    token: state.modal.props.token,
    currency_unit: state.modal.props.currency_unit,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(PriceHistoryModal)
