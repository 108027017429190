import React, { Component } from 'react'
import { hideModal } from '../../../core/_actions/modalActions'
import { connect } from 'react-redux'
import { getSupportedStickerpull } from '../../../core/actions/stickerpullactions'

import Modal from '../../layouts/modal'
import LoadingSpinner from '../../helpers/loadingspinner'
import '../styles/stickersupportmodal.scss'

class StickerSupportModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      supportedList: '',
    }
  }
  componentDidMount() {
    //this._getUnsupportedList()
    this.onMountGetSupportedList(this.props.token)
  }
  onMountGetSupportedList(token) {
    getSupportedStickerpull(token).then(res => {
      console.log(res)
      if (res && res.length) {
        this.setState({
          isLoading: false,
          supportedList: res,
        })
      }
    })
  }
  componentWillUnmount() {
    this.state = {
      isLoading: true,
      supportedList: '',
    }
  }
  render() {
    const { isLoading, supportedList } = this.state
    if (!isLoading && supportedList.length > 0) {
      return (
        <Modal>
          <div className="sticker-support-modal">
            <h2>Currently supported Makes & Models</h2>
            <div className="sticker-support-modal-text">
              <div style={{ textAlign: 'left' }} className="sticker-support-list">
                <hr className="coverage-underline" />
                {supportedList &&
                  supportedList.map((item, i) => {
                    if (item.availability == 'Available') {
                      if (item.year_start && item.year_end == null) {
                        return (
                          <h4 key={i} style={{ width: '100%' }}>
                            <b>{`${item.make}`}</b>
                            {` (after ${item.year_start})`}
                          </h4>
                        )
                      }

                      return (
                        <h4 key={i}>
                          <b>{`${item.make}`}</b>
                        </h4>
                      )
                    } else if (item.availability == 'Coming Soon') {
                      return (
                        <h5 key={i} className="sticker-coming-soon">
                          <b>{`${item.make}`}</b>
                          <i>{` coming soon`}</i>
                        </h5>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
        </Modal>
      )
    }
    return (
      <Modal>
        <div className="sticker-support-modal">
          <LoadingSpinner loading wheel />
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  user_id: state.modal.props.user_id,
  token: state.modal.props.token,
  company_id: state.modal.props.token,
})

export default connect(mapStateToProps, {
  hideModalConnect: hideModal,
})(StickerSupportModal)
