import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from '../../layouts/modal'
import DatePicker from 'react-datepicker'
import { setHours, setMinutes, addDays, addHours, getDay } from 'date-fns'
import RichTextEditor from 'react-rte'
import { indexOf } from 'lodash'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import runtimeEnv from '../../../core/config/envConstants'

// components
import Car from '../../inventory/components/car'
import { DELETE_SCHEDULED_PACKET_MODAL } from '../../../core/_constants/modalTypes'

// assets
import EditIcon from '../../../core/assets/images/icons/editicon'
import DeleteIcon from '../../../core/assets/images/icons/icon-trash'
import PlayIcon from '../../../core/assets/images/icons/play-icon'

// styles
import '../styles/sendPacketModal.scss'

// actions
import { editScheduledPacket } from '../../../core/actions/sendpacketactions'
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { stashScheduledPackets } from '../../../core/_actions/packetstashActions'
import { getScheduledPackets } from '../../../core/actions/sendpacketactions'
import { getModules } from '../../../core/actions/sendpacketactions'
import LoadingSpinner from '../../helpers/loadingspinner'
import { getTimezoneOffset } from 'date-fns-tz'

class ScheduledPacketModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accessToken: JSON.parse(localStorage.getItem('covideo-access-token')),
      editting: false,
      loading: false,
      errorMssg: false,
      vehicleDrawer: false,
      // todo: make new upload states
      // date and time
      scheduledTime: setHours(setMinutes(new Date(), 30), 16),
      rescheduledDate: new Date(),
      minTime: null,
      // text
      richTextValue: null,
      formattedTextValue: null,
      // modules
      modules: [],
      checkedModules: [],
      // covideo
      covideo: null,
    }
  }

  componentDidMount() {
    const { payload } = this.props
    const { modules } = this.state
    const packet = payload.packet
    const vehicles = packet.vehicle_ids

    this.setInitialValues()

    this.modules(vehicles[0])

    this.setState({
      checkedModules: packet.module_ids,
      rescheduledDate: packet.scheduled_time,
    })
  }

  modules = vehicle_id => {
    const { abortSignal, payload } = this.props
    const token = payload.token
    const packet = payload.packet
    const module_ids = packet.module_ids

    console.log(module_ids)
    console.log(token, abortSignal, vehicle_id)
    this.setState({ loading: true })
    getModules({
      token: token,
      vehicle_id,
      signal: abortSignal,
    }).then(res => {
      if (res && res.modules) {
        console.log(res.modules)
        this.setState({
          loading: false,
          modules: res.modules.map(module => {
            module.checked = module_ids.includes(module.module_id)
            return module
          }),
        })
      }
    })
  }

  updateModules = (index, module_id) => {
    const { modules, checkedModules } = this.state
    modules[index].checked = !modules[index].checked

    var checkedModulesUpdate = checkedModules

    if (checkedModulesUpdate.includes(module_id)) {
      var index = checkedModulesUpdate.indexOf(module_id)
      checkedModulesUpdate.splice(index, 1)
    } else {
      checkedModulesUpdate.push(module_id)
    }

    console.log(checkedModulesUpdate)
    this.setState({ modules })
  }

  setInitialValues() {
    const { payload } = this.props
    const packet = payload.packet
    const fullDateAndTime = new Date(packet.scheduled_time)
    const initialValue = packet.message

    this.setState({
      scheduledTime: fullDateAndTime,
      richTextValue: RichTextEditor.createValueFromString(initialValue, 'html'),
      formattedTextValue: packet.message,
    })

    if (getDay(fullDateAndTime) > getDay(new Date())) {
      this.setState({
        minTime: setHours(setMinutes(new Date(), 0), 7),
      })
    } else {
      this.setState({
        minTime: setHours(
          setMinutes(new Date(), new Date().getMinutes()),
          new Date().getHours() + 1,
        ),
      })
    }

    if (this.state.accessToken) {
      this.setState({ loading: true })
      const url = `https://api.covideo.com/videos/?start=0`

      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`,
        },
      })
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res) {
            console.log(res)
            console.log(packet.covideo_video_id)
            res.videos.map(video => {
              if (video.id === packet.covideo_video_id) {
                console.log(video.id)
                this.setState({
                  covideo: video,
                  loading: false,
                })
              }
            })
          } else {
            this.setState({
              loading: false,
            })
          }
        })
    }
  }

  updateData(date) {
    if (getDay(date) > getDay(new Date())) {
      this.setState({
        minTime: setHours(setMinutes(new Date(), 0), 7),
        scheduledTime: date,
        rescheduledDate: date,
      })
    } else {
      this.setState({
        minTime: setHours(
          setMinutes(new Date(), new Date().getMinutes()),
          new Date().getHours() + 1,
        ),
        scheduledTime: date,
        rescheduledDate: date,
      })
    }
  }

  handleCovideoStartAndStop(event, action) {
    var playPromise = event.target.play()

    if (action == 'stop') {
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            event.target.pause()
          })
          .catch(error => {})
      }
    }

    if (playPromise !== undefined) {
      playPromise.then(_ => {}).catch(error => {})
    }
  }

  render() {
    const { payload } = this.props
    const { modules, covideo, loading, rescheduledDate } = this.state
    const packet = payload.packet
    const module_ids = packet.module_ids
    const vehicles = packet.vehicle_ids
    const vehicle_info = packet.vehicles
    const token = payload.token
    const customers = packet.customers
    const packet_customer_ids = packet.customer_ids

    var scheduledPacketSendDate = new Date(rescheduledDate)

    return (
      <Modal>
        <div className="scheduleDetailSection">
          {!this.state.editting ? (
            <React.Fragment>
              <div className="scheduleDetail">
                <div
                  className={
                    this.state.vehicleDrawer ? 'scheduleDetailDrawer open' : 'scheduleDetailDrawer'
                  }
                  onClick={() => {
                    this.setState({
                      vehicleDrawer: !this.state.vehicleDrawer,
                    })
                  }}
                >
                  <b>{this.state.vehicleDrawer ? 'Hide' : 'Show'} Vehicle Information</b>
                </div>
                <div
                  className={
                    this.state.vehicleDrawer
                      ? 'scheduleDetailDrawerInfo open'
                      : 'scheduleDetailDrawerInfo'
                  }
                  onClick={() => {
                    this.setState({
                      vehicleDrawer: !this.state.vehicleDrawer,
                    })
                  }}
                >
                  {vehicle_info.length == 1 ? (
                    <div>
                      <Car
                        animation={'none'}
                        animationDelay={1 * 30}
                        viewPacket={true}
                        key={`${vehicle_info[0].vehicle_id}`}
                        vehicle={vehicle_info[0]}
                        miniMode={true}
                      />
                    </div>
                  ) : (
                    vehicle_info.map((vehicle, index) => (
                      <div>
                        <Car
                          animation={'none'}
                          animationDelay={index * 30}
                          viewPacket={true}
                          key={`${vehicle.vehicle_id}`}
                          vehicle={vehicle}
                          miniMode={true}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="scheduleDetail">
                To:{' '}
                {customers.map((customer, i) => (
                  <b>
                    {customer.first_name} {customer.last_name}
                    {customers.length < 1 ? '' : customers.length !== i + 1 ? ', ' : ''}
                  </b>
                ))}
              </div>
              <div
                className="scheduleDetail"
                onClick={() => {
                  this.setState({ errorMssg: true })
                }}
              >
                Scheduled Send Date:{' '}
                <b>
                  {scheduledPacketSendDate.toLocaleString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour12: 'true',
                    hour: 'numeric',
                    minute: '2-digit',
                  })}
                </b>
              </div>
              <div className="scheduleDetail">
                <div>Packet Message:</div>
                <div
                  onClick={() => {
                    this.setState({ errorMssg: true })
                  }}
                  dangerouslySetInnerHTML={{ __html: this.state.formattedTextValue }}
                  className="packet-message"
                />
              </div>
              {covideo && !loading ? (
                <div className="scheduleDetail">
                  <div>Covideo:</div>
                  <div className="covideo">
                    <video
                      className="dashboard-covideo-player"
                      id="dashboardCovideo"
                      onMouseOver={event => (
                        this.handleCovideoStartAndStop(event, 'start'),
                        this.setState({
                          playing: covideo.id,
                        })
                      )}
                      onMouseOut={event => (
                        this.handleCovideoStartAndStop(event, 'stop'),
                        this.setState({
                          playing: -1,
                        })
                      )}
                      poster={covideo.autogeneratedThumbnail}
                    >
                      <source src={covideo.videoSource} type="video/mp4" />
                    </video>
                    <div
                      className="covideo-overlay"
                      style={{ display: this.state.playing === covideo.id ? 'none' : 'block' }}
                    >
                      <PlayIcon />
                    </div>
                  </div>
                </div>
              ) : covideo ? (
                <LoadingSpinner loading />
              ) : null}

              {vehicle_info.length == 1 && (
                <div
                  className="scheduleDetail"
                  onClick={() => {
                    this.setState({ errorMssg: true })
                  }}
                >
                  <div>Modules:</div>
                  <div className="scheduledModules">
                    {modules.map((module, i) => (
                      <div
                        key={module.module_id}
                        className={`send-modal-module-type ${module.checked ? 'active ' : ''}${
                          !module.enabled ? 'disabled' : ''
                        }`}
                        disabled={!module.enabled}
                      >
                        <input
                          type="checkbox"
                          checked={module.checked && module.enabled}
                          id={module.module_id}
                          key={module.module_id}
                          disabled={!module.enabled}
                          readOnly
                        />
                        {module.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', height: '50px' }}>
                <div
                  className="edit-error-message"
                  style={{ display: !this.state.errorMssg ? 'none' : 'flex' }}
                >
                  Use the "Edit Scheduled Packet" button to change your packet.
                </div>
              </div>
              <div className="schedule-button-row">
                <button
                  onClick={() => {
                    this.setState({ editting: true, errorMssg: false })
                  }}
                  className="edit-packet"
                >
                  <div className="button-contents">
                    <div>Edit Scheduled Packet</div>
                    <div>
                      <EditIcon />
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => {
                    this.props.showModalConnect(DELETE_SCHEDULED_PACKET_MODAL, {
                      recipient: customers,
                      date: scheduledPacketSendDate,
                      id: packet.id,
                    })
                  }}
                  className="delete-packet"
                >
                  <div className="button-contents">
                    <div>Delete Scheduled Packet</div>
                    <div>
                      <DeleteIcon />
                    </div>
                  </div>
                </button>
              </div>
              <br />
              <div style={{ display: !loading ? 'none' : 'block' }}>
                <LoadingSpinner loading />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="scheduleDetail">
                <div
                  className={
                    this.state.vehicleDrawer ? 'scheduleDetailDrawer open' : 'scheduleDetailDrawer'
                  }
                  onClick={() => {
                    this.setState({
                      vehicleDrawer: !this.state.vehicleDrawer,
                    })
                  }}
                >
                  <b>{this.state.vehicleDrawer ? 'Hide' : 'Show'} Vehicle Information</b>
                </div>
                <div
                  className={
                    this.state.vehicleDrawer
                      ? 'scheduleDetailDrawerInfo open'
                      : 'scheduleDetailDrawerInfo'
                  }
                  onClick={() => {
                    this.setState({
                      vehicleDrawer: !this.state.vehicleDrawer,
                    })
                  }}
                >
                  {vehicle_info.length == 1 ? (
                    <div>
                      <Car
                        animation={'none'}
                        animationDelay={1 * 30}
                        viewPacket={true}
                        key={`${vehicle_info[0].vehicle_id}`}
                        vehicle={vehicle_info[0]}
                        miniMode={true}
                      />
                    </div>
                  ) : (
                    vehicle_info.map((vehicle, index) => (
                      <div>
                        <Car
                          animation={'none'}
                          animationDelay={index * 30}
                          viewPacket={true}
                          key={`${vehicle.vehicle_id}`}
                          vehicle={vehicle}
                          miniMode={true}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="scheduleDetail">
                To:{' '}
                {customers.map(customer => (
                  <b>
                    {customer.first_name} {customer.last_name}
                  </b>
                ))}
              </div>
              <div className="scheduleDetail">
                Scheduled Send Date:
                <DatePicker
                  selected={this.state.scheduledTime}
                  onChange={date => this.updateData(date)}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={new Date()}
                  minTime={this.state.minTime}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                />
              </div>
              <div className="scheduleDetail">
                <div>Packet Message:</div>
                <RichTextEditor
                  value={this.state.richTextValue}
                  className="packet-message-edit"
                  onChange={richTextValue =>
                    this.setState({
                      richTextValue: richTextValue,
                      formattedTextValue: richTextValue.toString('html'),
                    })
                  }
                />
              </div>
              {covideo && !loading ? (
                <div className="scheduleDetail">
                  <div>Covideo:</div>
                  <div className="covideo">
                    <video
                      className="dashboard-covideo-player"
                      id="dashboardCovideo"
                      onMouseOver={event => (
                        event.target.play(),
                        this.setState({
                          playing: covideo.id,
                        })
                      )}
                      onMouseOut={event => (
                        event.target.pause(),
                        this.setState({
                          playing: -1,
                        })
                      )}
                      poster={covideo.autogeneratedThumbnail}
                    >
                      <source src={covideo.videoSource} type="video/mp4" />
                    </video>
                  </div>
                </div>
              ) : covideo ? (
                <LoadingSpinner loading />
              ) : null}

              {vehicle_info.length == 1 && (
                <div className="scheduleDetail">
                  <div>Modules:</div>
                  <div className="scheduledModules">
                    {modules.map((module, i) => (
                      <div
                        key={module.module_id}
                        className={`editable send-modal-module-type ${
                          module.checked ? 'active ' : ''
                        }${!module.enabled ? 'disabled' : ''}`}
                        onClick={() => {
                          this.updateModules(i, module.module_id)
                        }}
                        disabled={!module.enabled}
                      >
                        <input
                          type="checkbox"
                          checked={module.checked && module.enabled}
                          id={module.module_id}
                          key={module.module_id}
                          disabled={!module.enabled}
                          readOnly
                        />
                        {module.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="schedule-button-row">
                <button
                  onClick={() => {
                    this.setState({
                      editting: false,
                      // doesn't work as intended
                      //formattedTextValue: packet.message,
                      //richTextValue: RichTextEditor.createValueFromString(initialValue, 'html'),
                    })
                  }}
                  className="edit-packet cancel"
                >
                  Undo Edits
                </button>
                <button
                  className="confirm-edit"
                  onClick={() => {
                    this.setState({ loading: true })
                    editScheduledPacket({
                      token: token,
                      accessToken: this.props.accessToken,
                      share_types: [packet.share_types],
                      show_price: packet.show_price,
                      customer_ids: packet_customer_ids,
                      files: packet.files,
                      id: packet.id,
                      vehicle_ids: vehicles,
                      scheduledTime: this.state.scheduledTime,
                      message: this.state.formattedTextValue,
                      module_ids: module_ids,
                      covideo_id: packet.covideo_video_id,
                      accessToken: this.state.accessToken,
                    })
                      .then(res => {
                        if (res) {
                          toast(`Successfully edited packet.`)
                          getScheduledPackets(token)
                            .then(res => {
                              if (res) {
                                this.props.stashScheduledPacketsConnect(res)
                                this.setState({ editting: false, loading: false })
                              }
                            })
                            .catch(error => {
                              toast('An error has occurred')
                              console.log(error)
                            })
                        }
                      })
                      .catch(error => {
                        toast('An error has occurred')
                        console.log(error)
                      })
                  }}
                >
                  Confirm Changes
                </button>
              </div>
              <div style={{ display: !loading ? 'none' : 'block' }}>
                <LoadingSpinner loading />
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  payload: state.modal.sharedProps,
})

export default connect(mapStateToProps, {
  hideModalConnect: hideModal,
  showModalConnect: showModal,
  stashScheduledPacketsConnect: stashScheduledPackets,
})(ScheduledPacketModal)
