import React from 'react'

const ReportIssueIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        className="a"
        d="M25,6.73c-10.94,0-19.8,7.58-19.8,16.94a15.52,15.52,0,0,0,4.88,11.1.47.47,0,0,1,.13.37l-1,7.62a.46.46,0,0,0,.68.45l6.73-4a.42.42,0,0,1,.38,0,22.57,22.57,0,0,0,8,1.46c10.94,0,19.8-7.58,19.8-16.93S35.94,6.73,25,6.73Z"
      />
      <path
        className="b"
        d="M28.78,16.41a20.18,20.18,0,0,1-.38,3.46,41.32,41.32,0,0,1-1,4.1,17.34,17.34,0,0,1-1,2.84,1.73,1.73,0,0,1-.53.67,1.53,1.53,0,0,1-.87.2,1.59,1.59,0,0,1-.86-.19,2,2,0,0,1-.55-.68,17.85,17.85,0,0,1-1.05-2.89c-.38-1.29-.7-2.63-1-4a18.55,18.55,0,0,1-.4-3.51,5.91,5.91,0,0,1,1.2-4.09A3.51,3.51,0,0,1,25,11a3.41,3.41,0,0,1,2.58,1.3A6.06,6.06,0,0,1,28.78,16.41Z"
      />
      <path
        className="b"
        d="M28.39,32.64a3.33,3.33,0,0,1-1,2.4A3.21,3.21,0,0,1,25,36a3.41,3.41,0,0,1-2.94-1.69,3.21,3.21,0,0,1-.48-1.69,3.27,3.27,0,0,1,.47-1.7,3.35,3.35,0,0,1,1.25-1.23,3.33,3.33,0,0,1,3.38,0A3.26,3.26,0,0,1,28,30.94,3.39,3.39,0,0,1,28.39,32.64Z"
      />
    </svg>
  )
}
export default ReportIssueIcon
