import { reactAdminConstants } from '../_constants/reactadminconstants'
// onChangeTimeInterval
function onChangeTimeInterval(title: string, startDate: string, endDate: string) {
  return function(dispatch: boolean) {
    if (title === 'Custom') {
      const interval = { startDate, endDate }
      dispatch({ type: reactAdminConstants.SET_TIME_INTERVAL_CUSTOM, interval })
      dispatch({ type: reactAdminConstants.SET_TIME_INTERVAL, title })
    } else {
      dispatch({ type: reactAdminConstants.SET_TIME_INTERVAL, title })
    }
  }
}

export { onChangeTimeInterval }
