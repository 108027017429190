import React from 'react'

const UploadIcon = props => {
  const color = props.color ? props.color : '#000000'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill={color} d="M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z" />
    </svg>
  )
}
export default UploadIcon
