import React, { useState, useEffect } from 'react'

//banner styles
import '../styles/notificationBanner.scss'

const BannerContent = ({ storeName, image, link, cancelIcon }) => {
  const ignoreAppStoreToken = localStorage.getItem('ignoreAppStoreToken')
  const [showBanner, setShowBanner] = useState(false)
  useEffect(() => {
    if (ignoreAppStoreToken === null) {
      setShowBanner(false)
    } else {
      setShowBanner(true)
    }
  }, [])

  const handleClick = () => {
    localStorage.setItem('ignoreAppStoreToken', 'true')
    setShowBanner(!showBanner)
  }

  if (!showBanner) {
    return (
      <div className="outerContainer">
        <div className="bannerContainer">
          <a href={link}>
            <div className="imageContainer">
              <img src={image} alt="iPacket Logo" />
            </div>
            <div className="textContainer">
              Try our mobile app available at the {storeName} store!
            </div>
          </a>
          <img
            src={cancelIcon}
            className="cancelIcon"
            onClick={() => handleClick()}
            alt="Click to cancel"
          />
        </div>
      </div>
    )
  }

  return null
}

export default BannerContent
