// general: entire page not found component.

// TODO:
// 1. mobile support

// Note: all styles for nopage are contained in here, or imported from other
// component .css files due to there not being much .css for this component at the time.

// @flow
import React, { PureComponent } from 'react'
// core files
import Helper from '../core/helpers/platformhelper'
// css styles
import './styles/universalstyles.scss'
import './login/styles/login.scss'

// global styles
const noPageStyle = {
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

// global variable
import Logo from './layouts/logo'

type Props = {} // none.

export default class NoPageComponent extends PureComponent {
  props: Props
  componentDidMount() {
    this.redirect = setTimeout(() => {
      window.location.href = '/packets'
    }, 2000)
  }
  componentWillUnmount() {
    clearTimeout(this.redirect)
  }
  componentDidUn
  render() {
    return (
      <div>
        <div style={noPageStyle}>
          <div id="noPage">
            <div id="noPageMid">
              <h4 className="loginTitleTextStyle">Page Not Found Redirecting...</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
