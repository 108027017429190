// general:

// @flow
import React, { Component } from 'react'
// node packages
import { Link } from 'react-router-dom'
// --- redux
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { EmptyPacketAllConst } from '../../core/_actions/emptystoreActions'
import {
  stashVehicles,
  stashModules,
  stashVehicleImages,
  stashModuleVideos,
} from '../../core/_actions/packetstashActions'
import { packetViewActive } from '../../core/_actions/packetviewActions'
// core files
// --- actions
import PacketActions, { recordPacketView } from '../../core/actions/packets/packetactions'
// --- wrappers
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// components
import PacketLeft from './columns/packetleftcolumn'
import PacketCenter from './columns/packetcentercolumn'
import PacketRight from './columns/packetrightcolumn'
// icons
import IconChevronLeft from '../../core/assets/images/icons/icon-chevron-left.js'
// helpers
import PrivacySlide from '../helpers/privacyslide'
import LoadingSpinner from '../helpers/loadingspinner'
// css styles
import '../styles/universalstyles.scss'
import './styles/packetsend.scss'

class PacketViewComponent extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      userInfo: this.props.userInfo,
      loadingImages: true,
      loadingModules: true,
      loadingVehicles: true,
    }
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      window.scrollTo(0, 0) // eslint-disable-line no-undef
      this.props.packetViewActive('general_info')
      this.onMountGetContent()
    }
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({ userInfo: newProps.userInfo })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({
      userInfo: null,
      loadingImages: true,
      loadingModules: true,
      loadingVehicles: true,
    })
  }

  //
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetContent
  onMountGetContent() {
    // --- get page focus
    // eslint-disable-next-line no-undef
    const pagefocusFirstHalf = window.location.href.split('packet/')[1]
    // eslint-disable-next-line no-undef
    const vehicleId = pagefocusFirstHalf.split('/')[1]

    if (vehicleId !== null && vehicleId !== undefined) {
      this.onMountGetVehiclePacket(vehicleId)
      this.onMountGetVehicleModules(vehicleId)
      this.onMountGetPacketImages(vehicleId)
      this.onMountRecordPacketView(vehicleId)
    }
  }
  onMountRecordPacketView(vehicle_id) {
    const token = this.state.userInfo.dj_token
    if (this.props.userIsStaff) {
      // do nothing
    } else {
      recordPacketView({ token, vehicle_id })
    }
  }
  // onMountGetVehiclePacket
  // - get the packet data from correct vehicleId prop.
  async onMountGetVehiclePacket(vehicleId: number) {
    const token = this.state.userInfo.dj_token
    if (token !== null && token !== undefined) {
      const vehicleInfo = await PacketActions.getVehicleInfo(vehicleId, token)
      // --- save for packet send and stash for unmount
      console.log('Vehicle Info')
      console.log(vehicleInfo)
      const vehicleIdArray = []
      vehicleIdArray.push(vehicleId)
      this.props.stashVehicles(vehicleIdArray, vehicleInfo)
      // --- set relavent states
      if (!vehicleInfo) {
        this.props.history.push('/page-not-found')
      } else {
        this.setState({ loadingVehicles: vehicleInfo.loading })
      }
    }
  }

  // onMountGetVehicleModules
  async onMountGetVehicleModules(vehicleId: number) {
    const token = this.state.userInfo.dj_token
    if (token !== null && token !== undefined) {
      const moduleInfo = await PacketActions.getPacketSendModules(vehicleId, token)
      console.log(moduleInfo)
      // --- save for packet send and stash for unmount
      this.props.stashModules(moduleInfo.id_array, moduleInfo.results)
      const videoModules = moduleInfo.results.filter(d => {
        return d.content_type === 'html5_video' || d.content_type === 'youtube_video'
      })
      console.log(videoModules)
      this.props.stashModuleVideos(videoModules)
      // --- set relavent states
      this.setState({ loadingModules: moduleInfo.loading })
    }
  }

  // onMountGetPacketImages
  async onMountGetPacketImages(vehicleId: number) {
    const token = this.state.userInfo.dj_token
    if (token !== null && token !== undefined) {
      const d = await PacketActions.getPacketSendImages(vehicleId, token)
      console.log(d)
      // --- stash for unmount
      this.props.stashVehicleImages(d)
      // --- set relavent states
      this.setState({ loadingImages: d.loading })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************OTHER FUNCTIONS******************************
  goToPreviousPage() {
    const { urlHistoryStash } = this.props
    const previousUrlToNavigate = urlHistoryStash
      .slice()
      .reverse()
      .find(
        url =>
          url.pathname == '/packets' ||
          url.pathname == '/dashboard' ||
          url.pathname == '/reports/status-report',
      )
    if (previousUrlToNavigate) {
      this.props.history.push(previousUrlToNavigate.pathname)
    } else {
      this.props.history.push('/packets')
    }
  }

  // ***********************LETS*SETUP*CONTAINERS***********************
  // PacketSendTopContainer
  PacketSendTopContainer(loadingBoolean) {
    // Please forgive me for not properly fixing this function
    console.log('router here', this.props)
    if (loadingBoolean) {
      return (
        <div className="packetSendTopBarInnerStyle flexRowSpace">
          {this.props.vehicle_info && !this.props.vehicle_info.archived ? (
            <React.Fragment>
              <button
                to={{ pathname: '/packets', state: this.props.location.state }}
                className="packetSendTopBarLinkStyle"
                onClick={() => {
                  this.goToPreviousPage()
                  EmptyPacketAllConst()
                }}
              >
                <IconChevronLeft />
                Back
              </button>
              <PrivacySlide />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Link
                to={{
                  pathname: '/archived-inventory',
                  state: this.props.location.state,
                }}
                className="packetSendTopBarLinkStyle"
                onClick={() => {
                  EmptyPacketAllConst()
                }}
              >
                <IconChevronLeft />
                Back
              </Link>
            </React.Fragment>
          )}
        </div>
      )
    }
    return <div className="packetSendTopBarInnerStyle flexRowSpace" />
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    // states
    const { userInfo, loadingVehicles, loadingImages, loadingModules } = this.state
    const { vehicle_info } = this.props
    // booleans
    const loadingBoolean =
      !loadingImages &&
      !loadingModules &&
      !loadingVehicles &&
      userInfo !== null &&
      userInfo !== undefined

    return (
      <div id="packetSendStyle">
        <div className="flexColumn packetSendMainStyle">
          <div className="packetSendTopBarContainerStyle">
            {this.PacketSendTopContainer(loadingBoolean)}
          </div>

          {vehicle_info && loadingBoolean ? (
            <div
              className="flexRowSpace packetSendContentContainerStyle"
              style={{ alignItems: 'flex-start' }}
            >
              {(!vehicle_info.archived || (vehicle_info.archived && vehicle_info.date_created)) && (
                <PacketLeft />
              )}
              <PacketCenter userInfo={userInfo} />
              {(!vehicle_info.archived || (vehicle_info.archived && vehicle_info.date_created)) && (
                <PacketRight userInfo={userInfo} />
              )}
            </div>
          ) : (
            <div style={{ position: 'relative', margin: '0 auto 0 auto' }}>
              <LoadingSpinner loading wheel />
              <div style={{ height: '100vh' }} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userIsStaff:
    state.authentication.user.user.is_staff != undefined && state.authentication.user.user.is_staff,
  vehicle_info: state.packetstash.vehicle_info,
  urlHistoryStash: state.stashUrlHistory.user_history,
})

const PacketView = UserInfoWrapper(withRouter(PacketViewComponent))
export default connect(mapStateToProps, {
  stashVehicles,
  stashModules,
  stashVehicleImages,
  packetViewActive,
  stashModuleVideos,
})(PacketView)
