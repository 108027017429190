import React from 'react'

const SoldIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 16.97">
      <path className="a" d="M.43.4V16.57H39.57V.4Zm36.23,14H1.89V1.65H36.66Z" />
      <rect className="b" x="1.89" y="1.65" width="34.77" height="12.71" />
      <path
        className="a"
        d="M13.35,6a1,1,0,0,0-.5-.33,1.91,1.91,0,0,0-.6-.11,1.88,1.88,0,0,0-.33.05,1.18,1.18,0,0,0-.33.12.76.76,0,0,0-.25.22.58.58,0,0,0-.09.33.52.52,0,0,0,.24.47,2.24,2.24,0,0,0,.58.28l.77.22a3,3,0,0,1,.76.32,1.75,1.75,0,0,1,.59.56,1.67,1.67,0,0,1,.24.95,2.11,2.11,0,0,1-.2,1,1.9,1.9,0,0,1-.56.72,2.55,2.55,0,0,1-.84.42,3.68,3.68,0,0,1-1,.15,3.88,3.88,0,0,1-1.24-.19,3.05,3.05,0,0,1-1.07-.64l1.06-1.19a1.6,1.6,0,0,0,.59.47,1.72,1.72,0,0,0,.73.16,2.2,2.2,0,0,0,.37,0,1.16,1.16,0,0,0,.34-.14.73.73,0,0,0,.24-.22.57.57,0,0,0,.09-.32.58.58,0,0,0-.25-.49,2.16,2.16,0,0,0-.59-.29l-.78-.25a3.87,3.87,0,0,1-.78-.32,1.89,1.89,0,0,1-.6-.56,1.63,1.63,0,0,1-.25-.91,2.13,2.13,0,0,1,.21-1,2.08,2.08,0,0,1,.57-.71,2.62,2.62,0,0,1,.83-.43,3.93,3.93,0,0,1,1-.15,4.22,4.22,0,0,1,1.13.15,2.7,2.7,0,0,1,1,.54Z"
      />
      <path
        className="a"
        d="M15.17,7.77a3.77,3.77,0,0,1,.26-1.49,3.37,3.37,0,0,1,.75-1.15,3.47,3.47,0,0,1,1.16-.73,4.38,4.38,0,0,1,1.48-.27,4.18,4.18,0,0,1,1.48.23,3.36,3.36,0,0,1,1.18.71,3.32,3.32,0,0,1,.78,1.12,3.77,3.77,0,0,1,.29,1.49,3.77,3.77,0,0,1-.25,1.49,3.25,3.25,0,0,1-.76,1.15,3.37,3.37,0,0,1-1.16.73,4.36,4.36,0,0,1-1.47.27,4.14,4.14,0,0,1-1.48-.23,3.27,3.27,0,0,1-1.18-.71,3.21,3.21,0,0,1-.79-1.12A4.16,4.16,0,0,1,15.17,7.77Zm1.57,0a2.26,2.26,0,0,0,.16.88,1.9,1.9,0,0,0,.44.69,2,2,0,0,0,.67.46,2.5,2.5,0,0,0,.88.15,2.22,2.22,0,0,0,.87-.18,2.1,2.1,0,0,0,.67-.46,2.19,2.19,0,0,0,.42-.71A2.46,2.46,0,0,0,21,7.7a2.75,2.75,0,0,0-.16-.88,2,2,0,0,0-.44-.69,2,2,0,0,0-.68-.46,2.42,2.42,0,0,0-.88-.15A2.22,2.22,0,0,0,18,5.7a2,2,0,0,0-.66.46,1.89,1.89,0,0,0-.42.71A2.32,2.32,0,0,0,16.74,7.75Z"
      />
      <path className="a" d="M23.59,4.24H25.1l.07,5.45,2.79,0L28,11l-4.3.06Z" />
      <path
        className="a"
        d="M28.73,4.18l2.26,0a6.12,6.12,0,0,1,1.56.17,3.48,3.48,0,0,1,1.3.57A3,3,0,0,1,34.74,6a3.66,3.66,0,0,1,.34,1.59A3.14,3.14,0,0,1,34,10.08a3.71,3.71,0,0,1-1.22.67,4.41,4.41,0,0,1-1.45.24l-2.47,0ZM30.3,9.61h.78a3.68,3.68,0,0,0,1-.12,2,2,0,0,0,.78-.36,1.74,1.74,0,0,0,.51-.65,2.28,2.28,0,0,0,.17-1,1.86,1.86,0,0,0-.2-.87A1.77,1.77,0,0,0,32.8,6a2.59,2.59,0,0,0-.75-.35,3.39,3.39,0,0,0-.91-.11h-.89Z"
      />
      <circle className="c" cx="5.41" cy="7.72" r="2" />
    </svg>
  )
}
export default SoldIcon
