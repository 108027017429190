import React, { Component } from 'react'
import { connect } from 'react-redux'
import { togglePrivacy } from '../../core/_actions/helperActions'
import './styles/privacyslide.scss'

class PrivacySlide extends Component {
  privacyToggle = () => {
    const presentationModeToken = localStorage.getItem('presentationModeToken')
    this.props.togglePrivacyConnect()
    if (presentationModeToken === null) {
      localStorage.setItem('presentationModeToken', 'true')
    } else {
      localStorage.removeItem('presentationModeToken', 'false')
    }
  }
  render() {
    const { isPrivate, togglePrivacyConnect } = this.props
    return (
      <div>
        <div className="privacySlide" style={{ animation: ` 1500ms fadeIn`, alignItems: 'center' }}>
          <span
            className={`packetSendItalicStyle ${isPrivate ? 'transparent' : ''}`}
            style={{ fontSize: '1.0em' }}
          >
            Presentation Mode
          </span>
          <label className="switch">
            <input onChange={() => this.privacyToggle()} type="checkbox" checked={isPrivate} />
            <span className="slider round" />
          </label>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  isPrivate: state.helpers.isPrivate,
})
export default connect(mapStateToProps, {
  togglePrivacyConnect: togglePrivacy,
})(PrivacySlide)
