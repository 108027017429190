// general:

// @flow
import React, { Component } from 'react'
// css styles
import '../../../../styles/universalstyles.scss'
import '../../../styles/packetsend.scss'
import '../../../styles/repairorder.scss'

type Props = { repair: Object }

export default class DescriptionChild extends Component {
  constructor(props: Props) {
    super(props)
    this.state = { repair: this.props.repair }
  }

  state: { repair: Object }

  // componentDidMount
  // - set mount to true.
  componentDidMount() {
    this.mounted = true
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({ repair: newProps.repair })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ repair: null })
  }

  parseRepairOrderLines(repairOrderObj, indexNum) {
    const { complaint, correction_1, correction_2, op_code } = repairOrderObj
    let loopCount = 0
    let lineCount = 0
    console.log(repairOrderObj)
    return (
      <div key={indexNum}>
        <div className="ro-complaint">
          {op_code} - {complaint}
        </div>
        <div className="ro-corrections">{correction_1.toUpperCase()}</div>
        <div className="ro-corrections">{correction_2.toUpperCase()}</div>
      </div>
    )
  }

  props: Props

  render() {
    const { repair } = this.state
    if (repair !== null && repair !== undefined) {
      const {
        advisor,
        date,
        first_name,
        invoice,
        last_name,
        owed_by_customer,
        repair_items,
        repair_order_number,
        store_address_1,
        store_address_2,
        store_city,
        store_logo,
        store_phone,
        store_state,
        store_zip,
        total_charges,
        vehicle_make,
        vehicle_miles,
        vehicle_model,
        vehicle_stock,
        vehicle_vin,
        vehicle_year,
      } = repair

      return (
        <div className="ro-wrap">
          <div className="ro">
            <div className="ro-section-header">
              <div className="ro-row flexrow">
                <div className="ro-header-left">
                  <img className="ro-logo" src={store_logo} />
                  <div className="ro-address">
                    <div className="ro-address-one">
                      {store_address_1}
                      {store_address_2 != '' ? store_address_2 : null}
                      {store_city},{store_state}
                      {store_zip}
                    </div>
                    <div className="ro-phone">{store_phone}</div>
                  </div>
                </div>

                <div className="ro-header-right">
                  <div id="ro-barcode-target" className="ro-barcode-target" />
                </div>
              </div>
            </div>

            <div className="ro-section-subheader">
              <div className="ro-row flexrow">
                <div className="ro-vehc-info">
                  VIN: {vehicle_vin}
                  <br />
                  {`${vehicle_year} ${vehicle_make} ${vehicle_model}`}
                  <br />
                  MILES: {vehicle_miles} STOCK#: {vehicle_stock}
                  <br />
                  {`${first_name} ${last_name}`}
                  <br />
                  <br />
                </div>
                <div className="ro-info">
                  RO No: {repair_order_number}
                  <br />
                  {/*DATE: {date} (removed 7-19-19) */}
                  <br />
                  INVOICE: {invoice}
                  <br />
                  ADVISOR: {advisor}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>

            <div className="ro-row">
              <div className="ro-invoice">INVOICE</div>
            </div>

            <div className="ro-section-invoice">
              <div className="ro-row">
                <div className="ro-lines">
                  <div className="ro-row flexrow">
                    <div className="ro-line-total">LINE TOTAL</div>
                    <div className="ro-hourly-rate">HOURLY CHARGE</div>
                  </div>

                  {repair_items &&
                    repair_items.length > 0 &&
                    repair_items.map((d, index) => {
                      return this.parseRepairOrderLines(d, index)
                    })}
                </div>
              </div>
            </div>
            <div className="ro-row">
              <div className="ro-charges">
                <span>TOTAL CHARGES:</span>
                <span> ${total_charges}</span> <br />
                <span className="ro-owed">OWED BY CUSTOMER: $0.00</span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return null
  }
}
