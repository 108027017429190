import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../../../core/config/aipDjapi'

function postArcProcessingRequest(token, vin, mileage, zip) {
  if (token && vin && mileage) {
    let url = `${AIP_DJAPI_URLS.ArcDataAPIURL}?vin=${vin}&mileage=${mileage}${
      zip ? `&zip=${zip}` : ''
    }`
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
    return AIP_DJAPI(url, {
      method: 'POST',
      headers,
    }).then(response => {
      return response.json()
    })
  }
}
function getArcData(token, task_id) {
  if (token) {
    let url = `${AIP_DJAPI_URLS.ArcDataAPIURL}/${task_id}`
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
    return AIP_DJAPI(url, {
      headers,
    }).then(response => {
      return response.json()
    })
  }
}

export { postArcProcessingRequest, getArcData }
