// general: file that keeps all the api end-points and valid end-point(s) list.

import runtimeEnv from '../config/envConstants'
// Note:
// STAGING is reccommended for development by back-end team;
// use DEV at your own risk.

// ***********************DJANGO*END*POINTS***********************
// django end-point base(s)
// --- choose API endpoint based on hostname
export const DJangoAPIBaseURL = (() => {
  // eslint-disable-next-line no-undef
  if (process.env.API_URL) {
    return process.env.API_URL + '/'
  } else {
    switch (window.location.hostname) {
      // prod
      case 'dpapp.autoipacket.com':
      case 'app.autoipacket.com':
        return 'https://djapi.autoipacket.com/'
      // dev
      case 'dpapp.autoipacket.net':
        return 'https://djapi.autoipacket-stg.com/'
      // staging
      case 'staging-dpapp.autoipacket.com':
        return 'https://djapi.autoipacket-stg.com/'
      // 3rd party
      case '3pt-staging-dpapp.autoipacket.com':
        return 'https://3pt-djapi.autoipacket-stg.com/'
      case 'dpapp.autoipacket-stg.com':
        return 'https://djapi.autoipacket-stg.com/'
      case 'exp-dpapp.autoipacket.com':
        return 'https://exp-djapi.autoipacket.com/'
      // localhost is used in a few different environs
      case 'localhost':
        if (runtimeEnv.usingOwnAPI) {
          // to defer to a local API
          return 'http://localhost:8000'
        } else if (runtimeEnv.isProd) {
          // in a container to leverage a host-layer API
          return 'http://host.docker.internal:8000/'
        }
        // in development normally to defer to staging
        return 'https://djapi.autoipacket-stg.com/'

      default:
        return 'https://djapi.autoipacket-stg.com/'
    }
  }
})()

//
// VERSIONING
const DJV1 = 'v1/'

//
//
//
// USER URLS
// user login - POST -> render location: autoipacket.js
export const DJangoLoginAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/login/`
// user logout - POST -> render location: autoipacket.js
export const DJangoLogoutAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/logout/`
// user info - GET
export const DJangoUserInfo = `${DJangoAPIBaseURL}${DJV1}users/auth/login/info/`
// user password reset - GET -> render location: login.js
export const DJangoLoginResetPasswordAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/account/password/reset/`
// user password - checks a users migration status on a password request - POST -> render location: autoipacket.js
export const DJangoUserMigratedAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/user-migrated/`
// user password - resets a users password request - POST -> render location: autoipacket.js
export const DJangoResetPasswordRequestAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/legacy/reset-password/`
// user password - resets a users password - POST -> render location: autoipacket.js
export const DJangoResetPasswordAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/legacy/reset-password/confirm/`
// user password - checks a users password strength - POST -> render location: useractions.js
export const DJangoResetPasswordStrengthAPIURL = `${DJangoAPIBaseURL}password-evaluation/`

export const DjangoChangePasswordAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/legacy/change-password/`
export const DjangoAuthUserDetail = `${DJangoAPIBaseURL}${DJV1}users/user-detail/`

export const DjangoTOSAgreement = `${DJangoAPIBaseURL}${DJV1}users/terms-of-service/`
export const DjangoTOS = `${DJangoAPIBaseURL}terms-of-service`
export const DjangoIntercomInfo = `${DJangoAPIBaseURL}${DJV1}users/intercom-info/`
export const DjangoIntercomCovideoRequest = `${DJangoAPIBaseURL}${DJV1}users/intercom-unlock-packet-message/` // will change soon
//
//
//
// PACKETS SEND
// packet send - packet context - GET -> render location: autoipacket.js
export const DJangoPacketContextAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/context/`
// packet send - packet information - GET -> render location: autoipacket.js
export const DJangoPacketInfoAPIURL = `${DJangoAPIBaseURL}${DJV1}packet/viewpacket`
// packet send - packet image(s) - GET -> render location: autoipacket.js
export const DJangoPacketImagesAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/vehicle/images/`
// packet send - packet module(s) - GET -> render location: autoipacket.js
export const DJangoPacketModulesAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/vehicle/module-v2/`
// packet send - single vehicle information - GET -> render location: autoipacket.js
export const DJangoVehicleInfoAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/vehicle/`
// packet send - activity list types (counts|launched|viewed|printed)
// - GET -> render location: autoipacket.js
export const DJangoPActivityListTypesAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/history/`
// packet send - single module pdf - GET -> render location: autoipacket.js
export const DJangoPacketSingleModulesAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/vehicle/module/`
// packet send - send the packet - POST -> render location: autoipacket.js
export const DJangoPacketSendAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/send/`
// packet scheduled send - get the scheduled packets - GET -> redner location: autopacket.js
export const DJangoScheduledPacketGetAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/get-scheduled-packets/`
// packet scheduled send edit - edit scheduled packets - POST -> redner location: autopacket.js
export const DJangoEditScheduledPacketSendAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/modify-schedule-packet-send/`
// schedule packet send - schedule the packet to be sent - POST -> render location: ?
export const DJangoSchedulePacketSendAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/schedule-packet-send/`
// delete scheduled packet send
export const DJangoDeleteScheduledPacketSendAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/modify-schedule-packet-send/`
//
// PACKETS SEND - SOCIAL SHARE
export const DJangoPacketSSListAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/social/`
export const DJangoPacketSSDetailAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/info/social/`
export const DJangoPacketSSPostAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/info/social/`
export const DJangoPacketSSPutAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/info/social/`

export const DJangoStoreShareTypes = `${DJangoAPIBaseURL}${DJV1}store/packet-share-method-check/`
//
// PACKETS SEND - EMAIL PREVIEW
export const DJangoPacketSendEmailPreview = `${DJangoAPIBaseURL}${DJV1}packets/preview-email/`
// PACKETS SEND - GET PREVIOUS ATTACHMENTS
export const DJangoPacketSendPreviousAttachments = `${DJangoAPIBaseURL}${DJV1}packets/attachment/?username=`
// PACKETS SEND - GET PREVIOUS ATTACHMENT DETAILS
export const DJangoPacketSendPreviousAttachmentDetails = `${DJangoAPIBaseURL}${DJV1}packets/attachment/`

//
//
//
// DASHBOARD URLS
// dashboard - dashboard list types (viewed|hot_list|user_shares|price_changes)
// - GET -> render location: autoipacket.js
export const DJangoDashboardListTypesAPIURL = `${DJangoAPIBaseURL}${DJV1}dashboard/stats/`
// dashboard - search dashboard social-stats - GET -> render location: autoipacket.js
export const DJangoDashboardListSSAPIURL = `${DJangoAPIBaseURL}${DJV1}dashboard/social-stats/`
// dashboard - dashboard heatmap check - GET -> render location: autoipacket.js
export const DJangoDashboardHeatMapCheckGetAPIURL = `${DJangoAPIBaseURL}${DJV1}heatmap/show_on_dashboard/`
// dashboard - dashboard heatmap data - GET -> render location: autoipacket.js
export const DJangoDashboardHeatMapGetAPIURL = `${DJangoAPIBaseURL}${DJV1}heatmap/activity-data/`
// dashboard - dashboard notes (message) - GET -> render location: autoipacket.js
export const DJangoDashboardNotesGetAPIURL = `${DJangoAPIBaseURL}${DJV1}dashboard/packets/message/`
// dashboard - dashboard social-share-view-history - GET -> render location: autoipacket.js
export const DJangoDashboardListSSVHAPIURL = `${DJangoAPIBaseURL}${DJV1}dashboard/social-share-view-history/`
// dashboard - dashboard time sent interval - GET -> render location: autoipacket.js
export const DJangoDashboardSentIntervalGetAPIURL = `${DJangoAPIBaseURL}${DJV1}dashboard/packets/sent/`
// dashboard - dashboard customer-packet-view-history
// - GET -> render location: autoipacket.js
export const DJangoDashboardListCPVHAPIURL = `${DJangoAPIBaseURL}${DJV1}dashboard/customer-packet-view-history/`

export const DJangoDashboardPacketSource = `${DJangoAPIBaseURL}${DJV1}packets/source/`

//
//
//
// INVENTORY URLS
// inventory - vehicle listing - GET
export const DjangoInventoryContentGetAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/vehicle/`
export const DjangoInventoryContentFilteredGetAPIURL = `${DJangoAPIBaseURL}${DJV1}query/filter/vehicles`
// inventory - image urls for chosen vehicle - GET
export const DjangoInventoryImagesGetAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/vehicle/images/`
// inventory - filter options for vehicle listing - GET (no trailing /)
export const DjangoInventoryOptionsGetAPIURL = `${DJangoAPIBaseURL}${DJV1}query/filter/options`
// inventory - search for vehicles - GET (no trailing /)
export const DjangoInventorySearchGetAPIURL = `${DJangoAPIBaseURL}${DJV1}query/vehicles`

// packet - price delta - GET -> render location: autoipacket.js
export const DJangoPacketPriceDeltaAPIURL = `${DJangoAPIBaseURL}${DJV1}mobile/price-change-deltas/`
// packet - message - GET -> render location: autoipacket.js
export const DJangoPacketMessageAPIURL = `${DJangoAPIBaseURL}${DJV1}users/packet_send_message/legacy/`
// packet - activity - GET -> render location: autoipacket.js
export const DJangoPacketActivityAPIURL = `${DJangoAPIBaseURL}${DJV1}users/packet-activity/legacy/`
// packet - search vehicle list - GET -> render location: autoipacket.js
export const DJangoPacketSearchAPIURL = `${DJangoAPIBaseURL}${DJV1}query/vehicle`
// packet - search customer packet sent list - GET -> render location: autoipacket.js
export const DJangoPacketCustomerSearchAPIURL = `${DJangoAPIBaseURL}${DJV1}users/customer/`
// packet - customer packet update alert - GET & POST -> render location: autoipacket.js
export const DJangoPacketCustomerAlertSaveAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/info`
// packet - get stores near me - GET -> render location: autoipacket.js
export const DJangoPacketNearMeAPIURL = `${DJangoAPIBaseURL}${DJV1}users/company/stores/distance/`

export const DjangoNewFilterOptions = `${DJangoAPIBaseURL}${DJV1}query/filter/options/client`

export const DJangoPriceChangeConfigAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/customer-price-change-alerts/`

export const DjangoPacketActivityAPIURL = `${DJangoAPIBaseURL}${DJV1}/packets/history/`

// packet view - vehicle tomeline service - GET
export const DJangoGetVehicleHistory = `${DJangoAPIBaseURL}${DJV1}vdp/vehicle-history/`
//
//
//
// CUSTOMER URLS
// contact - contacts list ALL - GET -> render location: autoipacket.js
export const DJangoContactListAPIURL = `${DJangoAPIBaseURL}${DJV1}users/customer-list/`
// contact - search contacts list - GET -> render location: autoipacket.js
export const DJangoContactSearchAPIURL = `${DJangoAPIBaseURL}${DJV1}query/customers`
// contact - search contacts list EXACT - GET -> render location: autoipacket.js
export const DJangoContactSearchExactMatchAPIURL = `${DJangoAPIBaseURL}${DJV1}query/customers/exact`
// contact - contacts list SINGLE - GET & POST & PUT -> render location: autoipacket.js
export const DJangoContactSingleGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/customer-detail/`
export const DJangoContactSingleSaveAPIURL = `${DJangoAPIBaseURL}${DJV1}users/customer-detail/`
export const DJangoRecentCustomersAPIURL = `${DJangoAPIBaseURL}${DJV1}users/recent_customers/`
export const DJangoFavoriteCustomersAPIURL = `${DJangoAPIBaseURL}${DJV1}users/favorite_customers/`
//
//
//
// PROFILE URLS
// profile - legacy user information - GET -> render location: autoipacket.js
export const DJangoLegacyUserGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/legacy-user-detail/`
// profile - legacy user info - POST -> render location: accountSettingsModal.js
export const DJangoLegacyUserUpdateAPIURL = `${DJangoAPIBaseURL}${DJV1}users/legacy-user-detail/`
// profile - image avatar - GET & PUT -> render location: autoipacket.js
export const DJangoProfileImageGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/files/profile-image/legacy/`
export const DJangoProfileImageSaveAPIURL = `${DJangoAPIBaseURL}${DJV1}users/files/profile-image/legacy/`
// profile - notification preferences - POST -> render location: autoipacket.js
export const DJangoNotifPrefSaveAPIURL = `${DJangoAPIBaseURL}${DJV1}users/notification-preferences/legacy/`

export const DJangoNotifPrefAPIURL = `${DJangoAPIBaseURL}${DJV1}users/notification-preferences/`
// profile - profile user store list - GET -> render location: autoipacket.js
export const DJangoProfileStoreListGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/store_access_list/`
// profile - profile user default store SINGLE - GET -> render location: autoipacket.js
export const DJangoProfileStoreSingleGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/company/store/`
// profile - get list of user role options - GET -> render location: autoipacket.js
export const DJangoProfileRolesGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/roles/`
// profile - get default user role - GET & PUT & DELETE -> render location: autoipacket.js
export const DJangoProfileRoleGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/roles/`

//
//
//
// ADMIN URLS
// admin - get list store overview - GET -> render location: autoipacket.js
export const DJangoAdminOverviewGetAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// admin - get list store image logo - GET -> render location: autoipacket.js
export const DJangoAdminOverviewImgGetAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// admin - get store franchises as a list - GET & POST & PUT & DELETE -> render location: autoipacket.js
export const DJangoAdminFranchiseGetAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
export const DJangoAdminFranchisePostAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
export const DJangoAdminFranchisePutAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// admin - returns the HTML text that stores insert for their icons - GET -> render location: autoipacket.js
export const DJangoAdminHTMLGetAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// admin - get list of store teammembers - GET -> render location: autoipacket.js
export const DJangoAdminTeamMembersGetAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// admin - get a single teammember - GET & POST & DELETE -> render location: autoipacket.js
export const DJangoAdminTeamMemberGetAPIURL = `${DJangoAPIBaseURL}${DJV1}users/legacy-user-detail/`
// admin - put a single teammember - PUT -> render location: autoipacket.js
export const DJangoAdminTeamMemberPutAPIURL = `${DJangoAPIBaseURL}${DJV1}users/legacy-user/`
// admin - Enables an admin user to update their Credential Information to login
// to any third party system we interface with on their behalf.
// - GET & PUT -> render location: autoipacket.js
export const DJangoAdminCredentialInfoPutAPIURL = `${DJangoAPIBaseURL}${DJV1}data-ingest/dealer-credential/`
// admin - gets list of email addresses subscribed to receive a store's activity reports
export const DJangoAdminActivityRecipientsAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/activity-reports/recipients/`
// admin - gets list of a store's custom scheduled reports
export const DJangoAdminCustomScheduledReportsListAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/scheduled-built-reports/`
// admin - create scheduled report or get single report
export const DJangoAdminCustomScheduledReportAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/scheduled-built-report/`

export const DJangoAdminResetPasswordAPIURL = `${DJangoAPIBaseURL}${DJV1}users/auth/reset-password/`

// admin - get store region info
export const DJangoAdminOverViewGetRegionInfoUrl = `${DJangoAPIBaseURL}${DJV1}store/region/`

// admin - get acct manager info
export const DJangoAdminOverViewGetManagerInfoUrl = `${DJangoAPIBaseURL}${DJV1}store/`

// admin - get store health info
export const DJangoAdminOverViewGetStoreHealthInfoUrl = `${DJangoAPIBaseURL}${DJV1}store/`

// admin - get store company info
export const DJangoAdminCompanyGetUrl = `${DJangoAPIBaseURL}${DJV1}store/company/`

// modules - GET -> Get list of modules and their info/status for store: modules.js
export const DjangoAdminModulesGetURL = `${DJangoAPIBaseURL}${DJV1}store/`

export const ActivityReportingSources = `${DJangoAPIBaseURL}v3/legacy-activity-report/sources`
export const ActivityReportingEmailFrequencies = `${DJangoAPIBaseURL}v3/legacy-activity-report/email-frequencies`
export const ActivityReportingEmailSchedules = `${DJangoAPIBaseURL}v3/legacy-activity-report/email-schedules`

//
//
//
// REPORTS URLS
// reports - get list of activity reports - GET -> render location: autoipacket.js
export const ReportsActivityListGetAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/activity-reports/available/`
// reports - S3 Resource, table in database with links - GET -> render location: autoipacket.js
export const ReportsActivityGetAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/activity-reports/`
// reports - get list of packet status reports - GET -> render location: autoipacket.js
export const ReportsStatusGetAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/vehicle-module-status-report/`
// reports - enables a user to DOWNLOAD reports. - GET -> render location: autoipacket.js
export const ReportsUploadGetAPIURL = `${DJangoAPIBaseURL}${DJV1}reports/user-upload-report/`

export const ReportsWebButtonAPIURL = `${DJangoAPIBaseURL}${DJV1}heatmap/ewb_usage_stats/`
// reports - gets monthly reports generated for a store
export const ReportsActivityGenerateMonthly = `${DJangoAPIBaseURL}${DJV1}reports/web-link/activity-report/monthly/store/`
// reports - generates a custom report based on selected date range
export const ReportsActivityGenerateDateRange = `${DJangoAPIBaseURL}${DJV1}reports/web-link/`
// reports - gets list of possible custom report types based on a given store
export const getListOfAvailableUsageReportTypes = `${DJangoAPIBaseURL}${DJV1}reports/web-link/available-types/`
// reports - gets user's history or custom reports generated
export const getCustomReportUserHistory = `${DJangoAPIBaseURL}${DJV1}reports/web-link/user-history/`
// reports - base url for weblink usage in custom reports
export const customReportWeblinkBaseUrl = `${DJangoAPIBaseURL}${DJV1}reports/activity/store/`

export const ReportsCompanyUserPacketShare = `${DJangoAPIBaseURL}${DJV1}reports/vitals/report/company/performance/users/shares/`

//
//
//
// STICKERPULL URLS
// pull sticker - request a sticker for provided VIN
export const StickerPullPutAPIURL = `${DJangoAPIBaseURL}${DJV1}sticker-puller/pull/`
// stickerpull - retreived streamed PDF - GET -> render location: autoipacket.js
export const StickerPullDownloadGetAPIURL = `${DJangoAPIBaseURL}${DJV1}sticker-puller/download/`
// stickerpull - get stickerpull subscription info - GET -> render location: autoipacket.js
export const StickerPullSubscriptionGetAPIURL = `${DJangoAPIBaseURL}${DJV1}sticker-puller/subscription/`
// stickerpull - enables a user to DOWNLOAD reports. - GET -> render location: autoipacket.js
export const StickerPullListGetAPIURL = `${DJangoAPIBaseURL}${DJV1}sticker-puller/stickers/`
// stickerpull - enables a user to DOWNLOAD reports. - GET -> render location: autoipacket.js
export const StickerSupportedGetAPIURL = `${DJangoAPIBaseURL}${DJV1}sticker-puller/support-catalog/`
// stickerpull - enables a user to see list of successful/failed pulls. - GET -> render location: autoipacket.js
export const StickerPullGetHistory = `${DJangoAPIBaseURL}${DJV1}sticker-puller/history/?success`

export const StickerPullSupportCatalogAPIURL = `${DJangoAPIBaseURL}${DJV1}sticker-puller/support-catalog/v2/`

export const ArcDataAPIURL = `${DJangoAPIBaseURL}${DJV1}ml/vehicle/arc/`

export const StickerPullV2APIURL = `${DJangoAPIBaseURL}v2/sticker-puller/pull/`
export const StickerPullV2PollAPIURL = `${DJangoAPIBaseURL}v2/sticker-puller/poll/`

//
//
//
// TOOLS URLS
// tools - get and put of the type of documents that are available for the user for upload
// - GET & PUT -> render location: autoipacket.js
export const DJangoToolsDocUploadAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// tools - enables a user to filter by their store, under CPO and EDIT
// - GET & PUT -> render location: autoipacket.js
export const DJangoToolsFinanceTablesCPOAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// tools - enables a user to filter by their store, under NON-CPO and EDIT
// - GET & PUT -> render location: autoipacket.js
export const DJangoToolsFinanceTablesNonCPOAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`

export const DJangoToolsFinanceTablesAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`

//
//
//
// STAFF TOOLS UTLS
export const DjangoCompanyStoreSearchAPIURL = `${DJangoAPIBaseURL}${DJV1}query/company-store`

export const DjangoChangeCompanyStoreAPIURL = `${DJangoAPIBaseURL}${DJV1}users/staff_user_access_view/`

// STATISTICS UTILS
// tools - returns the amount of packets sent based on date
// - GET -> render location: autoipacket.js
export const DjangoPacketsSentAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// tools - returns the amount of packet views based on date
// - GET -> render location: autoipacket.js
export const DjangoPacketsViewedAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// tools - returns the amount of web clicks based on date
// - GET -> render location: autoipacket.js
export const DjangoWebClicksAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// tools - returns the amount of indirect web clicks based on date
// - GET -> render location: autoipacket.js
export const DjangoIndirectClicksAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`
// tools - returns the amount of indirect web clicks based on date
// - GET -> render location: autoipacket.js
export const DjangoSocialSharesAPIURL = `${DJangoAPIBaseURL}${DJV1}store/`

//
//
//
// STAFF TOOLS AUDITS
// Staff tools - Retrieves how many makes have and should have MSRP a given store has
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditMSRPAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/MSRP/`
// Staff tools - Retrieves how many cars have and should have Brochures from a provided store
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditBrochureAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Brochure/`
// Staff tools - Retrieves how many cars have and should have Carfax reports from a provided store
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditCarfaxAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Carfax/`
// Staff tools - Retrieves how many cars have and should have Autocheck from a provided store
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditAutocheckAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Autocheck/`
// Staff tools - Retrieves how many cars have and should have photos from a provided store
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditPhotoAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Photo/`
// Staff tools - Retrieves how many stores have and should have social sharing
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditSocialAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Social/`
// Staff tools - Retrieves how many stores have and should have text sharing
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditTextAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Text/`
// Staff tools - Retrieves how many stores support and should support availibility confirmation
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditAvailabilityAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Availability/`
// Staff tools - Retrieves how many stores have and should have usable phone numbers
// - GET -> -render location: autoipacket.js
export const DjangoStoreAuditPhoneAPIURL = `${DJangoAPIBaseURL}${DJV1}system-audit/Phone/`
//
//
//
// UNSUBSCRIBE
// Customer Company List - Retreives list of companies, user is subscribed to
export const DJangoCustomerCompanyList = `${DJangoAPIBaseURL}${DJV1}customers/company-list/`
// Customer Unsubscribe - Unsubscribes user from provided list of companies
export const DJangoCustomerUnsubscribe = `${DJangoAPIBaseURL}${DJV1}customers/unsubscribe/`
//
//
//
// EMAIL TEMPLATES
// Packet Share email Company template list - Retreieves the list of Company email templates currently in use
export const DJangoCompanyEmailTemplatesList = `${DJangoAPIBaseURL}${DJV1}packets/company-templates/`
// Packet Share email Store template list - Retreieves the list of Store email templates currently in use
export const DJangoStoreEmailTemplatesList = `${DJangoAPIBaseURL}${DJV1}packets/store-templates/`
// Packet Share email Users template list - Retreieves the list of Users email templates currently in use
export const DJangoUserEmailTemplatesList = `${DJangoAPIBaseURL}${DJV1}packets/user-templates/`
// Packet Share email template detail - Retrieves details about email templates
export const DJangoEmailTemplatesDetail = `${DJangoAPIBaseURL}${DJV1}packets/message-template/`
// Packet Share Create Message Template - Creates a new email template
export const DJangoCreateEmailTemplate = `${DJangoAPIBaseURL}${DJV1}packets/message-template/`
// Packet Share Delete Message Template - Deletes an email template
export const DJangoDeleteEmailTemplate = `${DJangoAPIBaseURL}${DJV1}packets/message-template/`
// Packet Share Update Message Template - Updates a previously created email template
export const DJangoUpdateEmailTemplate = `${DJangoAPIBaseURL}${DJV1}packets/message-template/`
// Packet Share render Template - Renders the message template with dynamically generated IDs
export const DJangoRenderEmailTemplate = `${DJangoAPIBaseURL}${DJV1}packets/render-message-template/`
//
//
//
// OTHER URLS
// device version compatibility check - POST -> render location: autoipacket.js
export const DJangoVersionAPIURL = `${DJangoAPIBaseURL}compatibility-check/`
// send report issue to support team - POST -> render location: autoipacket.js
export const DJangoUserReportPostAPIURL = `${DJangoAPIBaseURL}contact-support/`

export const DjangoRecordAPIURL = `${DJangoAPIBaseURL}${DJV1}packets/history/`

// Initial Print
export const DjangoInitiatePrint = `${DJangoAPIBaseURL}${DJV1}store/print/`
// retrieve_VDP_Print
export const DjangoRetrievePrint = `${DJangoAPIBaseURL}${DJV1}store/print/retrieve/`

export const DjangoLoginInfo = `${DJangoAPIBaseURL}${DJV1}users/auth/login/info/`
