import React from 'react'

const CheckmarkAnimation = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
    <g id="AutoiPacket.com">
      <g transform="matrix(0.470345,-0.568771,0.50499,0.417601,-2.76459,52.2321)">
        <path className="animated-checkmark" d="M8.271,32.555L8.271,87.113L96.172,87.113" />
      </g>
    </g>
  </svg>
)
export default CheckmarkAnimation
