import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import Modal from '../../layouts/modal'
import './arccommonissuesmodal.scss'

function ArcCommonIssuesModal(props) {
  return (
    <Modal>
      <div className="arc-common-issues-modal">
        <h1>{props.title}</h1>
        <ul>
          {props.issueData &&
            props.issueData.issues.map(issue => {
              return <li>{issue}</li>
            })}
        </ul>
      </div>
    </Modal>
  )
}

export default ArcCommonIssuesModal
