// general: packet sent modal.

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import { hideModal } from '../../../core/_actions/modalActions'
import ls from 'local-storage'
import { NavLink } from 'react-router-dom'
// core files
import { emptyBasket } from '../../../core/actions/inventoryactions'
import { EmptyPacketAllConst } from '../../../core/_actions/emptystoreActions'
// components
import Modal from '../../layouts/modal'
import SocialShareModal from '../../layouts/socialshare'
// css styles
import '../../styles/universalstyles.scss'
import '../../packetview/styles/packetsend.scss'

type Props = {
  DJtoken: string,
  title: string,
  type: string,
  json: Object,
  vehicleArray: Object,
  hideModal: boolean,
  vehicleId: number,
}

export class PacketSentModal extends Component {
  constructor(props: Props) {
    super(props)
    this._onClose = this.onClose.bind(this)
  }

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.RemoveOnClose()
    this.props.hideModal()
  }

  // RemoveOnClose
  RemoveOnClose() {
    // remove saved
    EmptyPacketAllConst()
    emptyBasket()
    // redirect
    window.location.replace('/packets')
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  props: Props
  _onClose: Function // function

  render() {
    // props
    const { type, vehicleArray } = this.props

    // booleans
    const vehicleBoolean = vehicleArray && vehicleArray.length > 0

    // containres
    const ending = (
      <span>
        <br />
        <br />

        <div className="packetSendDescriptionStyle" style={{ fontSize: '0.75em' }}>
          You will now be redirected back to the inventory page.
        </div>
      </span>
    )

    if (type !== 'social' && type !== 'link') {
      return (
        <Modal>
          <div align="center">
            <b style={{ fontSize: '1.5em' }}>{this.props.title}</b>
          </div>

          {vehicleBoolean ? (
            <div align="center">
              <br />
              <div className="packetSendDescriptionStyle">
                {`Send receipt (${vehicleArray.length}):`}
              </div>

              {vehicleArray.map(vehicle => {
                const trimSuffix =
                  vehicle.trim != undefined && vehicle.trim.length > 0 ? ' ' + vehicle.trim : ''
                return (
                  <div
                    key={vehicle.vehicle_id}
                    className="basket-item"
                    style={{ textAlign: 'center', cursor: 'auto' }}
                  >
                    <div className="basket-item-info">
                      {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${trimSuffix}`}
                      <div className="packetSendDescriptionStyle">{vehicle.vin}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : null}

          {ending}
        </Modal>
      )
    } else if (type === 'link') {
      return (
        <Modal>
          <div align="center">
            <b style={{ fontSize: '1.5em' }}>Web Link</b>
          </div>

          <br />
          <div className="packetSendDescriptionStyle">Your web link has been generated, below:</div>
          <a
            href="#"
            className="packetSendDescriptionStyle"
            style={{ color: '#337dce', fontSize: '0.85em' }}
          >
            {'<web link will be here>'}
          </a>

          {ending}
        </Modal>
      )
    } else if (type === 'social') {
      return (
        <Modal>
          <SocialShareModal DJtoken={this.props.DJtoken} vehicleId={this.props.vehicleId} />
          {ending}
        </Modal>
      )
    }

    return null
  }
}

// ***********************LETS*EXPORT*MODAL***********************
function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    type: state.modal.props.type,
    json: state.modal.props.json,
    vehicleArray: state.modal.props.vehicleArray,
    DJtoken: state.modal.props.DJtoken,
    vehicleId: state.modal.props.vehicleId,
  }
}

export default connect(mapStateToProps, {
  hideModal,
  emptyBasket,
})(PacketSentModal)
