// import React & helper functions
import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'

// components
import Modal from '../../layouts/modal'

// icons
import CancelIcon from '../../../core/assets/images/icons/cancel-icon.js'

import { hideModal, showModal } from '../../../core/_actions/modalActions'

class EmailPreview extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { modalProps } = this.props

    console.log(modalProps)

    return (
      <Modal emailModal={true}>
        <div className="email-preview-modal">
          <div className="preview-modal-content">
            <div className="email-body" dangerouslySetInnerHTML={{ __html: modalProps.message }} />
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  modalProps: state.modal.props,
})

export default connect(mapStateToProps, { hideModalConnect: hideModal })(EmailPreview)
