import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import throttle from 'lodash.throttle'

import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import { facetUpdatePage } from '../../../core/actions/inventoryactions.js'

type Props = {
  page: number,
  updatePage: Function,
  numPages: number,
}

class PaginatorComponent extends PureComponent {
  nextPage = () => {
    this.handleUpdate(this.props.page + 1)
  }

  prevPage = () => {
    this.handleUpdate(this.props.page - 1)
  }
  updatePage = page => {
    let {
      facetFields,
      activeFacetFields,
      facetOptions,
      updateFacetConnect,
      user_id,
      token,
      sortBy,
      searchQuery,
    } = this.props
    if (page >= 1 && page <= this.props.numPages) {
      this.props.facetUpdatePageConnect({
        facetFields,
        activeFacetFields,
        facetOptions,
        user_id,
        token,
        page,
        sortBy,
        searchQuery,
      })
      try {
        window.scrollTo(0, 0)
      } catch (e) {
        console.log(e)
      }
    }
  }
  handleUpdate = throttle(this.updatePage, 750)

  props: Props

  render() {
    const { page, numPages } = this.props
    if (page && numPages) {
      return (
        <div className="paginator" style={{ animation: ` 1000ms fadeIn` }}>
          <div className="paginator-control paginator-button" onClick={this.prevPage}>
            &#171;
          </div>
          {page > 1 && (
            <div
              className="paginator-control"
              id="1"
              onClick={() => {
                this.handleUpdate(1)
              }}
            >
              1...
            </div>
          )}
          {page > 2 && (
            <div
              className="paginator-control"
              id={page - 1}
              onClick={() => {
                this.handleUpdate(page - 1)
              }}
            >
              {page - 1}
            </div>
          )}
          <div className="paginator-active-page">{page}</div>

          {page < numPages && (
            <div
              className="paginator-control"
              id={page + 1}
              onClick={() => {
                this.handleUpdate(page + 1)
              }}
            >
              {page + 1}
            </div>
          )}
          {page < numPages - 1 && (
            <div
              className="paginator-control"
              id={numPages}
              onClick={() => {
                this.handleUpdate(numPages)
              }}
            >
              ...{numPages}
            </div>
          )}
          <div className="paginator-control paginator-button" onClick={this.nextPage}>
            &#187;
          </div>
        </div>
      )
    }

    return <div className="paginator" />
  }
}

const mapStateToProps = state => ({
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  token: state.authentication.user.token,
  page: state.inventory.page,
  sortBy: state.inventory.sortBy,
  numPages:
    state.inventory.inventoryContent && Math.ceil(state.inventory.inventoryContent.count / 15),
  filters: state.inventory.activeFilters,
  searchQuery: state.inventory.searchQuery,
})
const Paginator = UserInfoWrapper(PaginatorComponent)
export default connect(mapStateToProps, { facetUpdatePageConnect: facetUpdatePage })(Paginator)
