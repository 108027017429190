// Helper function(s) to assist with Sentry.io reporting

// sentry
import * as Sentry from '@sentry/browser'

export default {
  // Add user context to further Sentry reports
  tagUserInfo: userInfoObj => {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: userInfoObj.user_id,
        username: userInfoObj.username,
        email: userInfoObj.user_email_address,
        default_store_id: userInfoObj.default_store_id,
        userObj: userInfoObj,
      })
    })
  },

  // add key/value to all further Sentry reports
  addContext: (keyName, keyContent) => {
    Sentry.setExtra(keyName, keyContent)
  },

  // call out to Sentry with custom message
  captureMsg: captureMsg => {
    Sentry.captureMessage(captureMsg)
  },

  /* 
  Capture environment context and reports to Sentry
    contextObj: Object full of additional context for Sentry
    captureMsg: label to be used in Sentry for captured event
    captureLevel: level of error as recorded in Sentry
  */
  grabContextAndCapture: (contextObj, captureMsg, captureLevel) => {
    for (const prop in contextObj) {
      Sentry.setExtra(`${prop}`, contextObj[prop])
    }

    Sentry.captureMessage(captureMsg, captureLevel)
  },

  reportIssuePrompt: () => {
    return false
  },
}
