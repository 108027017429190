import React from 'react'
import './styles/packetsendanimation.scss'

const PacketSendAnimation = props => (
  <svg className="packet-send-animation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
    <title>AutoiPacketYloRingMobile</title>
    <g id="AutoiPacket.com">
      <circle className="cls-1" cx="55" cy="55" r="52.24" />
      <path
        className="cls-2"
        d="M81.92,77.05H27.84a1.36,1.36,0,0,1-1.36-1.29l-1.8-34.91A1.37,1.37,0,0,1,26,39.41H84.09a1.36,1.36,0,0,1,1.36,1.45L83.28,75.78A1.36,1.36,0,0,1,81.92,77.05Z"
      />
      <path
        className="cls-2"
        d="M82.55,41.05V38.79a1.17,1.17,0,0,0-1.17-1.17H51.91a1.27,1.27,0,0,1-1.27-1.27V34.48a1.36,1.36,0,0,0-1.36-1.36H30.6a1.36,1.36,0,0,0-1.36,1.36v1.87A1.27,1.27,0,0,1,28,37.62h-.28a1.17,1.17,0,0,0-1.17,1.18l.26,31.92C37.59,53,58.51,41.05,82.55,41.05Z"
      />
      <g transform="matrix(0.470345,-0.568771,0.50499,0.417601,-2.76459,52.2321)">
        <path className="cls-3" d="M8.271,32.555L8.271,87.113L96.172,87.113" />
      </g>
    </g>
  </svg>
)
export default PacketSendAnimation
