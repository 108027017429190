// general: helper function(s) to make all things easier on your life.

// node packages
import UAParser from 'ua-parser-js'
import ls from 'local-storage'
import { format, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
// core files
import runtimeEnv from '../../core/config/envConstants'
import UserActions from '../actions/users/useractions'
import SentryHelper from './sentryHelper'

// global(s)
// --- imports
const Parser = new UAParser.UAParser()
const queryString = require('query-string')

export default {
  // ***********************LETS*CHECK*IF*IT*IS*VALID***********************
  // CheckStatusLogin
  // function checks if there is a valid user login -> retuns true || returns false
  // ultimetly blocks you from seeing protected components or being logged in
  CheckStatusLogin: () => {
    const localuserlogin = ls.get('didlogin')
    const localuserdata = ls.get('userInfo')
    // Bad exception for pricechange page! -Will 09/04/2019
    if (window.location.pathname.includes('pricechange')) {
      return false
    }
    if (localuserlogin) {
      if (localuserdata !== null && localuserdata !== undefined) {
        const today = Math.round(new Date().getTime() / 1000)
        const savedTime = Math.round(localuserdata.timestamp / 1000)
        SentryHelper.tagUserInfo(localuserdata) // add userInfo to Sentry context

        if (savedTime <= today) {
          // truthy check and return is_admin
          return localuserdata.is_admin ? 2 : 1
        }
        return UserActions.AIPByeBye(localuserdata.dj_token)
      }
      return false
    }
    return false
  },

  // CheckMobileScreenOrientation
  // returns true if the screen orientation is a match for mobile devices
  // else returns false
  CheckMobileScreenOrientation: () => {
    if (Parser.getDevice().type) {
      return Parser.getDevice().type !== undefined
    }
    return false
  },

  // CheckEmailValidation
  // - Checks if the email entered is in standard form, and returns false/true depending.
  CheckEmailValidation: (email: string) => {
    if (email !== null && email !== undefined && email !== '') {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return emailRegex.test(String(email).toLowerCase())
      // eslint-disable-next-line no-else-return
    } else {
      return false
    }
  },

  // CheckMobileValidation
  // - Checks if the phone-number entered is in standard form, and returns false/true depending.
  CheckMobileValidation: (mobile: string) => {
    if (mobile !== null && mobile !== undefined && mobile !== '') {
      // eslint-disable-next-line no-useless-escape
      const mobileRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      if (mobile.match(mobileRegex) !== null && mobile.match(mobileRegex) !== undefined) {
        return true
        // eslint-disable-next-line no-else-return
      } else {
        return false
      }
    } else {
      return false
    }
  },

  // InputCheckMarkSetter
  InputCheckMarkSetter: (state: Object, data: string) => {
    return state.indexOf(data) > -1 ? true : false
  },

  // CheckS3Bucket
  CheckS3Bucket: (url: string) => {
    if (url !== null && url !== undefined && url !== '') {
      // eslint-disable-next-line no-undef
      return fetch(url, { method: 'GET' }).then(response => {
        return response.status
      })
    }

    return false
  },
  // ***********************LETS*CHECK*IF*IT*IS*VALID***********************

  //
  // ***********************LETS*CONVERT*BAD*FORM***********************
  // FormatMoneyUSA
  // - USA money conversion
  FormatMoneyUSA: (money: number) => {
    const formatterUSA = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })

    return formatterUSA.format(money).replace(/\.00$/, '')
  },

  // FormatPhoneNumber
  // - takes the 'number', string, coming in -> checks if it is in the format
  // of 555-555-5555, and if not adds in the '-'s, before it is sent to the database.
  FormatPhoneNumber: (number: string) => {
    if (number !== null && number !== undefined && number !== '') {
      const numberReturn = number
        .replace(/-/g, '')
        .replace(/^(?=[0-9]{11})([0-9]{5})([0-9]{8})([0-9])$/, '$1-$2-$3')
      return numberReturn
    }

    return ''
  },

  prettyPhoneNumber: phoneNumberString => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return phoneNumberString
  },

  // FormatDateUSA
  // - USA date conversion
  FormatDateUSA: (date: string) => {
    if (date !== null && date !== undefined && date !== '') {
      const newDate = new Date(date)
      return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
    } else {
      const newDate = new Date()
      return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
    }
  },

  // parseURLQuery
  // - pulls query string from URL and breaks into parts
  parseURLQuery: () => {
    return queryString.parse(location.search) // eslint-disable-line no-undef
  },

  // isExplorer
  // - returns true if client is Internet Explorer
  isExplorer: () => {
    var ua = window.navigator.userAgent
    var msie = ua.indexOf('MSIE ')
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      return true
    }
    return false
  },

  // isSafari
  // - returns true if client is Safari (Desktop)
  isSafari: () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  },

  // isEdge
  // - returns true if the client is Microsoft Edge (Desktop)
  isEdge: () => {
    return /Edge/.test(navigator.userAgent)
  },

  // localizeTime
  // accepts timestamps from our database (localized to EST) and adjusts
  // to the clients Intl.DateTimeFormat().resolvedOptions().timeZone timezone. Accounts for DST as well.
  localizeTime: (timestamp: string) => {
    if (timestamp != null && timestamp != undefined) {
      return zonedTimeToUtc(timestamp, 'America/New_York')
    } else {
      return 'N/A'
    }
  },
  // convertUTCandLocalize
  // accepts timestamps from our database (localized to EST) and adjusts
  // to the clients Intl.DateTimeFormat().resolvedOptions().timeZone timezone. Accounts for DST as well.
  convertUTCandLocalize: (timestamp: string) => {
    if (timestamp != null && timestamp != undefined) {
      return zonedTimeToUtc(utcToZonedTime(timestamp, runtimeEnv.clientTZ), 'America/New_York')
    } else {
      return 'N/A'
    }
  },

  // extend date-fns format function
  dtFormat: (timestamp: string, formatString: string) => {
    return format(timestamp, formatString)
  },

  // a fxn to add suffix to a number
  ordinal: number => {
    const ordinalRules = new Intl.PluralRules('en', {
      type: 'ordinal',
    })
    const suffixes = {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th',
    }
    const suffix = suffixes[ordinalRules.select(number)]
    return number + suffix
  },

  // diffObjectAgainst
  // accepts two objects as arguments and returns an object consisting
  // of their differing keys
  diffObjectAgainst: (objToBeDiffPreferred: object, comparator: object) => {
    function changes(objToBeDiffPreferred, comparator) {
      return _.transform(objToBeDiffPreferred, function(result, value, key) {
        if (!_.isEqual(value, comparator[key])) {
          result[key] =
            _.isObject(value) && _.isObject(comparator[key])
              ? changes(value, comparator[key])
              : value
        }
      })
    }
    return changes(objToBeDiffPreferred, comparator)
  },

  alphabetizeStoreList: (storeList: Array) => {
    const sortedArray = storeList.sort((first, second) => {
      const a = first.company_store_name
      const b = second.company_store_name

      if (a > b) {
        return 1
      } else if (a < b) {
        return -1
      }
      return 0
    })
    return sortedArray
  },
}
