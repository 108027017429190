import React from 'react'

const ActivityIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M25.21,3.51A21.33,21.33,0,1,0,46.54,24.84,21.32,21.32,0,0,0,25.21,3.51ZM25,42.69A17.69,17.69,0,1,1,42.69,25,17.69,17.69,0,0,1,25,42.69Z" />
      <path d="M39.07,24.57h-3.6L32.38,14.09h0A1.45,1.45,0,0,0,29.56,14h0l-.06.23v0l-4,16.63L20.76,19h0a1.49,1.49,0,0,0-1.36-1.19,1.44,1.44,0,0,0-1.3,1h0l-3.23,5.78H10.92a1.71,1.71,0,0,0,0,3.38h4.5a1.45,1.45,0,0,0,1.3-1L19,22.85,24.21,36l0,.08v0h0a1.49,1.49,0,0,0,1.35,1A1.53,1.53,0,0,0,27,35.84h0l4-16.47,2.16,7.32h0a1.33,1.33,0,0,0,1.18.94h4.7a1.55,1.55,0,0,0,0-3.06Z" />
    </svg>
  )
}
export default ActivityIcon
