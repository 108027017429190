import React, { Component } from 'react'
import './styles/loadingspinner.scss'
import RefreshIcon from '../../core/assets/images/icons/refresh-icon.js'

let timeoutFunc
class LoadingSpinner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideSpinner: false,
      timeout: props.timeout ? props.timeout : 30000,
    }
  }
  componentDidMount() {
    this.createTimeout()
  }
  componentDidUpdate(prevProps) {
    const { loading } = this.props
    if (prevProps.loading && !loading) {
      clearTimeout(timoutFunc)
      this.setState({
        hideSpinner: false,
      })
    } else {
      this.createTimeout()
    }
  }
  createTimeout() {
    const { loading } = this.props
    const { timeout } = this.state
    if (loading) {
      timeoutFunc = setTimeout(() => {
        this.setState({ hideSpinner: true })
      }, timeout)
    }
  }
  checkMessageProp() {
    const { message } = this.props
    switch (typeof message) {
      case 'function':
        return message()
      case 'string':
        return message
      default:
        return undefined
    }
  }
  render() {
    const { loading, retry, hideMessage, message, moveVert } = this.props
    const { hideSpinner } = this.state
    if (hideSpinner && retry) {
      return (
        <div className="loading-container" style={{ visibility: loading ? 'visible' : 'hidden' }}>
          <div className="loader-overlay">
            <div className="loader-outer">
              <div className="refresh hoverspin" onClick={() => location.reload()}>
                <RefreshIcon />
              </div>
            </div>
          </div>
        </div>
      )
    } else if (hideSpinner && !hideMessage) {
      return (
        <div
          className="loading-container load-refresh"
          style={{ visibility: loading ? 'visible' : 'hidden' }}
        >
          <div className="loader-overlay">
            <div className="loader-outer">
              <div className="refresh">
                {message ? (
                  this.checkMessageProp()
                ) : (
                  <span onClick={() => location.reload()}>Click Here to Refresh</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="loading-container" style={{ visibility: loading ? 'visible' : 'hidden' }}>
        <div className="loader-overlay">
          <div className="loader-outer">
            <div className="loader">
              <svg className="circular" viewBox="25 25 50 50">
                <circle
                  className="path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default LoadingSpinner
