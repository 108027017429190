// general: data to be passed into any file that needs PLACEHOLDER response api data.

import BlankInventoryImg from '../assets/images/blankinventoryimg.jpg'

export default {
  // dashboard
  // default populate empty objects - used in production.
  dashboard_entries: [
    {
      customer_company_id: 0,
      customer_email: '',
      customerid: 0,
      customer_id: '',
      customer_name: '',
      last_viewed_date: '',
      packet_history_id: 0,
      packet_share_id: 0,
      packet_type: '',
      packet_type_label: '',
      sent_date: new Date().getTime(),
      stock_number: '',
      team_member: '',
      vehicle_id: 0,
      vehicle_make: '',
      vehicle_model: '',
      vehicle_status_id: 0,
      vehicle_vin: '',
      vehicle_year: '',
      view_count: 0,
      heatmap: false,
    },
  ],

  // default populate empty objects - used in production.
  dashboard_social: [
    {
      team_member: '',
      social_network_name: '',
      stock_number: '',
      vehicle_id: 0,
      vehicle_year: '',
      vehicle_make: '',
      vehicle_model: '',
      sent_date: new Date().getTime(),
      social_share_id: 0,
      last_viewed_date: '',
      packet_type: '',
      view_count: 0,
      heatmap: false,
    },
    {
      team_member: '',
      social_network_name: '',
      stock_number: '',
      vehicle_id: 0,
      vehicle_year: '',
      vehicle_make: '',
      vehicle_model: '',
      sent_date: new Date().getTime(),
      social_share_id: 0,
      last_viewed_date: '',
      packet_type: '',
      view_count: 0,
      heatmap: false,
    },
    {
      team_member: '',
      social_network_name: '',
      stock_number: '',
      vehicle_id: 0,
      vehicle_year: '',
      vehicle_make: '',
      vehicle_model: '',
      sent_date: new Date().getTime(),
      social_share_id: 0,
      last_viewed_date: '',
      packet_type: '',
      view_count: 0,
      heatmap: false,
    },
  ],

  // dashboard button types - used in production.
  dashboard_buttons: [
    {
      title: 'Viewed',
      title_id: 'viewed',
    },
    {
      title: 'Hot List',
      title_id: 'hot_list',
    },
    {
      title: 'User Shares',
      title_id: 'user_shares',
    },
    {
      title: 'Price Changes',
      title_id: 'price_changes',
    },
    {
      title: 'Social',
      title_id: 'social',
    },
    {
      title: 'All',
      title_id: 'all',
    },
  ],

  //
  //
  //
  // customers
  // default populate empty objects - used in production.
  customers: [
    {
      customer_id: 0,
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      mobile_number: '',
    },
    {
      customer_id: 0,
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      mobile_number: '',
    },
    {
      customer_id: 0,
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      mobile_number: '',
    },
  ],

  // email, firstname, lastname, phone, mobile, address, city, zipcode
  customers_edit: [
    {
      name: 'firstname',
      placeholder: 'First Name',
      value: 'firstname',
    },
    {
      name: 'lastname',
      placeholder: 'Last Name',
      value: 'lastname',
    },
    {
      name: 'email',
      placeholder: 'Email',
      value: 'email',
    },
    {
      name: 'phone',
      placeholder: 'Phone Number',
      value: 'phone',
    },
    {
      name: 'mobile',
      placeholder: 'Mobile Number',
      value: 'mobile',
    },
    {
      name: 'address',
      placeholder: 'Address',
      value: 'address',
    },
    {
      name: 'city',
      placeholder: 'City',
      value: 'city',
    },
    {
      name: 'zipcode',
      placeholder: 'Zip',
      value: 'zipcode',
    },
  ],

  //
  //
  //
  // packets
  // default populate empty objects - used in production.
  customer_packet_history: [
    {
      share_uuid: '',
      lead_source: '',
      show_price: false,
      created_dt: new Date().getTime(),
      viewed_dt: '',
      heatmap: false,
      disable_pc_alerts: false,
      vehicle_id: {
        vehicle_id: 0,
        company_store_id: {
          company_store_id: 0,
          store_full_name: '',
        },
        make: '',
        model: '',
        year: '',
        trim: '',
        vin: '',
        stock: '',
        stock_type: '',
        vehicle_type: '',
        image_path: '',
        cpo: '',
        miles: 0,
        veh_in_date: new Date().getTime(),
        total_cost: '',
        warranty_type: '',
      },
      user_id_fk: {
        legacy_user_id: 0,
        username: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        user_email_address: '',
        user_phone: '',
        title: '',
      },
      source: {
        source_id: 0,
        label: '',
        type: '',
      },
      packet_history: {
        packet_history_id: 0,
        message_id: 0,
        date_created: new Date().getTime(),
        user_created: 0,
        user_ip_address: '',
        message_sent: '',
        source: 0,
        vehicle_id: 0,
        packet_action_id: 0,
      },
    },
    {
      share_uuid: '',
      lead_source: '',
      show_price: false,
      created_dt: new Date().getTime(),
      viewed_dt: '',
      heatmap: false,
      disable_pc_alerts: false,
      vehicle_id: {
        vehicle_id: 0,
        company_store_id: {
          company_store_id: 0,
          store_full_name: '',
        },
        make: '',
        model: '',
        year: '',
        trim: '',
        vin: '',
        stock: '',
        stock_type: '',
        vehicle_type: '',
        image_path: '',
        cpo: '',
        miles: 0,
        veh_in_date: new Date().getTime(),
        total_cost: '',
        warranty_type: '',
      },
      user_id_fk: {
        legacy_user_id: 0,
        username: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        user_email_address: '',
        user_phone: '',
        title: '',
      },
      source: {
        source_id: 0,
        label: '',
        type: '',
      },
      packet_history: {
        packet_history_id: 0,
        message_id: 0,
        date_created: new Date().getTime(),
        user_created: 0,
        user_ip_address: '',
        message_sent: '',
        source: 0,
        vehicle_id: 0,
        packet_action_id: 0,
      },
    },
    {
      share_uuid: '',
      lead_source: '',
      show_price: false,
      created_dt: new Date().getTime(),
      viewed_dt: '',
      heatmap: false,
      disable_pc_alerts: false,
      vehicle_id: {
        vehicle_id: 0,
        company_store_id: {
          company_store_id: 0,
          store_full_name: '',
        },
        make: '',
        model: '',
        year: '',
        trim: '',
        vin: '',
        stock: '',
        stock_type: '',
        vehicle_type: '',
        image_path: '',
        cpo: '',
        miles: 0,
        veh_in_date: new Date().getTime(),
        total_cost: '',
        warranty_type: '',
      },
      user_id_fk: {
        legacy_user_id: 0,
        username: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        user_email_address: '',
        user_phone: '',
        title: '',
      },
      source: {
        source_id: 0,
        label: '',
        type: '',
      },
      packet_history: {
        packet_history_id: 0,
        message_id: 0,
        date_created: new Date().getTime(),
        user_created: 0,
        user_ip_address: '',
        message_sent: '',
        source: 0,
        vehicle_id: 0,
        packet_action_id: 0,
      },
    },
  ],

  //
  //
  //
  // admin
  // default populate empty objects - used in production.
  admin_team_members: [
    {
      legacy_user_id: null,
      username: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      user_email_address: '',
      user_phone: '',
      image_location: '',
      user_description: '',
      signature: '',
      profile_image: '',
      bio: '',
      send_message: '',
      date_created: '',
      user_created: 0,
      date_modified: '',
      user_modified: 0,
      active: false,
      password_reset_request: '',
      user_mobile: '',
      send_email_alerts: false,
      email_notification_interval_id: 0,
      send_mobile_alerts: false,
      notification_interval_id: 0,
      last_login: '',
      forgot_uuid: '',
      forgot_dt: '',
      is_admin: 0,
      announcements: false,
      enable_alerts: false,
      enable_views: false,
      view_frequency: 'first',
      title: '',
      last_login_mobile: '',
      default_store_id: 0,
    },
  ],

  // email, firstname, lastname, phone, mobile
  team_mem_edit: [
    {
      name: 'first_name',
      placeholder: 'First Name',
      value: 'first_name',
    },
    {
      name: 'last_name',
      placeholder: 'Last Name',
      value: 'last_name',
    },
    {
      name: 'password',
      placeholder: 'New Password',
      value: 'password',
    },
    {
      name: 'passwordConfirmed',
      placeholder: 'Confirm Password',
      value: 'passwordConfirmed',
    },
    {
      name: 'user_email_address',
      placeholder: 'Email',
      value: 'user_email_address',
    },
    {
      name: 'crm_email',
      placeholder: 'CRM Email Address',
      value: 'crm_email',
    },
    {
      name: 'user_phone',
      placeholder: 'Phone Number',
      value: 'user_phone',
    },
    {
      name: 'user_mobile',
      placeholder: 'Mobile Number',
      value: 'user_mobile',
    },
  ],

  //
  //
  //
  // reports
  // default populate empty objects - used in production.
  activity_report: [
    {
      generated_date: new Date().getTime(),
      html_link: '',
      month: '',
      pdf_link: '',
      year: 0,
    },
    {
      generated_date: new Date().getTime(),
      html_link: '',
      month: '',
      pdf_link: '',
      year: 0,
    },
    {
      generated_date: new Date().getTime(),
      html_link: '',
      month: '',
      pdf_link: '',
      year: 0,
    },
  ],

  // default populate empty objects - used in production.
  status_report_on_load_start: {
    page: '1',
    page_total: 0,
    results_count: 0,
    results: [
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'Mustang',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'D7339A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Nissan',
        model: 'ARMADA',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'F18718A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Lincoln',
        model: 'MKX',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'F19224A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
    ],
  },

  // default populate empty objects - used in production.
  status_report: {
    page: '1',
    page_total: 0,
    results_count: 0,
    results: [
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'Mustang',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'D7339A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Nissan',
        model: 'ARMADA',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'F18718A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Lincoln',
        model: 'MKX',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'F19224A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Lincoln',
        model: 'MKX',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'U8120',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'Mustang',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'D7339A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'EXPLORER',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'F18942A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Toyota',
        model: 'Prius',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'U7956A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'F-150',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'D7656A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'F-250-SUPER-DUTY',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'U8166',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
      {
        about_us: Math.random() >= 0.5,
        api_brochure: Math.random() >= 0.5,
        brochure: Math.random() >= 0.5,
        days: Math.floor(Math.random() * 1000) + 1,
        delivery: Math.random() >= 0.5,
        delivery_receipt: Math.random() >= 0.5,
        images: Math.random() >= 0.5,
        invoice: Math.random() >= 0.5,
        make: 'Ford',
        model: 'Mustang',
        mpg: Math.random() >= 0.5,
        msrp: Math.random() >= 0.5,
        one_low_price: Math.random() >= 0.5,
        pdf: Math.random() >= 0.5,
        rate: Math.random() >= 0.5,
        stock: 'D7339A',
        title: Math.random() >= 0.5,
        vehicle_id: 5587306,
        vmi: Math.random() >= 0.5,
        warrenty: Math.random() >= 0.5,
        why_buy_from_us: Math.random() >= 0.5,
        window_sticker: Math.random() >= 0.5,
        year: 0,
      },
    ],
  },

  // default populate empty objects - used in production.
  finance_table_non_cpo: [
    {
      company_store_id: 0,
      year: new Date().getTime(),
      term_60: 0,
      rate_60: '',
      term_60_80: 0,
      rate_60_80: '',
      term_80_100: 0,
      rate_80_100: '',
      term_100: 0,
      rate_100: '',
    },
    {
      company_store_id: 0,
      year: new Date().getTime(),
      term_60: 0,
      rate_60: '',
      term_60_80: 0,
      rate_60_80: '',
      term_80_100: 0,
      rate_80_100: '',
      term_100: 0,
      rate_100: '',
    },
    {
      company_store_id: 0,
      year: new Date().getTime(),
      term_60: 0,
      rate_60: '',
      term_60_80: 0,
      rate_60_80: '',
      term_80_100: 0,
      rate_80_100: '',
      term_100: 0,
      rate_100: '',
    },
  ],

  //
  //
  //
  // packet send
  // default populate empty objects - used in production.
  packetsend_entries: [
    {
      customer_first_name: '',
      customer_last_name: '',
      date_launched: new Date().getTime(),
      count: 0,
      customer_email: '',
      first_name: '',
      last_name: '',
      date_viewed: new Date().getTime(),
      module: '',
      date_printed: new Date().getTime(),
    },
    {
      customer_first_name: '',
      customer_last_name: '',
      date_launched: new Date().getTime(),
      count: 0,
      customer_email: '',
      first_name: '',
      last_name: '',
      date_viewed: new Date().getTime(),
      module: '',
      date_printed: new Date().getTime(),
    },
    {
      customer_first_name: '',
      customer_last_name: '',
      date_launched: new Date().getTime(),
      count: 0,
      customer_email: '',
      first_name: '',
      last_name: '',
      date_viewed: new Date().getTime(),
      module: '',
      date_printed: new Date().getTime(),
    },
  ],

  // packetsend button types - used in production.
  packetsend_buttons: [
    {
      title: 'Packet Shares',
      title_id: 'launched',
    },
    {
      title: 'User Reviews',
      title_id: 'viewed',
    },
    {
      title: 'Printed',
      title_id: 'printed',
    },
    {
      title: 'Price Changes',
      title_id: 'price-history',
    },
  ],

  // default populate empty objects - used in production.
  packet_mock_images: {
    count: 0,
    next: null,
    previous: null,
    results: [BlankInventoryImg, BlankInventoryImg, BlankInventoryImg],
  },
}
