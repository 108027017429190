import React from 'react'

const CogIcon = props => {
  return (
    <svg className="icon-additem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
      <path d="M49.46,30.19a25.24,25.24,0,0,0,0-10.29H46.4a22,22,0,0,0-2.67-6.45l2.16-2.19A25.22,25.22,0,0,0,38.7,4.09L36.51,6.25a21.62,21.62,0,0,0-6.38-2.64l0-3.08A25.3,25.3,0,0,0,19.8.54l.06,3.07a21.77,21.77,0,0,0-6.31,2.6L11.33,4.07a25.1,25.1,0,0,0-7.24,7.24l2.17,2.17A21.72,21.72,0,0,0,3.6,19.89H.52a25.29,25.29,0,0,0,0,10.3l3.07,0a22.16,22.16,0,0,0,2.62,6.32l-2.15,2.2a25.08,25.08,0,0,0,7.15,7.18l2.22-2.13a21.84,21.84,0,0,0,6.43,2.66l-.07,3.07a25.11,25.11,0,0,0,10.36,0l0-3.07a21.92,21.92,0,0,0,6.47-2.71l2.19,2.16a25.22,25.22,0,0,0,7.06-7.09l-2.15-2.19a22.07,22.07,0,0,0,2.67-6.4ZM25.05,37.6A12.55,12.55,0,1,1,37.6,25.05,12.55,12.55,0,0,1,25.05,37.6Z" />
    </svg>
  )
}
export default CogIcon
