import React, { Component } from 'react'
import Modal from '../../layouts/modal'
import { hideModal } from '../../../core/_actions/modalActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { changeCompanyStore } from '../../../core/actions/stafftoolactions'

import '../styles/changestoremodal.scss'

class ChangeStoreModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entire_company_access: true,
    }
  }
  changeCompanyStore = () => {
    const { token, history, store } = this.props
    const { entire_company_access } = this.state
    changeCompanyStore({
      token,
      company_store_id: store.company_store_id,
      entire_company_access,
      history,
    })
  }
  render() {
    const { entire_company_access } = this.state
    const { store } = this.props
    return (
      <Modal>
        <div className="change-company-modal">
          <h1>{`${store.store_full_name} (${store.store_type_id === 1 ? 'New' : 'Pre-Owned'})`}</h1>
          {`Are you sure you want to change to this store?`}
          <div>
            <label>
              <input
                type="checkbox"
                checked={entire_company_access}
                onClick={() => {
                  this.setState(prevState => {
                    return { entire_company_access: !prevState.entire_company_access }
                  })
                }}
              />
              Entire Company Access
            </label>
          </div>
          <div
            className="change-company-modal-btn-confirm"
            onClick={() => {
              this.changeCompanyStore()
            }}
          >
            Confirm
          </div>
          <div
            className="change-company-modal-btn-cancel"
            onClick={() => {
              this.props.hideModalConnect()
            }}
          >
            Cancel
          </div>
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  store: state.modal.props.store,
  token: state.authentication && state.authentication.user && state.authentication.user.token,
})
const ChangeStoreModal = withRouter(ChangeStoreModalComponent)
export default connect(mapStateToProps, {
  hideModalConnect: hideModal,
})(ChangeStoreModal)
