// general:

// constants
import {
  STASH_VIEWED_CUSTOMER,
  CLEAR_VIEWED_CUSTOMER,
  STASH_TABLE_STATE,
} from '../_constants/customerTypes'

const initState = {
  viewed_customer: null,
  table_state: null,
}

export function customerstash(state, action) {
  if (!state) {
    state = initState
  }

  switch (action.type) {
    case STASH_VIEWED_CUSTOMER:
      return {
        ...state,
        viewed_customer: action.viewed_customer,
      }
    case CLEAR_VIEWED_CUSTOMER:
      return {
        ...state,
        viewed_customer: null,
      }
    case STASH_TABLE_STATE:
      return {
        ...state,
        table_state: action.table_state,
      }

    default:
      return state
  }
}
