import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  getUserStoreGroups,
  updateActiveStore,
} from '../../../core/actions/features/toolsactions.js'
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'

class StoreSelectComponent extends PureComponent {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    //console.log('THIS PROPS STORES')
    //console.log(this.props.stores)
    if (!this.props.stores.length) {
      this.props.getUserStoreGroups(this.props.userInfo.user_id, this.props.userInfo.dj_token)
      this.props.updateActiveStore(
        this.props.defaultStore,
        this.props.userInfo.store_full_name,
        null,
      )
    }
  }
  componentDidUpdate(prevProps) {
    //console.log('   pREVIOUS PROPS')
    //console.log(this.props.stores)
    //console.log(this.props.defaultStore)
    if (
      this.props.defaultStore !== prevProps.defaultStore ||
      this.props.stores !== prevProps.stores
    ) {
      let foundStore = this.findStore(this.props.defaultStore)
      this.props.updateActiveStore(
        this.props.defaultStore,
        foundStore.company_store_name,
        foundStore,
      )
    }
  }
  findStore = store_id => {
    //Yes I know this is terrible forgive me pls - Khris
    let foundStore = this.props.stores[0]
    try {
      foundStore = this.props.stores.filter(store => {
        if (store_id === store.company_store_id) {
          return true
        }
      })[0]
    } catch (e) {
      //console.log(e)
    }
    return foundStore
  }
  createSelectOptions = () => {
    const storeOptions = []
    this.props.stores
      .sort((a, b) => {
        if (a.company_store_name < b.company_store_name) {
          return -1
        }
        if (a.company_store_name > b.company_store_name) {
          return 1
        }
        return 0
      })
      .map(store => {
        storeOptions.push(
          <option
            is={store.company_store_name}
            key={store.company_store_id}
            value={store.company_store_id}
          >
            {`${store.company_store_name} (${store.store_type === 1 ? 'New' : 'Pre-Owned'})`}
          </option>,
        )
      })
    return (
      <select
        id="dashboard-store-select"
        value={this.props.activeStoreId}
        onChange={e => {
          const store = this.findStore(parseInt(e.target.value))
          this.props.updateActiveStore(e.target.value, store.company_store_name, store)
        }}
      >
        {storeOptions}
      </select>
    )
  }
  render() {
    //console.log(this.props.activeStoreId)
    if (this.props.defaultStore && this.props.activeStoreId !== -1) {
      if (this.props.userInfo.is_admin) {
        return <React.Fragment>{this.createSelectOptions()}</React.Fragment>
      }
      return (
        <h2>
          {this.props.stores.length &&
            this.props.stores.filter(store => {
              return store.company_store_id === this.props.defaultStore
            })[0].company_store_name}
        </h2>
      )
    }
    return <React.Fragment />
  }
}
const mapStateToProps = state => ({
  defaultStore: state.authentication.user.user.profile.default_company_store,
  stores: state.tools.stores,
  activeStoreId: state.tools.activeStoreId,
})

const StoreSelect = UserInfoWrapper(StoreSelectComponent)
export default connect(mapStateToProps, {
  getUserStoreGroups,
  updateActiveStore,
})(StoreSelect)
