import React from 'react'

const IconHelpX = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <circle className="a" cx="25" cy="25" r="22.5" />
      <line className="c" x1="13.72" y1="36.28" x2="36.28" y2="13.72" />
      <line className="c" x1="36.28" y1="36.28" x2="13.72" y2="13.72" />
    </svg>
  )
}
export default IconHelpX
