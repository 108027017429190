import React from 'react'

const AccountSettingsIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
      <circle className="b" cx="25" cy="25" r="19.88" />
      <path
        className="a"
        d="M28.36,23.85V22.44a6.69,6.69,0,1,0-6.71,0v1.39a7,7,0,0,0-6.65,7v4.7a1.25,1.25,0,0,0,1.25,1.25h17.5A1.25,1.25,0,0,0,35,35.53V30.84A7,7,0,0,0,28.36,23.85Z"
      />
    </svg>
  )
}
export default AccountSettingsIcon
