import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getInventoryContent,
  updateBasket,
  setActiveSingleSend,
} from '../../../core/actions/inventoryactions.js'
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import LoadingSpinner from '../../helpers/loadingspinner.js'
import Car from './car'
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import {
  PRICE_HISTORY_MODAL,
  PACKET_SEND_MODAL,
  PACKET_ACTIVITY_MODAL,
} from '../../../core/_constants/modalTypes'

type Props = {
  vehicles: Object,
  fetchPackets: Function,
  basket: Object,
  updateBasket: Function,
  page: number,
  activePackets: Function,
  packetsPerPage: number,
  userInfo: Object,
}

class CarDisplayComponent extends PureComponent {
  props: Props
  constructor(props: Props) {
    super(props)
  }
  componentDidUpdate(prevProps) {
    const { basket } = this.props
    if (prevProps.basket.length < basket.length && basket.length === 3) {
      toast('Cart is Now Full (3/3)')
    }
  }
  // ***********************LETS*CALL*MODAL*SETUP***********************
  priceHistoryModal = args => {
    const { vehicle_id, vin, stock_num, currency_unit } = args
    this.props.showModal(PRICE_HISTORY_MODAL, {
      title: 'Price History',
      vehicle_id,
      vin,
      stock_num,
      currency_unit,
      token: this.props.userInfo.dj_token,
    })
  }
  packetActivityModal = vehicle => {
    this.props.showModal(PACKET_ACTIVITY_MODAL, { vehicle })
  }
  showSendModal = vehicle => {
    this.props.showModal(PACKET_SEND_MODAL, { vehicle })
  }
  viewPacket = vehicle => {
    this.props.setActiveSingleSend(vehicle)
    this.props.hideModal()
  }
  // ***********************LETS*CALL*MODAL*SETUP***********************
  render() {
    const { page, vehicles, basket, isLoading, isPrivate } = this.props

    //Should be able to do this with one return and car display div but it's this way for now.
    //Rolling Load Animation could be applied to first few vehicles for better performance -Khris
    if (vehicles && vehicles.length && !isLoading) {
      let basketIds = basket.map(vehicle => {
        return vehicle.vehicle_id
      })

      return (
        <div className="car-display" style={{ animation: `1250ms fadeIn` }}>
          {vehicles &&
            vehicles.map((vehicle, i) => (
              <Car
                animationDelay={(i + 1) * 30}
                inBasket={basketIds.includes(vehicle.vehicle_id)}
                basket={basket}
                updateBasket={this.props.updateBasket}
                priceHistory={this.priceHistoryModal}
                viewPacket={this.viewPacket}
                sendPacket={this.showSendModal}
                packetActivity={this.packetActivityModal}
                addCartDisabled={this.props.basket.length > 2}
                sendDisabled={basket.length > 0}
                key={`${vehicle.vehicle_id} ${i}`}
                vehicle={vehicle}
                isPrivate={isPrivate}
                userInfo={this.props.userInfo}
              />
            ))}
        </div>
      )
    } else if (isLoading) {
      return (
        <div className="car-display">
          <LoadingSpinner loading wheel timeout={60 * 1000} />
        </div>
      )
    }
    return <div className="car-display no-results">No Results Found</div>
  }
}

const mapStateToProps = state => ({
  vehicles: state.inventory.inventoryContent && state.inventory.inventoryContent.results,
  basket: state.inventory.basket,
  isLoading: state.inventory.isLoading > 0,
  isPrivate: state.helpers.isPrivate,
})

const CarDisplay = UserInfoWrapper(CarDisplayComponent)
export default connect(mapStateToProps, {
  updateBasket,
  hideModal,
  showModal,
  setActiveSingleSend,
})(CarDisplay)
