// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import { updateSortBy } from '../../../core/actions/inventoryactions.js'

class SortByComponent extends PureComponent {
  props: Props
  update = sortBy => {
    const {
      searchQuery,
      facetFields,
      activeFacetFields,
      facetOptions,
      facetClearAllConnect,
      user_id,
      token,
      activeFacetOptions,
      updateSortByConnect,
    } = this.props
    updateSortByConnect({
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery,
    })
  }
  render() {
    const { sortBy } = this.props
    return (
      <div className="sort-by" defaultValue={sortBy}>
        <label style={{ display: 'none' }} htmlFor="optGroupSelector">
          Select how to sort by
        </label>
        Sort by: &nbsp;
        <select
          id="optGroupSelector"
          onChange={e => {
            this.update(e.target.value)
          }}
        >
          <optgroup label="Distance">
            <option value="">Nearest</option>
          </optgroup>
          <optgroup label="Year">
            <option value="-year">Newest Model Year</option>
            <option value="year">Oldest Model Year</option>
          </optgroup>
          <optgroup label="Price">
            <option value="-price">Most Expensive</option>
            <option value="price">Least Expensive</option>
          </optgroup>
          <optgroup label="Mileage">
            <option value="-miles">Highest Miles</option>
            <option value="miles">Lowest Miles</option>
          </optgroup>
          <optgroup label="Make">
            <option value="make">Make [A-Z]</option>
            <option value="-make">Make [Z-A]</option>
          </optgroup>
          <optgroup label="Model">
            <option value="model">Model [A-Z]</option>
            <option value="-model">Model [Z-A]</option>
          </optgroup>
          <optgroup label="Inventory Age">
            <option value="-veh_in_date">Newest in Inventory</option>
            <option value="veh_in_date">Oldest in Inventory</option>
          </optgroup>
          <optgroup label="Last Price Change">
            <option value="-last_price_change">Newest Price Change</option>
            <option value="last_price_change">Oldest Price Change</option>
          </optgroup>
        </select>
      </div>
    )
  }
}

const mapStateToProps = (state: Object) => ({
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  activeFacetOptions: state.inventory.activeFacetOptions,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  token: state.authentication.user.token,
  sortBy: state.inventory.sortBy,
  searchQuery: state.inventory.searchQuery,
})
const SortBy = UserInfoWrapper(SortByComponent)
export default connect(mapStateToProps, { updateSortByConnect: updateSortBy })(SortBy)
