// core files
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showModal, hideModal } from '../../core/_actions/modalActions'
import { STICKER_PULL_COVERAGE_MODAL } from '../../core/_constants/modalTypes'

// css styles
import '../styles/universalstyles.scss'
import './styles/stickerpull.scss'
// child components
import StickerPuller from './components/stickerpullcomp'
import Helper from '../../core/helpers/platformhelper'

type Props = {}

export class StickerPullPage extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.
    //this.setState({ userInfo: newProps.userInfo })
  }

  componentWillUnmount() {
    this.setState({})
  }

  renderHeader() {
    return (
      <div className="stickerpull-header">
        <h1>Vehicle OEM Window Sticker/Buildsheet Lookup</h1>
        <p>
          Enter your 17-character Vehicle Identification Number (VIN) below to access the OEM Window
          Sticker/Buildsheet.
        </p>

        <p>
          Year, Make, & Model restrictions apply. To see a full list of our Year, Make, & Model
          coverage -{' '}
          <a
            href="#"
            onClick={() => {
              this.props.showModalConnect(STICKER_PULL_COVERAGE_MODAL, {
                token: this.props.token,
              })
            }}
          >
            click here
          </a>
        </p>
      </div>
    )
  }

  render() {
    // if sticker pull disabled
    if (!this.props.showStickerPull) {
      return (
        <div>
          <div align="center" className="sticker_box">
            {this.renderHeader()}
            <h3> Sticker Pull is not currently available on this account.</h3>
          </div>
        </div>
      )
    }

    // render normally
    return (
      <div>
        <div align="center" className="sticker_box">
          {this.renderHeader()}
          {this.props.showStickerPull ? (
            <StickerPuller />
          ) : (
            <h2>Sticker Pulling not available to this account</h2>
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  showStickerPull:
    state.authentication.user.user && state.authentication.user.user.sticker_puller_enabled,
  token: state.authentication.user.token,
})
const StickerPullParent = StickerPullPage
export default connect(mapStateToProps, {
  showModalConnect: showModal,
})(StickerPullParent)
