// general:

// @flow
import React, { Component } from 'react'
// node packages
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import ReactToPrint from 'react-to-print'
import Collapsible from 'react-collapsible'
// core files
// -- redux
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { packetViewActive } from '../../../core/_actions/packetviewActions'
import { setActiveSingleSend, updateBasket } from '../../../core/actions/inventoryactions'
import {
  SINGLE_SEND_MODAL,
  REPORT_ISSUE_MODAL,
  PACKET_SEND_MODAL,
  RESIZE_QR_MODAL,
} from '../../../core/_constants/modalTypes'
// --- actions
import PacketActions from '../../../core/actions/packets/packetactions'
// helpers
import { activeNormalButtonStyle } from '../../helpers/reusables'
// icons
import IconExternalLink from '../../../core/assets/images/icons/icon-external-link'
import ReportIssueIcon from '../../../core/assets/images/icons/reportissue-icon.js'
import PrintIcon from '../../../core/assets/images/icons/print-icon.js'
import SendIcon from '../../../core/assets/images/icons/send-icon.js'
import ActivityIcon from '../../../core/assets/images/icons/activity-icon.js'
import CartIconW from '../../inventory/assets/carticonw'
import ArchiveIcon from '../../../core/assets/images/icons/archive-icon.js'
import QRIcon from '../../../core/assets/images/icons/qr-icon.js'
import Timeline from '../../../core/assets/images/icons/timeline'
import ArrowDownIcon from '../../../core/assets/images/icons/arrow-down_icon'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/packetsend.scss'
// Components
import QRCodePrint from './components/qrcodeprint.js'
// global(s)
// --- styles

const activeNormalStyle = activeNormalButtonStyle

type Props = {
  vehicle_info: Object,
  view_type: string,
  showModal: Function,
  hideModal: Function,
  packetViewActive: Function,
  setActiveSingleSend: Function,
  userInfo: Object,
  isStaff: Boolean,
}

class PacketRight extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      vehicle_info: this.props.vehicle_info,
      view_type: this.props.view_type,
      loading: false,
    }

    this._SendPacket = this.SendPacket.bind(this)
  }

  state: {
    vehicle_info: Object,
    view_type: string,
    loading: boolean,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
  }
  componentDidUpdate(prevProps) {
    const { basket } = this.props
    if (prevProps.basket.length < basket.length && basket.length === 3) {
      toast('Cart is Now Full (3/3)')
    }
  }
  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({ vehicle_info: newProps.vehicle_info, view_type: newProps.view_type })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ vehicle_info: null, view_type: 'general_info', loading: false })
  }
  mistake = message => {
    if (!toast.isActive(this.mistakeToastId) || this.mistakeToastMessage !== message) {
      this.mistakeToastId = toast(message)
      this.mistakeToastMessage = message
    }
  }
  // SendPacket
  SendPacket() {
    this.setState({ loading: true })
    PacketActions.getVehicleInfoForSend(
      this.state.vehicle_info.vehicle_id,
      this.state.vehicle_info.stock,
      this.props.userInfo.dj_token,
      this.props.vehicle_info.vin,
      this.props.userInfo.user_id,
    ).then(json => {
      console.log(json)
      if (json && json.results && json.results.length > 0) {
        for (const d of json.results) {
          if (this.state.vehicle_info.stock === d.stock) {
            this.props.setActiveSingleSend(d)
            this.props.showModal(PACKET_SEND_MODAL, { vehicle: json.results[0] })
            this.setState({ loading: false })
          }
        }
      }
    })
  }

  props: Props
  _SendPacket: Function // function

  render() {
    // states
    const { vehicle_info } = this.state
    let basketIds = this.props.basket.map(vehicle => {
      return vehicle.vehicle_id
    })

    if (vehicle_info !== null && vehicle_info !== undefined) {
      const { view_type, loading } = this.state
      const { vin } = vehicle_info
      const vehicleId = vehicle_info.vehicle_id
      let inBasket = basketIds.includes(vehicle_info.vehicle_id)
      // styles
      const style = view_type !== 'modules' ? activeNormalStyle : null
      return (
        <div className="packetSendSideContainersStyle packetRightSideStyle">
          {/* Arbitrary div for Whatfix*/}
          <div className="packetSendRightButtonBox">
            {vehicle_info.archived ? (
              <React.Fragment />
            ) : (
              <React.Fragment>
                <button
                  className={`car-send-packet ${this.props.basket.length > 0 ? 'disabled' : ''}`}
                  onClick={() => {
                    if (!(this.props.basket.length > 0)) {
                      this._SendPacket()
                    }
                  }}
                  title={`${this.props.basket.length > 0 ? 'Please Use Cart Send' : 'Send Packet'}`}
                  id="sendPacketIcon"
                  disabled={loading}
                  style={{ minWidth: '100%', outline: 0 }}
                >
                  <span>
                    <SendIcon /> Send Packet
                  </span>
                </button>
                <div
                  onClick={() => {
                    if (!inBasket && this.props.basket.length > 2) {
                      this.mistake('Cart is Full (3/3)')
                    } else {
                      this.props.updateBasket(vehicle_info)
                    }
                  }}
                  className={[
                    `${inBasket ? 'remove' : 'add'}-packet-button`,
                    'update-packet-button',
                    `${!inBasket && this.props.basket.length > 2 ? 'disabled' : ''}`,
                    'packetSendButtonsStyle',
                  ].join(' ')}
                >
                  <span id="cartIcon">
                    <CartIconW />
                    {inBasket
                      ? 'Remove from Cart'
                      : `${this.props.basket.length > 2 ? 'Cart Full (3/3)' : 'Add to Cart'}`}
                  </span>
                </div>
              </React.Fragment>
            )}
            {/* Arbitrary div for Whatfix*/}
            <div className="packetSendRightButtonSubbox">
              <NavLink
                className={
                  'packetSendButtonsStyle ' +
                  (view_type == 'print' ? 'activeModule' : '') +
                  `downloadArchiveStyle`
                }
                id="printModule"
                to={`/packet/print/${vehicleId}`}
                activestyle={style}
                onClick={() => this.props.packetViewActive('print')}
              >
                <span>
                  {vehicle_info.archived ? (
                    <React.Fragment>
                      <ArchiveIcon color="#337dce" /> Download Archive
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PrintIcon /> Print Documents
                    </React.Fragment>
                  )}
                </span>
              </NavLink>

              <a
                className="packetSendButtonsStyle"
                href={`https://www.nhtsa.gov/recalls?vin=${vin}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <IconExternalLink /> Recall Check
                </span>
              </a>

              <NavLink
                className={
                  'packetSendButtonsStyle ' + (view_type == 'activity' ? 'activeModule' : '')
                }
                id="activityModule"
                to={`/packet/activity/${vehicleId}`}
                activestyle={style}
                onClick={() => this.props.packetViewActive('activity')}
              >
                <span>
                  <ActivityIcon /> Activity
                </span>
              </NavLink>

              {this.props.isStaff && (
                <NavLink
                  className={
                    'packetSendButtonsStyle ' + (view_type == 'timeline' ? 'timelineActive' : '')
                  }
                  id="timeline"
                  to={`/packet/timeline/${vehicleId}`}
                  onClick={() => this.props.packetViewActive('timeline')}
                >
                  <span>
                    <Timeline /> Vehicle Timeline Service
                  </span>
                </NavLink>
              )}

              <Collapsible
                classParentString="Collapsible-qr"
                trigger={
                  <div className="packetSendButtonsStyle">
                    <span>
                      <QRIcon /> QR Code
                      <ArrowDownIcon className="qr-arrow" />
                    </span>
                  </div>
                }
                transitionTime={150}
              >
                {/* Print Default QR */}
                <ReactToPrint
                  trigger={() => {
                    return (
                      <div className="packetSendButtonsStyle">
                        <span>
                          <QRIcon />
                          Default
                        </span>
                      </div>
                    )
                  }}
                  content={() => this.printDefaultQRCodeRef}
                />
                <div style={{ display: 'none' }}>
                  <QRCodePrint
                    type="default"
                    vehicle={vehicle_info}
                    ref={el => (this.printDefaultQRCodeRef = el)}
                  />
                </div>
                {/* Simple QR: launches a modal with a slider for resizing and the ReactToPrint ref */}
                <div
                  className={'packetSendButtonsStyle'}
                  onClick={() => {
                    this.props.showModal(RESIZE_QR_MODAL, {
                      vehicle: { vehicle_info },
                      type: 'simple',
                    })
                  }}
                >
                  <span>
                    <QRIcon />
                    Simple
                  </span>
                </div>
                <div style={{ display: 'none' }}>
                  <QRCodePrint vehicle={vehicle_info} type="simple" />
                </div>
                {/* Window Sticker QR: launches a modal with a slider for resizing and the ReactToPrint ref */}
                <div
                  className={'packetSendButtonsStyle'}
                  onClick={() => {
                    this.props.showModal(RESIZE_QR_MODAL, {
                      vehicle: { vehicle_info },
                      type: 'window sticker',
                    })
                  }}
                >
                  <span>
                    <QRIcon />
                    Window Sticker
                  </span>
                </div>
                <div style={{ display: 'none' }}>
                  <QRCodePrint vehicle={vehicle_info} type="window sticker" />
                </div>
              </Collapsible>
              <br />
              <button
                className="packetSendButtonsStyle"
                id="reportIssue"
                to={`/packet/report-issue/${vehicleId}`}
                activestyle={style}
                onClick={() => {
                  this.props.showModal(REPORT_ISSUE_MODAL, {
                    vehicleId: this.state.vehicle_info.vehicle_id,
                  })
                }}
              >
                <span>
                  <ReportIssueIcon /> Report Issue
                </span>
              </button>
            </div>
          </div>
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  vehicle_info: {
    ...state.packetstash.vehicle_info,
    thumbnail_url: state.packetstash.vehicle_image_thumb,
  },
  view_type: state.packetview.view_type,
  basket: state.inventory.basket,
  isStaff:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.is_staff,
})
export default connect(mapStateToProps, {
  hideModal,
  showModal,
  packetViewActive,
  setActiveSingleSend,
  updateBasket,
})(PacketRight)
