import React, { Component } from 'react'
import Modal from '../../layouts/modal'
import { connect } from 'react-redux'
import format from 'date-fns/format'
import { getPacketActivity } from '../../../core/actions/inventoryactions.js'
import { SHOW_PACKET_ACTIVITY } from '../../../core/_constants/modalTypes'
import Helper from '../../../core/helpers/platformhelper'
import runtimeEnv from '../../../core/config/envConstants'
import ActivityIcon from '../../../core/assets/images/icons/activity-btn.js'

import LoadingSpinner from '../../helpers/loadingspinner'

import '../styles/packetactivitymodal.scss'
class PacketActivityModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      packetActivity: null,
      page: 1,
      hasError: false,
      errorMessage: null,
    }
  }
  componentDidMount() {
    const { vehicle } = this.props
    if (vehicle) {
      this.onMountGetActivity()
    }
  }
  onMountGetActivity() {
    const { vehicle, token } = this.props
    console.log(vehicle)
    getPacketActivity({ token, vehicleId: vehicle.vehicle_id, activityType: 'launched' }).then(
      res => {
        if (res.detail != undefined) {
          this.setState({ packetActivity: null, hasError: true, errorMessage: res.detail })
        } else if (res.results) {
          this.setState({ packetActivity: res.results, hasError: false, errorMessage: null })
        }
      },
    )
  }
  render() {
    const { packetActivity, hasError, errorMessage } = this.state
    if (packetActivity != null) {
      return (
        <Modal>
          <div className="packet-activity-modal">
            <ActivityIcon />
            <table className="packet-activity-table">
              <thead className="packet-activity-table-header">
                <tr>
                  <th>Recipient</th>
                  <th>Sender</th>
                  <th>Sent</th>
                  <th>Views</th>
                </tr>
              </thead>
              <tbody className="packet-activity-table-body">
                {packetActivity.map(activity => (
                  <tr>
                    <td>
                      {`${activity.customer_first_name} ${activity.customer_last_name}`}
                      <br />
                      {activity.customer_email}
                      <br />
                      {activity.customer_phone}
                    </td>
                    <td>
                      {`${activity.sales_person_first_name}`}
                      <br />
                      {`${activity.sales_person_last_name}`}
                    </td>
                    <td>
                      {format(Helper.localizeTime(activity.date_launched), 'M/d/yyyy')} <br />
                      {runtimeEnv.timezoneAbbr}
                    </td>
                    <td>{activity.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )
    }
    if (hasError) {
      return (
        <Modal>
          <div id="no-activity">
            <ActivityIcon />
            <br />
            <span>{errorMessage != null ? errorMessage : 'No packet send history'}</span>
          </div>
        </Modal>
      )
    }
    return (
      <Modal>
        <div>
          <LoadingSpinner loading wheel />
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  vehicle: state.modal.props.vehicle,
  token: state.userSettings.settings.dj_token,
})
export default connect(mapStateToProps, null)(PacketActivityModal)
