// general: Redux constants for User profile storage

// GENERAL CONST MODALS
// --- normal open and close modal
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE'
export const SETTINGS_EMPTY = 'SETTINGS_EMPTY'
export const TOS_ACCEPTANCE_UPDATE = 'TOS_ACCEPTANCE_UPDATE'
export const GET_TOS_ACCEPTANCE = 'GET_TOS_ACCEPTANCE'
export const GET_INTERCOM_INFO = 'GET_INTERCOM_INFO'
export const GET_DEFAULT_STORE = 'GET_DEFAULT_STORE'
export const SET_REQUIRED_ACTION = 'SET_REQUIRED_ACTION'
export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SET_USER_LOGIN_ERROR = 'SET_USER_LOGIN_ERROR'
