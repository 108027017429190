import React, { Component } from 'react'
import { connect } from 'react-redux'
import ChatIcon from '../../core/assets/images/icons/chat-icon.js'
import { showModal } from '../../core/_actions/modalActions'
import { WORD_TRACK_MODAL } from '../../core/_constants/modalTypes'
import './styles/wordtrack.scss'

class WordTrack extends Component {
  render() {
    const { isMSRP } = this.props
    return (
      <span
        className="word-track-icon"
        onClick={() => {
          this.props.showModal(WORD_TRACK_MODAL, { isMSRP })
        }}
      >
        <ChatIcon color="#3cb54a" />
      </span>
    )
  }
}
const mapStateToProps = state => ({
  state,
})
export default connect(mapStateToProps, {
  showModal,
})(WordTrack)
