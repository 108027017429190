import cloneDeep from 'lodash/cloneDeep'

import {
  GET_FILTER_OPTIONS,
  GET_INVENTORY_CONTENT,
  UPDATE_PAGE,
  UPDATE_BASKET,
  EMPTY_BASKET,
  UPDATE_ACTIVE_FILTERS,
  SET_LOADING,
  SEARCH_INVENTORY,
  SET_SEARCH_SUBMISSION,
  ACTIVE_SINGLE_SEND,
  GET_FACET_OPTIONS,
  UPDATE_FACET_OPTIONS,
  UPDATE_SORT_BY,
  CLEAR_FACET_OPTIONS,
} from '../_constants/inventoryTypes.js'
import {
  DjangoInventoryContentGetAPIURL,
  DjangoInventoryImagesGetAPIURL,
  DjangoInventoryOptionsGetAPIURL,
  DjangoInventoryContentFilteredGetAPIURL,
  DjangoInventorySearchGetAPIURL,
  DJangoVehicleInfoAPIURL,
  DjangoNewFilterOptions,
  DjangoPacketActivityAPIURL,
} from '../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

import debounce from 'lodash.debounce'

function getFilterOptions(args) {
  return function(dispatch) {
    let headers = {
      Authorization: `Token ${args.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoInventoryOptionsGetAPIURL}?user_id=${args.user_id}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        dispatch({
          type: GET_FILTER_OPTIONS,
          filterOptions: res.filter_options,
        })
      })
      .catch(e => console.log('Failed to get filter options'))
  }
}
function getInventoryContent(args) {
  let url = AIP_DJAPI_URLS.DjangoInventoryContentFilteredGetAPIURL
  if (args.filters) {
    // There's definitely a fancy way to do this with one map and Object.keys but something more verbose seems like a better idea
    // since the naming conventions for the returned data are inconsistent
    url += '?'
    url += args.filters.price_max ? `max_price=${args.filters.price_max}&` : ''
    url += args.filters.price_min ? `min_price=${args.filters.price_min}&` : ''
    url += args.filters.year_max ? `year_max=${args.filters.year_max}&` : ''
    url += args.filters.year_min ? `year_min=${args.filters.year_min}&` : ''
    url += args.filters.miles_max ? `max_miles=${args.filters.miles_max}&` : ''
    url += args.filters.miles_min ? `min_miles=${args.filters.miles_min}&` : ''
    url += args.filters.sortBy ? `sort_by=${args.filters.sortBy}&` : ''
    //   yes I know this is ridiculous :|
    args.filters.states &&
      Object.keys(args.filters.states).map(state =>
        args.filters.states[state].map(
          location => (url += `company_store_id=${Object.keys(location)[0]}&`),
        ),
      )
    args.filters.locations &&
      args.filters.locations.map(locations => {
        locations.split(' ').map(location => {
          url += `company_store_id=${location}&`
        })
      })
    args.filters.cpos && args.filters.cpos.map(cpo => (url += `cpo=${cpo}&`))
    args.filters.certifications &&
      args.filters.certifications.map(cert => (url += `certification_type=${cert}&`))
    args.filters.stock_types && args.filters.stock_types.map(type => (url += `stock_type=${type}&`))
    args.filters.vehicle_types &&
      args.filters.vehicle_types.map(vehicle_type => (url += `vehicle_type=${vehicle_type}&`))
    args.filters.makes && args.filters.makes.map(make => (url += `make=${make}&`))
    args.filters.models && args.filters.models.map(model => (url += `model=${model}&`))
    url = `${url}user_id=${args.user_id}&page=${args.page}`
  } else {
    url = `${url}?user_id=${args.user_id}&page=${args.page}`
  }
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${url}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
function updatePage(args) {
  return function(dispatch) {
    dispatch({
      type: SET_LOADING,
    })
    dispatch({
      type: UPDATE_PAGE,
      page: args.page,
    })
    getInventoryContent(args)
      .then(res => {
        dispatch({
          type: GET_INVENTORY_CONTENT,
          inventoryContent: res,
          page: args.page,
        })
      })
      .catch(e => console.log('No Inventory Content Returned'))
  }
}
function updateActiveFilters(args) {
  return function(dispatch) {
    // Split these into separate dispatches so the filter UI would update instantly instead of
    // waiting for the api call to return
    dispatch({
      type: SET_LOADING,
    })
    dispatch({
      type: UPDATE_ACTIVE_FILTERS,
      activeFilters: args.filters,
    })
    getInventoryContent(args)
      .then(res => {
        dispatch({
          type: GET_INVENTORY_CONTENT,
          inventoryContent: res,
          page: args.page,
        })
      })
      .catch(e => console.log('No Inventory Content Returned'))
  }
}
function updateActiveFiltersLocal(args) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_ACTIVE_FILTERS,
      activeFilters: args.filters,
    })
  }
}
function updateBasket(vehicle) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_BASKET,
      vehicle,
    })
  }
}
function emptyBasket() {
  return function(dispatch) {
    dispatch({
      type: EMPTY_BASKET,
    })
  }
}
function updateSearchQuery(searchQuery) {
  return function(dispatch) {
    dispatch({
      type: SEARCH_INVENTORY,
      searchQuery,
    })
  }
}
function searchInventory(args) {
  return function(dispatch) {
    let { facetFields, activeFacetFields, facetOptions } = args
    facetFields.map((field, i) => (facetFields[i] = []))
    activeFacetFields = []
    const searchResults = {}
    dispatch({
      type: SET_LOADING,
    })
    facetGetInventory({ ...args, facetFields, activeFacetFields }).then(res => {
      dispatch({
        type: GET_INVENTORY_CONTENT,
        inventoryContent: res,
        page: args.page,
      })
    })
    dispatch({
      type: SET_SEARCH_SUBMISSION,
      searchQuery: args.searchQuery,
    })
    dispatch({
      type: CLEAR_FACET_OPTIONS,
    })
    const url = `${DjangoNewFilterOptions}?user_id=${args.user_id}&search=${args.searchQuery}`
    let headers = {
      Authorization: `Token ${args.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${url}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        const facetFields = []
        res.facets.map((facet, facetIndex) => {
          facetFields.push([])
        })
        dispatch({
          type: GET_FACET_OPTIONS,
          facetOptions: res.facets,
          viewMyInventory: res.view_my_inventory,
          activeFacetOptions: cloneDeep(res.facets),
          facetFields,
        })
      })
      .catch(e => {
        console.log(e)
        console.log('Filter Options Endpoint Failed')
      })
  }
}
function setActiveSingleSend(vehicle) {
  return function(dispatch) {
    dispatch({
      type: ACTIVE_SINGLE_SEND,
      vehicle,
    })
  }
}
function getFacetOptions(args) {
  return function(dispatch) {
    let url = `${DjangoNewFilterOptions}?user_id=${args.user_id}`
    if (args.searchQuery) {
      url += `&search=${args.searchQuery}`
    }
    let headers = {
      Authorization: `Token ${args.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${url}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        const facetFields = []
        res.facets.map((facet, facetIndex) => {
          facetFields.push([])
        })
        dispatch({
          type: GET_FACET_OPTIONS,
          facetOptions: res.facets,
          viewMyInventory: res.view_my_inventory,
          activeFacetOptions: cloneDeep(res.facets),
          facetFields,
        })
      })
      .catch(e => {
        console.log(e)
        console.log('Filter Options Endpoint Failed')
      })
  }
}
const debounceFacetGetInventory = debounce((args, dispatch) => {
  // this whole binding wariables thing was the absolute last solution I expected
  // Async debounce is a pain in the @ss
  facetGetInventory(args).then(res => {
    dispatch({
      type: GET_INVENTORY_CONTENT,
      inventoryContent: res,
      page: args.page,
    })
  })
}, 1500)
function updateFacet(args) {
  const { facetIndex, optionIndex, facetFields, activeFacetFields, facetOptions } = args
  return function(dispatch) {
    dispatch({
      type: SET_LOADING,
    })
    dispatch({
      type: UPDATE_PAGE,
      page: args.page,
    })
    // This cloneDeep is VERY important, javascript is very very very good at understanding when to make references
    // Annoyingly Good
    const activeFacetOptions = cloneDeep(facetOptions)
    const foundIndex = facetFields[facetIndex].indexOf(optionIndex)
    if (foundIndex > -1) {
      facetFields[facetIndex].splice(foundIndex, 1)
      if (facetFields[facetIndex].length === 0) {
        activeFacetFields.splice(activeFacetFields.indexOf(facetIndex), 1)
      }
    } else {
      facetFields[facetIndex].push(optionIndex)
      if (!activeFacetFields.includes(facetIndex)) {
        activeFacetFields.push(facetIndex)
      }
    }
    activeFacetFields.map(activeField => {
      let fieldUnion = new Set()
      facetFields[activeField].map((selected, fieldIndex) => {
        fieldUnion = new Set([
          ...fieldUnion,
          ...facetOptions[activeField].facet_options[selected].values,
        ])
      })

      activeFacetOptions.map((facet, fIndex) => {
        facet.facet_options.map((option, oIndex) => {
          if (activeField !== fIndex) {
            const intersect = []
            let i
            for (i = 0; i < option.values.length; i++) {
              if (fieldUnion.has(option.values[i])) {
                intersect.push(option.values[i])
              }
            }
            activeFacetOptions[fIndex].facet_options[oIndex].values = intersect
          }
        })
      })
    })

    dispatch({
      type: UPDATE_FACET_OPTIONS,
      facetFields,
      activeFacetFields,
      activeFacetOptions,
    })
    debounceFacetGetInventory.call({}, args, dispatch)
  }
}
function facetGetInventory(args) {
  const { facetOptions, facetFields, searchQuery } = args
  let url = `${DjangoInventoryContentFilteredGetAPIURL}?`
  facetFields &&
    facetFields.map((selected, fieldIndex) => {
      selected.map(optionIndex => {
        facetOptions[fieldIndex].facet_options[optionIndex].keys.map(key => {
          url += `${facetOptions[fieldIndex].facet_api}=${encodeURIComponent(key)}&`
        })
      })
    })
  url += `user_id=${args.user_id}&page=${args.page}`
  url += `&sort_by=${args.sortBy}`
  if (searchQuery) {
    url += `&search=${encodeURIComponent(searchQuery)}`
  }
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${url}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
function facetUpdatePage(args) {
  return function(dispatch) {
    dispatch({
      type: SET_LOADING,
    })
    dispatch({
      type: UPDATE_PAGE,
      page: args.page,
    })
    debounceFacetGetInventory.call({}, args, dispatch)
  }
}
function facetClearAll(args) {
  let { facetFields, activeFacetFields, facetOptions } = args
  facetFields.map((field, i) => (facetFields[i] = []))
  activeFacetFields = []
  return function(dispatch) {
    dispatch({
      type: SET_LOADING,
    })
    dispatch({
      type: UPDATE_PAGE,
      page: args.page,
    })
    dispatch({
      type: UPDATE_FACET_OPTIONS,
      facetFields,
      activeFacetFields,
      activeFacetOptions: facetOptions,
    })
    debounceFacetGetInventory.call({}, { ...args, facetFields, activeFacetFields }, dispatch)
  }
}
function updateSortBy(args) {
  const { sortBy } = args
  return function(dispatch) {
    dispatch({
      type: SET_LOADING,
    })
    dispatch({
      type: UPDATE_SORT_BY,
      sortBy,
    })
    dispatch({
      type: UPDATE_PAGE,
      page: args.page,
    })
    debounceFacetGetInventory.call({}, args, dispatch)
  }
}
function getPacketActivity(args) {
  const { token, activityType, vehicleId } = args
  console.log(args)
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoPacketActivityAPIURL}${vehicleId}/${activityType}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
function clearFacetOptions() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_FACET_OPTIONS,
    })
  }
}
function getArchivedPackets(args) {
  const { token, signal, searchQuery, selectedStores, page } = args
  let url = `${DjangoInventoryContentFilteredGetAPIURL}?archived=true`
  if (searchQuery) {
    url += `&search=${encodeURIComponent(searchQuery)}`
  }
  if (selectedStores && selectedStores.length) {
    selectedStores.map(storeId => {
      url += `&company_store_id=${storeId}`
    })
  }
  if (page && !searchQuery.length) {
    url += `&page=${page}`
  }
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${url}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export {
  getFilterOptions,
  updatePage,
  updateActiveFilters,
  updateActiveFiltersLocal,
  updateBasket,
  emptyBasket,
  searchInventory,
  setActiveSingleSend,
  getFacetOptions,
  updateFacet,
  facetUpdatePage,
  facetClearAll,
  updateSortBy,
  updateSearchQuery,
  getPacketActivity,
  clearFacetOptions,
  getArchivedPackets,
}
