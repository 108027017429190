import React from 'react'
import * as clipboard from 'clipboard-polyfill'
import { toast } from 'react-toastify'

export default function WebLinkDisplay(props) {
  const { vdpBaseURL, customerShares, share_uuids, webLinkNames } = props
  const baseUrl = vdpBaseURL ? `${vdpBaseURL}/` : 'https://www.ipacket.info/'
  let allPlaintextLinks = ''
  let allHtmlLinks = ''
  if (customerShares) {
    customerShares.forEach(customer => {
      customer.share_uuids.forEach(item => {
        allPlaintextLinks += `${baseUrl}${item}\n`
        allHtmlLinks += `<a href="${baseUrl}${item}">${baseUrl}${item}</a><br />`
      })
    })
  }

  if (customerShares) {
    return (
      <div className="weblink-display">
        <h3>Packet Link Generated</h3>
        <hr />
        Click "Copy Link" to save this code to your clipboard, then share this direct link however
        you like.
        <br />
        <br />
        {webLinkNames.map(webLinkName => (
          <div>
            <b>
              {webLinkName.first_name} {webLinkName.last_name}
            </b>
            {webLinkName.shares.map(vehicle => (
              <div className="weblink-display-item">
                <div className="weblink-display-item-col1">
                  <b>
                    {vehicle.year} {vehicle.make} {vehicle.model}
                    <br />
                    {vehicle.vin}
                  </b>
                  <br />
                  <div className="weblink-display-link">{`${baseUrl}${vehicle.shares}`}</div>
                </div>
                <div className="weblink-display-item-col2">
                  <div
                    className="weblink-display-copy"
                    onClick={() => {
                      const dt = new clipboard.DT()
                      dt.setData('text/plain', `${baseUrl}${vehicle.shares}`)
                      dt.setData(
                        'text/html',
                        `<a href="${baseUrl}${vehicle.shares}">${baseUrl}${vehicle.shares}</a>`,
                      )
                      clipboard.write(dt).then(() => {
                        toast('Link Copied!')
                      })
                    }}
                  >
                    <span>Copy Link</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        {customerShares.length > 1 || customerShares[0].share_uuids.length > 1 ? (
          <button
            className="weblink-copy-all"
            onClick={() => {
              const allDT = new clipboard.DT()
              allDT.setData('text/plain', allPlaintextLinks)
              allDT.setData('text/html', allHtmlLinks)
              clipboard.write(allDT).then(() => {
                toast('All Links Copied!')
              })
            }}
          >
            Copy All Links
          </button>
        ) : null}
        <br />
        Close this window once your have successfully shared the link.
        <br />
      </div>
    )
  }
  // uncertain how this code is used
  return (
    <div className="weblink-display">
      Click "Copy Link" to save this code to your clipboard, then share this direct link however you
      like.
      {share_uuids.map(item => (
        <div className="weblink-display-item" key={item}>
          <div className="weblink-display-link">
            <span>{`${baseUrl}${item}`}</span>
          </div>
          <div
            className="weblink-display-copy"
            onClick={() => {
              clipboard.writeText(`${baseUrl}${item}`).then(() => {
                toast('Link Copied!')
              })
            }}
          >
            <span>Copy Link</span>
          </div>
        </div>
      ))}
      Close this window once your have successfully shared the link.
      <br />
    </div>
  )
}
