// packages
import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
// components
import UnsubscribeForm from './components/unsubscribeform'
import PostSubmit from './components/postSubmit'
// styles
import './styles/unsubscribe.scss'

function Unsubscribe(props) {
  const jwtString = props.match.params.jwt_string
  const { selectedCompanies } = props
  const [success, setSuccess] = useState(false)

  const handleSuccess = useCallback(() => {
    if (success) {
      return
    }
    setSuccess(true)
  }, [])

  return (
    <div className="unsubscribe-main-content">
      {success ? (
        <div className="post-form-submission">
          <PostSubmit companies={selectedCompanies} />
        </div>
      ) : (
        <div className="unsubscribe-form">
          <UnsubscribeForm jwtString={jwtString} handleSuccess={handleSuccess} />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  selectedCompanies: state.unsubscribe.selectedCompanies,
})

export default connect(mapStateToProps, null)(Unsubscribe)
