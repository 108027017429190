// TODO
// 1. If no filters selected, hide the clear all button?
// 2. Change styling of PO/CPO text beore title of car.
// 3. Change <a> to <button>? (Price history/MSRP/pagination/go butt)
// 4. Custom select dropdown for Sort by:
// 5. Space left when MSRP isn't available.
// 6. Make MultiSend Cart have yellow packet total.
// 7. Days in inventory needs added.
// 9. Icons for  1. cart   2. send   3. msrp/sticker   4. search
// 10. Bounce between show/hide on MultiSend Cart.
// 11. Pagination doesn't exactly match design
// 12. How do you send Multi-Cart Packets?
// 13. Making $ smaller on price display.
// 14. Located at text in car description needs hooked up.

// @flow
import React, { PureComponent } from 'react'
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
import FacetMenu from './components/facetmenu'
import CarDisplay from './components/cardisplay'
import Basket from './components/basket'
import ShowLocation from './components/showlocation'
import SortBy from './components/sortby'
import Paginator from './components/paginator'
import FilterClears from './components/filterclears'
import PageInfo from './components/pageinfo'
import PrivacySlide from '../helpers/privacyslide'
import { connect } from 'react-redux'
import { getFilterOptions, updateActiveFilters } from '../../core/actions/inventoryactions.js'
import './styles/inventory.scss'
import '../styles/animate.css'

type Props = {} // none.

class InventoryComponent extends PureComponent {
  props: Props
  constructor(props: Props) {
    super(props)
  }
  componentDidMount() {
    if (this.props.location.state) {
      const item = document.querySelector('.car-container-' + this.props.location.state)
      if (item) {
        item.scrollIntoView()
      }
    }
  }

  render() {
    return (
      <div style={{ animation: ` 1500ms fadeIn` }}>
        <div className="inventory-page">
          <div className="sidebar">
            <Basket />
            <ShowLocation />
            {/*<RefineMenu />*/}
            <FacetMenu />
          </div>

          <div className="main">
            <PageInfo />
            <div className="page-controls">
              <PrivacySlide />
              <FilterClears />
              <Paginator />
              <SortBy />
            </div>

            <CarDisplay />

            <div className="page-controls page-controls-bottom">
              <Paginator />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const Inventory = UserInfoWrapper(InventoryComponent)

export default connect(null, {
  updateActiveFilters,
  getFilterOptions,
})(Inventory)
