import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js'

import PropTypes from 'prop-types'

function AuditGraph(props) {
  const chartRef = useRef(null)

  useEffect(() => {
    if (chartRef.current) {
      const chart = new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          // Bring in data
          datasets: [
            {
              data: [props.doHave, props.shouldHave - props.doHave],
              backgroundColor: ['#337dce', '#ccc'],
            },
          ],
          labels: ['Do have', "Don't have"],
        },
        options: {
          legend: {
            display: false,
          },
          rotation: Math.PI,
          circumference: Math.PI,
        },
      })
    }
  })

  return (
    <div className="audit-doughnut">
      <h4>{props.category}</h4>
      <h3>{props.coverage}</h3>
      <div className="canvas-container">
        <canvas ref={chartRef} />
      </div>
      <p className="audit-info">{props.children}</p>
    </div>
  )
}
AuditGraph.propTypes = {
  doHave: PropTypes.number.isRequired,
  shouldHave: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  coverage: PropTypes.string.isRequired,
  children: PropTypes.any,
}
export default AuditGraph
