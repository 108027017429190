// general: 1/6 of the customer component - child of customer dash - (4th).

// TODO:
// 1. mobile support
// 2. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import _ from 'lodash'
// core files
import PacketActions from '../../../core/actions/packets/packetactions'
// helpers
import Helper from '../../../core/helpers/platformhelper'
// redux
import { showModal, hideModal } from '../../../core/_actions/modalActions'
// images
import Paginator from '../../helpers/paginator'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/customers.scss'

type Props = {
  DJtoken: string, // token to send to get the data.
  customerId: number, // sent for the customer's data.
}

export class CustomersAlertsComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '', // email default blank sting.
      firstname: '', // firstname default blank sting.
      lastname: '', // lastname default blank sting.
      phone: '', // phone default blank sting.
      mobile: '', // mobile default blank sting.
      packetsSentArr: [],
      hasError: false,
      errorMessage: '',
      page: 1,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountLoadPacketsSent()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    this.setState({
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      mobile: '',
      loading: true,
      loadingText: 'Loading...',
      packetsSentArr: [],
      hasError: false,
      errorMessage: '',
    })
  }

  handleChange(event) {
    const { packetsSentArr } = this.state
    const sentPacketIndex = _.findIndex(packetsSentArr, { share_uuid: event.target.name })
    const arrayToCopy = this.state.packetsSentArr
    arrayToCopy[sentPacketIndex].isSelected = event.target.checked
    this.setState({
      packetsSentArr: arrayToCopy,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountLoadPacketsSent
  onMountLoadPacketsSent() {
    // props
    const { customerId, DJtoken } = this.props
    let { page } = this.state

    if (customerId !== null && customerId !== undefined) {
      // ***********************LETS*CALL*API***********************
      // CustomerHistoryGetAPIData
      // sends manipulated states to the API as the correct request params to get the
      // returned customer data
      this.CustomerHistoryGetAPIData(page + '')
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  CustomerHistoryGetAPIData(pageDirection: string) {
    // on entry set the loading states
    this.setState({ loading: true, loadingText: 'Loading...' })

    // props
    const { DJtoken, customerId } = this.props

    PacketActions.getCustomerHistoryPackets(customerId, pageDirection, DJtoken)
      .then(json => {
        if (json.results !== undefined && json.results && json.results.length > 0) {
          this.CustomerPrepAlertDisable(json, pageDirection)
        } else {
          this.setState({ count: 0, loading: false, loadingText: 'No results found.' })
        }
      })
      .catch(error => {
        this.CustomerPrepAlertDisableFailed()
        console.log(error.toString())
      })
  }

  CustomerPrepAlertDisable(json: Object, pageDirection: string) {
    // add selected = false to each received entry
    json.results.forEach(function(item) {
      item.isSelected = false
      item.isLoading = false
      item.hasErrored = false
    })
    this.setState({
      packetsSentArr: json.results,
      pagePrevious: json.previous !== null ? json.previous.split('query/packets/')[1] : null,
      pageNext: json.next !== null ? json.next.split('query/packets/')[1] : null,
      pageNum: pageDirection === '1' ? 0 : this.state.pageNum,
      count: json.count,
      loading: false,
    })
  }

  // CustomerPrepAlertDisableFailed
  CustomerPrepAlertDisableFailed() {
    this.setState({
      count: 0,
      loading: true,
      loadingText: 'There was an error loading.',
    })
  }

  // Used to more cleanly change the state of nested state objects
  // can accept single value or paired arrays [attr1, attr2] -> [val1, val2]
  modifySingleSentPacketState(share_uuid, attrs, values) {
    const { packetsSentArr } = this.state
    const sentPacketIndex = _.findIndex(packetsSentArr, { share_uuid: share_uuid })
    const arrayToCopy = packetsSentArr
    if (Array.isArray(attrs) && attrs.length > 1) {
      attrs.forEach(function(item, index) {
        arrayToCopy[sentPacketIndex][attrs[index]] = values[index]
      })
    } else {
      arrayToCopy[sentPacketIndex][attrs] = values
    }
    this.setState({
      packetsSentArr: arrayToCopy,
    })
  }

  disableSelectedAlerts() {
    const { packetsSentArr } = this.state
    const { customerId, DJtoken } = this.props
    packetsSentArr.forEach(item => {
      if (item.isSelected) {
        this.modifySingleSentPacketState(item.share_uuid, 'isLoading', true)

        PacketActions.postUpdateCustomerPacketAlert(
          customerId,
          item.packet_history.vehicle_id,
          true,
          item.share_uuid,
          DJtoken,
        )
          .then(json => {
            console.log('Success for a packet disable!')
            if (json.detail != undefined && json.detail != null) {
              this.modifySingleSentPacketState(
                item.share_uuid,
                ['isSelected', 'isLoading', 'hasErrored'],
                [false, false, true],
              )
              this.setState({ hasError: true, errorMessage: json.detail })
            } else if (json.disable_pc_alerts != undefined && json.disable_pc_alerts != null) {
              this.modifySingleSentPacketState(
                item.share_uuid,
                ['isSelected', 'isLoading', 'disable_pc_alerts'],
                [false, false, json.disable_pc_alerts],
              )
            }
          })
          .catch(error => {
            console.log(error.toString())
          })
      }
    })
  }

  selectAllInputs() {
    const { packetsSentArr } = this.state
    const arrayToCopy = packetsSentArr
    arrayToCopy.forEach(item => {
      item.isSelected = true
    })
    console.log(packetsSentArr)
    console.log(arrayToCopy)
    this.setState({
      packetsSentArr: arrayToCopy,
    })
  }

  // BEN: feel free to change how these display as much as you like
  renderPacketsSent() {
    const { loading, loadingText, packetsSentArr, hasError, errorMessage } = this.state
    if (loading) {
      return <h2>Loading...</h2>
    } else {
      if (packetsSentArr.length == 0) {
        return <div>No packets have been sent to this user.</div>
      } else {
        return (
          <div id="customer-scroll-box">
            {hasError ? <h3 className="customer-alerts-message">{errorMessage}</h3> : null}
            <button className="customer-selectall-button" onClick={() => this.selectAllInputs()}>
              Select All
            </button>
            <button className="customer-edit-button" onClick={() => this.disableSelectedAlerts()}>
              Disable
            </button>
            <form onSubmit={this.handleSubmit}>
              {packetsSentArr.map((packetSent, i) => (
                <div key={i} className="customer-disable-alert-entry">
                  {packetSent.hasErrored ? (
                    <div className="error">
                      <i>Unable to disable alert.</i>
                    </div>
                  ) : null}
                  <label>
                    {packetSent.isLoading ? (
                      <div className="alertLoader" />
                    ) : (
                      <div className="customer-checkbox">
                        <input
                          type="checkbox"
                          align="center"
                          key={i}
                          listid={i}
                          name={packetSent.share_uuid}
                          checked={packetSent.isSelected}
                          onChange={this.handleChange}
                        />
                        <span className="customer-checkmark" />
                      </div>
                    )}
                    <div className="customer-carinfo">
                      <b>Stock:</b>&nbsp;
                      {`${packetSent.vehicle_id.stock}`}
                      <br />
                      <b>Vehicle:</b>&nbsp;
                      {`${packetSent.vehicle_id.year} ${packetSent.vehicle_id.make} ${packetSent.vehicle_id.model}`}
                      <br />
                      <b>Sent:</b>&nbsp;
                      {`${Helper.dtFormat(
                        Helper.localizeTime(packetSent.created_dt),
                        'M/d/yyyy h:mm b',
                      )}`}
                      <br />
                      <b>Alerts:</b>&nbsp;
                      <span style={{ fontSize: '15px' }}>
                        {packetSent.disable_pc_alerts ? (
                          <span style={{ color: '#F83030' }}>'DISABLED'</span>
                        ) : (
                          <span style={{ color: '#3CB54A' }}>'ENABLED'</span>
                        )}
                      </span>
                    </div>
                  </label>
                  <br />
                </div>
              ))}
            </form>
          </div>
        )
      }
    }
  }

  props: Props

  render() {
    // states
    const { hasError, errorMessage } = this.state

    return (
      <div align="center" className="customersViewBox">
        <h2>Disable Price Change Alerts</h2>
        <hr style={{ width: '80%' }} />
        <Paginator
          page={this.state.page}
          updatePage={page => {
            this.setState({ page }, newState => {
              this.CustomerHistoryGetAPIData(this.state.page + '')
            })
          }}
          numPages={Math.ceil(this.state.count / 15)}
        />
        {this.renderPacketsSent()}
        <Paginator
          page={this.state.page}
          updatePage={page => {
            this.setState({ page }, newState => {
              this.CustomerHistoryGetAPIData(this.state.page + '')
            })
          }}
          numPages={Math.ceil(this.state.count / 15)}
        />
      </div>
    )
  }
}

// ***********************LETS*EXPORT*CustomerView***********************

const CustomersAlerts = CustomersAlertsComponent
export default connect(null, {
  hideModal,
  showModal,
})(CustomersAlerts)
