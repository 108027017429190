// general: 1/6 of the admin component - child of adminsettings - (0.5).

// @flow
import React, { Component } from 'react'
// node packages
import ls from 'local-storage'
// --- redux
import { connect } from 'react-redux'
import { stashAdminCompanyStore } from '../../core/_actions/packetstashActions'

// core files
// --- actions
import ProfileActions from '../../core/actions/users/profileactions'
// css styles
import '../styles/universalstyles.scss'
import '../admin_settings/styles/adminsettings.scss'
import DealershipIcon from '../../core/assets/images/icons/dealership-icon'

type Props = {
  DJtoken: string, // token to send to get the data.
  storeId: number, // sent for the store's data.
  userId: number,
  stashAdminCompanyStore: Function,
}

class StoreChange extends Component {
  constructor(props) {
    super(props)

    this.state = {
      storeList: [],
      storeId: this.props.storeId,
      dropdownValueStore: '',
      message: '',
      success: true,
    }

    this._onChange = this.onChange.bind(this)
  }

  state: {
    storeList: Object,
    storeId: number,
    dropdownValueStore: string,
    message: string,
    success: boolean,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    const { storeId } = this.props
    console.log(this.props)
    this.mounted = true
    if (this.mounted) {
      this.onMountGetStoreList()
      this.props.stashAdminCompanyStore(this.props.storeId)
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({
      storeList: [],
      storeId: null,
      dropdownValueStore: '',
      message: '',
      success: true,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetStoreList
  onMountGetStoreList() {
    const { storeId } = this.props
    if (this.props.userId !== null && this.props.userId !== undefined) {
      ProfileActions.getUserStoreGroups(this.props.userId, this.props.DJtoken).then(json => {
        if (json && json.length > 0) {
          let storeName = ''
          json.forEach(store => {
            if (store.company_store_id === storeId) {
              storeName = store.company_store_name
            }
          })
          this.setState({ storeList: json, dropdownValueStore: `${storeName}---${storeId}` })
        }
      })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onGetStoreInfo
  onGetStoreInfo(storeId: number, token: string) {
    ProfileActions.getUserDefaultStoreInfo(storeId, token).then(json => {
      if (json.company_store_id !== null && json.company_store_id !== undefined) {
        const userObjData = ls.get('userInfo')
        userObjData.timestamp = new Date().getTime() // timestamp
        userObjData.default_store_id_temp = json.company_store_id // default_store_id
        userObjData.store_full_name_temp = json.store_full_name // store_full_name
        ls.set('userInfo', userObjData) // WRITEuserInfo
        this.props.stashAdminCompanyStore(json.company_store_id)
      }
    })
  }

  // onChange
  onChange(event: Object) {
    const { DJtoken } = this.props
    const target = event.target.value
    this.setState({ dropdownValueStore: target, storeId: target.split('---')[1] })
    this.onGetStoreInfo(target.split('---')[1], DJtoken)
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // DropdownStoreContainer
  DropdownStoreContainer() {
    // states
    const { storeId, storeList, dropdownValueStore } = this.state

    // arrays
    const storeListArray = []

    // counters
    let counter3 = 0

    if (storeList && storeList.length > 0) {
      for (const entry of storeList) {
        storeListArray.push(
          <option
            key={counter3++}
            value={`${entry.company_store_name}---${entry.company_store_id}`}
          >
            {`${entry.company_store_name} (${entry.store_type === 1 ? 'New' : 'Pre-Owned'})`}
          </option>,
        )
      }
    }

    return (
      <div className="loc-select-box admin-page" align="center">
        <div className="loc-select-header">
          <DealershipIcon />
          <span className="box-label">Selected Location:</span>
        </div>
        {storeListArray && storeListArray.length > 0 ? (
          <select value={dropdownValueStore} onChange={this._onChange}>
            {storeListArray}
          </select>
        ) : null}
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _onChange: Function // function

  render() {
    const { success, message } = this.state
    return (
      <div>
        {this.DropdownStoreContainer()}
        <span>{!success ? message : null}</span>
      </div>
    )
  }
}

export default connect(null, { stashAdminCompanyStore })(StoreChange)
