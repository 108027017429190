import React from 'react'

const IconHelp = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <circle className="a" cx="25" cy="25" r="22.5" />
      <path
        className="b"
        stroke="none"
        d="M35.33,17.62A7.47,7.47,0,0,1,35,20a7.22,7.22,0,0,1-1,1.9,9.35,9.35,0,0,1-1.49,1.62c-.58.51-1.24,1-2,1.58-.45.36-.83.67-1.13.95a3.55,3.55,0,0,0-.7.84,3.09,3.09,0,0,0-.36.91,5.2,5.2,0,0,0-.11,1.13V30H21.65V28.43a7.89,7.89,0,0,1,.22-1.91A5.67,5.67,0,0,1,22.5,25a8.06,8.06,0,0,1,1-1.36A14,14,0,0,1,25,22.27L26.4,21a7.72,7.72,0,0,0,1.22-1.29,2.66,2.66,0,0,0,.51-1.63,2.75,2.75,0,0,0-.82-2.1A3.18,3.18,0,0,0,25,15.19a3.13,3.13,0,0,0-2.66,1.17,4.76,4.76,0,0,0-1,2.66l-6.73-.7a10.72,10.72,0,0,1,1.16-3.91,8.75,8.75,0,0,1,2.35-2.79A10.12,10.12,0,0,1,21.45,10a13.15,13.15,0,0,1,3.86-.55A14.48,14.48,0,0,1,29,9.87a9.49,9.49,0,0,1,3.2,1.48,7.44,7.44,0,0,1,2.25,2.57A7.58,7.58,0,0,1,35.33,17.62Z"
      />
      <path
        className="b"
        stroke="none"
        d="M29.11,36.58a3.86,3.86,0,0,1-1.19,2.86A4,4,0,0,1,25,40.6a4.2,4.2,0,0,1-1.59-.29,4.05,4.05,0,0,1-1.31-.85,4.11,4.11,0,0,1-.91-1.27,3.57,3.57,0,0,1-.34-1.57,3.9,3.9,0,0,1,.32-1.54,3.77,3.77,0,0,1,.89-1.29A4.24,4.24,0,0,1,25,32.6a4,4,0,0,1,1.59.32,4.41,4.41,0,0,1,1.31.85A3.6,3.6,0,0,1,28.79,35,3.9,3.9,0,0,1,29.11,36.58Z"
      />
    </svg>
  )
}
export default IconHelp
