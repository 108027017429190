import React from 'react'

const TopReports = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path className="a" d="M28.39,5.58h0V22H44.81A16.42,16.42,0,0,0,28.39,5.58Z" />
      <path d="M25,25.26V8.86h0A16.42,16.42,0,1,0,41.41,25.27H25Z" />
    </svg>
  )
}
export default TopReports
