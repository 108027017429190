import {
  GET_INVENTORY_CONTENT,
  GET_FILTER_OPTIONS,
  UPDATE_BASKET,
  EMPTY_BASKET,
  FETCH_PACKETS,
  UPDATE_ACTIVE_FILTERS,
  UPDATE_PAGE,
  UPDATE_SORT_BY,
  UPDATE_ACTIVE_PACKETS,
  SET_LOADING,
  SEARCH_INVENTORY,
  SET_SEARCH_SUBMISSION,
  ACTIVE_SINGLE_SEND,
  GET_FACET_OPTIONS,
  UPDATE_FACET_OPTIONS,
  CLEAR_FACET_OPTIONS,
} from '../_constants/inventoryTypes.js'

var hash = require('object-hash')

const initState = {
  inventoryContent: {},
  activeFilters: {
    states: [],
    locations: [],
    cpos: [],
    certifications: [],
    stock_types: [],
    vehicle_types: [],
    makes: [],
    models: [],
    price_max: 0,
    price_min: 0,
    year_max: 2019,
    year_min: 2019,
    miles_max: 0,
    miles_min: 0,
    query: '',
  },
  searchQuery: '',
  correctedSearchQuery: null,
  searchSubmitted: '',
  facetFields: null,
  activeFacetFields: [],
  facetOptions: null,
  activeFacetOptions: null,
  viewMyInventory: null,
  filterOptions: {},
  searchResults: {},
  basket: [],
  page: 1,
  sortBy: '',
  isLoading: false,
  activeSingleSend: {},
  checkIn: {
    GET_FACET_OPTIONS: {
      timestamp: Date.now(),
      timeout: 1000 * 60 * 15,
      hash: null,
    },
  },
}

export function inventory(state, action) {
  if (!state) {
    state = initState
  }
  switch (action.type) {
    case GET_INVENTORY_CONTENT:
      return {
        ...state,
        isLoading: false,
        inventoryContent: action.inventoryContent,
        correctedSearchQuery:
          action.inventoryContent && action.inventoryContent.corrected_search_string
            ? action.inventoryContent.corrected_search_string
            : null,
      }

    case GET_FILTER_OPTIONS:
      const activeFilters = initState.activeFilters
      activeFilters.price_max = action.filterOptions.price_range.price__max
      activeFilters.price_min = action.filterOptions.price_range.price__min
      activeFilters.year_max = action.filterOptions.date_ranges.year__max
      activeFilters.year_min = action.filterOptions.date_ranges.year__min
      activeFilters.miles_max = action.filterOptions.miles_range.miles__max
      activeFilters.miles_min = action.filterOptions.miles_range.miles__min
      return {
        ...state,
        filterOptions: action.filterOptions,
        activeFilters,
      }
    case UPDATE_PAGE:
      return {
        ...state,
        page: action.page,
      }
    case UPDATE_BASKET:
      //I know doing this much in the reducer is a sin but come on it's not that much
      //Otherwise I would have to pass the whole basket array around - Khris
      const newBasket = [...state.basket]
      const ids = newBasket.map(item => {
        return item.vehicle_id
      })
      if (ids.includes(action.vehicle.vehicle_id)) {
        const i = ids.indexOf(action.vehicle.vehicle_id)
        newBasket.splice(i, 1)
      } else {
        newBasket.push(action.vehicle)
      }
      return {
        ...state,
        basket: [...newBasket],
      }
    case EMPTY_BASKET:
      return {
        ...state,
        basket: [],
      }
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...action.activeFilters,
        },
        page: 1,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_INVENTORY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      }
    case SET_SEARCH_SUBMISSION:
      return {
        ...state,
        searchSubmitted: action.searchQuery,
      }
    case ACTIVE_SINGLE_SEND:
      return {
        ...state,
        activeSingleSend: action.vehicle,
      }
    case GET_FACET_OPTIONS:
      let activeFacetFields = []
      let activeFacetOptions = [...action.activeFacetOptions]
      let facetFields = action.facetFields
      let facetOptions = [...action.facetOptions]
      if (hash(action.facetOptions) === hash(state.facetOptions)) {
        console.log('')
        activeFacetFields = state.activeFacetFields
        activeFacetOptions = state.activeFacetOptions
        facetFields = state.facetFields
        facetOptions = state.facetOptions
      }
      return {
        ...state,
        facetFields,
        activeFacetFields,
        facetOptions,
        activeFacetOptions,
        viewMyInventory: action.viewMyInventory,
        checkIn: {
          ...state.checkIn,
          GET_FACET_OPTIONS: {
            ...state.checkIn.GET_FACET_OPTIONS,
            timestamp: Date.now(),
            hash: hash(action.facetOptions),
          },
        },
      }
    case UPDATE_FACET_OPTIONS:
      return {
        ...state,
        facetFields: [...action.facetFields],
        activeFacetFields: [...action.activeFacetFields],
        activeFacetOptions: [...action.activeFacetOptions],
      }
    case CLEAR_FACET_OPTIONS:
      return {
        ...state,
        facetFields: null,
        activeFacetFields: [],
        facetOptions: null,
        activeFacetOptions: null,
        viewMyInventory: null,
      }
    case UPDATE_SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy,
      }
    default:
      return state
  }
}
