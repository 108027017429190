// general:

// @flow
import React, { Component } from 'react'
// --- actions
import PacketActions from '../../../../../core/actions/packets/packetactions'
// --- helpers
import LoadingSpinner from '../../../../helpers/loadingspinner'
// css styles
import '../../../../styles/universalstyles.scss'
import '../../../styles/packetsend.scss'
import '../../../styles/repairorder.scss'

type Props = { repair: Object }

export default class HTMLModuleChild extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      htmlContent: null,
    }
  }

  // componentDidMount
  // - set mount to true.
  componentDidMount() {
    this.mounted = true
    this.onMountGetHTML()
  }

  onMountGetHTML() {
    const { module_url, token } = this.props
    PacketActions.getEmbedHTMLModuleContent(module_url, this.props.token)
      .then(returnedHTML => {
        this.setState({
          htmlContent: returnedHTML,
          loading: false,
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          htmlContent: '<h1>Error loading HTML Module content!</h1>',
          loading: false,
        })
      })
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    //this.setState({ repair: newProps.repair })
    this.onMountGetHTML()
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ htmlContent: null, loading: true })
  }

  render() {
    const { loading, htmlContent } = this.state
    if (loading) {
      return <h1>Loading...</h1>
    }
    if (htmlContent !== null && htmlContent !== undefined) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    }
    return <h1>Loading...</h1>
  }
}
