// general: action file for all things 'admin' data being passed back and forth.

// API(s)
import {
  DJangoAdminOverviewGetAPIURL,
  DJangoAdminOverviewImgGetAPIURL,
  DJangoAdminFranchiseGetAPIURL,
  DJangoAdminFranchisePostAPIURL,
  DJangoAdminHTMLGetAPIURL,
  DJangoAdminTeamMembersGetAPIURL,
  DJangoAdminTeamMemberPutAPIURL,
  DJangoAdminTeamMemberGetAPIURL,
  DJangoAdminCredentialInfoPutAPIURL,
  DJangoAdminActivityRecipientsAPIURL,
  DJangoAdminCustomScheduledReportsListAPIURL,
  DJangoAdminCustomScheduledReportAPIURL,
  DJangoAdminResetPasswordAPIURL,
  DJangoAdminOverViewGetRegionInfoUrl,
  DJangoProfileStoreSingleGetAPIURL,
  DJangoAdminOverViewGetManagerInfoUrl,
  DJangoProfileImageGetAPIURL,
  DJangoAdminOverViewGetStoreHealthInfoUrl,
  DJangoAdminCompanyGetUrl,
  DjangoAdminModulesGetURL,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

export default {
  // ADMIN - API - Reset Password
  // resetPassword: (username: string, token) => {
  //   console.log(username)
  //   const form = new FormData()
  //   form.append('username', username)
  //   form.append('platform', 'web')
  //   let headers = {
  //     Authorization: `bearer ${token}`,
  //     Accept: 'application/json',
  //   }
  //   return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminResetPasswordAPIURL}`, {
  //     method: 'POST',
  //     headers,
  //     body:form
  //   }).then((response)=>{
  //     return response.json()
  //   })
  // },
  // ADMIN - API - OVERVIEW
  getAdminOverview: (storeId: number, token: string) => {
    // v1/store/:store_id/overview
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminOverviewGetAPIURL}${storeId}/overview`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  // ADMIN - LOGO PIC CHANGE

  PutLogoImage: (storeId: number, token: string, file: Object) => {
    // v1/store/:store_id/overview/logo
    console.log(`file: ${JSON.stringify(file)}`)
    const parameters = new FormData() // eslint-disable-line no-undef
    parameters.append('file', file)
    console.log(`file: ${JSON.stringify(parameters.file)}`)

    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
    }
    return AIP_DJAPI(`${DJangoAdminOverviewImgGetAPIURL}${storeId}/logo`, {
      body: parameters,
      method: 'PUT',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getAdminOverviewImg: (storeId: number, token: string) => {
    // v1/store/:store_id/overview/logo
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminOverviewImgGetAPIURL}${storeId}/overview/logo`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getAdminOverviewRegionInfo: (storeId: number, token: string) => {
    // v1/store/region/?store_id=4
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminOverViewGetRegionInfoUrl}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getStoreDetail: (storeId: number, token: string) => {
    // v1/store/region/?store_id=4
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileStoreSingleGetAPIURL}${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getStoreHealth: (storeId: number, token: string, startDate: Object, endDate: Object) => {
    console.log(startDate)
    console.log(endDate)
    if (startDate && endDate) {
      let headers = {
        Authorization: `bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
      return AIP_DJAPI(
        `${AIP_DJAPI_URLS.DJangoAdminOverViewGetStoreHealthInfoUrl}${storeId}/health-v2/?from_date=${startDate}&to_date=${endDate}`,
        {
          method: 'GET',
          headers,
        },
      ).then(response => {
        return response.json()
      })
    }
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoAdminOverViewGetStoreHealthInfoUrl}${storeId}/health-v2/`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },
  //
  //
  //
  // ADMIN - API - FRANCHISE
  getAdminFranchiseList: (storeId: number, token: string) => {
    // v1/store/:store_id/franchise
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminFranchiseGetAPIURL}${storeId}/franchise`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getAdminFranchiseSearch: (search: string, token: string) => {
    // v1/store/franchise?search=term
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminFranchiseGetAPIURL}franchise?search=${search}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  postAdminFranchise: (storeId: number, makeID: number, token: string) => {
    // v1/store/:store_id/franchise

    const params = { make_id: makeID }
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminFranchisePostAPIURL}${storeId}/franchise`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  // Note: not currently being used, but fully setup.
  getAdminFranchiseWID: (storeId: number, franchiseId: number, token: string) => {
    // v1/store/:store_id/franchise/:franchise_id
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoAdminFranchiseGetAPIURL}${storeId}/franchise/${franchiseId}`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  deleteAdminFranchise: (storeId: number, franchiseId: number, token: string) => {
    // v1/store/:store_id/franchise/:franchise_id
    let headers = {
      Authorization: `bearer ${token}`,
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoAdminFranchiseGetAPIURL}${storeId}/franchise/${franchiseId}`,
      {
        method: 'DELETE',
        headers,
      },
    )
  },

  //
  //
  //
  // ADMIN - API - TEAM MEMBERS
  getAdminMembersList: (numORurl: string, token: string, search: string, storeId: number) => {
    // v1/store/{store_id}/team-members/&search={auto_fill_search_value}&page={page}

    let pathParameters = `${storeId}/team-members/?page=${numORurl}`

    if (search !== null && search !== undefined && search !== '') {
      pathParameters += `&search=${search}`
    }

    if (numORurl !== '1') {
      pathParameters = numORurl
    }

    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminTeamMembersGetAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  exportAdminMembersList: async (token: string, storeId: number) => {
    //
    let pathParameters = `${storeId}/team-members/?export=true`
    return fetch(`${DJangoAdminTeamMembersGetAPIURL}${pathParameters}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
  },
  getAdminMember: (legacyUserId: number, token: string) => {
    // v1/users/legacy-user-detail/{legacy_user_id}
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminTeamMemberGetAPIURL}${legacyUserId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  postAdminMember: (legacyUserId: number, params: Object, token: string) => {
    // v1/users/legacy-user-detail/{legacy_user_id}
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminTeamMemberGetAPIURL}${legacyUserId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  putAdminMember: (params: Object, token: string) => {
    // v1/users/legacy-user/
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminTeamMemberPutAPIURL}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  deleteAdminMember: (legacyUserId: number, token: string) => {
    // v1/users/legacy-user-detail/{legacy_user_id}

    let headers = {
      Authorization: `bearer ${token}`,
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminTeamMemberGetAPIURL}${legacyUserId}`, {
      method: 'DELETE',
      headers,
    })
  },

  //
  //
  //
  // ADMIN - API - CREDENTIAL INFO
  getAdminCredentialInfo: (serviceName: string, companyStore: string, token: string) => {
    // v1/data-ingest/dealer-credential/{service_name}/{company_store}
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoAdminCredentialInfoPutAPIURL}${serviceName}/${companyStore}`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  putAdminCredentialInfo: (
    serviceName: string,
    companyStore: string,
    username: string,
    password: string,
    active: number,
    dealerContact: string,
    token: string,
  ) => {
    // v1/data-ingest/dealer-credential/{service_name}/{company_store}

    const params = {
      company_store: companyStore,
      username,
      password,
      active,
      dealer_contact: dealerContact,
    }
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoAdminCredentialInfoPutAPIURL}${serviceName}/${companyStore}`,
      {
        method: 'PUT',
        headers,
        body: JSON.stringify(params),
      },
    ).then(response => {
      return response.json()
    })
  },
  getActivityReportRecipients: (storeId: number, token: string) => {
    // v1/store/:store_id/overview
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminActivityRecipientsAPIURL}?store_id=${storeId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getCustomScheduledReports: (token: string) => {
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminCustomScheduledReportsListAPIURL}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  createCustomScheduledReport: (
    token: string,
    store_id: Number,
    subject: String,
    dayValToSubmit: String,
    message: string,
    weekdayToSubmit: string,
  ) => {
    const params = {
      built_report_id: 1,
      scheduled_cron_task: {
        minute: '*',
        hour: '*',
        day_of_month: dayValToSubmit,
        month: '*',
        day_of_week: weekdayToSubmit,
      },
      activity_report_config: {
        store_ids: [store_id],
        auto_build_store: true,
        use_default_analytic_config: false,
        monthly: false,
        store_id,
      },
      analytics_config: [
        {
          slice_label: ['Slice 3', 'Slice 4'],
          slice_color: ['#DDDDDD', '#444444'],
          store_id: [store_id],
          share_source_id: [10],
          analytic_label: 'A different label',
          title: 'Another Title',
          description: 'How to describe',
        },
      ],
      internal_label: 'Test Activity Report 2',
      auto_run_analytics: false,
      from_address: 'test@autoipacket.com',
      subject,
      message,
    }
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminCustomScheduledReportAPIURL}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  updateCustomScheduledReport: (
    reportId: Number,
    token: String,
    store_id: Number,
    subject: String,
    dayValToSubmit: String,
    message: string,
    weekdayToSubmit: string,
  ) => {
    const params = {
      id: reportId,
      built_report_id: 1,
      scheduled_cron_task: {
        minute: '*',
        hour: '*',
        day_of_month: dayValToSubmit,
        month: '*',
        day_of_week: weekdayToSubmit,
      },
      activity_report_config: {
        store_ids: [store_id],
        auto_build_store: true,
        use_default_analytic_config: false,
        monthly: false,
        store_id,
      },
      analytics_config: [
        {
          slice_label: ['Slice 3', 'Slice 4'],
          slice_color: ['#DDDDDD', '#444444'],
          store_id: [store_id],
          share_source_id: [10],
          analytic_label: 'A different label',
          title: 'Another Title',
          description: 'How to describe',
        },
      ],
      internal_label: 'Test Activity Report 2',
      auto_run_analytics: false,
      from_address: 'test@autoipacket.com',
      subject,
      message,
    }
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminCustomScheduledReportAPIURL}${reportId}/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  deleteCustomScheduledReport: (reportId: number, token: string) => {
    let headers = {
      Authorization: `bearer ${token}`,
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminCustomScheduledReportAPIURL}${reportId}/`, {
      method: 'DELETE',
      headers,
    })
  },

  getAccountManagerInfo: (storeId: number, token: string) => {
    // v1/store/:store_id/overview
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoAdminOverViewGetManagerInfoUrl}${storeId}/account-manager`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  // modules - GET -> Get list of modules and their info/status for store: modules.js
  getModulesList: (storeId: number, token: string) => {
    // `${DJangoAPIBaseURL}${DJV1}store/`
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAdminModulesGetURL}${storeId}/modules`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  // modules - GET -> Get list of modules and their info/status for store: modules.js
  enableModule: (storeId: number, moduleID: number, token: string) => {
    // `${DJangoAPIBaseURL}${DJV1}store/`
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAdminModulesGetURL}${storeId}/module/${moduleID}/1/`, {
      method: 'POST',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  // modules - GET -> Get list of modules and their info/status for store: modules.js
  disableModule: (storeId: number, moduleID: number, token: string) => {
    // `${DJangoAPIBaseURL}${DJV1}store/`
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAdminModulesGetURL}${storeId}/module/${moduleID}/0/`, {
      method: 'POST',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  getCustomModulesList: (storeId: number, token: string) => {
    // `${DJangoAPIBaseURL}${DJV1}store/`
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAdminModulesGetURL}${storeId}/custom-modules`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  // getProfileImageInfo: (userName: number, token: string) => {
  //
  //   return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileImageGetAPIURL}${userName}`, {
  //     method: 'GET',
  //     headers,
  //   })
  //   .then(response => {
  //     // log response
  //     return response.blob()
  //   })
  //   .then(blobResponse => {
  //     const base64data = {}
  //
  //     // eslint-disable-next-line no-undef
  //     const fileReaderInstance = new FileReader()
  //
  //     fileReaderInstance.readAsDataURL(blobResponse)
  //     fileReaderInstance.onload = () => {
  //       const base64 = fileReaderInstance.result
  //       // if/else accounts for oddly encoded default img response
  //       if (base64.includes('application/json')) {
  //         base64data.file = JSON.parse(atob(base64.split(',')[1])).default_url
  //       } else if (base64.includes('text/plain')) {
  //         throw 'API error from profile-image'
  //       } else {
  //         base64data.file = base64
  //       }
  //     }
  //
  //     return base64data
  //   })
  //   .catch(error => {
  //     console.error(error)
  //   })
  // },

  updateActivityReportRecipients: (receivedID: number, emailString: string, token: string) => {
    // v1/store/:store_id/overview
    const params = {
      email: emailString,
    }
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminActivityRecipientsAPIURL}${receivedID}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  createActivityReportRecipients: (
    receivedID: number,
    storeID,
    emailString: string,
    token: string,
  ) => {
    // v1/store/:store_id/overview
    const params = {
      email: emailString,
      store_ids: storeID,
    }

    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminActivityRecipientsAPIURL}${receivedID}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  getCompanyInfo: (companyID: Number, token: string) => {
    // v1/store/company/
    let headers = {
      Authorization: `bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoAdminCompanyGetUrl}${companyID}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
}
