// general: dashboard sent list modal.

// TODO:
// 0. THIS MODAL IS FULLY SETUP, BUT IS INACTIVE/NOT CALLED TO BE DISPLAYED (01/29/19)
// 1. styling
// 2. mobile support
// 3. cross browser check support
// 4. user simulated using test to find bugs

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// core files
import DashActions from '../../../core/actions/features/dashboardactions'
import { hideModal } from '../../../core/_actions/modalActions'
// components
import Modal from '../../layouts/modal'

type Props = {
  title: string,
  id: number,
  token: string,
  hideModal: boolean,
}

export class DashboardSentModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sent: [],
    }

    this._onClose = this.onClose.bind(this)
  }

  state: {
    sent: Object,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetSentInfo()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    this.setState({ sent: [] })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetSentInfo
  onMountGetSentInfo() {
    // props
    const { id, token } = this.props
    if (id !== null && id !== undefined) {
      DashActions.getDashSentInterval(token)
        .then(json => {
          if (json !== null && json !== undefined) {
            this.setState({ sent: json })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.props.hideModal()
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  props: Props
  _onClose: Function // function

  render() {
    // states
    const { sent } = this.state

    // arrays
    const sentArray = []

    // counters
    let count = 0

    if (sent.interval_breakdown && sent.interval_breakdown.length > 0) {
      for (const sentEntry of sent.interval_breakdown) {
        sentArray.push(<div key={count++}>{sentEntry.viewed}</div>)
      }
    }

    return (
      <Modal>
        <div className="row">
          total sent: {sent.total_sent_packets} - interval break down: {sentArray}
        </div>
      </Modal>
    )
  }
}

// ***********************LETS*EXPORT*SENT*MODAL***********************
function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    id: state.modal.props.id_object.main_id,
    token: state.modal.props.token,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(DashboardSentModal)
