// general:

// @flow
import React, { Component } from 'react'
// node packages
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import format from 'date-fns/format'
// --- redux
import { connect } from 'react-redux'
// core files
// --- actions
import PacketActions from '../../../../core/actions/packets/packetactions'
// --- other
import AIPSeedsData from '../../../../core/data/AIPSeedsData'
// helpers
import { ReactTableOptionsStatic } from '../../../helpers/reusables'
import LoadingSpinner from '../../../helpers/loadingspinner'
import Helper from '../../../../core/helpers/platformhelper'
import runtimeEnv from '../../../../core/config/envConstants'

// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'

// global(s)
// --- variables
let setTimeoutId

type Props = {
  DJtoken: string,
  stashed_content: Object,
}
/**
 * Deprecated!! NOT IN PRODUCTION
 **/
class SendActivity extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      vehicleId: null,

      packetSendViewTypeID: 'launched', // id packetsend type sent to api

      launchedNum: 0,
      viewedNum: 0,
      printedNum: 0,

      entries: [], // packetsend table json.results, if returned results.length > 0
      // ^else, remains empty.
      pagePrevious: null, // packetsend table json.previous
      pageNext: '1', // packetsend table json.next
      count: 0, // packetsend table json.count
      pageNum: 0, // react-table's page number variable that either gets -- or ++ based on
      // ^this.state.count status

      loading: true, // react-table's option's loading value
    }
  }

  state: {
    vehicleId: number,

    packetSendViewTypeID: string,

    launchedNum: number,
    viewedNum: number,
    printedNum: number,

    entries: Object,
    pagePrevious: string,
    pageNext: string,
    count: number,
    pageNum: number,

    loading: boolean,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountSetDataSourceToLoading()
      this.onMountCheckStash()
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false

    // clear global timeout variable
    clearTimeout(setTimeoutId)

    // reset the states on unmount
    this.setState({
      vehicleId: null,

      packetSendViewTypeID: 'launched',

      launchedNum: 0,
      viewedNum: 0,
      printedNum: 0,

      entries: [],
      pagePrevious: null,
      pageNext: '1',
      count: 0,
      pageNum: 0,

      loading: true,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountSetDataSourceToLoading
  // - sets dummy data onmount into the table for row init.
  // - called once onmount
  onMountSetDataSourceToLoading() {
    const entries = AIPSeedsData.packetsend_entries
    this.setState({ entries })
  }

  // onMountCheckStash
  onMountCheckStash() {
    const stash = this.props.stashed_content
    if (stash.vehicle_info !== null && stash.vehicle_info !== undefined) {
      this.onMountGetCountNum(stash.vehicle_info.vehicle_id)
      this.ActivityGetAPIData('launched', '1', stash.vehicle_info.vehicle_id)
      this.setState({ vehicleId: stash.vehicle_info.vehicle_id })
    }
  }

  // onMountGetCountNum
  onMountGetCountNum(vehicleId: number) {
    PacketActions.getPacketSendActivityTypes('counts', vehicleId, '1', this.props.DJtoken).then(
      json => {
        if (json !== null && json !== undefined) {
          this.setState({
            launchedNum: json.count_launched,
            viewedNum: json.count_viewed,
            printedNum: json.count_printed,
          })
        }
      },
    )
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*API***********************
  // ActivityGetAPIData
  // - sends manipulated states to the API as the correct request params to get the
  // returned packetsend data
  ActivityGetAPIData(packetSendViewTypeID: string, pageDirection: string, vehicleId: number) {
    this.setState({ loading: true })
    if (packetSendViewTypeID === 'price-history') {
      PacketActions.getPriceDeltaPackets(vehicleId, this.props.DJtoken).then(json => {
        if (json && json.price_changes) {
          this.setState({
            entries: json.price_changes.reverse(),
            pageNum: pageDirection === '1' ? 0 : this.state.pageNum,
            count: json.price_changes.length,
            loading: false,
            pageNext: null,
            pagePrevious: null,
          })
        } else {
          this.setState({
            entries: [],
            pageNum: 0,
            count: 0,
            loading: false,
            pageNext: null,
            pagePrevious: null,
          })
        }
      })
    } else {
      PacketActions.getPacketSendActivityTypes(
        packetSendViewTypeID,
        vehicleId,
        pageDirection,
        this.props.DJtoken,
      )
        .then(json => {
          if (json.results && json.results.length > 0) {
            this.setState({
              entries: json.results,
              pagePrevious:
                json.previous !== null ? json.previous.split('packets/history/')[1] : null,
              pageNext: json.next !== null ? json.next.split('packets/history/')[1] : null,
              pageNum: pageDirection === '1' ? 0 : this.state.pageNum,
              count: json.count,
              loading: false,
            })
          } else {
            this.setState({ entries: [], count: 0, loading: false })
          }
        })
        .catch(error => {
          this.setState({ count: 0, loading: false })
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*API***********************

  // ***********************LETS*SETUP*COLUMNS***********************
  // ActivityColumnsContainer
  // - renders the table and columns fully for the imported react-table
  ActivityColumnsContainer() {
    // states
    const { entries, packetSendViewTypeID } = this.state

    // arrays
    const columnsArray = []

    if (entries && entries.length > 0) {
      if (packetSendViewTypeID === 'launched') {
        // Add launched results
        columnsArray.push(
          {
            Header: 'Recipient',
            id: 'customer_first_name',
            accessor: d => {
              return (
                <div className="sent-cell flexColumn">
                  <span>
                    {d.customer_first_name} {d.customer_last_name}
                  </span>
                  <span>{d.customer_email}</span>
                </div>
              )
            },
          },
          {
            Header: 'Sender',
            id: 'sales_person_first_name',
            accessor: d => `${d.sales_person_first_name} ${d.sales_person_last_name}`,
          },
          {
            Header: `Date ${runtimeEnv.timezoneAbbr}`,
            width: 120,
            id: 'date_launched',
            accessor: d => {
              return (
                <div className="sent-cell">
                  {Helper.dtFormat(Helper.localizeTime(d.date_launched), 'M/d/yyyy')}
                  <br />
                  {Helper.dtFormat(Helper.localizeTime(d.date_launched), 'h:mm b')}
                </div>
              )
            },
          },
          {
            Header: 'Views',
            width: 60,
            id: 'count',
            accessor: d => d.count,
          },
        )
      } else if (packetSendViewTypeID === 'viewed') {
        // Add viewed results
        columnsArray.push(
          {
            Header: 'Team Member',
            id: 'first_name',
            accessor: d => `${d.first_name} ${d.last_name}`,
          },
          {
            Header: `Date (${runtimeEnv.timezoneAbbr})`,
            id: 'date_viewed',
            accessor: d => {
              return (
                <div className="sent-cell">
                  {Helper.dtFormat(Helper.localizeTime(d.date_viewed), 'M/d/yyyy')}
                  <br />
                  {Helper.dtFormat(Helper.localizeTime(d.date_viewed), 'h:mm b')}
                </div>
              )
            },
          },
        )
      } else if (packetSendViewTypeID === 'printed') {
        // Add printed results
        columnsArray.push(
          {
            Header: 'Team Member',
            id: 'first_name',
            accessor: d => `${d.first_name} ${d.last_name}`,
          },
          {
            Header: 'Module',
            id: 'module',
            accessor: d => d.module,
          },
          {
            Header: `Date (${runtimeEnv.timezoneAbbr})`,
            id: 'date_printed',
            accessor: d => {
              return (
                <div className="sent-cell">
                  {Helper.dtFormat(Helper.localizeTime(d.date_printed), 'M/d/yyyy')}
                  <br />
                  {Helper.dtFormat(Helper.localizeTime(d.date_printed), 'h:mm b')}
                </div>
              )
            },
          },
        )
      } else if (packetSendViewTypeID === 'price-history') {
        // Add printed results
        columnsArray.push(
          {
            Header: `Date (${runtimeEnv.timezoneAbbr})`,
            id: 'date',
            accessor: d => {
              return (
                <div className="sent-cell">
                  {Helper.dtFormat(Helper.localizeTime(d.date_changed), 'M/d/yyyy')}
                  <br />
                  {Helper.dtFormat(Helper.localizeTime(d.date_changed), 'h:mm b')}
                </div>
              )
            },
          },
          {
            Header: 'Change',
            id: 'change',
            accessor: d => {
              return (
                <div style={{ color: d.direction > 0 ? 'red' : 'green' }}>
                  {Helper.FormatMoneyUSA(d.delta * d.direction)}
                </div>
              )
            },
          },
          {
            Header: 'Price',
            id: 'price',
            accessor: d => {
              return <div className="">{Helper.FormatMoneyUSA(d.price)}</div>
            },
          },
        )
      }
    }

    return columnsArray
  }
  // ***********************LETS*SETUP*COLUMNS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // ActivityTopContainer
  // - renders the full top of the packetsend navigation header
  ActivityTopContainer() {
    // states
    const { packetSendViewTypeID, launchedNum, viewedNum, printedNum } = this.state

    // arrays
    const buttons = []

    // counters
    let buttonCounter = 0

    if (AIPSeedsData.packetsend_buttons && AIPSeedsData.packetsend_buttons.length > 0) {
      for (const buttonEntry of AIPSeedsData.packetsend_buttons) {
        let num = launchedNum

        if (buttonEntry.title_id === 'viewed') {
          num = viewedNum
        } else if (buttonEntry.title_id === 'printed') {
          num = printedNum
        } else if (buttonEntry.title_id === 'launched') {
          num = launchedNum
        } else {
          num = null
        }

        buttons.push(
          <button
            className={`packetsend-tab-btn ${
              buttonEntry.title_id === packetSendViewTypeID.toLowerCase() ? ' active' : ''
            }`}
            key={buttonCounter++}
            onClick={() => {
              this.setState({
                packetSendViewTypeID: buttonEntry.title_id,
                pagePrevious: null,
                pageNext: '1',
                count: 0,
                pageNum: 0,
                loading: true,
              })

              this.ActivityGetAPIData(buttonEntry.title_id, '1', this.state.vehicleId)
            }}
          >
            {buttonEntry.title}{' '}
            {num && (
              <React.Fragment>
                (<span className="packetsend-btn-num">{num}</span>)
              </React.Fragment>
            )}
          </button>,
        )
      }
    }

    return <div className="packetsend-tab-nav">{buttons}</div>
  }

  // ActivityTableContainer
  // - renders the body of the table using the data returned from 'ActivityColumnsContainer'
  ActivityTableContainer() {
    // states
    const {
      packetSendViewTypeID,
      entries,
      pageNext,
      pagePrevious,
      count,
      loading,
      pageNum,
    } = this.state

    // options
    const ReactTableOptions = {
      className: '-striped -highlight packetsend-activity-table',
      loading,
      sortable: false,
      loadingText: '',
    }

    return (
      <div style={{ width: '100%' }}>
        <ReactTable
          {...ReactTableOptions}
          {...ReactTableOptionsStatic}
          LoadingComponent={LoadingSpinner}
          data={entries}
          resolveData={data => data.map(row => row)}
          noDataText={
            packetSendViewTypeID == 'price-history'
              ? 'Price for this vehicle has not yet changed'
              : 'No entries for this cateagory'
          }
          columns={this.ActivityColumnsContainer()}
          showPagination={pageNext || pagePrevious}
          page={pageNum}
          pages={count > 0 ? Math.ceil(count / 15).toFixed(0) : 1}
          PreviousComponent={() => {
            const pagePrevBoolean = pagePrevious !== null && pagePrevious !== undefined

            return (
              <button
                className={`packetsend-page-btn btn-prev packetsendRemoveButtonStyle ${
                  pagePrevBoolean ? ' ' : ' btn-disabled'
                }`}
                style={{
                  color: pagePrevBoolean ? 'white' : 'grey',
                  cursor: pagePrevBoolean ? 'pointer' : 'auto',
                }}
                onClick={() => {
                  if (pagePrevBoolean) {
                    this.ActivityGetAPIData(
                      packetSendViewTypeID,
                      pagePrevious,
                      this.state.vehicleId,
                    )
                    this.setState({ pageNum: pageNum - 1 })
                  }
                }}
              >
                Previous
              </button>
            )
          }}
          NextComponent={() => {
            const pageNextBoolean = pageNext !== null && pageNext !== undefined

            return (
              <button
                className={`packetsend-page-btn btn-prev packetsendRemoveButtonStyle ${
                  pageNextBoolean ? ' ' : ' btn-disabled'
                }`}
                style={{
                  color: pageNextBoolean ? 'white' : 'grey',
                  cursor: pageNextBoolean ? 'pointer' : 'auto',
                }}
                onClick={() => {
                  if (pageNextBoolean) {
                    this.ActivityGetAPIData(packetSendViewTypeID, pageNext, this.state.vehicleId)
                    this.setState({ pageNum: pageNum + 1 })
                  }
                }}
              >
                Next
              </button>
            )
          }}
        />
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    const { loading } = this.state
    if (loading) {
      return (
        <div
          className="packetSendMiddleContentStyle"
          style={{ padding: 0, position: 'relative', minHeight: '400px' }}
        >
          <LoadingSpinner loading wheel />
        </div>
      )
    }
    return (
      <div className="packetSendMiddleContentStyle" style={{ padding: 0 }}>
        <b style={{ fontSize: '1.5em' }}>Activity</b>
        <br />
        {this.ActivityTopContainer()}
        {this.ActivityTableContainer()}
      </div>
    )
  }
}

const mapStateToProps = state => ({ stashed_content: state.packetstash })
export default connect(mapStateToProps, {})(SendActivity)
