import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setHours, setMinutes, addDays, addHours, getDay } from 'date-fns'

// components
import Modal from '../../layouts/modal'

// helpers
import Paginator from '../../helpers/paginator'
import LoadingSpinner from '../../helpers/loadingspinner'

// assets
import AddIcon from '../../../core/assets/images/icons/icon-additem'
import IconAddItem from '../../../core/assets/images/icons/icon-additem'

import { REPORT_ISSUE_MODAL } from '../../../core/_constants/modalTypes'

// actions
import packetActions from '../../../core/actions/packets/packetsendactions'
import { hideModal, showModal } from '../../../core/_actions/modalActions'
import { stashPreviousAttachments } from '../../../core/_actions/packetstashActions'

class PreviousAttachmentsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      previousAttachments: [],
      imgSrc: [],
      selectedAttachments: [],
      loading: 0,
      count: 0,
    }

    this.updatePage = this.updatePage.bind(this)
    this.updatePageData = this.updatePageData.bind(this)
  }

  componentDidMount() {
    const { page, previousAttachments, selectedAttachments } = this.state
    const { modalProps } = this.props

    packetActions.getPreviousAttachments(modalProps.username, modalProps.token, page).then(res => {
      if (res) {
        this.setState({
          previousAttachments: res.results,
          count: res.count,
        })

        modalProps.attachments.map(attachment => {
          res.results.map(previousAttachment => {
            if (attachment.attachment_id == previousAttachment.attachment_id) {
              const currentAttachments = []
              currentAttachments.push(attachment)
              this.setState({
                selectedAttachments: currentAttachments,
              })
            }
          })
        })
      }
    })
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  updatePage(page) {
    this.updatePageData(page)
  }

  updatePageData(page) {
    const { modalProps } = this.props

    this.setState(() => {
      return {
        previousAttachmentsPage: [],
        page: 0,
        page,
      }
    })

    packetActions.getPreviousAttachments(modalProps.username, modalProps.token, page).then(res => {
      if (res) {
        this.setState({
          previousAttachments: res.results,
        })
      }
    })
  }

  CustomerConfigureStates(json, page) {
    this.setState(() => {
      return {
        previousAttachmentsPage: json.results,
        page: page,
      }
    })
  }

  async addAttachment(attachment) {
    const { selectedAttachments } = this.state
    const { modalProps } = this.props

    console.log(modalProps.newAttachmentsLength)

    if (modalProps.newAttachmentsLength + selectedAttachments.length < 3) {
      var attachments = selectedAttachments

      var formattedAttachment = attachment
      formattedAttachment.name = formattedAttachment.file_url.split('/').pop()
      formattedAttachment.type = formattedAttachment.file_type
      formattedAttachment.lastModifiedDate = new Date(formattedAttachment.date_modified)

      attachments.push(formattedAttachment)

      await fetch(attachment.api_url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${modalProps.token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(res => {
          if (res) {
            return res.blob()
          }
        })
        .then(blobResponse => {
          const fileReaderInstance = new FileReader()
          fileReaderInstance.readAsDataURL(blobResponse)

          fileReaderInstance.onload = () => {
            const { imgSrc } = this.state
            imgSrc.push(fileReaderInstance.result)

            this.setState({
              imgSrc: imgSrc,
            })
          }
        })

      this.setState({
        selectedAttachments: attachments,
      })
    }
  }

  deleteAttachment(attachment) {
    const { selectedAttachments } = this.state

    var attachmentIndex = selectedAttachments.indexOf(attachment)

    var attachments = selectedAttachments

    attachments.splice(attachmentIndex, 1)

    this.setState({
      selectedAttachments: attachments,
    })
  }

  passAttachmentsUp() {
    this.props.getAttachmentsFromPreviousAttachmentsModal(this.state.selectedAttachments)
  }

  render() {
    const { page, previousAttachments, selectedAttachments, count, imgSrc } = this.state
    const { newAttachmentsLength } = this.props

    console.log(selectedAttachments, previousAttachments)
    return (
      <Modal>
        <div className="previous-attachments-modal">
          <div className="previous-attachments">
            <div className="attachment-header">Previous Attachments</div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Attachment Name</th>
                    <th>Attachment Type</th>
                    <th>Creation Date</th>
                    <th></th>
                  </tr>
                </thead>
                {previousAttachments.length > 0 && (
                  <tbody>
                    {previousAttachments.map((attachment, index) => (
                      <tr key={index}>
                        <td>{attachment.file_url.split('/').pop()}</td>
                        <td>{attachment.file_type}</td>
                        <td>
                          {addHours(new Date(attachment.date_created + 'Z'), 4).toLocaleString()}
                        </td>
                        {console.log()}
                        {console.log(
                          selectedAttachments.findIndex(
                            v => v.attachment_id === attachment.attachment_id,
                          ),
                        )}

                        {!(
                          selectedAttachments.findIndex(
                            v => v.attachment_id === attachment.attachment_id,
                          ) === 0
                        ) ? (
                          <td
                            className={
                              newAttachmentsLength + selectedAttachments.length >= 3
                                ? 'add-attachment disabled'
                                : 'add-attachment'
                            }
                            onClick={() => this.addAttachment(attachment)}
                            disabled={
                              newAttachmentsLength + selectedAttachments.length >= 3 ? true : false
                            }
                          >
                            <IconAddItem />
                          </td>
                        ) : (
                          <td
                            className="delete-attachment"
                            onClick={() => this.deleteAttachment(attachment)}
                          >
                            <IconAddItem />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
            <Paginator page={page} updatePage={this.updatePage} numPages={Math.ceil(count / 10)} />
            {selectedAttachments.length > 0 && (
              <div>
                <div className="attachment-header">Selected Attachments</div>
                <table>
                  <thead>
                    <tr>
                      <th>Attachment Name</th>
                      <th>Attachment Type</th>
                      <th>Creation Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  {selectedAttachments.length > 1 && (
                    <tbody>
                      {selectedAttachments.map(attachment => (
                        <tr>
                          <td>{attachment.file_url.split('/').pop()}</td>
                          <td>{attachment.file_type}</td>
                          <td>
                            {addHours(new Date(attachment.date_created + 'Z'), 4).toLocaleString()}
                          </td>

                          <td
                            className="delete-attachment"
                            onClick={() => this.deleteAttachment(attachment)}
                          >
                            <IconAddItem />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                  {selectedAttachments.length === 1 && (
                    <tbody>
                      <tr>
                        <td>{selectedAttachments[0].file_url.split('/').pop()}</td>
                        <td>{selectedAttachments[0].file_type}</td>
                        <td>
                          {addHours(
                            new Date(selectedAttachments[0].date_created + 'Z'),
                            4,
                          ).toLocaleString()}
                        </td>
                        <td
                          className="delete-attachment"
                          onClick={() => this.deleteAttachment(selectedAttachments[0])}
                        >
                          <IconAddItem />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            )}
            <div className="attachments-button">
              <button
                className="cancel-button"
                onClick={() => {
                  this.props.hideModalConnect()
                }}
              >
                Cancel
              </button>
              {selectedAttachments.length > 0 && (
                <button
                  className="add-button"
                  onClick={() => {
                    this.props.stashPreviousAttachmentsConnect(selectedAttachments, imgSrc),
                      this.props.hideModalConnect()
                  }}
                >
                  Add Selected Attachments
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  modalProps: state.modal.props,
})

export default connect(mapStateToProps, {
  showModalConnect: showModal,
  hideModalConnect: hideModal,
  stashPreviousAttachmentsConnect: stashPreviousAttachments,
})(PreviousAttachmentsModal)
