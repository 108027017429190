// packages
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { sub, format, add } from 'date-fns'
import DatePicker from 'react-datepicker'

// actions
import packetactions from '../../../../core/actions/packets/packetactions'

// components
import LoadingSpinner from '../../../helpers/loadingspinner'
import { showModal } from '../../../../core/_actions/modalActions'
import { LIGHTBOX_MODAL } from '../../../../core/_constants/modalTypes'

//icons
import ChangeIcon from '../../../../core/assets/images/icons/change-icon'
import ViewModule from '../../../../core/assets/images/icons/viewmodule-icon'
import SendIcon from '../../../../core/assets/images/icons/send-icon'
import CameraIcon from '../../../../core/assets/images/icons/camera-icon'
import PriceChange from '../../../../core/assets/images/icons/pricechange-icon'
import LabelIcon from '../../../../core/assets/images/icons/label-icon'
import IconLocation from '../../../../core/assets/images/icons/icon-location'

// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'
import '../../styles/timeline.scss'

function VehicleTimelineService(props) {
  const { token, vin, showModalConnect } = props

  const today = new Date()
  const [currentTimePeriod, setCurrentTimePeriod] = useState('All-Time')
  const [lowerbound, setLowerBound] = useState('none')
  const [upperbound, setUpperbound] = useState(new Date())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [eventSorting, setEventSorting] = useState('new-to-old')
  const [eventDates, setEventDates] = useState({})
  const [currentEventDates, setCurrentEventDates] = useState({})
  const [eventDateKeys, setEventDateKeys] = useState([])
  const [currentEventKeys, setCurrentEventKeys] = useState([])
  const [eventFilters, setEventFilters] = useState({
    status_change: false,
    module_added: false,
    packet_share: false,
    sticker_pull: false,
    image_added: false,
    price_change: false,
  })
  const [addedImages, setAddedImages] = useState([])
  const [hasDataFetched, setHasDataFetched] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentVin, setCurrentVin] = useState('')
  const [associatedStore, setAssociatedStore] = useState(null)

  const handleNoTemplatesFound = () => {
    if (currentEventKeys.length === 0) {
      return currentEventKeys.length === 0
    } else if (currentEventKeys.length > 0) {
      const noTemplatesFilter = eventDateKey => {
        if (
          Object.keys(currentEventDates).includes(eventDateKey) &&
          currentEventDates[eventDateKey].length > 0
        ) {
          return (
            (new Date(currentEventDates[eventDateKey][0].time) >= new Date(lowerbound) &&
              new Date(currentEventDates[eventDateKey][0].time) <= new Date(upperbound)) ||
            lowerbound === 'none'
          )
        }
      }
      const filteredList = currentEventKeys.filter(eventDateKey => noTemplatesFilter(eventDateKey))
      return filteredList.length === 0
    }
  }

  const handleTimePeriod = timePeriod => {
    switch (timePeriod) {
      case 'Day':
        setCurrentTimePeriod('Day')
        setLowerBound(sub(today, { days: 1 }))
        setUpperbound(new Date())
        return
      case 'Week':
        setCurrentTimePeriod('Week')
        setLowerBound(sub(today, { weeks: 1 }))
        setUpperbound(new Date())
        return
      case 'Month':
        setCurrentTimePeriod('Month')
        setLowerBound(sub(today, { months: 1 }))
        setUpperbound(new Date())
        return
      case 'Three-Months':
        setCurrentTimePeriod('Three-Months')
        setLowerBound(sub(today, { months: 3 }))
        setUpperbound(new Date())
        return
      case 'Six-Months':
        setCurrentTimePeriod('Six-Months')
        setLowerBound(sub(today, { months: 6 }))
        setUpperbound(new Date())
        return
      case 'Year':
        setCurrentTimePeriod('Year')
        setLowerBound(sub(today, { years: 1 }))
        setUpperbound(new Date())
        return
      case 'All-Time':
        setCurrentTimePeriod('All-Time')
        setLowerBound('none')
        setUpperbound(new Date())
        return
      case 'Custom':
        setCurrentTimePeriod('Custom')
        return
    }
  }

  const handleCustomTimePeriod = () => {
    setLowerBound(startDate)
    setUpperbound(endDate)
  }

  const handleCustomTimeChange = (date, type) => {
    if (type === 'startDate') {
      setStartDate(date)
      if (endDate && date > endDate) {
        setEndDate(add(date, { hours: 23, minutes: 59, seconds: 59 }))
      }
    } else {
      setEndDate(date)
    }
  }

  const handleEventSorting = timeDirection => {
    const newEventDateKeys = eventDateKeys
    if (timeDirection === 'new-to-old') {
      newEventDateKeys.sort((a, b) => new Date(b) - new Date(a))
    } else {
      newEventDateKeys.sort((a, b) => new Date(a) - new Date(b))
    }
    setCurrentEventKeys(newEventDateKeys)
    setEventSorting(timeDirection)
  }

  const handleEventFilters = filter => {
    const {
      status_change,
      module_added,
      packet_share,
      sticker_pull,
      image_added,
      price_change,
    } = eventFilters

    const updatedEventFilters = eventFilters

    switch (filter) {
      case 'status_change':
        updatedEventFilters.status_change = !status_change
        setEventFilters(updatedEventFilters)
        updateCurrentEventDates()
        return
      case 'module_added':
        updatedEventFilters.module_added = !module_added
        setEventFilters(updatedEventFilters)
        updateCurrentEventDates()
        return
      case 'packet_share':
        updatedEventFilters.packet_share = !packet_share
        setEventFilters(updatedEventFilters)
        updateCurrentEventDates()
        return
      case 'sticker_pull':
        updatedEventFilters.sticker_pull = !sticker_pull
        setEventFilters(updatedEventFilters)
        updateCurrentEventDates()
        return
      case 'image_added':
        updatedEventFilters.image_added = !image_added
        setEventFilters(updatedEventFilters)
        updateCurrentEventDates()
        return
      case 'price_change':
        updatedEventFilters.price_change = !price_change
        setEventFilters(updatedEventFilters)
        updateCurrentEventDates()
        return
    }
  }
  const resetEventFilters = () => {
    const {
      status_change,
      module_added,
      packet_share,
      sticker_pull,
      image_added,
      price_change,
    } = eventFilters

    const updatedEventFilters = eventFilters

    if (status_change) {
      updatedEventFilters.status_change = false
      setEventFilters(updatedEventFilters)
      updateCurrentEventDates()
    }
    if (module_added) {
      updatedEventFilters.module_added = false
      setEventFilters(updatedEventFilters)
      updateCurrentEventDates()
    }
    if (packet_share) {
      updatedEventFilters.packet_share = false
      setEventFilters(updatedEventFilters)
      updateCurrentEventDates()
    }
    if (sticker_pull) {
      updatedEventFilters.sticker_pull = false
      setEventFilters(updatedEventFilters)
      updateCurrentEventDates()
    }
    if (image_added) {
      updatedEventFilters.image_added = false
      setEventFilters(updatedEventFilters)
      updateCurrentEventDates()
    }
    if (price_change) {
      updatedEventFilters.price_change = false
      setEventFilters(updatedEventFilters)
      updateCurrentEventDates()
    }
  }

  const updateCurrentEventDates = () => {
    const eventFilterKeys = Object.keys(eventFilters)
    const appliedFilters = eventFilterKeys.filter(key => eventFilters[key])

    const updatedCurrentEvents = {}
    const updatedEventKeys = []

    if (appliedFilters.length > 0) {
      eventDateKeys.forEach(eventKey => {
        const newEventDates = eventDates[eventKey].filter(event =>
          appliedFilters.includes(event.category),
        )
        updatedCurrentEvents[eventKey] = newEventDates
        newEventDates.length > 0 && updatedEventKeys.push(eventKey)
      })

      setCurrentEventDates(updatedCurrentEvents)
      setCurrentEventKeys(updatedEventKeys)
    } else {
      setCurrentEventDates(eventDates)
      setCurrentEventKeys(eventDateKeys)
    }
  }

  const totalEventCount = () => {
    const currentEventTotals = []

    currentEventKeys.forEach(eventDateKey => {
      if (lowerbound === 'none') {
        currentEventDates[eventDateKey] &&
          currentEventTotals.push(currentEventDates[eventDateKey].length)
      } else if (
        new Date(currentEventDates[eventDateKey][0].time) >= new Date(lowerbound) &&
        new Date(currentEventDates[eventDateKey][0].time) <= new Date(upperbound)
      ) {
        currentEventDates[eventDateKey] &&
          currentEventTotals.push(currentEventDates[eventDateKey].length)
      }
    })
    const arrayReduceFunc = (previousValue, currentValue) => previousValue + currentValue
    return currentEventTotals.reduce(arrayReduceFunc, 0)
  }

  const getVehicleHistoryData = () => {
    setIsLoading(true)
    resetEventFilters()
    handleEventSorting('new-to-old')
    handleTimePeriod('All-Time')
    handleCustomTimeChange(null, 'startDate')
    handleCustomTimeChange(null, 'endDate')

    setCurrentVin(vin)
    packetactions
      .getVehicleHistory(vin, token)
      .then(res => {
        if (res) {
          const eventsByDate = {}
          const newAddedImages = []
          if (res.vehicles.length > 0) {
            res.vehicles[0].history.forEach(eventType => {
              eventType.events.forEach(event => {
                let eventDate = new Date(event.time)
                eventDate.setHours(0, 0, 0)
                eventDate = eventDate.toUTCString()
                event.date = eventDate
                if (event.category === 'status_change') {
                  event.icon = ChangeIcon
                  event.displayCategory = 'Status Change'
                } else if (event.category === 'module_added') {
                  event.icon = ViewModule
                  event.displayCategory = 'Module Added'
                } else if (event.category === 'packet_share') {
                  event.icon = SendIcon
                  event.displayCategory = 'Packet Share'
                } else if (event.category === 'sticker_pull') {
                  event.icon = LabelIcon
                  event.displayCategory = 'Sticker Pull'
                } else if (event.category === 'image_added') {
                  event.icon = CameraIcon
                  event.displayCategory = 'Image Added'
                  event.extra_data &&
                    event.extra_data.image_link &&
                    newAddedImages.push(event.extra_data.image_link)
                } else {
                  event.icon = PriceChange
                  event.displayCategory = 'Price Change'
                }
                if (eventsByDate[eventDate]) {
                  !eventsByDate[eventDate].includes(event) && eventsByDate[eventDate].push(event)
                } else {
                  eventsByDate[eventDate] = []
                  eventsByDate[eventDate].push(event)
                }
              })
            })
            setAssociatedStore(res.vehicles[0].store_id)
            setEventDates(eventsByDate)
            setCurrentEventDates(eventsByDate)
            const eventsByDateKeys = Object.keys(eventsByDate)
            eventsByDateKeys.sort((a, b) => new Date(b) - new Date(a))
            setEventDateKeys(eventsByDateKeys)
            setCurrentEventKeys(eventsByDateKeys)
            setAddedImages(newAddedImages)
          } else {
            setEventDates({})
            setCurrentEventDates({})
            setEventDateKeys([])
            setCurrentEventKeys([])
            setAssociatedStore()
          }
          setHasDataFetched(true)
          setIsLoading(false)
        }
      })
      .catch(error => {
        if (error) {
          console.error(error)
          setHasDataFetched(null)
          setIsLoading(false)
        }
      })
  }

  useEffect(() => {
    const shouldDataFetch = hasDataFetched === null || currentVin !== vin
    shouldDataFetch && getVehicleHistoryData()
  }, [currentTimePeriod, eventFilters, vin, eventSorting])

  const renderNoEventsFoundMessage = () => {
    return (
      <div className="eventInfo">
        <h2>No Events Found</h2>
        <p>Sorry, no events were found in the selected time period</p>
      </div>
    )
  }

  const renderTimelineRow = eventDateKey => {
    const events = currentEventDates[eventDateKey]
    const renderedEvents = events.map(event => {
      return (
        <div className="eventInfo" key={events.indexOf(event)}>
          <p>
            {event.icon()} {event.displayCategory}
          </p>
          {event.extra_data.image_link && (
            <img
              src={event.extra_data.image_link}
              className="addedImage"
              onClick={() => {
                showModalConnect(LIGHTBOX_MODAL, {
                  images: addedImages,
                  index: events.indexOf(event),
                })
              }}
            />
          )}
          <p>{event.label}</p>
          <p>Time: {format(new Date(event.time), 'h:mm aaa')}</p>
        </div>
      )
    })
    return renderedEvents
  }

  const renderVerticalTimeline = () => {
    return (
      <React.Fragment>
        {currentEventKeys.map(eventDateKey => {
          if (currentEventDates[eventDateKey] && currentEventDates[eventDateKey].length > 0) {
            const fullEventDate = format(
              new Date(currentEventDates[eventDateKey][0].time),
              'EEEE, do MMMM y',
            )
            if (lowerbound === 'none') {
              return (
                <span key={currentEventKeys.indexOf(eventDateKey)}>
                  <h2>{fullEventDate}</h2>
                  {renderTimelineRow(eventDateKey)}
                </span>
              )
            } else if (
              new Date(currentEventDates[eventDateKey][0].time) >= new Date(lowerbound) &&
              new Date(currentEventDates[eventDateKey][0].time) <= new Date(upperbound)
            ) {
              return (
                <span key={currentEventKeys.indexOf(eventDateKey)}>
                  <h2>{fullEventDate}</h2>
                  {renderTimelineRow(eventDateKey)}
                </span>
              )
            }
          }
        })}
        {handleNoTemplatesFound() && <span>{renderNoEventsFoundMessage()}</span>}
      </React.Fragment>
    )
  }

  const renderDateRange = () => {
    const newEventDateKeys = eventDateKeys.slice().reverse()
    const eventTotals = totalEventCount()

    if (lowerbound === 'none') {
      return (
        <div className="date-range">
          {eventDateKeys.length > 0 && (
            <React.Fragment>
              <div>
                <b>From:</b> {format(new Date(newEventDateKeys[0]), 'do MMMM y')}
              </div>
              <div>
                <b>To:</b> {format(upperbound, 'do MMMM y')}
              </div>
              {eventTotals === 1 ? (
                <div>
                  <b>{eventTotals} event</b>
                </div>
              ) : (
                <div>
                  <b>{eventTotals} events</b>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      )
    }
    return (
      <div className="date-range">
        <div>
          <b>From:</b> {format(lowerbound, 'do MMMM y')}
        </div>
        <div>
          <b>To:</b> {format(upperbound, 'do MMMM y')}
        </div>
        {eventTotals === 1 ? (
          <div>
            <b>{eventTotals} event</b>
          </div>
        ) : (
          <div>
            <b>{eventTotals} events</b>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="vehicle-timeline-service">
      <b className="title">Vehicle Timeline Service</b>

      <div className="main-content">
        <div className="left-column">
          <div className="currentTimePeriod">
            <p>Time Period</p>
            <select
              value={currentTimePeriod}
              onChange={e => handleTimePeriod(e.target.value)}
              defaultValue="All-Time"
            >
              <option value="Day">Last 24 Hours</option>
              <option value="Week">Last Week</option>
              <option value="Month">Last Month</option>
              <option value="Three-Months">Last 3 Months</option>
              <option value="Six-Months">Last 6 Months</option>
              <option value="Year">Last Year</option>
              <option value="All-Time">All Time</option>
              <option value="Custom">Custom</option>
            </select>
            {currentTimePeriod === 'Custom' && (
              <span className="picker-containers">
                <p>From: </p>
                <DatePicker
                  selected={startDate}
                  onChange={date => handleCustomTimeChange(date, 'startDate')}
                  className="datePicker"
                  maxDate={new Date()}
                  placeholderText="Select Start Date"
                />
                {startDate && (
                  <React.Fragment>
                    <p>To: </p>
                    <DatePicker
                      selected={endDate}
                      onChange={date => handleCustomTimeChange(date, 'endDate')}
                      className="datePicker"
                      minDate={startDate}
                      maxDate={new Date()}
                      placeholderText="Select End Date"
                    />
                  </React.Fragment>
                )}
                <button
                  type="button"
                  onClick={() => handleCustomTimePeriod()}
                  disabled={!endDate}
                  className="datepicker-button"
                >
                  Submit
                </button>
              </span>
            )}
          </div>

          <div className="customLegend">
            <p>Legend</p>
            <button
              onClick={e => handleEventFilters(e.target.value)}
              value="status_change"
              className={eventFilters.status_change ? 'selected' : null}
            >
              <ChangeIcon />
              Status Change
            </button>
            <button
              onClick={e => handleEventFilters(e.target.value)}
              value="module_added"
              className={eventFilters.module_added ? 'selected' : null}
            >
              <ViewModule />
              Module Added
            </button>
            <button
              onClick={e => handleEventFilters(e.target.value)}
              value="packet_share"
              className={eventFilters.packet_share ? 'selected' : null}
            >
              <SendIcon />
              Packet Share
            </button>
            <button
              onClick={e => handleEventFilters(e.target.value)}
              value="sticker_pull"
              className={eventFilters.sticker_pull ? 'selected' : null}
            >
              <LabelIcon />
              Sticker Pull
            </button>
            <button
              onClick={e => handleEventFilters(e.target.value)}
              value="image_added"
              className={eventFilters.image_added ? 'selected' : null}
            >
              <CameraIcon />
              Image Added
            </button>
            <button
              onClick={e => handleEventFilters(e.target.value)}
              value="price_change"
              className={eventFilters.price_change ? 'selected' : null}
            >
              <PriceChange />
              Price Change
            </button>
          </div>
        </div>

        <div className="right-column">
          <div
            className="store-overview-link"
            style={{ display: associatedStore ? 'block' : 'none' }}
          >
            <Link to={`/staff-tools/overview/${associatedStore}`}>
              <IconLocation /> Store Overview at Vehicle Location
            </Link>
          </div>
          {hasDataFetched && renderDateRange()}
          <div className="vertical-timeline">
            {isLoading && <LoadingSpinner wheel loading />}
            <div className="sort-by">
              <div className="text">Sort by:</div>
              <select value={eventSorting} onChange={e => handleEventSorting(e.target.value)}>
                <option value="new-to-old">Newest to Oldest</option>
                <option value="old-to-new">Oldest to Newest</option>
              </select>
            </div>
            {hasDataFetched && renderVerticalTimeline()}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  token: ownProps.token,
  vin: ownProps.vin,
})

const mapDispatchToProps = {
  showModalConnect: showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTimelineService)
