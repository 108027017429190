import { SHOW_MODAL, HIDE_MODAL, HIDE_ALL_MODALS } from '../_constants/modalTypes.js'

const initialState = {
  type: null,
  props: {},
  modalList: [],
  typeList: [],
  sharedProps: {},
}

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      if (state.modalList < 1) {
        return {
          ...state,
          type: action.payload.type,
          props: action.payload.props,
          modalList: [
            {
              type: action.payload.type,
              props: action.payload.props,
            },
          ],
          typeList: [action.payload.type],
          sharedProps: action.payload.props,
        }
      } else {
        let newTypeList = state.typeList

        newTypeList.push(action.payload.type)

        console.log(action.payload.type)
        console.log(newTypeList)

        const newModal = {
          type: action.payload.type,
          props: action.payload.props,
        }

        return {
          ...state,
          type: action.payload.type,
          props: action.payload.props,
          modalList: state.modalList.concat(newModal),
          typeList: newTypeList,
          sharedProps: { ...state.props, ...action.payload.props },
        }
      }

    case HIDE_MODAL:
      if (state.modalList.length > 1) {
        let newModalList = state.modalList
        const typeIndex = state.typeList.indexOf(newModalList[newModalList.length - 1].type)
        let newTypeList = state.typeList
        newTypeList.splice(typeIndex, 1)

        let currentModal = newModalList.pop()
        const currentProps = currentModal.props
        let currentSharedProps = state.sharedProps
        for (const [key, value] of Object.entries(currentProps)) {
          if (key in currentSharedProps) {
            delete currentSharedProps[key]
          }
        }

        return {
          ...state,
          type: newModalList[newModalList.length - 1].type,
          props: newModalList[newModalList.length - 1].props,
          modalList: newModalList,
          typeList: newTypeList,
          sharedProps: currentSharedProps,
        }
      } else {
        return initialState
      }

    case HIDE_ALL_MODALS:
      return initialState

    default:
      return state
  }
}

export default modalReducer
