import React, { Component } from 'react'
import { connect } from 'react-redux'

// components
import { hideModal, hideAllModals } from '../../../core/_actions/modalActions'
import Modal from '../../layouts/modal'

// styles
import '../styles/deletescheduledpacketmodal.scss'

// actions
import { deleteScheduledPacket, getScheduledPackets } from '../../../core/actions/sendpacketactions'
import { stashScheduledPackets } from '../../../core/_actions/packetstashActions'
import LoadingSpinner from '../../helpers/loadingspinner'

class DeleteScheduledPacketModal extends Component {
  constructor(props) {
    super(props)
  }

  deleteScheduledPacketAndHideModals() {
    const { recipient, date, id, token } = this.props.payload
    const { scheduledPackets } = this.props

    var newScheduledPackets = scheduledPackets

    newScheduledPackets.splice(
      newScheduledPackets.findIndex(v => v.id === id),
      1,
    )

    console.log(newScheduledPackets)

    deleteScheduledPacket(id, token)
      .then(this.props.stashScheduledPackets(newScheduledPackets))
      .then(this.props.hideAllModals())
  }

  render() {
    const { recipient, date, id, token } = this.props.payload

    console.log(id)

    const formattedDate = date.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: 'true',
      hour: 'numeric',
      minute: '2-digit',
    })

    return (
      <Modal>
        <div className="delete-content">
          <div className="delete-message">
            Are you sure you want to delete this scheduled packet send to{' '}
            <b>{recipient.map(customer => customer.first_name + ' ' + customer.last_name)}</b> on{' '}
            <b>{formattedDate}</b>?
          </div>
          <div className="button-row">
            <button
              className="confirm-button"
              onClick={() => this.deleteScheduledPacketAndHideModals()}
            >
              Confirm
            </button>
            <button className="cancel-button" onClick={() => this.props.hideModal()}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  payload: state.modal.sharedProps,
  scheduledPackets: state.packetstash.scheduled_packets,
})

export default connect(mapStateToProps, { hideModal, hideAllModals, stashScheduledPackets })(
  DeleteScheduledPacketModal,
)
