//device detect library
import UAParser from 'ua-parser-js'

//initialize UAParser
const Parser = new UAParser.UAParser()

const BannerConfiguration = () => {
  const os = Parser.getOS()
  const userDevice = {
    device: '',
    storeName: '',
    storeLink: '',
  }

  const getUserDevice = () => {
    switch (os.name) {
      case 'Android':
        ;(userDevice.device = 'Android'),
          (userDevice.storeName = 'Play'),
          (userDevice.storeLink =
            'https://play.google.com/store/apps/details?id=com.autoipacket.mobileapp')
        return userDevice
      case 'Windows':
        ;(userDevice.device = 'Windows'),
          (userDevice.storeName = 'Microsoft'),
          (userDevice.storeLink = 'https://www.microsoft.com/store/apps/windows-phone')
        return userDevice
      case 'iOS':
        ;(userDevice.device = 'iOS'),
          (userDevice.storeName = 'App'),
          (userDevice.storeLink = 'https://apps.apple.com/us/app/autoipacket/id1246416007')
        return userDevice
    }
  }

  return getUserDevice()
}

export default BannerConfiguration
