import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { generateExecutiveReport } from '../../../../core/actions/features/reportsactions'
import ScheduleSendIcon from '../../../../core/assets/images/icons/schedule-send-icon'
import LoadingSpinner from '../../../helpers/loadingspinner'
import CheckmarkAnimation from '../../../tools/assets/checkmarkanimation'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'

function ExecutiveReports(props) {
  const [executiveReports, setExecutiveReports] = useState(null)
  const [loading, setLoading] = useState(false)
  const generateReport = (report, year, index) => {
    setLoading(true)
    updateIndividualReportLoading(year, index, true)
    generateExecutiveReport(props.token, {
      start_date: report.start_date,
      end_date: report.end_date,
      company_id: props.profile.legacy_user.company_id,
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          setLoading(false)
          updateIndividualReportLoading(year, index, false)
        }
      })
      .then(res => {
        if (res && res.message) {
          console.log(res.message)
          toast.success(res.message, {
            autoClose: 60000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setLoading(false)
          updateIndividualReportLoading(year, index, false)
          updateIndividualReportSuccess(year, index, true)
        }
      })
  }
  const updateIndividualReportLoading = (year, index, loading) => {
    let newExecutiveReports = cloneDeep(executiveReports)
    newExecutiveReports[year][index].loading = loading
    setExecutiveReports(newExecutiveReports)
  }
  const updateIndividualReportSuccess = (year, index, success) => {
    let newExecutiveReports = cloneDeep(executiveReports)
    newExecutiveReports[year][index].success = success
    setExecutiveReports(newExecutiveReports)
  }
  useEffect(() => {
    let companyCreationDate = moment(new Date(props.profile.company_date_created))
    let dateCursor = moment(new Date(props.profile.company_date_created))
    let currentDate = moment(new Date())
    let monthListings = {}

    const pushToMonthListing = (label, start_date, end_date, year) => {
      if (!monthListings[year]) {
        monthListings[year] = []
      }
      monthListings[year].unshift({
        label,
        start_date,
        end_date,
        loading: false,
      })
    }
    // push month that company was created, as long as it isnt current month
    if (!companyCreationDate.isSame(currentDate, 'month')) {
      pushToMonthListing(
        companyCreationDate.format('MMMM YYYY'),
        companyCreationDate.format('YYYY-MM-DDTHH:mm:ss'),
        companyCreationDate.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        companyCreationDate.format('YYYY'),
      )
    }
    dateCursor = dateCursor.add(1, 'M')
    // push the rest of the months if available
    if (!companyCreationDate.isSame(currentDate, 'month')) {
      while (!dateCursor.isSame(currentDate, 'month')) {
        pushToMonthListing(
          dateCursor.format('MMMM YYYY'),
          dateCursor.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
          dateCursor.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
          dateCursor.format('YYYY'),
        )
        dateCursor = dateCursor.add(1, 'M')
      }
    }
    // push current month to date
    let currentDateString = currentDate.format('YYYY-MM-DDTHH:mm:ss')
    pushToMonthListing(
      currentDate.format('MMMM YYYY'),
      currentDate.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
      currentDateString,
      currentDate.format('YYYY'),
    )
    setExecutiveReports({ ...monthListings })
  }, [])

  if (executiveReports) {
    return (
      <div className="activity-report-tables">
        {/*loading && <LoadingSpinner timeout={180000} loading />*/}
        {Object.keys(executiveReports)
          .reverse()
          .map(year => {
            return (
              <div className="activity-report-table-container">
                <div className="activity-report-table-title">{year}</div>
                <div className="activity-report-table">
                  {executiveReports[year].map((report, i) => {
                    return (
                      <div
                        onClick={() => {
                          if (!loading && !report.success) {
                            generateReport(report, year, i)
                          }
                        }}
                      >
                        <div
                          className={`${
                            loading || report.success
                              ? 'activity-report-table-item-disabled'
                              : 'activity-report-table-item'
                          }`}
                        >
                          <div className="activity-report-table-item-title">{report.label}</div>
                          <div className="activity-report-table-item-icon-toggle">
                            {report.loading ? (
                              <LoadingSpinner timeout={180000} loading />
                            ) : report.success ? (
                              <CheckmarkAnimation />
                            ) : (
                              <ScheduleSendIcon />
                            )}
                          </div>
                        </div>
                        <div className="custom-report-details"></div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  return <React.Fragment />
}
const mapStateToProps = state => ({
  profile: state.authentication.user && state.authentication.user.user.profile,
  token: state.authentication.user && state.authentication.user.token,
})
export default connect(mapStateToProps)(ExecutiveReports)
