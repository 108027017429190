import { zhCN } from 'date-fns/locale'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

import {
  DJangoContactSearchAPIURL,
  DJangoPacketSendAPIURL,
  DJangoSchedulePacketSendAPIURL,
  DJangoPacketModulesAPIURL,
  DJangoVehicleInfoAPIURL,
  DJangoStoreShareTypes,
  DJangoScheduledPacketGetAPIURL,
  DJangoEditScheduledPacketSendAPIURL,
  DJangoDeleteScheduledPacketSendAPIURL,
} from '../config/autoipacket_end_commands'
// Yes I know this file seems redundant but I wanted separation and to follow the naming convention I set
function searchCustomers(args) {
  const { page, search, store_id, per_page, token } = args
  let url = DJangoContactSearchAPIURL
  url += `?search=${search}`
  url += `&store_id=${store_id}`
  url += `&page=${page}`
  url += `&items_per_page=${per_page}`

  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${url}`, {
    method: 'GET',
    headers,
    signal: args.signal,
  }).then(response => {
    return response.json()
  })
}

function sendPacket(args) {
  const {
    token,
    share_types,
    vehicle_ids,
    customer_ids,
    module_ids,
    show_price,
    files,
    message,
    covideo_id,
    accessToken,
  } = args
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
  }

  const form = new FormData()
  share_types.map(type => {
    form.append('share_type', type)
  })
  vehicle_ids.map(id => {
    form.append('vehicle_id', id)
  })
  customer_ids.map(id => {
    form.append('send_to', id)
  })
  files.map(file => {
    form.append('file', file)
  })
  form.append('module_ids', module_ids.join())
  form.append('show_price', show_price.toString())
  form.append('share_source', 'web')
  form.append('message', message)
  form.append('lead_source', 'none')
  form.append('html_flag', true)
  if (covideo_id && accessToken) {
    form.append('covideo_video_id', covideo_id)
    headers['Covideo-Bearer-Key'] = accessToken
  }

  const packetReturn = AIP_DJAPI(DJangoPacketSendAPIURL, {
    body: form,
    method: 'POST',
    headers,
  }).then(response => {
    return response.json()
  })

  return packetReturn
}

function editScheduledPacket(args) {
  const {
    token,
    share_types,
    vehicle_ids,
    customer_ids,
    module_ids,
    show_price,
    files,
    message,
    id,
    scheduledTime,
    covideo_id,
    accessToken,
  } = args

  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
  }
  const form = new FormData()
  const formattedDate = scheduledTime
    .toLocaleString('en-ZA')
    .replace(',', '')
    .replaceAll('/', '-')

  share_types.map(type => {
    form.append('share_type', type)
  })
  vehicle_ids.map(id => {
    form.append('vehicle_id', id)
  })
  customer_ids.map(customer => {
    form.append('send_to', customer)
  })
  form.append('module_ids', module_ids.join())
  form.append('show_price', show_price.toString())
  form.append('share_source', 'web')
  form.append('message', message)
  form.append('scheduled_time', formattedDate)

  for (var pair of form.entries()) {
    console.log(pair[0] + ', ' + pair[1])
  }

  if (covideo_id && accessToken) {
    form.append('covideo_video_id', covideo_id)
    headers['Covideo-Bearer-Key'] = accessToken
  }

  const packetReturn = AIP_DJAPI(`${DJangoEditScheduledPacketSendAPIURL}${id}/`, {
    body: form,
    method: 'POST',
    headers,
  }).then(response => {
    return response.json()
  })

  return packetReturn
}

function deleteScheduledPacket(id, token) {
  let headers = {
    Authorization: `Token ${token}`,
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDeleteScheduledPacketSendAPIURL}${id}/`, {
    method: 'DELETE',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getModules(args) {
  const { token, vehicle_id } = args
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketModulesAPIURL}${vehicle_id}`, {
    method: 'GET',
    headers,
    signal: args.signal,
  }).then(response => {
    return response.json()
  })
}
function getVehicle(vehicleId, token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoVehicleInfoAPIURL}${vehicleId}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
function getShareTypes(args) {
  const { token, store_id } = args
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoStoreShareTypes}${store_id}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getScheduledPackets(token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoScheduledPacketGetAPIURL}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
function schedulePacketSend(args) {
  const {
    token,
    share_types,
    vehicle_ids,
    customer_ids,
    module_ids,
    show_price,
    message,
    scheduled_time,
    covideo_id,
    accessToken,
  } = args

  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
  }

  const form = new FormData()
  console.log(args)
  share_types.map(type => {
    form.append('share_type', type)
  })
  vehicle_ids.map(id => {
    form.append('vehicle_id', id)
  })
  customer_ids.map(id => {
    form.append('send_to', id)
  })
  form.append('module_ids', module_ids.join())
  form.append('show_price', show_price.toString())
  form.append('share_source', 'web')
  form.append('message', message)
  form.append('scheduled_time', scheduled_time)
  form.append('lead_source', 'none')
  form.append('html_flag', true)
  if (covideo_id && accessToken) {
    form.append('covideo_video_id', covideo_id)
    headers['Covideo-Bearer-Key'] = accessToken
  }
  //form.appeand('covideo_bearer_key', args.accessToken)

  console.log(form)

  const packetReturn = AIP_DJAPI(DJangoSchedulePacketSendAPIURL, {
    body: form,
    method: 'POST',
    headers,
  }).then(response => {
    return response.json()
  })

  return packetReturn
}

export {
  searchCustomers,
  sendPacket,
  getModules,
  getVehicle,
  getShareTypes,
  getScheduledPackets,
  editScheduledPacket,
  schedulePacketSend,
  deleteScheduledPacket,
}
