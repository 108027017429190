import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'

// core files
import Cropper from 'react-cropper'
import actions from '../../../core/actions/users/adminactions'

// components
import FallBackImage from '../../../core/assets/images/AutoiPacketYloRingDrk.png'
import NoCompanyLogo from '../../../core/assets/images/noCompanyLogo.png'
// css styles
import '../styles/stickySideBar.scss'

export const StoreLogoChange = ({ storeId, token, setConfigIsSet, newStore, ...props }) => {
  const [fileConstExists, setFileConstExists] = useState(true)
  const [loadedLogo, setLoadedLogo] = useState(false)
  const [userLogo, setUserLogo] = useState(null)
  const [formerLogo, setFormerLogo] = useState(null)
  const [isCropping, setIsCropping] = useState(false)
  const [imageToUpload, setImageToUpload] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const inputPicture = useRef(null)
  const cropperData = useRef(null)

  useEffect(() => {
    // Kind of Janky Check for the existence of the File Constructor - Originally written by Khristan
    try {
      new File(['test'], 'test.txt', {
        type: 'text/plain',
      })
    } catch (e) {
      setFileConstExists(false)
    }
    if (newStore === false || !newStore) {
      actions
        .getAdminOverviewImg(storeId, token)
        .then(json => {
          if (
            json.store_logo_url !== null &&
            json.store_logo_url !== undefined &&
            json.store_logo_url !== ''
          ) {
            setUserLogo(json.store_logo_url)
          } else {
            setUserLogo(FallBackImage)
          }
          setLoadedLogo(true)
        })
        .catch(error => {
          console.error(error)
          toast('An Error has occured!')
        })
    }
  }, [storeId])

  const imageUploadHandler = blob => {
    if (formerLogo !== null) {
      setUserLogo(URL.createObjectURL(blob))
      const logoFileName = 'None.png'
      let logoFile = null
      try {
        logoFile = new File([blob], logoFileName, {
          type: blob.type,
          lastModified: Date.now(),
        })
      } catch (e) {
        logoFile = imageToUpload
      }
      if (newStore === false || !newStore) {
        actions
          .PutLogoImage(storeId, token, logoFile)
          .then(res => {
            console.log(res)
            setIsSubmitting(false)
            toast('Profile image updated!')
            if (setConfigIsSet) {
              setConfigIsSet(true)
            }
          })
          .catch(error => {
            console.log(error)
            setUserLogo(formerLogo)
            toast('An Error has occured!')
          })
      }
    }
  }
  /* if (isSubmitting || !loadedLogo) {
    return (
      <div className="logo-container">
        <LoadingSpinner loading wheel />
      </div>
    )
  } */
  return (
    <React.Fragment>
      {isCropping ? (
        <div className={`logo-cropper ${fileConstExists ? '' : 'hidden'}`}>
          <Cropper
            ref={cropperData}
            src={userLogo}
            style={{ height: 180, width: '80%', margin: '0 auto' }}
            viewMode={1}
            aspectRatio={1}
            guides={true}
            modal={false}
          />
        </div>
      ) : (
        <div className="logo-container">
          <img
            className="logo-image"
            src={userLogo != null ? userLogo : FallBackImage}
            alt="Store logo"
          />
        </div>
      )}
      {!isCropping ? (
        <button
          className="logo-button"
          type="button"
          onClick={() => {
            inputPicture.current.click()
          }}
        >
          + Upload New Image
        </button>
      ) : (
        <div className="logo-option-button-container">
          <button
            className="options-button"
            type="button"
            onClick={() => {
              setIsSubmitting(true)
              setIsCropping(false)
              cropperData.current.cropper.getCroppedCanvas().toBlob(blob => {
                imageUploadHandler(blob)
              })
            }}
          >
            Save
          </button>
          <button
            className="options-button"
            type="button"
            onClick={() => {
              setIsCropping(false)
              setUserLogo(formerLogo)
              setFormerLogo(null)
            }}
          >
            Cancel
          </button>
        </div>
      )}
      <input
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        ref={inputPicture}
        onChange={e => {
          setFormerLogo(userLogo)
          setImageToUpload(e.target.files[0])
          setIsCropping(true)
          setUserLogo(URL.createObjectURL(e.target.files[0]))
        }}
        onClick={event => {
          event.target.value = null
        }}
        style={{ display: 'none' }}
      />
    </React.Fragment>
  )
}

export const CompanyLogoCropper = ({ setCroppedLogo, ...props }) => {
  const [fileConstExists, setFileConstExists] = useState(true)
  const [userLogo, setUserLogo] = useState(NoCompanyLogo)
  const [formerLogo, setFormerLogo] = useState(null)
  const [isCropping, setIsCropping] = useState(false)
  const [imageToUpload, setImageToUpload] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const inputPicture = useRef(null)
  const cropperData = useRef(null)

  useEffect(() => {
    // Kind of Janky Check for the existence of the File Constructor - Originally written by Khristan
    try {
      new File(['test'], 'test.txt', {
        type: 'text/plain',
      })
    } catch (e) {
      setFileConstExists(false)
    }
  }, [])

  const imageUploadHandler = blob => {
    if (formerLogo !== null) {
      setUserLogo(URL.createObjectURL(blob))
      const logoFileName = 'None.png'
      let logoFile = null
      try {
        console.log(`try!`)
        logoFile = new File([blob], logoFileName, {
          type: blob.type,
          lastModified: Date.now(),
        })
      } catch (e) {
        console.log(`caught error!`)
        logoFile = imageToUpload
      }
      setCroppedLogo('logo_file', logoFile)
      setIsSubmitting(false)
    }
  }
  /* if (isSubmitting) {
    return (
      <div className="logo-container">
        <LoadingSpinner loading wheel />
      </div>
    )
  } */
  return (
    <React.Fragment>
      {isCropping ? (
        <div className={`logo-cropper ${fileConstExists ? '' : 'hidden'}`}>
          <Cropper
            ref={cropperData}
            src={userLogo}
            style={{ height: 180, width: '80%', margin: '0 auto' }}
            viewMode={1}
            aspectRatio={1}
            guides={true}
            modal={false}
          />
        </div>
      ) : (
        <div className="logo-container">
          <img className="logo-image" src={userLogo} alt="Store logo" />
        </div>
      )}
      {!isCropping ? (
        <button
          className="logo-button"
          type="button"
          onClick={() => {
            inputPicture.current.click()
          }}
        >
          + Upload Company Logo
        </button>
      ) : (
        <div className="logo-option-button-container">
          <button
            className="options-button"
            type="button"
            onClick={() => {
              setIsCropping(false)
              setIsSubmitting(true)
              cropperData.current.cropper.getCroppedCanvas().toBlob(blob => {
                imageUploadHandler(blob)
              })
            }}
          >
            Save
          </button>
          <button
            className="options-button"
            type="button"
            onClick={() => {
              setIsCropping(false)
              setUserLogo(formerLogo)
              setFormerLogo(null)
            }}
          >
            Cancel
          </button>
        </div>
      )}
      <input
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        ref={inputPicture}
        onChange={e => {
          setFormerLogo(userLogo)
          setImageToUpload(e.target.files[0])
          setIsCropping(true)
          setUserLogo(URL.createObjectURL(e.target.files[0]))
        }}
        onClick={event => {
          event.target.value = null
        }}
        style={{ display: 'none' }}
      />
    </React.Fragment>
  )
}
