import {
  ADD_SELECTED_COMPANIES,
  REMOVE_SELECTED_COMPANIES,
  ADD_ALL,
  REMOVE_ALL,
} from '../_constants/unsubscribeConstants'

const initState = {
  selectedCompanies: [],
}

export function unsubscribe(state, action) {
  if (!state) {
    state = initState
  }
  switch (action.type) {
    case ADD_SELECTED_COMPANIES:
      let newSelectedCompanies = [...state.selectedCompanies]
      if (newSelectedCompanies.includes(action.selectedCompany)) {
      } else {
        newSelectedCompanies.push(action.selectedCompany)
      }
      return {
        ...state,
        selectedCompanies: [...newSelectedCompanies],
      }
    case REMOVE_SELECTED_COMPANIES:
      newSelectedCompanies = [...state.selectedCompanies]
      if (newSelectedCompanies.includes(action.selectedCompany)) {
        const selectedCompanyIdx = newSelectedCompanies.indexOf(action.selectedCompany)
        newSelectedCompanies.splice(selectedCompanyIdx, 1)
      }
      return {
        ...state,
        selectedCompanies: [...newSelectedCompanies],
      }
    case ADD_ALL:
      return {
        ...state,
        selectedCompanies: [...action.selectedCompanies],
      }
    case REMOVE_ALL:
      return {
        ...state,
        selectedCompanies: [],
      }
    default:
      return state
  }
}
