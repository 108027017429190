// general: packet sent ss component.

// @flow
import React, { Component } from 'react'
// node packages
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share'
// --- redux
import { connect } from 'react-redux'
// core files
// --- actions
import PacketSendActions from '../../core/actions/packets/packetsendactions'
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// helpers
import LoadingSpinner from '../helpers/loadingspinner'
// css styles
import '../styles/universalstyles.scss'
import '../packetview/styles/packetsend.scss'

type Props = {
  userInfo: Object,
  stashed_content: Object,
  vehicle_stash_info: Object,
  vehicle_send_info: Object,
}

class SocialShareComponent extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      share_return_face: null,
      share_return_link: null,
      sent_success_link: false,
      sent_success_face: false,
      loading: false,
    }
  }

  state: {
    share_return_face: Object,
    share_return_link: Object,
    sent_success_link: boolean,
    sent_success_face: boolean,
    loading: boolean,
  }

  // componentDidMount
  // - set mount to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetSelections(this.state)
    }
  }

  // componentWillReceiveProps
  // - get stashd props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.onMountGetSelections(newProps)
  }

  // componentWillUnmount
  // - resets all values and states.
  componentWillUnmount() {
    this.mounted = false

    this.setState({
      userInfo: null,
      stashed_content: null,
      vehicle_stash_info: null,
      vehicle_send_info: null,

      share_return_face: null,
      share_return_link: null,

      sent_success_link: false,
      sent_success_face: false,

      loading: false,
    })
  }

  //
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetVehicle
  onMountGetSelections = () => {
    const {
      vehicle_stash_info,
      vehicle_send_info,
      userInfo,
      facebook,
      linkedin,
      vehicleId,
      showPrice,
      moduleIds,
    } = this.props
    if (vehicle_stash_info || vehicle_send_info) {
      const send_content = vehicle_send_info ? vehicle_send_info : vehicle_stash_info
      if (facebook) {
        this.SocialShareGetAPIData(2, vehicleId, showPrice, moduleIds)
      }
      if (linkedin) {
        this.SocialShareGetAPIData(1, vehicleId, showPrice, moduleIds)
      }
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*API***********************
  // SocialShareGetAPIData - 1
  SocialShareGetAPIData = (platform_id: number, vehicle_id: number, show_price, module_ids) => {
    this.setState({ loading: true })

    const params = {
      vehicle_id,
      message: this.props.stashed_content.message,
      show_price,
      module_ids,
      platform_id,
    }

    PacketSendActions.putPacketSocialShare(params, this.props.userInfo.dj_token)
      .then(json => {
        if (json.vdp_url) {
          if (platform_id === 1) {
            this.setState({ share_return_link: json, sent_success_link: true })
          } else if (platform_id === 2) {
            this.setState({ share_return_face: json, sent_success_face: true })
          }
        } else {
          console.log('Error')
        }
        this.setState({ loading: false })
      })
      .catch(error => {
        console.log(error.toString())
      })
  }

  // ActivateSShare - 3
  ActivateSShare(type: number) {
    console.log('----------')
    console.log('type: ', type === 1 ? 'LinkedIn' : 'Facebook')
    console.log(
      'share json: ',
      type === 1 ? this.state.share_return_link : this.state.share_return_face,
    )
    console.log(
      'share status: ',
      type === 1 ? this.state.sent_success_link : this.state.sent_success_face,
    )
    console.log('----------')

    if (type === 1) {
      PacketSendActions.postPacketSocialShare(
        this.state.share_return_link.vdp_url.split('.info/')[1],
        this.state.sent_success_link,
        this.props.userInfo.dj_token,
      )
    } else if (type === 2) {
      PacketSendActions.postPacketSocialShare(
        this.state.share_return_face.vdp_url.split('.info/')[1],
        this.state.sent_success_face,
        this.props.userInfo.dj_token,
      )
    }
  }
  // ***********************LETS*CALL*API***********************

  props: Props

  render() {
    // states
    console.log(this.state)
    const { loading, share_return_face, share_return_link } = this.state
    const { facebook, linkedin } = this.props
    if (share_return_face || share_return_link) {
      return (
        <div className="social-share-success-container">
          <b style={{ fontSize: '1.5em' }}>Social Share</b>

          <br />
          <br />
          <div className="flexRowSpace" style={{ justifyContent: 'space-around' }}>
            {facebook && share_return_face && (
              <React.Fragment>
                <FacebookShareButton
                  children={<FacebookIcon size={50} round={true} />}
                  url={share_return_face.vdp_url}
                  hashtag="#AutoiPacket"
                  beforeOnClick={() => {
                    this.ActivateSShare(2)
                  }}
                  className="packetSendMiddleImageMiniContainerStyle"
                />
              </React.Fragment>
            )}

            {linkedin && share_return_link && (
              <React.Fragment>
                <LinkedinShareButton
                  children={<LinkedinIcon size={50} round={true} />}
                  url={share_return_link.vdp_url}
                  title="AutoiPacket Packet Share"
                  beforeOnClick={() => {
                    this.ActivateSShare(1)
                  }}
                  className="packetSendMiddleImageMiniContainerStyle"
                />
              </React.Fragment>
            )}
          </div>
        </div>
      )
    }

    return <LoadingSpinner loading={loading} wheel />
  }
}

const mapStateToProps = state => ({
  vehicle_stash_info: state.packetstash.vehicle_info,
  vehicle_send_info: state.inventory.activeSingleSend,
  stashed_content: state.packetstash,
})
const SocialShare = UserInfoWrapper(SocialShareComponent)
export default connect(mapStateToProps, {})(SocialShare)
