import React from 'react'

const ArrowUpIcon = props => {
  return (
    <svg className="arrow-up-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <polygon points="49.78 33.87 42.65 41 25.08 23.43 7.51 41 0.22 33.71 17.79 16.14 17.78 16.13 24.91 9 24.92 9.01 24.93 9 32.22 16.29 32.21 16.3 49.78 33.87" />
    </svg>
  )
}
export default ArrowUpIcon
