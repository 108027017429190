// general: 1/3 of the tools component - parent for tools - (1st).

// TODO:
// 1. mobile support
// 2. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

// core files
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// components
import DocUpload from './components/documentupload'
import Tables from './components/financetables'
// css styles
import '../styles/universalstyles.scss'

type Props = {
  userInfo: Object,
}

export class ToolsComponent extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      userInfo: this.props.userInfo,
    }
  }

  state: {
    userInfo: Object,
  }

  componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.
    this.setState({ userInfo: newProps.userInfo })
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    this.setState({ userInfo: {} })
  }

  // ***********************LETS*SETUP*CONTAINERS***********************
  // ToolsViewColleciton
  ToolsViewColleciton() {
    return (
      <div className="subnav-bar">
        <div className="subnav-content">
          <div className="subnav-center">
            <NavLink to="/tools/document-upload">Document Upload</NavLink>
            <NavLink to="/tools/finance-tables">Finance Tables</NavLink>
          </div>
        </div>
      </div>
    )
  }

  // ToolsViewSwitcher
  ToolsViewSwitcher() {
    // states
    const { userInfo } = this.state

    // get page focus
    // eslint-disable-next-line no-undef
    const pageOn = window.location.href.split('tools/')[1]

    if (userInfo !== null && userInfo !== undefined) {
      return (
        <span>
          {pageOn === 'document-upload' ? (
            <DocUpload DJtoken={userInfo.dj_token} storeId={userInfo.default_store_id} />
          ) : null}
          {pageOn === 'finance-tables' ? (
            <Tables
              userId={userInfo.user_id}
              DJtoken={userInfo.dj_token}
              storeId={userInfo.default_store_id}
              isAdmin={userInfo.is_admin}
              storeName={userInfo.store_full_name}
            />
          ) : null}
        </span>
      )
    }
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    const { defaultStoreId } = this.props
    return (
      <div style={{ animation: ` 1500ms fadeIn` }} className="tools-normal">
        {this.ToolsViewColleciton()}
        {defaultStoreId && this.ToolsViewSwitcher()}
      </div>
    )
  }
}

const Tools = UserInfoWrapper(ToolsComponent)

const mapStateToProps = state => ({
  defaultStoreId: state.authentication.user.user.profile.default_company_store,
})
export default connect(mapStateToProps, null)(Tools)

// NOTE: <NavLink to="/tools/finance-tables">Finance Tables</NavLink> (retired 05/06/2019)
