import React, { Component, useState } from 'react'

// node packages
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import LazyLoad from 'react-lazy-load'
import ReactToPrint from 'react-to-print'

import { connect, useSelector } from 'react-redux'
import { EmptyPacketAllConst } from '../../../core/_actions/emptystoreActions'

import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'

// core files
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
// - icons
import AddCartIcon from '../assets/addcarticon.js'
import RemoveCartIcon from '../assets/removecarticon.js'
import CartIcon from '../assets/carticon.js'
import SendIcon from '../../../core/assets/images/icons/send-icon.js'
import CartIconW from '../assets/carticonw.js'
import SearchIcon from '../assets/searchicon.js'
import IconLocation from '../../../core/assets/images/icons/icon-location.js'
import PriceHistory from '../../../core/assets/images/icons/pricehistorybtn.js'
import IconHistory from '../../../core/assets/images/icons/activity-btn.js'
import ArchiveIcon from '../../../core/assets/images/icons/archive-icon.js'
import QRIcon from '../../../core/assets/images/icons/qr-icon.js'
import IPacketRingLogo from '../../../core/assets/images/icons/ipacketringlogo.js'
// components
// import QRCodePrint from '../../../content/packetview/columns/components/qrcodeprint'

//components
import VehicleSlideshow from './children/vehicleslideshow'
import { data } from 'jquery'

class Car extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayFallback: false,
    }
  }

  mistake = message => {
    if (!toast.isActive(this.mistakeToastId) || this.mistakeToastMessage !== message) {
      this.mistakeToastId = toast(message)
      this.mistakeToastMessage = message
    }
  }

  vehicleImage = () => {
    const { viewPacket, vehicle, userInfo, miniMode } = this.props
    const { displayFallback } = this.state
    let imageOverlay = null
    if (this.props.vehicle.archived && !this.props.vehicle.inside_archived_plan) {
      imageOverlay = (
        <div className="view-packet-overlay-container">
          <div className="view-packet-overlay">
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (this.props.unlockIntercomMessenger) {
                  this.props.unlockIntercomMessenger()
                }
              }}
            >
              Request Info!&nbsp;
            </span>
          </div>
        </div>
      )
    } else {
      imageOverlay = (
        <div className="view-packet-overlay-container">
          <div className="view-packet-overlay">
            <span>View{vehicle.archived ? ` Archive` : ` Packet`}&nbsp;</span>
            {vehicle.archived ? <ArchiveIcon color="#1cd760" /> : <SearchIcon color="#1cd760" />}
          </div>
        </div>
      )
    }
    let image = (
      <div
        className={`car-img-wrapper${
          vehicle.archived || miniMode ? '' : ' car-img-wrapper-slideshow-active'
        }`}
      >
        {vehicle.archived && !vehicle.inside_archived_plan ? (
          <div className="car-img-archived">
            <ArchiveIcon />
          </div>
        ) : (
          <div className="img-holder">
            {displayFallback ? (
              <div className="car-img-fallback">
                <IPacketRingLogo />
              </div>
            ) : (
              <LazyLoad debounce={false} throttle={125} threshold={200}>
                <img
                  className="mainImage"
                  alt={vehicle.make + vehicle.model}
                  /*src={'https://picsum.photos/350/300/?random'} */
                  src={vehicle.thumbnail_url + `?nocache='${Date.now()}'`}
                  onError={() => {
                    this.setState({ displayFallback: true })
                  }}
                />
              </LazyLoad>
            )}

            {!miniMode && !vehicle.archived && (
              <div className="car-slideshow-wrapper">
                <VehicleSlideshow
                  userInfo={userInfo}
                  vehicleID={vehicle.vehicle_id}
                ></VehicleSlideshow>
              </div>
            )}
          </div>
        )}
        {viewPacket && imageOverlay}
      </div>
    )

    if (
      viewPacket &&
      ((this.props.vehicle.archived && this.props.vehicle.inside_archived_plan) ||
        !this.props.vehicle.archived)
    ) {
      image = (
        <Link
          className="car-left-section"
          to={{
            pathname: `/packet/view/${this.props.vehicle.vehicle_id}`,
            state: this.props.vehicle.vehicle_id,
          }}
        >
          {image}
        </Link>
      )
    } else if (this.props.vehicle.archived && !this.props.vehicle.inside_archived_plan) {
      image = (
        <div
          onClick={() => {
            if (this.props.unlockIntercomMessenger) {
              this.props.unlockIntercomMessenger()
            }
          }}
          style={{ cursor: 'pointer' }}
          className="car-left-section"
        >
          {image}
        </div>
      )
    } else {
      image = <div className="car-left-section"> {image} </div>
    }
    return image
  }

  vehicleName = () => {
    const { viewPacket, vehicle } = this.props

    let name = (
      <React.Fragment>
        <h1 id="vehicle_title">{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</h1>
        {/* {console.log('HERE CHECK', vehicle)} */}
        <h2 className={`${vehicle.cpo !== 'N' ? 'cpo-highlight' : ''}`}>
          {vehicle.vehicle_condition_label ? vehicle.vehicle_condition_label : null}
        </h2>
      </React.Fragment>
    )
    if (
      (viewPacket && !this.props.vehicle.archived) ||
      (this.props.vehicle.archived && this.props.vehicle.inside_archived_plan)
    ) {
      name = (
        <Link
          onClick={() => {
            if (!this.props.vehicle.archived) {
              this.props.viewPacket(vehicle)
            }
          }}
          to={{
            pathname: `/packet/view/${this.props.vehicle.vehicle_id}`,
            state: this.props.vehicle.vehicle_id,
          }}
        >
          {name}
        </Link>
      )
    }
    return name
  }
  render() {
    const {
      vehicle,
      isPrivate,
      miniMode,
      animationDelay,
      sendDisabled,
      addCartDisabled,
      basket,
      animation,
    } = this.props
    const inDate = new Date(this.props.vehicle.veh_in_date)
    const currentDate = moment()
    let daysSinceLastPriceChange = null
    if (this.props.vehicle.last_price_change) {
      const lastPriceChangeDate = moment(this.props.vehicle.last_price_change)
      daysSinceLastPriceChange = currentDate.diff(lastPriceChangeDate, 'days')
    }

    // TODO - one is subtracted from price_history_count beacuse the endpoint returns at least one inital price
    const vehiclePriceCount = this.props.vehicle.price_history_count - 1
    const ageDays = Math.floor((Date.now() - inDate.valueOf()) / 86400000)
    const trimSuffix =
      vehicle.trim != undefined && vehicle.trim.length > 0 ? ' ' + vehicle.trim : ''
    return (
      <div
        className={`car-container-${this.props.vehicle.vehicle_id} ${
          this.props.vehicle.archived && !this.props.vehicle.inside_archived_plan
            ? 'car-container-archived-noaccess'
            : ''
        }`}
        style={{ animation: ` 200ms ${animationDelay}ms ${animation ? animation : 'slideInUp'}` }}
      >
        <div className="car-info">
          {this.vehicleImage()}
          <div className="car-center-section">
            {this.vehicleName()}
            <span className="span-vin">
              <b>VIN:</b>&nbsp;{`${this.props.vehicle.vin}   `}
            </span>
            <span className="span-stock">
              <b>STOCK#:</b>&nbsp;{this.props.vehicle.stock}
            </span>
            <br />
            {this.props.vehicle.miles !== null && this.props.vehicle.miles !== undefined ? (
              <React.Fragment>
                {' '}
                <span id="miles-text">{`${
                  this.props.vehicle.distance_unit.label
                    ? this.props.vehicle.distance_unit.label
                    : 'Miles'
                }: ${this.props.vehicle.miles.toLocaleString()}`}</span>{' '}
                <br />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            {!miniMode && trimSuffix ? (
              <div>
                <b>Trim:</b> {trimSuffix} <br />
              </div>
            ) : null}
            {!miniMode && this.props.vehicle.engine_description ? (
              <div>
                <b>Engine:</b> {this.props.vehicle.engine_description} <br />
              </div>
            ) : null}
            {!miniMode && this.props.vehicle.color ? (
              <div>
                <b>Exterior:</b> {this.props.vehicle.color} <br />
              </div>
            ) : null}
            {!miniMode && this.props.vehicle.interior_color ? (
              <div>
                <b>Interior:</b> {this.props.vehicle.interior_color} <br />
              </div>
            ) : null}
            {/*<b>Drivetrain:</b> <br />*/}
            {!miniMode && this.props.vehicle.transmission_description ? (
              <div>
                <b>Drivetrain:</b> {this.props.vehicle.drive_train} <br />
              </div>
            ) : null}

            {!miniMode && this.props.vehicle.inventory_type ? (
              <div>
                <b>Inventory Type:</b> {this.props.vehicle.inventory_type} <br />
              </div>
            ) : null}
            {this.props.vehicle.archived && !this.props.vehicle.inside_archived_plan && (
              <div className="car-archived-packet-plan">
                Oh no! {this.props.vehicle.company_store.store_full_name} is subscribed to the{' '}
                <b>{this.props.vehicle.archived_packet_plan} day</b>, Archived Packet plan.
                <br />
                Don't Worry! You can chat with your Digital Success Manager to request a quote for
                an upgrade!
                <br />
                <span
                  onClick={() => {
                    if (this.props.unlockIntercomMessenger) {
                      this.props.unlockIntercomMessenger()
                    }
                  }}
                >
                  More Info
                </span>
              </div>
            )}
          </div>
          <div className="car-right-section">
            <div className="car-right-section-text">
              <h1>
                {this.props.priceHistory &&
                  ((this.props.vehicle.archived && this.props.vehicle.inside_archived_plan) ||
                    !this.props.vehicle.archived) &&
                  !isPrivate && (
                    <button
                      style={vehiclePriceCount > 0 ? { display: 'inline' } : { display: 'none' }}
                      className="price-history-vlp"
                      onClick={() => {
                        this.props.priceHistory({
                          vehicle_id: this.props.vehicle.vehicle_id,
                          vin: this.props.vehicle.vin,
                          stock_num: this.props.vehicle.stock,
                          currency_unit: this.props.vehicle.currency_unit,
                        })
                      }}
                    >
                      <PriceHistory />
                      <div className="vehicle-history-count">{vehiclePriceCount}</div>
                    </button>
                  )}
                {parseInt(vehicle.price) > 0 ? (
                  <React.Fragment>
                    <sup className="dollarSup">
                      {this.props.vehicle.currency_unit.symbol
                        ? this.props.vehicle.currency_unit.symbol
                        : '$'}
                    </sup>
                    {`${new Intl.NumberFormat('en-US', { style: 'decimal' }).format(
                      vehicle.price,
                    )}`}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {!vehicle.archived && <React.Fragment>Please Call</React.Fragment>}
                  </React.Fragment>
                )}
                {/*this.props.packetActivity && !miniMode && !isPrivate && (
              <div
                className="car-activity-vlp"
                onClick={() => {
                  this.props.packetActivity(this.props.vehicle)
                }}
              >
                <IconHistory />
              </div>
              )*/}
                {!miniMode ? <br /> : null}
              </h1>
              {!miniMode && !isPrivate && daysSinceLastPriceChange ? (
                <React.Fragment>
                  <span className="car-last-price-change">
                    <i>{`Days Since Last Price Change - `}</i>
                    <b>
                      <span>{`${daysSinceLastPriceChange}`}</span>
                    </b>
                  </span>
                  <br />
                </React.Fragment>
              ) : null}
              {!miniMode ? <IconLocation /> : null}

              {!miniMode ? (
                <span className="text-location">
                  Located at <br />
                  <b className="vlp-location-value">
                    {this.props.vehicle.company_store &&
                      this.props.vehicle.company_store.store_full_name}
                  </b>
                  <b>{this.props.vehicle.location}</b> <br />
                  {!isPrivate && !this.props.vehicle.archived && (
                    <i>
                      {this.props.vehicle.veh_in_date
                        ? differenceInDays(new Date(), parseISO(this.props.vehicle.veh_in_date))
                        : 0}{' '}
                      days in inventory
                    </i>
                  )}
                  {!isPrivate && this.props.vehicle.archived && this.props.vehicle.archived_ts ? (
                    <React.Fragment>
                      <i>
                        {differenceInDays(new Date(), parseISO(this.props.vehicle.archived_ts))}{' '}
                        days archived
                      </i>
                    </React.Fragment>
                  ) : null}
                </span>
              ) : null}
              {!miniMode && this.props.vehicle.archived ? (
                <React.Fragment>
                  <br />
                  <span className="text-location">
                    <b>{`First Seen - `}</b>
                    {`${moment(this.props.vehicle.veh_in_date).format('MMMM Do YYYY')}`}
                  </span>
                </React.Fragment>
              ) : null}
            </div>
            {false ? (
              <div className="qrInventoryHolder">
                <ReactToPrint
                  trigger={() => {
                    return (
                      <div className="generateQR">
                        <span>
                          <QRIcon />
                          Default
                        </span>
                      </div>
                    )
                  }}
                  content={() => this.printDefaultQRCodeRef}
                />
                <ReactToPrint
                  trigger={() => {
                    return (
                      <div className="simpleQR">
                        <span>
                          <QRIcon />
                          Simple
                        </span>
                      </div>
                    )
                  }}
                  content={() => this.printSimpleQRCodeRef}
                />
                <ReactToPrint
                  trigger={() => {
                    return (
                      <div className="simpleQR">
                        <span>
                          <QRIcon />
                          Window Sticker
                        </span>
                      </div>
                    )
                  }}
                  content={() => this.printWindowStickerQRCodeRef}
                />

                <div style={{ display: 'none' }}>
                  <QRCodePrint
                    type="default"
                    vehicle={this.props.vehicle}
                    ref={el => (this.printDefaultQRCodeRef = el)}
                  />
                </div>
                <div style={{ display: 'none' }}>
                  <QRCodePrint
                    type="simple"
                    vehicle={this.props.vehicle}
                    ref={el => (this.printSimpleQRCodeRef = el)}
                  />
                </div>
                <div style={{ display: 'none' }}>
                  <QRCodePrint
                    type="window sticker"
                    vehicle={this.props.vehicle}
                    ref={el => (this.printWindowStickerQRCodeRef = el)}
                  />
                </div>
              </div>
            ) : null}

            {this.props.updateBasket && !this.props.vehicle.archived && (
              <div
                onClick={() => {
                  if (!this.props.inBasket && this.props.addCartDisabled) {
                    this.mistake('Cart is Full (3/3)')
                  } else {
                    this.props.updateBasket(this.props.vehicle)
                  }
                }}
                className={[
                  `${this.props.inBasket ? 'remove' : 'add'}-packet-button`,
                  'update-packet-button',
                  'packet-button',
                  `${!this.props.inBasket && this.props.addCartDisabled ? 'disabled' : ''}`,
                ].join(' ')}
              >
                <span id="cartIcon">
                  <CartIconW />
                  {this.props.inBasket
                    ? 'REMOVE FROM CART'
                    : `${this.props.addCartDisabled ? 'Cart Full (3/3)' : 'ADD TO CART'}`}
                </span>
              </div>
            )}
            {/* {!miniMode ? <br /> : null} */}
            {this.props.sendPacket && !this.props.vehicle.archived && (
              <div
                className={`car-send-packet packet-button ${sendDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!sendDisabled) {
                    this.props.sendPacket(this.props.vehicle)
                  }
                }}
                title={`${sendDisabled ? 'Please Use Cart Send' : 'Send Packet'}`}
              >
                <span>
                  <SendIcon /> SEND PACKET
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Car
