import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from '../../layouts/modal'

import '../styles/facetoptionsmodal.scss'
import { updateFacet } from '../../../core/actions/inventoryactions.js'
import Collapsible from 'react-collapsible'

class FacetOptionsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedField: 0,
    }
  }
  componentDidMount() {
    const { selectedField, activeFacetOptions } = this.props
    if (selectedField && selectedField >= 0 && selectedField < activeFacetOptions.length) {
      this.setState({ selectedField })
    }
  }
  updateCheckField = (facetIndex, optionIndex) => {
    let {
      searchQuery,
      facetFields,
      activeFacetFields,
      facetOptions,
      activeFacetOptions,
      updateFacetConnect,
      user_id,
      token,
      sortBy,
    } = this.props
    updateFacetConnect({
      facetIndex,
      optionIndex,
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery,
    })
  }
  renderFields = () => {
    const { activeFacetOptions } = this.props
    const { selectedField } = this.state
    return (
      <div className="modal-facet-fields">
        {activeFacetOptions.map((facet, facetIndex) => (
          <React.Fragment key={facet.facet_name + facetIndex}>
            {facet.facet_options.length > 0 && (
              <div
                className={`modal-facet-field ${
                  facetIndex === selectedField ? 'modal-facet-field-selected' : ''
                }`}
                onClick={() => {
                  this.setState({ selectedField: facetIndex })
                }}
                key={facet.facet_name + facetIndex}
              >
                {facet.facet_name}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  }
  renderFacetOptions = () => {
    const { activeFacetOptions, facetFields } = this.props
    const { selectedField } = this.state
    return (
      <div className="modal-facet-options">
        {activeFacetOptions[selectedField].facet_options.map((option, optionIndex) => (
          <div className="modal-facet-option" key={option.name}>
            <input
              type="checkbox"
              checked={facetFields[selectedField].includes(optionIndex)}
              title={`${option.name} (${option.values.length})`}
              onChange={() => {
                this.updateCheckField(selectedField, optionIndex)
              }}
            />
            {`${option.name} (${option.values.length})`}
          </div>
        ))}
      </div>
    )
  }
  render() {
    return (
      <Modal>
        <div className="facet-options-modal">
          {this.renderFields()}
          {this.renderFacetOptions()}
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  activeFacetOptions: state.inventory.activeFacetOptions,
  user_id:
    state.authentication.user && state.authentication.user.user.profile.legacy_user.legacy_user_id,
  token: state.authentication.user && state.authentication.user.token,
  results: state.inventory.inventoryContent && state.inventory.inventoryContent.count,
  isLoading: state.inventory.isLoading,
  sortBy: state.inventory.sortBy,
  facetCheckIn: state.inventory.checkIn.GET_FACET_OPTIONS,
  viewMyInventory: state.inventory.viewMyInventory,
  searchQuery: state.inventory.searchQuery,
})

export default connect(mapStateToProps, {
  updateFacetConnect: updateFacet,
})(FacetOptionsModal)
