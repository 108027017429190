// general: 1/4 of the reports component - child of reports - (4th).

// TODO:
// 1. mobile support
// 2. cross browser check support

// @flow
import React, { Component } from 'react'
// date-fns
import format from 'date-fns/format'
// core files
import ProfileActions from '../../../../core/actions/users/profileactions'
import ReportsActions from '../../../../core/actions/features/reportsactions'
// images
import DownloadBoxIcon from '../../../../core/assets/images/icons/download-box_icon.js'

// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/reports.scss'
// helpers
import Helper from '../../../../core/helpers/platformhelper'

type Props = {
  DJtoken: string, // token to send to get the data.
  userId: number,
}

export default class UserUploadReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeList: [],
    }
  }

  state: {
    storeList: Object,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetStoreList()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    // reset the states on unmount
    this.setState({ storeList: [] })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetStoreList
  onMountGetStoreList() {
    // props
    const { userId, DJtoken } = this.props

    if (userId !== null && userId !== undefined) {
      ProfileActions.getUserStoreGroups(userId, DJtoken)
        .then(json => {
          if (json && json.length > 0) {
            this.setState({ storeList: json })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*DOWNLOAD***********************
  downloadReportAsFile(storeType, storeName, data) {
    const newUsed = storeType == 1 ? 'New' : 'Preowned'
    const filename =
      storeName.replace(/\s/g, '') + newUsed + '-' + format(new Date(), 'yyyymmdd') + '.csv'
    // Internet Explorer case
    if (Helper.isExplorer()) {
      var fileForIE = new Blob(data.split('\n'), { type: 'text/plain;charset=utf-8' })
      window.navigator.msSaveBlob(fileForIE, filename)
    } else {
      // eslint-disable-next-line no-undef
      const element = document.createElement('a')

      // eslint-disable-next-line prefer-template
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data))
      element.setAttribute('download', filename)

      element.style.display = 'none'
      // eslint-disable-next-line no-undef
      document.body.appendChild(element)
      element.click()

      // eslint-disable-next-line no-undef
      document.body.removeChild(element)
    }
  }
  // ***********************LETS*CALL*DOWNLOAD***********************

  props: Props

  render() {
    // states
    const { storeList } = this.state

    // arrays
    const array = []

    // counters
    let counter = 0

    if (storeList && storeList.length > 0) {
      for (const entry of storeList) {
        array.push(
          <div key={counter++} className="flexRowSpace" style={{ width: '100%' }}>
            <span>
              {counter}&nbsp;.&nbsp;
              <b>{`${entry.store_type === 1 ? 'New' : 'Pre-Owned'} ${entry.company_store_name}`}</b>
            </span>

            <button
              className="csv_dl_btn"
              onClick={() => {
                ReportsActions.getReportsUpload(this.props.DJtoken, entry.company_store_id)
                  .then(json => {
                    if (json !== null && json !== undefined) {
                      this.downloadReportAsFile(
                        entry.company_store_type,
                        entry.company_store_name,
                        json,
                      )
                    }
                  })
                  .catch(error => {
                    console.log(error.toString())
                  })
              }}
            >
              <DownloadBoxIcon />
            </button>
          </div>,
        )
      }
    }

    return (
      <div align="center">
        <div className="flexColumn" id="upload_box">
          <div className="flexRowSpace" style={{ width: '100%' }}>
            <h4>Store</h4>
            <h4>CSV Download</h4>
          </div>
          {array}
        </div>
      </div>
    )
  }
}
