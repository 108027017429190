// general: exports constant values.

// PACKET STASH CONSTANTS
export const STASH_VEHICLE_IDS = 'STASH_VEHICLE_IDS'
export const STASH_VEHICLE_IMAGES = 'STASH_VEHICLE_IMAGES'
export const STASH_RECIPIENTS = 'STASH_RECIPIENTS'
export const STASH_MESSAGE = 'STASH_MESSAGE'
export const STASH_MODULES = 'STASH_MODULES'
export const STASH_PRICE = 'STASH_PRICE'
export const STASH_ATTACHMENTS = 'STASH_ATTACHMENTS'
export const STASH_LEADS = 'STASH_LEADS'
export const STASH_SINGLE_MODULE = 'STASH_SINGLE_MODULE'
export const STASH_DEFAULT_STORE_ID = 'STASH_DEFAULT_STORE_ID'
export const STASH_MODULE_VIDEOS = 'STASH_MODULE_VIDEOS'
export const STASH_SCHEDULED_PACKETS = 'STASH_SCHEDULED_PACKETS'
export const STASH_PREVIOUS_ATTACHMENTS = 'STASH_PREVIOUS_ATTACHMENTS'
export const STASH_IMG_SRC = 'STASH_IMG_SRC'
export const EMPTY_PREVIOUS_ATTACHMENTS = 'EMPTY_PREVIOUS_ATTACHMENTS'
export const STASH_COVIDEO = 'STASH_COVIDEO'
export const EMPTY_COVIDEO = 'EMPTY_COVIDEO'

// PACKET CUSTOMER CREATE
export const IS_CREATING_CUSTOMER = 'IS_CREATING_CUSTOMER'
export const UPDATE_CUSTOMERS_TO_SEND = 'UPDATE_CUSTOMERS_TO_SEND'

// PACKET VIEW CONSTANTS
export const PACKET_VIEW_TYPE = 'PACKET_VIEW_TYPE'

// OTHER PACKET THINGS
export const STASH_DELETE_MODAL = 'STASH_DELETE_MODAL'
