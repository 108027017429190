// general:
// https://github.com/reduxjs/redux/issues/1585

// redux
// --- functions
import {
  stashVehicles,
  stashRecipients,
  stashMessage,
  stashModules,
  stashPrice,
  stashAttachments,
  stashLeads,
  stashVehicleImages,
  stashSingleModules,
  toggleCreatingCustomer,
  stashAdminCompanyStore,
  stashModuleVideos,
  deleteModalAction,
  stashCovideo,
} from './packetstashActions'
import { packetViewActive } from './packetviewActions'
import { showModal, hideModal } from './modalActions'
// --- constants
import { NO_MODAL } from '../_constants/modalTypes'
// --- stores
import { store } from '../../../index'

// EMPTY STORES
export const EmptyAllStashConst = () => {
  // --- packet stashes
  EmptyPacketStash()
  // --- packet view types
  EmptyPacketViews()
  // --- packet default company store
  EmptyStashAdminCompanyStoreConst()
  // --- modals
  EmptyShowModalConst()
  EmptyHideModalConst()
}

export const EmptySelectStores = (type: string) => {
  if (type === 'packet_stash') {
    EmptyPacketStash()
  } else if (type === 'packet_view_types') {
    EmptyPacketViews()
  } else if (type === 'packet_company_store') {
    EmptyStashAdminCompanyStoreConst()
  } else if (type === 'modals') {
    EmptyShowModalConst()
    EmptyHideModalConst()
  }
}

export const EmptyPacketAllConst = () => {
  // --- packet stashes
  EmptyPacketStash()
  // --- packet view types
  EmptyPacketViews()
}

//
//
//
// EMPTY - PACKETS - STASH - ALL
const EmptyPacketStash = () => {
  EmptyStashVehiclesConst()
  EmptyStashRecipientsConst()
  EmptyStashMessageConst()
  EmptyStashModulesConst()
  EmptyStashPriceConst()
  EmptyStashAttachmentsConst()
  EmptyStashLeadsConst()
  EmptyStashVehicleImagesConst()
  EmptyStashSingleModulesConst()
  EmptyToggleCreatingCustomerConst()
  EmptyStashModuleVideosConst()
  EmptyDeleteModalActionConst()
  EmptyCovideoConst()
}

//
// EMPTY - PACKETS - STASH - IND.
const EmptyStashVehiclesConst = () => {
  store.dispatch(stashVehicles([], null))
}

const EmptyStashRecipientsConst = () => {
  store.dispatch(stashRecipients([], []))
}

const EmptyStashMessageConst = () => {
  store.dispatch(stashMessage(''))
}

const EmptyStashModulesConst = () => {
  store.dispatch(stashModules([], []))
}

const EmptyStashPriceConst = () => {
  store.dispatch(stashPrice(true))
}

const EmptyStashAttachmentsConst = () => {
  store.dispatch(stashAttachments([]))
}

const EmptyStashLeadsConst = () => {
  store.dispatch(stashLeads('none'))
}

const EmptyStashVehicleImagesConst = () => {
  store.dispatch(stashVehicleImages([]))
}

const EmptyStashSingleModulesConst = () => {
  store.dispatch(stashSingleModules(null))
}

const EmptyToggleCreatingCustomerConst = () => {
  store.dispatch(toggleCreatingCustomer(null))
}

const EmptyStashModuleVideosConst = () => {
  store.dispatch(stashModuleVideos([]))
}

export const EmptyDeleteModalActionConst = () => {
  store.dispatch(deleteModalAction(false))
}

export const EmptyCovideoConst = () => {
  store.dispatch(stashCovideo(null))
}

//
//
//
// EMPTY - PACKET DEFAULT COMP STORE
export const EmptyStashAdminCompanyStoreConst = () => {
  store.dispatch(stashAdminCompanyStore(null))
}

//
//
//
// EMPTY - PACKET VIEW TYPE
export const EmptyPacketViews = () => {
  store.dispatch(packetViewActive('general_info'))
}

//
//
//
// EMPTY - MODALS
const EmptyShowModalConst = () => {
  store.dispatch(showModal(NO_MODAL, {}))
}

const EmptyHideModalConst = () => {
  store.dispatch(hideModal(NO_MODAL, {}))
}
