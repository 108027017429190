import React from 'react'

const PlayIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,85A35,35,0,1,1,85,50,35,35,0,0,1,50,85Z" />
      <path d="M75.26,54.06l-36.46,21a4.68,4.68,0,0,1-7-4.06V29a4.68,4.68,0,0,1,7-4.06l36.46,21A4.68,4.68,0,0,1,75.26,54.06Z" />
    </svg>
  )
}
export default PlayIcon
