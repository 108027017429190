import React from 'react'

const IPacketIcon = () => {
  return (
    <svg
      width="406"
      height="105"
      viewBox="0 0 406 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M399.673 33.3316V33.2444C400.577 32.9704 401.204 32.3476 401.204 31.538C401.204 30.8156 400.891 30.2426 400.476 29.9188C399.937 29.6074 399.297 29.3832 397.904 29.3832C396.687 29.3832 395.733 29.4704 395.056 29.6074V36.9562H396.775V34.0042H397.59C398.544 34.0042 398.996 34.3654 399.121 35.175C399.347 36.022 399.485 36.6946 399.711 36.9687H401.568C401.392 36.6946 401.254 36.2462 401.029 35.1252C400.803 34.1412 400.401 33.6056 399.673 33.3316ZM397.641 32.796H396.825V30.691C397.001 30.6412 397.327 30.6038 397.779 30.6038C398.858 30.6038 399.36 31.0522 399.36 31.7248C399.36 32.4846 398.594 32.796 397.641 32.796Z" />
      <path d="M397.954 25.7588C393.713 25.7588 390.325 29.0346 390.325 33.1076C390.325 37.2803 393.713 40.5561 397.954 40.5561C402.246 40.5561 405.546 37.2803 405.546 33.1076C405.546 29.0222 402.246 25.7588 397.954 25.7588ZM398.005 38.9867C394.667 38.9867 392.22 36.3835 392.22 33.1076C392.22 29.8816 394.654 27.2286 397.954 27.2286C401.255 27.2286 403.651 29.8692 403.651 33.145C403.651 36.3835 401.255 38.9867 398.005 38.9867Z" />
      <path d="M226.986 44.3425C219.771 41.104 208.992 43.0098 203.019 48.6771C204.362 50.2714 205.717 51.8782 206.621 52.9618C209.921 51.6042 212.619 50.0223 215.542 49.3746C222.958 47.7304 227.915 51.6166 227.463 58.5918C225.468 58.5918 223.448 58.4921 221.44 58.6167C216.182 58.9281 210.912 59.3267 206.219 62.042C198.853 66.3018 198.828 76.6276 206.081 81.0618C210.95 84.0387 221.453 84.7113 226.723 78.197C226.986 77.8731 227.338 77.624 227.827 77.1632C228.003 78.5956 228.04 79.7913 228.341 80.9248C228.467 81.423 229.094 82.1205 229.533 82.1454C231.453 82.2824 233.398 82.2077 234.992 82.2077C234.992 73.3642 235.193 64.7449 234.917 56.1505C234.741 50.7945 232.068 46.6343 226.986 44.3425ZM213.422 76.8518C211.088 76.2788 209.281 75.0208 208.817 72.5172C208.29 69.6898 209.143 67.7716 211.816 66.4264C213.447 65.5919 215.304 64.9815 217.111 64.7324C220.499 64.2591 223.937 64.1345 227.501 63.8481C228.266 73.3019 221.954 78.9194 213.422 76.8518Z" />
      <path d="M143.015 82.0085C145.587 82.0085 148.009 82.0085 150.356 82.0085C150.356 69.2041 150.356 56.5989 150.356 43.944C147.846 43.944 145.537 43.944 143.015 43.944C143.015 56.7858 143.015 69.4033 143.015 82.0085Z" />
      <path d="M146.767 25.2356C143.705 25.1733 141.597 27.1413 141.597 30.0933C141.597 32.9581 143.567 34.8887 146.553 34.9136C149.74 34.9385 151.773 33.0577 151.748 30.1057C151.723 27.2534 149.728 25.2978 146.767 25.2356Z" />
      <path d="M189.832 26.4436C188.263 25.9578 186.619 25.5094 185.001 25.472C177.572 25.2852 170.144 25.2478 162.703 25.1731C162.59 25.1731 162.464 25.3101 162.251 25.4596C162.251 44.2801 162.251 63.1379 162.251 82.1079C164.836 82.1079 167.295 82.1079 170.181 82.1079C170.181 73.7253 170.181 65.5045 170.181 57.1841C173.82 57.1841 177.221 57.4083 180.584 57.1219C183.922 56.8354 187.347 56.4119 190.497 55.3656C197.536 53.0115 200.548 47.9047 200.334 39.6839C200.159 33.2942 196.382 28.4738 189.832 26.4436ZM186.18 49.1752C181.023 50.9065 175.677 50.3086 170.232 50.3834C170.232 44.3673 170.232 38.6875 170.232 32.5219H182.88C183.771 32.6216 184.674 32.7212 185.553 32.8582C189.631 33.5059 191.99 36.6821 192.115 40.3192C192.291 44.99 190.547 47.7178 186.18 49.1752Z" />
      <path d="M272.549 73.6507C266.037 79.318 256.174 77.1881 252.974 69.204C252.033 66.8748 251.707 64.1346 251.769 61.6061C251.958 54.2697 257.868 48.9636 264.995 49.3746C268.333 49.5614 270.955 51.1558 273.176 53.6345C274.983 52.1647 276.728 50.7697 278.56 49.275C274.519 43.6076 265.008 41.2909 257.027 43.8443C248.482 46.5845 243.5 53.7715 243.538 63.3C243.575 72.43 248.783 79.6543 257.152 82.1953C265.459 84.7238 274.783 82.5316 278.622 77.0636C276.978 75.6062 275.322 74.124 273.628 72.6169C273.189 73.0404 272.875 73.3518 272.549 73.6507Z" />
      <path d="M336.758 43.2838C323.708 45.9244 318.387 59.0277 322.39 70.5741C325.69 80.1027 334.85 84.5868 345.893 82.8679C350.912 82.0832 355.003 79.592 358.215 75.2824C356.333 73.7877 354.601 72.4176 352.882 71.0599C347.173 76.8767 341.325 78.3091 335.628 75.3945C331.538 73.3019 328.852 68.9923 329.467 65.3054C334.461 65.3054 339.481 65.3054 344.5 65.3054C349.507 65.3054 354.526 65.3054 359.771 65.3054C359.771 63.7982 359.809 62.5527 359.771 61.3071C359.382 48.3906 349.544 40.6805 336.758 43.2838ZM329.204 59.4886C329.354 55.6771 331.224 52.9244 334.185 50.9938C338.251 48.3408 342.668 48.0045 346.984 50.3835C350.435 52.2892 351.677 55.5526 351.853 59.4886C344.274 59.4886 336.871 59.4886 329.204 59.4886Z" />
      <path d="M377.037 33.1078C374.389 33.1078 372.03 33.1078 369.42 33.1078C369.42 36.7573 369.42 40.2947 369.42 44.0439C366.873 44.0439 364.577 44.0439 362.205 44.0439C362.205 46.2111 362.205 48.1542 362.205 50.334C364.69 50.334 366.986 50.334 369.458 50.334C369.458 51.3678 369.458 52.1774 369.458 52.9746C369.458 59.3768 369.383 65.779 369.483 72.1687C369.546 76.441 370.964 80.1528 375.28 81.9215C379.183 83.5158 383.173 83.0675 387 81.9589C387 79.7293 387 77.7613 387 75.6812C386.147 75.8805 385.507 76.0549 384.842 76.2044C379.697 77.3005 377.238 75.4446 377.125 70.2132C377.012 65.0067 377.062 59.8003 377.049 54.5938C377.049 53.2112 377.049 51.8162 377.049 50.2343C380.575 50.2343 383.788 50.2343 387.101 50.2343C387.101 47.9674 387.101 46.0741 387.101 43.9691C383.725 43.9691 380.513 43.9691 377.037 43.9691C377.037 40.2324 377.037 36.695 377.037 33.1078Z" />
      <path d="M317.822 43.8444C315.338 43.8444 312.703 43.9066 310.08 43.9066C309.453 43.9066 308.486 44.0312 307.746 44.7536C303.116 49.3249 297.871 54.469 293.316 59.1025C292.939 59.4762 292.5 59.8001 291.76 60.4228C291.76 47.0579 291.76 34.1539 291.76 21.1626C289.187 21.1626 286.816 21.1626 284.356 21.1626C284.356 41.5401 284.356 61.8054 284.356 82.0583C286.904 82.0583 289.275 82.0583 291.935 82.0583C291.935 75.5565 291.935 69.1543 291.935 62.2663C292.902 63.3499 293.504 64.0101 294.081 64.6702C298.812 70.0635 303.542 75.4568 308.286 80.8377C308.712 81.3235 309.264 82.0583 309.779 82.0708C313.079 82.1829 316.392 82.1331 320.181 82.1331C313.43 74.9212 307.106 68.1703 300.87 61.5189C306.592 55.5776 312.213 49.6985 317.822 43.8444Z" />
      <path d="M52.7902 0.000179339C23.7539 -0.0745547 0.100705 23.2175 0.000320551 51.9901C-0.100064 80.9496 23.39 104.466 52.4639 104.528C81.5504 104.578 105.078 81.2111 105.141 52.2019C105.191 23.5788 81.6383 0.0749133 52.7902 0.000179339ZM52.6145 97.3786C27.5936 97.391 7.24057 77.1755 7.21548 52.314C7.20293 27.4276 27.5309 7.16219 52.5267 7.14973C77.5476 7.13728 97.9006 27.3528 97.9257 52.2268C97.9257 77.1132 77.6103 97.3661 52.6145 97.3786Z" />
      <path d="M51.5356 35.586C49.7788 35.5984 48.5366 35.2621 48.7624 33.1322C48.9005 31.7746 48.1476 31.3137 46.8426 31.3137C41.0579 31.3386 35.2607 31.3511 29.476 31.3012C28.033 31.2888 27.3805 31.8244 27.506 33.2817C27.6189 34.4774 27.3052 35.1002 25.9625 35.7355C24.3062 36.5202 22.5746 37.7035 22.9635 40.3316C23.4153 43.4081 23.3274 46.5594 23.5031 49.6858C23.8921 56.9599 24.3062 64.234 24.6826 71.5081C24.7579 73.0402 25.1344 74.0366 27.0166 74.0366C44.1573 73.9993 61.298 73.9993 78.4387 74.0366C80.1076 74.0366 80.6848 73.3267 80.735 71.7697C80.8354 68.6558 81.0236 65.5419 81.2118 62.4279C81.7138 54.1948 82.2282 45.9616 82.7176 37.8405C77.8615 35.586 77.8615 35.586 72.9552 35.586C65.8279 35.5735 58.6754 35.5486 51.5356 35.586Z" />
    </svg>
  )
}
export default IPacketIcon
