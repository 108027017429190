import React, { PureComponent } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import LazyLoad from 'react-lazy-load'
import UAParser from 'ua-parser-js'

import { connect } from 'react-redux'
import { showModal, closeModal } from '../../../core/_actions/modalActions'
import { LIGHTBOX_MODAL } from '../../../core/_constants/modalTypes'
import {} from '../../../core/helpers/platformhelper'

import '../styles/lightboxmodal.scss'
import Modal from '../../layouts/modal'

const Parser = new UAParser.UAParser()
const os = Parser.getOS()

type Props = {
  index: Number,
  images: Array,
}

class LightBoxModal extends PureComponent {
  constructor(props: Props) {
    super(props)

    this.state = {
      currentTouchPosition: 0,
      touchStartPosition: 0,
      index: this.props.index,
      images: this.props.images,
    }
  }

  state: {
    currentTouchPosition: number,
    touchStartPosition: number,
    index: number,
    images: Array,
  }

  componentWillUnmount() {
    this.setState({
      currentTouchPosition: 0,
      touchStartPosition: 0,
      index: 0,
      images: [],
    })
  }

  touchStart = e => {
    this.setState({ touchStartPosition: e.touches[0].pageX })
  }

  touchMove = e => {
    this.setState({ currentTouchPosition: e.touches[0].pageX })
  }

  touchEnd = () => {
    const { currentTouchPosition, touchStartPosition, index } = this.state
    const touchMoveDistance = touchStartPosition - currentTouchPosition
    if (touchMoveDistance > 80) {
      this.manageImage(index + 1)
    } else if (touchMoveDistance < -80) {
      this.manageImage(index - 1)
    }
  }

  manageImage = i => {
    var tinyImg = document.getElementsByClassName('lightbox-modal-mini-image')[i]

    const { images, showModalConnect } = this.props
    if (i >= 0 && i <= images.length - 1) {
      showModalConnect(LIGHTBOX_MODAL, { images, index: i })

      tinyImg.scrollIntoView(false)
    }
  }

  updateActiveImage = newIndex => {
    const { images } = this.state
    var tinyImg = document.getElementsByClassName('lightbox-modal-mini-image')[newIndex]

    if (newIndex >= 0 && newIndex <= images.length - 1) {
      this.setState({ index: newIndex })
      if (tinyImg && tinyImg.scrollIntoView) {
        tinyImg.scrollIntoView(false)
      }
    }

    // circle back to beginning
    if (newIndex === images.length) {
      this.setState({ index: 0 })
      if (tinyImg && tinyImg.scrollIntoView) {
        tinyImg.scrollIntoView(false)
      }
    }
    // go to the end
    if (newIndex === -1) {
      this.setState({ index: images.length - 1 })
      if (tinyImg && tinyImg.scrollIntoView) {
        tinyImg.scrollIntoView(false)
      }
    }
  }

  render() {
    const { alt } = this.props
    const { images, index } = this.state

    const tinyImgArray = []

    let activeImageStyle = { border: '2px solid #EEB21E' }

    if (images && images.length > 0) {
      images.map((image, i) => {
        tinyImgArray.push(
          <button
            key={i}
            onClick={() => {
              this.updateActiveImage(i)
            }}
            id={i}
            style={i === index ? activeImageStyle : null}
          >
            <LazyLoad debounce={false} throttle={125} threshold={200}>
              <img className="lightbox-modal-mini-image" src={image} alt="packet-mini-img" id={i} />
            </LazyLoad>
          </button>,
        )
      })
    }

    // console.log(this.props)
    return (
      <div className="lightbox-modal-container">
        <Modal>
          <div className="lightbox-modal-image">
            <button
              className="lightbox-modal-control-previous"
              onClick={() => {
                this.updateActiveImage(index - 1)
              }}
            >
              {'<'}
            </button>
            <LazyLoad debounce={false} throttle={125} threshold={200}>
              <img
                src={images && images[index] ? images[index] : 'https://picsum.photos/200'}
                alt={alt ? alt : 'Vehicle Image'}
                onTouchStart={e => this.touchStart(e)}
                onTouchMove={e => this.touchMove(e)}
                onTouchEnd={() => this.touchEnd()}
              />
            </LazyLoad>
            <button
              className="lightbox-modal-control-next"
              onClick={() => {
                this.updateActiveImage(index + 1)
              }}
            >
              {'>'}
            </button>
          </div>
          <div className=" lightbox-modal-mini-image-holder">{tinyImgArray}</div>
        </Modal>
      </div>
    )
  }
}

export default connect(null, {
  showModalConnect: showModal,
  closeModalConnect: closeModal,
})(LightBoxModal)
