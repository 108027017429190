import React from 'react'

const SendIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M29.09,39.82v0l-5.17-1.93,0,0a.61.61,0,0,0-.18,0,1,1,0,0,0-1.13.85h0l-.69,6.94a.94.94,0,0,0,.87,1,1,1,0,0,0,.71-.21h0l5.69-4.88v0a1,1,0,0,0,.52-.73A1,1,0,0,0,29.09,39.82Z" />
      <path d="M40.71,39.13a1.7,1.7,0,0,0,0-.22L47.2,5.5A1.36,1.36,0,0,0,47.31,5a1.65,1.65,0,0,0-1.7-1.6,1.83,1.83,0,0,0-.84.21h0L3.82,25.48l-.14.08-.08,0h0A1.63,1.63,0,0,0,2.69,27a1.89,1.89,0,0,0,1.53,1.65h0l14,4.88L38.57,13.26,22.86,35.2l14.77,5.36h0a2.27,2.27,0,0,0,.91.18,2.22,2.22,0,0,0,2.16-1.57h0Z" />
    </svg>
  )
}
export default SendIcon
