// general: exports web app modal event names to be called in a .js file.

// GENERAL CONST MODALS
// --- normal open and close modal
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS'
// --- reset modal redux
export const NO_MODAL = 'NO_MODAL'

// USER MODALS
export const NOTIFICATION_MODAL = 'NOTIFICATION_MODAL'
export const DELETE_MODAL = 'DELETE_MODAL'
export const REPORT_ISSUE_MODAL = 'REPORT_ISSUE_MODAL'
export const RESIZE_QR_MODAL = 'RESIZE_QR_MODAL'
export const TOS_MODAL = 'TOS_MODAL'
export const TOS_FORM_MODAL = 'TOS_FORM_MODAL'

// DASHBOARD MODALS
export const DASHBOARD_CUSTOMER_MODAL = 'DASHBOARD_CUSTOMER_MODAL'
export const DASHBOARD_VIEWS_MODAL = 'DASHBOARD_VIEWS_MODAL'
export const DASHBOARD_SENT_MODAL = 'DASHBOARD_SENT_MODAL'

// CUSTOMER MODALS
export const CUSTOMER_EDIT_MODAL = 'CUSTOMER_EDIT_MODAL'
export const CUSTOMER_ADD_MODAL = 'CUSTOMER_ADD_MODAL'
export const CUSTOMER_FORM_MODAL = 'CUSTOMER_FORM_MODAL'

// ADMIN MODALS
export const ADMIN_TEAMMEMBER_MODAL = 'ADMIN_TEAMMEMBER_MODAL'
export const SCHEDULED_REPORTS_MODAL = 'SCHEDULED_REPORTS_MODAL'

// EMAIL TEMPLATES
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const EDIT_EMAIL_TEMPLATE = 'EDIT_EMAIL_TEMPLATE'
export const VIEW_EMAIL_TEMPLATE = `VIEW_EMAIL_TEMPLATE`
export const SELECT_EMAIL_TEMPLATE = 'SELECT_EMAIL_TEMPLATE'

// STICKER PULL UNSUPPORTED MODALS
export const STICKER_SUPPORTED_MODAL = 'STICKER_SUPPORTED_MODAL'
export const STICKER_PULL_COVERAGE_MODAL = 'STICKER_PULL_COVERAGE_MODAL'

// PACKET MODALS
export const PRICE_HISTORY_MODAL = 'PRICE_HISTORY_MODAL'
export const PACKET_SENT_MODAL = 'PACKET_SENT_MODAL'
export const LIGHTBOX_MODAL = 'LIGHTBOX_MODAL'

// INVENTORY MODALS
export const MODELS_MODAL = 'MODELS_MODAL'
export const SEARCH_MODAL = 'SEARCH_MODAL'
export const FACET_OPTIONS_MODAL = 'FACET_OPTIONS_MODAL'
export const PACKET_ACTIVITY_MODAL = 'PACKET_ACTIVITY_MODAL'

// PACKET SEND MODALS
export const SINGLE_SEND_MODAL = 'SINGLE_SEND_MODAL'
export const MULTI_SEND_MODAL = 'MULTI_SEND_MODAL'
export const PACKET_SEND_MODAL = 'PACKET_SEND_MODAL'

export const CHANGE_STORE_MODAL = 'CHANGE_STORE_MODAL'
export const WEBLINK_DISPLAY_MODAL = 'WEBLINK_DISPLAY_MODAL'
export const WORD_TRACK_MODAL = 'WORD_TRACK_MODAL'

export const SCHEDULED_PACKET_MODAL = 'SCHEDULED_PACKET_MODAL'
export const COVIDEO_MODAL = 'COVIDEO_MODAL'
export const PREVIOUS_ATTACHMENTS_MODAL = 'PREVIOUS_ATTACHMENTS_MODAL'
export const EMAIL_PREVIEW = 'EMAIL_PREVIEW'
export const DELETE_SCHEDULED_PACKET_MODAL = 'DELETE_SCHEDULED_PACKET_MODAL'

export const ARC_COMMON_ISSUES_MODAL = 'ARC_COMMON_ISSUES_MODAL'
