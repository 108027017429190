import React from 'react'

const CartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 50">
    <path d="M23.12,39.3a3.6,3.6,0,1,0,3.6,3.59A3.6,3.6,0,0,0,23.12,39.3Z" />
    <path d="M44.57,39.3a3.6,3.6,0,1,0,3.59,3.59A3.6,3.6,0,0,0,44.57,39.3Z" />
    <path d="M52.84,11.39a2.58,2.58,0,0,0,.09-.62,2.5,2.5,0,0,0-2.5-2.5H17.61l-.42-2.66h0a2.41,2.41,0,0,0-2.4-2.1H9.49a2.42,2.42,0,1,0,0,4.83h3.38l4.16,26a2.32,2.32,0,0,0,.07.46v.06h0a2.7,2.7,0,0,0,2.46,2H45.8a2.37,2.37,0,0,0,0-4.73H21.55L21.4,32,21,29.75H48.31a2.42,2.42,0,0,0,2.38-2h0Zm-5.7,6.5H39.69V13h8.09Zm-9.81,0H29.08V13h8.25Zm0,2.31V25H29.08V20.2Zm2.36,0h7.15L46.21,25H39.69ZM26.72,13v4.88H19.15L18.37,13ZM19.51,20.2h7.21V25H20.28Z" />
  </svg>
)
export default CartIcon
