// This component is intended to assume the role of the userinfowrapper and serve
// as the single source of truth for application/user settings.
// Updated on: application load and settings updates
// emptied on: logout or token expiration
// current role:
//        *only* leveraged to serve default_store_id to 'View My Inventory' button on 'Inventory'

import {
  SETTINGS_UPDATE,
  SETTINGS_EMPTY,
  SET_USER_LOGIN_ERROR,
  TOS_ACCEPTANCE_UPDATE,
  GET_TOS_ACCEPTANCE,
  GET_INTERCOM_INFO,
  GET_DEFAULT_STORE,
  SET_REQUIRED_ACTION,
  SET_DARK_MODE,
} from '../_constants/settingsTypes'
import _ from 'lodash'
import ls from 'local-storage'

const initialState = {
  userLoginError: null,
  tosAcceptance: true,
  checkedTOSAcceptance: false,
  settings: null,
  intercom_info: null,
  default_store_info: null,
  required_action: null,
  dark_mode: {
    enabled: false,
  },
}

export function userSettings(state, action) {
  if (!state) {
    state = initialState
  }

  switch (action.type) {
    case SET_REQUIRED_ACTION:
      return {
        ...state,
        required_action: action.required_action,
      }
    case GET_INTERCOM_INFO:
      return {
        ...state,
        intercom_info: action.intercom_info,
      }
    case SET_USER_LOGIN_ERROR:
      return {
        ...state,
        userLoginError: action.userLoginError,
      }
    case GET_DEFAULT_STORE:
      return {
        ...state,
        default_store_info: action.default_store_info,
      }
    case GET_TOS_ACCEPTANCE:
      return {
        ...state,
        tosAcceptance: action.tosAcceptance,
        checkedTOSAcceptance: true,
      }
    case TOS_ACCEPTANCE_UPDATE:
      return {
        ...state,
        tosAcceptance: action.tosAcceptance,
      }
    case SETTINGS_UPDATE:
      return {
        ...state,
        settings: _.assign(state.settings, action.settings), // combines existing state w/ new object
      }
    case SETTINGS_EMPTY:
      return initialState
    case SET_DARK_MODE:
      return {
        ...state,
        dark_mode: {
          ...state.dark_mode,
          ...action.dark_mode,
        },
      }
    default:
      return state
  }
}
