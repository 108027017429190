// general: redux action file for all things 'packet' data being stashed.

// constants
import {
  STASH_VEHICLE_IDS,
  STASH_VEHICLE_IMAGES,
  STASH_RECIPIENTS,
  STASH_MESSAGE,
  STASH_MODULES,
  STASH_PRICE,
  STASH_ATTACHMENTS,
  STASH_LEADS,
  IS_CREATING_CUSTOMER,
  UPDATE_CUSTOMERS_TO_SEND,
  STASH_SINGLE_MODULE,
  STASH_DEFAULT_STORE_ID,
  STASH_MODULE_VIDEOS,
  STASH_DELETE_MODAL,
  STASH_SCHEDULED_PACKETS,
  STASH_PREVIOUS_ATTACHMENTS,
  STASH_IMG_SRC,
  EMPTY_PREVIOUS_ATTACHMENTS,
  STASH_COVIDEO,
  EMPTY_COVIDEO,
} from '../_constants/packetviewTypes'

// stashVehicles
function stashVehicles(array: Object, object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_VEHICLE_IDS, vehicle_ids: array, vehicle_info: object })
  }
}

// stashVehicleImages
function stashVehicleImages(array: Object) {
  return function(dispatch: boolean) {
    dispatch({
      type: STASH_VEHICLE_IMAGES,
      vehicle_images: array.packet_images,
      vehicle_image_thumb: array.first_image,
    })
  }
}

// stashPrice
function stashPrice(price: string) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_PRICE, price })
  }
}

// stashRecipients
function stashRecipients(array: Object, object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_RECIPIENTS, recipient_ids: array, recipient_info: object })
  }
}

// stashMessage
function stashMessage(message: string) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_MESSAGE, message })
  }
}

// stashModules
function stashModules(array: Object, object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_MODULES, module_ids: array, module_info: object })
  }
}

// stashSingleModules
function stashSingleModules(object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_SINGLE_MODULE, single_module_info: object })
  }
}

// stashAttachments
function stashAttachments(array: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_ATTACHMENTS, attachment_files: array })
  }
}

// stashLeads
function stashLeads(type: string) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_LEADS, lead_source: type })
  }
}

// toggleCreatingCustomer
function toggleCreatingCustomer(is_creating_customer_val: boolean) {
  return function(dispatch: boolean) {
    dispatch({ type: IS_CREATING_CUSTOMER, is_creating_customer: is_creating_customer_val })
  }
}

// stashAdminCompanyStore
function stashAdminCompanyStore(default_store_id: number) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_DEFAULT_STORE_ID, default_store_id })
  }
}

// updateCustomersToSend
function updateCustomersToSend(customersToSend: Array) {
  return function(dispatch: boolean) {
    dispatch({ type: UPDATE_CUSTOMERS_TO_SEND, customers_to_send: customersToSend })
  }
}

// stashModuleVideos
function stashModuleVideos(module_videos: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_MODULE_VIDEOS, module_videos })
  }
}

// deleteModalAction
function deleteModalAction(value: boolean) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_DELETE_MODAL, deletemodal: value })
  }
}

// stashScheduledPackets
function stashScheduledPackets(packets) {
  return function(dispatch) {
    dispatch({ type: STASH_SCHEDULED_PACKETS, scheduled_packets: packets })
  }
}

function stashPreviousAttachments(attachments, img_src) {
  return function(dispatch) {
    dispatch({
      type: STASH_PREVIOUS_ATTACHMENTS,
      previous_attachments: attachments,
    })
    dispatch({
      type: STASH_IMG_SRC,
      img_src: img_src,
    })
  }
}

function emptyPreviousAttachments() {
  return function(dispatch) {
    dispatch({
      type: EMPTY_PREVIOUS_ATTACHMENTS,
    })
  }
}

function stashCovideo(covideo) {
  return function(dispatch) {
    dispatch({
      type: STASH_COVIDEO,
      covideo: covideo,
    })
  }
}

function emptyCovideo() {
  return function(dispatch) {
    dispatch({
      type: EMPTY_COVIDEO,
    })
  }
}

export {
  stashVehicles,
  stashRecipients,
  stashMessage,
  stashModules,
  stashPrice,
  stashAttachments,
  stashLeads,
  stashVehicleImages,
  toggleCreatingCustomer,
  updateCustomersToSend,
  stashSingleModules,
  stashAdminCompanyStore,
  stashModuleVideos,
  deleteModalAction,
  stashScheduledPackets,
  stashPreviousAttachments,
  emptyPreviousAttachments,
  stashCovideo,
  emptyCovideo,
}
