import React, { Component } from 'react'
import './styles/tooltip.scss'
import IconHelp from '../../core/assets/images/icons/help-icon.js'
import IconHelpX from '../../core/assets/images/icons/help-icon-x.js'

/*
  USAGE:
  props
  -----
  tip - can be a string or any component
  onHover - makes the component work on hover instead of toggle

  if component is used inline <ToolTip/> it will have default behavior

  if component is used as Wrapper <ToolTip> children </ToolTip>
  all children onhover will show tip
*/

class ToolTip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }
  toggle = () => {
    this.setState(prevState => {
      return {
        visible: !prevState.visible,
      }
    })
  }
  render() {
    const { visible } = this.state
    const { children, tip, onHover } = this.props
    if (children) {
      return (
        <div className="tooltip-container tooltip-container-hoverable">
          {children}
          <div className="tool-tip">{tip}</div>
        </div>
      )
    } else {
      return (
        <div
          className={`tooltip-container ${onHover ? 'tooltip-container-hoverable' : ''}`}
          onClick={() => {
            if (!onHover) {
              this.toggle()
            }
          }}
        >
          <div className="help-icon">{!visible || onHover ? <IconHelp /> : <IconHelpX />}</div>
          {(visible || onHover) && <div className="tool-tip">{tip}</div>}
        </div>
      )
    }
  }
}
export default ToolTip
