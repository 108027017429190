// general:

// @flow
import React, { Component } from 'react'
// node packages
// --- redux
import { connect } from 'react-redux'
// components
import ModuleChild from './components/packetmodules'
import SendActivity from './components/sendactivity'
import SendPrint from './components/sendprint'
import SendReport from './components/sendreport'
import SendView from './components/sendview'
import PacketViewCollapsed from './components/packetviewcollapsed'
import VehicleTimelineService from './components/vehicleTimelineService'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/packetsend.scss'

type Props = {
  userInfo: Object,
  view_type: string,
}

class PacketCenter extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      pageOn: 'view',
      view_type: this.props.view_type,
    }
  }

  state: {
    pageOn: string,
    view_type: string,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetUrlId()
    }
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    if (newProps.view_type !== this.state.view_type) {
      this.onMountGetUrlId()
      this.setState({ view_type: newProps.view_type })
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ pageOn: 'view', view_type: 'general_info' })
  }

  onMountGetUrlId() {
    // --- get page focus
    // eslint-disable-next-line no-undef
    const pagefocusFirstHalf = window.location.href.split('packet/')[1]
    // eslint-disable-next-line no-undef
    this.setState({ pageOn: pagefocusFirstHalf.split('/')[0] })
  }

  props: Props

  render() {
    // props/states
    const { userInfo, vin } = this.props
    const { pageOn, view_type } = this.state

    let mainContent = (
      <div idstyle={{ width: '100%' }}>
        <SendView DJtoken={userInfo.dj_token} />
      </div>
    )

    if (pageOn !== 'view' && view_type !== 'general_info' && view_type !== 'module') {
      mainContent = (
        <div idstyle={{ width: '100%' }}>
          <PacketViewCollapsed DJtoken={userInfo.dj_token} />

          {view_type === 'activity' || pageOn === 'activity' ? (
            <SendActivity DJtoken={userInfo.dj_token} />
          ) : null}
          {view_type === 'print' || pageOn === 'print' ? (
            <SendPrint token={userInfo.dj_token} />
          ) : null}
          {view_type === 'timeline' || pageOn === 'timeline' ? (
            <VehicleTimelineService vin={vin} token={userInfo.dj_token} />
          ) : null}
          {view_type === 'report-issue' || pageOn === 'report-issue' ? (
            <SendReport
              userId={userInfo.user_id}
              DJtoken={userInfo.dj_token}
              email={userInfo.user_email_address}
              firstname={userInfo.first_name}
              lastname={userInfo.last_name}
            />
          ) : null}
        </div>
      )
    } else if (view_type === 'module') {
      mainContent = (
        <div style={{ width: '100%' }}>
          <PacketViewCollapsed DJtoken={userInfo.dj_token} />
          <ModuleChild token={userInfo.dj_token} />
        </div>
      )
    }

    return <div className="packetSendMiddleContainersStyle">{mainContent}</div>
  }
}

const mapStateToProps = state => ({
  view_type: state.packetview.view_type,
  vin: state.packetstash.vehicle_info.vin,
})
export default connect(mapStateToProps, {})(PacketCenter)
