// general: action file for all things 'tools' data being passed back and forth.

import { GET_USER_STORE, UPDATE_ACTIVE_STORE } from '../../_constants/toolsTypes'
// API(s)
import {
  DJangoToolsDocUploadAPIURL,
  DJangoToolsFinanceTablesCPOAPIURL,
  DJangoToolsFinanceTablesNonCPOAPIURL,
  DJangoProfileStoreListGetAPIURL,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

export default {
  // TOOLS - API - GENERAL
  getToolsDocUpload: (storeId: number, token: string) => {
    // v1/store/:store_id/tools/document-type-list

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoToolsDocUploadAPIURL}${storeId}/tools/document-type-list`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  // putToolsDocUpload: (
  //   storeId: number,
  //   token: string,
  //   fileArray: Object,
  //   labelName: string,
  //   moduleId: number,
  //   folderPath: string,
  // ) => {
  //   // v1/store/:store_id/tools/document-type
  //
  //   const params = new FormData() // eslint-disable-line no-undef
  //   // required
  //   params.append('files', fileArray)
  //   //params.append('document_type', '.pdf')
  //   // extra(s)
  //   params.append('document_type', moduleId)
  //   //params.append('label', labelName)
  //   //params.append('folder', folderPath)
  //   console.log('--file array')
  //   console.log(fileArray)
  //   console.log('--params')
  //   console.log(params)
  //
  //   let headers = {
  //     Authorization: `Token ${token}`,
  //     Accept: 'application/json',
  //   }
  //   return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoToolsDocUploadAPIURL}${storeId}/tools/document-type`, {
  //     method: 'POST',
  //     headers,
  //     body:params
  //   }).then((response)=>{
  //     return response.json()
  //   })
  // },

  //
  //
  //
  // TOOLS - API - CPO
  getToolsFinanceTablesCPO: (numORurl: string, storeId: number, token: string) => {
    // v1/store/:store_id/tools/finance-tables/cpo

    let pathParameters = `${storeId}/tools/finance-tables/cpo?page=${numORurl}&items_per_page=15`

    if (numORurl !== '1') {
      pathParameters = numORurl
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoToolsFinanceTablesCPOAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  putToolsFinanceTablesCPO: (
    storeId: number,
    yearBegin: number,
    yearEnd: number,
    cpoId: number,
    cpoRateId: number,
    effectiveEndDate: string,
    effectiveStartDate: string,
    make: string,
    makeId: number,
    model: string,
    modelId: number,
    months1: number,
    months2: number,
    months3: number,
    token: string,
  ) => {
    // v1/store/:store_id/tools/finance-tables/cpo

    const params = {
      company_store_id: storeId,
      begin_model_year: yearBegin,
      end_model_year: yearEnd,

      cpo_id: cpoId,
      cpo_rate_id: cpoRateId,
      effective_end_date: effectiveEndDate,
      effective_start_date: effectiveStartDate,

      make,
      make_id: makeId,
      model,
      model_id: modelId,
      month_24_36: months1,
      month_37_48: months2,
      month_49_66: months3,
    }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesCPOAPIURL}${storeId}/tools/finance-tables/cpo`,
      {
        method: 'PUT',
        headers,
        body: JSON.stringify(params),
      },
    ).then(response => {
      return response.json()
    })
  },

  //
  //
  //
  // TOOLS - API - NON CPO
  getToolsFinanceTablesNonCPO: (numORurl: string, storeId: number, token: string) => {
    // v1/store/:store_id/tools/finance-tables/noncpo

    let pathParameters = `${storeId}/tools/finance-tables/noncpo?page=${numORurl}&items_per_page=15`

    if (numORurl !== '1') {
      pathParameters = numORurl
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoToolsFinanceTablesNonCPOAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  putToolsFinanceTablesNonCPO: (
    storeId: number,
    token: string,
    year: number,
    term1: number,
    miles1: string,
    term2: number,
    miles2: string,
    term3: number,
    miles3: string,
    term4: number,
    miles4: string,
  ) => {
    // v1/store/:store_id/tools/finance-tables/noncpo

    const params = {
      company_store_id: storeId,
      year,

      term_60: term1,
      rate_60: miles1,

      term_60_80: term2,
      rate_60_80: miles2,

      term_80_100: term3,
      rate_80_100: miles3,

      term_100: term4,
      rate_100: miles4,
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesNonCPOAPIURL}${storeId}/tools/finance-tables/noncpo`,
      {
        method: 'PUT',
        headers,
        body: JSON.stringify(params),
      },
    ).then(response => {
      return response.json()
    })
  },
}
function uploadDocuments(args) {
  const { storeId, file, token, docType } = args
  const form = new FormData()
  form.append('document_type', docType)
  form.append('files', file)
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsDocUploadAPIURL}${storeId}/tools/document-type?store_id=${storeId}`,
    {
      method: 'POST',
      headers,
      body: form,
    },
  ).then(response => {
    return response.json()
  })
}
function getUserStoreGroups(userId: number, token: string) {
  return function(dispatch) {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileStoreListGetAPIURL}${userId}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        dispatch({
          type: GET_USER_STORE,
          stores: res,
        })
      })
  }
}
function updateActiveStore(store_id, store_name, store) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_ACTIVE_STORE,
      activeStoreId: store_id,
      activeStoreName: store_name,
      activeStore: store,
    })
  }
}
function getFinanceTablesCPO(storeId, page, token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesCPOAPIURL}${storeId}/tools/finance-tables/cpo?page=${page}&items_per_page=15`,
    {
      method: 'GET',
      headers,
    },
  ).then(response => {
    return response.json()
  })
}
function getFinanceTablesNonCPO(storeId, page, token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesCPOAPIURL}${storeId}/tools/finance-tables/noncpo?page=${page}&items_per_page=15}`,
    {
      method: 'GET',
      headers,
    },
  ).then(response => {
    return response.json()
  })
}
function putFinanceTablesCPO(storeId, page, token, params) {
  console.log(params)
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesCPOAPIURL}${storeId}/tools/finance-tables/cpo`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    },
  ).then(response => {
    return response.json()
  })
}
function putFinanceTablesNonCPO(storeId, page, token, params) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesCPOAPIURL}${storeId}/tools/finance-tables/noncpo`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    },
  ).then(response => {
    return response.json()
  })
}

function getFinanceTables(storeId, page, token, tableTypeId) {
  const headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesAPIURL}${storeId}/tools/finance-tables/?type_id=${tableTypeId}&items_per_page=15`,
    { headers },
  )
}
function postUpdatedFinanceTable(table, token) {
  const headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DJangoToolsFinanceTablesAPIURL}tools/finance-table/${table.id}/`,
    { method: 'POST', headers, body: JSON.stringify(table) },
  )
}
export {
  getUserStoreGroups,
  updateActiveStore,
  uploadDocuments,
  getFinanceTablesCPO,
  getFinanceTablesNonCPO,
  putFinanceTablesCPO,
  putFinanceTablesNonCPO,
  getFinanceTables,
  postUpdatedFinanceTable,
}
