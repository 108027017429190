import React, { useRef, useState, useEffect } from 'react'
import Chart from 'chart.js'

// date-fns
import sub from 'date-fns/sub'
import format from 'date-fns/format'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
// helpers
import StaffToolsActions from '../../../core/actions/stafftoolactions'
import PropTypes from 'prop-types'
// redux
import { useSelector, shallowEqual } from 'react-redux'
// components
import LoadingSpinner from '../../helpers/loadingspinner'
// assets
import '../styles/graph.scss'

function StatisticsGraph({ storeId, token, creationDate, onGraphLoaded, healthLoaded }) {
  const chartRef = useRef(null)
  const timePeriod = useSelector(state => state.reactAdmin.title)
  const interval = useSelector(state => state.reactAdmin.interval)
  const darkModeState = useSelector(
    state => state.authentication.user.user.profile.dark_mode_webapp,
    shallowEqual,
  )
  const [graphTypes, setGraphTypes] = useState(['Packets Sent'])
  const [isLoading, setIsLoading] = useState(true)
  const [disableGraph, setDisableGraph] = useState(false)
  const [respData, setRespData] = useState([])
  const [unCliked, setUnCliked] = useState('')
  /**
   * formats the date for graph use
   * @param {date} date to format
   * @return {string} date formatted
   */
  function formatByTimePeriod(date) {
    switch (timePeriod) {
      case 'Last Week':
        return format(date, 'EEEE')
      case 'Last Month':
        return format(date, 'MMMM do')
      default:
      case 'Last Year':
        return format(date, 'MMMM')
    }
  }
  /**
   * Sends request according to which view is selected
   * @param {array} dateRanges dates to POST
   * @param {chart} chart chart ref to update with data
   */

  function sendRequestByDates(dateRanges, chart) {
    chart.data.labels = chart.data.labels.reverse()
    console.log(`Fetching graph types: ${graphTypes}`)
    let packetsSend = false
    let packetsViewed = false
    let webClicks = false
    let indirectWebClicks = false
    let socialShares = false

    if (!localStorage.getItem('dateRange')) {
      window.localStorage.setItem('dateRange', JSON.stringify(dateRanges))
      if (graphTypes.includes('Packets Sent')) {
        StaffToolsActions.getPacketsSent(storeId, dateRanges, token).then(json => {
          const mappedCount = json.ranges.map(person => person.count)
          const NewData = [...respData]
          NewData.push({
            label: 'Packets Sent',
            backgroumdColor: '#337dce',
            borderColor: '#337dce',
            fill: false,
            data: mappedCount,
          })
          packetsSend = true
          chart.data.datasets = NewData
          chart.update()
          setRespData(NewData)
        })
      }
    } else {
      const storeDate = JSON.parse(localStorage.getItem('dateRange'))
      if (storeDate.length !== dateRanges.length && storeDate[0].start !== dateRanges[0].start) {
        window.localStorage.setItem('dateRange', JSON.stringify(dateRanges))
        let newData = []
        if (graphTypes.includes('Packets Sent')) {
          StaffToolsActions.getPacketsSent(storeId, dateRanges, token).then(json => {
            const mappedCount = json.ranges.map(person => person.count)
            const NewData = [...newData]
            NewData.push({
              label: 'Packets Sent',
              backgroumdColor: '#337dce',
              borderColor: '#337dce',
              fill: false,
              data: mappedCount,
            })
            packetsSend = true
            chart.data.datasets = NewData
            chart.update()
            newData = NewData
            setRespData(NewData)
          })
        }
        if (graphTypes.includes('Packets Viewed')) {
          StaffToolsActions.getPacketsViewed(storeId, dateRanges, token).then(json => {
            const mappedCount = json.ranges.map(person => person.count)
            const NewData = [...newData]
            NewData.push({
              label: 'Packets Viewed',
              backgroundColor: '#337dce',
              borderColor: '#337dce',
              borderDash: [10, 10],
              fill: false,
              data: mappedCount,
            })
            newData = NewData
            setRespData(NewData)
            chart.data.datasets = NewData
            chart.update()
            packetsViewed = true
          })
        }
        if (graphTypes.includes('Web Clicks')) {
          StaffToolsActions.getWebClicks(storeId, dateRanges, token).then(json => {
            const mappedCount = json.ranges.map(person => person.count)
            const NewData = [...newData]
            NewData.push({
              label: 'Web Clicks',
              backgroundColor: '#62646a',
              borderColor: '#62646a',
              fill: false,
              data: mappedCount,
            })
            newData = NewData
            setRespData(NewData)
            chart.data.datasets = NewData
            chart.update()
            webClicks = true
          })
        }
        if (graphTypes.includes('Indirect Web Clicks')) {
          StaffToolsActions.getIndirectClicks(storeId, dateRanges, token).then(json => {
            const mappedCount = json.ranges.map(person => person.count)
            const NewData = [...newData]
            NewData.push({
              label: 'Indirect Web Clicks',
              backgroundColor: '#62646a',
              borderColor: '#62646a',
              borderDash: [10, 10],
              fill: false,
              data: mappedCount,
            })
            newData = NewData
            setRespData(NewData)
            chart.data.datasets = NewData
            chart.update()
            indirectWebClicks = true
          })
        }
        if (graphTypes.includes('Social Shares')) {
          StaffToolsActions.getSocialShares(storeId, dateRanges, token).then(json => {
            const mappedCount = json.ranges.map(person => person.count)
            const NewData = [...newData]
            NewData.push({
              label: 'Social Shares',
              backgroundColor: '#eeb21e',
              borderColor: '#eeb21e',
              fill: false,
              data: mappedCount,
            })
            newData = NewData
            setRespData(NewData)
            chart.data.datasets = NewData
            chart.update()
            socialShares = true
          })
        }
      } else {
        const temArr = respData
        if (respData.length > graphTypes.length) {
          let index = 0
          for (let k = 0; k < respData.length; k++) {
            if (respData[k].label === unCliked) {
              index = k
            }
          }
          temArr.splice(index, 1)
          chart.data.datasets = temArr
          chart.update()
        } else {
          for (let i = 0; i < temArr.length; i++) {
            if (temArr[i].label === 'Packets Sent') {
              packetsSend = true
            }
            if (temArr[i].label === 'Packets Viewed') {
              packetsViewed = true
            }
            if (temArr[i].label === 'Web Clicks') {
              webClicks = true
            }
            if (temArr[i].label === 'Indirect Web Clicks') {
              indirectWebClicks = true
            }
            if (temArr[i].label === 'Social Shares') {
              socialShares = true
            }
          }
          if (graphTypes.includes('Packets Sent') && !packetsSend) {
            StaffToolsActions.getPacketsSent(storeId, dateRanges, token).then(json => {
              const mappedCount = json.ranges.map(person => person.count)
              const NewData = [...respData]
              NewData.push({
                label: 'Packets Sent',
                backgroumdColor: '#337dce',
                borderColor: '#337dce',
                fill: false,
                data: mappedCount,
              })
              packetsSend = true
              chart.data.datasets = NewData
              chart.update()
              setRespData(NewData)
            })
          }
          if (graphTypes.includes('Packets Viewed') && !packetsViewed) {
            StaffToolsActions.getPacketsViewed(storeId, dateRanges, token).then(json => {
              const mappedCount = json.ranges.map(person => person.count)
              const NewData = [...respData]
              NewData.push({
                label: 'Packets Viewed',
                backgroundColor: '#337dce',
                borderColor: '#337dce',
                borderDash: [10, 10],
                fill: false,
                data: mappedCount,
              })
              setRespData(NewData)
              chart.data.datasets = NewData
              chart.update()
              packetsViewed = true
            })
          }
          if (graphTypes.includes('Web Clicks') && !webClicks) {
            StaffToolsActions.getWebClicks(storeId, dateRanges, token).then(json => {
              const mappedCount = json.ranges.map(person => person.count)
              const NewData = [...respData]
              NewData.push({
                label: 'Web Clicks',
                backgroundColor: '#62646a',
                borderColor: '#62646a',
                fill: false,
                data: mappedCount,
              })
              setRespData(NewData)
              chart.data.datasets = NewData
              chart.update()
              webClicks = true
            })
          }
          if (graphTypes.includes('Indirect Web Clicks') && !indirectWebClicks) {
            StaffToolsActions.getIndirectClicks(storeId, dateRanges, token).then(json => {
              const mappedCount = json.ranges.map(person => person.count)
              const NewData = [...respData]
              NewData.push({
                label: 'Indirect Web Clicks',
                backgroundColor: '#62646a',
                borderColor: '#62646a',
                borderDash: [10, 10],
                fill: false,
                data: mappedCount,
              })
              setRespData(NewData)
              chart.data.datasets = NewData
              chart.update()
              indirectWebClicks = true
            })
          }
          if (graphTypes.includes('Social Shares') && !socialShares) {
            StaffToolsActions.getSocialShares(storeId, dateRanges, token).then(json => {
              const mappedCount = json.ranges.map(person => person.count)
              const NewData = [...respData]
              NewData.push({
                label: 'Social Shares',
                backgroundColor: '#eeb21e',
                borderColor: '#eeb21e',
                fill: false,
                data: mappedCount,
              })
              setRespData(NewData)
              chart.data.datasets = NewData
              chart.update()
              socialShares = true
            })
          }
        }
      }
    }
  }
  /**
   * Finds the highest multiple of a number (for graph deliniation)
   * @param {number} number to find highest multiple of
   * @return {number} highestMultiple
   */
  function findMultiple(number) {
    let highestMultiple = -1
    if (number % 2 === 0) {
      for (let i = 2; i < 10; i++) {
        if (number % i === 0) {
          highestMultiple = i
        }
      }
    } else {
      const newNum = number - 1
      for (let j = 2; j < 10; j++) {
        if (newNum % j === 0) {
          highestMultiple = j
        }
      }
    }
    return highestMultiple
  }
  /**
   * Creates date ranges based on custom settings
   * @param {chart} chart chart ref to update with data
   * @return {array} ranges of dates
   */
  function viewCustomRange(chart) {
    const ranges = []
    const dayTotal = differenceInCalendarDays(interval.endDate, interval.startDate)
    let endDate = interval.endDate
    const dayDelineationAmount = dayTotal / findMultiple(dayTotal)
    console.log(
      `Difference in days (Custom): ${dayDelineationAmount}, Multiple: ${findMultiple(dayTotal)}`,
    )
    let startDate = sub(interval.endDate, { days: dayDelineationAmount })

    if (dayTotal <= 1) {
      setDisableGraph(true)
      return [{ start: format(endDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') }]
    }
    for (let i = 0; i < findMultiple(dayTotal) + 1; i++) {
      ranges.push({ start: format(startDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') })
      chart.data.labels.push(format(endDate, 'MMMM do, yyyy'))
      startDate = sub(startDate, { days: dayDelineationAmount })
      endDate = sub(endDate, { days: dayDelineationAmount })
    }
    return ranges.reverse()
  }
  /**
   * Creates date ranges based on all time setting
   * @param {chart} chart chart ref to update with data
   * @return {array} ranges of dates
   */
  function viewAllTime(chart) {
    const ranges = []
    let endDate = new Date()
    const dayTotal = differenceInCalendarDays(new Date(), new Date(creationDate))
    const dayDelineationAmount = dayTotal / findMultiple(dayTotal)
    console.log(
      `Difference in days (Custom): ${dayDelineationAmount}, Multiple: ${findMultiple(dayTotal)}`,
    )
    let startDate = sub(new Date(), { days: dayDelineationAmount })
    console.log(findMultiple(dayTotal))
    for (let i = 0; i < findMultiple(dayTotal) + 1; i++) {
      console.log(i)
      ranges.push({ start: format(startDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') })
      chart.data.labels.push(format(endDate, 'MMMM do, yyyy'))
      startDate = sub(startDate, { days: dayDelineationAmount })
      endDate = sub(endDate, { days: dayDelineationAmount })
    }
    return ranges.reverse()
  }
  /**
   * Creates date ranges based on last year setting
   * @param {chart} chart chart ref to update with data
   * @return {array} ranges of dates
   */
  function viewLastYear(chart) {
    const ranges = []
    let startDate = startOfMonth(new Date())
    let endDate = new Date()
    for (let i = 0; i < 12; i++) {
      ranges.push({ start: format(startDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') })
      chart.data.labels.push(formatByTimePeriod(startDate))
      startDate = sub(startDate, { months: 1 })
      endDate = sub(endDate, { months: 1 })
      endDate = endOfMonth(endDate)
    }
    return ranges.reverse()
  }
  /**
   * Creates date ranges based on last month setting
   * @param {chart} chart chart ref to update with data
   * @return {array} ranges of dates
   */
  function viewLastMonth(chart) {
    const ranges = []
    let startDate = sub(new Date(), { weeks: 1 })
    let endDate = new Date()
    for (let i = 0; i < 4; i++) {
      ranges.push({ start: format(startDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') })
      chart.data.labels.push(formatByTimePeriod(startDate))
      startDate = sub(startDate, { weeks: 1 })
      endDate = sub(endDate, { weeks: 1 })
    }
    return ranges.reverse()
  }
  /**
   * Creates date ranges based on last week setting
   * @param {chart} chart chart ref to update with data
   * @return {array} ranges of dates
   */
  function viewLastWeek(chart) {
    const ranges = []
    let startDate = sub(new Date(), { days: 1 })
    let endDate = new Date()
    for (let i = 0; i < 7; i++) {
      ranges.push({ start: format(startDate, 'yyyy-MM-dd'), end: format(endDate, 'yyyy-MM-dd') })
      chart.data.labels.push(formatByTimePeriod(startDate))
      startDate = sub(startDate, { days: 1 })
      endDate = sub(endDate, { days: 1 })
    }
    return ranges.reverse()
  }
  useEffect(() => {
    if (chartRef.current) {
      let lineChart
      if (darkModeState == true) {
        lineChart = new Chart(chartRef.current, {
          type: 'line',
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            aspectRatio: 2.2,
            animation: {
              duration: 500,
            },
            legend: {
              labels: {
                fontColor: '#ffffff',
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: '#767676',
                  },
                  ticks: {
                    fontColor: '#ffffff',
                    beginAtZero: true,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: '#767676',
                  },
                  ticks: {
                    fontColor: '#ffffff',
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        })
      } else {
        lineChart = new Chart(chartRef.current, {
          type: 'line',
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            responsive: true,
            aspectRatio: 2.2,
            animation: {
              duration: 500,
            },
            legend: {},
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: '#767676',
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: '#767676',
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        })
      }

      if (graphTypes.length > 0) {
        setIsLoading(true)
        onGraphLoaded(false)
        setDisableGraph(false)
        switch (timePeriod) {
          case 'Last Week':
            sendRequestByDates(viewLastWeek(lineChart), lineChart)
            break
          case 'Last Month':
            sendRequestByDates(viewLastMonth(lineChart), lineChart)
            break
          case 'Last Year':
            sendRequestByDates(viewLastYear(lineChart), lineChart)
            break
          case 'Custom':
            sendRequestByDates(viewCustomRange(lineChart), lineChart)
            break
          default:
          case 'All Time':
            sendRequestByDates(viewAllTime(lineChart), lineChart)
            break
        }
      }

      setTimeout(() => {
        setIsLoading(false)
        onGraphLoaded(true)
      }, 500)
    }
  }, [timePeriod, graphTypes, interval])
  function handleButtonClick(title) {
    if (!graphTypes.includes(title)) {
      setGraphTypes(graphTypes.concat(title))
    } else {
      setUnCliked(title)
      if (graphTypes.length === 1 && graphTypes[0] === title) {
        setRespData([])
      }
      setGraphTypes(graphTypes.filter(typeName => typeName !== title))
    }
  }
  function textContainer(buttonTitle) {
    return (
      <button
        key={buttonTitle}
        className={`statistics-button ${graphTypes.includes(buttonTitle) ? 'selected' : ''}`}
        onClick={() => handleButtonClick(buttonTitle)}
        disabled={isLoading || !healthLoaded}
      >
        {buttonTitle}
      </button>
    )
  }
  const typeButtonTitles = [
    'Packets Sent',
    'Packets Viewed',
    'Web Clicks',
    'Indirect Web Clicks',
    'Social Shares',
  ]

  return (
    <div className="statistics-graph-container">
      {isLoading ? (
        <div className="section-loading loading-graph">
          <LoadingSpinner loading wheel />
        </div>
      ) : (
        ''
      )}
      <div className="graph-sizer">
        {disableGraph ? (
          <span className="disabled-graph-message">Graph disabled for this view</span>
        ) : (
          ''
        )}
        {graphTypes.length === 0 ? (
          <span className="disabled-graph-message">No graph type selected</span>
        ) : (
          ''
        )}
        <canvas ref={chartRef} />
      </div>
      <div className="statistics-graph-buttons">
        {typeButtonTitles.map(title => textContainer(title))}
      </div>
    </div>
  )
}
export default StatisticsGraph
StatisticsGraph.propTypes = {
  token: PropTypes.string,
  creationDate: PropTypes.string,
  onGraphLoaded: PropTypes.func,
  healthLoaded: PropTypes.bool,
}
