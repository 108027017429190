import React from 'react'

const DownloadBoxIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M39.91,5.83H10.09l-7,8.23V44.17H46.91V14.06Zm-8,28.06-6.9,6-6.9-6-6.89-6h8.36V19.47H30.44v8.38H38.8ZM8.5,13.34,13,8.12H37.06l4.44,5.22Z" />
    </svg>
  )
}
export default DownloadBoxIcon
