import {
  ADD_EMAIL_TEMPLATE,
  REMOVE_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  CHANGE_TEMPLATE_TYPE,
  GET_COMPANY_TEMPLATES,
  GET_STORE_TEMPLATES,
  GET_USER_TEMPLATES,
  SELECT_EMAIL_TEMPLATE,
} from '../_constants/emailTemplateTypes'
import {
  DJangoCompanyEmailTemplatesList,
  DJangoStoreEmailTemplatesList,
  DJangoUserEmailTemplatesList,
  DJangoEmailTemplatesDetail,
  DJangoCreateEmailTemplate,
  DJangoUpdateEmailTemplate,
  DJangoDeleteEmailTemplate,
  DJangoRenderEmailTemplate,
} from '../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

function addTemplate(newTemplate, templateType) {
  return function(dispactch) {
    dispactch({
      type: ADD_EMAIL_TEMPLATE,
      newTemplate,
      templateType,
    })
  }
}

function removeTemplate(removedTemplate, templateType) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_EMAIL_TEMPLATE,
      removedTemplate,
      templateType,
    })
  }
}

function editTemplate(selectedTemplate, editedTemplate, templateType) {
  return function(dispatch) {
    dispatch({
      type: EDIT_EMAIL_TEMPLATE,
      selectedTemplate,
      editedTemplate,
      templateType,
    })
  }
}

function updateCompanyTemplateList(companyTemplates, companyId) {
  return function(dispatch) {
    dispatch({
      type: GET_COMPANY_TEMPLATES,
      companyTemplates,
      companyId,
    })
  }
}

function updateStoreTemplateList(storeTemplates, storeId) {
  return function(dispatch) {
    dispatch({
      type: GET_STORE_TEMPLATES,
      storeTemplates,
      storeId,
    })
  }
}

function updateUserTemplateList(userTemplates) {
  return function(dispatch) {
    dispatch({
      type: GET_USER_TEMPLATES,
      userTemplates,
    })
  }
}

function selectTemplate(selectedTemplate) {
  return function(dispatch) {
    dispatch({
      type: SELECT_EMAIL_TEMPLATE,
      selectedTemplate,
    })
  }
}

function changeTemplateType(newTemplateType) {
  return function(dispatch) {
    dispatch({
      type: CHANGE_TEMPLATE_TYPE,
      newTemplateType,
    })
  }
}

function getCompanyTemplates(token: String, company_id: Number) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoCompanyEmailTemplatesList}${company_id}/`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getStoreTemplates(token: String, store_id: Number) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoStoreEmailTemplatesList}${store_id}/`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getUserTemplates(token: String) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoUserEmailTemplatesList}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function getEmailTemplateDetail(token: String, message_template_id: Number) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoEmailTemplatesDetail}${message_template_id}/`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function createEmailTemplate(token: String, template: Object) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoCreateEmailTemplate}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(template),
  }).then(response => {
    return response.json()
  })
}

function updateEmailTemplate(token: String, template: Object, message_template_id: Number) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoUpdateEmailTemplate}${message_template_id}/`, {
    method: 'POST',
    headers,
    body: JSON.stringify(template),
  }).then(response => {
    return response.json()
  })
}

function deleteEmailTemplate(token: String, message_template_id: Number) {
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDeleteEmailTemplate}${message_template_id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(response => {
    return response.json()
  })
}

function renderEmailTemplate(token: String, message_template_id: Number, message_options: Object) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoRenderEmailTemplate}${message_template_id}/`, {
    method: 'POST',
    headers,
    body: JSON.stringify(message_options),
  }).then(response => {
    return response.json()
  })
}

export {
  addTemplate,
  removeTemplate,
  editTemplate,
  updateCompanyTemplateList,
  updateStoreTemplateList,
  updateUserTemplateList,
  selectTemplate,
  changeTemplateType,
  getCompanyTemplates,
  getStoreTemplates,
  getUserTemplates,
  getEmailTemplateDetail,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
  renderEmailTemplate,
}
