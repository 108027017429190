// general: modal router and container.

// hot reloading
import { hot } from 'react-hot-loader/root'

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// import AbortController from 'abort-controller'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

// redux modals - dispatched types
import {
  NOTIFICATION_MODAL,
  DASHBOARD_CUSTOMER_MODAL,
  DASHBOARD_VIEWS_MODAL,
  DASHBOARD_SENT_MODAL,
  CUSTOMER_EDIT_MODAL,
  CUSTOMER_ADD_MODAL,
  CUSTOMER_FORM_MODAL,
  ADMIN_TEAMMEMBER_MODAL,
  SCHEDULED_REPORTS_MODAL,
  ADD_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  VIEW_EMAIL_TEMPLATE,
  SELECT_EMAIL_TEMPLATE,
  STICKER_SUPPORTED_MODAL,
  STICKER_PULL_COVERAGE_MODAL,
  PRICE_HISTORY_MODAL,
  PACKET_SENT_MODAL,
  FACET_OPTIONS_MODAL,
  SINGLE_SEND_MODAL,
  MULTI_SEND_MODAL,
  PACKET_SEND_MODAL,
  DELETE_MODAL,
  LIGHTBOX_MODAL,
  REPORT_ISSUE_MODAL,
  RESIZE_QR_MODAL,
  CHANGE_STORE_MODAL,
  PACKET_ACTIVITY_MODAL,
  TOS_MODAL,
  TOS_FORM_MODAL,
  WEBLINK_DISPLAY_MODAL,
  WORD_TRACK_MODAL,
  NO_MODAL,
  SCHEDULED_PACKET_MODAL,
  COVIDEO_MODAL,
  PREVIOUS_ATTACHMENTS_MODAL,
  EMAIL_PREVIEW,
  DELETE_SCHEDULED_PACKET_MODAL,
  ARC_COMMON_ISSUES_MODAL,
} from '../../core/_constants/modalTypes'
// user modal components
import ReportIssueModal from './user_modals/reportissuemodal'
import ResizeQRModal from './user_modals/resizeqrmodal'
import TOSModal from './user_modals/tosmodal'
import TOSFormModal from './user_modals/tosformmodal'

// dashboard modal components
import DashCustomerModal from './dashboard_modals/dashboardcustomermodal'
import DashViewsModal from './dashboard_modals/dashboardviewsmodal'
import DashSentModal from './dashboard_modals/dashboardsentmodal'
// customers modals
import CustomerEditModal from './customers_modals/customerseditmodal'
import CustomerAddModal from './customers_modals/customersaddmodal'
import CustomerFormModal from './customers_modals/customerformmodal'
// admin modals
// eslint-disable-next-line import/no-named-as-default
import TeamMemberEditModal from './admin_modals/teammembereditmodal'
import AddEmailTemplate from './admin_modals/addEmailTemplate'
import EditEmailTemplate from './admin_modals/editEmailTemplate'
import ViewEmailTemplate from './admin_modals/viewEmailTemplate'
import EmailTemplatesModal from './packet_send_modals/emailTemplatesModal'
import ScheduledReportsModal from './admin_modals/scheduledReportModal'

// eslint-disable-next-line import/no-named-as-default
import StickerSupportModal from './sticker_pull_modals/stickersupportmodal'
import StickerPullCoverageModal from './sticker_pull_modals/stickerpullcoveragemodal'

//  inventory modals
import FacetOptionsModal from './inventory_modals/facetoptionsmodal'
import PacketActivityModal from './inventory_modals/packetactivitymodal'
// packet send modals
import PacketSendModal from './packet_send_modals/packetsendmodal'
import WebLinkDisplayModal from './packet_send_modals/weblinkdisplaymodal'
import ScheduledPacketModal from './packet_send_modals/scheduledpacketmodal'
import PreviousAttachmentsModal from './packet_send_modals/previousattachments'
import EmailPreview from './packet_send_modals/emailPreview'
import DeleteScheduledPacketModal from './packet_send_modals/confirmdeletescheduledpacket'

// packet
import PriceHistoryModal from './packet_modals/pricehistorymodal'
import PacketSentModal from './packet_modals/packetsentmodal'
import LightBoxModal from './packet_modals/lightboxmodal'
// other modals
import NotificationModal from './other_modals/notificationModal'
import DeleteModal from './other_modals/deleteModal'
import CovideoModal from './other_modals/covideomodal'

import ChangeStoreModal from './staff_tools_modals/changestoremodal'
import WordTrackModal from './other_modals/wordtrackmodal'
import { withRouter } from 'react-router'

import ArcCommonIssuesModal from './arc_modals/arccommonissuesmodal'

let abortController = new AbortController()
let abortSignal = abortController.signal

// MODAL_TYPES
// global variables for universal modal setup
// they take the dispatched modal type, check it, and direct to a component to
// host on the user's screen.

// ModalContainer
class ModalContainerComponent extends Component {
  componentDidUpdate(prevProps) {
    console.log('CALLED UPDATE')
    const { type, router } = this.props
    abortController = new AbortController()
    abortSignal = abortController.signal
    // if (!prevProps.type && type) {
    //   router.history.push(`${router.history.location.pathname}`)
    // }
    // if (prevProps.type && !type) {
    //   this.props.router.history.goBack()
    // }
    // disable background scroll on modal open
    const modal = document.querySelector('.modal-content-div')
    const body = document.querySelector('body')
    if (modal) {
      // Disable scroll
      body.style.overflow = 'hidden'
    } else {
      // Enable scroll
      body.style.overflow = 'auto'
    }
  }

  getModal(type) {
    let selectModalList = []
    let selectModal = null

    switch (type) {
      case 'NOTIFICATION_MODAL':
        selectModal = <NotificationModal />
        break
      case 'DASHBOARD_CUSTOMER_MODAL':
        selectModal = <DashCustomerModal />
        break
      case 'DASHBOARD_VIEWS_MODAL':
        selectModal = <DashViewsModal />
        break
      case 'DASHBOARD_SENT_MODAL':
        selectModal = <DashSentModal />
        break
      case 'CUSTOMER_EDIT_MODAL':
        selectModal = <CustomerEditModal />
        break
      case 'CUSTOMER_ADD_MODAL':
        selectModal = <CustomerAddModal />
        break
      case 'CUSTOMER_FORM_MODAL':
        selectModal = <CustomerFormModal />
        break
      case 'ADMIN_TEAMMEMBER_MODAL':
        selectModal = <TeamMemberEditModal />
        break
      case 'SCHEDULED_REPORTS_MODAL':
        selectModal = <ScheduledReportsModal />
        break
      case ADD_EMAIL_TEMPLATE:
        selectModal = <AddEmailTemplate />
        break
      case EDIT_EMAIL_TEMPLATE:
        selectModal = <EditEmailTemplate />
        break
      case VIEW_EMAIL_TEMPLATE:
        selectModal = <ViewEmailTemplate />
        break
      case SELECT_EMAIL_TEMPLATE:
        selectModal = <EmailTemplatesModal />
        break
      case 'STICKER_SUPPORTED_MODAL':
        selectModal = <StickerSupportModal />
        break
      case 'STICKER_PULL_COVERAGE_MODAL':
        selectModal = <StickerPullCoverageModal />
        break
      case 'PRICE_HISTORY_MODAL':
        selectModal = <PriceHistoryModal />
        break
      case 'PACKET_SENT_MODAL':
        selectModal = <PacketSentModal />
        break
      case 'FACET_OPTIONS_MODAL':
        selectModal = <FacetOptionsModal {...this.props.props} />
        break
      case 'PACKET_SEND_MODAL':
        selectModal = (
          <PacketSendModal
            {...this.props.props}
            abortSignal={abortSignal}
            abortController={abortController}
          />
        )
        break
      case 'DELETE_MODAL':
        selectModal = <DeleteModal />
        break
      case 'LIGHTBOX_MODAL':
        selectModal = <LightBoxModal {...this.props.props} />
        break
      case 'REPORT_ISSUE_MODAL':
        selectModal = <ReportIssueModal />
        break
      case 'RESIZE_QR_MODAL':
        selectModal = <ResizeQRModal />
        break
      case 'CHANGE_STORE_MODAL':
        selectModal = <ChangeStoreModal />
        break
      case 'PACKET_ACTIVITY_MODAL':
        selectModal = <PacketActivityModal />
        break
      case 'TOS_MODAL':
        selectModal = <TOSModal />
        break
      case 'TOS_FORM_MODAL':
        selectModal = <TOSFormModal />
        break
      case 'WEBLINK_DISPLAY_MODAL':
        selectModal = <WebLinkDisplayModal />
        break
      case 'WORD_TRACK_MODAL':
        selectModal = <WordTrackModal />
        break
      case 'SCHEDULED_PACKET_MODAL':
        selectModal = <ScheduledPacketModal />
        break
      case 'PREVIOUS_ATTACHMENTS_MODAL':
        selectModal = <PreviousAttachmentsModal />
        break
      case 'EMAIL_PREVIEW':
        selectModal = <EmailPreview />
        break
      case 'COVIDEO_MODAL':
        selectModal = <CovideoModal />
        break
      case 'DELETE_SCHEDULED_PACKET_MODAL':
        selectModal = <DeleteScheduledPacketModal />
        break
      case 'ARC_COMMON_ISSUES_MODAL':
        selectModal = <ArcCommonIssuesModal {...this.props.props} />
        break
      default:
        selectModal = null
    }

    return selectModal
  }

  render() {
    return this.props.typeList.map(type => this.getModal(type))
  }
}

// ***********************LETS*EXPORT*MODAL***********************
const mapStateToProps = state => ({
  type: state.modal.type,
  props: state.modal.props,
  modalList: state.modal.modalList,
  typeList: state.modal.typeList,
})
const ModalContainer = withRouter(ModalContainerComponent)
export default connect(mapStateToProps)(hot(ModalContainer))
