import React, { Component } from 'react'
import USAStateSeed from '../../../core/data/USAStateSeed'
import { EMAIL_REGEX, PHONE_REGEX } from '../../../core/_constants/regextypes'
import MaskedInput from 'react-text-mask'
import { hideModal } from '../../../core/_actions/modalActions'

//PLEASE READ!!
//USAGE:
//---------------------------
//This is a 'Dumb' Component. That means keeping as little data processing in here as possible is important
//Keeping this component 'Dumb' makes it more versatile in future usage
//That is why functions like submit() and cancel() are callbacks passed by props instead of internal to this function
//The parent decides the usage of these methods so that this remains a very adaptable form

class CustomerForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customer: null,
    }
    this.wrapperRef = React.createRef()
    this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this)
  }
  componentDidMount() {
    const { customerId, customer } = this.props
    document.addEventListener('mouseup', this.handleClickOutsideModal)
    if (customerId && customer) {
      this.setState({ customer: customer })
    } else {
      this.setState({
        customer: {
          is_favorite: false,
          first_name: '',
          last_name: '',
          mobile_number: '',
          email: '',
          address1: '',
          city: '',
          state: '',
          postal_code: '',
          phone_number: '',
        },
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutsideModal)
  }

  handleClickOutsideModal(event) {
    const { cancel } = this.props
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      cancel()
    }
  }

  updateField = e => {
    const { customer } = this.state
    let updatedField = { customer }
    if (e.target.name === 'name_field') {
      updatedField.customer[e.target.id] = e.target.value.replace(/[^a-zA-Z\s`-]\(\)/g, '')
    } else if (e.target.id === 'is_favorite') {
      updatedField.customer[e.target.id] = e.target.checked
    } else {
      updatedField.customer[e.target.id] = e.target.value
    }
    this.setState(updatedField)
  }
  submit = allowSubmit => {
    const { submit, customerId } = this.props
    const { customer } = this.state
    if (allowSubmit) {
      submit(customer, customerId)
    }
  }
  renderFullForm() {
    const { customer } = this.state
    const contactMethodProvided =
      (customer.email && customer.email.length > 0 && EMAIL_REGEX.test(customer.email)) ||
      (customer.mobile_number &&
        customer.mobile_number.length > 0 &&
        PHONE_REGEX.test(customer.mobile_number))

    return (
      <div>
        <div className="customer-form-row">
          <label>
            <div>Phone Number</div>
            <MaskedInput
              mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              id="phone_number"
              type="tel"
              value={customer.phone_number}
              onChange={this.updateField}
            />
          </label>
        </div>
        <div className="customer-form-row">
          <label>
            Address
            <input id="address1" value={customer.address1} onChange={this.updateField} />
          </label>
        </div>
        <div className="customer-form-row">
          <label>
            City
            <input id="city" value={customer.city} onChange={this.updateField} />
          </label>
        </div>
        <div className="customer-form-row">
          <label>
            Zip
            <input id="state" value={customer.state} onChange={this.updateField} />
          </label>
        </div>
        <div className="state-box">
          <label>
            State
            <select id="postal_code" value={customer.postal_code} onChange={this.updateField}>
              <option
                key={'blah blah blah unique key blah blah blah'}
                disabled
                hidden
                style={{ display: 'none' }}
                value=""
              >
                Choose State
              </option>
              {USAStateSeed.merica.map(state => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="customer-form-row favorite">
          <label>
            <input
              id="is_favorite"
              type="checkbox"
              checked={customer.is_favorite}
              onChange={this.updateField}
            />
            <svg className="star" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  id="svg_1"
                  d="m33,13.408001l-11.056,-1.607l-4.944,-10.018l-4.944,10.018l-11.056,1.607l8,7.798l-1.889,11.011l9.889,-5.199l9.889,5.199l-1.889,-11.011l8,-7.798z"
                />
              </g>
            </svg>
            <p>{`${customer.is_favorite ? 'Remove from' : 'Add to'} Favorites`}</p>
          </label>
        </div>
      </div>
    )
  }
  renderErrorFeedback() {
    const { hasError, duplicateCustomer, errorMessage } = this.props
    const errToRender =
      hasError && errorMessage != '' ? errorMessage : 'An unknown error has occured!'
    return (
      <div className="customer-form-error">
        <p>{errToRender}</p>
      </div>
    )
  }
  render() {
    const { customer } = this.state
    const {
      submit,
      submitting,
      cancel,
      customerId,
      displayFullForm,
      hasError,
      errorMessage,
      duplicateCustomer,
    } = this.props
    if (submit && customer) {
      const allowSubmit =
        (customerId && customer.first_name.trim().length && customer.last_name.trim().length) ||
        (customer.first_name.trim().length && customer.last_name.trim().length)
      const contactMethodProvided =
        (customer.email && customer.email.length > 0 && EMAIL_REGEX.test(customer.email)) ||
        (customer.mobile_number &&
          customer.mobile_number.length > 0 &&
          PHONE_REGEX.test(customer.mobile_number))

      const hasMobileDuplicate =
        duplicateCustomer && duplicateCustomer.mobile_number !== this.props.customer.mobile_number
      const hasEmailDuplicate =
        duplicateCustomer && duplicateCustomer.email !== this.props.customer.email
      let title = ''
      if (duplicateCustomer) {
        title = 'Found Existing Contact'
      } else {
        title = `${customerId ? 'Edit' : 'Add New'} Contact`
      }
      return (
        <div
          ref={this.wrapperRef}
          className={'customer-form-box' + (duplicateCustomer ? ' duplicate' : '')}
        >
          <h3>{title}</h3>
          {duplicateCustomer && (
            <h5>
              We found an <b>existing contact</b> with this email address or mobile number. Please
              determine the correct information for this contact below.
            </h5>
          )}
          <hr />
          <div className="customer-form-row">
            <label>
              <div
                className={
                  customer.first_name.trim().length < 1 ? 'customer-form-required-active' : ''
                }
              >
                First Name
                {customer.first_name.trim().length < 1 ? <span className="req-ast">*</span> : null}
              </div>
              <input
                id="first_name"
                className={customer.first_name.trim().length < 1 ? 'input-required' : ''}
                type="text"
                value={customer.first_name}
                onChange={this.updateField}
                name="name_field"
                autoFocus
              />
            </label>
          </div>
          <div className="customer-form-row">
            <label>
              <div
                className={
                  customer.last_name.trim().length < 1 ? 'customer-form-required-active' : ''
                }
              >
                Last Name
                {customer.last_name.trim().length < 1 ? <span className="req-ast">*</span> : null}
              </div>
              <input
                id="last_name"
                className={customer.last_name.trim().length < 1 ? 'input-required' : ''}
                type="text"
                value={customer.last_name}
                onChange={this.updateField}
                name="name_field"
              />
            </label>
          </div>
          <div className="form-contact-methods">
            <div className="customer-form-row email">
              <label>
                {hasEmailDuplicate ? (
                  <React.Fragment>
                    <div className="customer-form-conflict-heading">Email Conflict</div>
                    <div
                      onClick={() => {
                        let { customer } = this.state
                        customer.email = duplicateCustomer.email
                        this.setState({ customer })
                      }}
                      className="customer-form-conflict"
                      title={duplicateCustomer.email}
                    >
                      {duplicateCustomer.email.length ? duplicateCustomer.email : '[NONE]'}
                    </div>
                    <div className="customer-form-conflict-or">OR</div>
                    <div
                      onClick={() => {
                        let { customer } = this.state
                        customer.email = this.props.customer.email
                        this.setState({ customer })
                      }}
                      className="customer-form-conflict"
                      title={this.props.customer.email}
                    >
                      {this.props.customer.email.length ? this.props.customer.email : '[NONE]'}
                    </div>
                  </React.Fragment>
                ) : (
                  <div>Email</div>
                )}
                <input
                  className={`${hasEmailDuplicate ? 'customer-form-conflict-field' : ''}`}
                  id="email"
                  value={customer.email}
                  onChange={this.updateField}
                />
              </label>
            </div>
            <div className={'customer-form-row mobile'}>
              <label>
                {hasMobileDuplicate ? (
                  <React.Fragment>
                    <div className="customer-form-conflict-heading">Mobile Number Conflict</div>
                    <div
                      onClick={() => {
                        let { customer } = this.state
                        customer.mobile_number = duplicateCustomer.mobile_number
                        console.log(customer)
                        this.setState({ customer })
                      }}
                      className="customer-form-conflict"
                      title={duplicateCustomer.mobile_number.replace(/[- )(]/g, '')}
                    >
                      {duplicateCustomer.mobile_number.replace(/[- )(]/g, '').length
                        ? duplicateCustomer.mobile_number.replace(/[- )(]/g, '')
                        : '[NONE]'}
                    </div>
                    <div className="customer-form-conflict-or">OR</div>
                    <div
                      onClick={() => {
                        let { customer } = this.state
                        customer.mobile_number = this.props.customer.mobile_number
                        console.log(customer)
                        this.setState({ customer })
                      }}
                      className="customer-form-conflict"
                      title={this.props.customer.mobile_number.replace(/[- )(]/g, '')}
                    >
                      {this.props.customer.mobile_number.replace(/[- )(]/g, '').length
                        ? this.props.customer.mobile_number.replace(/[- )(]/g, '')
                        : '[NONE]'}
                    </div>
                  </React.Fragment>
                ) : (
                  <div>Mobile Number</div>
                )}
                <MaskedInput
                  mask={[
                    '(',
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  id="mobile_number"
                  type="tel"
                  value={customer.mobile_number}
                  onChange={this.updateField}
                  className={`${hasMobileDuplicate ? 'customer-form-conflict-field' : ''}`}
                />
              </label>
            </div>
          </div>
          {displayFullForm ? this.renderFullForm() : null}
          {hasError ? this.renderErrorFeedback() : null}
          <button
            className="contact-save-btn"
            onClick={() => {
              this.submit(allowSubmit)
            }}
            disabled={!allowSubmit || submitting}
          >
            Save
          </button>
          {cancel && (
            <button
              className="customer-form-cancel"
              onClick={() => {
                cancel()
              }}
            >
              Cancel
            </button>
          )}
        </div>
      )
    }
    return <div className="no_submit_function_given" />
  }
}
export default CustomerForm
