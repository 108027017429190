// general: modal wrapper file.

// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideModal } from '../../core/_actions/modalActions'

import './styles/modalRoot.scss'
import CloseIcon from './assets/closeicon.js'

type Props = {
  hideModal: Function,
  overlayStyle: Object,
  contentStyle: Object,
  dialogStyle: Object,
  title: Function,
  children: Object,
}

class Modal extends Component {
  constructor(props) {
    super(props)
    this._onOverlayClick = this.onOverlayClick.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      if (this.props.hideModal) {
        // eslint-disable-next-line no-undef
        window.addEventListener('keydown', this.listenKeyboard, true)
      }
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    if (this.props.hideModal) {
      // eslint-disable-next-line no-undef
      window.removeEventListener('keydown', this.listenKeyboard, true)
    }
  }

  // ***********************LETS*DO*CLICK*EVENTS***********************
  // onOverlayClick
  onOverlayClick = e => {
    if (e.target.className == 'modal-content-div') {
      this.props.hideModal()
    }
  }

  // onDialogClick
  onDialogClick = event => {
    event.stopPropagation()
  }
  // ***********************LETS*DO*CLICK*EVENTS***********************

  // ***********************LETS*DO*SETUP***********************
  // title
  get title() {
    const { title } = this.props

    if (title) {
      return (
        <div className="modal__title">
          <h1>{title}</h1>
        </div>
      )
    }

    return null
  }

  // close
  get close() {
    const { hideModal } = this.props

    if (hideModal) {
      return <div className="modal__close" onClick={hideModal} />
    }

    return null
  }
  // ***********************LETS*DO*SETUP***********************

  // ***********************LETS*DO*LISTENERS***********************
  // listenKeyboard
  listenKeyboard = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.hideModal()
    }
  }
  // ***********************LETS*DO*LISTENERS***********************

  props: Props
  _onOverlayClick: Function // function

  render() {
    // styles
    const overlayStyle = this.props.overlayStyle ? this.props.overlayStyle : {}
    const contentStyle = this.props.contentStyle ? this.props.contentStyle : {}
    const dialogStyle = this.props.dialogStyle ? this.props.dialogStyle : {}

    return (
      <React.Fragment>
        <div
          className="modal-overlay-div"
          style={{ ...overlayStyle, animation: ` 1000ms fadeIn` }}
        />
        <div
          className="modal-content-div"
          style={{ ...contentStyle, animation: `150ms pulse` }}
          onMouseDown={this._onOverlayClick}
        >
          <div
            className={!this.props.emailModal ? 'modal-dialog-div' : 'modal-dialog-div email'}
            style={dialogStyle}
            onClick={this.onDialogClick}
          >
            <button className="modal-exit-btn" onClick={this.props.hideModal}>
              <CloseIcon />
            </button>
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, {
  hideModal,
})(Modal)
