import React from 'react'

const SearchIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M48.37,41.72l-7.94-7.94a4.32,4.32,0,0,0-4.87-.84l-.65-.73a19.88,19.88,0,1,0-3,2.91l.68.75a4.27,4.27,0,0,0,.8,4.93l7.94,8a4.32,4.32,0,0,0,6.08,0l.94-1A4.3,4.3,0,0,0,48.37,41.72Zm-42.71-22a14,14,0,1,1,14,14A14,14,0,0,1,5.66,19.73Z" />
    </svg>
  )
}
export default SearchIcon
