import {
  GET_COMPANY_TEMPLATES,
  GET_STORE_TEMPLATES,
  GET_USER_TEMPLATES,
  CHANGE_TEMPLATE_TYPE,
  ADD_EMAIL_TEMPLATE,
  REMOVE_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  SELECT_EMAIL_TEMPLATE,
} from '../_constants/emailTemplateTypes'

const initState = {
  templateTypes: ['Company', 'Store', 'User'],
  currentTemplateType: null,
  companyTemplates: [],
  storeTemplates: [],
  userTemplates: [],
  currentStoreId: -1,
  visitedStores: [],
  visitedCompanies: [],
  selectedTemplate: null,
}

export function emailTemplates(state, action) {
  if (!state) {
    state = initState
  }

  switch (action.type) {
    case ADD_EMAIL_TEMPLATE:
      let newEmailTemplates

      if (action.templateType === 'Company') {
        newEmailTemplates = [...state.companyTemplates]
        if (newEmailTemplates.includes(action.newTemplate)) {
        } else {
          newEmailTemplates.push(action.newTemplate)
        }

        return {
          ...state,
          companyTemplates: [...newEmailTemplates],
        }
      } else if (action.templateType === 'Store') {
        newEmailTemplates = [...state.storeTemplates]
        if (newEmailTemplates.includes(action.newTemplate)) {
        } else {
          newEmailTemplates.push(action.newTemplate)
        }

        return {
          ...state,
          storeTemplates: [...newEmailTemplates],
        }
      } else {
        newEmailTemplates = [...state.userTemplates]
        if (newEmailTemplates.includes(action.newTemplate)) {
        } else {
          newEmailTemplates.push(action.newTemplate)
        }

        return {
          ...state,
          userTemplates: [...newEmailTemplates],
        }
      }

    case REMOVE_EMAIL_TEMPLATE:
      if (action.templateType === 'Company') {
        newEmailTemplates = [...state.companyTemplates]
        if (newEmailTemplates.includes(action.removedTemplate)) {
          const removedTemplateIdx = newEmailTemplates.indexOf(action.removedTemplate)
          newEmailTemplates.splice(removedTemplateIdx, 1)
        }

        return {
          ...state,
          companyTemplates: [...newEmailTemplates],
        }
      } else if (action.templateType === 'Store') {
        newEmailTemplates = [...state.storeTemplates]
        if (newEmailTemplates.includes(action.removedTemplate)) {
          const removedTemplateIdx = newEmailTemplates.indexOf(action.removedTemplate)
          newEmailTemplates.splice(removedTemplateIdx, 1)
        }

        return {
          ...state,
          storeTemplates: [...newEmailTemplates],
        }
      } else {
        newEmailTemplates = [...state.userTemplates]
        if (newEmailTemplates.includes(action.removedTemplate)) {
          const removedTemplateIdx = newEmailTemplates.indexOf(action.removedTemplate)
          newEmailTemplates.splice(removedTemplateIdx, 1)
        }

        return {
          ...state,
          userTemplates: [...newEmailTemplates],
        }
      }

    case EDIT_EMAIL_TEMPLATE:
      if (action.templateType === 'Company') {
        newEmailTemplates = [...state.companyTemplates]
        if (newEmailTemplates.includes(action.selectedTemplate)) {
          const selectedTemplateIdx = newEmailTemplates.indexOf(action.selectedTemplate)
          newEmailTemplates.splice(selectedTemplateIdx, 1, action.editedTemplate)
        }

        return {
          ...state,
          companyTemplates: [...newEmailTemplates],
        }
      } else if (action.templateType === 'Store') {
        newEmailTemplates = [...state.storeTemplates]
        if (newEmailTemplates.includes(action.selectedTemplate)) {
          const selectedTemplateIdx = newEmailTemplates.indexOf(action.selectedTemplate)
          newEmailTemplates.splice(selectedTemplateIdx, 1, action.editedTemplate)
        }

        return {
          ...state,
          storeTemplates: [...newEmailTemplates],
        }
      } else {
        newEmailTemplates = [...state.userTemplates]
        if (newEmailTemplates.includes(action.selectedTemplate)) {
          const selectedTemplateIdx = newEmailTemplates.indexOf(action.selectedTemplate)
          newEmailTemplates.splice(selectedTemplateIdx, 1, action.editedTemplate)
        }

        return {
          ...state,
          userTemplates: [...newEmailTemplates],
        }
      }

    case GET_COMPANY_TEMPLATES:
      const currentVisitedCompanies = [...state.visitedCompanies]
      if (!currentVisitedCompanies.includes(action.companyId)) {
        const newCompanyTemplatesSet = new Set(
          [...state.companyTemplates].concat(action.companyTemplates),
        )
        const newCompanyTemplates = Array.from(newCompanyTemplatesSet)
        const newVisitedCompanies = [...state.visitedCompanies]
        newVisitedCompanies.push(action.companyId)
        return {
          ...state,
          companyTemplates: [...newCompanyTemplates],
          visitedCompanies: [...newVisitedCompanies],
        }
      }

    case GET_STORE_TEMPLATES:
      if (!action.storeTemplates) {
        return {
          ...state,
        }
      }
      if (!state.visitedStores.includes(action.storeId)) {
        const newStoreTemplatesSet = new Set(state.storeTemplates.concat(action.storeTemplates))
        const newStoreTemplates = Array.from(newStoreTemplatesSet)
        const newVisitedStores = state.visitedStores.push(action.storeId)
        return {
          ...state,
          storeTemplates: newStoreTemplates,
          visitedStores: [...newVisitedStores],
        }
      }

    case GET_USER_TEMPLATES:
      const currentUserTemplates = [...state.userTemplates]
      const newUserTemplates = currentUserTemplates.concat(action.userTemplates)
      return {
        ...state,
        userTemplates: [...newUserTemplates],
      }

    case SELECT_EMAIL_TEMPLATE:
      let newSelectedTemplate = action.selectedTemplate
      return {
        ...state,
        selectedTemplate: newSelectedTemplate,
      }

    case CHANGE_TEMPLATE_TYPE:
      let newTemplateType = action.newTemplateType
      return {
        ...state,
        currentTemplateType: newTemplateType,
      }

    default:
      return state
  }
}
