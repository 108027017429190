import React from 'react'

const priceHistory = props => {
  return (
    <svg
      className="price-history-styling"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 153.7 48.34"
    >
      <title>Price History</title>
      <line className="phb-a" x1="23.19" y1="37.23" x2="23.16" y2="37.29" />
      <line className="phb-a" x1="23.16" y1="37.29" x2="23.12" y2="37.23" />
      <path
        className="phb-b"
        d="M145.65,2H25.28a8.15,8.15,0,0,0-7.11,4.29L3.12,33.4c-3.18,5.74.78,12.94,7.12,12.94H145.65a6.18,6.18,0,0,0,6-6.26V8.26A6.18,6.18,0,0,0,145.65,2Z"
      />
      <path
        className="phb-delta"
        d="M44.06,36,29.59,11a3,3,0,0,0-5.16,0L10,36a3,3,0,0,0,2.57,4.48H41.48A3,3,0,0,0,44.06,36Zm-16.21,1.1H16.11A1.38,1.38,0,0,1,14.92,35l5.87-10.13a1.38,1.38,0,0,1,2.39,0L29,35A1.38,1.38,0,0,1,27.85,37.06Z"
      />
      <text className="phb-c" transform="translate(51.18 22.2)">
        PRICE
        <tspan x="0" y="18">
          HIS
        </tspan>
        <tspan className="phb-d" x="34.91" y="18">
          T
        </tspan>
        <tspan x="47.06" y="18">
          O
        </tspan>
        <tspan className="phb-e" x="63.76" y="18">
          R
        </tspan>
        <tspan x="77.37" y="18">
          Y
        </tspan>
      </text>
    </svg>
  )
}
export default priceHistory
