// general: 1/6 of the customers component - parent for customer dash - (3rd).

// TODO:
// 1. mobile support
// 2. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

// core files
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
import CustomerActions from '../../core/actions/users/customeractions'

// components
import CustomersAlerts from './components/customersalerts'
import CustomersHistory from './components/customershistory'
import CustomersView from './components/customersview'
// redux
import { CUSTOMER_FORM_MODAL } from '../../core/_constants/modalTypes'
import { showModal, hideModal } from '../../core/_actions/modalActions'
import runtimeEnv from '../../core/config/envConstants'
import IconTrash from '../../core/assets/images/icons/icon-trash'
import IconEmail from '../../core/assets/images/icons/icon-email'
import IconPhone from '../../core/assets/images/icons/icon-phone'
import IconMobile from '../../core/assets/images/icons/icon-mobile'

import {
  stashViewedCustomer,
  stashCustomerTableState,
} from '../../core/_actions/customerstashActions'
// css styles
import '../styles/universalstyles.scss'

type Props = {
  userInfo: Object,
}

export class CustomersDashComponent extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      userInfo: this.props.userInfo,
      customerId: null,
      pendingDeletion: false,
      deleted: false,
    }
    this._CustomersViewShowModals = this.CustomersViewShowModals.bind(this)
    this._DeleteCustomer = this.DeleteCustomer.bind(this)
  }

  state: {
    userInfo: Object,
    customerId: number,
  }

  componentDidMount() {
    const { dj_token } = this.props.userInfo
    this.mounted = true // set 'mounted' to true.

    if (this.mounted) {
      // get customer_id
      // eslint-disable-next-line no-undef
      const customerIdFirstHalf = window.location.href.split('customerinfo/')[1]
      // eslint-disable-next-line no-undef
      const customerId = customerIdFirstHalf.split('/')[0]

      this.setState({ customerId })

      // Call API for most up-to-date customer information
      CustomerActions.getSingleContact(customerId, dj_token)
        .then(json => {
          if (json.customer_id !== null && json.customer_id !== undefined) {
            this.props.stashViewedCustomer(json)
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }

  componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.
    this.setState({ userInfo: newProps.userInfo })
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    this.setState({ userInfo: {}, customerId: null })
    this.props.stashViewedCustomer(null)
  }

  DeleteCustomer() {
    const { customerId } = this.state
    const { dj_token } = this.props.userInfo
    const { pendingDeletion } = this.state
    console.log('deleting')

    if (!pendingDeletion) {
      this.setState({ pendingDeletion: true })
    } else {
      CustomerActions.putDeleteSingleContact(customerId, dj_token)
        .then(json => {
          console.log(json)

          window.location.href = '/customers'
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }

  CustomersViewShowModals() {
    this.props.showModal(CUSTOMER_FORM_MODAL, {
      customer: this.props.viewed_customer,
      customerId: this.props.viewed_customer.customer_id,
      displayFullForm: true,
    })
  }

  renderConfirmDelete() {
    return (
      <div className="customer-confirm-delete">
        <p> Are you sure you want to delete this contact? </p>
        <button
          className="confirm-delete-yes"
          onClick={() => {
            this._DeleteCustomer()
          }}
        >
          {' '}
          Yes{' '}
        </button>
        <button
          className="confirm-delete-no"
          onClick={() => {
            this.setState({ pendingDeletion: false })
          }}
        >
          {' '}
          No{' '}
        </button>
      </div>
    )
  }

  // ***********************LETS*SETUP*CONTAINERS***********************
  // CustomerDashViewColleciton
  CustomerDashViewColleciton() {
    // state
    const { customerId } = this.state

    if (customerId !== null && customerId !== undefined) {
      return (
        <div className="subnav-bar">
          <div className="subnav-content">
            <div className="subnav-left">
              <Link to="/customers" className="back-to-customers">
                Back
              </Link>
            </div>
            <div className="subnav-center">
              <NavLink to={`/customerinfo/${customerId}/alerts`} className="cust_info_btn">
                Alerts
              </NavLink>
              {/*
              <NavLink to={`/customerinfo/${customerId}/info`} className="cust_info_btn">
                Info
              </NavLink>
              */}
              <NavLink className="cust_history_btn" to={`/customerinfo/${customerId}/history`}>
                History
              </NavLink>
            </div>
            <div className="subnav-right" />
          </div>
        </div>
      )
    }
  }

  // CustomersDashViewSwitcher
  CustomersDashViewSwitcher() {
    // states
    const { userInfo, customerId } = this.state

    // get page focus
    // eslint-disable-next-line no-undef
    const pagefocusFirstHalf = window.location.href.split('customerinfo/')[1]
    // eslint-disable-next-line no-undef
    const pageOn = pagefocusFirstHalf.split('/')[1]

    if (customerId !== null && customerId !== undefined) {
      return (
        <span>
          {pageOn === 'alerts' ? (
            <CustomersAlerts DJtoken={userInfo.dj_token} customerId={customerId} />
          ) : null}
          {pageOn === 'info' ? (
            <CustomersView DJtoken={userInfo.dj_token} customerId={customerId} />
          ) : null}
          {pageOn === 'history' ? (
            <CustomersHistory
              DJtoken={userInfo.dj_token}
              customerId={customerId}
              storeId={userInfo.default_store_id}
            />
          ) : null}
        </span>
      )
    }
  }

  renderCustomerCard() {
    const { pendingDeletion } = this.state
    const { viewed_customer } = this.props
    console.log(viewed_customer)
    if (viewed_customer) {
      return (
        <div className="customer-dash-card">
          <div className="customer-card">
            <div
              style={{
                verticalAlign: 'middle',
                display: 'inline-block',
                fontSize: '1.3em',
                maxWidth: '250px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                lineHeight: '1.3em',
              }}
            >
              <b>
                {` ${
                  this.props.viewed_customer.first_name
                    ? this.props.viewed_customer.first_name
                    : 'None'
                } ${
                  this.props.viewed_customer.last_name
                    ? this.props.viewed_customer.last_name
                    : 'None'
                }`}
              </b>
            </div>
            <button className="customer-edit-button" onClick={this._CustomersViewShowModals}>
              EDIT
            </button>
            <br />
            <span>
              <IconEmail />
              {this.props.viewed_customer.email ? this.props.viewed_customer.email : 'None'}
            </span>
            <br />
            <span>
              <IconPhone />
              {this.props.viewed_customer.phone_number
                ? this.props.viewed_customer.phone_number.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '($1) $2-$3\xa0\xa0\xa0',
                  )
                : 'None'}
            </span>
            <span>
              <IconMobile />
              {this.props.viewed_customer.mobile_number
                ? this.props.viewed_customer.mobile_number.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    '($1) $2-$3',
                  )
                : 'None'}
            </span>
          </div>
          {/*!runtimeEnv.isProd ? (
              <button className="customer-delete-button" onClick={this._DeleteCustomer}>
                <IconTrash /> Delete Customer
              </button>
            ) : null */}
          {pendingDeletion ? this.renderConfirmDelete() : null}
        </div>
      )
    }
    return <React.Fragment />
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    return (
      <div className="customer-dash">
        {this.CustomerDashViewColleciton()}
        {this.renderCustomerCard()}
        {this.CustomersDashViewSwitcher()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  viewed_customer: state.customerstash.viewed_customer,
})

const CustomersDash = UserInfoWrapper(CustomersDashComponent)
export default connect(mapStateToProps, {
  stashViewedCustomer,
  hideModal,
  showModal,
})(CustomersDash)
