import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Helper from '../../../core/helpers/platformhelper'
// core files
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import { CUSTOMER_FORM_MODAL } from '../../../core/_constants/modalTypes'
import { showModal, hideModal } from '../../../core/_actions/modalActions'

import EditIcon from '../../../core/assets/images/icons/editicon'
import IconEmail from '../../../core/assets/images/icons/icon-email'
import IconPhone from '../../../core/assets/images/icons/icon-phone'
import IconMobile from '../../../core/assets/images/icons/icon-mobile'

const emptyField = 'none'
const emptyField2 = ''

type Props = {
  userInfo: Object,
}

export class CustomersRow extends Component {
  constructor(props: Props) {
    super(props)
    this._CustomersViewShowModals = this.CustomersViewShowModals.bind(this)
  }
  CustomersViewShowModals() {
    this.props.showModal(CUSTOMER_FORM_MODAL, {
      customer: this.props.customer,
      customerId: this.props.customer.customer_id,
      displayFullForm: true,
      updateFunction: this.props.update,
    })
  }
  render() {
    const { customer } = this.props
    if (customer) {
      return (
        <div className="customer-display">
          <div className="customer-display-name">
            <Link to={`/customerinfo/${customer.customer_id}/alerts`}>
              {`${customer.first_name ? customer.first_name : emptyField2} ${
                customer.last_name ? customer.last_name : emptyField2
              }`}
            </Link>
          </div>
          <div className="customer-display-field customer-display-field-email">
            <div
              title={customer.email ? customer.email : emptyField}
              className="customer-display-field-icon"
            >
              <IconEmail color="rgb(85,85,85)" />
            </div>
            <div
              title={customer.email ? customer.email : emptyField}
              className="customer-display-field-data"
            >
              {`${customer.email ? customer.email : emptyField}`}
            </div>
          </div>
          <div className="customer-display-field customer-display-field-phone">
            <div
              title={Helper.prettyPhoneNumber(
                customer.phone_number ? customer.phone_number : emptyField,
              )}
              className="customer-display-field-icon"
            >
              <IconPhone color="rgb(85,85,85)" />
            </div>
            <div
              title={Helper.prettyPhoneNumber(
                customer.phone_number ? customer.phone_number : emptyField,
              )}
              className="customer-display-field-data"
            >
              {`${Helper.prettyPhoneNumber(
                customer.phone_number ? customer.phone_number : emptyField,
              )}`}
            </div>
          </div>
          <div className="customer-display-field customer-display-field-mobile">
            <div
              title={Helper.prettyPhoneNumber(
                customer.mobile_number ? customer.mobile_number : emptyField,
              )}
              className="customer-display-field-icon"
            >
              <IconMobile color="rgb(85,85,85)" />
            </div>
            <div
              title={Helper.prettyPhoneNumber(
                customer.mobile_number ? customer.mobile_number : emptyField,
              )}
              className="customer-display-field-data"
            >
              {`${Helper.prettyPhoneNumber(
                customer.mobile_number ? customer.mobile_number : emptyField,
              )}`}
            </div>
          </div>
          <div className="customer-display-edit">
            <div className="edit-icon" onClick={this._CustomersViewShowModals}>
              <EditIcon />
            </div>
          </div>
        </div>
      )
    }
    return <React.Fragment />
  }
}
const mapStateToProps = state => ({
  edited_customer: state.customerstash.viewed_customer,
})

const CustomersRowWrapped = UserInfoWrapper(CustomersRow)
export default connect(mapStateToProps, {
  hideModal,
  showModal,
})(CustomersRowWrapped)
