import React, { Component } from 'react'
import { connect } from 'react-redux'
import './styles/archivedinventory.scss'
import { getArchivedPackets } from '../../core/actions/inventoryactions.js'
import Car from '../inventory/components/car'
import { showModal, hideModal, setActiveSingleSend } from '../../core/_actions/modalActions'
import { unlockIntercomMessenger } from '../../core/actions/users/useractions'
import LoadingSpinner from '../helpers/loadingspinner'
import SearchIcon from '../inventory/assets/searchicon.js'
import IconCancel from '../../core/assets/images/icons/icon-x'
import BottomScrollListener from 'react-bottom-scroll-listener'
import StoreSelect from '../tools/components/storeselect'
import { toast } from 'react-toastify'

import {
  PRICE_HISTORY_MODAL,
  PACKET_SEND_MODAL,
  PACKET_ACTIVITY_MODAL,
} from '../../core/_constants/modalTypes'

let abortController = new AbortController()

class ArchivedInventory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      activeSearch: '',
      archivedPackets: [],
      archivedPacketsPage: 1,
      searchedPackets: [],
      loading: true,
      endOfResults: false,
      scrollLoad: false,
      requestedIntercom: false,
    }
  }
  componentDidMount() {
    let { activeStoreId } = this.props

    if (activeStoreId > -1) {
      this.setState(
        {
          archivedPackets: [],
          archivedPacketsPage: 1,
          searchedPackets: [],
          loading: true,
          endOfResults: false,
          scrollLoad: false,
        },
        () => {
          this.searchArchivedInventory()
        },
      )
    }
  }
  componentDidUpdate(prevProps) {
    let { activeStoreId } = this.props

    if (activeStoreId > -1 && activeStoreId !== prevProps.activeStoreId) {
      this.setState(
        {
          archivedPackets: [],
          archivedPacketsPage: 1,
          searchedPackets: [],
          loading: true,
          endOfResults: false,
          scrollLoad: false,
        },
        () => {
          this.searchArchivedInventory()
        },
      )
    }
  }

  scrollSearch = () => {
    const { loading, activeSearch, endOfResults, scrollLoad } = this.state
    if (!loading && !activeSearch.length && !endOfResults) {
      this.setState({ scrollLoad: true })
      this.searchArchivedInventory(false)
    }
  }
  paginateSearch = () => {
    const { archivedPacketsPage } = this.state
    this.setState({
      archivedPacketsPage: archivedPacketsPage + 1,
    })
    this.searchArchivedInventory()
  }
  searchArchivedInventory = searchEnabled => {
    let { query, archivedPackets, archivedPacketsPage, endOfResults, activeSearch } = this.state
    const { token, activeStoreId } = this.props
    if (query.length && searchEnabled) {
      this.setState({ loading: true, activeSearch: query })
      abortController.abort()
      abortController = new AbortController()
      getArchivedPackets({
        token,
        signal: abortController.signal,
        searchQuery: query,
        page: 1,
      }).then(res => {
        if (res && res.results) {
          this.setState({
            archivedPackets: res.results,
            archivedPacketsPage: 1,
            endOfResults: false,
            searchedPackets: res.results,
            loading: false,
            scrollLoad: false,
          })
        }
      })
    } else {
      if (activeSearch.length) {
        this.setState({ loading: true, activeSearch: '', query: '' })
      }
      abortController.abort()
      abortController = new AbortController()
      getArchivedPackets({
        token,
        signal: abortController.signal,
        searchQuery: '',
        selectedStores: [activeStoreId],
        page: archivedPacketsPage,
      }).then(res => {
        if (res && res.results) {
          archivedPackets = archivedPackets.concat(res.results)
          if (!res.results.length) {
            endOfResults = true
          }
          this.setState({
            archivedPackets,
            archivedPacketsPage: archivedPacketsPage + 1,
            endOfResults,
            searchedArchivedPackets: [],
            loading: false,
            scrollLoad: false,
          })
        }
      })
    }
    console.log(this.state)
  }
  updateQuery = query => {
    //ADD TEXT VALIDATION
    this.setState({ query })
  }
  priceHistoryModal = args => {
    const { vehicle_id, vin, stock_num, currency_unit } = args
    this.props.showModal(PRICE_HISTORY_MODAL, {
      title: 'Price History',
      vehicle_id,
      vin,
      stock_num,
      token: this.props.token,
      currency_unit,
    })
  }
  renderStoreSelector = () => {
    return <div>Store Selector</div>
  }
  renderSearch = () => {
    let { query, activeSearch } = this.state
    return (
      <div class="archived-packet-search">
        <div class="archived-packet-search-field">
          <label style={{ display: 'none' }} htmlFor="archived-packet-search-input">
            Document Upload Input
          </label>
          <input
            id="archived-packet-search-input"
            placeholder="Type here to begin your search"
            type="text"
            value={query}
            onChange={e => {
              this.updateQuery(e.target.value)
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                this.searchArchivedInventory(true)
              }
            }}
          />
          {query.length > 0 && (
            <div
              className="archived-packet-search-reset"
              onClick={() => {
                if (activeSearch.length) {
                  this.setState(
                    {
                      query: '',
                      activeSearch: '',
                      archivedPackets: [],
                      archivedPacketsPage: 1,
                      searchedPackets: [],
                      loading: true,
                      endOfResults: false,
                      scrollLoad: false,
                      requestedIntercom: false,
                    },
                    () => {
                      this.searchArchivedInventory()
                    },
                  )
                }
              }}
            >
              <IconCancel />
            </div>
          )}
        </div>
        {this.renderSearchControl()}
      </div>
    )
  }
  renderSearchControl() {
    let { activeSearch } = this.state
    return (
      <div
        className="archived-packet-search-button"
        onClick={() => {
          this.searchArchivedInventory(true)
        }}
      >
        <SearchIcon color={'white'} />
      </div>
    )
  }
  renderArchived = () => {
    let { archivedPackets, loading, requestedIntercom, activeSearch } = this.state
    const { token } = this.props
    if (!loading && !archivedPackets.length) {
      return <div className="archived-packet-no-results">No Results Found</div>
    }
    return (
      <div className="archived-packet-vehicles" style={{ animation: `1250ms fadeIn` }}>
        {!activeSearch.length && (
          <React.Fragment>
            <h3>Recently Archived</h3>
            <hr />
          </React.Fragment>
        )}
        {archivedPackets.map((vehicle, i) => {
          return (
            <React.Fragment>
              <Car
                animation={'none'}
                animationDelay={(i + 1) * 30}
                inBasket={false}
                priceHistory={this.priceHistoryModal}
                viewPacket={true}
                key={`${vehicle.vehicle_id} ${i}`}
                vehicle={vehicle}
                unlockIntercomMessenger={() => {
                  if (!requestedIntercom) {
                    unlockIntercomMessenger({ token, vehicle_id: vehicle.vehicle_id }).then(() => {
                      toast('Request Received! A Digital Success Manager will reach out shortly')
                    })
                  } else {
                    toast('A Digital Success Manager will reach out shortly!')
                  }
                }}
              />
              {console.log(vehicle)}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
  renderLoadMore = () => {
    const { loading, activeSearch, endOfResults, scrollLoad } = this.state
    if (!loading && !activeSearch.length && !endOfResults) {
      return (
        <button
          className="archived-packet-load-more"
          onClick={() => {
            this.setState({ scrollLoad: true })
            this.searchArchivedInventory(false)
          }}
        >
          {scrollLoad ? (
            <LoadingSpinner timeout={120000} loading />
          ) : (
            <React.Fragment>Load More</React.Fragment>
          )}
        </button>
      )
    }
  }
  render() {
    const { loading, activeSearch } = this.state
    const { defaultStoreId, activeStoreId } = this.props
    console.log('STATE HERE')
    console.log(this.state)

    return (
      <div>
        <div class="inventory-page page-content archived-packet">
          {activeStoreId && (
            <React.Fragment>
              <BottomScrollListener onBottom={this.scrollSearch} offset={200} />
              <h1>Archived Packet</h1>
              <h2>
                Search a <b>VIN</b> or <b>Stock #</b> to find a specific archived packet
              </h2>
              {this.renderSearch()}
              <div class="archived-packet-store-select">
                {defaultStoreId && !activeSearch.length && (
                  <div style={loading ? { opacity: '0' } : {}}>
                    <StoreSelect />
                  </div>
                )}
              </div>
              <div
                style={{
                  minHeight: '150px',
                  position: 'relative',
                  marginBottom: loading ? 150 : 0,
                }}
              >
                {loading ? <LoadingSpinner loading /> : this.renderArchived()}
              </div>
              {this.renderLoadMore()}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  token: state.authentication.user && state.authentication.user.token,
  activeStoreId: state.tools && state.tools.activeStoreId,
  defaultStoreId: state.authentication.user.user.profile.default_company_store,
})
export default connect(mapStateToProps, {
  hideModal,
  showModal,
  setActiveSingleSend,
})(ArchivedInventory)
