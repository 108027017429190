// general: entire footer component.

// TODO:
// 1. cross browser check support

// Note: all styles for the footer are contained in here due to there not being
// much .css for this component at the time.

// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { showModal } from '../../core/_actions/modalActions'
import { TOS_MODAL } from '../../core/_constants/modalTypes'
import { version } from '../../../../package.json'

//styles
import './styles/layout.scss'

type Props = {} // none.

// global styles
const footerStyle = {
  overflow: 'hidden',
  width: '100%',
  padding: '30px 0px',
  color: '#666666',
}

const footerTextStyle = {
  textAlign: 'center',
  fontWeight: 'normal',
  marginBottom: '0px',
}

class Footer extends PureComponent {
  props: Props

  render() {
    return (
      <div className="footer">
        <h5 style={footerTextStyle}>
          © {new Date().getFullYear()} AutoIPacket, LLC® All rights reserved.
        </h5>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            this.props.showModalConnect(TOS_MODAL, {})
          }}
        >
          Terms of Service
        </span>
        <h5 style={footerTextStyle}>{version}</h5>
      </div>
    )
  }
}

export default connect(null, {
  showModalConnect: showModal,
})(Footer)
