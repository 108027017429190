import React from 'react'
import { connect } from 'react-redux'

import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import AccountSettingsForm from './components/accountsettingsform'
import AvatarChange from './components/avatarchange'
// css styles
import './styles/accountsettings.scss'

const AccountSettings = () => {
  const userID = useSelector(
    state => state.authentication.user.user.profile.legacy_user.legacy_user_id,
  )
  const token = useSelector(state => state.authentication.access_token)
  const username = useSelector(state => state.authentication.user.user.username)
  const prefID = useSelector(state => state.authentication.user.user.profile.profile_prefs[0])

  return (
    <div style={{ animation: ` 1500ms fadeIn` }}>
      <div className="account-settings-page">
        <div className="account-settings-sidebar">
          <AvatarChange user_id={userID} username={username} token={token} />
        </div>
        <div className="account-settings-main">
          <AccountSettingsForm />
        </div>
      </div>
    </div>
  )
}

const AccountSettingsWithRouter = withRouter(AccountSettings)
export default AccountSettingsWithRouter
