import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  ADD_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  VIEW_EMAIL_TEMPLATE,
} from '../../../core/_constants/modalTypes'

import { showModal } from '../../../core/_actions/modalActions'
import {
  updateCompanyTemplateList,
  updateStoreTemplateList,
  getCompanyTemplates,
  getStoreTemplates,
  editTemplate,
  updateEmailTemplate,
} from '../../../core/actions/emailTemplateActions'
import profileactions from '../../../core/actions/users/profileactions'

import ToolTip from '../../helpers/tooltip'
import LoadingSpinner from '../../helpers/loadingspinner'

import IconAddItem from '../../../core/assets/images/icons/icon-additem'
import EditIcon from '../../../core/assets/images/icons/editicon'
import Warning from '../../../core/assets/images/icons/warning'
import IconX from '../../../core/assets/images/icons/icon-x'
import EyeOpenIcon from '../../../core/assets/images/icons/eye-open_icon'

function EmailTemplates(props) {
  const {
    companyTemplates,
    storeTemplates,
    showModalConnect,
    updateCompanyTemplateListConnect,
    updateStoreTemplateListConnect,
    editTemplateConnect,
    token,
    companyId,
    companyName,
    user_id,
    defaultStoreId,
    defaultStoreName,
  } = props
  const [companySearchValue, setCompanySearchValue] = useState('')
  const [storeSearchValue, setStoreSearchValue] = useState('')
  const [store_ids, setStore_ids] = useState([])
  const [isCompanySearching, setIsCompanySearching] = useState(false)
  const [isStoreSearching, setIsStoreSearching] = useState(false)
  const [localCompanyTemplateList, setLocalCompanyTemplateList] = useState([])
  const [localStoreTemplateList, setLocalStoreTemplateList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  let setTimeoutID

  const fetchCompanyTemplates = () => {
    setIsLoading(true)
    getCompanyTemplates(token, companyId)
      .then(res => {
        setIsLoading(false)
        if (res) {
          updateCompanyTemplateListConnect(res.results, companyId)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const fetchStoreTemplates = store_id => {
    setIsLoading(true)
    getStoreTemplates(token, store_id)
      .then(res => {
        setIsLoading(false)
        if (res) {
          updateStoreTemplateListConnect(res.results, store_id)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const handleCompanyInputChange = value => {
    clearTimeout(setTimeoutID)
    setCompanySearchValue(value)
    if (value) {
      setIsCompanySearching(true)
      setTimeoutID = setTimeout(() => {
        const filteredTemplateList = companyTemplates.filter(
          template =>
            template.name.toLowerCase().includes(value.toLowerCase()) ||
            template.message.toLowerCase().includes(value.toLowerCase()),
        )
        setLocalCompanyTemplateList(filteredTemplateList)
      }, 250)
    } else {
      setIsCompanySearching(false)
    }
  }

  const handleStoreInputChange = value => {
    clearTimeout(setTimeoutID)
    setStoreSearchValue(value)
    if (value) {
      setIsStoreSearching(true)
      setTimeoutID = setTimeout(() => {
        const filteredTemplateList = storeTemplates.filter(
          template =>
            template.name.toLowerCase().includes(value.toLowerCase()) ||
            template.message.toLowerCase().includes(value.toLowerCase()),
        )
        setLocalStoreTemplateList(filteredTemplateList)
      }, 250)
    } else {
      setIsStoreSearching(false)
    }
  }

  const handleCompanyInputBlur = () => {
    if (companySearchValue.length > 0) {
    } else {
      setIsCompanySearching(false)
    }
  }

  const handleStoreInputBlur = () => {
    if (storeSearchValue.length > 0) {
    } else {
      setIsStoreSearching(false)
    }
  }

  const handleClearCompanySearchField = () => {
    setCompanySearchValue('')
    setIsCompanySearching(false)
  }

  const handleClearStoreSearchField = () => {
    setStoreSearchValue('')
    setIsStoreSearching(false)
  }

  const handleTemplateActivity = (emailTemplate, templateType) => {
    if (emailTemplate.active) {
      const editedTemplate = emailTemplate
      editedTemplate.active = false
      updateEmailTemplate(token, editedTemplate, editedTemplate.id)
        .then(res => {
          if (res) {
            editTemplateConnect(emailTemplate, editedTemplate, templateType)
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      const editedTemplate = emailTemplate
      editedTemplate.active = true
      updateEmailTemplate(token, editedTemplate, editedTemplate.id)
        .then(res => {
          if (res) {
            editTemplateConnect(emailTemplate, editedTemplate, templateType)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  useEffect(() => {
    companyTemplates.length > 0 ? null : fetchCompanyTemplates()
    storeTemplates.length > 0 ? null : fetchStoreTemplates(defaultStoreId)
  }, [])

  const renderSearchBarDisclaimer = () => {
    return (
      <div className="search-disclaimer">
        In the search bars below you can search for a <b>specific</b> template. The search looks
        through the list of templates and matches the keywords entered with the <b>name</b> as well
        as the <b>message</b>. So you can filter templates by searching through their <b>names</b>{' '}
        or the actual <b>message</b>.
      </div>
    )
  }

  const renderCompanySearchBar = () => {
    return (
      <div className="searchTemplates">
        <input
          type="text"
          name="search"
          placeholder="You can search for a specific company template here"
          className="searchTemplates"
          value={companySearchValue}
          onBlur={() => handleCompanyInputBlur()}
          onChange={e => handleCompanyInputChange(e.target.value)}
        />
        <button
          className={isCompanySearching ? 'inputClose' : 'inputClose-hidden'}
          onClick={() => handleClearCompanySearchField()}
          type="button"
        >
          <IconX />
        </button>
      </div>
    )
  }

  const renderStoreSearchBar = () => {
    return (
      <div className="searchTemplates">
        <input
          type="text"
          name="search"
          placeholder="You can search for a specific store template here"
          className="searchTemplates"
          value={storeSearchValue}
          onBlur={() => handleStoreInputBlur()}
          onChange={e => handleStoreInputChange(e.target.value)}
        />
        <button
          className={isStoreSearching ? 'inputClose' : 'inputClose-hidden'}
          onClick={() => handleClearStoreSearchField()}
          type="button"
        >
          <IconX />
        </button>
      </div>
    )
  }

  const renderNoSearchResultsMessage = templateType => {
    return (
      <div className="noSearchResults">
        <Warning />
        There are no {templateType} template names or messages that contain{' '}
        {templateType === 'Company' ? companySearchValue : storeSearchValue}.
      </div>
    )
  }

  const renderPrivacySlide = (emailTemplate, templateType) => {
    return (
      <div className="privacySlide" style={{ animation: ` 1500ms fadeIn`, alignItems: 'center' }}>
        <span
          className={`packetSendItalicStyle ${emailTemplate.active ? 'transparent' : ''}`}
          style={{ fontSize: '1.0em' }}
        ></span>
        <label className="switch">
          <input
            onChange={() => handleTemplateActivity(emailTemplate, templateType)}
            type="checkbox"
            checked={emailTemplate.active}
          />
          <span className="slider round" />
        </label>
      </div>
    )
  }

  const renderTableHeader = templateType => {
    return (
      <div className="templateHeader">
        <span className="templateName">Name</span>
        {templateType === 'Store' ? (
          <span className="templateStore">Store</span>
        ) : (
          <span className="templateStore"></span>
        )}
        <ToolTip
          onHover
          tip={
            <React.Fragment>
              This Toggle allows you to activate or deactivate this template.
              <br />
              Only active templates can be rendered in the packet send modals.
            </React.Fragment>
          }
        >
          <span className="templateActivityToggle">Enable/disable template</span>
        </ToolTip>
        <span className="editTemplate">Edit Template</span>
        <span className="viewTemplate">View Template</span>
      </div>
    )
  }

  const renderTemplateRow = (emailTemplate, templateType, index) => {
    return (
      <div className="templateRow" key={index}>
        <span className="templateName">{emailTemplate.name}</span>
        {templateType === 'Store' ? (
          <span className="templateStore">
            {emailTemplate.store ? emailTemplate.store : defaultStoreName}
          </span>
        ) : (
          <span className="templateStore"></span>
        )}
        {renderPrivacySlide(emailTemplate, templateType)}
        <span
          className="editTemplate"
          onClick={() =>
            showModalConnect(EDIT_EMAIL_TEMPLATE, {
              selectedTemplate: emailTemplate,
              storeName: emailTemplate.store ? emailTemplate.store : defaultStoreName,
              templateType: templateType,
            })
          }
        >
          <EditIcon />
        </span>
        <span
          className="viewTemplate"
          onClick={() =>
            showModalConnect(VIEW_EMAIL_TEMPLATE, {
              selectedTemplate: emailTemplate,
              storeName: emailTemplate.store ? emailTemplate.store : defaultStoreName,
              templateType: templateType,
            })
          }
        >
          <EyeOpenIcon />
        </span>
      </div>
    )
  }

  const renderNoTemplatesMessage = templateType => {
    return (
      <div className="noTemplatesError">
        <Warning />
        There are no {templateType} email templates at this time. You can use the Add Template
        button to create one.
      </div>
    )
  }

  return (
    <div align="center" className="white_box">
      <div id="basics-bloc">
        <h2>Email Templates</h2>
        <hr />

        <div className="infoBlurb">
          On this page you can create and edit the email templates used on packet shares.
        </div>

        <div className="emailTemplates-body">
          <div className="addTemplate">
            <button
              onClick={() =>
                showModalConnect(ADD_EMAIL_TEMPLATE, {
                  storeIds: store_ids,
                  companyId: companyId,
                  defaultStoreId: defaultStoreId,
                })
              }
            >
              <span>
                <IconAddItem className="addIcon" />
                Add Template
              </span>
            </button>
          </div>

          {(companyTemplates.length > 0 || storeTemplates.length > 0) &&
            renderSearchBarDisclaimer()}

          <div className="companyTemplates">
            <p>Company Templates</p>
            {isLoading && <LoadingSpinner loading wheel />}
            {companyTemplates.length === 0 && !isLoading && renderNoTemplatesMessage('company')}
            {companyTemplates.length > 0 && renderCompanySearchBar()}
            {companyTemplates.length > 0 && renderTableHeader('Company')}
            {companyTemplates.length > 0 &&
              !isCompanySearching &&
              companyTemplates.map(template =>
                renderTemplateRow(template, 'Company', companyTemplates.indexOf(template)),
              )}
            {localCompanyTemplateList.length === 0 &&
              isCompanySearching &&
              renderNoSearchResultsMessage('Company')}
            {localCompanyTemplateList.length > 0 &&
              isCompanySearching &&
              localCompanyTemplateList.map(template =>
                renderTemplateRow(template, 'Company', localCompanyTemplateList.indexOf(template)),
              )}
          </div>

          <div className="storeTemplates">
            <p>Store Templates</p>
            {isLoading && <LoadingSpinner loading wheel />}
            {storeTemplates.length === 0 && !isLoading && renderNoTemplatesMessage('store')}
            {storeTemplates.length > 0 && renderStoreSearchBar()}
            {storeTemplates.length > 0 && renderTableHeader('Store')}
            {storeTemplates.length > 0 &&
              !isStoreSearching &&
              storeTemplates.map(template =>
                renderTemplateRow(template, 'Store', storeTemplates.indexOf(template)),
              )}
            {localStoreTemplateList.length === 0 &&
              isStoreSearching &&
              renderNoSearchResultsMessage('Store')}
            {localStoreTemplateList.length > 0 &&
              isStoreSearching &&
              localStoreTemplateList.map(template =>
                renderTemplateRow(template, 'Store', localStoreTemplateList.indexOf(template)),
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  companyTemplates: state.emailTemplates.companyTemplates,
  storeTemplates: state.emailTemplates.storeTemplates,
  token: state.authentication.user.token,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  companyId:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_id,
  companyName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_name,
  defaultStoreId:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.default_store_id,
  defaultStoreName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.store_full_name,
  isStaff:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.is_staff,
})

const mapDispatchToProps = {
  showModalConnect: showModal,
  updateCompanyTemplateListConnect: updateCompanyTemplateList,
  updateStoreTemplateListConnect: updateStoreTemplateList,
  editTemplateConnect: editTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
