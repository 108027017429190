import React from 'react'

const NoContactIcon = () => {
  return (
    <div className="no-contact-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.24 218.74">
        <rect class="a" x="145.99" y="63.45" width="47.26" height="40.87" rx="13.5" />
        <rect class="a" x="145.99" y="114.1" width="47.26" height="40.87" rx="13.5" />
        <rect class="a" x="145.99" y="164.75" width="47.26" height="40.87" rx="13.5" />
        <rect class="a" x="145.99" y="12.79" width="47.26" height="40.87" rx="13.5" />
        <rect class="b" width="176.05" height="218.74" rx="13.81" />
        <path
          class="c"
          d="M119.1,95.85H101.55V80a13.52,13.52,0,1,0-27,0v15.9H57a12,12,0,0,0-12,12v3a12,12,0,0,0,12,12H74.51V138.8a13.52,13.52,0,0,0,27,0V122.89H119.1a12,12,0,0,0,12-12v-3A12,12,0,0,0,119.1,95.85Z"
        />
        <ellipse class="d" cx="87.21" cy="75.6" rx="29.97" ry="29.2" />
        <path
          class="d"
          d="M134.77,130a47.53,47.53,0,0,0-13.36-26.79,4,4,0,0,0-5.89.32c-7.1,8.6-17.16,14-28.31,14-10.78,0-20.54-5-27.6-13.1a4,4,0,0,0-6-.15,47.46,47.46,0,0,0-12.37,25.77,4,4,0,0,0,4,4.52h85.56A4,4,0,0,0,134.77,130Z"
        />
        <rect class="d" x="31.17" y="145.12" width="113.72" height="9.51" rx="4.76" />
        <rect class="d" x="45.22" y="163.71" width="85.62" height="8.64" rx="4.32" />
      </svg>
    </div>
  )
}

export default NoContactIcon
