// general: customers edit modal.

// TODO:
// 1. mobile support
// 2. cross browser check support
// 3. user simulated using test to find bugs

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// core files
import CustomerActions from '../../../core/actions/users/customeractions'
import Helper from '../../../core/helpers/platformhelper'
import USAStateSeed from '../../../core/data/USAStateSeed'
import CustomerEditInputSeed from '../../../core/data/AIPSeedsData'
import { hideModal } from '../../../core/_actions/modalActions'
// components
import Modal from '../../layouts/modal'

type Props = {
  title: string,
  customerId: number,
  token: string,
  hideModal: boolean,
}

export class CustomersEditModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '', // email default blank sting.
      firstname: '', // firstname default blank sting.
      lastname: '', // lastname default blank sting.
      phone: '', // phone default blank sting.
      mobile: '', // mobile default blank sting.

      address: '',
      city: '',
      state: '',
      zipcode: '',

      message: '',
      success: true,
    }

    this._onClose = this.onClose.bind(this)
    this._onUpdateField = this.onUpdateField.bind(this)
    this._onSubmit = this.onSubmit.bind(this)
  }

  state: {
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    mobile: string,

    address: string,
    city: string,
    state: string,
    zipcode: string,

    message: string,
    success: boolean,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetCustomerInfo()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    this.setState({
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      mobile: '',

      address: '',
      city: '',
      state: '',
      zipcode: '',

      message: '',
      success: true,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetCustomerInfo
  onMountGetCustomerInfo() {
    // props
    const { customerId, token } = this.props

    if (customerId !== null && customerId !== undefined) {
      CustomerActions.getSingleContact(customerId, token)
        .then(json => {
          if (json.customer_id !== null && json.customer_id !== undefined) {
            this.setState({
              email: json.email,
              firstname: json.first_name,
              lastname: json.last_name,
              phone: json.phone_number,
              mobile: json.mobile_number,
              address: json.address1,
              city: json.city,
              state: json.state,
              zipcode: json.postal_code,
            })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.props.hideModal()
  }

  // onUpdateField
  onUpdateField(field: string, value: string) {
    this.setState({ message: '', success: true })

    const newState = {}
    newState[field] = value.target.value
    this.setState(newState)
  }

  // onSubmit
  onSubmit() {
    // props/states
    const { customerId, token } = this.props
    const { email, firstname, lastname, phone, mobile, address, city, state, zipcode } = this.state

    // conditionals
    const fullNameIsGood = firstname !== '' && lastname !== '' ? true : false
    const emailIsValid = email !== '' && Helper.CheckEmailValidation(email) ? true : false
    const mobileIsValid = mobile !== '' && Helper.CheckMobileValidation(mobile) ? true : false
    const emailAndMobileAreGood = emailIsValid && mobileIsValid ? true : false

    if (fullNameIsGood && (emailAndMobileAreGood || emailIsValid || mobileIsValid)) {
      CustomerActions.putUpdateSingleContact(
        customerId,
        firstname,
        lastname,
        email,
        phone,
        mobile,
        address,
        city,
        state,
        zipcode,
        token,
      )
        .then(json => {
          if (json.customer_id !== null && json.customer_id !== undefined) {
            // ^if the api is successful.
            // set states to a success
            this.setState({ message: '', success: true })

            // close the modal
            this.props.hideModal()

            // reload the page.
            // eslint-disable-next-line no-undef
            location.reload() // eslint-disable-line no-restricted-globals
          } else if (json.non_field_errors[0] !== null && json.non_field_errors[0] !== undefined) {
            this.setState({
              message: `There was an error with - ${json.non_field_errors[0]}`,
              success: false,
            })
          } else {
            // ^else, the api has failed.
            this.setState({
              message: `There was an error with updating ${firstname} ${lastname}.`,
              success: false,
            })
          }
        })
        .catch(error => {
          this.setState({
            message: `There was an error with updating ${firstname} ${lastname}.`,
            success: false,
          })
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // CustomerEditInputContainers
  CustomerEditInputContainers() {
    // states
    const { state } = this.state

    // arrays
    const customerInputsArray = []
    const USAStateSeedArray = []

    // counters
    let counterCustomer = 0
    let counterStates = 0

    if (CustomerEditInputSeed.customers_edit && CustomerEditInputSeed.customers_edit.length > 0) {
      for (const entry of CustomerEditInputSeed.customers_edit) {
        customerInputsArray.push(
          <div className="customer-form-row" key={counterCustomer++}>
            <label className="customer-form-label" htmlFor={`form-${entry.name}`}>
              {entry.placeholder}
              <input
                key={counterCustomer++}
                ref={text => {
                  this._inputEditCustomer = text
                }}
                id={`form-${entry.name}`}
                type="text"
                name={entry.name}
                placeholder={entry.placeholder}
                className="customer-form-control"
                value={this.state[entry.value]}
                onChange={text => {
                  this._onUpdateField(entry.value, text)
                }}
              />
            </label>
          </div>,
        )
      }
    }

    if (USAStateSeed.merica && USAStateSeed.merica.length > 0) {
      USAStateSeedArray.push(
        <option key={counterStates++} value="none">
          Choose state
        </option>,
      )

      for (const entry of USAStateSeed.merica) {
        USAStateSeedArray.push(
          <option key={counterStates++} value={entry.abbreviation}>
            {entry.name}
          </option>,
        )
      }
    }

    return (
      <div>
        {customerInputsArray}
        <label className="state-box">
          State
          <br />
          <select
            value={state}
            onChange={(event: Object) => {
              this.setState({ state: event.target.value })
            }}
          >
            {USAStateSeedArray}
          </select>
        </label>
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _onClose: Function // function
  _onUpdateField: Function // function
  _onSubmit: Function // function
  _inputEditCustomer: Function // ref

  render() {
    // states
    const { success, message } = this.state

    return (
      <Modal>
        <div className="customer-info-box">
          <h3>Edit Contact Information</h3>
          <hr />

          {this.CustomerEditInputContainers()}

          <button onClick={this._onSubmit}>Save</button>

          <span>{!success ? message : null}</span>
          <button onClick={this._onClose}>Cancel</button>
        </div>
      </Modal>
    )
  }
}

// ***********************LETS*EXPORT*CUSTOMER*MODAL***********************
function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    customerId: state.modal.props.customer_id,
    token: state.modal.props.token,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(CustomersEditModal)
