// general:

// @flow
import React, { Component } from 'react'
// node packages
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import moment from 'moment-timezone'

// --- redux
import { connect } from 'react-redux'
import { showModal, hideModal } from '../../../../core/_actions/modalActions'
import { PRICE_HISTORY_MODAL } from '../../../../core/_constants/modalTypes'
// images
import ArrowUpPNG from '../../../../core/assets/images/icons/black_arrow_up_icon.png'
import ArrowDownPNG from '../../../../core/assets/images/icons/black_arrow_down_icon.png'
import IconDealership from '../../../../core/assets/images/icons/icon-dealership'
import PriceHistory from '../../../../core/assets/images/icons/pricehistorybtn.js'
// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'

type Props = {
  DJtoken: string,
  showModal: Function,
  hideModal: Function,
  vehicle_info: Object,
  first_image: string,
  privacyHide: boolean,
}

class PacketSentTop extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      vehicleId: this.props.vehicle_info.vehicle_id,
      vehicle_info: this.props.vehicle_info,
      privacyHide: this.props.privacyHide,
      first_image: this.props.first_image,
      close: true,
    }
  }

  state: {
    vehicleId: number,
    vehicle_info: Object,
    privacyHide: boolean,
    first_image: string,
    close: boolean,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({
      vehicleId: newProps.vehicle_info.vehicle_id,
      vehicle_info: newProps.vehicle_info,
      privacyHide: newProps.privacyHide,
      first_image: newProps.first_image,
    })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({
      vehicleId: null,
      vehicle_info: null,
      privacyHide: false,
      first_image: '',
      close: true,
    })
  }

  // ***********************LETS*CALL*MODAL*SETUP***********************
  // PriceHistoryModal
  PriceHistoryModal() {
    this.props.showModal(PRICE_HISTORY_MODAL, {
      title: 'Price History',
      vehicle_id: this.state.vehicleId,
      token: this.props.DJtoken,
      currency_unit: this.state.vehicle_info.currency_unit,
    })
  }
  // ***********************LETS*CALL*MODAL*SETUP***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // PacketsTopContainer
  PacketsTopContainer() {
    // styles
    const bottomStyle = {
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '1%',
      fontSize: '0.85em',
    }

    // props/states
    const { first_image, vehicle_info, privacyHide } = this.state
    const { color, drive_train } = vehicle_info
    const engineDescription = vehicle_info.engine_description
    const vehicleBody = vehicle_info.vehicle_body
    const interiorColor = vehicle_info.interior_color
    const transmissionDescription = vehicle_info.transmission_description
    // TODO - one is subtracted from price_history_count beacuse the endpoint returns at least one inital price
    const priceHistoryCount = vehicle_info.price_history_count - 1

    // booleans
    const engineBoolean =
      engineDescription !== null && engineDescription !== undefined && engineDescription !== ''
    const bodyBoolean = vehicleBody !== null && vehicleBody !== undefined && vehicleBody !== ''
    const colorBoolean = color !== null && color !== undefined && color !== ''
    const interiorColorBoolean =
      interiorColor !== null && interiorColor !== undefined && interiorColor !== ''
    const transDescrBoolean =
      transmissionDescription !== null &&
      transmissionDescription !== undefined &&
      transmissionDescription !== ''

    const inDate = new Date(vehicle_info.veh_in_date)
    const currentDate = moment()
    let daysSinceLastPriceChange = null
    if (vehicle_info.last_price_change) {
      const lastPriceChangeDate = moment(vehicle_info.last_price_change)
      daysSinceLastPriceChange = currentDate.diff(lastPriceChangeDate, 'days')
    }
    return (
      <div className="packetSendInfoContainerStyle">
        <div className="packetSendContentContainerStyle">
          <div className="packetSendModuleImgContainerStyle">
            <img
              className="packetSendModuleImgStyle"
              src={first_image}
              alt="packet-thumbnail-img"
            />
          </div>

          <div className="packetSendModuleSideContainerStyle">
            <div id="what-am-i">
              {parseInt(vehicle_info.price) > 0 ? (
                <React.Fragment>
                  <span className="packetSendModuleMoneyStyle">
                    <sup>$</sup>
                  </span>
                  {vehicle_info.price && (
                    <span className="packetSendModulePriceStyle">
                      {parseInt(vehicle_info.price).toLocaleString()}
                    </span>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="packetSendModulePriceStyle">Please Call</span>
                </React.Fragment>
              )}
              {!privacyHide && priceHistoryCount > 0 ? (
                <button
                  className="vdp-price-history"
                  onClick={() => {
                    this.PriceHistoryModal()
                  }}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <PriceHistory />
                  <div className="vehicle-history-count">{priceHistoryCount}</div>
                </button>
              ) : null}
              {!privacyHide && daysSinceLastPriceChange ? (
                <React.Fragment>
                  <span className="car-last-price-change">
                    <i>{`Days Since Last Price Change - `}</i>
                    <b>
                      <span>{`${daysSinceLastPriceChange}`}</span>
                    </b>
                  </span>
                  <br />
                </React.Fragment>
              ) : null}
            </div>
            <div className="vehicle_stuff">
              {vehicle_info.miles && (
                <span className="miles-row">
                  <b>Miles: {vehicle_info.miles.toLocaleString()}</b>
                </span>
              )}
              {engineBoolean ? (
                <span>
                  <b>Engine:</b> {engineDescription}
                </span>
              ) : (
                <div />
              )}
            </div>

            <div className="vehicle_stuff">
              {bodyBoolean ? (
                <span>
                  <b>Body Type:</b> {vehicleBody}
                </span>
              ) : null}
              {colorBoolean ? (
                <span>
                  <b>Exterior Color:</b> {color}
                </span>
              ) : (
                <div />
              )}
            </div>

            <div className="vehicle_stuff">
              {interiorColorBoolean ? (
                <span>
                  <b>Interior Color:</b> {interiorColor}
                </span>
              ) : null}
              {transDescrBoolean ? (
                <span>
                  <b>Drivetrain:</b> {drive_train}
                </span>
              ) : (
                <div />
              )}
              {vehicle_info.inventory_type ? (
                <span>
                  <b>Inventory Type:</b> {vehicle_info.inventory_type}
                </span>
              ) : (
                <div />
              )}
            </div>
            <div id="located-box">
              <span>
                <div className="AIPIconDealershipStyle">
                  <IconDealership withBuilding />
                </div>
                <b>Located at</b>
              </span>
              <span className="vlp-location-value">
                <b>{vehicle_info.store_full_name}</b>
              </span>
              <span className="packetSendItalicStyle" style={{ opacity: !privacyHide ? '1' : '0' }}>
                <b>
                  {vehicle_info.veh_in_date
                    ? differenceInDays(new Date(), parseISO(vehicle_info.veh_in_date))
                    : 0}{' '}
                  days in inventory
                </b>
                &nbsp;(Not visible when sharing)
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    // states
    const { vehicle_info } = this.props

    if (vehicle_info) {
      // styles
      let color = '#4d4d4d'

      if (this.props.darkModeState == true) {
        color = 'white'
      }

      // states
      const { close } = this.state

      // variables
      const icon = !close ? ArrowUpPNG : ArrowDownPNG

      // conditional trim string to be added to end of vehicle name
      const trimSuffix =
        vehicle_info.trim != undefined && vehicle_info.trim.length > 0
          ? ' ' + vehicle_info.trim
          : ''

      return (
        <div style={{ width: '100%', marginBottom: '15px' }}>
          <div className="flexRow packetSendTitleStyle" style={{ paddingLeft: '10px' }}>
            {vehicle_info.year} {vehicle_info.make} {vehicle_info.model} {trimSuffix}
          </div>
          <h2>
            {vehicle_info.vehicle_condition_label ? vehicle_info.vehicle_condition_label : null}
          </h2>
          <div className="flexRow packetSendSubTitleStyle" style={{ flexWrap: 'wrap' }}>
            <span style={{ marginRight: '10px' }}>
              <b>VIN:</b> {vehicle_info.vin}
            </span>
            <span>
              <b>STOCK #:</b> {vehicle_info.stock}
            </span>
          </div>

          {!close ? this.PacketsTopContainer() : null}

          <button
            className="flexColumn CollapsableButtonStyle"
            onClick={() => {
              this.setState({ close: !close })
            }}
            color="#f2f2f2"
            style={{ backgroundColor: color, alignItems: 'center' }}
          >
            <img
              src={icon}
              alt={`${icon}-img`}
              style={{
                overflow: 'hidden',
                height: '15px',
                width: '15px',
                opacity: '0.5',
              }}
            />
          </button>
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  vehicle_info: state.packetstash.vehicle_info,
  privacyHide: state.helpers.isPrivate,
  first_image: state.packetstash.vehicle_image_thumb,
  darkModeState: state.userSettings.dark_mode.enabled,
})
export default connect(mapStateToProps, {
  hideModal,
  showModal,
})(PacketSentTop)
