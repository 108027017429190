// general: customers add modal.

// TODO:
// 1. mobile support
// 2. cross browser check support
// 3. user simulated using test to find bugs

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// core files
import CustomerActions from '../../../core/actions/users/customeractions'
import Helper from '../../../core/helpers/platformhelper'
import USAStateSeed from '../../../core/data/USAStateSeed'
import CustomerAddInputSeed from '../../../core/data/AIPSeedsData'
import { hideModal } from '../../../core/_actions/modalActions'
// wrappers
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'

// components
import Modal from '../../layouts/modal'
// styles
import '../styles/customerModal.scss'

type Props = {
  title: string,
  companyId: number,
  userId: number,
  storeId: number,
  token: string,
  hideModal: boolean,
}

export class CustomersAddModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '', // email default blank sting.
      firstname: '', // firstname default blank sting.
      lastname: '', // lastname default blank sting.
      phone: '', // phone default blank sting.
      mobile: '', // mobile default blank sting.

      address: '',
      city: '',
      state: '',
      zipcode: '',

      message: '',
      success: true,
      requiredInputs: {
        firstname: true,
        lastname: true,
        email: true,
        phone: false,
        mobile: false,
      },
      formFailures: {
        user: false,
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        mobile: false,
      },
    }

    this._onClose = this.onClose.bind(this)
    this._onUpdateField = this.onUpdateField.bind(this)
    this._onSubmit = this.onSubmit.bind(this)
  }

  state: {
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    mobile: string,

    address: string,
    city: string,
    state: string,
    zipcode: string,

    message: string,
    success: boolean,
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    this.setState({
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      mobile: '',

      address: '',
      city: '',
      state: '',
      zipcode: '',

      message: '',
      success: true,
      formFailures: {
        user: false,
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        mobile: false,
      },
      requiredInputs: {
        firstname: true,
        lastname: true,
        email: true,
        phone: false,
        mobile: false,
      },
    })
  }

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.props.hideModal()
  }

  // onUpdateField
  onUpdateField(field: string, value: string) {
    this.setState({ message: '', success: true })

    const newState = {}
    newState[field] = value.target.value
    this.setState(newState)
  }

  // onSubmit
  onSubmit() {
    // props/states
    const { userId, storeId, token } = this.props
    const { company_id } = this.props.userInfo
    const { email, firstname, lastname, phone, mobile, address, city, state, zipcode } = this.state

    // conditionals
    const userBoolean =
      userId !== null &&
      userId !== undefined &&
      storeId !== null &&
      storeId !== undefined &&
      company_id !== null &&
      company_id !== undefined
    const fullNameIsGood = firstname !== '' && lastname !== '' ? true : false
    const emailIsValid = email !== '' && Helper.CheckEmailValidation(email) ? true : false
    //const phoneIsValid = phone !== '' && Helper.CheckMobileValidation(phone) ? true : false
    //const mobileIsValid = mobile !== '' && Helper.CheckMobileValidation(mobile) ? true : false
    //const emailAndMobileAreGood = emailIsValid && mobileIsValid ? true : false

    // formFailures
    let formFailures = {
      user: !userBoolean,
      firstname: !fullNameIsGood,
      lastname: !fullNameIsGood,
      email: !emailIsValid,
      //phone: !phoneIsValid,
      //mobile: !mobileIsValid,
    }

    console.log('userId = ' + userId)
    console.log('storeId = ' + storeId)
    console.log('companyId = ' + company_id)

    if (userBoolean && fullNameIsGood && emailIsValid) {
      console.log('submitting!')
      CustomerActions.putCreateSingleContact(
        company_id,
        firstname,
        lastname,
        email,
        phone,
        mobile,
        address,
        city,
        state,
        zipcode,
        token,
      )
        .then(json => {
          if (json.customer_id !== null && json.customer_id !== undefined) {
            // ^if the api is successful.
            // set states to a success
            console.log('we did it!')
            this.setState({ message: '', success: true })

            // close the modal
            this.props.hideModal()

            // reload the page.
            // eslint-disable-next-line no-undef
            //location.reload() // eslint-disable-line no-restricted-globals
          } else if (json.non_field_errors[0] !== null && json.non_field_errors[0] !== undefined) {
            this.setState({
              message: `There was an error with - ${json.non_field_errors[0]}`,
              success: false,
            })
          } else {
            // ^else, the api has failed.
            this.setState({
              message: `There was an error with updating ${firstname} ${lastname}.`,
              success: false,
            })
          }
        })
        .catch(error => {
          this.setState({
            message: `There was an error with updating ${firstname} ${lastname}.`,
            success: false,
          })
          console.log(error.toString())
        })
    } else {
      console.log('somethings wrong!')
      this.setState({
        success: false,
        formFailures: formFailures,
        message: 'Please fill out all required fields',
      })
    }
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // CustomerAddInputContainers
  CustomerAddInputContainers() {
    // states
    const { state, requiredInputs, formFailures } = this.state

    // arrays
    const customerInputsArray = []
    const USAStateSeedArray = []

    // counters
    let counterCustomer = 0
    let counterStates = 0

    if (CustomerAddInputSeed.customers_edit && CustomerAddInputSeed.customers_edit.length > 0) {
      for (const entry of CustomerAddInputSeed.customers_edit) {
        customerInputsArray.push(
          <div className="customer-form-row" key={counterCustomer++}>
            <label className="customer-form-label" htmlFor={`form-${entry.name}`}>
              {entry.placeholder}
              {requiredInputs[entry.name] ? <span className="required">*</span> : null}
              <input
                key={counterCustomer++}
                ref={text => {
                  this._inputAddCustomer = text
                }}
                id={`form-${entry.name}`}
                type="text"
                name={entry.name}
                placeholder={entry.placeholder}
                className={formFailures[entry.name] ? `form-control error` : `form-control`}
                value={this.state[entry.value]}
                onChange={text => {
                  this._onUpdateField(entry.value, text)
                }}
              />
            </label>
          </div>,
        )
      }
    }

    if (USAStateSeed.merica && USAStateSeed.merica.length > 0) {
      USAStateSeedArray.push(
        <option key={counterStates++} value="none">
          Choose state
        </option>,
      )

      for (const entry of USAStateSeed.merica) {
        USAStateSeedArray.push(
          <option key={counterStates++} value={entry.abbreviation}>
            {entry.name}
          </option>,
        )
      }
    }

    return (
      <div>
        {customerInputsArray}
        <label className="state-box">
          State
          <select
            value={state}
            onChange={(event: Object) => {
              this.setState({ state: event.target.value })
            }}
          >
            {USAStateSeedArray}
          </select>
        </label>
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _onClose: Function // function
  _onUpdateField: Function // function
  _onSubmit: Function // function
  _inputAddCustomer: Function // ref

  render() {
    // states
    const { success, message } = this.state

    return (
      <Modal>
        <div className="customer-info-box">
          <h3>Add New Contact</h3>
          <hr />

          {this.CustomerAddInputContainers()}
          <div className="text-failure-tip">{!success ? message : null}</div>

          <button onClick={this._onSubmit}>Submit</button>
          <button onClick={this._onClose}>Cancel</button>
        </div>
      </Modal>
    )
  }
}

// ***********************LETS*EXPORT*CUSTOMER*MODAL***********************
function mapStateToProps(state) {
  console.log(state)

  return {
    title: state.modal.props.title,
    userId: state.authentication.user.user.profile.legacy_user.legacy_user_id,
    storeId: state.authentication.user.user.profile.legacy_user.default_store_id,
    token: state.modal.props.token,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(UserInfoWrapper(CustomersAddModal))
