// general:

// @flow
import React, { Component } from 'react'
// node packages
import LazyLoad from 'react-lazy-load'
// --- redux
import { connect } from 'react-redux'
// core files
// --- other
import { showModal } from '../../../../../core/_actions/modalActions'
import { LIGHTBOX_MODAL } from '../../../../../core/_constants/modalTypes'
import ZoomIcon from '../../../assets/zoomicon'
import IPacketRingLogo from '../../../../../core/assets/images/icons/ipacketringlogo'
import AIPSeedsData from '../../../../../core/data/AIPSeedsData'
// css styles
import '../../../../styles/universalstyles.scss'
import '../../../styles/packetsend.scss'

type Props = {
  DJtoken: string, // token to send to get the data.
  vehicleId: number,
  stashed_content: Object,
  module_videos: Object,
}

class ImagesChild extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      packet_images: AIPSeedsData.packet_mock_images.results,
      module_videos: this.props.module_videos,
      index: 1,
      loading: true,
      currentTouchPosition: 0,
      touchStartPosition: 0,
      displayFallback: false,
    }
  }

  state: {
    packet_images: Object,
    module_videos: Object,
    index: number,
    loading: boolean,
    currentTouchPosition: number,
    touchStartPosition: number,
    displayFallback: boolean,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountSetDataSourceToLoading()
      this.onMountCheckStash()
    }
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({ module_videos: newProps.module_videos })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({
      packet_images: [],
      module_videos: [],
      index: 0,
      loading: true,
      currentTouchPosition: 0,
      touchStartPosition: 0,
      displayFallback: false,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountSetDataSourceToLoading
  // sets dummy data onmount into the table for row init;
  // called once onmount
  onMountSetDataSourceToLoading() {
    const packet_images = AIPSeedsData.packet_mock_images.results
    this.setState({ packet_images, index: 0 })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  props: Props

  onMountCheckStash() {
    const stash = this.props.stashed_content
    this.setState({
      packet_images: stash.vehicle_images,
      first_image: stash.vehicle_image_thumb,
      loading: false,
    })
  }
  updateActiveImage = newIndex => {
    const { packet_images } = this.state
    var tinyImg = document.getElementsByClassName('packetSendMiddleImageMiniStyle')[newIndex]

    if (newIndex >= 0 && newIndex <= packet_images.length - 1) {
      this.setState({ index: newIndex })
      if (tinyImg && tinyImg.scrollIntoView) {
        tinyImg.scrollIntoView(false)
      }
    }

    // circle back to beginning
    if (newIndex === packet_images.length) {
      this.setState({ index: 0 })
      if (tinyImg && tinyImg.scrollIntoView) {
        tinyImg.scrollIntoView(false)
      }
    }
    // go to the end
    if (newIndex === -1) {
      this.setState({ index: packet_images.length - 1 })
      if (tinyImg && tinyImg.scrollIntoView) {
        tinyImg.scrollIntoView(false)
      }
    }
  }
  touchMove = e => {
    this.setState({ currentTouchPosition: e.touches[0].pageX })
  }
  touchStart = e => {
    this.setState({ touchStartPosition: e.touches[0].pageX })
  }
  touchEnd = () => {
    const { currentTouchPosition, touchStartPosition, index } = this.state
    const touchMoveDistance = touchStartPosition - currentTouchPosition
    if (touchMoveDistance > 60) {
      this.updateActiveImage(index + 1)
    } else if (touchMoveDistance < -60) {
      this.updateActiveImage(index - 1)
    }
  }

  renderSlideShowFallback() {
    const { packet_images } = this.state
    const packet_images_slice = packet_images.slice(0, 1)
    this.setState({
      displayFallback: true,
      packet_images: packet_images_slice,
    })
  }

  render() {
    // styles
    let activeImageStyle = { border: '2px solid #EEB21E' }

    // states
    const { packet_images, index, loading, module_videos, displayFallback } = this.state

    // arrays
    const tinyImgArray = []

    if (packet_images && packet_images.length > 0) {
      packet_images.map((image, i) => {
        tinyImgArray.push(
          <button
            key={i}
            onClick={() => {
              this.updateActiveImage(i)
            }}
            className="AIPremoveButtonStyle packetSendMiddleImageMiniContainerStyle"
            style={i === index ? activeImageStyle : null}
            id={i}
            alt="imagePreview"
          >
            {displayFallback ? (
              <div className="car-slideshow-fallback">
                <IPacketRingLogo />
              </div>
            ) : (
              <React.Fragment>
                <div style={{ display: 'none' }}>Car Tile for Slideshow</div>
                <LazyLoad debounce={false} throttle={125} threshold={200}>
                  <img
                    className="packetSendMiddleImageMiniStyle"
                    src={image}
                    alt="packet-mini-img"
                    id={i}
                    onError={() => this.renderSlideShowFallback()}
                  />
                </LazyLoad>
              </React.Fragment>
            )}
          </button>,
        )
      })
    }

    return (
      <span>
        <div className="packetSendMiddleImageBigContainerStyle">
          {displayFallback ? (
            <div className="car-img-fallback">
              <IPacketRingLogo />
            </div>
          ) : (
            <LazyLoad debounce={false} throttle={125} threshold={200}>
              <img
                aria-hidden="true"
                className="packetSendMiddleImageBigStyle"
                src={packet_images[index]}
                alt="packet-big-img"
                onClick={() => {
                  this.props.showModal(LIGHTBOX_MODAL, { images: packet_images, index: index })
                }}
                onTouchMove={e => this.touchMove(e)}
                onTouchStart={e => this.touchStart(e)}
                onTouchEnd={() => this.touchEnd()}
                onError={() => {
                  this.setState({ displayFallback: true })
                }}
              />
            </LazyLoad>
          )}
          <div
            aria-hidden="true"
            className="packetSendMiddleImageBigOverlay"
            onClick={() => {
              this.props.showModal(LIGHTBOX_MODAL, { images: packet_images, index: index })
            }}
            onError={() => {
              this.setState({ displayFallback: true })
            }}
          >
            <ZoomIcon color="#fff" />
          </div>
          <button
            disabled={loading}
            style={{
              outline: 0,
              zIndex: 9999,
              position: 'absolute',
              bottom: '50%',
              left: '5px',
            }}
            onClick={() => {
              this.updateActiveImage(index - 1)
            }}
          >
            {'<'}
          </button>
          <button
            disabled={loading}
            style={{ outline: 0 }}
            onClick={() => {
              this.updateActiveImage(index + 1)
            }}
            style={{
              outline: 0,
              zIndex: 9999,
              position: 'absolute',
              bottom: '50%',
              right: '5px',
            }}
          >
            {'>'}
          </button>
        </div>

        <div
          className="flexRow packetSendMiddleImageSmallInnerStyle"
          style={{
            justifyContent: 'flex-start',
          }}
        >
          {tinyImgArray}
        </div>

        {module_videos && module_videos.length > 0 ? (
          <div className="flexColumn seller_videos" align="left">
            <b>Seller Videos</b>
            <div
              className="flexRow seller_videos_inset"
              style={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}
            >
              {module_videos.map((d, index, array) => {
                if (d !== null && d !== undefined) {
                  if (d.url.includes('s3.amazonaws.com') || d.url.includes('.mp4')) {
                    return (
                      <video
                        key={d.module_id}
                        className={'video_box ' + (array.length > 1 ? 'half' : 'full')}
                        style={{
                          marginBottom: '5px',
                          border: '1px solid',
                          outline: 0,
                          minHeight: '175px',
                        }}
                        controls
                      >
                        <source src={d.url} type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    )
                  } else if (d.url.includes('youtube.com')) {
                    return (
                      <iframe
                        src={`${d.url}?rel=0`}
                        key={d.module_id}
                        controls
                        className={'video_box ' + (array.length > 1 ? 'half' : 'full')}
                        allowFullScreen
                        style={{
                          marginBottom: '5px',
                          border: '1px solid',
                          outline: 0,
                          minHeight: '175px',
                        }}
                      />
                    )
                  } else {
                    return (
                      <iframe
                        src={`${d.url}`}
                        key={d.module_id}
                        controls
                        className={'video_box ' + (array.length > 1 ? 'half' : 'full')}
                        allowFullScreen
                        style={{
                          marginBottom: '5px',
                          border: '1px solid',
                          outline: 0,
                          minHeight: '175px',
                        }}
                      />
                    )
                  }
                }
              })}
            </div>
          </div>
        ) : null}
      </span>
    )
  }
}

const mapStateToProps = state => ({
  stashed_content: state.packetstash,
  module_videos: state.packetstash.module_videos,
})
export default connect(mapStateToProps, {
  showModal,
})(ImagesChild)
