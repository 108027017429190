import { combineReducers } from 'redux'
import modal from './modalReducer'
import { authentication } from './authenticationReducer'
import { inventory } from './inventoryReducer'
import { tools } from './toolsReducer'
import { customerstash } from './customersReducer'
import { packetstash } from './packetstashReducer'
import { packetview } from './packetviewReducer'
import { helpers } from './helpersReducer'
import { userSettings } from './settingsReducer'
import { reactAdmin } from './reactadminappReducer'
import { unsubscribe } from './unsubscribeReducer'
import { emailTemplates } from './emailTemplateReducer'
import { stashUrlHistory } from './url_infoReducer'

export default combineReducers({
  modal,
  authentication,
  inventory,
  tools,
  customerstash,
  packetstash,
  helpers,
  packetview,
  userSettings,
  reactAdmin,
  unsubscribe,
  emailTemplates,
  stashUrlHistory,
})
