import React, { useEffect } from 'react'
import sha256 from 'crypto-js/sha256'
import cryptoRandomString from 'crypto-random-string'

export default function LoginPage(props) {
  const { require_login } = props
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    let pkce_unhashed = cryptoRandomString({ length: 128, type: 'alphanumeric' })
    let pkce_hashed = sha256(pkce_unhashed)
    localStorage.setItem('pendingLogin', JSON.stringify({ pkce_hashed, pkce_unhashed }))
    if (window.location.hostname === 'dpapp.autoipacket.com') {
      window.location.href = `https://auth.autoipacket.com/?redirect=https://${
        window.location.host
      }/auth&pkce=${pkce_hashed}&client_id=5100206${require_login ? '&require_login=true' : ''}`
    } else if (window.location.hostname === 'localhost') {
      window.location.href = `https://auth.autoipacket-stg.com/?redirect=http://${
        window.location.host
      }/auth&pkce=${pkce_hashed}&client_id=5100206${require_login ? '&require_login=true' : ''}`
    } else {
      window.location.href = `https://auth.autoipacket-stg.com/?redirect=https://${
        window.location.host
      }/auth&pkce=${pkce_hashed}&client_id=5100206${require_login ? '&require_login=true' : ''}`
    }
  }, [])
  return <React.Fragment />
}
