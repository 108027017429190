// price change alert settings page linked from email

// core components
import React, { Component } from 'react'
import { browserHistory, NavLink } from 'react-router'
import _ from 'lodash'

// node packages
import ls from 'local-storage'
import { connect } from 'react-redux'
// core files
import ExternalActions from '../../core/actions/externalactions'
import Helper from '../../core/helpers/platformhelper'
// redux

import runtimeEnv from '../../../web/core/config/envConstants'

// images
import Logo from '../layouts/logo'

// css styles
import '../styles/universalstyles.scss'
import './styles/pricechange.scss'

// global variable
const AIPLogoAWS = 'https://s3.amazonaws.com/cdn.autoipacket.com/images/logo/documentation-800.png'

type Props = {
  tempStash: Function,
}

export class priceChangeConfig extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loadingText: 'Loading...',
      packetsSentArr: [],
      hasError: false,
      errorMessage: '',
      succeeded: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    console.log(this.props)
    this._isMounted = true
    if (this._isMounted) {
      const jwtString = window.location.href.split('pricechange/')[1]
      this.setState({ jwt_string: jwtString })
      if (jwtString != undefined) {
        this.onMountLoadPCAlerts()
      }
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this._isMounted = false

    this.setState({
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      mobile: '',
      loading: true,
      loadingText: 'Loading...',
      packetsSentArr: [],
      hasError: false,
      errorMessage: '',
      succeeded: false,
    })
  }

  handleChange(event) {
    const { packetsSentArr } = this.state
    console.log('HANDLE CHANGE')
    const sentPacketIndex = _.findIndex(packetsSentArr, { vehicle_id: parseInt(event.target.name) })
    const arrayToCopy = this.state.packetsSentArr
    arrayToCopy[sentPacketIndex].isSelected = event.target.checked
    this.setState({
      packetsSentArr: arrayToCopy,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountLoadPCAlerts
  onMountLoadPCAlerts() {
    // props
    const { jwt_string } = this.props.match.params
    if (jwt_string !== null && jwt_string !== undefined) {
      console.log('JWT IS NOT NULL')
      // ***********************LETS*CALL*API***********************
      // getPacketsSentToCustomer
      // sends manipulated states to the API as the correct request params to get the
      // returned customer data
      this.getPacketsSentToCustomer()
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  getPacketsSentToCustomer() {
    // on entry set the loading states
    this.setState({ loading: true, loadingText: 'Loading...' })

    // props
    const { jwt_string } = this.props.match.params

    ExternalActions.getPriceChangeSettings(jwt_string)
      .then(json => {
        console.log(json)

        if (json !== undefined && json && json.length > 0) {
          console.log('got data back!')
          this.CustomerPrepAlertDisable(json)
        } else {
          console.log('got nothing back!')
          this.setState({ count: 0, loading: false, loadingText: 'No results found.' })
        }
      })
      .catch(error => {
        this.CustomerPrepAlertDisableFailed()
        console.log(error.toString())
      })
    /*
    PacketActions.getCustomerHistoryPackets(customerId, pageDirection, DJtoken)
      .then(json => {
        if (json.results !== undefined && json.results && json.results.length > 0) {
          this.CustomerPrepAlertDisable(json, pageDirection)
        } else {
          this.setState({ count: 0, loading: false, loadingText: 'No results found.' })
        }
      })
      .catch(error => {
        this.CustomerPrepAlertDisableFailed()
        console.log(error.toString())
      })
      */
  }

  CustomerPrepAlertDisable(json: Object) {
    // add selected = false to each received entry
    json.forEach(function(item) {
      item.isSelected = false
      item.isLoading = false
      item.hasErrored = false
    })
    this.setState({
      packetsSentArr: json,
      count: json.length,
      loading: false,
    })
  }

  // CustomerPrepAlertDisableFailed
  CustomerPrepAlertDisableFailed() {
    this.setState({
      count: 0,
      loading: true,
      loadingText: 'There was an error loading.',
    })
  }

  // Used to more cleanly change the state of nested state objects
  // can accept single value or paired arrays [attr1, attr2] -> [val1, val2]
  modifyRowState(vehicle_id, attrs, values) {
    const { packetsSentArr } = this.state
    const sentPacketIndex = _.findIndex(packetsSentArr, { vehicle_id: parseInt(vehicle_id) })
    const arrayToCopy = packetsSentArr
    if (Array.isArray(attrs) && attrs.length > 1) {
      attrs.forEach(function(item, index) {
        arrayToCopy[sentPacketIndex][attrs[index]] = values[index]
      })
    } else {
      arrayToCopy[sentPacketIndex][attrs] = values
    }
    this.setState({
      packetsSentArr: arrayToCopy,
    })
  }

  disableSelectedAlerts() {
    const { packetsSentArr } = this.state
    const { jwt_string } = this.props.match.params
    packetsSentArr.forEach(item => {
      if (item.isSelected) {
        // if the item is selected, set state to
        // isLoading while the API is contacted!
        this.modifyRowState(item.vehicle_id, 'isLoading', true)

        ExternalActions.updatePriceChangeSettings(jwt_string, item.vehicle_id, false)
          .then(json => {
            console.log('Success for a packet disable!')
            if (json.price_change_alerts != undefined && json.price_change_alerts != null) {
              this.modifyRowState(
                item.vehicle_id,
                ['isSelected', 'isLoading', 'price_change_alerts'],
                [false, false, json.price_change_alerts],
              )
              this.setState({ succeeded: true })
            } else {
              this.modifyRowState(
                item.vehicle_id,
                ['isSelected', 'isLoading', 'hasErrored'],
                [false, false, true],
              )
              this.setState({ hasError: true, errorMessage: json.detail })
            }
          })
          .catch(error => {
            console.error(error.toString())
            this.modifyRowState(
              item.vehicle_id,
              ['isSelected', 'isLoading', 'price_change_alerts'],
              [false, false, !item.price_change_alerts],
            )
          })
      }
    })
  }

  selectAllInputs() {
    const { packetsSentArr } = this.state
    const arrayToCopy = packetsSentArr
    arrayToCopy.forEach(item => {
      item.isSelected = true
    })
    console.log(packetsSentArr)
    console.log(arrayToCopy)
    this.setState({
      packetsSentArr: arrayToCopy,
    })
  }

  // BEN: feel free to change how these display as much as you like
  renderPacketsSent() {
    const { loading, loadingText, packetsSentArr, hasError, errorMessage } = this.state
    if (loading) {
      return <h2>Loading...</h2>
    } else {
      if (packetsSentArr.length == 0) {
        return <div>No packets have been sent to this user.</div>
      } else {
        return (
          <div id="customer-scroll-box">
            {hasError ? <h3 className="customer-alerts-message">{errorMessage}</h3> : null}
            <button className="customer-selectall-button" onClick={() => this.selectAllInputs()}>
              Select All
            </button>
            <button className="customer-edit-button" onClick={() => this.disableSelectedAlerts()}>
              Disable
            </button>
            <form onSubmit={this.handleSubmit}>
              {packetsSentArr.map((packetSent, i) => (
                <div key={i} className="customer-disable-alert-entry">
                  {packetSent.hasErrored ? (
                    <div className="error">
                      <i>Unable to disable alert.</i>
                    </div>
                  ) : null}
                  <label>
                    {packetSent.isLoading ? (
                      <div className="alertLoader" />
                    ) : (
                      <div className="customer-checkbox">
                        <input
                          type="checkbox"
                          align="center"
                          key={i}
                          listid={i}
                          name={packetSent.vehicle_id}
                          checked={packetSent.isSelected}
                          onChange={this.handleChange}
                        />
                        <span className="customer-checkmark" />
                      </div>
                    )}
                    <div className="customer-carinfo">
                      <br />
                      <b>
                        {`${packetSent.year ? packetSent.year : ''}${
                          packetSent.make ? ' ' + packetSent.make : ''
                        }${packetSent.model ? ' ' + packetSent.model : ''}${
                          packetSent.trim ? ' ' + packetSent.trim : ''
                        }`}
                      </b>
                      <br />
                      Alerts:&nbsp;
                      <span style={{ fontSize: '15px' }}>
                        {packetSent.price_change_alerts ? (
                          <span style={{ color: '#3CB54A' }}>ON</span>
                        ) : (
                          <span style={{ color: '#F83030' }}>OFF</span>
                        )}
                      </span>
                    </div>
                  </label>
                  <br />
                </div>
              ))}
            </form>
          </div>
        )
      }
    }
  }

  props: Props

  render() {
    // states
    const { hasError, errorMessage, succeeded } = this.state

    return (
      <div>
        <div className="headerMainStyle width_prop">
          <div
            style={{
              display: 'inline-block',
              width: '33%',
              verticalAlign: 'middle',
              minWidth: '200px',
              marginTop: '14px',
            }}
          >
            <Logo styleClassName="logoMainStyle" />
          </div>
        </div>

        <div align="center" className="customersViewBox">
          <h2>Disable Your Price Change Alerts</h2>
          <hr style={{ width: '80%' }} />
          {succeeded ? <h2>Alert(s) successfully disabled!</h2> : null}
          {this.renderPacketsSent()}
        </div>
      </div>
    )
  }
}

export default priceChangeConfig
