import React from 'react'
import './styles/reportissueanimation.scss'

const ReportIssueAnimation = props => (
  <svg
    className="report-issue-animation"
    width="100%"
    height="100%"
    viewBox="0 0 110 110"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeMiterlimit: 10 }}
  >
    <g id="AutoiPacket.com">
      <circle cx="55" cy="55" r="52.24" style={{ fill: '#424347' }} />
      <g transform="matrix(1.18635,0,0,1.18635,-10.2616,-10.2652)">
        <path
          d="M81.92,77.05L27.84,77.05C27.12,77.051 26.517,76.479 26.48,75.76L24.68,40.85C24.679,40.826 24.678,40.803 24.678,40.779C24.678,40.046 25.267,39.436 26,39.41L84.09,39.41C84.091,39.41 84.092,39.41 84.093,39.41C84.839,39.41 85.453,40.024 85.453,40.77C85.453,40.8 85.452,40.83 85.45,40.86L83.28,75.78C83.233,76.492 82.633,77.052 81.92,77.05Z"
          style={{ fill: 'white', fillOpacity: 0.92, fillRule: 'nonzero' }}
        />
      </g>
      <g transform="matrix(1.18635,0,0,1.18635,-10.2616,-10.2652)">
        <path
          d="M82.55,41.05L82.55,38.79C82.55,38.148 82.022,37.62 81.38,37.62L51.91,37.62C51.213,37.62 50.64,37.047 50.64,36.35L50.64,34.48C50.64,33.734 50.026,33.12 49.28,33.12L30.6,33.12C29.854,33.12 29.24,33.734 29.24,34.48L29.24,36.35C29.24,37.036 28.685,37.604 28,37.62L27.72,37.62C27.72,37.62 27.72,37.62 27.72,37.62C27.078,37.62 26.55,38.148 26.55,38.79C26.55,38.793 26.55,38.797 26.55,38.8L26.81,70.72C37.59,53 58.51,41.05 82.55,41.05Z"
          style={{ fill: 'white', fillOpacity: 0.92, fillRule: 'nonzero' }}
        />
      </g>
      <g transform="matrix(1.61616,0,0,1.58514,51.2228,44.0497)">
        <path
          d="M3,18L5,18C5.553,18 6,17.552 6,17L6,10C6,8.363 6.647,6.833 7.822,5.693C8.997,4.553 10.551,3.946 12.185,4.003C15.392,4.1 18,6.922 18,10.295L18,20L14,20L14,19L10,19L10,22L19,22C19.553,22 20,21.552 20,21L20,18L21,18C21.553,18 22,17.552 22,17L22,11C22,10.448 21.553,10 21,10L19.995,10C19.845,5.689 16.427,2.13 12.246,2.004C10.053,1.936 7.995,2.739 6.43,4.258C4.863,5.778 4,7.817 4,10L3,10C2.447,10 2,10.448 2,11L2,17C2,17.552 2.447,18 3,18Z"
          style={{ fill: '#337dce', fillRule: 'nonzero' }}
        />
      </g>
      <g transform="matrix(0.929549,0,0,0.910469,59.3743,53.3754)">
        <path
          d="M12,2C6.486,2 2,6.486 2,12C2,17.514 6.486,22 12,22C17.514,22 22,17.514 22,12C22,6.486 17.514,2 12,2ZM12,20C7.589,20 4,16.411 4,12C4,10.954 4.207,9.957 4.574,9.041C5.29,9.143 5.715,9.436 6.255,9.816C7.002,10.344 7.931,11 9.664,11C11.398,11 12.328,10.344 13.074,9.817C13.747,9.343 14.232,9 15.332,9C16.432,9 16.917,9.343 17.59,9.817C18.162,10.22 18.847,10.698 19.915,10.9C19.965,11.261 20,11.626 20,12C20,16.411 16.411,20 12,20Z"
          style={{ fill: '#337dce', fillRule: 'nonzero' }}
        />
      </g>
      <g transform="matrix(0.929549,0,0,0.910469,59.3743,53.3754)">
        <circle cx="8.5" cy="13.5" r="1.5" style={{ fill: '#337dce' }} />
      </g>
      <g transform="matrix(0.929549,0,0,0.910469,59.4208,53.4239)">
        <circle className="ri-eye" cx="15.5" cy="13.5" r="1.5" style={{ fill: '#337dce' }} />
      </g>
      <g transform="matrix(1.22347,0,0,1.22347,3.1427,-7.43337)">
        <path
          className="ri-wink"
          d="M56.337,59.757C56.337,59.757 57.914,61.293 59.247,59.614"
          style={{
            fill: 'none',
            stroke: '#337dce',
            strokeWidth: '0.84px',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 1.5,
          }}
        />
      </g>
    </g>
  </svg>
)
export default ReportIssueAnimation
