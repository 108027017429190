import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { connect } from 'react-redux'

import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import { showModal } from '../../../core/_actions/modalActions'
import {
  getFacetOptions,
  updateFacet,
  searchInventory,
  updateSearchQuery,
  facetUpdatePage,
} from '../../../core/actions/inventoryactions.js'
import { FACET_OPTIONS_MODAL, SEARCH_MODAL } from '../../../core/_constants/modalTypes'
import Collapsible from 'react-collapsible'
import LoadingSpinner from '../../helpers/loadingspinner.js'
import SearchIcon from '../assets/searchicon.js'
import IconCancel from '../../../core/assets/images/icons/icon-x'

//Add List of String Changes Here
const convertList = {
  HONDA: 'Honda',
}
const convertToCamelCase = searchField => {
  return searchField
    .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
    .replace(/^\w/, c => c.toLowerCase())
}
class FacetMenuComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      facetSearchFields: {},
    }
    this.handleFacetSearchChange = this.handleFacetSearchChange.bind(this)
  }
  componentDidMount() {
    let {
      facetCheckIn,
      token,
      user_id,
      getFacetOptionsConnect,
      searchInventoryConnect,
      facetFields,
      activeFacetFields,
      facetOptions,
      activeFacetOptions,
      updateFacetConnect,
      facetUpdatePageConnect,
      sortBy,
    } = this.props
    if (Date.now() - facetCheckIn.timestamp > facetCheckIn.timeout) {
      getFacetOptionsConnect({ token, user_id })
      facetUpdatePageConnect({
        facetFields: null,
        activeFacetFields: [],
        facetOptions: null,
        user_id,
        token,
        page: 1,
        sortBy,
      })
    }
    if (
      this.props.activeFacetOptions !== null &&
      Object.keys(this.state.facetSearchFields).length === 0
    ) {
      this.setState({
        facetSearchFields: Object.assign(
          ...this.props.activeFacetOptions.map(facet => ({
            [convertToCamelCase(facet.facet_name)]: '',
          })),
        ),
      })
    }
  }
  componentDidUpdate() {
    /*
      Go crazy, go stupid!!
      (This checks if the facet search fields have loaded, and maps them to the state in camelCase
      for searching input fields. Is this a good solution? Dear god no. But it does not impact
      TTI (time to interaction), therefore it's an acceptable solution.
    */
    if (
      this.props.activeFacetOptions !== null &&
      Object.keys(this.state.facetSearchFields).length === 0
    ) {
      this.setState({
        facetSearchFields: Object.assign(
          ...this.props.activeFacetOptions.map(facet => ({
            [convertToCamelCase(facet.facet_name)]: '',
          })),
        ),
      })
    }
  }
  handleFacetSearchChange(e, category) {
    let newState = Object.assign({}, this.state)
    newState.facetSearchFields[convertToCamelCase(category)] = e.target.value
    this.setState(newState)
  }
  handleClearFacetSearch(emptyString, category) {
    let newState = Object.assign({}, this.state)
    newState.facetSearchFields[convertToCamelCase(category)] = emptyString
    this.setState(newState)
  }
  updateCheckField = (facetIndex, optionIndex) => {
    let {
      searchQuery,
      facetFields,
      activeFacetFields,
      facetOptions,
      activeFacetOptions,
      updateFacetConnect,
      user_id,
      token,
      sortBy,
    } = this.props
    updateFacetConnect({
      facetIndex,
      optionIndex,
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery,
    })
  }
  updateSearchQuery = searchQuery => {
    this.props.updateSearchQueryConnect(searchQuery)
  }
  search = query => {
    let {
      facetFields,
      activeFacetFields,
      facetOptions,
      activeFacetOptions,
      searchInventoryConnect,
      user_id,
      token,
      sortBy,
      searchQuery,
    } = this.props
    console.log(searchQuery)
    searchInventoryConnect({
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery: query,
    })
  }
  optionsByPriority() {}
  render() {
    const {
      activeFacetOptions,
      facetOptions,
      facetFields,
      activeFacetFields,
      results,
      isLoading,
      searchQuery,
    } = this.props
    if (activeFacetOptions && facetOptions && facetFields) {
      return (
        <div className="refine-menu" style={{ animation: ` 1000ms fadeIn` }}>
          <div className="search-bar">
            <div className="search-indicator" />
            <input
              value={searchQuery}
              disabled={isLoading}
              placeholder="Search"
              type="text"
              id="query"
              onChange={e => {
                this.updateSearchQuery(e.target.value)
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.search(e.target.value)
                }
              }}
              name="query"
              label="query-search"
              title="Type search term here"
            ></input>
            {searchQuery && (
              <div
                onClick={() => {
                  this.updateSearchQuery('')
                  this.search('')
                }}
                className="search-bar-clear"
              >
                <IconCancel />
              </div>
            )}
            <div
              onClick={() => {
                this.search(searchQuery)
              }}
              className="go-button"
            >
              <SearchIcon label="submit-search">
                <p style={{ display: 'none' }}>Submit search</p>
              </SearchIcon>
            </div>
          </div>
          <div className="result-count">
            <b>{isLoading ? 'Loading Vehicles' : `${results} vehicles found`}</b>
            <button className="filter-button">Filters</button>
          </div>
          <div>
            <ul>
              {activeFacetOptions.map((facet, facetIndex) => (
                <div key={facet.facet_name + facetIndex}>
                  {facet.facet_options.length > 0 && (
                    <Collapsible
                      key={facet.facet_name}
                      trigger={
                        <h1>
                          {facet.facet_name}
                          <span>
                            {/*
                            <div
                              onClick={e => {
                                e.stopPropagation()
                                this.props.showModalConnect(FACET_OPTIONS_MODAL, {
                                  selectedField: facetIndex,
                                })
                               }}
                              className="see-all-models"
                            >
                              See All
                            </div>
                          */}
                          </span>
                        </h1>
                      }
                      transitionTime={1}
                    >
                      {facet.facet_options.length > 5 ? (
                        <div className="facet-search-container">
                          <SearchIcon color="lightgray" className="search-icon" />
                          <input
                            placeholder={`Search ${facet.facet_name}`}
                            value={
                              this.state.facetSearchFields[convertToCamelCase(facet.facet_name)] ||
                              ''
                            }
                            onChange={e => this.handleFacetSearchChange(e, facet.facet_name)}
                            title={`Search ${facet.facet_name}`}
                          />
                          {/* clear field */}
                          {this.state.facetSearchFields[convertToCamelCase(facet.facet_name)] !==
                            '' && (
                            <div
                              aria-hidden
                              role="button"
                              onClick={() => {
                                this.handleClearFacetSearch('', facet.facet_name)
                              }}
                              className="search-bar-clear search-filter-clear"
                            >
                              <IconCancel className="cancel-icon" />
                            </div>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="scrollable-wrapper">
                        <div className="filter-scroll">
                          {facet.facet_options.map(
                            (option, optionIndex) =>
                              (option.values.length ||
                                facetFields[facetIndex].includes(optionIndex)) > 0 && (
                                <React.Fragment key={`${option.name}${optionIndex}`}>
                                  {String(option.name)
                                    .toLowerCase()
                                    .indexOf(
                                      this.state.facetSearchFields[
                                        convertToCamelCase(facet.facet_name)
                                      ],
                                    ) !== -1 ||
                                  String(option.name).indexOf(
                                    this.state.facetSearchFields[
                                      convertToCamelCase(facet.facet_name)
                                    ],
                                  ) !== -1 ? (
                                    <label
                                      className="refine-item"
                                      key={`${option.name}${optionIndex}`}
                                    >
                                      {
                                        <React.Fragment>
                                          <fieldset>
                                            <legend
                                              style={{ display: 'none' }}
                                            >{`Filter ${facet.facet_name}`}</legend>
                                            <label
                                              style={{ display: 'none' }}
                                              htmlFor={`${option.name}${optionIndex}`}
                                            >{`Filter inventory by ${facet.facet_name}`}</label>
                                            <input
                                              key={`${option.keys.join(' ')}`}
                                              id={`${option.name}${optionIndex}`}
                                              name={facet.facet_name}
                                              checked={facetFields[facetIndex].includes(
                                                optionIndex,
                                              )}
                                              onChange={() => {
                                                this.updateCheckField(
                                                  facetIndex,
                                                  optionIndex,
                                                  !facetFields[facetIndex].includes(optionIndex),
                                                )
                                              }}
                                              type="checkbox"
                                            />
                                          </fieldset>

                                          <div>
                                            {`${
                                              convertList[option.name]
                                                ? convertList[option.name]
                                                : option.name
                                            } (${option.values.length})`}
                                          </div>
                                        </React.Fragment>
                                      }
                                    </label>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              ),
                          )}
                          {/*Get Rid of false && below for true facet*/}
                          {/*
                          activeFacetFields.includes(facetIndex) &&
                          facet.facet_options.map(
                            (option, optionIndex) =>
                              option.values.length === 0 && (
                                <label className="refine-item" key={`${option.name}${optionIndex}noresults`}>
                                  {
                                    <React.Fragment>
                                      <input
                                        key={`${option.key}noresults`}
                                        id={optionIndex}
                                        name={facet.facet_name}
                                        checked={facetFields[facetIndex].includes(optionIndex)}
                                        onChange={() => {
                                          this.updateCheckField(facetIndex, optionIndex)
                                        }}
                                        type="checkbox"
                                      />
                                      <div>{`${option.name}`}</div>
                                    </React.Fragment>
                                  }
                                </label>
                              ),
                          )*/}
                        </div>
                        <div className="facet-search-empty">No Results Found</div>
                      </div>
                    </Collapsible>
                  )}
                </div>
              ))}
            </ul>
          </div>
        </div>
      )
    } else {
      return (
        <div className="refine-menu">
          <LoadingSpinner loading wheel timeout={60 * 1000} />
        </div>
      )
    }
  }
}
const mapStateToProps = state => ({
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  activeFacetOptions: state.inventory.activeFacetOptions,
  user_id:
    state.authentication.user && state.authentication.user.user.profile.legacy_user.legacy_user_id,
  token: state.authentication.user && state.authentication.user.token,
  results: state.inventory.inventoryContent && state.inventory.inventoryContent.count,
  isLoading: state.inventory.isLoading,
  sortBy: state.inventory.sortBy,
  facetCheckIn: state.inventory.checkIn.GET_FACET_OPTIONS,
  viewMyInventory: state.inventory.viewMyInventory,
  searchQuery: state.inventory.searchQuery,
})
const FacetMenu = UserInfoWrapper(FacetMenuComponent)
export default connect(mapStateToProps, {
  getFacetOptionsConnect: getFacetOptions,
  updateFacetConnect: updateFacet,
  searchInventoryConnect: searchInventory,
  showModalConnect: showModal,
  updateSearchQueryConnect: updateSearchQuery,
  facetUpdatePageConnect: facetUpdatePage,
})(FacetMenu)
