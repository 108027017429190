// general: action file for all things 'dashboard' data being passed back and forth.

// API(s)
import {
  StickerPullPutAPIURL,
  StickerPullDownloadGetAPIURL,
  StickerPullSubscriptionGetAPIURL,
  StickerPullListGetAPIURL,
  StickerSupportedGetAPIURL,
  StickerPullGetHistory,
  StickerPullSupportCatalogAPIURL,
  StickerPullV2APIURL,
  StickerPullV2PollAPIURL,
} from '../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

export function pullSticker(token, vin, externalPull, referrer) {
  let url = `${AIP_DJAPI_URLS.StickerPullV2APIURL}${vin}${
    externalPull ? `?external_pull=True${referrer ? `&request_source=${referrer}` : ''}` : ''
  }`
  return AIP_DJAPI(url, {
    method: 'PUT',
    headers: {
      Authorization: `Token ${token}`,
    },
  })
}

export function pollSticker(token, id) {
  let url = `${AIP_DJAPI_URLS.StickerPullV2PollAPIURL}${id}`
  return AIP_DJAPI(url, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
    },
  })
}

export function putSticker(providedVIN, token, externalPull, referrer) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.StickerPullPutAPIURL}${providedVIN}${
      externalPull ? `?external_pull=True${referrer ? `&request_source=${referrer}` : ''}` : ''
    }`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    },
  ).then(response => {
    return response.json()
  })
}
export function downloadSticker(token, url) {
  return AIP_DJAPI(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Token ${token}`,
    },
  })
}
export function getSupportedStickerpull(token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.StickerSupportedGetAPIURL}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function getStickerPullHistory(success, token, items_per_page, page) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.StickerPullGetHistory}=${success}&items_per_page=${items_per_page}&page=${page}`,
    {
      method: 'GET',
      headers,
    },
  ).then(response => {
    return response.json()
  })
}
export function getStickerPullSupportCatalog(token) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.StickerPullSupportCatalogAPIURL}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
