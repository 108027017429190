import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from '../../layouts/modal'

import {
  getCompanyTemplates,
  getStoreTemplates,
  getUserTemplates,
  updateCompanyTemplateList,
  updateStoreTemplateList,
  updateUserTemplateList,
  selectTemplate,
  changeTemplateType,
} from '../../../core/actions/emailTemplateActions'
import profileactions from '../../../core/actions/users/profileactions'
import { hideModal } from '../../../core/_actions/modalActions'

import Warning from '../../../core/assets/images/icons/warning'
import LoadingSpinner from '../../helpers/loadingspinner'

function EmailTemplates(props) {
  const {
    default_store_id,
    templateTypes,
    currentTemplateType,
    currentSelectedTemplate,
    companyTemplates,
    storeTemplates,
    userTemplates,
    getEmailTemplate,
    selectTemplateConnect,
    changeTemplateTypeConnect,
    updateCompanyTemplateListConnect,
    updateStoreTemplateListConnect,
    updateUserTemplateListConnect,
    token,
    companyName,
    companyId,
    defaultStoreName,
    userId,
    isMessageEdited,
    hideModalConnect,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('Company')
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [userDisclaimerResponse, setUserDisclaimerResponse] = useState('No')
  const activeCompanyTemplates = companyTemplates.filter(template => template.active)
  const activeStoreTemplates = storeTemplates.filter(template => template.active)
  const activeUserTemplates = userTemplates.filter(template => template.active)
  const lostChangesDisclaimer = `
  Are you sure you want to load another template.
  You will lose changes you had previously made to the message.
  `

  const fetchCompanyTemplates = () => {
    getCompanyTemplates(token, companyId)
      .then(res => {
        if (res) {
          updateCompanyTemplateListConnect(res.results, companyId)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const fetchStoreTemplates = store_id => {
    setIsLoading(true)
    getStoreTemplates(token, store_id)
      .then(res => {
        if (res) {
          setIsLoading(false)
          updateStoreTemplateListConnect(res.results, store_id)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const fetchUserTemplates = () => {
    setIsLoading(true)
    getUserTemplates(token)
      .then(res => {
        setIsLoading(false)
        if (res) {
          updateUserTemplateListConnect(res.results)
        }
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  const handleTemplateTypeChange = value => {
    changeTemplateTypeConnect(value)
    setSelectedType(value)
  }

  const handleSubmit = () => {
    selectTemplateConnect(selectedTemplate)
    getEmailTemplate(selectedTemplate)
    hideModalConnect()
  }

  useEffect(() => {
    if (selectedType == 'Company') {
      companyTemplates.length > 0 ? null : fetchCompanyTemplates()
    }

    if (selectedType == 'Store') {
      storeTemplates.length > 0 ? null : fetchStoreTemplates(default_store_id)
    }

    if (selectedType == 'User') {
      userTemplates.length > 0 ? null : fetchUserTemplates()
    }
  }, [selectedType])

  useEffect(() => {
    currentSelectedTemplate !== null ? setSelectedTemplate(currentSelectedTemplate) : null
  }, [])

  const renderTableHeader = () => {
    return (
      <div className="templateHeader">
        <span className="templateRadio" />
        <span className="templateName">Name</span>
        {selectedType === 'Store' ? <span className="templateStore">Store</span> : null}
      </div>
    )
  }

  function handleSelectTemplateRadioBtn(emailTemplate, index) {
    setSelectedTemplate(emailTemplate)
    document.getElementById(`emailTemplateRadio-${index}`).click()
  }

  const renderTemplateRows = (emailTemplate, index) => {
    return (
      <div className="templateRow" key={index.toString()}>
        <div onClick={() => handleSelectTemplateRadioBtn(emailTemplate, index)}>
          <input
            className="templateRadio"
            id={`emailTemplateRadio-${index}`}
            type="radio"
            name="emailTemplate"
            value={emailTemplate}
            defaultChecked={
              currentSelectedTemplate !== null && currentSelectedTemplate === emailTemplate
                ? currentSelectedTemplate
                : null
            }
          />
          <span className="templateName">{emailTemplate.name}</span>
        </div>
        {selectedType === 'Store' ? (
          <span className="templateStore">{defaultStoreName}</span>
        ) : null}
      </div>
    )
  }

  const renderNoTemplatesMessage = templateType => {
    return (
      <div className="noTemplatesError">
        <Warning />
        There are no {templateType} email templates at this time. You can create, edit &
        enable/disable templates under the email templates tab in admin settings and account
        settings.
      </div>
    )
  }

  return (
    <Modal>
      <div className="email-templates-modal">
        <div className="modal-content">
          <span className="modal-header">Select a template</span>

          <div className="templateTypes-dropdown">
            <label htmlFor="templateTypes">Template Type:</label>
            <select
              defaultValue={templateTypes[0]}
              onChange={e => handleTemplateTypeChange(e.target.value)}
              name="templateTypes"
              autoFocus
            >
              {templateTypes.map(templateType => {
                return (
                  <option key={templateType} value={templateType}>
                    {templateType}
                  </option>
                )
              })}
            </select>
          </div>

          {(activeCompanyTemplates.length > 0 && selectedType === 'Company') ||
            (activeStoreTemplates.length > 0 && selectedType === 'Store') ||
            (activeUserTemplates.length > 0 && selectedType === 'User' && renderTableHeader())}

          {isLoading && <LoadingSpinner loading wheel />}

          {companyTemplates.length > 0 && selectedType === 'Company'
            ? companyTemplates.map(
                template =>
                  template.active &&
                  renderTemplateRows(template, companyTemplates.indexOf(template)),
              )
            : null}

          {activeCompanyTemplates.length === 0 && !isLoading && selectedType === 'Company'
            ? renderNoTemplatesMessage('company')
            : null}

          {storeTemplates.length > 0 && selectedType === 'Store'
            ? storeTemplates.map(
                template =>
                  template.active && renderTemplateRows(template, storeTemplates.indexOf(template)),
              )
            : null}

          {activeStoreTemplates.length === 0 && !isLoading && selectedType === 'Store'
            ? renderNoTemplatesMessage('store')
            : null}

          {userTemplates.length > 0 && selectedType === 'User'
            ? userTemplates.map(
                template =>
                  template.active && renderTemplateRows(template, userTemplates.indexOf(template)),
              )
            : null}

          {activeUserTemplates.length === 0 && !isLoading && selectedType === 'User'
            ? renderNoTemplatesMessage('user')
            : null}

          {isMessageEdited && selectedTemplate !== null ? (
            <div className="disclaimer">
              <span>{lostChangesDisclaimer}</span>
              <span className="disclaimer-radio">
                <input
                  type="radio"
                  name="disclaimer"
                  onChange={e => setUserDisclaimerResponse(e.target.value)}
                  value="Yes"
                  defaultChecked={userDisclaimerResponse === 'Yes' ? true : false}
                />
                <label htmlFor="disclaimer">Yes</label>
                <input
                  type="radio"
                  name="disclaimer"
                  onChange={e => setUserDisclaimerResponse(e.target.value)}
                  value="No"
                  defaultChecked={
                    userDisclaimerResponse === 'No' || userDisclaimerResponse === null
                      ? true
                      : false
                  }
                />
                <label htmlFor="disclaimer">No</label>
              </span>
            </div>
          ) : null}

          <button
            className="select-template-button"
            onClick={() => handleSubmit()}
            type="submit"
            disabled={
              selectedTemplate === null || (isMessageEdited && userDisclaimerResponse === 'No')
            }
          >
            Select Template
          </button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  default_store_id: state.authentication.user.user.profile.default_company_store,
  templateTypes: state.emailTemplates.templateTypes,
  currentTemplateType: state.emailTemplates.currentTemplateType,
  currentSelectedTemplate: state.emailTemplates.selectedTemplate,
  companyTemplates: state.emailTemplates.companyTemplates,
  storeTemplates: state.emailTemplates.storeTemplates,
  userTemplates: state.emailTemplates.userTemplates,
  getEmailTemplate: state.modal.props.getEmailTemplate,
  isMessageEdited: state.modal.props.isMessageEdited,
  token: state.authentication.user.token,
  userId: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  companyId:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_id,
  companyName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_name,
  defaultStoreName:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.store_full_name,
})

const mapDispatchToProps = {
  selectTemplateConnect: selectTemplate,
  changeTemplateTypeConnect: changeTemplateType,
  updateCompanyTemplateListConnect: updateCompanyTemplateList,
  updateStoreTemplateListConnect: updateStoreTemplateList,
  updateUserTemplateListConnect: updateUserTemplateList,
  hideModalConnect: hideModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
