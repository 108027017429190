import React from 'react'

const VideoIcon = props => {
  const color = props.color ? props.color : '#000000'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path fill={props.color} d="M0 0h24v24H0z" fill="none" />
      <path
        fill={props.color}
        d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
      />
    </svg>
  )
}
export default VideoIcon
