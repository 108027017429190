import React from 'react'

const PrintIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M43.16,15.11H37.74V6h-25v9.08H6.84a3.79,3.79,0,0,0-3.78,3.78V33.67a3.79,3.79,0,0,0,3.78,3.78h5.77v6h25v-6h5.51a3.79,3.79,0,0,0,3.78-3.78V18.89A3.79,3.79,0,0,0,43.16,15.11Zm-27.31-6h19v6h-19ZM34.77,40.52H15.72V30.89H34.77Zm9-8.08a1.61,1.61,0,0,1-1.61,1.61H37.65V27.81h-25v6.24H7.82a1.61,1.61,0,0,1-1.61-1.61V20.12a1.61,1.61,0,0,1,1.61-1.61H42.18a1.61,1.61,0,0,1,1.61,1.61Z" />
      <circle cx="11" cy="23.16" r="2.24" />
      <circle cx="17.96" cy="23.16" r="2.24" />
    </svg>
  )
}
export default PrintIcon
