import React from 'react'

const EyeOpenIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M25.07,9.4H24.9A27.84,27.84,0,0,0,0,25,27.78,27.78,0,0,0,50,25,27.87,27.87,0,0,0,25.07,9.4Zm-4.76,6.15a4.73,4.73,0,1,1-4.73,4.72A4.72,4.72,0,0,1,20.31,15.55Zm4.61,21.71A21.92,21.92,0,0,1,5.37,25a21.85,21.85,0,0,1,9-9.44,12.36,12.36,0,1,0,21.36,0,21.91,21.91,0,0,1,9,9.44A21.93,21.93,0,0,1,24.92,37.26Z" />
    </svg>
  )
}
export default EyeOpenIcon
