import React from 'react'

const TopTools = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M43.64,36,29.33,21.72A13.11,13.11,0,0,0,30,17.64,12.62,12.62,0,0,0,17.51,4.89,12.21,12.21,0,0,0,10.57,7L18,11.33a2.93,2.93,0,0,1,1.07,4l-2.46,4.26a2.94,2.94,0,0,1-4,1.07L5.09,16.3A11.9,11.9,0,0,0,5,17.64,12.62,12.62,0,0,0,17.51,30.39a12.12,12.12,0,0,0,4.23-.77L35.89,43.77a4.58,4.58,0,0,0,6.48,0l1.27-1.26A4.58,4.58,0,0,0,43.64,36Zm-4.55,5.38a2.36,2.36,0,1,1,2.35-2.36A2.36,2.36,0,0,1,39.09,41.41Z" />
      <path
        className="a"
        d="M42.24,37.29,29.61,24.66a2.93,2.93,0,0,0-4.12,0l-1,1a2.91,2.91,0,0,0,0,4.12L37.13,42.4a2.92,2.92,0,0,0,4.13,0l1-1A2.92,2.92,0,0,0,42.24,37.29Zm-3.16,4.13a2.36,2.36,0,1,1,2.36-2.36A2.36,2.36,0,0,1,39.08,41.42Z"
      />
    </svg>
  )
}
export default TopTools
