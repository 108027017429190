import React, { useState, useEffect, useRef } from 'react'
import './arcdisplay.scss'
import IPacketRingLogo from '../../../core/assets/images/icons/ipacketringlogo'
import WindowSticker from '../../../core/assets/images/icons/windowsticker'
import PrintIcon from '../../../core/assets/images/icons/print-icon'
import ArcLogo from '../../../core/assets/images/arc_logo.png'
import Chart from 'chart.js'
import ContentLoader from 'react-content-loader'
import ToolTip from '../../helpers/tooltip'

import { ARC_COMMON_ISSUES_MODAL, WORD_TRACK_MODAL } from '../../../core/_constants/modalTypes'

import { showModal } from '../../../core/_actions/modalActions'

import { connect } from 'react-redux'
import { postArcProcessingRequest, getArcData } from './state/arcdisplayactions'

let arcDataTimeout = null
const arcDataTimeoutDelay = 3 * 1000 //3 seconds

function ArcDataDisplay(props) {
  const {
    loading,
    vin,
    arcData,
    showModalConnect,
    enteredMileage,
    submittedMileage,
    downloadSticker,
  } = props
  const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' })
  const arcBreakdownGraphRef = useRef(null)

  let renderGraph = animate => {
    //TODO actually fix this because animate flag is terrible way to solve this bug = Khris
    let animationOption
    let arcBreakdownGraph = new Chart(arcBreakdownGraphRef.current.getContext('2d'), {
      type: 'bar',
      data: {
        labels: arcData.mileage_ranges.map(range => {
          return `${range.as_str} ${arcData.distance_unit.symbol}`
        }),
        datasets: [
          {
            label: 'NON CPO',
            backgroundColor: '#1cd760',
            data: arcData.mileage_ranges.map(range => {
              return range.avg_cost.non_cpo
            }),
          },
          {
            label: 'CPO',
            backgroundColor: '#004438',
            data: arcData.mileage_ranges.map(range => {
              return range.avg_cost.cpo
            }),
          },
        ],
      },
      options: {
        animation: false,
        maintainAspectRatio: false,
        barValueSpacing: 10,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      },
      plugins: [
        {
          id: 'horizontalDottedLine',
          afterDatasetsDraw(chart, args, options) {
            const {
              ctx,
              chartArea: { top, right, bottom, left, height },
              scales,
              width,
            } = chart
            ctx.save()
            ctx.strokeStyle = 'grey'
            ctx.strokeRect(left, scales['y-axis-0'].getPixelForValue(arcData.overall_avg), width, 0)
            ctx.restore()
          },
        },
      ],
    })
  }
  useEffect(() => {
    if (arcBreakdownGraphRef.current) {
      renderGraph(false)
    }
  }, [enteredMileage])
  useEffect(() => {
    if (arcBreakdownGraphRef.current) {
      renderGraph(true)
    }
  }, [arcBreakdownGraphRef, arcData, enteredMileage])
  if (loading) {
    return (
      <div className="arc-display-loading-container">
        <div className="arc-display-loading-text">Request may take up to 2 minutes.</div>
        <ContentLoader
          speed={2}
          width={1400}
          height={900}
          viewBox="0 0 1400 900"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="60" rx="2" ry="2" width="133" height="133" />
          <rect x="147" y="72" rx="0" ry="0" width="150" height="16" />
          <rect x="149" y="102" rx="0" ry="0" width="120" height="10" />
          <rect x="150" y="121" rx="0" ry="0" width="113" height="10" />
          <rect x="1" y="232" rx="0" ry="0" width="282" height="14" />
          <rect x="516" y="64" rx="0" ry="0" width="830" height="400" />
          <rect x="3" y="295" rx="0" ry="0" width="466" height="260" />
          <rect x="516" y="491" rx="0" ry="0" width="169" height="21" />
          <rect x="517" y="531" rx="0" ry="0" width="150" height="80" />
          <rect x="677" y="531" rx="0" ry="0" width="150" height="80" />
          <rect x="837" y="531" rx="0" ry="0" width="150" height="80" />
        </ContentLoader>
      </div>
    )
  } else if (vin && vin.length === 17 && arcData) {
    let avgNonCpo = `${arcData.currency_unit.symbol}${currencyFormatter.format(
      Math.round(arcData.avg_ro_cost.non_cpo),
    )}`
    let avgCpo = `${arcData.currency_unit.symbol}${currencyFormatter.format(
      Math.round(arcData.avg_ro_cost.cpo),
    )}`
    return (
      <div className="arc-display-body">
        <div className="arc-display-general">
          <div className="arc-display-general-section arc-display-general-vehicle-info">
            <div className="arc-display-general-vehicle-info-image">
              <IPacketRingLogo />
            </div>
            <div className="arc-display-general-vehicle-info-details">
              <div className="arc-display-general-vehicle-info-details-ymm">
                {`${
                  arcData.candidate_info.year
                } ${arcData.candidate_info.make.toUpperCase()} ${arcData.candidate_info.model.toUpperCase()}`}
              </div>
              <span>{vin}</span>
              <br />
              Miles: {currencyFormatter.format(submittedMileage)}
              <br />
              Location: {arcData.candidate_info.state}
            </div>
          </div>
          <div className="arc-display-general-section arc-display-general-window-sticker">
            {downloadSticker ? (
              downloadSticker()
            ) : (
              <a href={`/stickerpull/${vin}`} target="_blank">
                <WindowSticker />
                View Window Sticker/Build Sheet
              </a>
            )}
          </div>

          <div className="arc-display-general-section arc-display-general-vehicle-avg-recon-container">
            <div className="arc-display-general-vehicle-avg-recon">
              <div className="arc-display-general-vehicle-avg-recon-title">
                Average Recon Cost (ARC)
              </div>
              <div className="arc-display-general-vehicle-avg-recon-display">
                <div className="arc-display-general-vehicle-avg-recon-display-non-cpo">
                  <h1>{avgNonCpo}</h1>
                  <h2>NON CPO</h2>
                  <div className="arc-display-general-vehicle-avg-recon-display-vehicles-analyzed">
                    Vehicles Analyzed: {arcData.non_cpo_vehicles_analyzed}
                  </div>
                </div>
                <div className="arc-display-general-vehicle-avg-recon-display-cpo">
                  <h1>{avgCpo}</h1>
                  <h2>CPO</h2>
                  <div className="arc-display-general-vehicle-avg-recon-display-vehicles-analyzed">
                    Vehicles Analyzed: {arcData.cpo_vehicles_analyzed}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="arc-display-detailed">
          <div className="arc-display-detailed-graph">
            <div className="arc-display-detailed-section-title arc-display-detailed-graph-title">
              ARC Breakdown
            </div>
            <div className="arc-display-detailed-graph-display">
              <canvas
                className="arc-display-detailed-graph-display-canvas"
                ref={arcBreakdownGraphRef}
              />
            </div>
          </div>

          <div className="arc-display-detailed-repairs">
            <div className="arc-display-detailed-section-title arc-display-detailed-repairs-title">
              Most Common Repairs
            </div>
            <div className="arc-display-detailed-repairs-display">
              {arcData.common_issues.overall_rankings.length ? (
                arcData.common_issues.overall_rankings.slice(0, 5).map((issue, i) => {
                  if (arcData.common_issues[issue.issue.toLowerCase()]) {
                    return (
                      <div
                        className="arc-display-detailed-repairs-display-item"
                        onClick={() => {
                          // showModalConnect(ARC_COMMON_ISSUES_MODAL, {
                          //   title: issue.display_as,
                          //   issueData: arcData.common_issues[issue.issue.toLowerCase()],
                          // })
                        }}
                      >
                        <div>
                          {`${i + 1}) ${issue.display_as}`}
                          {/*
                          <div className="arc-display-detailed-repairs-display-item-number">
                            {i}
                            {arcData.common_issues[issue.issue.toLowerCase()].issues.length < 100
                              ? arcData.common_issues[issue.issue.toLowerCase()].issues.length
                              : '99+'}
                          </div>
                        */}
                        </div>
                      </div>
                    )
                  }
                  return <React.Fragment />
                })
              ) : (
                <div className="arc-display-detailed-repairs-display-item">
                  <div>None</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <React.Fragment />
  }
}

function ArcDisplay(props) {
  const { vin, downloadSticker, token, showModalConnect } = props
  let mileage = props.mileage
  mileage = parseInt(mileage)

  const [arcData, setArcData] = useState(null)
  const [enteredMileage, setEnteredMileage] = useState(mileage ? mileage : '')
  const [submittedMileage, setSubmittedMileage] = useState(null)
  const [enteredZip, setEnteredZip] = useState()
  const [submittedZip, setSubmittedZip] = useState(null)
  const [loading, setLoading] = useState(false)

  const setupArcDataTimeout = task_id => {
    arcDataTimeout = setTimeout(() => {
      // setArcData(mockArcData.result)
      getArcData(token, task_id).then(res => {
        if (res) {
          if (res.state === 'SUCCESS') {
            setLoading(false)
            setArcData(res.result)
          } else if (res.state === 'PENDING') {
            setupArcDataTimeout(task_id)
          }
        } else {
          //Failed To Load Data
        }
      })
    }, arcDataTimeoutDelay)
  }

  const sendProcessingRequest = () => {
    if (vin && vin.length === 17 && enteredMileage && enteredMileage > 0) {
      setLoading(true)
      setSubmittedMileage(enteredMileage)
      setSubmittedZip(enteredZip)
      console.log('ENTERED ZIP', enteredZip)
      postArcProcessingRequest(
        token,
        vin,
        enteredMileage,
        (enteredZip + '').length === 5 && enteredZip,
      ).then(res => {
        if (res && res.task_id) {
          setupArcDataTimeout(res.task_id)
        } else {
          //Failed to Load Data
        }
      })
    }
  }
  useEffect(() => {})
  useEffect(() => {}, [])

  return (
    <div
      className={`arc-display-container${
        (vin && vin.length === 17 && arcData) || loading
          ? ' arc-display-container-search-active'
          : ''
      }`}
    >
      <div className="arc-display">
        <div className="arc-display-desc">
          Average estimated reconditioning cost and repair information.
        </div>

        <div className="arc-display-header">
          <div className="arc-display-header-left">
            <img src={ArcLogo} />
            BETA
          </div>
          <div className="arc-display-header-right">
            {/*<PrintIcon />*/}
            <input
              placeholder="Zip(Optional)"
              minlength="5"
              type="text"
              maxlength="5"
              value={enteredZip}
              onChange={e => {
                let val = parseInt(e.target.value)
                if (!isNaN(val) && val > 0) {
                  setEnteredZip(val)
                } else if (e.target.value === '') {
                  setEnteredZip('')
                }
              }}
            />
            <input
              placeholder="Mileage"
              minlength="17"
              type="text"
              maxlength="17"
              value={enteredMileage}
              onChange={e => {
                let val = parseInt(e.target.value)
                if (!isNaN(val) && val > 0 && e.target.value.length < 7) {
                  setEnteredMileage(val)
                } else if (e.target.value === '') {
                  setEnteredMileage('')
                }
              }}
            />
            <button
              disabled={!enteredMileage}
              onClick={() => {
                sendProcessingRequest()
              }}
            >
              Submit
            </button>
          </div>
        </div>
        <ArcDataDisplay
          loading={loading}
          vin={vin}
          arcData={arcData}
          downloadSticker={downloadSticker}
          showModalConnect={showModalConnect}
          enteredMileage={enteredMileage}
          submittedMileage={submittedMileage}
        />
        <div className="arc-display-footer">Beta v0.3.0</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.authentication.user.token,
})
export default connect(mapStateToProps, {
  showModalConnect: showModal,
})(ArcDisplay)
