import React, { useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import RichTextEditor from 'react-rte'

import Modal from '../../layouts/modal'
import ToolTip from '../../helpers/tooltip'

import { editTemplate, updateEmailTemplate } from '../../../core/actions/emailTemplateActions'
import { hideModal } from '../../../core/_actions/modalActions'
import packetsendactions from '../../../core/actions/packets/packetsendactions'

import Warning from '../../../core/assets/images/icons/warning'
import IconEmail from '../../../core/assets/images/icons/icon-email'
import VehicleIcon from '../../../core/assets/images/icons/vehicle-icon'
import VehicleMultipleIcon from '../../../core/assets/images/icons/vehicle-multiple-icon'

import '../styles/emailTemplates.scss'

function EditEmailTemplate(props) {
  const {
    editTemplateConnect,
    hideModalConnect,
    selectedTemplate,
    token,
    storeName,
    templateType,
    testVehicleId,
    darkModeEnabled,
  } = props
  const editedTemplate = selectedTemplate
  const [richTextValue, setRichTextValue] = useState(
    RichTextEditor.createValueFromString(selectedTemplate.message, 'html'),
  )
  const [inputFieldValue, setInputFieldValue] = useState(selectedTemplate.name)
  const [isKeywordClicked, setIsKeywordClicked] = useState(false)
  const keywordSvgClass = isKeywordClicked ? 'keyword-button-clicked' : 'keyword-button'
  const defaultIsSingleVehcleValue =
    selectedTemplate.is_single_vehicle ||
    (selectedTemplate.is_single_vehicle === false && selectedTemplate.is_multiple_vehicle === false)
      ? 'Single'
      : 'Multiple'
  const [isSingleVehicle, setSingleVehicle] = useState(defaultIsSingleVehcleValue)
  const [emailPreviewVisisble, setEmailPreviewVisisble] = useState(false)
  const previewButtonClass = emailPreviewVisisble ? 'preview-button-clicked' : 'preview-button'
  const previewButtonText = emailPreviewVisisble ? 'Hide Email Preview' : 'Show Email Preview'
  const [fetchedMessage, setFetchedMessage] = useState('')
  const modifiedMessage = fetchedMessage.replace('{{message}}', richTextValue.toString('html'))
  const templateTextPlaceholder = `Hello Jane,

Here is the information you requested about the {{vehicle}}.
Please let me know if you need anything else.

Thank you,
{{user}}`

  const fetchEmailPreview = () => {
    const vehicle_ids = []
    vehicle_ids.push(testVehicleId)
    packetsendactions
      .getEmailPreview(vehicle_ids, token)
      .then(res => {
        if (res) {
          setFetchedMessage(res)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleEmailPreview = () => {
    if (emailPreviewVisisble === false) {
      fetchEmailPreview()
      setEmailPreviewVisisble(true)
    } else {
      setEmailPreviewVisisble(false)
    }
  }

  const handleKeywordInput = (keyword: string) => {
    const selectionState = richTextValue.getEditorState().getSelection()
    const currentContent = richTextValue.getEditorState().getCurrentContent()
    const blockMap = {}

    const currentAnchorKey = selectionState.getAnchorKey()
    const currentCursorPosition = selectionState.getEndOffset()
    const currentContentBlock = currentContent.getBlockForKey(currentAnchorKey).getText()

    currentContent.getBlockMap().map(contentBlock => {
      blockMap[contentBlock.getKey()] = contentBlock.getText()
    })

    const slicedText = currentContentBlock.slice(currentCursorPosition).split(' ')
    slicedText.unshift(keyword)
    slicedText.unshift(currentContentBlock.slice(0, currentCursorPosition))
    slicedText[0].length === 0 ? slicedText.splice(0, 1) : null
    const editedText = slicedText.join(' ')

    blockMap[currentAnchorKey] = editedText

    const outputString = Object.values(blockMap)
      .map(contentBlock => {
        return '<p>' + contentBlock + '<p/>'
      })
      .join('\n')

    setRichTextValue(RichTextEditor.createValueFromString(outputString, 'html'))

    // reset the dropdown
    document.getElementById('rte-custom-select').selectedIndex = 0
  }

  const saveChanges = () => {
    const isSingleVehicleBoolean = isSingleVehicle === 'Single' ? true : false
    editedTemplate.name = inputFieldValue
    editedTemplate.message = richTextValue.toString('html')
    editedTemplate.is_single_vehicle = isSingleVehicleBoolean
    editedTemplate.is_multiple_vehicle = !isSingleVehicleBoolean

    if (editedTemplate === selectedTemplate) {
      updateEmailTemplate(token, editedTemplate, editedTemplate.id)
        .then(res => {
          console.log(res)
          editTemplateConnect(selectedTemplate, editedTemplate, templateType)
          toast(`Successfully edited ${editedTemplate.name}`)
        })
        .catch(error => {
          console.error(error)
          toast(`Error: Unable to edit ${editedTemplate.name}`)
        })
    }

    hideModalConnect()
  }

  const customrteSelectTag = () => {
    return (
      <select onChange={e => handleKeywordInput(e.target.value)} className="rte-custom-select">
        <option disabled selected>
          Select a keyword
        </option>
        <option value={`{{user}}`}>User</option>
        <option value={`{{vehicle}}`}>Vehicle</option>
      </select>
    )
  }

  const rteCustomControls = () => {
    return [() => customrteSelectTag()]
  }

  return (
    <Modal>
      <div className="editEmailTemplate addEmailTemplate">
        <div className="template-options">
          <div className="templateType-radio" id="ED-templateRadio">
            <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
              Template Type
            </span>
            <span>{templateType}</span>
          </div>

          {templateType === 'Store' && (
            <div className="templateType-radio">
              <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
                Store Name
              </span>
              <span>{storeName}</span>
            </div>
          )}

          <div className="templateType-radio">
            <ToolTip
              onHover
              tip={
                <React.Fragment>
                  This option allows you to specify whether the template
                  <br />
                  will be for single or multiple packet sends.
                </React.Fragment>
              }
            >
              <Warning className="tooltip-icon" />
            </ToolTip>
            <span className={darkModeEnabled ? 'radio-label-dark radio-label' : 'radio-label'}>
              Vehicle Number
            </span>
            <select
              onChange={e => setSingleVehicle(e.target.value)}
              defaultValue={defaultIsSingleVehcleValue}
            >
              <option value="Single">Single</option>
              <option value="Multiple">Multiple</option>
            </select>
            <span className="vehicle-icon">
              {isSingleVehicle === 'Single' ? (
                <VehicleIcon />
              ) : (
                <VehicleMultipleIcon color="#3278c8" />
              )}
            </span>
          </div>
        </div>

        <div className={darkModeEnabled ? 'inputField-dark inputField' : 'inputField'}>
          <label htmlFor="Email Template Name">Email Template Name</label>
          <input
            name="Email Template Name"
            type="text"
            placeholder="Enter Template Name"
            value={inputFieldValue}
            onChange={e => setInputFieldValue(e.target.value)}
          />
        </div>

        <div className="email-preview-button">
          <ToolTip
            onHover
            tip={
              <React.Fragment>
                This button allows you get a preview of your email.
                <br />
                This preview shows you how your email wil actually look
                <br />
                after sharing a packet through email.
              </React.Fragment>
            }
          >
            <span className={previewButtonClass} onClick={() => handleEmailPreview()}>
              <IconEmail color={emailPreviewVisisble ? '#fefefe' : '#1cd760'} /> {previewButtonText}
            </span>
          </ToolTip>
        </div>

        {emailPreviewVisisble && (
          <div className="email-preview">
            <h3>Email Preview</h3>
            <div className="email-body" dangerouslySetInnerHTML={{ __html: modifiedMessage }} />
          </div>
        )}

        <span className={keywordSvgClass} onClick={() => setIsKeywordClicked(!isKeywordClicked)}>
          <Warning />
          {isKeywordClicked ? 'Hide Keyword Info' : 'Show Keyword Info'}
        </span>

        <div className="keyword-disclaimer">
          {isKeywordClicked && (
            <React.Fragment>
              <p>
                You can add keywords to your template to make it more re-usable. Keywords allow you
                to inject variable data into your templates.
              </p>
              <p>
                Here is a list of keywords you can use:
                <li>{isSingleVehicle === 'Single' ? `{{ vehicle }}` : `{{ vehicle_count }}`}</li>
                <li>{`{{ user }}`}</li>
              </p>
            </React.Fragment>
          )}
        </div>

        <RichTextEditor
          value={richTextValue}
          className={darkModeEnabled ? 'richTextEditor-dark richTextEditor' : 'richTextEditor'}
          onChange={value => setRichTextValue(value)}
          placeholder={templateTextPlaceholder}
          customControls={rteCustomControls()}
        />

        <button className="submit" type="submit" onClick={() => saveChanges()}>
          Save
        </button>
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  selectedTemplate: state.modal.props.selectedTemplate,
  token: state.authentication.user.token,
  storeName: state.modal.props.storeName,
  templateType: state.modal.props.templateType,
  testVehicleId:
    state.inventory.inventoryContent.results.length > 0 &&
    state.inventory.inventoryContent.results[0].vehicle_id,
  darkModeEnabled:
    state.authentication.user && state.authentication.user.user.profile.dark_mode_webapp,
})

const mapDispatchToProps = {
  hideModalConnect: hideModal,
  editTemplateConnect: editTemplate,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailTemplate)
