import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import format from 'date-fns/format'
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import {
  DASHBOARD_CUSTOMER_MODAL,
  DASHBOARD_VIEWS_MODAL,
  DASHBOARD_SENT_MODAL,
} from '../../../core/_constants/modalTypes'
import SoldIcon from '../../../core/assets/images/icons/SoldIcon.js'
import Helper from '../../../core/helpers/platformhelper'

import iconLinkedin from '../../../core/assets/images/icons/icon-linkedinlogo.png'
import iconFacebook from '../../../core/assets/images/icons/icon-facebooklogo.png'

class DashboardRowSocial extends Component {
  render() {
    const { info, animationDelay, token } = this.props
    let packetTypeLabel = 'Social Share'
    return (
      <div
        className="dashboard-table-row"
        style={{ animation: `slideInUp 250ms ${animationDelay ? animationDelay : 0}ms` }}
      >
        <div className="dashboard-table-row-cell dashboard-table-cell-default" key="team_member">
          {info.team_member}
        </div>
        <div className="dashboard-table-row-cell dashboard-table-cell-default" key="stock_number">
          <img
            className={info.social_network_name == 'LinkedIn' ? 'iconLinkedin' : 'iconFacebook'}
            src={info.social_network_name == 'LinkedIn' ? iconLinkedin : iconFacebook}
          />
          <span>
            <b>{info.social_network_name}</b>
          </span>
        </div>
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default"
          key="vehicle"
          style={{ 'line-height': 16.5, 'padding-top': '3px' }}
        >
          <div
            className={`dashboard-table-sold-icon ${info.vehicle_status_id === 3 ? '' : 'hidden'}`}
          >
            <SoldIcon />
          </div>
          <Link to={`/packet/view/${info.vehicle_id}`}>
            {`${info.vehicle_year} ${info.vehicle_make}`}
            <br />
            {`${info.vehicle_model}`}
          </Link>
        </div>
        <div
          className="dashboard-table-row-cell dashboard-table-cell-default dashboard-table-cell-multi-row"
          key="sent_date"
        >
          <span style={{ fontSize: '0.9em' }}>{`${format(
            Helper.localizeTime(info.sent_date),
            'M/d/yyyy',
          )}`}</span>
          <br />
          <span style={{ fontSize: '0.95em' }}>{`${format(
            Helper.localizeTime(info.sent_date),
            'h:mm a',
          )}`}</span>
        </div>
        <div className="dashboard-table-row-cell dashboard-table-cell-default" key="views">
          {info.view_count}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  token: state.userSettings.settings && state.userSettings.settings.dj_token,
})
export default connect(mapStateToProps, {
  showModalConnect: showModal,
})(DashboardRowSocial)
