import React from 'react'

const DealershipIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 45">
      <path d="M95.83,38.61h-.15v-19A5.14,5.14,0,0,0,98,15.35a16.32,16.32,0,0,0-.5-3.1,16.11,16.11,0,0,0-.88-2.51H3.35A17.72,17.72,0,0,0,2.69,12a17.08,17.08,0,0,0-.4,3.35,5.13,5.13,0,0,0,1.94,4V38.61H4.05a1.76,1.76,0,1,0,0,3.52H95.83a1.76,1.76,0,1,0,0-3.52ZM34.55,18.81a5.09,5.09,0,0,0,7.77-.25,5.09,5.09,0,0,0,7.68.35,5.09,5.09,0,0,0,7.68-.35,5.08,5.08,0,0,0,7.91.08,5.08,5.08,0,0,0,7.91-.08,5.09,5.09,0,0,0,7.68.35,5.09,5.09,0,0,0,7.68-.35,5.1,5.1,0,0,0,4,1.93c.13,0,.26,0,.4,0V38.61H29.81V20.39a5,5,0,0,0,1,.1A5.1,5.1,0,0,0,34.55,18.81ZM22.8,20.49a5.12,5.12,0,0,0,4-1.93,4.85,4.85,0,0,0,.59.61V38.61H17.11V20.2a5.19,5.19,0,0,0,2-1.29A5.14,5.14,0,0,0,22.8,20.49ZM6.66,20.41a4.91,4.91,0,0,0,.78.08,5.1,5.1,0,0,0,4-1.93,5.12,5.12,0,0,0,4,1.93,4.92,4.92,0,0,0,.78-.07V38.61H6.66Z" />
      <rect x="3.35" y="2.77" width="93.26" height="5.38" />
      <rect x="19.76" y="25.61" width="1.9" height="6.12" rx="0.8" />
      <rect x="11.63" y="25.61" width="1.9" height="6.12" rx="0.8" />
      <path d="M73.6,36.81a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-.36H85v.36a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V35.33a2.64,2.64,0,0,0,.53-1.59V32a2.7,2.7,0,0,0-1.27-2.28v0c0-3.24-3.19-5.87-7.13-5.87S74,26.47,74,29.71c0,.09,0,.17,0,.26a2.7,2.7,0,0,0-1,2.06v1.71a2.64,2.64,0,0,0,.53,1.59Zm13.32-4.37a1.3,1.3,0,1,1-1.3-1.3A1.3,1.3,0,0,1,86.92,32.44Zm-5.6-6.84c2.9,0,5.25,1.53,5.25,3.42H76.07C76.07,27.13,78.42,25.6,81.32,25.6ZM77,31.14a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,77,31.14Z" />
      <path d="M53.86,36.81a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-.36h7.45v.36a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V35.33a2.64,2.64,0,0,0,.53-1.59V32a2.7,2.7,0,0,0-1.27-2.28v0c0-3.24-3.2-5.87-7.14-5.87s-7.14,2.63-7.14,5.87c0,.09,0,.17,0,.26a2.71,2.71,0,0,0-1,2.06v1.71a2.64,2.64,0,0,0,.53,1.59Zm13.33-4.37a1.3,1.3,0,1,1-1.3-1.3A1.3,1.3,0,0,1,67.19,32.44Zm-5.6-6.84c2.9,0,5.25,1.53,5.25,3.42H56.33C56.33,27.13,58.69,25.6,61.59,25.6Zm-4.31,5.54a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,57.28,31.14Z" />
      <path d="M34,36.81a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-.36h7.45v.36a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V35.33A2.64,2.64,0,0,0,50,33.74V32a2.71,2.71,0,0,0-1.28-2.28s0,0,0,0c0-3.24-3.2-5.87-7.14-5.87s-7.14,2.63-7.14,5.87a2.28,2.28,0,0,0,0,.26,2.71,2.71,0,0,0-1,2.06v1.71A2.64,2.64,0,0,0,34,35.33Zm13.33-4.37a1.31,1.31,0,1,1-1.3-1.3A1.3,1.3,0,0,1,47.34,32.44ZM41.73,25.6C44.63,25.6,47,27.13,47,29H36.48C36.48,27.13,38.83,25.6,41.73,25.6Zm-4.3,5.54a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,37.43,31.14Z" />
    </svg>
  )
}
export default DealershipIcon
