import React, { useState, useEffect } from 'react'
import PropTypes, { array } from 'prop-types'
// components
import ModuleRow from './modulerow'
// helpers
import actions from '../../../core/actions/users/adminactions'
// assets
import SearchPNG from '../../../core/assets/images/icons/search.png'
import CancelPNG from '../../../core/assets/images/icons/cancel_black.png'
import '../styles/modules.scss'

function Modules({ storeId, token }) {
  const [modules, setModules] = useState([])
  const [showEnabled, setShowEnabled] = useState(true)
  const [showDisabled, setShowDisabled] = useState(true)
  const [showMobile, setShowMobile] = useState(true)
  const [showCustom, setShowCustom] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [showFilterlist, setShowFilterList] = useState(false)
  // get the list of modules

  function onMountGetModules(storeId: number, token: string) {
    if (storeId) {
      actions.getModulesList(storeId, token).then(json => {
        console.log('the modules')
        console.log(json.results)
        if (json) {
          setModules(modules.concat(json.results))
          console.log(modules)
        }
      })
      actions.getCustomModulesList(storeId, token).then(json => {
        console.log('the custom modules')
        console.log(json)
        if (json) {
          setModules(modules.concat(json.results))
          console.log(modules)
        }
      })
    }
  }

  // when component mounts get the modules
  // re-render useEffect if storeId changes
  useEffect(() => {
    onMountGetModules(storeId, token)
  }, [storeId])

  function filterEnabled(mod) {
    return mod.active === showEnabled
  }
  function filterDisabled(mod) {
    return !mod.active === showDisabled
  }
  function filterMobile(mod) {
    return mod.mobileapp_active === showMobile
  }
  function filterSearch(mod) {
    if (searchTerm !== '') {
      return mod.module_id.label.toLowerCase().includes(searchTerm.toLowerCase())
    }
    return true
  }
  const filteredModules = modules.filter(
    singleModule =>
      (filterEnabled(singleModule) || filterDisabled(singleModule)) &&
      filterMobile(singleModule) &&
      filterSearch(singleModule),
  )

  const mappedModules = filteredModules.map(singleModule => (
    <ModuleRow
      key={singleModule.module_id.module_id}
      storeId={storeId}
      token={token}
      moduleId={singleModule.module_id.module_id}
      moduleName={singleModule.module_id.label}
      isActive={singleModule.active}
      description={singleModule.module_id.description}
      dateCreated={singleModule.date_created}
      userCreated={singleModule.user_created}
      dateModified={singleModule.date_modified}
      userModified={singleModule.user_modified}
      isMobileActive={singleModule.mobileapp_active}
      isMobilePreview={singleModule.mobileapp_preview}
    />
  ))
  console.log(mappedModules)

  return (
    <div className="modules-container">
      <span className="controller-container">
        <div className="module-search-container">
          <label
            style={{
              display: 'none',
            }}
            htmlFor="module-search-input"
          >
            Store and User Search
          </label>
          <input
            id="module-search-input"
            className="module-search-input"
            placeholder="Type here to begin your search"
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <div
            className="module-input-btn"
            onClick={() => {
              if (searchTerm !== '') {
                setSearchTerm('')
              }
            }}
          >
            <img
              alt="cancelOrSearchImage"
              className=""
              src={searchTerm !== '' ? CancelPNG : SearchPNG}
            />
          </div>
        </div>
        <button className="module-filter-button" onClick={() => setShowFilterList(true)}>
          Filters
        </button>
        {!showFilterlist ? (
          ''
        ) : (
          <div className="filter-container">
            <span className="filter-checkbox-container">
              <input
                id="showEnabled"
                onClick={() => setShowEnabled(!showEnabled)}
                checked={showEnabled}
                type="checkbox"
              />
              <label htmlFor="showEnabled">Enabled</label>
            </span>
            <span className="filter-checkbox-container">
              <input
                id="showDisabled"
                onClick={() => setShowDisabled(!showDisabled)}
                checked={showDisabled}
                type="checkbox"
              />
              <label htmlFor="showDisabled">Disabled</label>
            </span>
            <span className="filter-checkbox-container">
              <input
                id="showMobile"
                onClick={() => setShowMobile(!showMobile)}
                checked={showMobile}
                type="checkbox"
              />
              <label htmlFor="showMobile">Mobile</label>
            </span>
            <button className="module-filter-button" onClick={() => setShowFilterList(false)}>
              Close
            </button>
          </div>
        )}
      </span>
      {mappedModules}
    </div>
  )
}

export default Modules
Modules.propTypes = {
  storeId: PropTypes.string.isRequired,
  token: PropTypes.string,
}
