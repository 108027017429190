import React from 'react'

export default function PostSubmit({ companies }) {
  const renderCompanyNames = () => {
    return (
      <React.Fragment>
        {companies.map(company => {
          return (
            <p key={company.id}>
              <b>{company.name}</b>
            </p>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <h1>Thank You</h1>
      <div>
        Thank you for completing the form. You have now been successfully unsubscribed from:{' '}
        {renderCompanyNames()}You can close this window now.
      </div>
    </React.Fragment>
  )
}
