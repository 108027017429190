import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../styles/bulkuserupload.scss'
// import xlsxParser from 'xlsx-parse-json'
import XLSX from 'xlsx'

import FileDrop from 'react-file-drop'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { searchCompanyStores, changeCompanyStore } from '../../../core/actions/stafftoolactions'
import LoadingSpinner from '../../helpers/loadingspinner'
import RefreshIcon from '../../tools/assets/refreshicon.js'
import CheckmarkAnimation from '../../tools/assets/checkmarkanimation.js'
import AddFileIcon from '../../tools/assets/addfileicon.js'
import CancelIcon from '../../tools/assets/cancelicon.js'
import DuplicateIcon from '../../../core/assets/images/icons/duplicateicon.js'

import AdminActions from '../../../core/actions/users/adminactions'
import UserActions, { userChangePassword } from '../../../core/actions/users/useractions'
import TabNav from '../../reactadminapp/components/tabnav'

class BulkUserUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      docs: [],
      users: [],
      pendingCompanySearches: 0,
      dragging: true,
    }
  }
  updatePasswordField(docIndex, password) {
    console.log('CALLED')
    const { docs } = this.state
    docs[docIndex].password = password

    this.setState({ docs })
    if (password.length) {
      console.log('Calling Strength Check')
      UserActions.postPasswordStrengthDJAPI(password).then(res => {
        const { aip_standards } = res
        if (aip_standards) {
          docs[docIndex].passwordCompliance = aip_standards.aip_percentage
          docs[docIndex].passwordRequirements = aip_standards.aip_pw_rules
          docs[docIndex].passwordStrength = aip_standards.normalized_strength
          this.setState({ docs })
        }
      })
    }
  }
  selectFiles(fileList) {
    let { docs } = this.state
    _.map(fileList, file => {
      const fileExtenstion = file.name.split('.').pop()
      console.log()
      if (fileExtenstion === 'xlsx' || fileExtenstion === 'csv') {
        docs.push({
          submitted: false,
          file,
          password: '',
          confirmPassword: '',
          users: [],
          passwordCompliance: 0,
          passwordStrength: 0,
          passwordRequirements: [],
        })
      } else {
        this.mistake(`File "${file.name}" is not a spreadsheet or is improperly named`)
      }
      docs = [...new Set(docs)]
      this.setState({ docs }, () => {
        this.processSheet()
      })
    })
  }
  processSheet() {
    const { docs, users } = this.state
    docs.map(doc => {
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString
      reader.onload = e => {
        const wb = XLSX.read(e.target.result, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        console.log('DATA HERE')
        console.log(data, data[0].length)
        const users = []
        let unorthodoxSheet = 0
        if (data[0].length < 41) {
          unorthodoxSheet = 1
        }
        let i
        for (i = 1 + unorthodoxSheet; i < data.length; i++) {
          if (data[i].length) {
            const user = {}
            data[0 + unorthodoxSheet].map((name, n) => {
              user[name] = data[i][n] ? data[i][n] : ''
            })
            doc.users.push(user)
          }
        }
        console.log('USERS')
        console.log(users)
        this.setState({ users }, () => {
          // this.createUsers()
        })
      }
      if (rABS) reader.readAsBinaryString(doc.file)
      else reader.readAsArrayBuffer(doc.file)

      // xlsxParser.onFileSelection(doc).then(data => {
      //   users.push(...data['Registrants'])
      //   this.setState({ users }, () => {
      //     this.createUsers()
      //   })
      // })
    })
  }
  createUsers = doc => {
    const { token } = this.props
    const { users } = doc
    let uniqueStores = new Set()
    const uniqueStoreAssoc = {}
    console.log('Unique Stores Go Here')
    users.map(user => {
      user['Dealership name'] !== undefined && uniqueStores.add(user['Dealership name'])
    })
    uniqueStores = [...uniqueStores]
    console.log(uniqueStores)
    uniqueStores.map(store => {
      if (store && store.length) {
        this.setState(
          prevState => {
            return {
              pendingCompanySearches: prevState.pendingCompanySearches + 1,
            }
          },
          () => {
            console.log('CHECKING PENDING START')
            console.log(this.state)
          },
        )
        searchCompanyStores({ token, page: 1, search: store }).then(res => {
          if (res && res.count) {
            uniqueStoreAssoc[store] = res.results[0]
            for (let i = 0; i < res.count; i++) {
              if (res.results[i].store_type_id === 2) {
                uniqueStoreAssoc[store] = res.results[i]
                break
              }
            }
          }
          this.setState(
            prevState => {
              return {
                pendingCompanySearches: prevState.pendingCompanySearches - 1,
              }
            },
            () => {
              console.log('CHECKING PENDING')
              console.log(this.state)
              if (this.state.pendingCompanySearches === 0) {
                this.sendUsers(uniqueStoreAssoc, doc)
              }
            },
          )
        })
      }
    })
  }
  sendUsers(uniqueStoreAssoc, doc) {
    const { token } = this.props
    const { password, confirmPassword, users } = doc

    console.log('Sending Users with Unique Stores')
    console.log(uniqueStoreAssoc)

    users.map(user => {
      const role_id = user.Title.toLowerCase().includes('manager') ? 6 : 8
      // const teststring =
      //   Math.random()
      //     .toString(36)
      //     .substring(2, 15) +
      //   Math.random()
      //     .toString(36)
      //     .substring(2, 15)

      if (
        uniqueStoreAssoc[user['Dealership name']] &&
        user['Is team member'] !== 'true' &&
        user['Is team member'] !== true &&
        user['Is guest speaker'] !== 'true' &&
        user['Is guest speaker'] !== true
      ) {
        user.userData = {
          first_name: user['First name'],
          last_name: user['Last name'],
          default_store_id: uniqueStoreAssoc[user['Dealership name']].company_store_id,
          crm_email: '',
          user_email_address: user.Email,
          role_id,
          user_phone: '',
          user_mobile: user['Mobile phone number'],
          send_email_alerts: true,
          send_mobile_alerts: true,
          view_frequency: 'all',
          send_email: false,
          temp_password: password,
        }
        user.attemptedSubmit = true
        user.pendingSubmit = true
        this.setState({ users })
        AdminActions.putAdminMember(user.userData, token).then(res => {
          // console.log(user['Full name'])
          // console.log(res)
          if (res && res.legacy_user_id) {
            console.log('AUTO ADD RESPONSES')
            console.log(res)
            user.successfulSubmit = true
            user.pendingSubmit = false
            user.returnedUser = res
            this.setState({ users })
            // userChangePassword(password, confirmPassword, token, res.legacy_user_id, false).then(
            //   res => {
            //     console.log(res)
            //   },
            // )
          } else if (res && res.non_field_errors) {
            console.log('Has Non Field Errors')
            user.isDuplicate = true
            user.successfulSubmit = false
            user.pendingSubmit = false
          } else {
            user.successfulSubmit = false
            user.pendingSubmit = false
            this.setState({ users })
          }
        })
      } else {
        let message = ''
        if (!uniqueStoreAssoc[user['Dealership name']]) {
          message += '| Dealership Not Found |'
        }
        if (!(user['Is team member'] !== 'true' && user['Is team member'] !== true)) {
          message += '| Is Team Member |'
        }
        if (!(user['Is guest speaker'] !== 'true' && user['Is guest speaker'] !== true)) {
          message += '| Is Guest Speaker |'
        }
        user.message = message
        user.attemptedSubmit = true
        user.pendingSubmit = false
        user.successfulSubmit = false
        this.setState({ users })
      }
    })
    console.log('FINISHED MAGIC HERE')
    console.log(users)
  }
  mistake = message => {
    if (!toast.isActive(this.mistakeToastId) || this.mistakeToastMessage !== message) {
      this.mistakeToastId = toast(message)
      this.mistakeToastMessage = message
    }
  }
  handleFileDrop = (files, event) => {
    this.selectFiles(files)
  }
  handleDragEnter = event => {
    this.setState({ dragging: true })
  }
  handleDragLeave = event => {
    console.log({ dragging: false })
  }
  renderStatus(user) {
    if (user.attemptedSubmit && !user.pendingSubmit && !user.successfulSubmit && user.isDuplicate) {
      return <DuplicateIcon title="Duplicated User" />
    } else if (user.attemptedSubmit && !user.pendingSubmit && !user.successfulSubmit) {
      return <CancelIcon title="Failed" color="red" />
    } else if (user.attemptedSubmit && !user.pendingSubmit && user.successfulSubmit) {
      return <CheckmarkAnimation />
    } else if (!user.attemptedSubmit && !user.pendingSubmit && !user.successfulSubmit) {
      return <React.Fragment />
    }
    return <LoadingSpinner loading={true} />
  }
  renderPasswordComplianceInfo(doc) {
    const {
      password,
      confirmPassword,
      passwordCompliance,
      passwordStrength,
      passwordRequirements,
      passwordVisible,
    } = doc
    let passwordComplianceText = ''
    let passwordComplianceClass = ''
    if (passwordCompliance <= 33) {
      passwordComplianceText = 'Poor'
      passwordComplianceClass = 'password-compliance-poor'
    } else if (passwordCompliance > 33 && passwordCompliance <= 66) {
      passwordComplianceText = 'Good'
      passwordComplianceClass = 'password-compliance-good'
    } else if (passwordCompliance > 66 && passwordCompliance <= 99) {
      passwordComplianceText = 'Great'
      passwordComplianceClass = 'password-compliance-great'
    } else if (passwordCompliance >= 100) {
      passwordComplianceText = 'Perfect'
      passwordComplianceClass = 'password-compliance-perfect'
    }
    if (password.length) {
      return (
        <div className="bulk-user-upload-doc-queue-item-password-compliance">
          <div className={`${password.length === 0 ? ' hidden' : ''}`}>
            <div className="accountSettings-password-compliance-grade-wrapper">
              <label>{passwordComplianceText}</label>
              <div
                className={`accountSettings-password-compliance-grade ${passwordComplianceClass}`}
              />
            </div>
            <br />
            <div className="accountSettings-password-tips">
              <ul>
                {passwordRequirements.map(rq => (
                  <li>{rq}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )
    }
  }
  renderDocQueue = () => {
    const { docs } = this.state
    if (docs.length) {
      return (
        <div className="bulk-user-upload-doc-queue">
          {docs.map((doc, i) => {
            return (
              <div className="bulk-user-upload-doc-queue-item">
                <div className="bulk-user-upload-doc-queue-item-name">{doc.file.name}</div>

                {!doc.submitted ? (
                  <React.Fragment>
                    <div className="bulk-user-upload-doc-queue-item-guide">
                      * Leave password blank if you do not want to manually set password for new
                      accounts
                    </div>

                    <div className="bulk-user-upload-doc-queue-item-password">
                      <input
                        placeholder={'Password'}
                        onChange={e => {
                          this.updatePasswordField(i, e.target.value)
                        }}
                        value={doc.password}
                      />
                      <input
                        placeholder={'Confirm Password'}
                        onChange={e => {
                          docs[i].confirmPassword = e.target.value
                          this.setState(docs)
                        }}
                        value={doc.confirmPassword}
                      />
                    </div>
                    <div className="bulk-user-upload-doc-queue-item-submit">
                      <button
                        onClick={() => {
                          docs[i].submitted = true
                          this.setState({ docs }, () => {
                            this.createUsers(doc)
                          })
                        }}
                        disabled={
                          doc.password.length > 0 &&
                          (doc.password !== doc.confirmPassword || doc.passwordCompliance < 100)
                        }
                      >
                        Submit
                      </button>
                    </div>
                    {this.renderPasswordComplianceInfo(doc)}
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
                {this.renderQueue(doc.users)}
              </div>
            )
          })}
        </div>
      )
    }
  }
  renderQueue = users => {
    if (users && users.length) {
      console.log('USERS HERE', users)
      return (
        <div className="bulk-user-upload-queue">
          <div className="bulk-user-upload-queue-header bulk-user-upload-queue-item">
            <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-name">
              Full Name
            </div>
            <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-email">
              Email
            </div>
            <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-mobile">
              Mobile Number
            </div>
            <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-store">
              Store Name
            </div>
            <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-status">
              Status
            </div>
          </div>
          {users.map(user => {
            if (true) {
              return (
                <div className="bulk-user-upload-queue-item">
                  <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-name">
                    {user.returnedUser ? (
                      <span>{`${user['First name']} ${user['Last name']}`}</span>
                    ) : (
                      <React.Fragment>{`${user['First name']} ${user['Last name']}`}</React.Fragment>
                    )}
                  </div>
                  <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-email">
                    {user.Email}
                  </div>
                  <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-mobile">
                    {user['Mobile phone number']}
                  </div>
                  <div className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-store">
                    {user['Dealership name']}
                  </div>
                  <div
                    title={user.message ? user.message : ''}
                    className="bulk-user-upload-queue-item-field bulk-user-upload-queue-item-status"
                  >
                    {this.renderStatus(user)}
                  </div>
                </div>
              )
            }
          })}
        </div>
      )
    }
  }
  renderDropZone = () => {
    const { users } = this.state
    return (
      <FileDrop
        onDrop={this.handleFileDrop}
        onFrameDragEnter={this.handleDragEnter}
        onFrameDragLeave={this.handleDragLeave}
        onFrameDragDrop={this.handleDragLeave}
      >
        <div className="bulk-user-upload">
          <div className="bulk-user-upload-button" onClick={() => this.docDialog.click()}>
            <input
              className="doc-upload-input"
              type="file"
              accept=".xlsx,.csv"
              multiple
              ref={ref => (this.docDialog = ref)}
              onChange={e => {
                this.handleFileDrop(e.target.files)
              }}
            />
            <AddFileIcon color="rgba(0,0,0,.5)" />
            <br />
            Click Here or Drag File In
          </div>
        </div>
      </FileDrop>
    )
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <TabNav />
          <div className="report_box">
            <div className="staff-tools">
              <div className="staff-tools-sub-page">
                <h1>Bulk User Upload</h1>
                <h2>Please Jump to the Company associated with the sheet before using this tool</h2>
                {this.renderDropZone()}
                {this.renderDocQueue()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  token: state.authentication.user.token,
})
export default connect(mapStateToProps, null)(BulkUserUpload)
