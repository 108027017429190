import {
  DJangoCustomerCompanyList,
  DJangoCustomerUnsubscribe,
} from '../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../config/aipDjapi'

import {
  ADD_ALL,
  ADD_SELECTED_COMPANIES,
  REMOVE_ALL,
  REMOVE_SELECTED_COMPANIES,
} from '../_constants/unsubscribeConstants'

function addSelectedCompany(selectedCompany) {
  return function(dispatch) {
    dispatch({
      type: ADD_SELECTED_COMPANIES,
      selectedCompany,
    })
  }
}

function removeSelectedCompany(selectedCompany) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_SELECTED_COMPANIES,
      selectedCompany,
    })
  }
}

function addAllCompanies(selectedCompanies) {
  return function(dispatch) {
    dispatch({
      type: ADD_ALL,
      selectedCompanies,
    })
  }
}

function removeAllCompanies() {
  return function(dispatch) {
    dispatch({
      type: REMOVE_ALL,
    })
  }
}

function getCustomerCompanyList(jwt_string: string) {
  // v1/customers/company-list/:jwt
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoCustomerCompanyList}${jwt_string}/`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

function unsubscribeCustomer(jwt_string: string, companies: Array) {
  // v1/customers/unsubscribe/:jwt
  let params = {
    company_ids: companies,
  }
  return fetch(`${DJangoCustomerUnsubscribe}${jwt_string}/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  }).then(response => {
    return response.json()
  })
}

export {
  addSelectedCompany,
  removeSelectedCompany,
  addAllCompanies,
  removeAllCompanies,
  getCustomerCompanyList,
  unsubscribeCustomer,
}
