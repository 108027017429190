import React, { Component } from 'react'
import moment from 'moment-timezone'
import { StickerPullCoverageGraphSection } from '../../../../modals/sticker_pull_modals/stickerpullcoveragemodal'
import '../../../../modals/styles/stickerpullcoveragemodal.scss'

class StickerPullCoverageGraphPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.StickerPullCoverageGraph = this.StickerPullCoverageGraph.bind(this)
    this.getGraphRangeStyle = this.getGraphRangeStyle.bind(this)
    this.getGraphRangeTitle = this.getGraphRangeTitle.bind(this)
  }
  getGraphRangeStyle(coverage_type) {
    switch (coverage_type) {
      case 'high_coverage':
        return 'sticker-pull-coverage-table-graph-range-high-coverage'
        break
      case 'medium_coverage':
        return 'sticker-pull-coverage-table-graph-range-medium-coverage'
        break
      case 'low_coverage':
        return 'sticker-pull-coverage-table-graph-range-low-coverage'
        break
      case 'no_coverage':
        return 'sticker-pull-coverage-table-graph-range-no-coverage'
        break
    }
  }

  getGraphRangeTitle(coverage_type) {
    switch (coverage_type) {
      case 'high_coverage':
        return 'High Coverage'
        break
      case 'medium_coverage':
        return 'Partial Coverage'
        break
      case 'low_coverage':
        return 'Low Coverage'
        break
      case 'no_coverage':
        return 'No Coverage'
        break
    }
  }

  StickerPullCoverageGraph(props) {
    const make_coverage = props
    const start_year = make_coverage.coverage[0].year_start
    const end_year = make_coverage.coverage[make_coverage.coverage.length - 1].year_end
      ? make_coverage.coverage[make_coverage.coverage.length - 1].year_end
      : new Date().getFullYear()
    const num_years = end_year - start_year + 1
    let coverage_graph_ranges = []

    make_coverage.coverage.map((range_info, range_info_index) => {
      let coverage_graph_sections = []
      for (
        let i = range_info.year_start;
        i < (range_info.year_end ? range_info.year_end : end_year) + 1;
        i++
      ) {
        coverage_graph_sections.push(
          <StickerPullCoverageGraphSection
            key={i}
            index={i}
            range_start={range_info.year_start}
            range_end={range_info.year_end ? range_info.year_end : end_year}
          />,
        )
      }
      let dateRange = range_info.year_end ? range_info.year_end : end_year
      if (dateRange !== range_info.year_start) {
        dateRange = `${range_info.year_start} - ${
          range_info.year_end ? range_info.year_end : end_year
        }`
      }
      let rangePositionStyle = ''
      if (range_info_index === 0) {
        rangePositionStyle += ' sticker-pull-coverage-graph-range-first'
      }
      if (range_info_index === make_coverage.coverage.length - 1) {
        rangePositionStyle += ' sticker-pull-coverage-graph-range-last'
      }
      coverage_graph_ranges.push(
        <div
          className={`sticker-pull-coverage-table-graph-range ${this.getGraphRangeStyle(
            range_info.type,
          )} ${rangePositionStyle}`}
          style={{
            flexGrow: `${(range_info.year_end ? range_info.year_end : end_year) -
              range_info.year_start +
              1}`,
          }}
        >
          {coverage_graph_sections}
          <div className="sticker-pull-coverage-table-graph-range-display">
            {dateRange}
            <div className="sticker-pull-coverage-table-graph-range-display-title">
              {this.getGraphRangeTitle(range_info.type)}
            </div>
          </div>
        </div>,
      )
    })

    return (
      <div className="sticker-pull-coverage-table-graph-container">
        <div className="sticker-pull-coverage-table-graph-title">{make_coverage.make}</div>
        <div className="sticker-pull-coverage-table-graph">{coverage_graph_ranges}</div>
      </div>
    )
  }
  StickerPullCoverageGraphKey() {
    return (
      <div className="sticker-pull-coverage-table-graph-key-container">
        <div className="sticker-pull-coverage-table-graph-key">
          <div className="sticker-pull-coverage-table-graph-key-example sticker-pull-coverage-table-graph-range-high-coverage" />
          <div className="sticker-pull-coverage-table-graph-key-title">High Coverage</div>
        </div>
        <div className="sticker-pull-coverage-table-graph-key">
          <div className="sticker-pull-coverage-table-graph-key-example sticker-pull-coverage-table-graph-range-medium-coverage" />
          <div className="sticker-pull-coverage-table-graph-key-title">Partial Coverage</div>
        </div>
        <div className="sticker-pull-coverage-table-graph-key">
          <div className="sticker-pull-coverage-table-graph-key-example sticker-pull-coverage-table-graph-range-low-coverage" />
          <div className="sticker-pull-coverage-table-graph-key-title">Low Coverage</div>
        </div>
      </div>
    )
  }
  render() {
    const { coverage } = this.props
    const date = moment().format('MMMM Do YYYY, h:mm a')
    return (
      <div className="PrintGraph">
        <div className="sticker-pull-coverage-table">
          <div className="sticker-pull-coverage-table-title">Sticker Coverage</div>
          <p className="sticker-pull-coverage-table-disclaimer">
            Sticker Pull Coverage can change over time. This coverage is for
          </p>
          <p>{date}</p>
          {this.StickerPullCoverageGraphKey()}
          <div className="sticker-pull-coverage-table">
            {coverage.map(make => this.StickerPullCoverageGraph(make))}
          </div>
        </div>
      </div>
    )
  }
}

export default StickerPullCoverageGraphPrint
