// General : Left navigation section for overview / audit

// node modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Cropper from 'react-cropper'
// components
import Helper from '../../../core/helpers/platformhelper'
import { StoreLogoChange } from './logouploadingcomponents'
// data
import actions from '../../../core/actions/users/adminactions'
import { getShareTypes } from '../../../core/actions/sendpacketactions'
// icons
import FacebookIcon from '../../../core/assets/images/icons/facebook-icon'
import LinkedInIcon from '../../../core/assets/images/icons/linkedin-icon'
import EyeOpenIcon from '../../../core/assets/images/icons/eye-open_icon'
import IconMobile from '../../../core/assets/images/icons/icon-mobile'
import ContentCopy from '../../../core/assets/images/icons/content_copy'
// styles
import '../styles/stickySideBar.scss'

let fileConstExists = true
export class SideBar extends Component {
  constructor(props) {
    super(props)
    const URLStoreId = window.location.href.split('overview/')[1]
    let storeIdToUse
    if (URLStoreId !== undefined) {
      storeIdToUse = URLStoreId
    } else {
      storeIdToUse = this.props.storeId
    }
    this.state = {
      storeId: storeIdToUse,
      marketName: null,
      aRep: null,
      storePhone: null,
      storeEmail: null,
      storeFax: null,
      features: null,
      storeInsights: null,
      // Logo cropping
      previousImg: null,
      img: null,
      imageToUpload: null,
      logoModified: false,
      isCropping: false,
      // Account Manager
      accManagerEmail: null,
      accManagerPhone: null,
      accManagerMobile: null,
      // section loaded Data
      sections1LoadedData: null,
      sections2LoadedData: null,
      sections3LoadedData: null,
    }
    this.imageUpdater = this.imageUpdater.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
  }

  componentDidMount() {
    // check for file constructor
    try {
      new File(['test'], 'test.txt', {
        type: 'text/plain',
      })
    } catch (e) {
      fileConstExists = false
    }
    // get store logo
    this.onMountGetStoreLogo(this.state.storeId),
      // get region section info
      this.onMountGetRegionInfo(this.state.storeId),
      // get store detail info
      this.onMountGetStoreDetail(this.state.storeId),
      // get features section info
      this.onMountGetFeatures(this.state.storeId)
    // get account manager info
    this.onMountGetAccountManager(this.state.storeId)
  }

  componentWillUnmount() {
    this.setState({
      storeId: null,
      img: null,
      marketName: null,
      aRep: null,
      storePhone: null,
      storeEmail: null,
      storeFax: null,
      features: null,
      storeInsights: null,
      sections1LoadedData: null,
      sections2LoadedData: null,
      sections3LoadedData: null,
      accManagerEmail: null,
      accManagerPhone: null,
      accManagerMobile: null,
    })
  }
  // on mount, if store id exists from redux get the features data and set depending state
  onMountGetFeatures(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      getShareTypes({
        token: DJtoken,
        store_id: storeId,
      }).then(json => {
        console.log(`Features: ${JSON.stringify(json)}`)
        this.setState({ features: json, sections1LoadedData: json })
      })
    }
  }
  // on mount, if store id exists from redux get the region data and set depending state
  onMountGetRegionInfo(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      actions.getAdminOverviewRegionInfo(storeId, DJtoken).then(json => {
        console.log(`Admin Region: ${JSON.stringify(json)}`)
        this.setState({ sections2LoadedData: json })
        if (json[0].market_name !== null && json[0].market_name !== undefined) {
          this.setState({
            marketName: json[0].market_name,
            aRep: json[0].account_rep,
          })
        }
      })
    }
  }

  // on mount, if store id exists from redux get the store deatail data and set depending state
  onMountGetStoreDetail(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      actions.getStoreDetail(storeId, DJtoken).then(json => {
        console.log(`Store Detail: ${JSON.stringify(json)}`)
        this.setState({
          storePhone: json.store_phone,
          storeEmail: json.store_email_address,
          storeFax: json.store_fax,
          storeInsights: json.has_insights,
          sections3LoadedData: json,
        })
      })
    }
  }

  // onMountGetStoreLogo
  // on mount, if store id exists from redux get the store logo and set depending state with either the url or default image if there's an issue
  onMountGetStoreLogo(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      actions.getAdminOverviewImg(storeId, DJtoken).then(json => {
        if (
          json.store_logo_url !== null &&
          json.store_logo_url !== undefined &&
          json.store_logo_url !== ''
        ) {
          this.setState({ img: json.store_logo_url })
        } else {
          this.setState({ img: require('../../../core/assets/images/noStoreLogo.png') })
        }
      })
    } else {
      this.setState({ img: require('../../../core/assets/images/noStoreLogo.png') })
    }
  }

  onMountGetAccountManager(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      actions.getAccountManagerInfo(storeId, DJtoken).then(json => {
        console.log(`Account Manager: ${JSON.stringify(json)}`)
        this.setState({
          // info
          accManagerEmail: json.user_email_address,
          accManagerPhone: json.user_phone,
          accManagerMobile: json.user_mobile,
        })
      })
    }
  }
  // on mount, change logo pic to default if there's an issue with url
  addDefaultSrc(pic) {
    pic.target.src = require('../../../core/assets/images/noStoreLogo.png')
  }
  // File uploading
  imageUpdater() {
    this.refs.avatarUploader.click()
  }
  fileChangedHandler = event => {
    this.setState({
      previousImg: this.state.img,
      img: URL.createObjectURL(event.target.files[0]),
      imageToUpload: event.target.files[0],
      logoModified: true,
      isCropping: true,
    })
  }
  // upload the image on save button click - if successful show a notifcation and reload page, if not console log the error and notify the user of error
  uploadImage(storeId: number, newPic: Object) {
    const { imageToUpload } = this.state
    let logoFile = null
    if (this.state.logoModified) {
      const logoFileName = 'None.png'
      // Create File with blob so API can accept return
      // Internet Explorer is the Scourge of the Earth
      // Stolen from avatar changer -- Victor
      try {
        logoFile = new File([newPic], logoFileName, {
          type: newPic.type,
          lastModified: Date.now(),
        })
        console.log(logoFile)
      } catch (e) {
        logoFile = imageToUpload
      }
    }
    console.log(logoFile)
    const { DJtoken } = this.props
    console.log('new image'),
      actions
        .PutLogoImage(this.state.storeId, DJtoken, logoFile)
        .then(res => {
          console.log(res)
          // notification
          toast('Profile image updated!')
          // set the image state to be the uploaded pic, set templogo state to null in to get standard component view (not confirmation)
          this.setState({
            img: URL.createObjectURL(logoFile),
            isCropping: false,
            logoModified: false,
          })
          // reload page to ensure new pic comes from server
          window.location.reload(true)
        })
        .catch(error => {
          console.log(error)
          // notify user of error
          toast('An Error has occured!')
        })
  }

  // Render logo section
  renderLogoSection() {
    const tempLogo = this.state.tempLogo
    // If the upload button hasn't been clicked, show the stored picture and upload pic button , if it has  (therefore setting templogo state to true)
    // go to the else
    if (!tempLogo) {
      return (
        <React.Fragment>
          {/* Show the state image, if it breaks show default image */}
          {this.state.isCropping ? (
            <div className={`logo-cropper ${fileConstExists ? '' : 'hidden'}`}>
              <Cropper
                ref="cropper"
                src={this.state.img}
                style={{ height: 180, width: '80%', margin: '0 auto' }}
                viewMode={1}
                aspectRatio={1}
                guides={true}
                modal={false}
              />
            </div>
          ) : (
            <div className="logo-container">
              <img
                onError={this.addDefaultSrc}
                alt="Store logo"
                className="logo-image"
                src={this.state.img}
              />
            </div>
          )}

          {!this.state.isCropping ? (
            <button className="logo-button" type="button" onClick={this.imageUpdater}>
              Change Store Logo +
            </button>
          ) : (
            <div className="logo-option-button-container">
              <button
                className="options-button"
                onClick={() => {
                  this.setState({
                    logoModified: false,
                    img: this.state.previousImg,
                    imageToUpload: null,
                    previousImg: null,
                    isCropping: false,
                  })
                }}
              >
                Cancel
              </button>
              <button
                className="options-button"
                type="button"
                onClick={() => {
                  this.refs.cropper.getCroppedCanvas().toBlob(blob => {
                    this.uploadImage(this.state.storeId, blob)
                  })
                }}
              >
                Save
              </button>
            </div>
          )}
          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            ref="avatarUploader"
            onChange={this.fileChangedHandler}
            onClick={event => {
              event.target.value = null
            }}
            style={{ display: 'none' }}
          />
        </React.Fragment>
      )
    }
  }

  // render store contact pic
  renderStoreContactSection() {
    return (
      <div className="sidebar-container store-contact">
        <h2>Store Contact</h2>
        <div className="sidebar-text-container">
          {this.state.storePhone !== null ? (
            <p>
              <b>VDP Phone:</b>{' '}
              {this.state.storePhone
                ? Helper.prettyPhoneNumber(this.state.storePhone)
                : 'Not Listed'}{' '}
            </p>
          ) : (
            ''
          )}
          {this.state.storeEmail !== null ? (
            <p>
              <b>VDP Email:</b> {this.state.storeEmail ? this.state.storeEmail : 'Not Listed'}
            </p>
          ) : (
            ''
          )}
          {this.state.storeFax !== null ? (
            <p>
              <b>Fax:</b>{' '}
              {this.state.storeFax ? Helper.prettyPhoneNumber(this.state.storeFax) : 'Not Listed'}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  handleCopy(info) {
    navigator.clipboard
      .writeText(info)
      .then(() => toast(`Copied ${info}`))
      .catch(err => {
        console.log('something went wong', err)
      })
  }
  // render store region section
  renderStoreRegionSection() {
    return (
      <div className="sidebar-container store-region">
        <h2>Store Region</h2>
        <div className="sidebar-text-container">
          <p className="sidebar-market">
            <b>Market:</b> {this.state.marketName ? this.state.marketName : 'Not Listed'}
          </p>
          <p>
            <b>Rep:</b> {this.state.aRep ? this.state.aRep : 'Not Listed'}
          </p>
          <div className="rep-contact">
            {this.state.accManagerEmail ? (
              <p>
                <b>Email: </b> {this.state.accManagerEmail}
                <button onClick={() => this.handleCopy(this.state.accManagerEmail)}>
                  <ContentCopy />
                  Copy
                </button>
              </p>
            ) : (
              ''
            )}
            {this.state.accManagerPhone ? (
              <p>
                <b>Phone: </b> {Helper.prettyPhoneNumber(this.state.accManagerPhone)}
                <br></br>
                <button onClick={() => this.handleCopy(this.state.accManagerPhone)}>
                  <ContentCopy />
                  Copy
                </button>
              </p>
            ) : (
              ''
            )}
            {this.state.accManagerMobile ? (
              <p>
                <b>Mobile: </b> {Helper.prettyPhoneNumber(this.state.accManagerMobile)}
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    )
  }

  // render features section
  // build has / doesn't have array lists for features and icons depending on existance vs null, then display them both with inline-flex
  renderFeaturesSection() {
    const featuresEnabled = []
    const iconsEnabled = []

    const featuresDisabled = []
    const iconsDisabled = []
    if (this.state.features !== null && this.state.features !== undefined) {
      if (this.state.storeInsights === true) {
        featuresEnabled.push('AutoiPacket Insights')
        iconsEnabled.push(<EyeOpenIcon />)
      } else {
        featuresDisabled.push('AutoiPacket Insights')
        iconsDisabled.push(<EyeOpenIcon />)
      }
      if (this.state.features.facebook_enabled === true) {
        featuresEnabled.push('Facebook Share')
        iconsEnabled.push(<FacebookIcon />)
      } else {
        featuresDisabled.push('Facebook Share')
        iconsDisabled.push(<FacebookIcon />)
      }
      if (this.state.features.linked_in_enabled === true) {
        featuresEnabled.push('LinkedIn Share')
        iconsEnabled.push(<LinkedInIcon />)
      } else {
        featuresDisabled.push('LinkedIn Share')
        iconsDisabled.push(<LinkedInIcon />)
      }
      if (this.state.features.sms_share_enabled === true) {
        featuresEnabled.push('SMS Share')
        iconsEnabled.push(<IconMobile />)
      } else {
        featuresDisabled.push('SMS Share')
        iconsDisabled.push(<IconMobile />)
      }
    }

    const finalFeaturesEnabled = featuresEnabled.map(feature => (
      <p className="features">{feature}</p>
    ))
    const finalIconsEnabled = iconsEnabled.map(icon => <div className="featuresIcon">{icon}</div>)

    const finalFeaturesDisabled = featuresDisabled.map(feature => (
      <p className="features">{feature}</p>
    ))
    const finalIconsDisabled = iconsDisabled.map(icon => <div className="featuresIcon">{icon}</div>)
    return (
      <div className="sidebar-container store-features">
        <h2>Features</h2>
        <div className="features-container-container">
          {featuresEnabled.length > 0 ? (
            <div className="left-features">
              <h4>Enabled</h4>
              <div className="features-container">
                <span className="features-icons">{finalIconsEnabled}</span>
                <span className="features-names">{finalFeaturesEnabled}</span>
              </div>
            </div>
          ) : (
            ''
          )}
          {featuresDisabled.length > 0 ? (
            <div className="right-features">
              <h4>Disabled</h4>
              <div className="features-container">
                <span className="features-icons">{finalIconsDisabled}</span>
                <span className="features-names">{finalFeaturesDisabled}</span>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  // final render
  render() {
    if (
      this.state.sections1LoadedData &&
      this.state.sections2LoadedData &&
      this.state.sections3LoadedData
    ) {
      this.props.checkSideBar({
        Data1: this.state.sections1LoadedData,
        Data2: this.state.sections2LoadedData,
        Data3: this.state.sections3LoadedData,
      })
    }
    return (
      <React.Fragment>
        <div className="overview-logo-section">
          {this.state.storeId && this.props.DJtoken ? (
            <StoreLogoChange storeId={this.state.storeId} token={this.props.DJtoken} />
          ) : (
            ''
          )}
          {/* {this.renderLogoSection()} */}
        </div>
        <div className="overview-infosidebar-section">
          {this.renderStoreContactSection()}
          {this.renderStoreRegionSection()}
          {this.renderFeaturesSection()}
          <div className="sidebar-container sidebar-buttons">
            <button className="print-button" onClick={window.print}>
              Print / Create PDF
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// get storeID and DJtoken from redux store for api calls
const mapStateToProps = state => ({
  DJtoken: state.authentication.user.token,
})
export default connect(mapStateToProps, {})(SideBar)
