import React, { useState, useEffect } from 'react'
// date-fns
import sub from 'date-fns/sub'
import format from 'date-fns/format'
// helpers
import actions from '../../../core/actions/users/adminactions'
import PropTypes from 'prop-types'
// redux
import { useSelector } from 'react-redux'
// components
import TimeSwitcher from './timeswitcher'

function StoreHealth({ storeId, token, graphLoaded, onHealthLoaded }) {
  const title = useSelector(state => state.reactAdmin.title)
  const interval = useSelector(state => state.reactAdmin.interval)
  const [storePacketsSent, setStorePacketsSent] = useState('-')
  const [storePacketsViewed, setStorePacketsViewed] = useState('-')
  const [storePCPacketsSent, setStorePCPacketsSent] = useState('-')
  const [storePCPacketsViewed, setStorePCPacketsViewed] = useState('-')
  const [storeUniqueRecipients, setStoreUniqueRecipients] = useState('-')
  const [storeUniqueSenders, setStoreUniqueSenders] = useState('-')
  const [healthLoaded, setHealthLoaded] = useState(false)

  /**
   * Retrives the startdate formated based on redux title state
   */
  function formatByTitle() {
    let startDate = new Date()
    switch (title) {
      case 'Last Week':
        startDate = sub(startDate, { weeks: 1 })
        break
      case 'Last Month':
        startDate = sub(startDate, { months: 1 })
        break
      default:
      case 'Last Year':
        startDate = sub(startDate, { years: 1 })
        break
    }
    return format(startDate, 'yyyy-MM-dd')
  }
  useEffect(() => {
    setHealthLoaded(false)
    onHealthLoaded(healthLoaded)
    if (title === 'All Time' && storeId !== null && storeId !== undefined) {
      actions.getStoreHealth(storeId, token).then(json => {
        console.log(`Store Health: ${JSON.stringify(json)}`)
        setStorePacketsSent(json.packets_sent)
        setStorePacketsViewed(json.packets_viewed)
        setStoreUniqueRecipients(json.unique_recipients)
        setStoreUniqueSenders(json.unique_senders)
        setStorePCPacketsSent(json.pc_packets_sent)
        setStorePCPacketsViewed(json.pc_packets_viewed)
        setHealthLoaded(true)
        onHealthLoaded(true)
      })
    } else if (storeId !== null && storeId !== undefined) {
      let startDate
      let endDate
      if (title === 'Custom') {
        startDate = format(interval.startDate, 'yyyy-MM-dd')
        endDate = format(interval.endDate, 'yyyy-MM-dd')
      } else {
        startDate = formatByTitle()
        endDate = format(new Date(), 'yyyy-MM-dd')
      }
      actions.getStoreHealth(storeId, token, startDate, endDate).then(json => {
        console.log(`Store Health: ${JSON.stringify(json)}`)
        setStorePacketsSent(json.packets_sent)
        setStorePacketsViewed(json.packets_viewed)
        setStoreUniqueRecipients(json.unique_recipients)
        setStoreUniqueSenders(json.unique_senders)
        setStorePCPacketsSent(json.pc_packets_sent)
        setStorePCPacketsViewed(json.pc_packets_viewed)
        setHealthLoaded(true)
        onHealthLoaded(true)
      })
    }
  }, [title, interval])

  return (
    <div className="health-container">
      <TimeSwitcher isLoading={!graphLoaded || !healthLoaded} />
      <div className="health-inner-container">
        <div className="health-value">
          <h3>{storePacketsSent}</h3>
          <p>Packets Sent</p>
        </div>
        <div className="health-value">
          <h3>{storePacketsViewed}</h3>
          <p>Packets Viewed</p>
        </div>
        <div className="health-value">
          <h3>{storePCPacketsSent}</h3>
          <p>PC Packets Sent</p>
        </div>
        <div className="health-value">
          <h3>{storePCPacketsViewed}</h3>
          <p>PC Packets Viewed</p>
        </div>
        <div className="health-value">
          <h3>{storeUniqueRecipients}</h3>
          <p>Unique Recipients</p>
        </div>
        <div className="health-value">
          <h3>{storeUniqueSenders}</h3>
          <p>Unique Senders</p>
        </div>
      </div>
    </div>
  )
}
export default StoreHealth
StoreHealth.propTypes = {
  token: PropTypes.string,
  onHealthLoaded: PropTypes.func,
  graphLoaded: PropTypes.bool,
}
