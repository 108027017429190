// general:

// @flow
import React, { Component } from 'react'
// node packages
import { NavLink } from 'react-router-dom'
// --- redux
import { connect } from 'react-redux'
import { EmptyPacketViews } from '../../../core/_actions/emptystoreActions'
// components children
import PacketModules from './packetleftmodules'
// helpers
import { activeNormalButtonStyle } from '../../helpers/reusables'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/packetsend.scss'

// global(s)
// --- styles
const activeNormalStyle = activeNormalButtonStyle

type Props = {
  vehicleId: number,
  view_type: string,
}

class PacketLeft extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      vehicleId: this.props.vehicleId,
      view_type: this.props.view_type,
    }
  }

  state: {
    vehicleId: number,
    view_type: string,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({ vehicleId: newProps.vehicleId, view_type: newProps.view_type })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ vehicleId: null, view_type: 'general_info' })
  }

  props: Props

  render() {
    // states
    const { vehicleId } = this.state
    const { view_type } = this.props

    if (vehicleId !== null && vehicleId !== undefined) {
      return (
        <div className="packetSendSideContainersStyle packetLeftSideStyle">
          <NavLink
            id="general_info_btn"
            className={
              'packetSendButtonsStyle ' + (view_type == 'general_info' ? 'activeModule' : '')
            }
            to={`/packet/view/${vehicleId}`}
            onClick={() => EmptyPacketViews()}
            style={{ alignItems: 'flex-start' }}
          >
            General Info.
          </NavLink>
          <PacketModules type={view_type} />
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  vehicleId:
    state.packetstash &&
    state.packetstash.vehicle_info &&
    state.packetstash.vehicle_info.vehicle_id,
  view_type: state.packetview.view_type,
})
export default connect(mapStateToProps, {})(PacketLeft)
