// general: action file for all things 'packet' data being passed back and forth.

// API(s)
import {
  DJangoPacketCustomerSearchAPIURL,
  DJangoPacketCustomerAlertSaveAPIURL,
  DJangoPacketImagesAPIURL,
  DJangoPacketModulesAPIURL,
  DJangoPActivityListTypesAPIURL,
  DJangoVehicleInfoAPIURL,
  DJangoPacketPriceDeltaAPIURL,
  DJangoPacketSingleModulesAPIURL,
  DjangoInventoryContentFilteredGetAPIURL,
  DjangoRecordAPIURL,
  DJangoGetVehicleHistory,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'
import cloneDeep from 'lodash/cloneDeep'

export function getFromURL(args) {
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${args.url}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function recordPacketView(args) {
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoRecordAPIURL}${args.vehicle_id}/record_view`, {
    method: 'PUT',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function recordDocumentPrint(args) {
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoRecordAPIURL}${args.vehicle_id}/record_print`, {
    method: 'PUT',
    headers,
  }).then(response => {
    return response.json()
  })
}

export function initiateVdpPrint(args) {
  const { token, vehicle_id, modules } = args
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  let url = `${AIP_DJAPI_URLS.DjangoInitiatePrint}?vehicle_id=${vehicle_id}`
  let module_ids = modules ? modules.map(module => module.module_id) : []
  return AIP_DJAPI(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({ modules: [...module_ids] }),
  })
}

export function retrieveVdpPrint(token, print_id) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
  }
  let url = `${AIP_DJAPI_URLS.DjangoRetrievePrint}${print_id}/`
  return AIP_DJAPI(url, {
    method: 'GET',
    headers,
  })
}

// PACKET - FUNCTIONS REFERENCED IN THE 'EXPORT'
const GetVehicleInfoConst = async (vehicleId: number, token: string) => {
  // objects
  let packetObjData = {}
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  await AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoVehicleInfoAPIURL}${vehicleId}`, {
    method: 'GET',
    headers,
  })
    .then(response => {
      return response.json()
    })
    .then(json => {
      console.log('RESPONSE')
      console.log(json)
      if (json !== null && json !== undefined && json.vehicle_id) {
        packetObjData = cloneDeep(json)
        let price = ''
        if (json.vehicle_price) {
          json.vehicle_price.forEach(vp => {
            if (vp.price_type_id.price_type_id === 2) {
              price = vp.price
            }
          })
        }
        packetObjData.vehicle_id = json.vehicle_id // vehicle_id
        packetObjData.price = price // price
        packetObjData.engine_description = json.engine_description // engine_description
        packetObjData.vehicle_body = json.vehicle_body // vehicle_body
        packetObjData.interior_color = json.interior_color // interior_color
        packetObjData.miles = json.miles // miles
        packetObjData.color = json.color // color
        packetObjData.year = json.year // year
        packetObjData.make = json.make // make
        packetObjData.model = json.model // model
        packetObjData.vin = json.vin // vin
        packetObjData.stock = json.stock // stock
        packetObjData.veh_in_date = json.veh_in_date // veh_in_date
        packetObjData.is_cpo = json.cpo === 'N' ? false : true // cpo
        packetObjData.stock_type = json.stock_type === 'N' ? 'New' : 'Pre-Owned' // stock_type
        packetObjData.comments = json.comments
        packetObjData.trim = json.trim
        // transmission_description
        packetObjData.transmission_description = json.transmission_description
        packetObjData.description = json.description // description
        packetObjData.store_full_name =
          json.company_store_id && json.company_store_id.store_full_name // store_full_name
        packetObjData.loading = false // loading
        packetObjData.archived = json.archived
        packetObjData.date_created = json.date_created
        packetObjData.inside_archived_plan = json.inside_archived_plan
        packetObjData.archived_ts = json.archived_ts
        console.log('THIS IS THE RETURNED JSON')
        console.log(json)
      } else {
        packetObjData = null // loading
      }
    })
    .catch(error => {
      packetObjData.vehicle_id = vehicleId // vehicle_id
      packetObjData.loading = false // loading
      console.log(error.toString())
    })

  return packetObjData
}

const GetPacketSendImagesConst = async (vehicleId: number, token: string) => {
  // objects
  const imgObjData = {}

  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  await AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketImagesAPIURL}${vehicleId}?items_per_page=1000`, {
    method: 'GET',
    headers,
  })
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.results && json.results.length > 0) {
        const newArray = []

        for (const entry of json.results) {
          newArray.push(`${entry.base_url}${entry.vehicle_img_filepath}?nocache="${Date.now()}"`)
        }

        // search returned images for "vehicle_img_primary"
        json.results.forEach(item => {
          if (item.vehicle_img_primary == true) {
            //imgObjData.first_image = `${item.base_url}${item.vehicle_img_filepath}`
          }
        })
        imgObjData.packet_images = newArray // packet_images
        // if no vehicle_img_primary provided, use first image
        if (!imgObjData.first_image) {
          imgObjData.first_image = newArray[0] // first_image
        }
        imgObjData.loading = false // loading
      } else if (json.detail != null && json.detail != undefined) {
        // detail *should* contain path to placeholder image(s)
        const newArray = []
        newArray.push(json.detail)

        imgObjData.packet_images = newArray // packet_images
        imgObjData.first_image = newArray[0] // first_image
        imgObjData.loading = false // loading
      } else {
        // detail on response results in a fully missing image
        imgObjData.packet_images = [] // packet_images
        imgObjData.first_image = '' // first_image
        imgObjData.loading = false // loading
      }
    })
    .catch(error => {
      imgObjData.packet_images = [] // packet_images
      imgObjData.first_image = '' // first_image
      imgObjData.loading = false // loading
      console.log(error.toString())
    })

  return imgObjData
}

const GetPacketSendModulesConst = async (vehicleId: number, token: string) => {
  // objects
  const moduleObjData = {}

  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  await AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketModulesAPIURL}${vehicleId}`, {
    method: 'GET',
    headers,
  })
    .then(response => {
      return response.json()
    })
    .then(json => {
      if (json.modules && json.modules.length > 0) {
        const idArray = []
        const objArray = []

        json.modules.map(d => {
          idArray.push(d.module_id)
          moduleObjData.module_choices = idArray // module_choices

          objArray.push({
            module_id: d.module_id,
            label: d.label,
            description: d.description,
            content_type: d.content_type,
            url: d.url,
            djapi_hosted: d.djapi_hosted,
            default_on_packet_send: d.default_on_packet_send,
            enabled: d.enabled,
            thumbnail: d.content_type === 'pdf' ? `${d.url.split('.pdf')[0]}_page_${1}.png` : '',
          })
        })

        if (idArray && idArray.length > 0) {
          moduleObjData.id_array = idArray // id_array
        }

        moduleObjData.results = objArray // results
        moduleObjData.loading = false // loading
      } else {
        moduleObjData.id_array = null // id_array
        moduleObjData.results = [] // results
        moduleObjData.loading = false // loading
      }
    })
    .catch(error => {
      moduleObjData.id_array = null // id_array
      moduleObjData.results = [] // results
      moduleObjData.loading = false // loading
      console.log(error.toString())
    })

  return moduleObjData
}
const GetHTMLModuleURLContentsConst = (moduleUrl: string, token: string) => {
  let htmlContent

  return fetch(moduleUrl, {
    method: 'GET',
    headers: {
      Accept: 'text/html',
      'Content-Type': 'text/html',
      //Authorization: `Token ${token}`,
    },
  })
    .then(responseHTMLStream => {
      return responseHTMLStream.text()
    })
    .then(responseHTML => {
      console.log('responseHTML')
      console.log(responseHTML)
      return responseHTML
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}

// ----------EXPORT----------

export default {
  // PACKETS - API - INFORMATION
  getVehicleInfo: (vehicleId: number, token: string) => {
    // v1/packets/vehicle/{vehicle_id}
    return GetVehicleInfoConst(vehicleId, token)
  },

  getVehicleInfoForSend: (
    vehicleId: number,
    stock: string,
    token: string,
    vin: string,
    user_id: number,
  ) => {
    // v1/query/vehicles
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DjangoInventoryContentFilteredGetAPIURL}?vehicle_id=${vehicleId}&stock=${stock}&vin=${vin}&page=1&user_id=${user_id}`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  getEmbedHTMLModuleContent: (fetchurl: string, token: string) => {
    // v1/packets/vehicle/images/{vehicle_id}

    return GetHTMLModuleURLContentsConst(fetchurl, token)
  },

  getPacketSendImages: (vehicleId: number, token: string) => {
    // v1/packets/vehicle/images/{vehicle_id}
    return GetPacketSendImagesConst(vehicleId, token)
  },

  getPacketSendModules: (vehicleId: number, token: string) => {
    // v1/packets/vehicle/module-v2/:vehicle_id
    return GetPacketSendModulesConst(vehicleId, token)
  },

  getPriceDeltaPackets: (vehicleId: number, token: string) => {
    // v1/mobile/price-change-deltas/?vehicle_ids={vehicle_id}

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketPriceDeltaAPIURL}?vehicle_ids=${vehicleId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getPacketRepairOrder: (url: string, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${url}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  //
  //
  //
  // PACKETS - API - ACTIVITY TABLE
  getPacketSendActivityTypes: (
    displayPage: string,
    vehicleId: number,
    numORurl: string,
    token: string,
  ) => {
    // v1/packets/history/:vehicle_id/:history_type

    let pathParameters = `${vehicleId}/${displayPage}?page=${numORurl}&items_per_page=15`

    if (numORurl !== '1') {
      pathParameters = numORurl
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPActivityListTypesAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  //
  //
  //
  // PACKETS - API - VEHICLE TIMELINE SERVICE
  getVehicleHistory: (vin: number, token: string) => {
    // v1/vdp/vehicle-history/:vin
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoGetVehicleHistory}${vin}`, {
      method: 'GET',
      headers,
      params: JSON.stringify({ all_vehicles: false }),
    }).then(response => {
      return response.json()
    })
  },

  //
  //
  //
  // PACKETS - API - CUSTOMERS
  getCustomerHistoryPackets: (customerId, numORurl, token) => {
    // v1/users/customer/{int:customer_id}/packet-history/
    console.log('THIS IS numORurl')
    console.log(numORurl)
    let pathParameters = `${customerId}/packet-history/?page=${numORurl}&items_per_page=15`

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketCustomerSearchAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  postUpdateCustomerPacketAlert: (
    customerId: number,
    vehicleId: number,
    disablePcAlerts: boolean,
    shareUuid: number,
    token: string,
  ) => {
    const params = {
      share_uuid: shareUuid,
      disable_pc_alerts: disablePcAlerts,
      vehicle_id: vehicleId,
      customer_id_fk: customerId,
    }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoPacketCustomerAlertSaveAPIURL}/${shareUuid}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
}
