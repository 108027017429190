import React, { useEffect, useState, useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import StickerPullCoverageGraphPrint from '../../packetview/columns/components/children/stickerpullcoveragegraphprint'
import Modal from '../../layouts/modal'
import LoadingSpinner from '../../helpers/loadingspinner'
import { getStickerPullSupportCatalog } from '../../../core/actions/stickerpullactions'
import '../styles/stickerpullcoveragemodal.scss'

function StickerPullCoverageModal(props) {
  const [coverage, setCoverage] = useState(null)
  useEffect(() => {
    getStickerPullSupportCatalog(props.token).then(res => {
      if (res && res.makes) {
        setCoverage(res)
      }
    })
  }, [])
  return (
    <Modal>
      <div className="sticker-pull-coverage-container">
        {coverage ? <StickerPullCoverageTable coverage={coverage} /> : <LoadingSpinner loading />}
      </div>
    </Modal>
  )
}

function StickerPullCoverageTable(props) {
  const { coverage } = props
  const stickerPullCoverageRef = useRef()

  const date = moment().format('MMMM Do YYYY, h:mm a')

  return (
    <div className="sticker-pull-coverage-table">
      <div className="sticker-pull-coverage-table-button-container">
        <ReactToPrint
          trigger={() => (
            <button className="sticker-pull-coverage-table-button">Print Sticker Pull Graph</button>
          )}
          content={() => stickerPullCoverageRef.current}
        />
      </div>
      <div className="sticker-pull-coverage-table-title">Sticker Coverage</div>
      <p className="sticker-pull-coverage-table-disclaimer">
        Sticker Pull Coverage can change over time. This coverage is for
      </p>
      <p>{date}</p>
      {StickerPullCoverageGraphKey()}
      {coverage.makes.map((make, key) => {
        return <StickerPullCoverageGraph make_coverage={make} key={key} />
      })}
      <div style={{ display: 'none' }}>
        <StickerPullCoverageGraphPrint coverage={coverage.makes} ref={stickerPullCoverageRef} />
      </div>
    </div>
  )
}
function getGraphRangeStyle(coverage_type) {
  switch (coverage_type) {
    case 'high_coverage':
      return 'sticker-pull-coverage-table-graph-range-high-coverage'
      break
    case 'medium_coverage':
      return 'sticker-pull-coverage-table-graph-range-medium-coverage'
      break
    case 'low_coverage':
      return 'sticker-pull-coverage-table-graph-range-low-coverage'
      break
    case 'no_coverage':
      return 'sticker-pull-coverage-table-graph-range-no-coverage'
      break
  }
}
function getGraphRangeTitle(coverage_type) {
  switch (coverage_type) {
    case 'high_coverage':
      return 'High Coverage'
      break
    case 'medium_coverage':
      return 'Partial Coverage'
      break
    case 'low_coverage':
      return 'Low Coverage'
      break
    case 'no_coverage':
      return 'No Coverage'
      break
  }
}
function StickerPullCoverageGraph(props) {
  const { make_coverage } = props
  const start_year = make_coverage.coverage[0].year_start
  const end_year = make_coverage.coverage[make_coverage.coverage.length - 1].year_end
    ? make_coverage.coverage[make_coverage.coverage.length - 1].year_end
    : new Date().getFullYear()
  const num_years = end_year - start_year + 1
  let coverage_graph_ranges = []
  make_coverage.coverage.map((range_info, range_info_index) => {
    let coverage_graph_sections = []
    for (
      let i = range_info.year_start;
      i < (range_info.year_end ? range_info.year_end : end_year) + 1;
      i++
    ) {
      coverage_graph_sections.push(
        <StickerPullCoverageGraphSection
          key={i}
          index={i}
          range_start={range_info.year_start}
          range_end={range_info.year_end ? range_info.year_end : end_year}
        />,
      )
    }
    let dateRange = range_info.year_end ? range_info.year_end : end_year
    if (dateRange !== range_info.year_start) {
      dateRange = `${range_info.year_start} - ${
        range_info.year_end ? range_info.year_end : end_year
      }`
    }
    let rangePositionStyle = ''
    if (range_info_index === 0) {
      rangePositionStyle += ' sticker-pull-coverage-graph-range-first'
    }
    if (range_info_index === make_coverage.coverage.length - 1) {
      rangePositionStyle += ' sticker-pull-coverage-graph-range-last'
    }
    coverage_graph_ranges.push(
      <div
        className={`sticker-pull-coverage-table-graph-range ${getGraphRangeStyle(
          range_info.type,
        )} ${rangePositionStyle}`}
        style={{
          flexGrow: `${(range_info.year_end ? range_info.year_end : end_year) -
            range_info.year_start +
            1}`,
        }}
      >
        {coverage_graph_sections}
        <div className="sticker-pull-coverage-table-graph-range-display">
          {dateRange}
          <div className="sticker-pull-coverage-table-graph-range-display-title">
            {getGraphRangeTitle(range_info.type)}
          </div>
        </div>
      </div>,
    )
  })

  return (
    <div className="sticker-pull-coverage-table-graph-container">
      <div className="sticker-pull-coverage-table-graph-title">{make_coverage.make}</div>
      <div className="sticker-pull-coverage-table-graph">{coverage_graph_ranges}</div>
    </div>
  )
}
export function StickerPullCoverageGraphSection(props) {
  const { range_start, range_end, index } = props
  let positionStyle = ''
  if (index === range_end) {
    positionStyle += ' sticker-pull-coverage-table-graph-section-last'
  }
  if (index === range_start) {
    positionStyle += ' sticker-pull-coverage-table-graph-section-first'
  }
  return (
    <div
      className={`sticker-pull-coverage-table-graph-section ${positionStyle}`}
      style={{ flexGrow: `${range_end - range_start + 1}` }}
    ></div>
  )
}

function StickerPullCoverageGraphKey() {
  return (
    <div className="sticker-pull-coverage-table-graph-key-container">
      <div className="sticker-pull-coverage-table-graph-key">
        <div className="sticker-pull-coverage-table-graph-key-example sticker-pull-coverage-table-graph-range-high-coverage" />
        <div className="sticker-pull-coverage-table-graph-key-title">High Coverage</div>
      </div>
      <div className="sticker-pull-coverage-table-graph-key">
        <div className="sticker-pull-coverage-table-graph-key-example sticker-pull-coverage-table-graph-range-medium-coverage" />
        <div className="sticker-pull-coverage-table-graph-key-title">Partial Coverage</div>
      </div>
      <div className="sticker-pull-coverage-table-graph-key">
        <div className="sticker-pull-coverage-table-graph-key-example sticker-pull-coverage-table-graph-range-low-coverage" />
        <div className="sticker-pull-coverage-table-graph-key-title">Low Coverage</div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  token: state.authentication.user.token,
})
export default connect(mapStateToProps)(StickerPullCoverageModal)
