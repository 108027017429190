// TODO:
// 1. mobile support
// 2. cross browser check support
// 3. user simulated using test to find bugs

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// core files
import CustomerActions from '../../../core/actions/users/customeractions'
import DashActions from '../../../core/actions/features/dashboardactions'
import { hideModal } from '../../../core/_actions/modalActions'
// actions
import ProfileActions from '../../../core/actions/users/profileactions'
// components
import Modal from '../../layouts/modal'
import LoadingSpinner from '../../helpers/loadingspinner'
// icons
import IconEmail from '../../../core/assets/images/icons/icon-email'
import IconPhone from '../../../core/assets/images/icons/icon-phone'
import IconMobile from '../../../core/assets/images/icons/icon-mobile'
// assets
import PlayIcon from '../../../core/assets/images/icons/play-icon'

import '../styles/customerModal.scss'

type Props = {
  title: string,
  id: number,
  idSecond: number,
  token: string,
  hideModal: boolean,
  shareId: string,
  content: string,
  teamMember: string,
  covideoId: Number,
  userId: String,
}

export class DashboardCustomerModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accessToken: JSON.parse(localStorage.getItem('covideo-access-token')),
      email: '', // email default blank string.
      firstname: '', // firstname default blank string.
      lastname: '', // lastname default blank string.
      phone: '', // phone default blank string.
      mobile: '', // mobile default blank string.

      notes: '',

      attachments: [],

      currentUserFullName: '',
      covideo: null,
      playing: '',
      loading: false,
    }

    this._onClose = this.onClose.bind(this)
  }

  state: {
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    mobile: string,

    notes: string,

    attachments: array,

    currentUserFullName: String,
    covideo: Object,
    playing: Number,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetCustomerInfo()
      this.onMountGetNotesInfo()
      this.onMountGetAttachmentInfo()
      this.onMountGetUserInfo()
      this.onMountGetCovideo()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    this.setState({
      content: '',
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      mobile: '',

      notes: '',

      attachments: [],

      currentUserFullName: '',
      covideo: null,
      playing: '',
      loading: false,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  onMountGetCustomerInfo() {
    const { id, token } = this.props

    if (id !== null && id !== undefined) {
      CustomerActions.getSingleContact(id, token)
        .then(json => {
          if (json.customer_id !== null && json.customer_id !== undefined) {
            this.setState({
              email: json.email,
              firstname: json.first_name,
              lastname: json.last_name,
              phone: json.phone_number,
              mobile: json.mobile_number,
            })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }

  onMountGetNotesInfo() {
    const { idSecond, token } = this.props

    if (idSecond !== null && idSecond !== undefined) {
      DashActions.getDashNote(idSecond, token)
        .then(json => {
          if (json[0].message !== null && json[0].message !== undefined) {
            this.setState({ notes: json[0].message })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }

  onMountGetAttachmentInfo() {
    const { shareId, username, token } = this.props
    // still needs work
    if (shareId !== null && shareId !== undefined) {
      DashActions.getPacketAttachments(shareId, username, token)
        .then(json => {
          if (json.count !== undefined && json.count > 0) {
            this.setState({ attachments: json.results })
          }
        })
        .catch(error => {
          console.error(error.toString())
        })
    }
  }

  onMountGetUserInfo() {
    ProfileActions.getProfile(this.props.userId, this.props.token).then(res => {
      if (res !== null && res !== undefined) {
        const fullName = `${res.first_name} ${res.last_name}`
        this.setState({ currentUserFullName: fullName })
      }
    })
  }

  onMountGetCovideo() {
    this.setState({
      loading: true,
    })

    const { covideo } = this.state
    const { covideoId } = this.props
    const { accessToken } = this.state

    const url = 'https://api.covideo.com/videos/?start=0&search='

    if (accessToken) {
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          return response.json()
        })
        .then(res => {
          if (res) {
            res.videos.map(video =>
              video.id == covideoId ? this.setState({ covideo: video }) : null,
            )
          }
        })
    }

    this.setState({
      loading: false,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  // onClose
  onClose() {
    this.props.hideModal()
  }

  handleCovideoStartAndStop(event, action) {
    var playPromise = event.target.play()

    if (action == 'stop') {
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            event.target.pause()
          })
          .catch(error => {})
      }
    }

    if (playPromise !== undefined) {
      playPromise.then(_ => {}).catch(error => {})
    }
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  props: Props
  _onClose: Function // function

  render() {
    const { content, teamMember } = this.props
    const { accessToken } = this.state

    // states
    const {
      email,
      firstname,
      lastname,
      phone,
      mobile,
      notes,
      attachments,
      currentUserFullName,
      covideo,
      playing,
      loading,
    } = this.state
    if (!firstname) return <LoadingSpinner loading wheel />
    if (content === 'contactInfo') {
      return (
        <Modal>
          <div className="row, customer-info-box">
            <h1>
              {firstname} {lastname}
            </h1>

            <h3>Contact Information</h3>
            <hr />
            <div id="customer-info-box-mid">
              <p className="contact-item-row">
                <IconEmail />
                <b>Email: </b>
                {email ? email : 'Not specified'}
              </p>

              <p className="contact-item-row">
                <IconPhone />
                <b>Phone: </b>
                {phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : 'Not specified'}
              </p>

              <p className="contact-item-row">
                <IconMobile />
                <b>Mobile:</b>
                {mobile ? mobile.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : 'Not specified'}
              </p>
            </div>
          </div>
        </Modal>
      )
    }
    if (content === 'packetMessage') {
      return (
        <Modal>
          <div className="row, customer-info-box">
            <h3>Packet Message</h3>
            <hr />
            <div className="packet-mssg" dangerouslySetInnerHTML={{ __html: notes }} />
            <br />
            {attachments.length > 0 && (
              <div>
                <h4>Attachments</h4>
                <hr />
                <div className="packet-attachments">
                  {attachments.map(attachment => (
                    <div className="packet-attachment">
                      <a href={attachment.file_url} target="_blank">
                        {attachment.file_url.replace(/^.*\/(.*)$/, '$1')}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {teamMember == currentUserFullName && covideo && accessToken ? (
              <div className="covideo-player-holder">
                <video
                  className="dashboard-covideo-player"
                  id="dashboardCovideo"
                  onMouseOver={event => (
                    this.handleCovideoStartAndStop(event, 'start'),
                    this.setState({
                      playing: covideo.id,
                    })
                  )}
                  onMouseOut={event => (
                    this.handleCovideoStartAndStop(event, 'stop'),
                    this.setState({
                      playing: -1,
                    })
                  )}
                  poster={covideo.autogeneratedThumbnail}
                >
                  <source src={covideo.videoSource} type="video/mp4" />
                </video>
                <div
                  className="covideo-overlay"
                  style={{ display: this.state.playing === covideo.id ? 'none' : 'block' }}
                >
                  <PlayIcon />
                </div>
              </div>
            ) : covideo ? (
              <LoadingSpinner loading />
            ) : null}
          </div>
        </Modal>
      )
    }
  }
}

// ***********************LETS*EXPORT*CUSTOMER*MODAL***********************
function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    id: state.modal.props.id_object.main_id,
    idSecond: state.modal.props.id_object.other_id,
    shareId: state.modal.props.id_object.share_id,
    token: state.modal.props.token,
    content: state.modal.props.content,
    // currentUser stuff
    userId: state.authentication.user.user.profile.legacy_user.legacy_user_id,
    teamMember: state.modal.props.teamMember,
    covideoId: state.modal.props.covideoId,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(DashboardCustomerModal)
