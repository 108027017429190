import React from 'react'

//device detect library
import UAParser from 'ua-parser-js'

//components
import BannerConfiguration from './components/bannerConfig'
import BannerContent from './components/bannerContent'

//icons
import AutoiPacketIcon from '../../core/assets/images/icons/AutoiPacketWhtRingMobile.png'
import CancelIcon from '../../core/assets/images/icons/cancel.png'

//initialize UA Parser
const Parser = new UAParser.UAParser()

const NotificationBanner = () => {
  const userDevice = Parser.getDevice()
  const userDeviceConfig = BannerConfiguration()

  if (userDevice.type === 'mobile') {
    return (
      <BannerContent
        storeName={userDeviceConfig.storeName}
        image={AutoiPacketIcon}
        link={userDeviceConfig.storeLink}
        cancelIcon={CancelIcon}
      />
    )
  }

  return null
}

export default NotificationBanner
