import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'
import AUTH_API, { AUTH_API_URLS } from '../../config/authAPI'
import {
  TOS_ACCEPTANCE_UPDATE,
  GET_TOS_ACCEPTANCE,
  GET_INTERCOM_INFO,
  GET_DEFAULT_STORE,
  SET_USER_LOGIN_ERROR,
} from '../../../core/_constants/settingsTypes'
import { emptySettings } from '../../_actions/settingsActions'
import { browserHistory } from 'react-router'
import { EmptyAllStashConst } from '../../_actions/emptystoreActions'
import { store } from '../../../../index'
import runtimeEnv from '../../config/envConstants'
import { userConstants } from '../../_constants'

export async function swapRedirectToken(redirectToken, pkce, onSuccess) {
  let headers = {
    Authorization: `bearer ${redirectToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  AUTH_API(AUTH_API_URLS.SWAP_REDIRECT_TOKEN, {
    method: 'POST',
    headers,
    body: JSON.stringify({ pkce, client_id: 5100206 }),
  })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        logoutUser(true)
      }
    })
    .then(res => {
      if (res && res.access_token) {
        getUserLoginInfo(res, onSuccess)
      } else {
        logoutUser(true)
      }
    })
}
export function getUserLoginInfo(auth, onSuccess) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (auth) {
    if (auth.access_token) {
      headers['Authorization'] = `bearer ${auth.access_token}`
    }
  } else {
    let oldAuthentication = localStorage.getItem('authentication')
    if (oldAuthentication) {
      auth = JSON.parse(oldAuthentication)
      headers['Authorization'] = `bearer ${auth.access_token}`
    }
  }
  return AIP_DJAPI(
    AIP_DJAPI_URLS.DjangoLoginInfo,
    {
      headers,
    },
    false,
  )
    .then(response => {
      if (response.status === 200) {
        return response.json()
      } else if (response.status === 403) {
        return response
          .json()
          .then(res => {
            store.dispatch({
              type: SET_USER_LOGIN_ERROR,
              userLoginError: res,
            })
          })
          .catch(error => {
            console.error(error)
          })
      }
    })
    .then(res => {
      if (res) {
        let authentication = {
          ...auth,
          user: { ...res },
          userInfo: buildDeprecatedUserInfoObject(res, auth.access_token),
        }
        localStorage.setItem('authentication', JSON.stringify(authentication))
        store.dispatch({
          type: userConstants.UPDATE_USER_LOGIN_INFO,
          authentication,
        })
        if (onSuccess) {
          onSuccess()
        }
      }
    })
}

export function updateUserLoginInfo() {
  return getUserLoginInfo()
}
export function logoutUser(requireLogin) {
  localStorage.clear()
  // localStorage.removeItem('authentication')
  if (requireLogin) {
    window.location.pathname = '/require-login'
  } else {
    store.dispatch({
      type: userConstants.LOGOUT_USER,
    })
  }
}
export function refreshToken(refresh_token) {
  let headers = {
    Authorization: `bearer ${refresh_token}`,
  }
  return AUTH_API(AUTH_API_URLS.REFRESH_TOKEN, {
    method: 'POST',
    headers,
  }).then(async response => {
    if (response.status === 200) {
      let clonedResponse = response.clone()
      let json = await clonedResponse.json()
      let oldAuthentication = JSON.parse(localStorage.getItem('authentication'))
      localStorage.setItem('authentication', JSON.stringify({ ...oldAuthentication, ...json }))
      getUserLoginInfo(json)
      return { response, json }
    } else {
      logoutUser()
      return null
    }
  })
}
function buildDeprecatedUserInfoObject(userLoginInfo, token) {
  //Let this stand as the example of why it is foolish not to just use and store objects as they are returned by the API. Pointlessly mapping object fields to different names is unreliable and unmaintanable -Khristan
  const roleId = userLoginInfo.user.profile.legacy_user.role_id
  let userInfo = {
    avatar: '',
    company_id: userLoginInfo.user.profile.legacy_user.company_id,
    crm_email: '', //TODO Figure out if this is used anywhere
    default_store_id: userLoginInfo.user.profile.legacy_user.default_store_id,
    dj_token: token,
    emailMessageIsCustom: false,
    emailSignatureIsCustom: false,
    first_name: userLoginInfo.user.first_name,
    hasInsights: true,
    is_admin: roleId === 3 || roleId === 5 || roleId === 6,
    last_name: userLoginInfo.user.last_name,
    send_email_alerts: true,
    send_message: {},
    send_mobile_alerts: false,
    signature: {},
    store_full_name: userLoginInfo.user.profile.legacy_user.store_full_name,
    store_groups: [],
    store_type_id: 2,
    timestamp: new Date().getTime(),
    user_email_address: userLoginInfo.user.profile.legacy_user.user_email_address,
    user_id: userLoginInfo.user.profile.legacy_user.legacy_user_id,
    user_mobile: userLoginInfo.user.profile.legacy_user.user_mobile,
    user_phone: userLoginInfo.user.profile.legacy_user.user_phone,
    username: userLoginInfo.user.profile.legacy_user.username,
    view_frequency: 'all',
  }
  return userInfo
}

export function initiateResetPassword(email) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AUTH_API(AUTH_API_URLS.INITIATE_RESET_PASSWORD, {
    method: 'POST',
    headers,
    body: JSON.stringify({ email }),
  })
}
// ----------EXPORT----------
export function getIntercomInfo(args) {
  const { token } = args
  return function(dispatch) {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoIntercomInfo}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res) {
          dispatch({
            type: GET_INTERCOM_INFO,
            intercom_info: res,
          })
        }
      })
  }
}

export function getDefaultStore(defaultStoreId: number, token: string) {
  return function(dispatch) {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoProfileStoreSingleGetAPIURL}${defaultStoreId}`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res) {
          dispatch({
            type: GET_DEFAULT_STORE,
            default_store_info: res,
          })
        }
      })
  }
}
export function getUserDetail(userId: number, token: string) {
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoAuthUserDetail}${userId}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function getListAcceptedTOS(args) {
  const { token, company_id } = args

  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoTOSAgreement}?company_id=${company_id}&term_type=1`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function getTOSAgreement() {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DjangoTOS}?type=1`,
    {
      method: 'GET',
      headers,
    },
    false,
  ).then(response => {
    return response.json()
  })
}
export function getTOSAgreementInfo(args) {
  const { token, username } = args
  return function(dispatch) {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoTOSAgreement}1/${username}/`, {
      method: 'GET',
      headers,
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res) {
          dispatch({
            type: GET_TOS_ACCEPTANCE,
            tosAcceptance: res.is_accepted ? true : false,
          })
        }
      })
  }
}
export function updateTOSAgreement(args) {
  const { token, username, is_accepted } = args
  return function(dispatch) {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DjangoTOSAgreement}1/${username}/?is_accepted=${is_accepted ? '1' : '0'}`,
      {
        method: 'PUT',
        headers,
      },
    )
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res) {
          dispatch({
            type: TOS_ACCEPTANCE_UPDATE,
            tosAcceptance: res.is_accepted ? true : false,
          })
        }
      })
  }
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(
    `${AIP_DJAPI_URLS.DjangoTOSAgreement}1/${username}/?is_accepted=${is_accepted ? '1' : '0'}`,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    },
  ).then(response => {
    return response.json()
  })
}

export function unlockIntercomMessenger(args) {
  const { vehicle_id, token } = args
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DjangoIntercomUnlockMessage}?vehicle_id=${vehicle_id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  }).then(response => {
    return response.json()
  })
}
export default {
  // USERS - API - OTHER
  postSendSupportEmail: (
    token: string,
    userId: number,
    email: string,
    firstname: string,
    lastname: string,
    title: string,
    message: string,
  ) => {
    // contact-support/

    const params = {
      message,
      subject: title,
      last_name: lastname,
      first_name: firstname,
      legacy_user_id: userId,
      from_email: email,
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoUserReportPostAPIURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  postPasswordStrengthDJAPI: (password: string) => {
    // password-evaluation/
    const params = { password }
    const url = AIP_DJAPI_URLS.DJangoResetPasswordStrengthAPIURL
    // logging start
    // --- colors
    // --- log call
    // eslint-disable-next-line no-undef
    return fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(response => {
        // --- log head response
        return response.json()
      })
      .then(responseJson => {
        // --- log response
        return responseJson
      })
      .catch(error => {
        console.error(error)
      })
  },
}
