import React from 'react'

const ContentCopy = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path d="M5.75 16.854q-.5 0-.844-.344-.344-.343-.344-.843V6.354h.667v9.313q0 .187.167.354.166.167.354.167h7.312v.666Zm2.167-2.166q-.5 0-.844-.344t-.344-.844V4.521q0-.5.344-.833.344-.334.844-.334h6.979q.5 0 .833.334.333.333.333.833V13.5q0 .5-.333.844t-.833.344Zm0-.667h6.979q.187 0 .344-.167.156-.166.156-.354V4.521q0-.188-.156-.344-.157-.156-.344-.156H7.917q-.188 0-.355.156-.166.156-.166.344V13.5q0 .188.166.354.167.167.355.167Zm-.521 0v-10V14.021Z" />
    </svg>
  )
}
export default ContentCopy
