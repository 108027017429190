// general: action file for all things 'contact' data being passed back and forth.

// API(s)
import {
  DJangoContactListAPIURL,
  DJangoContactSearchAPIURL,
  DJangoContactSingleGetAPIURL,
  DJangoContactSingleSaveAPIURL,
  DJangoContactSearchExactMatchAPIURL,
  DJangoRecentCustomersAPIURL,
  DJangoFavoriteCustomersAPIURL,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

// HELPERS
import Helper from '../../helpers/platformhelper'

export function getRecentCustomers(args) {
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoRecentCustomersAPIURL}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function getFavoriteCustomers(args) {
  let headers = {
    Authorization: `Token ${args.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoFavoriteCustomersAPIURL}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export default {
  // CUSTOMERS - API - LISTS
  getListContacts: (defaultStoreId: number, numORurl: string, token: string) => {
    let pathParameters = `${defaultStoreId}?page=${numORurl}&items_per_page=50`

    if (numORurl !== '1') {
      pathParameters = numORurl
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactListAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getListLetterContacts: (
    defaultStoreId: number,
    letterIndex: string,
    numORurl: string,
    token: string,
  ) => {
    let pathParameters = `${defaultStoreId}/${letterIndex}?page=${numORurl}&items_per_page=50`

    if (numORurl !== '1') {
      pathParameters = numORurl
    }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactListAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getSearchContacts: (defaultStoreId: number, searchText: string, page: number, token: string) => {
    let pathParameters = `?search=${searchText}&store_id=${defaultStoreId}&page=${page}&items_per_page=50`

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSearchAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getSearchContactsExactMatch: (
    defaultStoreId: number,
    searchText: string,
    numORurl: string,
    token: string,
  ) => {
    let pathParameters = `?search=${searchText}&store_id=${defaultStoreId}&page=${numORurl}&items_per_page=50`

    if (numORurl !== '1') {
      pathParameters = numORurl
    }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSearchExactMatchAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  //
  //
  //
  // CUSTOMERS - API - SINGLE
  getSingleContact: (customerId: number, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSingleGetAPIURL}${customerId}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
  putCreateFavoriteCustomer: (customer: number, user: number, token: string) => {
    const params = {
      customer_id: customer,
      user_id: user,
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoFavoriteCustomersAPIURL}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },
  putDeleteFavoriteCustomer: (customer: number, user: number, token: string) => {
    const params = {
      customer_id: customer,
      user_id: user,
    }
    let headers = {
      Authorization: `Token ${token}`,
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoFavoriteCustomersAPIURL}${customer}`, {
      method: 'DELETE',
      headers,
    })
  },
  putUpdateSingleContact: (
    customerId: number,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    mobile: string,
    address: string,
    city: string,
    state: string,
    zipcode: number,
    token: string,
    isfavorite: boolean,
  ) => {
    const params = {
      is_favorite: isfavorite,
      first_name: firstname,
      last_name: lastname,
      email,
      phone_number: Helper.FormatPhoneNumber(phone),
      mobile_number: Helper.FormatPhoneNumber(mobile),
      address1: address,
      city,
      state,
      postal_code: zipcode,
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSingleSaveAPIURL}${customerId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  putCreateSingleContact: (
    companyIdFk: number,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    mobile: string,
    address: string,
    city: string,
    state: string,
    zipcode: number,
    token: string,
    isfavorite: boolean,
  ) => {
    const params = {
      is_favorite: isfavorite,
      first_name: firstname,
      last_name: lastname,
      email,
      phone_number: Helper.FormatPhoneNumber(phone),
      mobile_number: Helper.FormatPhoneNumber(mobile),
      address1: address,
      city,
      state,
      postal_code: zipcode,
      disable_pc_alerts: false,
      company_id_fk: companyIdFk,
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSingleSaveAPIURL}?duplication_mode=true`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(params),
    }).then(response => {
      return response.json()
    })
  },

  putDeleteSingleContact: (customer_id: number, token: string) => {
    const params = {
      customer_id: customer_id,
    }

    let headers = {
      Authorization: `Token ${token}`,
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoContactSingleSaveAPIURL}${customer_id}`, {
      method: 'DELETE',
      headers,
    })
  },
}
