import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
// core files
// -- actions
import { updateBasket, emptyBasket } from '../../../core/actions/inventoryactions.js'
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { PACKET_SEND_MODAL } from '../../../core/_constants/modalTypes'
import CartIconW from '../assets/carticonw.js'
import IconCancel from '../../../core/assets/images/icons/cancel-icon'

// -- actions

class Basket extends Component {
  render() {
    const { basket, emptyBasketConnect, updateBasketConnect, selectVehicle, miniMode } = this.props
    const basketBoolean = basket && basket.length
    return (
      <React.Fragment>
        <div
          className={basketBoolean ? 'basket-container fixed' : 'basket-container'}
          style={{ animation: ` 1000ms fadeIn` }}
        >
          <div style={{ height: '40px' }}>
            <div className="basket">
              <div
                className={`basket-info${!basketBoolean || miniMode ? ' basket-info-no-send' : ''}`}
              >
                {this.props.miniMode ? null : <CartIconW />}
                {this.props.miniMode ? `Cart ` : `Cart ${basketBoolean > 4 ? 'Full' : ''} `}(
                <span>{basketBoolean}/3</span>)
              </div>
              {basketBoolean ? (
                <button
                  //button functions missing
                  className="basket-send-btn"
                  onClick={() => {
                    this.props.showModalConnect(PACKET_SEND_MODAL, {})
                  }}
                >
                  Send
                </button>
              ) : null}
            </div>
          </div>

          <Collapsible
            trigger={
              <div className={basketBoolean ? 'basket-control visible' : 'hidden'}>
                <h1>SHOW</h1>
              </div>
            }
            triggerWhenOpen={
              <div className={basketBoolean ? 'basket-control visible' : 'hidden'}>
                <h1>HIDE</h1>
              </div>
            }
            transitionTime={200}
          >
            <div className="basket-items visible">
              {basket &&
                basket.map((vehicle, i) => (
                  <div
                    className="basket-item"
                    onClick={e => {
                      selectVehicle && selectVehicle(i)
                    }}
                  >
                    <Link
                      to={{
                        pathname: `/packet/view/${vehicle.vehicle_id}`,
                        state: vehicle.vehicle_id,
                      }}
                    >
                      <div className="basket-item-img">
                        {' '}
                        <img alt={vehicle.make_model} src={vehicle.thumbnail_url} />
                      </div>
                      <div className="basket-item-info">
                        {`
                            ${vehicle.year}
                            ${vehicle.make}
                            ${vehicle.model}
                          `}
                        <p>{vehicle.vin}</p>
                      </div>
                    </Link>
                    <div
                      className="basket-item-remove"
                      key={vehicle.vehicle_id}
                      onClick={() => {
                        updateBasketConnect(vehicle)
                      }}
                    >
                      <IconCancel />
                    </div>
                  </div>
                ))}
              <div
                className={basketBoolean ? 'basket-remove-all visible' : 'hidden'}
                onClick={emptyBasketConnect}
              >
                REMOVE ALL
              </div>
            </div>
          </Collapsible>
        </div>
        {basketBoolean ? <div className="basket-container-cleared"></div> : <React.Fragment />}
      </React.Fragment>
    )
  }
}

Basket.propTypes = {
  basket: PropTypes.array,
  updateBasketConnect: PropTypes.func,
  emptyBasketConnect: PropTypes.func,
  showModalConnect: PropTypes.func,
  selectVehicle: PropTypes.func,
}

const mapStateToProps = state => ({
  basket: state.inventory.basket,
})
export default connect(mapStateToProps, {
  updateBasketConnect: updateBasket,
  emptyBasketConnect: emptyBasket,
  showModalConnect: showModal,
})(Basket)
