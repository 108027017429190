// general: notification modal.

// @flow
import React from 'react'
// node packages
import { connect } from 'react-redux'
// components
import { hideModal } from '../../../core/_actions/modalActions'
import Modal from '../../layouts/modal'

type Props = {
  title: string,
  afterClose: boolean,
  hideModal: boolean,
}

const NotificationModal = ({ title, afterClose }) => {
  // eslint-disable-next-line no-unused-vars
  const onClose = (props: Props) => {
    hideModal()

    if (afterClose) {
      afterClose()
    }
  }

  return (
    <Modal title={title}>
      <button onClick={onClose}>Ok</button>
    </Modal>
  )
}

export default connect(null, { hideModal })(NotificationModal)
