// general:

// @flow
import React, { Component } from 'react'
// node packages
// --- redux
import { connect } from 'react-redux'
import { packetViewActive } from '../../../core/_actions/packetviewActions'
import { stashSingleModules } from '../../../core/_actions/packetstashActions'
// helpers
import { activeNormalButtonStyle, hoverNormalButtonStyle } from '../../helpers/reusables'
import IconExternalLink from '../../../core/assets/images/icons/icon-external-link'

// css styles
import '../../styles/universalstyles.scss'
import '../styles/packetsend.scss'

type Props = {
  type: string,
  packet_modules: Object,
  packetViewActive: Function,
  stashSingleModules: Function,
}

class PacketModules extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      packet_modules: this.props.packet_modules,
      type: this.props.type,
      clicked: false,
      clickedId: null,
      hover: false,
      hoverId: null,
    }
  }

  state: {
    packet_modules: Object,
    clicked: boolean,
    clickedId: number,
    hover: boolean,
    hoverId: number,
    type: string,
  }

  // componentDidMount
  // - set mount to true.
  componentDidMount() {
    this.mounted = true
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({ type: newProps.type, packet_modules: newProps.packet_modules })
  }

  // componentWillUnmount
  // - resets all values and states.
  componentWillUnmount() {
    this.mounted = false
    this.setState({
      packet_modules: [],
      clicked: false,
      clickedId: null,
      hover: false,
      hoverId: null,
      type: '',
    })
  }

  props: Props

  render() {
    // states
    const { packet_modules, type } = this.state

    if (packet_modules && packet_modules.length > 0) {
      const { clicked, clickedId, hover, hoverId, type } = this.state

      // style
      let buttonHoverStyle = { alignItems: 'flex-start' }

      // counters
      let counter = 0

      return (
        <div align="center" style={{ width: '100%' }}>
          {packet_modules &&
            packet_modules.map(d => {
              if (hover && hoverId === d.module_id) {
                buttonHoverStyle = { alignItems: 'flex-start' }
              } else if (clicked && clickedId === d.module_id && type === 'module') {
                buttonHoverStyle = { alignItems: 'flex-start' }
              } else {
                buttonHoverStyle = { alignItems: 'flex-start' }
              }
              // console.log("D");
              // console.log(d)
              if (d.content_type === '3pa') {
                return (
                  <button
                    className={
                      `packetSendButtonsStyle ` +
                      (type == 'module' && clickedId === d.module_id ? `activeModule` : '')
                    }
                    onClick={() => {
                      window.open(d.url)
                    }}
                    title={d.label}
                    key={counter++}
                    style={buttonHoverStyle}
                  >
                    <div className="packetSendButtonsTitleStyle">{d.label}</div>
                    <div className="packetSendButtonsLinkIcon">
                      <IconExternalLink />
                    </div>
                  </button>
                )
              }
              return (
                <button
                  className={
                    `packetSendButtonsStyle ` +
                    (type == 'module' && clickedId === d.module_id ? `activeModule` : null)
                  }
                  title={d.label}
                  key={counter++}
                  style={buttonHoverStyle}
                  onClick={() => {
                    this.setState({ clicked: true, clickedId: d.module_id })
                    this.props.packetViewActive('module')
                    this.props.stashSingleModules(d)
                  }}
                >
                  <div className="packetSendButtonsTitleStyle">{d.label}</div>
                </button>
              )
            })}
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  type: state.packetview.view_type,
  packet_modules: state.packetstash.module_info,
})
export default connect(mapStateToProps, { packetViewActive, stashSingleModules })(PacketModules)
