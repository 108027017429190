// general: all universal and reocurring items are contained here.

// ***********************REOCURRING*SETTINGS***********************
export const ReactTableOptionsStatic = {
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,

  showPageSizeOptions: false,
  pageSizeOptions: [5, 10, 20, 25, 50, 100],
  showPageJump: false,
  defaultPageSize: 15,

  defaultSortDesc: true,
  multiSort: false,
  resizable: false,
  filterable: false,
  manual: true,

  previousText: 'Previous',
  nextText: 'Next',
  noDataText: 'No results found.',
  pageText: null,
  ofText: 'of',
  rowsText: 'rows',
  minRows: 15,

  collapseOnSortingChange: false,
  collapseOnPageChange: false,
  collapseOnDataChange: false,
  freezeWhenExpanded: false,
}
// ***********************REOCURRING*SETTINGS***********************

// ***********************REOCURRING*STYLE*OBJECTS***********************
export const activeNormalButtonStyle = {
  backgroundImage: 'linear-gradient(#fff, #fff)',
  backgroundColor: 'white',
  border: '1px solid #EEB21E',
  color: '#EEB21E',
  textDecoration: 'none',
}
export const hoverNormalButtonStyle = {
  backgroundColor: 'rgb(170, 170, 170)',
  color: 'black',
  border: '1px solidblack',
  transition: 'background-color .25s',
  textDecoration: 'none',
}

// ---
export const activeSendButtonStyle = {
  backgroundImage: 'linear-gradient(#fff, #fff)',
  backgroundColor: 'white',
  border: '1px solid #EEB21E',
  color: '#EEB21E',
  textDecoration: 'none',
}

// ---
export const sendNormalButtonStyle = {
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '0.85em',
}
export const sendSendButtonStyle = {
  backgroundColor: '#EEB21E',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '0.85em',
}
// ***********************REOCURRING*STYLE*OBJECTS***********************
