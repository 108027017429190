// general: 1/6 of the admin component - child of adminsettings - (5th).

// @flow
import React, { Component } from 'react'
// node packages
import Collapsible from 'react-collapsible'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
// --- redux
import { connect } from 'react-redux'
import AdminActions from '../../../core/actions/users/adminactions'
import { showModal, hideModal, deleteModalAction } from '../../../core/_actions/modalActions'
import { EmptyDeleteModalActionConst } from '../../../core/_actions/emptystoreActions'
import { DELETE_MODAL, SCHEDULED_REPORTS_MODAL } from '../../../core/_constants/modalTypes'
// Components
import LoadingSpinner from '../../helpers/loadingspinner'
import Helper from '../../../core/helpers/platformhelper'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/adminsettings.scss'
// images
import IconTrash from '../../../core/assets/images/icons/icon-trash'
import ArrowDownIcon from '../../../core/assets/images/icons/arrow-down_icon'

type Props = {
  DJtoken: string,
  storeId: number,
  deleteEmailModal: boolean,
  deleteReportModal: Boolean,
  showModal: Function,
}

class AdminReporting extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      storeId: this.props.storeId,
      isLoading: true,
      recipientAddActive: false,
      activeRecipients: [],
      isFirstReportAdd: false,
      addEmailField: '',
      submissionRefused: false,
      refusalHint: '',
      deletemodal: this.props.deletemodal,
      emailToRemove: '',
      reportToRemove: null,
      entryID: null,
      scheduledReports: [],
      reportsCount: null,
    }
  }

  state: {
    storeId: number,
    emailToRemove: string,
    scheduledReports: array,
    reportToRemove: Number,
    reportsCount: Number,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      const { storeId } = this.state
      const { DJtoken } = this.props
      if (storeId !== null && storeId !== undefined) {
        this.onMountedGetReportRecipients(storeId)
        this.onMountedGetCustomScheduledReports(DJtoken)
      }
    }
  }

  componentWillReceiveProps(newProps: Props) {
    const { storeId, deletemodal } = newProps

    this.setState({ deletemodal })

    if (storeId !== null && storeId !== undefined) {
      if (storeId !== this.props.storeId) {
        this.setState({ storeId })
        this.onMountedGetReportRecipients(storeId)
        this.onMountedGetCustomScheduledReports(this.props.DJtoken)
      }
    }

    if (deletemodal && !this.state.reportToRemove) {
      this.RemoveEmailGood(this.state.emailToRemove)
      EmptyDeleteModalActionConst()
    }

    if (deletemodal && this.state.reportToRemove) {
      this.deleteReport(this.state.reportToRemove)
      EmptyDeleteModalActionConst()
    }
  }

  onMountedGetReportRecipients(storeId: number) {
    this.setState({ isLoading: true })
    AdminActions.getActivityReportRecipients(storeId, this.props.DJtoken)
      .then(json => {
        if (json.detail != undefined && json.detail == 'No Scheduled Reports for this store.') {
          this.setState({
            isFirstReportAdd: true,
            entryID: null,
            isLoading: false,
            activeRecipients: [],
          })
        } else if (json !== null && json !== undefined && json !== '') {
          this.setState({ isLoading: false })
          const topResult = json.results[0]
          // booleans
          const emailBoolean =
            topResult.email !== null && topResult.email !== undefined && topResult.email !== ''
          if (emailBoolean) {
            this.setState({
              isFirstReportAdd: false,
              activeRecipients: topResult.email.split('|'),
              entryID: topResult.id,
            })
          } else {
            this.setState({ isFirstReportAdd: false, activeRecipients: [], entryID: topResult.id })
          }
        }
      })
      .catch(error => {
        console.log(error.toString())

        this.setState({ isLoading: false })
      })
  }

  onMountedGetCustomScheduledReports(token) {
    this.setState({ isLoading: true })
    AdminActions.getCustomScheduledReports(token)
      .then(json => {
        if (json !== null && json !== undefined) {
          if (json.count == 0) {
            this.setState({
              reportsCount: json.count,
            })
          } else {
            this.setState({
              scheduledReports: json.results.reverse(),
              reportsCount: json.count,
            })
          }
        }
        this.setState({ isLoading: false })
      })
      .catch(error => {
        console.log(error.toString())
        this.setState({ isLoading: false })
      })
  }

  adminAddReportRecipient() {
    return (
      <div id="add_email_mssg">
        Enter a valid email address and click save to add a recipient to your Activity Report
        mailing list.
        <br />
        <input
          type="text"
          name="recipientAdd"
          placeholder="address@example.com"
          className="form-control-override"
          value={this.state.addEmailField}
          onChange={this.handleChange.bind(this)}
        />
        {this.errorFeedback()}
        <button onClick={() => this.submitAddEmail()}>Save</button>
      </div>
    )
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  handleChange(event) {
    this.setState({ addEmailField: event.target.value })
  }

  submitAddEmail() {
    const { activeRecipients } = this.state
    if (this.validateEmail(this.state.addEmailField)) {
      // validate that email isn't duplicate
      if (activeRecipients.indexOf(this.state.addEmailField) == -1) {
        // validation success, move on to submit
        let updateEmailString
        if (activeRecipients.length > 0 && activeRecipients[0] != '') {
          updateEmailString = `${activeRecipients.join('|')}|${this.state.addEmailField}`
        } else {
          updateEmailString = this.state.addEmailField
        }
        this.updateEmailsWithAPI(updateEmailString)
      } else {
        this.setState({
          submissionRefused: true,
          refusalHint: 'This e-mail is already subscribed.',
        })
      }
    } else {
      this.setState({
        submissionRefused: true,
        refusalHint: 'Please enter a valid e-mail address.',
      })
    }
  }

  // - remove a subscribed email from the list (modal)
  removeEmail(emailToRemove: string) {
    if (!this.state.deleteEmailModal) {
      this.setState({ emailToRemove })
      this.props.showModal(DELETE_MODAL, { removeItem: emailToRemove })
    }
  }

  // - remove a subscribed email from the list
  RemoveEmailGood(emailToRemove: string) {
    const emailsWithRemoved = this.state.activeRecipients.filter((value, index, arr) => {
      return value !== emailToRemove
    })
    const updateEmailString = emailsWithRemoved.join('|')
    this.updateEmailsWithAPI(updateEmailString)
  }

  updateEmailsWithAPI(emailString) {
    this.setState({ addEmailField: '' })
    if (this.state.isFirstReportAdd) {
      // we use 0 for the ID when a record isn't given to us on load
      AdminActions.createActivityReportRecipients(
        0,
        this.state.storeId,
        emailString,
        this.props.DJtoken,
      )
        .then(json => {
          if (json.detail != undefined && json.detail == 'No Scheduled Reports for this store.') {
            this.setState({ activeRecipients: [], isFirstReportAdd: true })
          } else if (json !== null && json !== undefined && json !== '') {
            this.setState({
              isLoading: false,
              recipientAddActive: false,
              submissionRefused: false,
              entryID: json.id,
              isFirstReportAdd: false,
            })
            const emailBoolean = json.email !== null && json.email !== undefined
            if (emailBoolean) {
              this.setState({ isFirstReportAdd: false, activeRecipients: json.email.split('|') })
            } else {
              this.setState({ isFirstReportAdd: false, activeRecipients: [] })
            }
          }
        })
        .catch(error => {
          console.log(error.toString())
          this.setState({ isLoading: false })
        })
    } else {
      AdminActions.updateActivityReportRecipients(
        this.state.entryID,
        emailString,
        this.props.DJtoken,
      )
        .then(json => {
          if (json.detail != undefined && json.detail == 'No Scheduled Reports for this store.') {
            this.setState({ activeRecipients: [], isFirstReportAdd: true })
          } else if (json !== null && json !== undefined && json !== '') {
            this.setState({
              isFirstReportAdd: false,
              isLoading: false,
              recipientAddActive: false,
              submissionRefused: false,
              entryID: json.id,
            })
            const emailBoolean = json.email !== null && json.email !== undefined
            if (emailBoolean) {
              this.setState({ isFirstReportAdd: false, activeRecipients: json.email.split('|') })
            } else {
              this.setState({ isFirstReportAdd: false, activeRecipients: [] })
            }
          }
        })
        .catch(error => {
          console.log(error.toString())
          this.setState({ isLoading: false })
        })
    }
  }

  // display a help tip if no emails are subscribed
  ifActiveRecipientsEmpty() {
    const { activeRecipients } = this.state
    if (activeRecipients.length == 0 || activeRecipients[0] == '') {
      return (
        <div className="infoBlock">
          <span>There are no recipients for this location. Add them using the button below.</span>
        </div>
      )
    }
  }

  // display subscribed emails in a list
  adminReportRecipientsContainer() {
    const { activeRecipients, recipientAddActive } = this.state

    const array = []
    const table = (
      <table className="report-recipients-table">
        <tbody>{array}</tbody>
      </table>
    )
    let counter = 0

    if (activeRecipients && activeRecipients.length > 0 && activeRecipients[0] != '') {
      for (const entry of activeRecipients) {
        array.push(
          <tr className="list-item chip" key={counter++}>
            <td className="list-item-content">
              <span className="list-item-value">{entry}</span>
            </td>

            <td className="list-item-actions">
              <button
                className="btn btn-avoid"
                onClick={() => {
                  // storeId: number, franchiseId: number, token: string
                  this.removeEmail(entry)
                }}
              >
                <IconTrash />
              </button>
            </td>
          </tr>,
        )
      }
    }

    return (
      <div align="center" className="report-recipients-table-container">
        <hr style={{ width: '80%', maxWidth: '500px' }} />
        <div>
          AutoiPacket generates both a weekly and monthly email report that gives you information on
          how your dealership is using AutoiPacket.
          <br />
          <br />
          {/* TODO: update or remove this section later for when recipients are different between reports */}
          You can change the recipients of these reports here.
        </div>
        {table}
        {this.ifActiveRecipientsEmpty()}
        <button
          id="add_button"
          onClick={() => {
            this.setState({ recipientAddActive: !recipientAddActive })
          }}
          className={recipientAddActive ? '' : ''}
        >
          {recipientAddActive ? 'Cancel' : 'Add Recipient'}
        </button>
        {recipientAddActive ? this.adminAddReportRecipient() : null}
      </div>
    )
  }

  noReportsToDisplay() {
    return (
      <div className="no-reports">
        <span>No Reports to Display</span>
      </div>
    )
  }

  // fx to change number weekday value to word
  weekday(dayNumber) {
    let day
    if (dayNumber == '1') {
      day = 'Sunday'
    } else if (dayNumber == '2') {
      day = 'Monday'
    } else if (dayNumber == '3') {
      day = 'Tuesday'
    } else if (dayNumber == '4') {
      day = 'Wednesday'
    } else if (dayNumber == '5') {
      day = 'Thursday'
    } else if (dayNumber == '6') {
      day = 'Friday'
    } else {
      day = 'Saturday'
    }
    return day
  }

  // display Custom Scheduled Reports Section
  adminCustomScheduledReportsContainer(report) {
    const triggerText = (
      <span>
        <div>
          <ArrowDownIcon /> {report.subject}
        </div>
        <div>
          {report.scheduled_cron_task.day_of_week == '*'
            ? `Monthly on the ${Helper.ordinal(report.scheduled_cron_task.day_of_month)}`
            : `Weekly on ${this.weekday(report.scheduled_cron_task.day_of_week)}s`}
        </div>
      </span>
    )
    return (
      <div key={report.id}>
        <Collapsible trigger={triggerText} transitionTime={150}>
          <div className="report-info-div">
            <div>
              <span>
                <u>Created</u>: {`${moment(report.date_created).format('MMMM Do, YYYY')}`}
              </span>
              <span>
                <u>Updated</u>: {`${moment(report.date_modified).format('MMMM Do, YYYY')}`}
              </span>
            </div>
            {/* TODO: this will be here for when recipients are different between reports */}
            {/* <div>
              <span>
                <u>Recipients</u>:
              </span>
              <span>{this.state.activeRecipients.join(', ')}</span>
            </div> */}
            <div>
              <span>
                <u>Message</u>:
              </span>
              {report.message}
            </div>
          </div>
          <div className="modify-report-btns">
            <button
              onClick={() => {
                this.ScheduledReportsShowModal(
                  this.props.userId,
                  this.state.storeId,
                  true,
                  false,
                  'Edit Scheduled Report',
                  report,
                )
              }}
            >
              Edit
            </button>
            <button
              onClick={() => {
                this.deleteReportModal(report)
              }}
            >
              Delete
            </button>
          </div>
        </Collapsible>
      </div>
    )
  }

  ScheduledReportsShowModal(
    userId: number,
    storeId: number,
    userEdit: boolean,
    userAdd: boolean,
    title: string,
    report: Object,
  ) {
    this.props.showModal(SCHEDULED_REPORTS_MODAL, {
      title,
      user_id: userId,
      store_id: this.state.storeId,
      user_edit: userEdit,
      user_add: userAdd,
      token: this.props.DJtoken,
      isAdmin: userId === this.props.userId,
      recipients: this.state.activeRecipients,
      report,
    })
  }

  deleteReportModal(report) {
    this.setState({ reportToRemove: report.id })
    if (!this.state.deletemodal) {
      this.props.showModal(DELETE_MODAL, { removeItem: report ? report.subject : 'This Report' })
    }
  }

  deleteReport(reportId: number) {
    const { DJtoken } = this.props
    const scheduledReportsMinusDeleted = this.state.scheduledReports.filter((value, index, arr) => {
      return value.id !== this.state.reportToRemove
    })
    this.setState({ scheduledReports: scheduledReportsMinusDeleted })
    AdminActions.deleteCustomScheduledReport(reportId, DJtoken)
      .then(() => {
        this.setState({ reportToRemove: null })
      })
      .catch(error => {
        console.log(error.toString())
        this.setState({ reportToRemove: null })
        toast('An error occurred, please try again later.')
      })
  }

  errorFeedback() {
    if (this.state.submissionRefused) {
      return (
        <div className="error_box">
          <p>{this.state.refusalHint}</p>
        </div>
      )
    }
  }

  props: Props

  render() {
    const loadingContainer = (
      <div align="center" className="white_box">
        <LoadingSpinner loading wheel />
      </div>
    )

    if (this.state.storeId !== null && this.state.storeId !== undefined) {
      if (this.state.isLoading) {
        return loadingContainer
      }
      // no current activeRecipients
      return (
        <div align="center" className="white_box" id="reporting-box">
          <h2>Reporting</h2>
          {this.adminReportRecipientsContainer()}
          {this.state.isLoading && <LoadingSpinner loading wheel />}
          {this.state.reportsCount == 0 ? (
            this.noReportsToDisplay()
          ) : this.state.reportsCount > 0 ? (
            this.state.scheduledReports.map((report, i) =>
              this.adminCustomScheduledReportsContainer(report, i),
            )
          ) : (
            <LoadingSpinner loading wheel />
          )}
          <button
            className="add-new-report-btn"
            onClick={() => {
              this.ScheduledReportsShowModal(
                this.props.userId,
                this.state.storeId,
                false,
                true,
                'Create Scheduled Report',
              )
            }}
          >
            Add Custom Scheduled Report
          </button>
        </div>
      )
    }
    return loadingContainer
  }
}

const mapStateToProps = state => ({
  deletemodal: state.packetstash.deletemodal,
})
export default connect(mapStateToProps, { hideModal, showModal, deleteModalAction })(AdminReporting)
