import React, { Component } from 'react'
import { getFromURL } from '../../../../core/actions/packets/packetactions'
import LoadingSpinner from '../../../helpers/loadingspinner'
import moment from 'moment-timezone'
import '../../styles/ratemodule.scss'

class RateModule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rateInfo: null,
      loading: true,
    }
  }
  componentDidMount() {
    const { url, token } = this.props
    if (url) {
      getFromURL({ token, url }).then(res => {
        if (res.cpo_id) {
          this.setState({ rateInfo: res, loading: false })
        } else {
          this.setState({ loading: false })
        }
      })
    }
  }
  render() {
    const { loading, rateInfo } = this.state
    if (loading) {
      return (
        <div>
          <LoadingSpinner loading={true} />
        </div>
      )
    } else if (!loading && !rateInfo) {
      return <div>Failed to Load Content</div>
    }
    return (
      <div
        className="rate-module-container"
        style={{ backgroundImage: `url(${rateInfo.finance_image})`, backgroundSize: 'cover' }}
      >
        <div className="rate-module-overlay">
          <div className="rate-module-title">
            <span>{`${rateInfo.make} ${rateInfo.model}`}</span>
            <br />
            {`${rateInfo.begin_model_year} - ${rateInfo.end_model_year}`}
          </div>
          As low as <span>{rateInfo.month_24_36}</span> for <span>24-36</span> months.
          <br />
          As low as <span>{rateInfo.month_37_48}</span> for <span>37-48</span> months.
          <br />
          As low as <span>{rateInfo.month_49_66}</span> for <span>49-66</span> months.
          <br />
          <span>Effective Start Date: </span>
          {moment(rateInfo.effective_start_date).format('MM/DD/YYYY')}
          <br />
          <span>Effective End Date: </span>
          {moment(rateInfo.effective_end_date).format('MM/DD/YYYY')}
        </div>
      </div>
    )
  }
}
export default RateModule
