// node modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import format from 'date-fns/format'
import Collapsible from 'react-collapsible'
// Components
import TabNav from './components/tabnav'
import SideBar from './components/overviewsidebar'
import AuditInfo from './components/auditinfo'
import StatisticsGraph from './components/statisticsgraph'
import StoreHealth from './components/storehealth'
import Modules from './components/modules'
import LoadingSpinner from '../helpers/loadingspinner'
// icons
import CheckCircleIcon from '../../core/assets/images/icons/check-circle-icon'
import IconX from '../../core/assets/images/icons/icon-x'
import IconLocation from '../../core/assets/images/icons/icon-location'
// data
import actions from '../../core/actions/users/adminactions'
// styles
import './styles/overview.scss'

class Overview extends Component {
  constructor(props) {
    super(props)
    // eslint-disable-next-line no-undef
    const URLStoreId = window.location.href.split('overview/')[1]
    let storeIdToUse
    if (URLStoreId !== undefined) {
      storeIdToUse = URLStoreId
    } else {
      storeIdToUse = this.props.storeId
    }
    this.state = {
      // title
      storeId: storeIdToUse,
      storeName: null,
      storeType: null,
      storeStreet1: null,
      storeStreet2: null,
      storeCity: null,
      storeState: null,
      storeZip: null,
      companyId: null,
      companyName: null,
      // info
      storeStatus: null,
      storeModified: null,
      storeCreated: null,
      storeDMSActive: null,
      storeDMSName: null,
      imageSource: null,
      infoLoadedData1: null,
      infoLoadedData2: null,
      infoLoadedData3: null,
      infoLoadedData4: null,
      // misc
      graphLoaded: false,
      healthLoaded: false,
      documentHeight: document.documentElement.scrollHeight,
    }
    this.statsRef = React.createRef()
    this.auditRef = React.createRef()
    this.moduleRef = React.createRef()
    this.handlePageJumpClick = this.handlePageJumpClick.bind(this)
    this.checkSideBar = this.checkSideBar.bind(this)
  }

  async componentDidMount() {
    this.onMountGetStoreDetail(this.state.storeId)
  }

  componentWillUnmount() {
    this.setState({
      // title
      storeId: null,
      storeName: null,
      storeType: null,
      companyName: null,
      companyId: null,
      storeStreet1: null,
      storeStreet2: null,
      storeCity: null,
      storeState: null,
      storeZip: null,
      // info
      storeStatus: null,
      storeModified: null,
      storeCreated: null,
      storeDMSActive: null,
      storeDMSName: null,
      imageSource: null,
      infoLoadedData1: null,
      infoLoadedData2: null,
      infoLoadedData3: null,
      infoLoadedData4: null,
      // misc
      graphLoaded: false,
      healthLoaded: false,
    })
  }

  // When the start date for store health is changed, update the state accordingly
  handleChangeStart = date => {
    this.setState({
      // state to be used with datepicker lib display
      startDate: date,
      // formatted state to be later sent to api
      dbstartDate: format(date, 'yyyy-MM-dd'),
    })
  }
  // When the end date for store health is changed, update the state accordingly
  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      dbEndDate: format(date, 'yyyy-MM-dd'),
    })
  }

  // Set loading state of graph to send to health component
  onGraphLoaded = loaded => {
    this.setState({ graphLoaded: loaded })
  }
  // Set loading state of health to send to graph component
  onHealthLoaded = loaded => {
    this.setState({ healthLoaded: loaded })
  }
  onMountGetStoreDetail(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      actions.getStoreDetail(storeId, DJtoken).then(json => {
        console.log(`Store detail: ${JSON.stringify(json, null, 2)}`)
        this.setState(
          {
            // title
            storeName: json.store_full_name,
            storeType: `${json.store_type_id === 1 ? 'New' : 'Pre-Owned'}`,
            storeStreet1: json.store_street_one,
            storeStreet2: json.store_street_two,
            storeCity: json.store_city,
            storeState: json.store_state,
            storeZip: json.store_zip,
            companyId: json.company_id,
            // info
            storeStatus: `${json.active === true ? 'Active' : 'Inactive'}`,
            storeModified: json.date_modified,
            storeCreated: json.date_created,
            storeDMSActive: `${json.dms_id.active === true ? 'Active' : 'Inactive'}`,
            storeDMSName: json.dms_id.dms_name,
            storeInventorySource: json.inventory_source,
            infoLoadedData1: json,
          },
          this.onMountGetCompanyName(json.company_id),
        )
      })

      // for image source data
      actions.getAdminOverview(storeId, DJtoken).then(json => {
        console.log(`Admin overview: ${JSON.stringify(json)}`)
        this.setState({
          // info
          imageSource: json.settings.image_source,
          infoLoadedData2: json,
        })
      })
    }
  }

  // get store detail (title and info sections) data on mount if storeID is present, then set dependent states
  onMountGetCompanyName(companyId: number) {
    const { DJtoken } = this.props
    if (companyId !== null && companyId !== undefined) {
      actions.getCompanyInfo(companyId, DJtoken).then(json => {
        console.log(`Company: ${JSON.stringify(json)}`)
        this.setState({
          // title
          companyName: json.company_name,
          infoLoadedData3: json,
        })
      })
    }
  }

  //  TODO : Render the store name, type (pre-owned or new), and address
  renderTitleSection() {
    return (
      <div className="company-overview-header">
        <div className="title-store-title">
          <h1>
            {this.state.storeName ? this.state.storeName : 'Not Listed'}
            <span className="title-id">Store {this.state.storeId}</span>
          </h1>
          <h2>
            <p className="store-type">
              {this.state.storeType ? this.state.storeType : 'Store Type Not Listed'}
            </p>
            {this.state.companyName ? this.state.companyName : 'Company Not Listed'}
            <span className="title-id">Company {this.state.companyId}</span>
          </h2>
        </div>
        <div className="title-store-address">
          <div className="inline-flex">
            <div className="small-icon">
              <IconLocation color="#737373" />
            </div>
            <div>
              <p>
                {this.state.storeStreet1 ? this.state.storeStreet1 : ''}
                {this.state.storeStreet2 ? this.state.storeStreet2 : ''}
              </p>
              <p>
                {this.state.storeCity ? this.state.storeCity : ''},{' '}
                {this.state.storeState ? this.state.storeState : ''}{' '}
                {this.state.storeZip ? this.state.storeZip : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  // render info section
  renderInfoSection() {
    return (
      <div className="overview-info">
        <div className="info-status">
          <h2>Store Status</h2>
          <div className="text-container">
            <div>
              <div className="inline-flex">
                {this.state.storeStatus === 'Active' || this.state.storeStatus === null ? (
                  <div className="small-icon active">
                    <CheckCircleIcon />
                  </div>
                ) : (
                  <div className="small-icon">
                    <IconX />
                  </div>
                )}
                {this.state.storeStatus === 'Active' || this.state.storeStatus === null
                  ? 'Active'
                  : this.state.storeStatus}
              </div>
            </div>
            <p>
              <b>Last Modified:</b>{' '}
              {this.state.storeModified
                ? format(new Date(this.state.storeModified), 'MMM do, yyyy, h:mm a')
                : 'Unavailable'}
            </p>
            <p>
              <b>Created:</b>{' '}
              {this.state.storeCreated
                ? format(new Date(this.state.storeCreated), 'MMM do, yyyy, h:mm a')
                : 'Unavailable'}
            </p>
          </div>
        </div>
        <div className="info-DMS">
          <h2>Feeds</h2>
          <div className="text-container">
            <div>
              <div className="inline-flex">
                {this.state.storeDMSActive === 'Active' || this.state.storeDMSActive === null ? (
                  <div className="small-icon active">
                    <CheckCircleIcon />
                  </div>
                ) : (
                  <div className="small-icon">
                    <IconX />
                  </div>
                )}
                {this.state.storeDMSActive === 'Active' || this.state.storeDMSActive === null
                  ? 'Active'
                  : this.state.storeDMSActive}
              </div>
            </div>
            <p>
              <b>DMS:</b> {this.state.storeDMSName ? this.state.storeDMSName : 'Not Listed'}
            </p>

            <p>
              <b> Image Source: </b>{' '}
              {this.state.imageSource ? this.state.imageSource : 'Not Listed'}
            </p>
            <p>
              <b> Inventory Source: </b>{' '}
              {this.state.storeInventorySource ? this.state.storeInventorySource : 'Not Listed'}
            </p>
          </div>
        </div>
      </div>
    )
  }
  onCollapsibleOpen = () => {
    this.setState({ documentHeight: document.documentElement.scrollHeight })
  }
  handlePageJumpClick(refName) {
    switch (refName) {
      case 'stats':
        this.statsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        break
      case 'audit':
        this.auditRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        break
      case 'module':
        this.moduleRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        break
      default:
    }
  }
  renderPageJumper() {
    return (
      <div className="overview-page-jump">
        <button onClick={() => this.handlePageJumpClick('stats')}>
          <p>Health & Statistics</p>
        </button>
        <button onClick={() => this.handlePageJumpClick('audit')}>
          <p>Audits</p>
        </button>
        <button onClick={() => this.handlePageJumpClick('module')}>
          <p>Modules</p>
        </button>
      </div>
    )
  }

  checkSideBar(res) {
    this.state.infoLoadedData4 = res
  }

  render() {
    console.log('data1')
    console.log(this.state.infoLoadedData1)
    console.log('data2')
    console.log(this.state.infoLoadedData2)
    console.log('data3')
    console.log(this.state.infoLoadedData3)
    console.log('data4')
    console.log(this.state.infoLoadedData4)
    return (
      <React.Fragment>
        <TabNav />
        {this.state.graphLoaded && this.state.documentHeight > 1200 ? this.renderPageJumper() : ''}
        {!this.state.infoLoadedData1 &&
        !this.state.infoLoadedData2 &&
        !this.state.infoLoadedData3 &&
        !this.state.infoLoadedData4 ? (
          <div className="loading-overview-container">
            <div className="loading-sidebar">
              <LoadingSpinner loading wheel />
            </div>
          </div>
        ) : (
          <div />
        )}
        <div>
          <div className="overview-container">
            {/* left nav */}
            <SideBar storeId={this.state.storeId} checkSideBar={this.checkSideBar} />
            {/* title */}
            {this.renderTitleSection()}
            <div className="main-section">
              {/* info */}
              {this.renderInfoSection()}
              {/* health */}
              <Collapsible
                trigger={
                  <span className="collapse-header" ref={this.statsRef}>
                    <h2>Store Health & Statistics</h2>
                    <button>Expand</button>
                  </span>
                }
                triggerWhenOpen={
                  <span className="collapse-header" ref={this.statsRef}>
                    <h2>Store Health & Statistics</h2>
                    <button>Collapse</button>
                  </span>
                }
                transitionTime={150}
                open={true}
              >
                <StoreHealth
                  storeId={this.state.storeId}
                  token={this.props.DJtoken}
                  graphLoaded={this.state.graphLoaded}
                  onHealthLoaded={this.onHealthLoaded}
                />
                {/* graph */}
                {this.state.storeCreated !== null ? (
                  <StatisticsGraph
                    storeId={this.state.storeId}
                    token={this.props.DJtoken}
                    creationDate={this.state.storeCreated}
                    healthLoaded={this.state.healthLoaded}
                    onGraphLoaded={this.onGraphLoaded}
                  />
                ) : (
                  ''
                )}
              </Collapsible>
              {/* audit */}
              <Collapsible
                trigger={
                  <span className="collapse-header" ref={this.auditRef}>
                    <h2>Audits</h2>
                    <button>Expand</button>
                  </span>
                }
                onOpen={this.onCollapsibleOpen}
                onClose={this.onCollapsibleOpen}
                triggerWhenOpen={
                  <span className="collapse-header" ref={this.auditRef}>
                    <h2>Audits</h2>
                    <button>Collapse</button>
                  </span>
                }
                transitionTime={150}
              >
                <AuditInfo storeId={this.state.storeId} />
              </Collapsible>
              {/* modules */}
              <Collapsible
                trigger={
                  <span className="collapse-header" ref={this.moduleRef}>
                    <h2>Modules</h2>
                    <button>Expand</button>
                  </span>
                }
                onOpen={this.onCollapsibleOpen}
                onClose={this.onCollapsibleOpen}
                triggerWhenOpen={
                  <span className="collapse-header" ref={this.moduleRef}>
                    <h2>Modules</h2>
                    <button>Collapse</button>
                  </span>
                }
                transitionTime={150}
              >
                <Modules storeId={this.state.storeId} token={this.props.DJtoken} />
              </Collapsible>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// Get the store id and token for all api calls
const mapStateToProps = state => ({
  storeId: state.authentication.user.user.profile.legacy_user.default_store_id,
  adminState: state.reactAdmin,
  DJtoken: state.authentication.user.token,
})
export default connect(mapStateToProps, {})(Overview)
