import { TOGGLE_PRIVACY } from '../_constants/helperTypes'

function togglePrivacy() {
  return function(dispatch) {
    dispatch({
      type: TOGGLE_PRIVACY,
    })
  }
}
export { togglePrivacy }
