// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { updateFacet } from '../../../core/actions/inventoryactions.js'
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import IconLocation from '../../../core/assets/images/icons/icon-location.js'

type Props = {} // none.

class ShowLocationComponent extends PureComponent {
  updateCheckField = () => {
    let {
      facetFields,
      activeFacetFields,
      facetOptions,
      activeFacetOptions,
      updateFacetConnect,
      user_id,
      token,
      sortBy,
      viewMyInventory,
      searchQuery,
    } = this.props
    updateFacetConnect({
      facetIndex: viewMyInventory.facet_index,
      optionIndex: viewMyInventory.option_index,
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery,
    })
  }

  // code to hot reload 'View My Inventory' when default store changed
  // Unfinished:
  //componentDidUpdate(prevProps) {
  //const { activeFilters, userInfo, defaultStoreID } = this.props
  //const active = activeFilters.locations.includes(prevProps.defaultStoreID.toString())
  //if (prevProps.defaultStoreID != this.props.defaultStoreID && active) {
  //  this.handleShowLocation()
  //}
  //}
  render() {
    const { viewMyInventory, facetFields, basket } = this.props
    const basketBoolean = basket && basket.length

    if (viewMyInventory && viewMyInventory.facet_index > -1) {
      const active = facetFields[viewMyInventory.facet_index].includes(viewMyInventory.option_index)
      return (
        <div
          className={
            'show-location' +
            (active ? ' show-location-active ' : ' ') +
            (basketBoolean ? ' fixed' : '')
          }
          onClick={this.updateCheckField}
          style={active ? { color: '#424347' } : { color: '#424347' }}
        >
          <input type="checkbox" checked={active} readOnly /> View My Inventory{' '}
          <IconLocation color={active ? '#EEB21E' : '#EEB21E'} />
        </div>
      )
    }
    return <React.Fragment />
  }
}
const mapStateToProps = state => ({
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  activeFacetOptions: state.inventory.activeFacetOptions,
  user_id:
    state.authentication.user && state.authentication.user.user.profile.legacy_user.legacy_user_id,
  token: state.authentication.user && state.authentication.user.token,
  results: state.inventory.inventoryContent && state.inventory.inventoryContent.count,
  isLoading: state.inventory.isLoading,
  sortBy: state.inventory.sortBy,
  facetCheckIn: state.inventory.checkIn.GET_FACET_OPTIONS,
  viewMyInventory: state.inventory.viewMyInventory,
  basket: state.inventory.basket,
  searchQuery: state.inventory.searchQuery,
})
const ShowLocation = UserInfoWrapper(ShowLocationComponent)

export default connect(mapStateToProps, {
  updateFacetConnect: updateFacet,
})(ShowLocation)
