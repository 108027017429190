import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import AIPLogo from '../../../../core/assets/images/iPacketLogo(black).png'
import PhoneImage from '../../../../core/assets/images/Empty_iPhone.png'
import AIPRingLogo from '../../../../core/assets/images/AutoiPacketYloRingDrk.png'
import '../../styles/qrcodeprint.scss'

// eslint-disable-next-line react/prefer-stateless-function
class QRCodePrint extends Component {
  render() {
    const { vehicle, type, size } = this.props
    let url = `https://www.ipacket.info/`
    if (window.location.hostname !== 'dpapp.autoipacket.com') {
      url = 'http://dev.carpacket.info/'
    }
    url += vehicle.vin
    url += '?source=aipdpqrc'
    if (type === 'simple') {
      return (
        <div
          className="qrcode-simple-container"
          style={{ position: `absolute`, width: '100%', height: '100%' }}
        >
          <div className={`qrcode-print-code s-${size}`}>
            <QRCode
              size={size}
              imageSettings={{
                src: AIPRingLogo,
                x: null,
                y: null,
                height: 0,
                width: 0,
                excavate: true,
                alt: 'qr-print-code',
              }}
              value={url}
              alt="qr-print-code"
            />
          </div>
        </div>
      )
    } else if (type === 'window sticker') {
      return (
        <div
          className="qrcode-window-sticker-container"
          style={{ position: `absolute`, width: '100%', height: '100%' }}
        >
          <div className="qrcode-window-sticker-inner-container">
            <div className={`qrcode-print-code ws-${size}`}>
              <div className="header-text">
                <p>SCAN QR CODE TO VIEW</p>
                <p>VEHICLE RECORDS</p>
              </div>
              <QRCode
                size={size * 0.5}
                imageSettings={{
                  src: AIPRingLogo,
                  x: null,
                  y: null,
                  height: 0,
                  width: 0,
                  excavate: true,
                  alt: 'qr-print-code',
                }}
                value={url}
                alt="qr-print-code"
              />
            </div>
          </div>
        </div>
      )
    }
    // else return the default
    return (
      <div className="qrcode-default-container">
        <div className="qrcode-print-heading">
          SCAN QR CODE TO VIEW
          <br />
          VEHICLE RECORDS
        </div>
        <div
          className="qrcode-print-code"
          style={{ backgroundImage: `url(${PhoneImage})`, backgroundSize: 'cover' }}
          title="QR Code Print"
        >
          <QRCode
            className="complex-qr"
            size={300}
            imageSettings={{
              src: AIPRingLogo,
              x: null,
              y: null,
              height: 0,
              width: 0,
              excavate: true,
            }}
            value={url}
            alt="qr-print-code"
          />
        </div>
        <div className="qrcode-print-info">
          <div className="qrcode-print-info-left">
            <h1>{`${vehicle.is_cpo ? 'Certified Pre-Owned ' : ''}${vehicle.year} ${vehicle.make} ${
              vehicle.model
            } ${vehicle.engine_description ? vehicle.engine_description : ''}`}</h1>
          </div>
          <div className="qrcode-print-info-right">
            {`VIN: ${vehicle.vin ? vehicle.vin : ''}`}
            <br />
            {`STOCK#: ${vehicle.stock ? vehicle.stock : ''}`}
            <br />
            {`${
              (vehicle.distance_unit && vehicle.miles) !== null && vehicle.distance_unit.label
                ? vehicle.distance_unit.label.toUpperCase()
                : 'MILES'
            }: ${vehicle.miles ? vehicle.miles : ''}`}
          </div>
        </div>
        <div className="qrcode-print-footer">
          <img src={AIPLogo} alt="qr code" />
          <br />© {new Date().getFullYear()} AutoIPacket, LLC® All rights reserved.
        </div>
      </div>
    )
  }
}

export default QRCodePrint
