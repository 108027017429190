// general: redux action file for all things 'packet' data being stashed.

// constants
import {
  STASH_VIEWED_CUSTOMER,
  CLEAR_VIEWED_CUSTOMER,
  STASH_TABLE_STATE,
} from '../_constants/customerTypes'

// stashVehicles
function stashViewedCustomer(customer: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_VIEWED_CUSTOMER, viewed_customer: customer })
  }
}
function clearViewedCustomer(customer: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_VIEWED_CUSTOMER, viewed_customer: null })
  }
}

// stashVehicleImages
function stashCustomerTableState(table_state: Object) {
  return function(dispatch: boolean) {
    dispatch({
      type: STASH_TABLE_STATE,
      table_state: table_state,
    })
  }
}

/*
// stashPrice
function stashPrice(price: string) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_PRICE, price })
  }
}

// stashRecipients
function stashRecipients(array: Object, object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_RECIPIENTS, recipient_ids: array, recipient_info: object })
  }
}

// stashMessage
function stashMessage(message: string) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_MESSAGE, message })
  }
}

// stashModules
function stashModules(array: Object, object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_MODULES, module_ids: array, module_info: object })
  }
}

// stashSingleModules
function stashSingleModules(object: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_SINGLE_MODULE, single_module_info: object })
  }
}

// stashAttachments
function stashAttachments(array: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_ATTACHMENTS, attachment_files: array })
  }
}

// stashLeads
function stashLeads(type: string) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_LEADS, lead_source: type })
  }
}

// toggleCreatingCustomer
function toggleCreatingCustomer(is_creating_customer_val: boolean) {
  return function(dispatch: boolean) {
    dispatch({ type: IS_CREATING_CUSTOMER, is_creating_customer: is_creating_customer_val })
  }
}

// stashAdminCompanyStore
function stashAdminCompanyStore(default_store_id: number) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_DEFAULT_STORE_ID, default_store_id })
  }
}

// updateCustomersToSend
function updateCustomersToSend(customersToSend: Array) {
  return function(dispatch: boolean) {
    dispatch({ type: UPDATE_CUSTOMERS_TO_SEND, customers_to_send: customersToSend })
  }
}

// stashModuleVideos
function stashModuleVideos(module_videos: Object) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_MODULE_VIDEOS, module_videos })
  }
}

// deleteModalAction
function deleteModalAction(value: boolean) {
  return function(dispatch: boolean) {
    dispatch({ type: STASH_DELETE_MODAL, deletemodal: value })
  }
}
  */

export { stashViewedCustomer, clearViewedCustomer, stashCustomerTableState }
