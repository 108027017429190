import React from 'react'

const TopCustomers = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M38.78,7.88H13.21A2.19,2.19,0,0,0,11,10H8.84a2.12,2.12,0,1,0,0,4.24H11v2.18H8.84a2.12,2.12,0,1,0,0,4.24H11v2.18H8.84a2.12,2.12,0,1,0,0,4.24H11v2.17H8.84a2.13,2.13,0,0,0-2.12,2.12h0a2.12,2.12,0,0,0,2.12,2.12H11v2.18H8.84a2.12,2.12,0,0,0,0,4.24H11a2.18,2.18,0,0,0,2.18,2.17H38.78A2.19,2.19,0,0,0,41,39.94V10.06A2.19,2.19,0,0,0,38.78,7.88Z" />
      <path
        className="a"
        d="M28.12,25.29v-.9a4.26,4.26,0,1,0-4.28,0v.89a4.47,4.47,0,0,0-4.24,4.46v3a.79.79,0,0,0,.79.8H31.56a.8.8,0,0,0,.8-.8v-3A4.48,4.48,0,0,0,28.12,25.29Z"
      />
    </svg>
  )
}
export default TopCustomers
