// @flow
import React, { Component } from 'react'
// core files
import ToolsActions, { uploadDocuments } from '../../../core/actions/features/toolsactions'
// images
import AddFileIcon from '../assets/addfileicon.js'
import UploadIcon from '../assets/uploadicon.js'
import CancelIcon from '../assets/cancelicon.js'
import RefreshIcon from '../assets/refreshicon.js'
import CheckmarkAnimation from '../assets/checkmarkanimation.js'
import LoadingSpinner from '../../helpers/loadingspinner.js'
import { toast } from 'react-toastify'
//var hash = require('object-hash')

import FileDrop from 'react-file-drop'
import '../../styles/universalstyles.scss'
import '../styles/tools.scss'

import _ from 'lodash'

export default class DocumentUpload extends Component {
  mistake = message => {
    if (!toast.isActive(this.mistakeToastId) || this.mistakeToastMessage !== message) {
      this.mistakeToastId = toast(message)
      this.mistakeToastMessage = message
    }
  }
  constructor(props) {
    super(props)
    this.state = {
      docTypes: null,
      selectedDocType: -1,
      docs: [],
      dragging: true,
    }
  }
  onMountGetFileTypes() {
    // props
    const { storeId, DJtoken } = this.props

    if (storeId !== null && storeId !== undefined) {
      ToolsActions.getToolsDocUpload(storeId, DJtoken)
        .then(json => {
          if (json && json.length > 0) {
            this.setState({ docTypes: json })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  componentDidMount() {
    const { docTypes } = this.state
    if (!docTypes) {
      this.onMountGetFileTypes()
    }
  }
  updateDocType(index) {
    this.setState({ selectedDocType: index })
  }
  selectFiles(fileList) {
    let { docs, selectedDocType } = this.state
    //FileList is not a normally iterable object hence using lodash
    if (selectedDocType > -1) {
      _.map(fileList, file => {
        if (
          file.name
            .split('.')
            .pop()
            .toLowerCase() === 'pdf'
        ) {
          docs.push({ file, uploading: false, uploadFail: false, uploadSuccess: false })
        } else {
          this.mistake(`File "${file.name}" is not pdf or is improperly named`)
        }
      })
      docs = [...new Set(docs)]
      this.setState({ docs })
    }
    //this resets the file input after every file grab
    this.docDialog.value = ''
  }
  removeDoc(i) {
    let { docs } = this.state
    docs.splice(i, 1)
    this.setState({ docs })
  }
  removeAllDocs() {
    this.setState({ docs: [] })
  }
  uploadDoc(doc) {
    const { storeId, DJtoken } = this.props
    let { docs, docTypes, selectedDocType } = this.state
    if (!doc.uploadSuccess) {
      doc.uploading = true
      this.setState({ docs })
      uploadDocuments({
        token: DJtoken,
        storeId,
        file: doc.file,
        docType: docTypes[selectedDocType].module_id,
      }).then(res => {
        doc.uploading = false
        if (res && res.files && res.files[0].status === 'success') {
          doc.uploadSuccess = true
          toast(`${doc.file.name} successfully uploaded!`)
        } else {
          doc.uploadFail = true
        }
        this.setState({ docs })
      })
    }
  }
  uploadAllDocs() {
    const { docs, selectedDocType } = this.state
    if (selectedDocType > -1) {
      docs.map((doc, i) => {
        this.uploadDoc(doc)
      })
    }
  }
  handleFileDrop = (files, event) => {
    this.selectFiles(files)
  }
  handleDragEnter = event => {
    const { dragging } = this.state
    if (!dragging) {
      this.setState({ dragging: true })
    }
  }
  handleDragLeave = event => {
    const { dragging } = this.state
    if (dragging) {
      this.setState({ dragging: false })
    }
  }
  renderDocTypes() {
    const { docTypes, selectedDocType } = this.state
    if (docTypes && docTypes.length > 0) {
      let docOptions = []
      docOptions.push(
        <option disabled hidden key="default" style={{ display: 'none' }} value="">
          Select Document Type
        </option>,
      )
      docTypes.map((docType, index) => {
        docOptions.push(
          <option key={docType.label} value={index}>
            {docType.label}
          </option>,
        )
      })
      return (
        <select
          defaultValue={''}
          className={`doc-upload-select ${selectedDocType === -1 ? 'doc-upload-attention' : ''}`}
          onChange={e => {
            this.updateDocType(e.target.value)
          }}
        >
          {docOptions}
        </select>
      )
    }
  }
  renderUploadControls() {
    const { docs, selectedDocType } = this.state
    return (
      <div className="doc-upload-controls">
        <div
          onClick={() => {
            selectedDocType === -1 && this.mistake('No Document Type Selected')
            selectedDocType > -1 && this.docDialog.click()
          }}
          className={`doc-upload-control doc-upload-add ${
            selectedDocType === -1 ? 'doc-upload-disabled' : ''
          } ${selectedDocType > -1 && !docs.length ? 'doc-upload-attention' : ''}`}
        >
          <label style={{ display: 'none' }} htmlFor="doc-upload-input">
            Document Upload Input
          </label>
          <input
            id="doc-upload-input"
            className="doc-upload-input"
            type="file"
            accept=".pdf"
            multiple
            ref={ref => (this.docDialog = ref)}
            onChange={e => {
              this.selectFiles(e.target.files)
            }}
          />
          <div title="Add File" className="doc-upload-icon">
            <AddFileIcon color={`${selectedDocType === -1 ? '#d3d3d3' : 'white'}`} />
          </div>
          <div className={`doc-upload-title`}>Add Files</div>
        </div>
        <div
          onClick={() => {
            if (selectedDocType === -1) {
              this.mistake('No Document Type Selected')
            } else if (selectedDocType > -1 && docs.length < 1) {
              this.mistake('No Files Added')
            }
            this.uploadAllDocs()
          }}
          title="Upload File"
          className={`doc-upload-control doc-upload-start ${
            selectedDocType === -1 || docs.length === 0 ? 'doc-upload-disabled' : ''
          }`}
        >
          <div className="doc-upload-icon">
            <UploadIcon
              color={`${selectedDocType === -1 || docs.length === 0 ? '#D3D3D3' : 'white'}`}
            />
          </div>
          <div className="doc-upload-title">Start Upload</div>
        </div>
        <div
          title="Clear"
          className={`doc-upload-control doc-upload-cancel ${
            selectedDocType === -1 ? 'doc-upload-disabled' : ''
          }`}
        >
          <div className="doc-upload-icon">
            <CancelIcon color={`${selectedDocType === -1 ? '#D3D3D3' : 'white'}`} />
          </div>
          <div
            onClick={() => {
              this.removeAllDocs()
            }}
            className="doc-upload-title"
          >
            Clear Uploads
          </div>
        </div>
      </div>
    )
  }
  renderDocQueue() {
    const { docs, selectedDocType } = this.state
    if (docs.length) {
      return (
        <div className="doc-queue-container">
          <div className="doc-queue">
            {docs.map((doc, index) => (
              <div className="doc-queue-item" style={{ animation: ` 1000ms fadeIn` }}>
                <div className="doc-queue-item-name">
                  {doc.file.name}
                  {doc.uploadSuccess && (
                    <React.Fragment>
                      {' '}
                      - <span> Uploaded</span>
                    </React.Fragment>
                  )}
                </div>
                {this.renderDocStatus(doc, index)}
                {doc.uploadSuccess ? (
                  <div
                    title="Successful Upload"
                    className="doc-queue-item-status doc-queue-item-success"
                  >
                    <CheckmarkAnimation color={'black'} />
                  </div>
                ) : (
                  <div
                    title="Clear"
                    className={`doc-queue-item-remove ${doc.uploadSuccess && ''}`}
                    onClick={() => {
                      this.removeDoc(index)
                    }}
                  >
                    <CancelIcon color={'white'} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    }
    return <div />
  }
  renderDocStatus(doc, index) {
    const { selectedDocType } = this.state
    if (!(doc.uploading || doc.uploadFail || doc.uploadSuccess)) {
      return (
        <div
          title="Upload"
          className={`doc-queue-item-status doc-queue-item-waiting ${
            selectedDocType === -1 ? 'doc-upload-disabled' : ''
          }`}
          onClick={() => {
            this.uploadDoc(doc)
          }}
        >
          <UploadIcon color={`${selectedDocType === -1 ? '#D3D3D3' : 'white'}`} />
        </div>
      )
    } else if (doc.uploading) {
      return (
        <div title="Uploading" className="doc-queue-item-status doc-queue-item-uploading">
          <LoadingSpinner loading hideMessage wheel />
        </div>
      )
    } else if (doc.uploadSuccess) {
      return <div className="doc-queue-item-status hidden"></div>
    } else {
      return (
        <div
          title="Retry"
          className="doc-queue-item-status doc-queue-item-fail"
          onClick={() => {
            this.uploadDoc(doc)
          }}
        >
          <RefreshIcon color={'white'} />
        </div>
      )
    }
  }
  render() {
    const { docs, docTypes, selectedDocType, dragging } = this.state
    return (
      <div align="center" className="white_box_upload">
        <h1>Document Upload</h1>
        <h4>A few things to remember when uploading documents:</h4>
        <ul className="upload-directions">
          <li className="list-item">You must upload different Document Types separately.</li>
          <li className="list-item">
            Files must be named with the vehicle's{' '}
            <span style={{ fontWeight: '700', fontSize: '0.9em' }}>VIN</span> or{' '}
            <span style={{ fontWeight: '700', fontSize: '0.9em' }}>STOCK</span> numbers when
            uploaded to be attached to the correct vehicles.
          </li>
          <li className="list-item">
            <div className="examples">
              Examples:
              <li>SCAZA11C6TCX57787.pdf</li>
              <li>51030L.pdf</li>
            </div>
          </li>
        </ul>
        <FileDrop
          onDrop={this.handleFileDrop}
          onFrameDragEnter={this.handleDragEnter}
          onFrameDragLeave={this.handleDragLeave}
          onFrameDragDrop={this.handleDragLeave}
        >
          <div className={`upload_area ${selectedDocType !== -1 ? 'allow-drag' : ''}`}>
            <ul className="upload-directions-continued">
              <li className="list-item">
                <span style={{ fontWeight: '700', fontSize: '0.9em' }}>Select</span> the Document
                Type you would like to upload
              </li>
              <li className="list-item">
                <span style={{ fontWeight: '700', fontSize: '0.9em' }}>Drag</span> Files in or Use
                the
                <span style={{ fontWeight: '700', fontSize: '0.9em' }}> Add Files</span> button and
                select all of the .pdf documents you wish to upload.
              </li>
              <li className="list-item">
                Use the <span style={{ fontWeight: '700', fontSize: '0.9em' }}>Start Upload</span>{' '}
                button to begin uploading the selected documents.
              </li>
            </ul>
            <div
              className={`${selectedDocType === -1 ? 'doc-upload-attention-text' : ''}`}
              onChange={e => {
                this.updateDocType(e.target.value)
              }}
            >
              <span className="select-document-label">Select a Document Type</span>
            </div>
            {this.renderDocTypes()}
            {this.renderUploadControls()}
            {this.renderDocQueue()}
          </div>
        </FileDrop>
      </div>
    )
  }
}
