import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import ChangeStore from '../reactadminapp/components/changestore.js'
import BulkUserUpload from './components/bulkuserupload.js'
import './styles/stafftools.scss'

class StaffTools extends Component {
  constructor(props) {
    super(props)
  }
  renderSubNav() {
    return (
      <div className="subnav-bar">
        <div className="subnav-content">
          <div className="subnav-center">
            <NavLink
              key="store-jump"
              to="/staff-tools/store-jump"
              className="reports-header-button"
            >
              Store Jump
            </NavLink>
            <NavLink
              key="bulk-upload"
              to="/staff-tools/bulk-upload"
              className="reports-header-button"
            >
              Bulk Upload
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
  renderSubPage() {
    const pageOn = window.location.href.split('staff-tools')[1]

    if (pageOn === '/bulk-upload') {
      return <BulkUserUpload />
    }
    return <ChangeStore />
  }
  render() {
    return (
      <React.Fragment>
        {this.renderSubNav()}
        <div className="report_box">
          <div className="staff-tools" style={{ animation: ` 1500ms fadeIn` }}>
            {this.renderSubPage()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  showStaffTools: state.authentication.user.user.is_staff,
})
export default connect(mapStateToProps, null)(StaffTools)
