import React from 'react'

const VehicleMutlipleIcon = props => {
  const color = props.color ? props.color : 'black'
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.46 13.005C9.36 12.71 9.08 12.5 8.75 12.5H3.25C2.92 12.5 2.645 12.71 2.54 13.005L1.5 16V20C1.5 20.275 1.725 20.5 2 20.5H2.5C2.775 20.5 3 20.275 3 20V19.5H9V20C9 20.275 9.225 20.5 9.5 20.5H10C10.275 20.5 10.5 20.275 10.5 20V16L9.46 13.005ZM3.25 18C2.835 18 2.5 17.665 2.5 17.25C2.5 16.835 2.835 16.5 3.25 16.5C3.665 16.5 4 16.835 4 17.25C4 17.665 3.665 18 3.25 18ZM8.75 18C8.335 18 8 17.665 8 17.25C8 16.835 8.335 16.5 8.75 16.5C9.165 16.5 9.5 16.835 9.5 17.25C9.5 17.665 9.165 18 8.75 18ZM2.5 15.5L3.25 13.25H8.75L9.5 15.5H2.5Z"
        fill={color}
      />
      <path
        d="M15.46 4.005C15.36 3.71 15.08 3.5 14.75 3.5H9.25C8.92 3.5 8.645 3.71 8.54 4.005L7.5 7V11C7.5 11.275 7.725 11.5 8 11.5H8.5C8.775 11.5 9 11.275 9 11V10.5H15V11C15 11.275 15.225 11.5 15.5 11.5H16C16.275 11.5 16.5 11.275 16.5 11V7L15.46 4.005ZM9.25 9C8.835 9 8.5 8.665 8.5 8.25C8.5 7.835 8.835 7.5 9.25 7.5C9.665 7.5 10 7.835 10 8.25C10 8.665 9.665 9 9.25 9ZM14.75 9C14.335 9 14 8.665 14 8.25C14 7.835 14.335 7.5 14.75 7.5C15.165 7.5 15.5 7.835 15.5 8.25C15.5 8.665 15.165 9 14.75 9ZM8.5 6.5L9.25 4.25H14.75L15.5 6.5H8.5Z"
        fill={color}
      />
      <path
        d="M21.46 13.005C21.36 12.71 21.08 12.5 20.75 12.5H15.25C14.92 12.5 14.645 12.71 14.54 13.005L13.5 16V20C13.5 20.275 13.725 20.5 14 20.5H14.5C14.775 20.5 15 20.275 15 20V19.5H21V20C21 20.275 21.225 20.5 21.5 20.5H22C22.275 20.5 22.5 20.275 22.5 20V16L21.46 13.005ZM15.25 18C14.835 18 14.5 17.665 14.5 17.25C14.5 16.835 14.835 16.5 15.25 16.5C15.665 16.5 16 16.835 16 17.25C16 17.665 15.665 18 15.25 18ZM20.75 18C20.335 18 20 17.665 20 17.25C20 16.835 20.335 16.5 20.75 16.5C21.165 16.5 21.5 16.835 21.5 17.25C21.5 17.665 21.165 18 20.75 18ZM14.5 15.5L15.25 13.25H20.75L21.5 15.5H14.5Z"
        fill={color}
      />
    </svg>
  )
}

export default VehicleMutlipleIcon
