import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  updateActiveFilters,
  updateFacet,
  facetClearAll,
} from '../../../core/actions/inventoryactions.js'
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'

import FilterIcon from '../assets/filtericon.js'

class FilterClearsComponent extends Component {
  updateFacetFields = (facetIndex, optionIndex) => {
    let {
      facetFields,
      activeFacetFields,
      facetOptions,
      updateFacetConnect,
      user_id,
      token,
      activeFacetOptions,
      sortBy,
      searchQuery,
    } = this.props
    updateFacetConnect({
      facetIndex,
      optionIndex,
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery,
    })
  }
  clearFacetFields = () => {
    let {
      facetFields,
      activeFacetFields,
      facetOptions,
      facetClearAllConnect,
      user_id,
      token,
      activeFacetOptions,
      sortBy,
      searchQuery,
    } = this.props
    facetClearAllConnect({
      facetFields,
      activeFacetFields,
      facetOptions,
      user_id,
      token,
      page: 1,
      sortBy,
      searchQuery,
    })
  }
  render() {
    const { facetOptions, facetFields, activeFacetFields } = this.props
    if (facetFields) {
      return (
        <div className="filter-clears-container">
          <div id="active-filter-label">
            <FilterIcon />
            Active Filters
          </div>
          {activeFacetFields.length > 0 && (
            <div
              className="filter-clear-all visible"
              onClick={() => {
                this.clearFacetFields()
              }}
            >
              Clear All
            </div>
          )}
          {facetFields.map((selected, fieldIndex) =>
            selected.map(optionIndex => (
              <div
                key={facetOptions[fieldIndex].facet_options[optionIndex].name}
                className="filter-clear visible"
                onClick={() => {
                  this.updateFacetFields(fieldIndex, optionIndex)
                }}
              >
                {` X ${facetOptions[fieldIndex].facet_options[optionIndex].name}`}
              </div>
            )),
          )}
        </div>
      )
    }
    return <div className="filter-clears-container" />
  }
}
const mapStateToProps = state => ({
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  activeFacetOptions: state.inventory.activeFacetOptions,
  user_id: state.authentication.user.user.profile.legacy_user.legacy_user_id,
  token: state.authentication.user.token,
  sortBy: state.inventory.sortBy,
  searchQuery: state.inventory.searchQuery,
})
const FilterClears = UserInfoWrapper(FilterClearsComponent)
export default connect(mapStateToProps, {
  updateActiveFilters,
  updateFacetConnect: updateFacet,
  facetClearAllConnect: facetClearAll,
})(FilterClears)
