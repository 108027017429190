import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getTOSAgreement, updateTOSAgreement } from '../core/actions/users/useractions'
import UserInfoWrapper from '../core/wrappers/userinfowrapper'
import ReportIssueAnimation from './helpers/reportissueanimation'
import LoadingSpinner from './helpers/loadingspinner'
import './styles/tosagreement.scss'

class TOSAgreement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tos: '',
    }
  }
  componentDidMount() {
    getTOSAgreement().then(res => {
      const { results } = res
      if (results && results.length) {
        this.setState({
          tos: results[0].service_terms ? results[0].service_terms : results[0].policy_text,
        })
      }
    })
  }
  accept = () => {
    const { updateTOSAgreementConnect, username, token } = this.props
    updateTOSAgreementConnect({ username, token, is_accepted: true })
  }
  render() {
    const { checkedTOSAcceptance } = this.props
    const { tos } = this.state
    if (!tos || !checkedTOSAcceptance) {
      return (
        <div className="tos-agreement-loading">
          <LoadingSpinner loading wheel />
        </div>
      )
    } else {
      return (
        <div className="tos-agreement-container">
          <div className="tos-agreement">
            <div className="tos-agreement-header">Updated AutoiPacket Terms of Service</div>
            <div className="tos-agreement-content">
              Please read through and accept our updated
              <span>{' Terms of Service '}</span>
              agreement
            </div>
            <div className="tos-agreement-text">{tos}</div>
            <div className="tos-agreement-controls">
              <button
                onClick={() => {
                  this.accept()
                }}
              >
                Accept
              </button>
              <br />
              <span>
                By clicking 'Accept' you acknowledge that you have read, understand, and agree to
                the updated Terms of Service
              </span>
            </div>
          </div>
        </div>
      )
    }
  }
}
const mapStateToProps = state => ({
  username: state.authentication.user.user.username,
  token: state.userSettings.settings && state.userSettings.settings.dj_token,
  checkedTOSAcceptance: state.userSettings.checkedTOSAcceptance,
})

export default connect(mapStateToProps, {
  updateTOSAgreementConnect: updateTOSAgreement,
})(TOSAgreement)
