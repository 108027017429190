import { reactAdminConstants } from '../_constants/reactadminconstants'

const initState = {
  title: 'Last Month',
  interval: {},
}
export function reactAdmin(state, action) {
  if (!state) {
    state = initState
  }
  switch (action.type) {
    case reactAdminConstants.SET_TIME_INTERVAL:
      return { ...state, title: action.title }

    case reactAdminConstants.SET_TIME_INTERVAL_CUSTOM:
      return { ...state, interval: action.interval }
    default:
      return state
  }
}
