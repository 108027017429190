// General : Component for Displaying an individual module and button in the agreed upon row format

import React, { useState, useEffect } from 'react'

// node modules
import format from 'date-fns/format'
// icons
import ClockIcon from '../../../core/assets/images/icons/clock-icon'
import IconMobile from '../../../core/assets/images/icons/icon-mobile'

// hooks prop stuff
import PropTypes from 'prop-types'

// helpers
import '../../helpers/styles/privacyslide.scss'

import ToolTip from '../../helpers/tooltip'
import adminActions from '../../../core/actions/users/adminactions'
import { getUserDetail } from '../../../core/actions/users/useractions'

function ModuleRow({
  moduleId,
  storeId,
  token,
  moduleName,
  isActive,
  isEnabled,
  description,
  dateCreated,
  userCreated,
  dateModified,
  userModified,
  isMobileActive,
  isMobilePreview,
}) {
  const [mini, setMini] = useState(true)
  const [isActiveSwitch, setIsActiveSwitch] = useState(isActive)
  const [slidersDisabled, setSlidersDisabled] = useState(false)
  const [fetchedUserModified, setFetchedUserModified] = useState('loading...')
  const [fetchedUserCreated, setFetchedUserCreated] = useState('loading...')
  // const [isCheckedDefault, setCheckedDefault] = useState(false)

  useEffect(() => {
    // This fetches real information based on loaded user ID
    if (userCreated === null || userCreated === undefined) {
      setFetchedUserCreated('unknown')
    } else {
      getUserDetail(userCreated, token).then(json => {
        if (json.detail !== undefined) {
          setFetchedUserCreated('unknown')
        } else if (
          json.first_name === undefined ||
          (json.first_name === '' && json.username !== null)
        ) {
          setFetchedUserCreated(json.username)
        } else {
          setFetchedUserCreated(`${json.first_name} ${json.last_name}`)
        }
      })
    }
    // This also fetches real information based on loaded user ID
    if (userModified === null || userModified === undefined) {
      setFetchedUserModified('unknown')
    } else {
      getUserDetail(userModified, token).then(json => {
        if (json.detail !== undefined) {
          setFetchedUserModified('unknown')
        }
        if (json.first_name === undefined || (json.first_name === '' && json.username !== null)) {
          setFetchedUserModified(json.username)
        } else {
          setFetchedUserModified(`${json.first_name} ${json.last_name}`)
        }
      })
    }
  }, [])

  // Slider for enabling/disabling modules
  function onActiveChange() {
    setSlidersDisabled(true)
    if (isActiveSwitch) {
      adminActions.disableModule(storeId, moduleId, token).then(json => {
        console.log(`${moduleId}: ${json.active}`)
        setIsActiveSwitch(false)
        setSlidersDisabled(false)
      })
    } else {
      adminActions.enableModule(storeId, moduleId, token).then(json => {
        console.log(`${moduleId}: ${json.active}`)
        setIsActiveSwitch(true)
        setSlidersDisabled(false)
      })
    }
  }
  function activeSlider() {
    return (
      <div className="active-slide">
        <label className="switch">
          <input
            onChange={() => onActiveChange()}
            type="checkbox"
            checked={isActiveSwitch}
            disabled={slidersDisabled}
          />
          <span className="slider round" />
          <p>{isActiveSwitch ? 'Active' : 'Inactive'}</p>
        </label>
        <ToolTip
          onHover
          tip={
            <React.Fragment>
              If module is active, it will show up
              <br />
              as an option while sending a packet
              <br />
              and on the VDP
            </React.Fragment>
          }
        />
      </div>
    )
  }

  return (
    // row button slider
    <div className="module-row-container">
      <div className="module-button-container">{activeSlider()}</div>
      <div className="module-main-view">
        <h1>{moduleName}</h1>
        <div className="module-description">
          {/* mini is the state that determines collapse of description string, if true only show the first 75 characters of string */}
          <p>
            {mini === true && description.length > 62
              ? `${description.substring(0, 65)}...`
              : description}
            {mini === true && description.length > 62 ? (
              <span
                className="text-expand-button"
                onClick={() => {
                  setMini(false)
                }}
              >
                Expand
              </span>
            ) : (
              ''
            )}
            {mini === false && description.length > 62 ? (
              <button
                className="text-expand-button"
                onClick={() => {
                  setMini(true)
                }}
              >
                Collapse
              </button>
            ) : (
              ''
            )}
          </p>
        </div>
        <div className="module-info">
          <span className="icon-text-container">
            <IconMobile />
            <p>
              Mobile: {isMobileActive === true ? 'Active' : null}{' '}
              {isMobilePreview === true ? '+ Preview' : null}
            </p>
          </span>
          <span className="icon-text-container">
            <ClockIcon />
            <p>
              Created {dateCreated ? format(new Date(dateCreated), 'MM/dd/yy') : '(not listed)'} by{' '}
              {fetchedUserCreated}
            </p>
          </span>
          <span className="icon-text-container">
            <ClockIcon />
            <p>
              Modified {dateModified ? format(new Date(dateModified), 'MM/dd/yy') : '(not listed)'}{' '}
              by {fetchedUserModified}
            </p>
          </span>
        </div>
        <div className="module-additional-switches">
          {isActiveSwitch ? (
            <span className="check-box-container">
              <label>
                <input
                  onClick={null}
                  type="checkbox"
                  checked={isEnabled}
                  disabled={slidersDisabled}
                />
                Enabled
              </label>
              <ToolTip
                onHover
                tip={
                  <React.Fragment>
                    If disabled, the module will show up
                    <br />
                    on the packet send screen, but cannot
                    <br />
                    be checked/unchecked. It will also
                    <br />
                    not show up on the VDP
                  </React.Fragment>
                }
              />
            </span>
          ) : (
            ''
          )}
          {isActiveSwitch ? (
            <span className="check-box-container">
              <label>
                <input
                  onClick={null}
                  type="checkbox"
                  checked={isEnabled}
                  disabled={slidersDisabled}
                />
                Checked by Default
                <ToolTip
                  onHover
                  tip={
                    <React.Fragment>
                      If checked here, the module checkbox will be
                      <br />
                      checked already on the packet send screen.
                      <br />
                      Does not affect the VDP
                    </React.Fragment>
                  }
                />
              </label>
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
export default ModuleRow
ModuleRow.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string,
  isActive: PropTypes.bool,
  description: PropTypes.string,
  dateCreated: PropTypes.string,
  dateModified: PropTypes.string,
  isMobileActive: PropTypes.bool,
  isMobilePreview: PropTypes.bool,
}
