// node modules
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
// assets
import '../styles/tabnav.scss'

class TabNav extends Component {
  reportLinks() {
    const links = []

    links.push(
      <NavLink key="overview" to="/staff-tools/overview" className="reports-header-button">
        Current Store Overview
      </NavLink>,
    )
    links.push(
      <NavLink key="search" to="/staff-tools/search" className="reports-header-button">
        Store/User Search
      </NavLink>,
    )
    links.push(
      <NavLink
        key="vehicle-history-search"
        to="/staff-tools/vehicle-history-search"
        className="reports-header-button"
      >
        Vehicle History Search
      </NavLink>,
    )
    links.push(
      <NavLink key="userManage" to="/staff-tools/bulk-upload" className="reports-header-button">
        Bulk Upload
      </NavLink>,
    )
    return links
  }

  render() {
    return (
      <div className="subnav-bar">
        <div className="subnav-content">
          <div className="subnav-center">{this.reportLinks()}</div>
        </div>
      </div>
    )
  }
}

export default TabNav
