// general: 1st level applicaiton start file.

// Note: do not place a wrapper around the first 2-outter most files ->
// index.js and routes.js
// we do not want to wrap them because onChange emission of a state change or prop
// change will cause all of these files to reload automatically due to the changed
// item. This is bad because the user will be redirected from the page they are on.
// Tho, if the wrapper does not have a change ever re-emitted wrap away!
// Thanks - Natalie

// import { hot } from 'react-hot-loader/root';

// @flow
import React from 'react'
// node packages
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'

// polyfills
import toBlob from 'canvas-to-blob'
import 'babel-polyfill'
import reduxStore from './web/core/_helpers/reduxStore'
import runtimeEnv from './web/core/config/envConstants'

import App from './app.js'

export const { store } = reduxStore()
toBlob.init()
// Run Sentry in production
Sentry.init({
  dsn: 'https://53de28df92b84df695aca8cdd5f62a37@sentry.io/1467429',
  release: runtimeEnv.appVersion,
  environment: runtimeEnv.isProd ? 'production' : 'development',
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
})

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
