import { TOGGLE_PRIVACY } from '../_constants/helperTypes'

const initState = {
  isPrivate: localStorage.getItem('presentationModeToken')
    ? localStorage.getItem('presentationModeToken')
    : false,
}

export function helpers(state, action) {
  if (!state) {
    state = initState
  }
  switch (action.type) {
    case TOGGLE_PRIVACY:
      return {
        ...state,
        isPrivate: !state.isPrivate,
      }
    default:
      return state
  }
}
