import React from 'react'

const ActivityIcon = props => {
  return (
    <svg className="icon-car-history" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.7 48.34">
      <line className="a" x1="23.19" y1="37.23" x2="23.16" y2="37.29" />
      <line className="a" x1="23.16" y1="37.29" x2="23.12" y2="37.23" />
      <text className="svg-font" transform="translate(49.42 22)">
        PACKET
      </text>
      <text className="svg-font" transform="translate(49.42 42)">
        ACTIVITY
      </text>
      <path
        className="c"
        d="M24.17,2H145.09a6.61,6.61,0,0,1,6.61,6.61V39.73a6.61,6.61,0,0,1-6.61,6.61H24.17A22.17,22.17,0,0,1,2,24.17v0A22.17,22.17,0,0,1,24.17,2Z"
      />
      <line className="a" x1="22.82" y1="23.27" x2="22.78" y2="23.23" />
      <path
        className="d"
        d="M25.62,7.14a17,17,0,1,0,17,17A17,17,0,0,0,25.62,7.14Zm-.18,31.28A14.12,14.12,0,1,1,39.56,24.3,14.12,14.12,0,0,1,25.44,38.42Z"
      />
      <path
        className="d"
        d="M36.68,24H33.8l-2.47-8.37h0a1.21,1.21,0,0,0-1.13-.92,1.19,1.19,0,0,0-1.11.85h0l0,.18v0L25.82,29l-3.76-9.49h0a1.19,1.19,0,0,0-1.09-1,1.13,1.13,0,0,0-1,.79h0L17.36,24H14.2a1.37,1.37,0,0,0,0,2.7h3.6a1.13,1.13,0,0,0,1-.79l1.84-3.28,4.14,10.47,0,.07v0h0a1.19,1.19,0,0,0,1.08.78,1.23,1.23,0,0,0,1.14-1h0l3.19-13.15L32,25.65h0a1.08,1.08,0,0,0,.95.75h3.75a1.24,1.24,0,0,0,0-2.44Z"
      />
    </svg>
  )
}
export default ActivityIcon

/*
<style>.a,.c{fill:none;}.b{font-size:28px;font-family:ProximaNova-Bold, Proxima Nova;font-weight:700;letter-spacing:-0.01em;}.c{stroke:#000;stroke-miterlimit:10;stroke-width:4px;}</style>
*/
