import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

// core files
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'
import ProfileActions from '../../../core/actions/users/profileactions'

// components
import ProfilePlaceholder from '../../../core/assets/images/profile-placeholder.png'
import LoadingSpinner from '../../helpers/loadingspinner'

// helpers
import runtimeEnv from '../../../core/config/envConstants'

// css styles
import '../styles/avatarchange.scss'

/*
    ProfileActions.getProfileAvatar() is deprecated! Suggest
    removing from ProfileActions, I dunno if I have that
    "authority"

*/
const AvatarChange = props => {
  const [fileConstExists, setFileConstExists] = useState(true)
  const [loadedAvatar, setLoadedAvatar] = useState(false)
  const [userAvatar, setUserAvatar] = useState(null)
  const [formerAvatar, setFormerAvatar] = useState(null)
  const [isCropping, setIsCropping] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [imageToUpload, setImageToUpload] = useState(null)
  const [loaded, setLoaded] = useState(0)
  const inputPicture = useRef(null)
  const cropperData = useRef(null)

  useEffect(() => {
    // Kind of Janky Check for the existence of the File Constructor - Khristan
    try {
      new File(['test'], 'test.txt', {
        type: 'text/plain',
      })
    } catch (e) {
      setFileConstExists(false)
    }
    ProfileActions.getProfile(props.user_id, props.token)
      .then(json => {
        setUserAvatar(json.avatar.file)
        setLoadedAvatar(true)
      })
      .catch(error => {
        console.error(error)
        toast('An Error has occured!')
      })
  }, [])

  const imageUploadHandler = blob => {
    // Only run if avatar has been modified
    if (formerAvatar !== null) {
      setUserAvatar(URL.createObjectURL(blob))
      const avatarFileName = 'None.png'
      // Create File with blob so API can accept return
      let avatarFile = null
      // Internet Explorer is the Scourge of the Earth
      // agreed - Victor
      try {
        avatarFile = new File([blob], avatarFileName, {
          type: blob.type,
          lastModified: Date.now(),
        })
      } catch (e) {
        avatarFile = imageToUpload
      }
      const apiEnv = runtimeEnv.isProd
        ? 'https://djapi.autoipacket.com/v1'
        : 'https://djapi.autoipacket-stg.com/v1'
      const data = new FormData()
      data.append('file', avatarFile)
      data.append('name', avatarFileName)
      axios.defaults.headers.common.Authorization = `bearer ${props.token}`
      axios
        .put(`${apiEnv}/users/files/profile-image/legacy/${props.username}`, data, {
          onUploadProgress: ProgressEvent => {
            setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100)
          },
        })
        .then(res => {
          console.log(res)
          setIsSubmitting(false)
          toast('Profile image updated!')
        })
        .catch(error => {
          console.log(error)
          setUserAvatar(formerAvatar)
          toast('An Error has occured!')
        })
    }
  }
  if (!loadedAvatar) {
    return (
      <div className="avatar-change-container">
        <LoadingSpinner loading wheel />
      </div>
    )
  }
  if (isSubmitting) {
    return (
      <div className="avatar-change-container">
        <LoadingSpinner loading wheel />
        <p>Uploading new image...</p>
        <div className="avatar-saving-bar" style={{ width: `${loaded}%` }}>
          &nbsp;
        </div>
      </div>
    )
  }
  return (
    <div className="avatar-change-container">
      {isCropping ? (
        <div className={`accountSettings-cropper ${fileConstExists ? '' : 'hidden'}`}>
          <Cropper
            ref={cropperData}
            src={userAvatar}
            style={{ height: 180, width: '80%', margin: '0 auto' }}
            viewMode={1}
            aspectRatio={1}
            guides={true}
            modal={false}
          />
        </div>
      ) : (
        <img
          className="avatar-image"
          src={userAvatar != null ? userAvatar : ProfilePlaceholder}
          alt="Your user avatar"
        />
      )}
      <div className="avatar-change-controls">
        {!isCropping ? (
          <button
            className="avatar-btn upload"
            onClick={() => {
              inputPicture.current.click()
            }}
          >
            + Upload New Image
          </button>
        ) : (
          <React.Fragment>
            <button
              className="avatar-btn save"
              type="button"
              onClick={() => {
                setIsSubmitting(true)
                setIsCropping(false)
                cropperData.current.cropper.getCroppedCanvas().toBlob(blob => {
                  imageUploadHandler(blob)
                })
              }}
            >
              Save
            </button>
            <button
              className="avatar-btn cancel"
              type="button"
              onClick={() => {
                setIsCropping(false)
                setUserAvatar(formerAvatar)
                setFormerAvatar(null)
              }}
            >
              Cancel
            </button>
          </React.Fragment>
        )}
      </div>
      <label style={{ display: 'none' }} htmlFor="avatar-picture-input">
        Upload New Avator Image
      </label>
      <input
        id="avatar-picture-input"
        type="file"
        accept="image/png,image/gif,image/jpeg,image/jpg"
        ref={inputPicture}
        onChange={e => {
          setFormerAvatar(userAvatar)
          setImageToUpload(e.target.files[0])
          setIsCropping(true)
          setUserAvatar(URL.createObjectURL(e.target.files[0]))
        }}
        onClick={event => {
          event.target.value = null
        }}
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default AvatarChange

AvatarChange.propTypes = {
  user_id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}
