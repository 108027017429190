import React from 'react'

const ScheduleSendIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M6 40V8l33.2 14h-2.95q-1.2 0-2.275.05t-1.875.2L9 12.5v8.4L21.1 24 9 27v8.35l14.1-5.9q-.4.85-.675 1.975t-.375 1.825Zm28.5 4q-3.9 0-6.7-2.775Q25 38.45 25 34.55q0-3.95 2.8-6.75t6.7-2.8q3.9 0 6.7 2.8 2.8 2.8 2.8 6.75 0 3.9-2.8 6.675T34.5 44Zm3.5-4.3 1.45-1.55-4.1-4.1v-6H33.3V35ZM9 29.45V12.5v22.85Z" />
    </svg>
  )
}
export default ScheduleSendIcon
