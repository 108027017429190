import React from 'react'

const CovideoIcon = props => {
  const height = props.height ? props.height : '24px'
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      style={{ enableBackground: 'new 0 0 1000 1000' }}
      height={height}
    >
      <path
        style={{ fill: '#21285A' }}
        className="st0"
        d="M544.62,966.21c78.51-65.62,140.41-136.97,182.53-223.22c52.15-106.31,77.08-217.49,42.41-334.11
                c-42.12-141.84-179.09-222.07-327.23-194.85c-7.16,1.43-14.61,1.72-24.36,2.58c16.62-69.34,49.29-126.94,98.57-174.79
                c6.59-6.59,20.92-9.17,30.95-8.31c269.35,24.64,455.32,237.83,438.99,501.74C972.72,762.19,784.46,948.15,544.62,966.21"
      />
      <path
        style={{ fill: '#21285A' }}
        className="st0"
        d="M562.1,791.13c-16.05,66.48-45.85,121.49-91.12,168.2c-5.16,5.44-17.19,8.02-25.5,7.16
                C226.84,943.57,63.23,797.43,21.11,587.39C-30.19,331.22,154.35,75.34,421.98,38.95c-10.6,9.74-18.34,16.05-25.5,23.21
                C252.63,199.7,196.47,368.19,223.98,561.32c19.48,138.11,150.15,253.59,317.49,230.09C546.91,790.55,552.64,791.13,562.1,791.13"
      />
      <path
        style={{ fill: '#F78C28' }}
        className="st1"
        d="M385.3,495.7c0-42.41-0.57-84.82,0-127.23c0.57-38.68,17.19-48.43,50.72-29.51
                c75.07,41.84,150.15,84.24,224.94,126.94c30.09,17.19,30.37,37.54,0.29,55.02c-76.22,44.13-152.44,87.4-229.52,130.38
                c-27.79,15.47-44.7,6.02-45.27-25.5c-0.86-43.27-0.29-86.82-0.29-130.09C385.88,495.7,385.59,495.7,385.3,495.7"
      />
    </svg>
  )
}
export default CovideoIcon
