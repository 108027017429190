import React from 'react'

const CircleIcon = props => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="40" fill="#C4C4C4" />
    </svg>
  )
}
export default CircleIcon
