import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
// helpers
import StaffToolsActions from '../../../core/actions/stafftoolactions'
// components
import AuditGraph from './auditgraph'
import GraphIcon from '../assets/graphicon.js'
import TableIcon from '../assets/tableicon.js'
// assets
import '../styles/audit.scss'

function AuditInfo({ storeId }) {
  const token = useSelector(state => state.authentication.user.token)
  const [loadedData, setLoadedData] = useState(false)
  const [showGraphs, setShowGraphs] = useState(true)
  const [MSRPAudit, setMSRPAudit] = useState({})
  const [brochureAudit, setBrochureAudit] = useState({})
  const [carfaxAudit, setCarfaxAudit] = useState({})
  const [autocheckAudit, setAutocheckAudit] = useState({})
  const [photoAudit, setPhotoAudit] = useState({})
  const [socialAudit, setSocialAudit] = useState({})
  const [textAudit, setTextAudit] = useState({})
  const [availibilityAudit, setAvailibilityAudit] = useState({})
  const [phoneAudit, setPhoneAudit] = useState({})
  useEffect(() => {
    Promise.all([
      StaffToolsActions.getAuditMSRP(storeId, token).then(json => {
        setMSRPAudit(json)
      }),
      StaffToolsActions.getAuditBrochure(storeId, token).then(json => {
        setBrochureAudit(json)
      }),
      StaffToolsActions.getAuditCarfax(storeId, token).then(json => {
        setCarfaxAudit(json)
      }),
      StaffToolsActions.getAuditAutocheck(storeId, token).then(json => {
        setAutocheckAudit(json)
      }),
      StaffToolsActions.getAuditPhoto(storeId, token).then(json => {
        setPhotoAudit(json)
      }),
    ]).then(values => {
      setLoadedData(true)
    })
  }, [])
  if (!loadedData) {
    return (
      <div className="audit-inner-container">
        <div className="section-loading loading-audits" />
      </div>
    )
  }
  return (
    <div className="audit-inner-container">
      <span className="graph-header-container">
        <span>
          <button className={showGraphs ? 'selected' : ''} onClick={() => setShowGraphs(true)}>
            <GraphIcon /> Graphs
          </button>
          <button className={showGraphs ? '' : 'selected'} onClick={() => setShowGraphs(false)}>
            <TableIcon /> Table
          </button>
        </span>
      </span>
      {showGraphs ? (
        <div className="audit-graph-container">
          {MSRPAudit.total.should_have > 0 ? (
            <AuditGraph
              doHave={MSRPAudit.total.do_have}
              shouldHave={MSRPAudit.total.should_have}
              coverage={MSRPAudit.total.coverage}
              category="MSRP"
            >
              <b>{MSRPAudit.total.do_have}</b> out of <b>{MSRPAudit.total.should_have}</b> MSRPs
              loaded
            </AuditGraph>
          ) : (
            ''
          )}
          {brochureAudit.should_have > 0 ? (
            <AuditGraph
              doHave={brochureAudit.do_have}
              shouldHave={brochureAudit.should_have}
              coverage={brochureAudit.coverage}
              category="Brochure"
            >
              <b>{brochureAudit.do_have}</b> out of <b>{brochureAudit.should_have}</b> vehicles have
              brochures
            </AuditGraph>
          ) : (
            ''
          )}
          {carfaxAudit.should_have > 0 ? (
            <AuditGraph
              doHave={carfaxAudit.do_have}
              shouldHave={carfaxAudit.should_have}
              coverage={carfaxAudit.coverage}
              category="Carfax"
            >
              <b>{carfaxAudit.do_have}</b> out of <b>{carfaxAudit.should_have}</b> vehicles have
              carfax
            </AuditGraph>
          ) : (
            ''
          )}
          {autocheckAudit.should_have > 0 ? (
            <AuditGraph
              doHave={autocheckAudit.do_have}
              shouldHave={autocheckAudit.should_have}
              coverage={autocheckAudit.coverage}
              category="Autocheck"
            >
              <b>{autocheckAudit.do_have}</b> out of <b>{autocheckAudit.should_have}</b> vehicles
              have autocheck
            </AuditGraph>
          ) : (
            ''
          )}

          {photoAudit.total_vehicles > 0 ? (
            <AuditGraph
              doHave={photoAudit.one_or_more}
              shouldHave={photoAudit.total_vehicles}
              coverage={photoAudit.one_or_more_coverage}
              category="1+ Photos"
            >
              <b>{photoAudit.one_or_more}</b> out of <b>{photoAudit.total_vehicles}</b> vehicles
              have at least one photo
            </AuditGraph>
          ) : (
            ''
          )}
          {photoAudit.total_vehicles > 0 ? (
            <AuditGraph
              doHave={photoAudit.multiple}
              shouldHave={photoAudit.total_vehicles}
              coverage={photoAudit.multiple_coverage}
              category="2+ Photos"
            >
              <b>{photoAudit.multiple}</b> out of <b>{photoAudit.total_vehicles}</b> vehicles have
              multiple photos
            </AuditGraph>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className="audit-table">
          <table>
            <thead>
              <tr>
                <th>Category</th>
                <th>Coverage</th>
                <th>Available</th>
                <th>Intended</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MSRP</td>
                <td>{MSRPAudit.total.coverage}</td>
                <td>{MSRPAudit.total.do_have}</td>
                <td>{MSRPAudit.total.should_have}</td>
              </tr>
              <tr>
                <td>Brochure</td>
                <td>{brochureAudit.coverage}</td>
                <td>{brochureAudit.do_have}</td>
                <td>{brochureAudit.should_have}</td>
              </tr>
              <tr>
                <td>Carfax</td>
                <td>{carfaxAudit.coverage}</td>
                <td>{carfaxAudit.do_have}</td>
                <td>{carfaxAudit.should_have}</td>
              </tr>
              <tr>
                <td>Autocheck</td>
                <td>{autocheckAudit.coverage}</td>
                <td>{autocheckAudit.do_have}</td>
                <td>{autocheckAudit.should_have}</td>
              </tr>
              <tr>
                <td>At least one photo</td>
                <td>{photoAudit.one_or_more_coverage}</td>
                <td>{photoAudit.one_or_more}</td>
                <td>{photoAudit.total_vehicles}</td>
              </tr>
              <tr>
                <td>Multiple photos</td>
                <td>{photoAudit.multiple_coverage}</td>
                <td>{photoAudit.multiple}</td>
                <td>{photoAudit.total_vehicles}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <h2>MSRP Audit Breakdown</h2>
      <div className="audit-table">
        <table>
          <thead>
            <tr>
              <th>Make</th>
              <th>Coverage</th>
              <th>Available</th>
              <th>Intended</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(MSRPAudit.makes).map(make => {
              return make[1].should_have > 0 ? (
                <tr key={make[0]}>
                  <td>{make[0]}</td>
                  <td>{make[1].coverage}</td>
                  <td>{make[1].do_have}</td>
                  <td>{make[1].should_have}</td>
                </tr>
              ) : (
                ''
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AuditInfo
