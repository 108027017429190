// general:

// @flow
import React, { Component } from 'react'
// node packages
// --- redux
import { connect } from 'react-redux'
// core files
// --- actions
import UserActions from '../../../../core/actions/users/useractions'
import ProfileActions from '../../../../core/actions/users/profileactions'
// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'

type Props = {
  packet_info: Object,
  userId: number,
  DJtoken: string,
  email: string,
  firstname: string,
  lastname: string,
}

class SendReport extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      mobile: '',
      comment: '',
      message: '',
      packet_info: this.props.packet_info,
    }

    // input container onChange event that is stalled with a 'bind'.
    this._onUpdateField = this.onUpdateField.bind(this)
    this._onSubmit = this.onSubmit.bind(this)
  }

  state: {
    mobile: string,
    comment: string,
    message: string,
    packet_info: Object,
  }

  // componentDidMount
  // - set 'mounted' to true.
  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetUserInfo()
    }
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ mobile: '', comment: '', message: '', packet_info: null })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetUserInfo
  onMountGetUserInfo() {
    ProfileActions.getProfile(this.props.userId, this.props.DJtoken).then(json => {
      if (json !== null && json !== undefined) {
        this.setState({ mobile: json.user_mobile })
      }
    })
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*DO*FUNCTIONS***********************
  // onSubmit
  onSubmit() {
    // props/states
    const { DJtoken, userId, email, firstname, lastname } = this.props
    const { comment, packet_info } = this.state

    // variables
    const title = `Report Problem with Vehicle Stk #: ${packet_info.stock} | ID: ${packet_info.vehicle_id}`

    if (comment !== null && comment !== undefined && comment !== '') {
      UserActions.postSendSupportEmail(DJtoken, userId, email, firstname, lastname, title, comment)
        .then(json => {
          if (json !== null && json !== undefined) {
            this.setState({ message: 'Report sent successfully, thank you.' })
          } else {
            this.setState({
              message: 'There was an error submitting your report, please try again.',
            })
          }
        })
        .catch(error => {
          this.setState({
            message: 'There was an error submitting your report, please try again.',
          })
          console.log(error.toString())
        })
    } else {
      this.setState({ message: 'Please leave a message before submitting.' })
    }
  }

  // onUpdateField
  onUpdateField(field: string, value: string) {
    this.setState({ message: '' })
    const newState = {}
    newState[field] = value.target.value
    this.setState(newState)
  }
  // ***********************LETS*DO*FUNCTIONS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // ReportVehicleInfoContainer
  ReportVehicleInfoContainer() {
    return (
      <span>
        <b style={{ fontSize: '1.5em' }}>Report Issue</b>
        <div className="packetSendDescriptionStyle">
          Do you see something wrong with this packet? Please leave a message describing the problem
          and we will address the issue.
        </div>
        <br />
      </span>
    )
  }

  // ReportUserInfoContainer
  ReportUserInfoContainer() {
    // props/states
    const { email, firstname, lastname } = this.props
    const { mobile, packet_info } = this.state

    // variables
    const title = `Report Problem with Vehicle Stk #: ${packet_info.stock} | ID: ${packet_info.vehicle_id}`

    // booleans
    const emailBoolean = email !== null && email !== undefined && email !== ''
    const mobileBoolean = mobile !== null && mobile !== undefined && mobile !== ''

    return (
      <div className="flexColumn packetReportNameStyle">
        <span>
          <span className="flexRowWrap">
            <b>Title:</b>&nbsp;{title}
          </span>
          <span className="flexRowWrap">
            <b>Name:</b>&nbsp;{firstname} {lastname}
          </span>
          {emailBoolean ? (
            <span className="flexRowWrap">
              <b>Email:</b>&nbsp;{email}
            </span>
          ) : null}
          {mobileBoolean ? (
            <span className="flexRowWrap">
              <b>Mobile:</b>&nbsp;{mobile}
            </span>
          ) : null}
        </span>
      </div>
    )
  }

  // ReportCommentContainer
  ReportCommentContainer() {
    return (
      <textarea
        ref={text => {
          this._inputMessage = text
        }}
        type="textarea"
        rows="5"
        placeholder="Report packet issue here."
        value={this.state.comment}
        onChange={text => {
          this._onUpdateField('comment', text)
        }}
        style={{ width: '90%' }}
      />
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _onUpdateField: Function // function
  _onSubmit: Function // function
  _inputMessage: Function // ref

  render() {
    const { packet_info } = this.state

    if (packet_info !== null && packet_info !== undefined) {
      const { message } = this.state
      return (
        <div className="packetSendMiddleContentStyle">
          {this.ReportVehicleInfoContainer()}

          <br />
          {this.ReportUserInfoContainer()}
          {this.ReportCommentContainer()}

          <br />
          {message}

          <br />
          <br />
          <button
            className="packetSendSubmitStyle"
            style={{ alignItems: 'center' }}
            onClick={this._onSubmit}
          >
            Submit
          </button>
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({ packet_info: state.packetstash.vehicle_info })
export default connect(mapStateToProps, {})(SendReport)
