import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFacetOptions, facetUpdatePage } from './web/core/actions/inventoryactions'
import {
  getTOSAgreement,
  getTOSAgreementInfo,
  updateTOSAgreement,
  getIntercomInfo,
} from './web/core/actions/users/useractions'

//This is experimental! This is a technique I thought up to Conditionally load application wide data//Large Data on page refresh rather then on the mount of specific pages
class LoadData extends Component {
  componentDidMount() {
    const {
      user,
      access_token,
      getFacetOptionsConnect,
      facetFields,
      activeFacetFields,
      facetOptions,
      facetUpdatePageConnect,
      getTOSAgreementInfoConnect,
      sortBy,
      is_staff,
    } = this.props
    if (user && access_token) {
      const user_id = user.user.profile.legacy_user.legacy_user_id
      const token = user.token
      const username = user.user.username
      const company_id = user.user.profile.legacy_user.company_id
      const full_name = user.user.first_name + ' ' + user.user.last_name
      const email = user.user.email
      const date_joined = Math.floor(new Date(user.user.date_joined).getTime() / 1000)
      const current_date = Math.floor(Date.now() / 1000)
      const {
        user_phone,
        user_mobile,
        default_store_id,
        store_full_name,
        company_name,
        role_name,
      } = user.user.profile.legacy_user
      window.intercomSettings = {
        app_id: 'dk5y5sc0',
        name: full_name, // Full name
        email: email, // Email address
        user_phone: `${user_phone}`,
        user_mobile: `${user_mobile}`,
        user_id: `${user_id}`,
        'User ID': `${user_id}`,
        default_store_id: `${default_store_id}`,
        store_full_name: `${store_full_name}`,
        company_name: `${company_name}`,
        company_id: `${company_id}`,
        company: is_staff
          ? null
          : {
              id: `${default_store_id}`,
            },
      }
      this.updateIntercom()
      getFacetOptionsConnect({ token, user_id })
      facetUpdatePageConnect({
        facetFields,
        activeFacetFields,
        facetOptions,
        user_id,
        token,
        page: 1,
        sortBy,
      })
      getTOSAgreementInfoConnect({ token, username })
    }
  }
  componentDidUpdate(prevProps) {
    this.updateIntercom()
  }
  updateIntercom() {
    const {
      user,
      access_token,
      getFacetOptionsConnect,
      facetFields,
      activeFacetFields,
      facetOptions,
      facetUpdatePageConnect,
      getTOSAgreementInfoConnect,
      sortBy,
      is_staff,
    } = this.props
    if (user && access_token) {
      const user_id = user.user.profile.legacy_user.legacy_user_id
      const token = user.token
      const username = user.user.username
      const company_id = user.user.profile.legacy_user.company_id
      const full_name = user.user.first_name + ' ' + user.user.last_name
      const email = user.user.email
      const date_joined = Math.floor(new Date(user.user.date_joined).getTime() / 1000)
      const current_date = Math.floor(Date.now() / 1000)
      const {
        user_phone,
        user_mobile,
        default_store_id,
        store_full_name,
        company_name,
        role_name,
      } = user.user.profile.legacy_user

      window.intercomSettings = {
        app_id: 'dk5y5sc0',
        name: full_name, // Full name
        email: email, // Email address
        user_phone: `${user_phone}`,
        user_mobile: `${user_mobile}`,
        user_id: `${user_id}`,
        'User ID': `${user_id}`,
        default_store_id: `${default_store_id}`,
        store_full_name: `${store_full_name}`,
        company_name: `${company_name}`,
        company_id: `${company_id}`,
        company: is_staff
          ? null
          : {
              id: `${default_store_id}`,
            },
      }
      try {
        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/dk5y5sc0'
        ;(function() {
          var w = window
          var ic = w.Intercom
          if (typeof ic === 'function') {
            ic('reattach_activator')
            ic('update', w.intercomSettings)
          } else {
            var d = document
            var i = function() {
              i.c(arguments)
            }
            i.q = []
            i.c = function(args) {
              i.q.push(args)
            }
            w.Intercom = i

            var l = function() {
              var s = d.createElement('script')
              s.type = 'text/javascript'
              s.async = true
              s.src = 'https://widget.intercom.io/widget/dk5y5sc0'
              var x = d.getElementsByTagName('script')[0]
              x.parentNode.insertBefore(s, x)
            }
            if (document.readyState === 'complete') {
              l()
            } else if (w.attachEvent) {
              w.attachEvent('onload', l)
            } else {
              w.addEventListener('load', l, false)
            }
          }
        })()
      } catch (e) {
        console.log('Intercom Load Failed with error:')
        console.error(e)
      }
    }
    // this.props.getIntercomInfoConnect({ token: this.props.user.token })
  }
  render() {
    return <div>{this.props.children}</div>
  }
}
const mapStateToProps = state => ({
  user: state.authentication.user,
  access_token: state.authentication.access_token,
  filters: state.inventory.filters,
  facetFields: state.inventory.facetFields,
  activeFacetFields: state.inventory.activeFacetFields,
  facetOptions: state.inventory.facetOptions,
  sortBy: state.inventory.sortBy,
  intercom_info: state.userSettings.intercom_info,
  is_staff:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.is_staff,
})
export default connect(mapStateToProps, {
  getTOSAgreementInfoConnect: getTOSAgreementInfo,
  getFacetOptionsConnect: getFacetOptions,
  facetUpdatePageConnect: facetUpdatePage,
  getIntercomInfoConnect: getIntercomInfo,
})(LoadData)
