// packages
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// actions
import {
  getCustomerCompanyList,
  unsubscribeCustomer,
  addSelectedCompany,
  removeSelectedCompany,
  addAllCompanies,
  removeAllCompanies,
} from '../../../core/actions/unsubscribeActions'
// components
import Dropdown from '../../layouts/dropdown'
import Warning from '../../../core/assets/images/icons/warning'
import BuildingIcon from '../../../core/assets/images/icons/building-icon'

function UnsubscribeForm(props) {
  const [companyList, setCompanyList] = useState([])
  const {
    jwt_token,
    handleSuccess,
    addSelectedCompanyConnect,
    removeSelectedCompanyConnect,
    addAllCompaniesConnect,
    removeAllCompaniesConnect,
    selectedCompanies,
  } = props
  const isSubmitActive = selectedCompanies.length === 0 ? true : false
  const noSelectedItemsWarning = selectedCompanies.length === 0 ? true : false

  const getData = () => {
    getCustomerCompanyList(jwt_token)
      .then(res => {
        res.map(company => {
          const newCompany = { id: company.company_id, name: company.company_name }
          setCompanyList(companyList => [...companyList, newCompany])
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleSumbit = () => {
    const selectedCompanyIds = selectedCompanies.map(company => {
      return company.id
    })
    unsubscribeCustomer(jwt_token, selectedCompanyIds)
      .then(res => {
        console.log(res)
        handleSuccess()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getSelectedItems = (action, selectedItem) => {
    switch (action) {
      case 'add-item':
        addSelectedCompanyConnect(selectedItem)
        return
      case 'remove-item':
        removeSelectedCompanyConnect(selectedItem)
        return
      case 'add-all':
        addAllCompaniesConnect(selectedItem)
        return
      case 'remove-all':
        removeAllCompaniesConnect()
        return
      default:
        return
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <React.Fragment>
      <h1>Unsubscribe to Emails</h1>

      <p>
        In the following form you can unsubscribe from recieving communications from AutoiPacket's
        partner companies.
      </p>

      <Dropdown
        placeholder="Select a Company"
        label="Companies"
        data={companyList}
        multiselect={true}
        getSelectedItems={getSelectedItems}
        customIcon={BuildingIcon}
      />

      <div className="disclaimer">
        <h2>Disclaimer</h2>
        <p>
          By unsubscribing to the chosen companies you are choosing to no longer recieve emails,
          text messages or social media alerts about any special offers, deals or news from these
          companies. You will also stop recieving any brochures pamphlets or written letters that
          contain information pertaining to these companies. If you continue recieving any sort of
          commmunication after unsubscribing please contact us immediately on our help line. Thank
          you.
        </p>
      </div>

      {noSelectedItemsWarning && (
        <div className="no-items-warning">
          <Warning />
          <span>You need to select at least one option to submit this form.</span>
        </div>
      )}

      <div className="submit">
        <button type="submit" disabled={isSubmitActive} onClick={() => handleSumbit()}>
          Unsubscribe
        </button>
      </div>
    </React.Fragment>
  )
}

UnsubscribeForm.propTypes = {
  jwt_token: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
}

const mapStateToProps = (state: Object, ownProps: Object) => ({
  jwt_token: ownProps.jwtString,
  handleSuccess: ownProps.handleSuccess,
  selectedCompanies: state.unsubscribe.selectedCompanies,
})

const mapDispatchToProps = {
  addSelectedCompanyConnect: addSelectedCompany,
  removeSelectedCompanyConnect: removeSelectedCompany,
  addAllCompaniesConnect: addAllCompanies,
  removeAllCompaniesConnect: removeAllCompanies,
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeForm)
