// This component is intended to assume the role of the userinfowrapper and serve
// as the single source of truth for application/user settings.
// Updated on: application load and settings updates
// emptied on: logout or token expiration
// current role:
//        *only* leveraged to serve default_store_id to 'View My Inventory' button on 'Inventory'

import { SETTINGS_UPDATE, SETTINGS_EMPTY, SET_DARK_MODE } from '../_constants/settingsTypes'
import _ from 'lodash'
import ls from 'local-storage'
import { connect } from 'react-redux'

import { store } from '../../../index.js'

// object from appLoad must be trimmed
function updateSettingsOnAppLoad(userSettings) {
  const formattedObj = {
    legacy_user_id: userSettings.user_id,
    default_store_id: userSettings.default_store_id,
    company_id: userSettings.company_id,
    username: userSettings.username,
    user_email_address: userSettings.user_email_address,
    is_admin: userSettings.is_admin,
    first_name: userSettings.first_name,
    last_name: userSettings.last_name,
    dj_token: userSettings.dj_token,
    login_timestamp: userSettings.timestamp,
    has_insights: userSettings.has_insights,
  }

  return function(dispatch) {
    dispatch({
      type: SETTINGS_UPDATE,
      settings: formattedObj,
    })
  }
}

function updateSettings(userSettings) {
  let userInfo = ls.get('userInfo')
  Object.keys(userSettings).map(key => {
    userInfo[key] = userSettings[key]
  })
  ls.set('userInfo', userInfo)
  return function(dispatch) {
    dispatch({
      type: SETTINGS_UPDATE,
      settings: userSettings,
    })
  }
}

function emptySettings() {
  return function(dispatch) {
    dispatch({
      type: SETTINGS_EMPTY,
    })
  }
}

function setDarkMode() {
  const darkModeState = store.getState().userSettings.dark_mode.enabled
  console.log(darkModeState)
  return function(dispatch) {
    if (darkModeState == false) {
      dispatch({
        type: SET_DARK_MODE,
        dark_mode: {
          enabled: true,
        },
      })
    } else {
      dispatch({
        type: SET_DARK_MODE,
        dark_mode: {
          enabled: false,
        },
      })
    }
  }
}

export { updateSettingsOnAppLoad, updateSettings, emptySettings, setDarkMode }
