import React, { Component } from 'react'
import { hideModal } from '../../../core/_actions/modalActions'
import { connect } from 'react-redux'
import { getTOSAgreement } from '../../..//core/actions/users/useractions'

import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import Modal from '../../layouts/modal'
import ReportIssueAnimation from '../../helpers/reportissueanimation'
import LoadingSpinner from '../../helpers/loadingspinner'
import '../styles/tosmodal.scss'

class TOSModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accepted: false,
      tos: '',
    }
  }
  componentDidMount() {
    getTOSAgreement().then(res => {
      console.log(res)
      const { results } = res
      if (results && results.length) {
        this.setState({
          tos: results[0].service_terms,
        })
      }
    })
  }
  render() {
    const { accepted, tos } = this.state
    if (tos) {
      return (
        <Modal>
          <div className="tos-modal">
            <div className="tos-modal-text">{tos}</div>
          </div>
        </Modal>
      )
    }
    return (
      <Modal>
        <div className="tos-modal">
          <LoadingSpinner loading wheel />
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  user_id: state.modal.props.user_id,
  token: state.modal.props.token,
  company_id: state.modal.props.token,
})

export default connect(mapStateToProps, {
  hideModalConnect: hideModal,
})(TOSModal)
