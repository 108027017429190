// general: action file for all things 'dashboard' data being passed back and forth.

// API(s)
import {
  DJangoDashboardListTypesAPIURL,
  DJangoDashboardListCPVHAPIURL,
  DJangoDashboardListSSAPIURL,
  DJangoDashboardNotesGetAPIURL,
  DJangoDashboardSentIntervalGetAPIURL,
  DJangoDashboardPacketSource,
  DjangoDashboardPacketAttachmentList,
} from '../../config/autoipacket_end_commands'
import AIP_DJAPI, { AIP_DJAPI_URLS } from '../../config/aipDjapi'

export function getDashboardPacketSource(args) {
  const { token } = args
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardPacketSource}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}

export function getDashboardResults(args) {
  const {
    token,
    tableType,
    page,
    itemsPerPage,
    sortDir,
    sortBy,
    query,
    storeId,
    userId,
    isAdmin,
    packet_type_source_id,
  } = args
  let pathParameters = `${tableType}/?page=${page}&items_per_page=${itemsPerPage}`
  pathParameters += `&sort_dir=${sortDir}&sort_by=${sortBy}`
  if (packet_type_source_id > -1) {
    pathParameters += `&packet_source_id=${packet_type_source_id}`
  }
  if (query) {
    pathParameters += `&s=${query}`
  }
  if (storeId && isAdmin) {
    pathParameters += `&store_id=${storeId}`
  }
  if (userId && !isAdmin) {
    pathParameters += `&user_id=${userId}`
  }
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardListTypesAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export function getDashboardSocialResults(args) {
  const {
    token,
    tableType,
    page,
    itemsPerPage,
    sortDir,
    sortBy,
    query,
    storeId,
    userId,
    isAdmin,
  } = args
  let pathParameters = `?page=${page}&items_per_page=${itemsPerPage}`
  pathParameters += `&sort_dir=${sortDir}&sort_by=${sortBy}`
  if (query) {
    pathParameters += `&s=${query}`
  }
  if (storeId && isAdmin) {
    pathParameters += `&store_id=${storeId}`
  }
  if (userId && !isAdmin) {
    pathParameters += `&user_id=${userId}`
  }
  let headers = {
    Authorization: `Token ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardListSSAPIURL}${pathParameters}`, {
    method: 'GET',
    headers,
  }).then(response => {
    return response.json()
  })
}
export default {
  // DASHBOARD - API - MAIN
  getDashboardTypes: (
    displayPage: string,
    userId: number,
    numORurl: string,
    token: string,
    search: string,
    sortObject: Object,
    storeId: number,
    isAdmin: boolean,
  ) => {
    // v1/dashboard/ ^stats/(?P<dashboard_type>viewed|hot_list|user_shares|price_changes)/$

    let pathParameters = `${displayPage}/?page=${numORurl}&items_per_page=15`

    if (isAdmin) {
      pathParameters += `&store_id=${storeId}`
    } else {
      pathParameters += `&user_id=${userId}`
    }

    if (search !== null && search !== undefined && search !== '') {
      pathParameters += `&s=${search}`
    }

    if (sortObject.sort_enabled) {
      let sortByDirection = 'asc'
      if (!sortObject.sort_by_direction) {
        sortByDirection = 'desc'
      }

      pathParameters += `&sort_by=${sortObject.sort_by}&sort_dir=${sortByDirection}`
    }

    if (numORurl !== '1') {
      pathParameters = numORurl
    }

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardListTypesAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getDashboardSS: (
    userId: number,
    numORurl: string,
    token: string,
    search: string,
    sortObject: Object,
    storeId: number,
    isAdmin: boolean,
  ) => {
    // v1/dashboard/ ^social-stats/$

    let pathParameters = `?page=${numORurl}&items_per_page=15`

    if (isAdmin) {
      pathParameters += `&store_id=${storeId}`
    } else {
      pathParameters += `&user_id=${userId}`
    }

    if (search !== null && search !== undefined && search !== '') {
      pathParameters += `&s=${search}`
    }

    if (sortObject.sort_enabled) {
      let sortByDirection = 'asc'
      if (!sortObject.sort_by_direction) {
        sortByDirection = 'desc'
      }

      pathParameters += `&sort_by=${sortObject.sort_by}&sort_dir=${sortByDirection}`
    }

    if (numORurl !== '1') {
      pathParameters = numORurl
    }
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardListSSAPIURL}${pathParameters}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  //
  //
  //
  // DASHBOARD - API - OTHER
  getDashNote: (shareId: number, token: string) => {
    // /v1/dashboard/packets/message/{packet_history_id}/
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardNotesGetAPIURL}${shareId}/`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getPacketAttachments: (shareId: number, username: string, token: string) => {
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(
      `${AIP_DJAPI_URLS.DjangoDashboardPacketAttachmentList}?share_uuid=${shareId}&=username=${username}`,
      {
        method: 'GET',
        headers,
      },
    ).then(response => {
      return response.json()
    })
  },

  getDashSentInterval: (token: string) => {
    // /v1/dashboard/packets/sent/:timeInterval/?startDate=2017-05-04 00:00:00&endDate=2017-08-06 23:59:59

    // Note: this is the format for sending a date range -> YYYY-MM-DD HH:MM[:ss[.uuuuuu]][TZ]
    const newDate = new Date()
    const djangoDateToday = `${newDate.getFullYear()}-${newDate.getMonth() +
      1}-${newDate.getDate()}`
    const djangoDateLessMonth = `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`
    const djangoSeconds = '00:00:00'

    const timeInterval = `month/?startDate=${djangoDateLessMonth} ${djangoSeconds}&endDate=${djangoDateToday} ${djangoSeconds}`

    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardSentIntervalGetAPIURL}${timeInterval}`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },

  getDashboardCPVH: (packetShareId: string, token: string) => {
    // v1/dashboard/ ^customer-packet-view-history/(?P<packet_share_id>[\w\-]+)/$
    let headers = {
      Authorization: `Token ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    return AIP_DJAPI(`${AIP_DJAPI_URLS.DJangoDashboardListCPVHAPIURL}${packetShareId}/`, {
      method: 'GET',
      headers,
    }).then(response => {
      return response.json()
    })
  },
}
