import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserActions, { userChangePassword } from '../../core/actions/users/useractions'
import LoadingSpinner from '../helpers/loadingspinner'
import './styles/temporarypasswordreset.scss'
import IconEyeOpen from '../../core/assets/images/icons/icon-eye-open'
import { toast } from 'react-toastify'

class TemporaryPasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      passwordVisible: false,
      passwordCompliance: 0,
      passwordStrength: 0,
      passwordRequirements: [],
      confirmPassword: '',
    }
  }
  updatePasswordField(password) {
    this.setState({ password })
    if (password.length) {
      UserActions.postPasswordStrengthDJAPI(password).then(res => {
        const { aip_standards } = res
        if (aip_standards) {
          this.setState({
            passwordCompliance: aip_standards.aip_percentage,
            passwordRequirements: aip_standards.aip_pw_rules,
            passwordStrength: aip_standards.normalized_strength,
          })
        }
      })
    }
  }
  updateConfirmPasswordField(password) {
    this.setState({ confirmPassword: password })
  }
  togglePasswordVisibility() {
    this.setState(prevState => {
      return { passwordVisible: !prevState.passwordVisible }
    })
  }
  changePassword() {
    const { token } = this.props
    const { password, confirmPassword, passwordCompliance } = this.state
    if (confirmPassword === password && passwordCompliance === 100) {
      userChangePassword(password, confirmPassword, token).then(res => {
        if (res) {
          toast(res)
          window.location.reload()
          this.setState({
            password: '',
            passwordVisible: false,
            passwordCompliance: 0,
            passwordStrength: 0,
            passwordRequirements: [],
            confirmPassword: '',
          })
        }
      })
    }
  }
  componentDidMount() {}
  renderChangePassword() {
    const {
      password,
      confirmPassword,
      passwordCompliance,
      passwordStrength,
      passwordRequirements,
      passwordVisible,
    } = this.state
    let passwordComplianceText = ''
    let passwordComplianceClass = ''
    if (passwordCompliance <= 33) {
      passwordComplianceText = 'Poor'
      passwordComplianceClass = 'password-compliance-poor'
    } else if (passwordCompliance > 33 && passwordCompliance <= 66) {
      passwordComplianceText = 'Good'
      passwordComplianceClass = 'password-compliance-good'
    } else if (passwordCompliance > 66 && passwordCompliance <= 99) {
      passwordComplianceText = 'Great'
      passwordComplianceClass = 'password-compliance-great'
    } else if (passwordCompliance >= 100) {
      passwordComplianceText = 'Perfect'
      passwordComplianceClass = 'password-compliance-perfect'
    }

    return (
      <div className="temporary-password-container">
        <div className="temporary-password">
          <div className="temporary-password-header">Please Update Your Password</div>
          <div className="temporary-password-fields">
            <div>
              <label>New Password</label>
              <br />
              <div className={passwordComplianceClass + '-input'} style={{ position: 'relative' }}>
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  onChange={e => {
                    this.updatePasswordField(e.target.value)
                  }}
                  value={password}
                />
                <div
                  className={`temporary-password-visibility-control ${
                    passwordVisible ? 'temporary-password-visibility-control-selected' : ''
                  }`}
                  onClick={() => {
                    this.togglePasswordVisibility()
                  }}
                >
                  <IconEyeOpen />
                </div>
              </div>
            </div>
            <div>
              <label>Confirm Password</label>
              <br />
              <input
                type={passwordVisible ? 'text' : 'password'}
                onChange={e => {
                  this.updateConfirmPasswordField(e.target.value)
                }}
                value={confirmPassword}
                disabled={!password.length > 0}
              />
            </div>
            <div className={`${password.length === 0 ? ' hidden' : ''}`}>
              <div className="accountSettings-password-compliance-grade-wrapper">
                <label>{passwordComplianceText}</label>
                <div
                  className={`accountSettings-password-compliance-grade ${passwordComplianceClass}`}
                />
              </div>
              <br />
              <div className="accountSettings-password-tips">
                <ul>
                  {passwordRequirements.map((rq, i) => (
                    <li key={i}>{rq}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="temporary-password-controls">
            <br />
            <button
              title="Change"
              type="button"
              onClick={() => {
                this.changePassword()
              }}
              disabled={
                password !== confirmPassword || passwordCompliance < 100 || password.length === 0
              }
            >
              Update
            </button>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return <div>{this.renderChangePassword()}</div>
  }
}
const mapStateToProps = state => ({
  token: state.authentication.access_token,
})

export default connect(mapStateToProps, {})(TemporaryPasswordReset)
