// general: 1/4 of the reports component - parent for reports - (1st).

// TODO:
// 1. mobile support
// 2. cross browser check support

// @flow
import React, { Component } from 'react'
// node packages
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
// core files
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// actions
import { generateExecutiveReport } from '../../core/actions/features/reportsactions'
// components
import Activity from './components/activityreports/activityreport'
import WebButton from './components/webbuttonreports/webbuttonreports'
import Status from './components/statusreports/statusreport'
import Upload from './components/useruploadreports/useruploadreport'
import Executive from './components/executivereports/executivereports'
import StoreSelect from '../tools/components/storeselect'
// css styles
import '../styles/universalstyles.scss'
import LoadingSpinner from '../helpers/loadingspinner'

type Props = {
  userInfo: Object,
  storeId: Number,
  DJtoken: String,
  company_id: Number,
}

export class ReportsComponent extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      userInfo: this.props.userInfo,
      customReportStartDate: new Date(),
      customReportEndDate: new Date(),
      loading: false,
    }
  }

  state: {
    userInfo: Object,
    customReportStartDate: String,
    customReportEndDate: String,
    loading: Boolean,
  }

  componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.
    this.setState({ userInfo: newProps.userInfo })
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    this.setState({ userInfo: {} })
  }
  reportLinks() {
    const links = []
    if (this.props.userInfo.is_admin) {
      links.push(
        <NavLink
          key="activityreport"
          to="/reports/activity-report"
          className="reports-header-button"
        >
          Activity Report
        </NavLink>,
        <NavLink
          key="executivereport"
          to="/reports/executive-report"
          className="reports-header-button"
        >
          Executive Report
        </NavLink>,
        <NavLink
          key="webbuttonreport"
          to="/reports/web-button-report"
          className="reports-header-button"
        >
          Website Plugin Report
        </NavLink>,
      )
    }
    links.push(
      <NavLink key="statusreport" to="/reports/status-report" className="reports-header-button">
        Status Report
      </NavLink>,
    )
    links.push(
      <NavLink
        key="uploadreport"
        to="/reports/user-upload-report"
        className="reports-header-button"
      >
        Upload Report
      </NavLink>,
    )
    return links
  }
  // ***********************LETS*SETUP*CONTAINERS***********************
  // ReportsViewColleciton
  ReportsViewColleciton() {
    return (
      <div className="subnav-bar">
        <div className="subnav-content">
          <div className="subnav-center">{this.reportLinks()}</div>
        </div>
      </div>
    )
  }

  getCustomExecutiveReport = () => {
    this.setState({ loading: true })
    let timeAdjustedStartDate = new Date(this.state.customReportStartDate.getTime())
    let timeAdjustedEndDate = new Date(this.state.customReportEndDate.getTime())
    timeAdjustedStartDate = new Date(timeAdjustedStartDate.setUTCHours(0, 0, 0, 0))
    timeAdjustedEndDate = new Date(timeAdjustedEndDate.setUTCHours(23, 59, 59, 999))

    generateExecutiveReport(this.props.DJtoken, {
      company_id: this.props.company_id,
      start_date: timeAdjustedStartDate.toISOString(),
      end_date: timeAdjustedEndDate.toISOString(),
    })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        this.setState({ loading: false })
      })
      .then(res => {
        if (res && res.message) {
          console.log(res.message)
          toast.success(res.message, {
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.setState({ loading: false })
        }
      })
  }

  ReportsViewSwitcher() {
    const { userInfo } = this.state

    // get page focus
    // eslint-disable-next-line no-undef
    const pageOn = window.location.href.split('reports')[1]
    console.log('Page On')
    console.log(pageOn)
    if (userInfo !== null && userInfo !== undefined) {
      if (userInfo.is_admin && (pageOn === '/activity-report' || pageOn === '' || pageOn === '/')) {
        return (
          <React.Fragment>
            <div className="subpage-header">
              <h2>Activity Reports</h2>
            </div>
            <div className="store-select-wrapper">
              <StoreSelect />
            </div>
            <Activity
              userId={userInfo.user_id}
              DJtoken={userInfo.dj_token}
              storeId={userInfo.default_store_id}
            />
          </React.Fragment>
        )
      } else if (pageOn === '/web-button-report') {
        return (
          <React.Fragment>
            <div className="subpage-header">
              <h2>Website Plugin Reports</h2>
            </div>
            <div className="store-select-wrapper">
              <StoreSelect />
            </div>
            <WebButton />
          </React.Fragment>
        )
      } else if (pageOn === '/status-report' || pageOn === '' || pageOn === '/') {
        return (
          <React.Fragment>
            <div className="subpage-header">
              <h2>Status Reports</h2>
            </div>
            <div className="store-select-wrapper">
              <StoreSelect />
            </div>
            <Status
              userId={userInfo.user_id}
              DJtoken={userInfo.dj_token}
              storeId={userInfo.default_store_id}
              isAdmin={userInfo.is_admin}
            />
          </React.Fragment>
        )
      } else if (pageOn === '/user-upload-report') {
        return (
          <React.Fragment>
            <div className="subpage-header">
              <h2>Upload Reports</h2>
            </div>
            <Upload DJtoken={userInfo.dj_token} userId={userInfo.user_id} />
          </React.Fragment>
        )
      } else if (pageOn === '/executive-report') {
        const selectionRange = {
          startDate: this.state.customReportStartDate, // .setHours(0, 0, 0, 0)
          endDate: this.state.customReportEndDate, // .setHours(23, 59, 59, 999)
          key: 'selection',
        }

        return (
          <React.Fragment>
            <div className="subpage-header">
              <h2>Executive Reports</h2>
              <h3>
                Select a date range or choose from the tables below to have a generated report of
                User usage data for the selected dates sent to your email address.
              </h3>
              <div className="activity-report-custom-container executive-container">
                <div className="activity-report-custom">
                  <div className="activity-report-custom-section">
                    <DateRangePicker
                      ranges={[selectionRange]}
                      months={1}
                      direction="horizontal"
                      maxDate={new Date()}
                      onChange={ranges => {
                        this.setState({ customReportStartDate: ranges.selection.startDate })
                        this.setState({ customReportEndDate: ranges.selection.endDate })
                      }}
                      rangeColors={['#1cd760']}
                      color="#1cd760"
                    />
                  </div>
                  <button
                    className={`activity-report-custom-generate-button ${
                      this.state.loading ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      this.getCustomExecutiveReport()
                    }}
                  >
                    Request Report
                  </button>
                </div>
              </div>
            </div>
            <Executive />
          </React.Fragment>
        )
      }
    }
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props

  render() {
    const { defaultStore } = this.props
    return (
      <div style={{ animation: ` 1500ms fadeIn` }} className="reports-normal">
        {console.log(`Default Store ID: ${defaultStore}`)}
        {this.ReportsViewColleciton()}
        <span>
          <div align="center" className="report_box" style={{ position: 'relative' }}>
            {defaultStore && this.ReportsViewSwitcher()}
          </div>
        </span>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  DJtoken: state.authentication.user.token,
  defaultStore: state.authentication.user.user.profile.default_company_store,
  company_id:
    state.authentication.user && state.authentication.user.user.profile.legacy_user.company_id,
})
const Reports = UserInfoWrapper(ReportsComponent)
export default connect(mapStateToProps, null)(Reports)
