import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import LoadingSpinner from '../../../helpers/loadingspinner.js'
import { getWebButtonCSVReports } from '../../../../core/actions/features/reportsactions'

function WebButtonReports(props) {
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNumt] = useState(0)

  const [webButtonCSVList, setWebButtonCSVList] = useState([])
  const { storeId, selectedStore, token } = props
  const getReport = () => {
    if (selectedStore && selectedStore.lot_id) {
      console.log(selectedStore)
      setLoading(true)
      setWebButtonCSVList([])
      getWebButtonCSVReports(token, selectedStore.lot_id, pageNum).then(res => {
        if (res) {
          setLoading(false)
          setWebButtonCSVList(res)
        }
      })
    }
  }
  useEffect(() => {
    getReport()
  }, [])
  useEffect(() => {
    getReport()
  }, [selectedStore])

  if (selectedStore && !loading && webButtonCSVList.length && selectedStore.lot_id) {
    return (
      <div className="reporting-table-container">
        <div className="reporting-table">
          <div className="reporting-table-row reporting-table-header">
            <div className="reporting-table-row-field reporting-table-header-field">
              Month of Report
            </div>
            <div className="reporting-table-row-field reporting-table-header-field">CSV</div>
          </div>
          {webButtonCSVList.map(item => {
            return (
              <WebButtonReportRow date={item.report_dt} csv_url={item.download_url} token={token} />
            )
          })}
        </div>
      </div>
    )
  } else if (selectedStore && !loading && (!webButtonCSVList.length || !selectedStore.lot_id)) {
    return (
      <div className="reporting-table-container">
        <div className="reporting-table-no-results">There are no reports available</div>
      </div>
    )
  }
  return (
    <div className="reporting-table-container">
      <LoadingSpinner loading />
    </div>
  )
}

function WebButtonReportRow(props) {
  const { date, csv_url, token } = props
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let month = new Date(date)
  month = `${monthNames[month.getMonth()]} ${month.getFullYear()}`
  console.log('MONTH')
  console.log(month)
  return (
    <div className="reporting-table-row">
      <div className="reporting-table-row-field">{month}</div>
      <div className="reporting-table-row-field">
        <button
          className="reporting-table-row-field-button download"
          onClick={() => {
            console.log('TOKEN')
            console.log(token)
            downloadCSV(csv_url, token, month)
          }}
        >
          download
        </button>
      </div>
    </div>
  )
}

function downloadCSV(url, token, month) {
  fetch(url, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  })
    .then(res => {
      return res.blob()
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${month.replace(' ', '_')}_export.csv`)
      // 3. Append to html page
      document.body.appendChild(link)
      // 4. Force download
      link.click()
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link)
    })
}

const mapStateToProps = state => ({
  storeId:
    state.tools.activeStoreId === -1
      ? state.authentication.user.user.profile.default_company_store
      : state.tools.activeStoreId,
  selectedStore: state.tools.stores.filter(function(entry) {
    return entry.company_store_id == state.tools.activeStoreId
  })[0],
  token: state.authentication.access_token,
})
export default connect(mapStateToProps)(WebButtonReports)
