// general:

// @flow
import React, { Component } from 'react'
// node packages
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import moment from 'moment-timezone'

// --- redux
import { connect } from 'react-redux'
import { showModal, hideModal } from '../../../../../core/_actions/modalActions'
import { PRICE_HISTORY_MODAL } from '../../../../../core/_constants/modalTypes'
// images
import IconLocation from '../../../../../core/assets/images/icons/icon-location.js'
import PriceHistory from '../../../../../core/assets/images/icons/pricehistorybtn.js'
// css styles
import '../../../../styles/universalstyles.scss'
import '../../../styles/packetsend.scss'

type Props = {
  DJtoken: string,
  showModal: Function,
  hideModal: Function,
  vehicle_info: Object,
  privacyHide: boolean,
}

class DescriptionChild extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      vehicleId: this.props.vehicle_info.vehicle_id,
      vehicleInfo: this.props.vehicle_info,
      privacyHide: this.props.privacyHide,
    }
  }

  state: {
    vehicleId: number,
    vehicleInfo: Object,
    privacyHide: boolean,
  }

  // componentDidMount
  // - set mount to true.
  componentDidMount() {
    this.mounted = true
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    this.setState({
      vehicleId: newProps.vehicle_info.vehicle_id,
      vehicleInfo: newProps.vehicle_info,
      privacyHide: newProps.privacyHide,
    })
  }

  // componentWillUnmount
  // - resets all values and states and things.
  componentWillUnmount() {
    this.mounted = false
    this.setState({ vehicleId: null, vehicleInfo: null, privacyHide: false })
  }

  // ***********************LETS*CALL*MODAL*SETUP***********************
  // PriceHistoryModal
  PriceHistoryModal() {
    this.props.showModal(PRICE_HISTORY_MODAL, {
      title: 'Price History',
      vehicle_id: this.state.vehicleId,
      token: this.props.DJtoken,
      vin: this.state.vehicleInfo.vin,
      stock_num: this.state.vehicleInfo.stock,
      currency_unit: this.state.vehicleInfo.currency_unit,
    })
  }
  // ***********************LETS*CALL*MODAL*SETUP***********************

  props: Props

  render() {
    // states
    const { vehicleInfo } = this.state
    console.log(vehicleInfo)
    if (vehicleInfo !== null && vehicleInfo !== undefined) {
      const { color, comments, drive_train } = vehicleInfo
      const engineDescription = vehicleInfo.engine_description
      const vehicleBody = vehicleInfo.vehicle_body
      const interiorColor = vehicleInfo.interior_color
      const transmissionDescription = vehicleInfo.transmission_description
      // TODO - one is subtracted from price_history_count beacuse the endpoint returns at least one inital price
      const priceHistoryCount = vehicleInfo.price_history_count - 1

      // states
      const { privacyHide } = this.state

      // booleans
      const engineBoolean =
        engineDescription !== null && engineDescription !== undefined && engineDescription !== ''
      const bodyBoolean = vehicleBody !== null && vehicleBody !== undefined && vehicleBody !== ''
      const colorBoolean = color !== null && color !== undefined && color !== ''
      const interiorColorBoolean =
        interiorColor !== null && interiorColor !== undefined && interiorColor !== ''
      const transDescrBoolean =
        transmissionDescription !== null &&
        transmissionDescription !== undefined &&
        transmissionDescription !== ''
      const commentsBoolean = comments !== null && comments !== undefined && comments !== ''

      const inDate = new Date(vehicleInfo.veh_in_date)
      const currentDate = moment()
      let daysSinceLastPriceChange = null
      if (vehicleInfo.last_price_change) {
        const lastPriceChangeDate = moment(vehicleInfo.last_price_change)
        daysSinceLastPriceChange = currentDate.diff(lastPriceChangeDate, 'days')
      }

      return (
        <div
          className="flexColumn packetSendInfoContainerStyle"
          style={{ border: 'none', width: '100%' }}
        >
          <div className="flexRow" style={{ flexWrap: 'wrap' }}>
            <span style={{ marginRight: '10px' }}>
              <b>VIN:</b> {vehicleInfo.vin}
            </span>
            <span>
              <b>STOCK #:</b> {vehicleInfo.stock}
            </span>
          </div>

          <div className="flexRow packetSendModulePriceRow">
            {parseInt(vehicleInfo.price) > 0 ? (
              <React.Fragment>
                <span className="packetSendModuleMoneyStyle">
                  <sup>
                    {vehicleInfo.currency_unit.symbol ? vehicleInfo.currency_unit.symbol : '$'}
                  </sup>
                </span>
                <span className="packetSendModulePriceStyle">
                  {parseInt(vehicleInfo.price).toLocaleString()}
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!vehicleInfo.archived && (
                  <span className="packetSendModulePriceStyle">Please Call</span>
                )}
              </React.Fragment>
            )}

            {!privacyHide && priceHistoryCount > 0 ? (
              <button
                className="vdp-price-history"
                onClick={() => {
                  this.PriceHistoryModal()
                }}
                style={{ backgroundColor: 'transparent' }}
              >
                <PriceHistory />
                <div className="vehicle-history-count">{priceHistoryCount}</div>
              </button>
            ) : null}
            {!privacyHide && daysSinceLastPriceChange ? (
              <React.Fragment>
                <span className="car-last-price-change">
                  <i>{`Days Since Last Price Change - `}</i>
                  <b>
                    <span>{`${daysSinceLastPriceChange}`}</span>
                  </b>
                </span>
                <br />
              </React.Fragment>
            ) : null}
          </div>
          {vehicleInfo.miles ? (
            <span className="miles-row">
              <b>{`${
                vehicleInfo.distance_unit.label ? vehicleInfo.distance_unit.label : 'Miles'
              }: ${vehicleInfo.miles.toLocaleString()}`}</b>
            </span>
          ) : (
            <br />
          )}

          <div className="flexColumn vehicle_stuff">
            {engineBoolean ? (
              <span>
                <b>Engine:</b> {engineDescription}
              </span>
            ) : null}
            {bodyBoolean ? (
              <span>
                <b>Body Type:</b> {vehicleBody}
              </span>
            ) : null}
            {colorBoolean ? (
              <span>
                <b>Exterior Color:</b> {color}
              </span>
            ) : null}
            {interiorColorBoolean ? (
              <span>
                <b>Interior Color:</b> {interiorColor}
              </span>
            ) : null}
            {transDescrBoolean ? (
              <span>
                <b>Drivetrain:</b> {drive_train}
              </span>
            ) : null}
            {vehicleInfo.inventory_type ? (
              <span>
                <b>Inventory Type:</b> {vehicleInfo.inventory_type}
              </span>
            ) : null}
          </div>

          <br />
          <div className="flexColumn" id="location-box">
            <span>
              <div className="AIPIconDealershipStyle">
                <IconLocation />
              </div>
              Located at
              <br />
              <span className="vlp-location-value">{vehicleInfo.store_full_name}</span>
            </span>

            {!vehicleInfo.archived ? (
              <span className="packetSendItalicStyle" style={{ opacity: !privacyHide ? '1' : '0' }}>
                <b>
                  {vehicleInfo.veh_in_date
                    ? differenceInDays(new Date(), parseISO(vehicleInfo.veh_in_date))
                    : 0}{' '}
                  days in inventory
                </b>
                &nbsp;(Not visible when sharing)
              </span>
            ) : (
              <span className="packetSendItalicStyle" style={{ opacity: !privacyHide ? '1' : '0' }}>
                {vehicleInfo.archived_ts ? (
                  <b>
                    {differenceInDays(new Date(), parseISO(vehicleInfo.archived_ts))} days archived
                  </b>
                ) : (
                  <b>
                    <b>This vehicle has been archived for </b>
                    {differenceInDays(new Date(), parseISO(vehicleInfo.veh_in_date))} days in
                    inventory
                  </b>
                )}
                &nbsp;&nbsp;(Not visible when sharing)
              </span>
            )}
            <span className="packetSendItalicStyle" style={{ opacity: !privacyHide ? '1' : '0' }}>
              <b>{`First Seen - ${moment(vehicleInfo.veh_in_date).format('MMMM Do YYYY')}`}</b>
            </span>
          </div>

          <br />

          <div
            id="seller-notes"
            className="flexColumn"
            style={{ display: !privacyHide ? 'block' : 'none' }}
          >
            <span>
              <b>Seller Notes:</b>{' '}
              {commentsBoolean ? <div dangerouslySetInnerHTML={{ __html: comments }} /> : null}
            </span>
          </div>
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  vehicle_info: state.packetstash.vehicle_info,
  privacyHide: state.helpers.isPrivate,
})
export default connect(mapStateToProps, {
  hideModal,
  showModal,
})(DescriptionChild)
