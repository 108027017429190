// packages
import React, { Component } from 'react'
import { connect } from 'react-redux'
// components
import StickerPullHistory from './stickerpullhistory'
import ArcDisplay from '../../arc/components/arcdisplay'
import DocumentViewer from '../../layouts/documentViewer'

// actions
import {
  putSticker,
  downloadSticker,
  pullSticker,
  pollSticker,
} from '../../../core/actions/stickerpullactions'
import { showModal } from '../../../core/_actions/modalActions'
// action types
import { STICKER_PULL_COVERAGE_MODAL } from '../../../core/_constants/modalTypes'
//helpers
import Helper from '../../../core/helpers/platformhelper'
import LoadingSpinner from '../../helpers/loadingspinner'
import { toast } from 'react-toastify'

//icons
import DownloadIcon from '../../../core/assets/images/icons/download-icon'
import CancelIcon from '../../../core/assets/images/icons/cancel-icon'
import WindowSticker from '../../../core/assets/images/icons/windowsticker'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/stickerpull.scss'

type Props = {}

export class StickerPuller extends Component {
  constructor(props: Props) {
    super(props)
    let urlVin = window.location.href.substring(window.location.href.lastIndexOf('pull/') + 5)
    // Added to get just the VIN number
    if (urlVin.includes('?')) {
      urlVin = urlVin.slice(0, urlVin.indexOf('?'))
    }

    let vinInput = ''

    if (urlVin.length === 17) {
      vinInput = urlVin
    }
    this.state = {
      vinInput,
      success: false,
      loading: false,
      stickerPullPdf: null,
      submittedInputVIN: vinInput,
    }
    this.handleChange = this.handleChange.bind(this)
    this.submitVin = this.submitVin.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.
    // this.setState({ userInfo: newProps.userInfo })
  }
  componentDidMount() {
    const { vinInput } = this.state
    if (vinInput.length === 17) {
      const urlReferrer = new URLSearchParams(window.location.search).get('request_source')
      let referrer = urlReferrer ? urlReferrer : document.referrer
      this.submitVin(vinInput, true, referrer)
    }
  }
  componentWillUnmount() {
    this.setState({
      vinInput: '',
      userFeedback: '',
      loading: false,
      error: false,
      errorMsg: '',
      errorCode: null,
      stickerPullPdf: null,
    })
  }

  /*
  downloadSticker(vinNum) {
    axios(`${apiURL}/pdf`, {
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
    })
  }
  */
  submitVin(vinInput, externalPull, referrer) {
    this.setState({
      loading: true,
      success: false,
      stickerPullPdf: null,
      ipacketViewer: null,
    })

    let pollingSticker = null
    const pollFreq = 1000
    const pollLimit = Math.abs((1000 * 60 * 15) / pollFreq) //15 minutes
    const setupPollingSticker = (id, pollCount = 0) => {
      pollingSticker = setTimeout(() => {
        console.log('Poll Count', pollCount)
        pollSticker(this.props.token, id)
          .then(response => {
            return response.json()
          })
          .then(res => {
            if (res && res.state) {
              if (res.state === 'SUCCESS') {
                if (res.ipacket_viewer) {
                  this.setState({
                    loading: false,
                    success: true,
                    ipacketViewer: res.ipacket_viewer,
                  })
                  // const link = document.createElement('a')
                  // link.href = res.ipacket_viewer
                  // link.target= '_blank'
                  // link.click()
                } else if (res.pdf) {
                  downloadSticker(this.props.token, res.pdf)
                    .then(response => {
                      if (response.status >= 200 && response.status < 300) {
                        return response.blob()
                      }
                    })
                    .then(res => {
                      const newBlob = new Blob([res], {
                        type: 'application/pdf;base64',
                      })
                      const data = window.URL.createObjectURL(newBlob)
                      const pdfObject = {
                        name: `${vinInput}`,
                        label: `${vinInput} Sticker`,
                        content_type: 'pdf',
                        url: data,
                      }
                      this.setState({
                        loading: false,
                        success: true,
                        userFeedback: '',
                        stickerPullPdf: pdfObject,
                      })
                      if (externalPull) {
                        const link = document.createElement('a')
                        link.href = data
                        link.download = `${vinInput}.pdf`
                        link.click()
                      }
                    })
                }
              } else if (res.state === 'PENDING' || res.state === 'STARTED') {
                if (pollCount < pollLimit) {
                  setupPollingSticker(id, pollCount + 1)
                } else {
                  this.setState({
                    loading: false,
                  })
                  toast('There was an issue pulling this sticker')
                }
              } else if (res.state === 'FAILED') {
                this.setState({
                  loading: false,
                })
                if (res.detail) {
                  toast(res.detail)
                } else {
                  toast('There was an issue pulling this sticker')
                }
              } else {
                this.setState({
                  loading: false,
                })
                toast('There was an issue pulling this sticker')
              }
            } else {
              this.setState({
                loading: false,
              })
              toast('There was an issue pulling this sticker')
            }
          })
      }, pollFreq)
    }
    pullSticker(this.props.token, vinInput, externalPull, referrer)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          this.setState({
            loading: false,
          })
          toast('There was an issue pulling this sticker')
        }
      })
      .then(res => {
        if (res) {
          if (res.state && res.state === 'STARTED') {
            setupPollingSticker(res.id)
          } else {
            this.setState({
              loading: false,
            })
            toast('There was an issue pulling this sticker')
          }
        } else {
          this.setState({ loading: false })
          toast('There was an issue pulling this sticker')
        }
      })
  }

  // safari large download button function
  handleSafariLargeDownloadBtn() {
    const { submittedInputVIN } = this.state
    downloadSticker(submittedInputVIN, this.props.token).then(res => {
      const newBlob = new Blob([res], { type: 'application/pdf;base64' })
      const data = window.URL.createObjectURL(newBlob)
      const pdfObject = {
        name: `${vinInput}.pdf`,
        label: `${vinInput} Sticker`,
        content_type: 'pdf',
        url: data,
      }
      this.setState({
        loading: false,
        success: true,
        error: false,
        userFeedback: '',
        stickerPullPdf: pdfObject,
      })
    })
  }

  handleChange(e) {
    this.setState({ vinInput: e.target.value })

    const vin = e.target.value

    if (vin.length !== 17) {
      e.target.setCustomValidity('Please enter a valid 17 character VIN.')
    } else {
      e.target.setCustomValidity('')
    }
  }

  handleSubmit(e) {
    const { vinInput } = this.state
    e.preventDefault()
    this.submitVin(vinInput)
    this.setState({ submittedInputVIN: vinInput })
  }

  refreshMessage() {
    //Please Forgive Me
    return (
      <React.Fragment>
        <div
          className="stickerpull-long-message"
          style={{ fontSize: '1.25em', display: 'inline-block' }}
        >
          <b>Please Wait, StickerPull is Working</b>.
        </div>
        <div
          style={{
            margin: '0 auto',
            width: '100px',
            height: '50px',
            position: 'relative',
          }}
        >
          <LoadingSpinner loading timeout={100000000000000000000} />
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {
      vinInput,
      success,
      loading,
      stickerPullPdf,
      submittedInputVIN,
      ipacketViewer,
    } = this.state

    return (
      <div style={{ position: 'relative' }}>
        <form className="stickerpull-form" onSubmit={this.handleSubmit}>
          <label style={{ display: 'none' }} htmlFor="stickerpull-input">
            Document Upload Input
          </label>
          <div className="stickerpull-input-container">
            <input
              id="stickerpull-input"
              className="stickerpull-input"
              placeholder="ENTER A VALID VIN"
              value={vinInput}
              onChange={this.handleChange}
              minlength="17"
              type="text"
              maxLength="17"
            />

            <div
              className={vinInput ? 'cancel-icon' : 'cancel-icon hidden-icon'}
              onClick={() => this.setState({ vinInput: '' })}
            >
              {`(${vinInput.length}/17)  `}
              <CancelIcon />
            </div>
          </div>
          <button className="stickerpull-submit" disabled={loading || !this.state.vinInput}>
            Submit
          </button>
        </form>
        {loading && (
          <LoadingSpinner loading wheel message={this.refreshMessage} timeout={3 * 1000} />
        )}
        {ipacketViewer && (
          <iframe className="ipacket-viewer" src={ipacketViewer} allowFullScreen></iframe>
        )}
        {stickerPullPdf && (
          <React.Fragment>
            <div className="document-viewer-container">
              <DocumentViewer module={stickerPullPdf} downloadIcon={<DownloadIcon />} />
            </div>
            {this.props.showArc ? (
              <ArcDisplay
                vin={submittedInputVIN}
                downloadSticker={() => {
                  if (Helper.isSafari()) {
                    return (
                      <button onClick={() => this.handleSafariLargeDownloadBtn()}>
                        <WindowSticker />
                        View Window Sticker/Build Sheet
                      </button>
                    )
                  }
                  return (
                    <a href={stickerPullPdf} download={`${submittedInputVIN}_IPACKET_STICKER`}>
                      <WindowSticker />
                      View Window Sticker/Build Sheet
                    </a>
                  )
                }}
              />
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        )}

        <StickerPullHistory success={success} viewSticker={this.submitVin} />
      </div>
    )
  }
}

function mapStateToProps(state: Object) {
  return {
    token: state.authentication.user.token,
    showArc: state.authentication.user.user && state.authentication.user.user.arc_enabled,
  }
}

export default connect(mapStateToProps, {
  showModalConnect: showModal,
})(StickerPuller)
