import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash/cloneDeep'
// core files
import CustomerActions from '../../../core/actions/users/customeractions'
import Helper from '../../../core/helpers/platformhelper'
import USAStateSeed from '../../../core/data/USAStateSeed'
import { hideModal } from '../../../core/_actions/modalActions'
import {
  stashViewedCustomer,
  stashCustomerTableState,
} from '../../../core/_actions/customerstashActions'
import CustomerForm from './customerform'
// wrappers
import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
// Sentry
import SentryHelper from '../../../core/helpers/sentryHelper'

// components
import Modal from '../../layouts/modal'
// styles
import '../styles/customerModal.scss'

class CustomerFormModalComponent extends Component {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, errorMessage: '', submitting: false }
  }

  submit = (customer, customerId) => {
    const { userInfo, hideModal } = this.props
    if (customerId) {
      CustomerActions.putUpdateSingleContact(
        customerId,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.phone_number,
        customer.mobile_number,
        customer.address1,
        customer.city,
        customer.state,
        customer.postal_code,
        userInfo.dj_token,
        customer.is_favorite,
      ).then(res => {
        if (res.msg || res.detail || res.non_field_errors) {
          const errorMessage =
            res.detail != '' ? res.detail : res.msg != '' ? res.msg : res.non_field_errors
          this.setState({ hasError: true, errorMessage })
          toast(errorMessage)
        } else {
          this.props.stashViewedCustomer(res)
          this.props.stashCustomerTableState(null)
          toast(`Successfully Edited Contact ${customer.first_name}`)
          this.props.updateFunction(customer, customer.is_favorite)
          hideModal()
        }
      })
      if (customer.is_favorite) {
        CustomerActions.putCreateFavoriteCustomer(
          customerId,
          userInfo.user_id,
          userInfo.dj_token,
        ).then(res => {})
      } else {
        CustomerActions.putDeleteFavoriteCustomer(
          customerId,
          userInfo.user_id,
          userInfo.dj_token,
        ).then(res => {
          this.props.updateFunction(customer, customer.is_favorite)
        })
      }
    } else {
      this.setState({ submitting: true })
      CustomerActions.putCreateSingleContact(
        userInfo.company_id,
        customer.first_name,
        customer.last_name,
        customer.email,
        customer.phone_number,
        customer.mobile_number,
        customer.address1,
        customer.city,
        customer.state,
        customer.postal_code,
        userInfo.dj_token,
        customer.is_favorite,
      ).then(res => {
        this.setState({ submitting: false })
        if (res.msg || res.detail || res.non_field_errors) {
          const errorMessage =
            res.detail != '' ? res.detail : res.msg != '' ? res.msg : res.non_field_errors
          this.setState({ hasError: true, errorMessage })
          // toast out message if defined
          if (errorMessage != undefined) {
            toast(errorMessage)
          }
        } else {
          toast(`Successfully Created Contact ${customer.first_name}`)
          hideModal()
        }
        if (customer.is_favorite) {
          CustomerActions.putCreateFavoriteCustomer(
            res.customer_id,
            userInfo.user_id,
            userInfo.dj_token,
          ).then(res => {})
        }
      })
    }
  }
  render() {
    const { hasError, errorMessage, submitting } = this.state
    const { customer, customerId, displayFullForm } = this.props

    return (
      <Modal>
        <CustomerForm
          displayFullForm={displayFullForm}
          customer={cloneDeep(customer)}
          customerId={customerId}
          submit={this.submit}
          submitting={submitting}
          hasError={hasError}
          errorMessage={errorMessage}
        />
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  customer: state.modal.props.customer,
  customerId: state.modal.props.customerId,
  displayFullForm: state.modal.props.displayFullForm,
  updateFunction: state.modal.props.updateFunction,
})
const CustomerFormModal = UserInfoWrapper(CustomerFormModalComponent)
export default connect(mapStateToProps, {
  hideModal,
  stashViewedCustomer,
  stashCustomerTableState,
})(CustomerFormModal)
