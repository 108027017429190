export const FILTER_CARS = 'FILTER_CARS'
export const FETCH_PACKETS = 'FETCH_PACKETS'
export const UPDATE_ACTIVE_FILTERS = 'UPDATE_ACTIVE_FILTERS'
export const UPDATE_ACTIVE_PACKETS = 'SORT_ACTIVE_PACKETS'
export const UPDATE_BASKET = 'UPDATE_BASKET'
export const EMPTY_BASKET = 'EMPTY_BASKET'
export const UPDATE_PAGE = 'UPDATE_PAGE'
export const SEND_PACKETS = 'SEND_PACKETS'
export const GET_INVENTORY_CONTENT = 'GET_INVENTORY_CONTENT'
export const GET_FILTER_OPTIONS = 'GET_FILTER_OPTIONS'
export const SET_LOADING = 'SET_LOADING'
export const SEARCH_INVENTORY = 'SEARCH_INVENTORY'
export const ACTIVE_SINGLE_SEND = 'ACTIVE_SINGLE_SEND'
export const GET_FACET_OPTIONS = 'GET_FACET_OPTIONS'
export const UPDATE_FACET_OPTIONS = 'UPDATE_FACET_OPTIONS'
export const UPDATE_SORT_BY = 'UPDATE_SORT_BY'
export const CLEAR_FACET_OPTIONS = 'CLEAR_FACET_OPTIONS'
export const SET_SEARCH_SUBMISSION = 'SET_SEARCH_SUBMISSION'
