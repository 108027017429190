import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, browserHistory } from 'react-router-dom'
import LoadData from './loaddata'
import ModalContainer from './web/content/modals/modalContainer'
import MainRouter from './routes'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './web/content/styles/toasts.scss'
import './styles/index.css' // legacy styles
import './web/content/styles/universalstyles.scss' //
import { updateUserLoginInfo } from './web/core/actions/users/useractions'

function App(props) {
  const { access_token, checkedLoginStatus } = props.authentication
  useEffect(() => {
    if (access_token) {
      updateUserLoginInfo()
    }
  }, [])
  if ((access_token && checkedLoginStatus) || !access_token) {
    return (
      <Router history={browserHistory}>
        <div style={{ height: '100%' }} className="outerHoldingDiv">
          {checkedLoginStatus ? <LoadData /> : null}
          <ModalContainer />
          <MainRouter />
          <ToastContainer
            toastClassName="default-toast"
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
            hideProgressBar={true}
            draggable
          />
        </div>
      </Router>
    )
  }
  return <React.Fragment />
}
const mapStateToProps = state => ({
  authentication: state.authentication,
})
const mapDispatchToProps = null
export default connect(mapStateToProps, mapDispatchToProps)(App)
