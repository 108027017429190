// general: entire logo component.

// Note: the styles for this file are driven by the 'styleClassName' property that
// is passed down in the parent component.

// @flow
import React, { PureComponent } from 'react'
// images
import AIPLogo from '../../core/assets/images/iPacketLogo.png'

type Props = {
  styleClassName: Object,
}

export default class Logo extends PureComponent {
  props: Props

  render() {
    return <img src={AIPLogo} className={this.props.styleClassName} alt="AutoIPacket_Logo" />
  }
}
