import React from 'react'

const IconDealership = props => {
  if (props.withSign) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-dealership"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 342.67 196.33"
      >
        <defs />
        <title>dealership</title>
        <path
          d="M279.34,187.88c6.76,1,13.76,1.16,20.23,3.05,7.06,2.06,13.72,5.51,20.37,8.71a7,7,0,0,1,3.49,4.52,81.31,81.31,0,0,1,1.08,15.33c-.24,5.68-2.79,7.86-8.6,9.06a4.76,4.76,0,0,0-2.12,1.3c-5.56,5.35-12.58,5.37-18.33.17a5.83,5.83,0,0,0-3.55-1.38q-26.7-.13-53.41,0a6.19,6.19,0,0,0-3.77,1.52c-5.07,4.59-10.59,4.81-16,.66a10.42,10.42,0,0,0-4.92-2.27c-8.09-.86-7.94-1.76-8.27-10.14-.09-2.17.35-4.36.26-6.54-.12-3,1-5.06,3.92-6,5.6-1.68,11.27-3.2,16.88-4.87,6-1.78,12.25-3,17.78-5.66a67.45,67.45,0,0,1,30.1-6.66c1.57,0,3.14,0,4.72,0Z"
          transform="translate(-19.59 -68.91)"
        />
        <path
          d="M104.41,234c0-9,0-17.39,0-25.79,0-.47,0-1.22.29-1.35,2.83-1.41,1.77-3.92,1.78-6,.06-15.87,0-31.74.1-47.61,0-2.71-.88-3.44-3.53-3.39-7.18.13-14.37,0-21.55.09-2.55,0-3.46-.74-3.4-3.28.15-6.89.1-13.77,0-20.66,0-1.89.42-2.73,2.59-2.73,21.65.07,43.3,0,64.95,0,.38,0,.77.09,1.59.19v7.61c0,5.26-.12,10.52.05,15.78.09,2.45-.83,3.08-3.21,3-7.18-.13-14.37,0-21.55-.09-2.71-.06-3.79.59-3.76,3.49.14,16.54.06,33.09.08,49.63,0,1.34-.48,2.87,1.53,3.55.4.14.58,1.35.58,2.07,0,7.75.08,15.49,0,23.24,0,.73-1,2.06-1.53,2.08C114.54,234.05,109.63,234,104.41,234Z"
          transform="translate(-19.59 -68.91)"
        />
        <path
          d="M238.38,195.21c-2.36.74-4.7,1.59-7.1,2.2-7,1.78-14.11,3.27-21.06,5.25-5.72,1.64-7.31,4.57-7,10.27.22,3.83-1.55,7.66.12,11.52a22.88,22.88,0,0,1,.86,4.13H201c-10.92,0-21.85-.05-32.77.07a6.09,6.09,0,0,0-3.7,1.56,12.71,12.71,0,0,1-17.72.06,8.49,8.49,0,0,0-4.32-1.84c-5.9-.82-9-6-6.84-11.57a8.55,8.55,0,0,0,.32-3.61c-.33-5.15.08-6.32,5.05-8,5.27-1.76,10.8-2.76,16.14-4.34,6.07-1.81,12.27-3.42,18-6,7.32-3.27,14.77-5.7,22.82-6,7-.25,14-.09,20.94-.12s13.11,2.85,19.33,5.37Z"
          transform="translate(-19.59 -68.91)"
        />
        <path
          d="M147.25,119c-1.47.08-2.53.18-3.6.18-20.57,0-41.14-.05-61.71.06-3,0-3.94-.78-3.85-3.72.22-6.69.14-13.39,0-20.08,0-2.24.74-3,3.07-3,21.06.07,42.12,0,63.18,0,1.3,0,2.9-.4,2.89,1.78C147.23,102.36,147.25,110.48,147.25,119Z"
          transform="translate(-19.59 -68.91)"
        />
      </svg>
    )
  } else if (props.withBuilding) {
    return (
      <svg
        className="svg-dealership with-building"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 457.07 142.89"
      >
        <title>locationsvg</title>
        <path
          d="M112.75,0H0V135.29H216.86a8.63,8.63,0,0,0,8.64-8.63V0ZM52.53,53.67H23.08V19.15H52.53Zm12.8-34.52H94.78V53.67H65.33ZM109.5,130.71H80.05V67.65H109.5Zm35.95,0H116V67.65h29.45Zm14.72-77H130.72V19.15h29.45Zm42.25,0H173V19.15h29.45Z"
          transform="translate(0 0)"
        />
        <path
          d="M372.49,87.59c-.42-2.9-3.23-6.52-5.91-7.86-11.26-5.58-22.53-11.59-34.48-15.17-11-3.29-12.93-2-24.38-3.7-3.55-.21-10.89-.13-10.05-.13-2.67,0-5.33,0-8,0a111.42,111.42,0,0,0-51,11.59c-9.36,4.65-20,6.76-30.09,9.86-9.5,2.92-19.08,5.56-28.58,8.49-5,1.54-6.83,5.22-6.62,10.37.15,3.78-.6,7.6-.45,11.38.57,14.6.31,16.17,14,17.67,2.9.31,6,2.07,8.32,3.95,9.1,7.24,18.45,6.85,27-1.15a10.38,10.38,0,0,1,6.39-2.65q45.21-.26,90.41,0a9.74,9.74,0,0,1,6,2.41,29.15,29.15,0,0,0,3.66,2.91,4.64,4.64,0,0,1,4.29-2.68c6.28-.12,12.46-1.48,18.75-1.25a4.73,4.73,0,0,1,3.46,1.52c.29-.27.58-.52.87-.8a7.94,7.94,0,0,1,3.58-2.26c9.84-2.1,14.16-5.89,14.56-15.79A146,146,0,0,0,372.49,87.59Z"
          transform="translate(0 0)"
          style={{ stroke: '#fff', strokeMiterlimit: 10, strokeWidth: '3px' }}
        />
        <path
          d="M386.9,60.86c11.45,1.66,13.42.41,24.38,3.7,12,3.58,23.22,9.59,34.47,15.17,2.69,1.34,5.5,5,5.92,7.86a146,146,0,0,1,1.82,26.71c-.4,9.9-4.72,13.69-14.56,15.79a7.94,7.94,0,0,0-3.58,2.26c-9.42,9.32-21.29,9.36-31,.3a9.74,9.74,0,0,0-6-2.41q-45.21-.24-90.41,0a10.38,10.38,0,0,0-6.39,2.65c-8.57,8-17.92,8.39-27,1.15-2.36-1.88-5.42-3.64-8.32-3.95-13.7-1.5-13.44-3.07-14-17.67-.15-3.78.6-7.6.45-11.38-.21-5.15,1.63-8.83,6.62-10.37,9.5-2.93,19.08-5.57,28.58-8.49,10.1-3.1,20.73-5.21,30.09-9.86a111.42,111.42,0,0,1,50.95-11.59c2.66,0,5.32,0,8,0C376,60.73,383.35,60.65,386.9,60.86Z"
          transform="translate(0 0)"
          style={{ stroke: '#fff', strokeMiterlimit: 10, strokeWidth: '3px' }}
        />
      </svg>
    )
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-dealership"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 342.67 196.33"
    >
      <defs />
      <title>dealership</title>
      <path
        d="M279.34,187.88c6.76,1,13.76,1.16,20.23,3.05,7.06,2.06,13.72,5.51,20.37,8.71a7,7,0,0,1,3.49,4.52,81.31,81.31,0,0,1,1.08,15.33c-.24,5.68-2.79,7.86-8.6,9.06a4.76,4.76,0,0,0-2.12,1.3c-5.56,5.35-12.58,5.37-18.33.17a5.83,5.83,0,0,0-3.55-1.38q-26.7-.13-53.41,0a6.19,6.19,0,0,0-3.77,1.52c-5.07,4.59-10.59,4.81-16,.66a10.42,10.42,0,0,0-4.92-2.27c-8.09-.86-7.94-1.76-8.27-10.14-.09-2.17.35-4.36.26-6.54-.12-3,1-5.06,3.92-6,5.6-1.68,11.27-3.2,16.88-4.87,6-1.78,12.25-3,17.78-5.66a67.45,67.45,0,0,1,30.1-6.66c1.57,0,3.14,0,4.72,0Z"
        transform="translate(-19.59 -68.91)"
      />
      =
      <path
        d="M238.38,195.21c-2.36.74-4.7,1.59-7.1,2.2-7,1.78-14.11,3.27-21.06,5.25-5.72,1.64-7.31,4.57-7,10.27.22,3.83-1.55,7.66.12,11.52a22.88,22.88,0,0,1,.86,4.13H201c-10.92,0-21.85-.05-32.77.07a6.09,6.09,0,0,0-3.7,1.56,12.71,12.71,0,0,1-17.72.06,8.49,8.49,0,0,0-4.32-1.84c-5.9-.82-9-6-6.84-11.57a8.55,8.55,0,0,0,.32-3.61c-.33-5.15.08-6.32,5.05-8,5.27-1.76,10.8-2.76,16.14-4.34,6.07-1.81,12.27-3.42,18-6,7.32-3.27,14.77-5.7,22.82-6,7-.25,14-.09,20.94-.12s13.11,2.85,19.33,5.37Z"
        transform="translate(-19.59 -68.91)"
      />
    </svg>
  )
}
export default IconDealership
