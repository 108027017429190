import React, { Component } from 'react'
import { hideModal } from '../../../core/_actions/modalActions'
import { connect } from 'react-redux'

import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'
import Modal from '../../layouts/modal'
import UserActions from '../../../core/actions/users/useractions'
import ReportIssueAnimation from '../../helpers/reportissueanimation'

import '../styles/reportissuemodal.scss'

class ReportIssueModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      url: '',
      sent: false,
      userAgent: null,
    }
  }
  componentDidMount() {
    this.setState({
      url: window.location.href,
      userAgent: navigator.userAgent,
    })
  }
  updateTextField = e => {
    let updatedField = {}
    updatedField[e.target.id] = e.target.value
    this.setState(updatedField)
  }
  sendMessage = () => {
    const { userInfo, vehicleId } = this.props
    const { message } = this.state
    let newMessage = ''
    if (vehicleId) {
      newMessage += `VehicleId: ${vehicleId}\n`
    }
    newMessage += `URL: ${window.location.href}\nBrowser Agent: ${navigator.userAgent}\nMessage\n-------\n${message}`
    console.log(userInfo.user_email_address)
    console.log(userInfo.first_name)
    console.log(userInfo.last_name)
    let title = 'Reported Issue For Dealer Portal'
    console.log(title)
    console.log(newMessage)
    UserActions.postSendSupportEmail(
      userInfo.dj_token,
      userInfo.user_id,
      userInfo.user_email_address,
      userInfo.first_name,
      userInfo.last_name,
      title,
      newMessage,
    ).then(res => {
      console.log(res)
      if (res) {
        this.setState({ sent: true })
      }
    })
    //this.setState({sent:true})
  }
  render() {
    const { message, sent } = this.state
    if (sent) {
      return (
        <Modal>
          <div style={{ animation: '500ms fadeIn' }}>
            <ReportIssueAnimation />
            <h1>Support has received your issue</h1>
          </div>
        </Modal>
      )
    }
    return (
      <Modal>
        <div>
          <div className="report-issue-container">
            <h1>Report Issue</h1>
            <textarea
              id="message"
              placeholder="Type a detailed description of the issue here..."
              value={this.state.message}
              onChange={this.updateTextField}
              maxLength="4000"
              autoFocus
            />
            <div className="report-issue-field">
              <label>URL</label>
              <input
                id="url"
                value={this.state.url}
                onChange={this.updateTextField}
                maxLength="200"
              />
            </div>
            <button
              className="report-issue-submit"
              onClick={this.sendMessage}
              disabled={message.length < 1}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = state => ({
  vehicleId: state.modal.props.vehicleId,
})
const ReportIssueModal = UserInfoWrapper(ReportIssueModalComponent)

export default connect(mapStateToProps, {
  hideModalConnect: hideModal,
})(ReportIssueModal)
