import React from 'react'

const IconAddTeam = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
      <title>Untitled-3</title>
      <path d="M62.53,31.28a45.91,45.91,0,0,0-52.8,71.06A37.89,37.89,0,0,1,33.42,77.6a18.84,18.84,0,1,1,25,0A37.86,37.86,0,0,1,82.1,102.34a45.93,45.93,0,0,0,6.62-44.88A27.32,27.32,0,0,1,62.53,31.28Z" />
      <path d="M51.51,87.52l-.06-2.28c0-.84-1.06-1.51-2.31-1.5l-6.57.08c-1.25,0-2.26.71-2.24,1.55l.05,2.07s0,0,0,.06,0,.07,0,.1v.05a1.57,1.57,0,0,0,0,.23c.29,1.88,2.63,3.35,5.5,3.35s5-1.34,5.47-3.09A1.84,1.84,0,0,0,51.51,87.52Z" />
      <path d="M45.9,120a46.15,46.15,0,0,0,8.11-.73L49,95a1.91,1.91,0,0,0-1.87-1.55H44.76A1.92,1.92,0,0,0,42.89,95l-5.06,24.23v.07A46.58,46.58,0,0,0,45.9,120Z" />
      <path d="M95.08,0A24.92,24.92,0,1,0,120,24.92,24.92,24.92,0,0,0,95.08,0Zm15.46,28.82H99V40.37a3,3,0,0,1-3,3H94.19a3,3,0,0,1-3-3V28.82H79.64a3,3,0,0,1-3-3V24a3,3,0,0,1,3-3H91.18V9.47a3,3,0,0,1,3-3H96a3,3,0,0,1,3,3V21h11.56a3,3,0,0,1,3,3v1.78A3,3,0,0,1,110.54,28.82Z" />
    </svg>
  )
}
export default IconAddTeam
