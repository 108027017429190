// general:
// @flow
import React, { useState, Fragment } from 'react'
import { toast } from 'react-toastify'
// node packages
// --- redux
import { connect } from 'react-redux'
import {
  recordDocumentPrint,
  initiateVdpPrint,
  retrieveVdpPrint,
} from '../../../../core/actions/packets/packetactions.js'
// icons
import PrintIcon from '../../../../core/assets/images/icons/print-icon.js'
import ArchiveIcon from '../../../../core/assets/images/icons/archive-icon.js'
import CheckCircleIcon from '../../../../core/assets/images/icons/check-circle-icon.js'
import OpenNewIcon from '../../../../core/assets/images/icons/open-pdf-new.js'
import CircleIcon from '../../../../core/assets/images/icons/circle-icon.js'
// helper
import LoadingSpinner from '../../../helpers/loadingspinner.js'
// css styles
import '../../../styles/universalstyles.scss'
import '../../styles/packetsend.scss'

function SendPrint(props) {
  const { packet_modules, token, vehicle_id, vehicle_info } = props
  const [selectedModules, setSelectedModules] = useState([])
  const [loading, setLoading] = useState(false)

  const isPrintable = module => {
    const isPdf = module.content_type === 'pdf'
    const isImage = module.content_type === 'image'
    const isServiceHistory = module.module_id === 4
    return isPdf || isImage || isServiceHistory
  }

  const printableBoolean = packet_modules.some(module => isPrintable(module))
  const printableModules = packet_modules.filter(module => isPrintable(module))

  const pageType = vehicle_info.archived ? true : false

  const selectedModuleStyle = module => {
    return selectedModules.includes(module) ? 'selected' : ''
  }

  const handleModuleClick = module => {
    if (selectedModules.includes(module)) {
      setSelectedModules(selectedModules.filter(selectedModule => selectedModule !== module))
    } else {
      setSelectedModules([...selectedModules, module])
    }
  }

  const handleSinglePrint = module => {
    setLoading(true)
    recordDocumentPrint({ token, vehicle_id })
    window.open(module.url, '_blank', 'noopener, noreferrer')
    setLoading(false)
  }

  const handlePrint = printType => {
    setLoading(true)

    let pollRetrievePrint

    const printOptionsBool =
      printType === 'All' || selectedModules.length === printableModules.length ? true : false

    const initiatePrintParams = printOptionsBool
      ? {
          token,
          vehicle_id,
        }
      : {
          token,
          vehicle_id,
          modules: selectedModules,
        }

    const setupPollRetrievePrint = printId => {
      pollRetrievePrint = setTimeout(() => {
        retrieveVdpPrint(token, printId)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              return response.json()
            } else {
              setLoading(false)
              toast('There was an issue printing your document. Please try again later')
            }
          })
          .then(res => {
            if (res.state === 'SUCCESS' && res.s3_link) {
              recordDocumentPrint({ token, vehicle_id })
              setLoading(false)
              window.open(res.s3_link, `_blank`)
            } else if (res.state === 'STARTED') {
              setupPollRetrievePrint(printId)
            } else {
              setLoading(false)
              toast('There was an issue printing your document. Please try again later')
            }
          })
      }, 1000)
    }

    initiateVdpPrint({ ...initiatePrintParams })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          setLoading(false)
          toast('There was an issue printing your document. Please try again later')
        }
      })
      .then(res => {
        if (res && res.id && res.state && res.state === 'STARTED') {
          setupPollRetrievePrint(res.id)
        } else {
          setLoading(false)
          toast('There was an issue printing your document. Please try again later')
        }
      })

    return
  }

  const printIcon = pageType ? (
    <span className="icon-container">
      <ArchiveIcon />
    </span>
  ) : (
    <span className="icon-container">
      <PrintIcon />
    </span>
  )

  const printModuleIcon = module => {
    if (module.module_id === 4) {
      return (
        <span className="checkmark-icon new-tab-icon">
          <OpenNewIcon />
        </span>
      )
    }

    if (selectedModules.includes(module)) {
      return (
        <span className="checkmark-icon">
          <CheckCircleIcon />
        </span>
      )
    }

    return (
      <span className="checkmark-icon">
        <CircleIcon />
      </span>
    )
  }

  if (loading) {
    return (
      <Fragment>
        <LoadingSpinner loading wheel />
      </Fragment>
    )
  }

  if (!printableBoolean) {
    return <Fragment>There are no printable modules for this vehicle at this time.</Fragment>
  }

  return (
    <div className="packetSendMiddleContentStyle">
      <h1 className="sendprint-header">{pageType ? 'Download Archive' : 'Print Document'}</h1>

      <div className="print-modules-container">
        {printableModules.map((module, index) => {
          return (
            <button
              key={index}
              className={`module-button packetSendButtonsStyle ${selectedModuleStyle(module)}`}
            >
              <span
                className="module-button-content"
                onClick={() =>
                  module.module_id === 4 ? handleSinglePrint(module) : handleModuleClick(module)
                }
              >
                {printIcon}
                {module.label}
              </span>
              {printModuleIcon(module)}
            </button>
          )
        })}
      </div>

      <div className="print-buttons">
        <button
          onClick={() =>
            selectedModules.length === 1
              ? handleSinglePrint(selectedModules.at(0))
              : handlePrint('Selected')
          }
          disabled={selectedModules.length === 0}
          className="module-button packetSendButtonsStyle"
        >
          <span className="module-button-content">
            {pageType ? 'Download Selected' : 'Print Selected'}
            {printIcon}
            {selectedModules.length > 0 ? (
              <span className="selected">
                ({selectedModules.length}/{printableModules.length})
              </span>
            ) : null}
          </span>
        </button>
        <button onClick={() => handlePrint('All')} className="module-button packetSendButtonsStyle">
          <span className="module-button-content">
            {pageType ? 'Download Archive' : 'Print All'}
            {printIcon}
          </span>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  vehicle_id: state.packetstash.vehicle_info.vehicle_id,
  vehicle_info: state.packetstash.vehicle_info,
  packet_modules: state.packetstash.module_info,
})

export default connect(mapStateToProps)(SendPrint)
