/*
 * src/store.js
 * No initialState
 */

import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../_reducers/rootReducer.js'
// import { createLogger } from 'redux-logger'
import runtimeEnv from '../config/envConstants'
// redux-persist for preserving state between page loads
// import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// redux-persist configurations
// const persistConfig = {
//   key: 'redux',
//   storage,
//   whitelist: [],
// }
//PLEASE READ!!
//Future Note I believe storing the serialized profile image is too large for the Redux Persist and is causing an error storing data exception
// const persistedReducer = persistReducer(persistConfig, rootReducer)

// redux-logger configuration
// const logger = createLogger({
//   collapsed: true,
// })

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// apply middlewares
const reduxMiddleware = [thunk].filter(Boolean)

export default function configureStore() {
  let store = createStore(rootReducer, composeEnhancers(applyMiddleware(...reduxMiddleware)))
  return { store }
}
