// These values are replaced by webpack at compile time
// our DB records send times as either EST or EDT :(
function isDST(currentDate) {
  const january = new Date(currentDate.getFullYear(), 0, 1).getTimezoneOffset()
  const july = new Date(currentDate.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(january, july) !== currentDate.getTimezoneOffset()
}

const serverOffset = isDST(new Date()) ? '-04:00' : '-05:00'
let timezoneAbbr = 'unknown'
try {
  timezoneAbbr = /.*\s(.+)/.exec(
    new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' }),
  )[1]
} catch (e) {
  // Less accurate case for EST
  timezoneAbbr = new Date()
    .toTimeString()
    .match(new RegExp('[A-Z](?!.*[(])', 'g'))
    .join('')
}
const runtime = {
  isProd: __ISPROD__,
  usingSync: __ISBROWSERSYNC__,
  usingOwnAPI: __USELOCALAPI__,
  appVersion: __APPVERSION__,
  timezoneAbbr,
  // our primary DB records times as either EST or EDT :(
  dataTimeOffset: serverOffset,
  clientTZ: Intl.DateTimeFormat().resolvedOptions().timeZone,
}

module.exports = runtime
