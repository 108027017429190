import React from 'react'

const TopInventory = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M46.11,18.49H41.82a10.6,10.6,0,0,0-9.94-8.87,52.43,52.43,0,0,0-14.67.24,10.32,10.32,0,0,0-5.43,2.25,10.5,10.5,0,0,0-3.62,6.38H3.85a1.23,1.23,0,0,0-1.23,1.23v1.49a2.65,2.65,0,0,0,2.61,2.65,0,0,0,0,1,0,0A8,8,0,0,0,3.85,28.4v.86a8.1,8.1,0,0,0,2.5,5.83h0v3.19a2.5,2.5,0,0,0,2.5,2.5h2.42a2.51,2.51,0,0,0,2.5-2.5v-.92l0,0H36.22l0,0v.91a2.51,2.51,0,0,0,2.5,2.51h2.42a2.51,2.51,0,0,0,2.5-2.5V35h0a8.05,8.05,0,0,0,2.42-5.75V28.4a8,8,0,0,0-1.39-4.51s0,0,0,0h0a2.68,2.68,0,0,0,2.67-2.67V19.76A1.27,1.27,0,0,0,46.11,18.49Zm-35,1.8a0,0,0,0,1,0,0,7.76,7.76,0,0,1,7.75-7.73c1.9-.13,3.92-.22,6.07-.22s4.18.09,6.08.22a7.92,7.92,0,0,1,5.48,2.27,7.79,7.79,0,0,1,2.27,5.46,0,0,0,0,1,0,0Z" />
      <circle className="a" cx="12.33" cy="29.31" r="3.39" />
      <circle className="a" cx="37.69" cy="29.31" r="3.39" />
    </svg>
  )
}
export default TopInventory
