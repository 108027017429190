import React from 'react'

const TopLookup = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <rect className="a" x="4" y="4" width="42" height="42" rx="4.92" />
      <rect className="f" x="7.49" y="35.76" width="35.03" height="7.21" rx="2.01" />
      <rect className="f" x="7.51" y="7.03" width="4.6" height="25.4" rx="2.01" />
      <rect className="f" x="15.11" y="7.03" width="4.6" height="17.75" rx="2.01" />
      <rect className="f" x="22.71" y="7.03" width="4.6" height="25.4" rx="2.01" />
      <rect className="f" x="30.31" y="7.03" width="4.6" height="17.75" rx="2.01" />
      <rect className="f" x="37.91" y="7.03" width="4.6" height="25.4" rx="2.01" />
      <path
        className="e"
        d="M42.64,33.48,36.3,27.14a14.69,14.69,0,1,0-13.38,8.64,14.57,14.57,0,0,0,5.8-1.19L35.13,41a4.61,4.61,0,0,0,6.5,0l1-1A4.62,4.62,0,0,0,42.64,33.48Zm-19.72-6.2a6.43,6.43,0,1,1,6.42-6.43A6.43,6.43,0,0,1,22.92,27.28Z"
      />
      <path
        className="d"
        d="M40.7,34.49l-4.92-4.93a2.66,2.66,0,0,0-3-.51l-.41-.46a12.4,12.4,0,1,0-1.85,1.8l.42.47a2.66,2.66,0,0,0,.5,3.06l4.92,4.93a2.7,2.7,0,0,0,3.78,0l.58-.59A2.67,2.67,0,0,0,40.7,34.49ZM14.21,20.85a8.71,8.71,0,1,1,8.71,8.71A8.71,8.71,0,0,1,14.21,20.85Z"
      />
    </svg>
  )
}
export default TopLookup
