// packages
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
// icons
import ArrowDown from '../../core/assets/images/icons/arrow-down_icon'
import CancelIcon from '../../core/assets/images/icons/cancel-icon'
import CircleIcon from '../../core/assets/images/icons/circle-icon'
// styles
import './styles/dropdown.scss'

Dropdown.propTypes = {
  // Placeholder that appears when no item is selected. The placeholder persists when multiselect is true.
  placeholder: PropTypes.string,
  // The label that appears above the dropdown
  label: PropTypes.string,
  // data passed into the dropdown
  data: PropTypes.array.isRequired,
  // a true or false value indicating whether the dropdown supports multiselection
  multiselect: PropTypes.bool,
  // a callback function that retrieves selected items. This function is passed the list of selected items.
  getSelectedItems: PropTypes.func,
  // a custom icon that overrides the default circle-icon alongside the data in the dropdown
  customIcon: PropTypes.func,
}

export default function Dropdown({
  placeholder,
  label,
  data,
  multiselect,
  getSelectedItems,
  customIcon,
}) {
  const [defaultPlaceHolder, setPlaceHolder] = useState('Select an Option')
  const defaultLabel = 'Label'

  const [expanded, setExpanded] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const [selectAll, setSelectAll] = useState(false)
  const iconClass = expanded ? 'icon-expanded' : 'icon'
  const dataClass = expanded ? 'data-expanded' : 'data'

  const checkProps = () => {
    if (placeholder !== (null || undefined)) {
      setPlaceHolder(placeholder)
    }
  }

  const handleClickEvents = () => {
    setExpanded(!expanded)
  }

  const closeDropdown = () => {
    if (expanded) {
      setExpanded(false)
    }
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectAll(false)
      setSelectedItems([])
      getSelectedItems('remove-all')
    } else {
      setSelectAll(true)
      setSelectedItems(data)
      getSelectedItems('add-all', data)
    }
  }

  const selectItem = selectedItem => {
    if (multiselect) {
      if (selectedItems.includes(selectedItem)) {
        removeItem(selectedItem)
      } else {
        setSelectedItems(selectedItems => [...selectedItems, selectedItem])
        getSelectedItems('add-item', selectedItem)
        if (selectedItems.length === data.length) {
          setSelectAll(true)
        }
      }
    } else {
      setSelectedItems([])
      setSelectedItems(selectedItems => [...selectedItems, selectedItem])
      getSelectedItems('add-item', selectedItem)
      setPlaceHolder(selectedItem)
      setExpanded(false)
    }
  }

  const removeItem = clickedItem => {
    setSelectedItems(selectedItems.filter(item => item !== clickedItem))
    if (selectAll) {
      setSelectAll(false)
    }
    getSelectedItems('remove-item', clickedItem)
  }

  const renderListItems = data => {
    if (typeof data[0] === ('string' || 'number')) {
      return (
        <React.Fragment>
          {data.map(row => {
            return (
              <li className="data-row" key={data.indexOf(row)} onClick={() => selectItem(row)}>
                <span
                  className={
                    selectedItems.includes(row) ? 'data-row-icon-selected' : 'data-row-icon'
                  }
                >
                  {customIcon ? customIcon() : <CircleIcon className="default" />}
                </span>
                <span className="data-row-text">{row}</span>
              </li>
            )
          })}
        </React.Fragment>
      )
    } else if (typeof data[0] === 'object') {
      return (
        <React.Fragment>
          {data.map(row => {
            return (
              <li className="data-row" key={row.id} onClick={() => selectItem(row)}>
                <span
                  className={
                    selectedItems.includes(row) ? 'data-row-icon-selected' : 'data-row-icon'
                  }
                >
                  {customIcon ? customIcon() : <CircleIcon className="default" />}
                </span>
                <span className="data-row-text">{row.name}</span>
              </li>
            )
          })}
        </React.Fragment>
      )
    }
  }

  const renderSelectedItems = items => {
    if (typeof data[0] === ('string' || 'number')) {
      return (
        <React.Fragment>
          {items.map(item => {
            return (
              <li className="selected-item" key={item} value={item}>
                <div className="text">{item}</div>
                <span className="cancel-icon" onClick={() => removeItem(item)}>
                  <CancelIcon />
                </span>
              </li>
            )
          })}
        </React.Fragment>
      )
    } else if (typeof data[0] === 'object') {
      return (
        <React.Fragment>
          {items.map(item => {
            return (
              <li className="selected-item" key={item.id} value={item.name}>
                <div className="text">{item.name}</div>
                <span className="cancel-icon" onClick={() => removeItem(item)}>
                  <CancelIcon />
                </span>
              </li>
            )
          })}
        </React.Fragment>
      )
    }
  }

  window.addEventListener('mouseup', closeDropdown)

  useEffect(() => {
    checkProps()
    setSelectedItems([])
  }, [])

  return (
    <React.Fragment>
      <div className="dropdown-container">
        <div className="dropdown">
          <li className="label">{label === undefined ? defaultLabel : label}</li>
          <li className="placeholder" onClick={() => handleClickEvents()}>
            {defaultPlaceHolder}
          </li>
          <span className={iconClass} onClick={() => handleClickEvents()}>
            <ArrowDown />
          </span>
          <ul className={dataClass} aria-expanded={expanded}>
            {multiselect && (
              <li className="checkbox">
                <div className="input">
                  <input
                    type="checkbox"
                    name="unsubscribeAll"
                    checked={selectAll}
                    onChange={() => handleSelectAll()}
                  />
                </div>
                <label htmlFor="unsubscribeAll">Unsubscribe To All</label>
              </li>
            )}
            {data !== (null || undefined) && data.length > 0 ? renderListItems(data) : null}
          </ul>
        </div>
      </div>

      {multiselect && selectedItems.length > 0 && (
        <ul className="selected-items">
          {selectedItems.length > 0 && renderSelectedItems(selectedItems)}
        </ul>
      )}
    </React.Fragment>
  )
}
