import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { withRouter, NavLink } from 'react-router-dom'
// helpers
import { showModal, hideModal } from '../../../core/_actions/modalActions'
import { searchCompanyStores, changeCompanyStore } from '../../../core/actions/stafftoolactions'
import { CHANGE_STORE_MODAL } from '../../../core/_constants/modalTypes'
// components
import LoadingSpinner from '../../helpers/loadingspinner'
import Paginator from '../../helpers/paginator'
// assets
import SearchPNG from '../../../core/assets/images/icons/search.png'
import CancelPNG from '../../../core/assets/images/icons/cancel_black.png'

class ChangeStoreComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      foundStores: null,
      page: 1,
      count: 0,
      loading: false,
    }
  }
  changeCompanyStore = company_store_id => {
    const { token, history } = this.props
    changeCompanyStore({ token, company_store_id, entire_company_access: true, history })
  }
  clearSearch = () => {
    this.debounceSearch.cancel()
    this.setState({
      search: '',
      foundStores: null,
      page: 1,
      count: 0,
      loading: false,
    })
  }
  updatePage = page => {
    this.setState({ page }, () => {
      const { search } = this.state
      this.updateSearch(search)
    })
  }
  updateSearch = search => {
    if (search.length === 0) {
      this.clearSearch()
    } else {
      this.setState({ search, loading: true, foundStores: null }, () => {
        this.debounceSearch()
      })
    }
  }
  search = () => {
    const { search, page } = this.state
    const { token } = this.props
    searchCompanyStores({ token, page, search }).then(res => {
      if (res && res.results) {
        console.log(res)
        this.setState({ foundStores: res.results, count: res.count, loading: false })
      } else {
        this.setState({ loading: false })
      }
    })
  }
  debounceSearch = debounce(this.search, 1000)
  componentDidMount() {
    this.searchInput.focus()
  }
  render() {
    const { search, page, count, foundStores, loading } = this.state
    return (
      <div className="staff-tools-change-store staff-tools-sub-page">
        <h1> Store Search</h1>
        <h2>
          View detailed store overview {this.props.is_staff ? 'or change to another store' : ''}
        </h2>
        <span />
        <div className="staff-tools-change-store-input">
          <label style={{ display: 'none' }} htmlFor="store-search-input">
            Store Search Input
          </label>
          <input
            id="store-search-input"
            placeholder="Type here to begin your search"
            type="text"
            value={search}
            ref={input => {
              this.searchInput = input
            }}
            onChange={e => {
              this.updateSearch(e.target.value)
            }}
          />
          <button
            className="staff-tools-change-store-input-btn"
            onClick={() => {
              if (search.length > 0) {
                this.clearSearch()
              }
            }}
          >
            <img
              className=""
              src={search.length > 0 ? CancelPNG : SearchPNG}
              alt="cancel-or-search"
            />
          </button>
        </div>
        {search.length > 0 && (
          <Paginator page={page} updatePage={this.updatePage} numPages={Math.ceil(count / 50)} />
        )}
        {foundStores && (
          <React.Fragment>
            <div
              className="staff-tools-change-store-result-count"
              style={{ textAlign: 'left', textIndent: '5%' }}
            >
              {count > 0 && (
                <h1
                  onClick={() => {
                    this.updatePage(2)
                  }}
                >
                  {' '}
                  {`Displaying ${(page - 1) * 50 + 1}-${
                    page * 50 > count ? count : page * 50
                  } of ${count} result${count > 1 ? 's' : ''}`}
                </h1>
              )}
            </div>
            <div className="store-search-results" style={{ animation: ` 2000ms fadeIn` }}>
              {foundStores.length > 0 ? (
                foundStores.map((store, i) => (
                  <div
                    className="store-search-row"
                    style={{ animation: `slideInUp 250ms ${i * 10}ms` }}
                  >
                    <div className="store-search-info-container">
                      <div className="store-search-store-name">
                        <p className="store-search-store-type">
                          {store.store_type_id === 1 ? 'New' : 'Pre-Owned'}
                        </p>
                        <p className="store-company-full-name">{store.store_full_name}</p>
                        <p className="search-id">Store {store.company_store_id}</p>
                      </div>
                      <div className="store-search-store-company">
                        <p className="store-search-company-name">{store.company_name}</p>
                        <p className="search-id">Company {store.company_id}</p>
                      </div>
                    </div>

                    <div className="store-search-links-container">
                      {this.props.isSuperUser ? (
                        <a
                          onClick={() => {
                            this.props.showModalConnect(CHANGE_STORE_MODAL, { store })
                          }}
                        >
                          Change To Store
                        </a>
                      ) : (
                        ''
                      )}
                      <NavLink to={`/staff-tools/overview/${store.company_store_id}`}>
                        View Store
                      </NavLink>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  classNames="staff-tools-change-store-no-results"
                  style={{ fontSize: '1.25em', marginTop: '180px' }}
                >
                  No Results
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {loading && (
          <div className="staff-tools-change-store-table" style={{ animation: ` 1500ms fadeIn` }}>
            <LoadingSpinner loading wheel />
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  token: state.authentication.user.token,
})
const ChangeStore = withRouter(ChangeStoreComponent)
export default connect(mapStateToProps, {
  showModalConnect: showModal,
  hideModalConnect: hideModal,
})(ChangeStore)
