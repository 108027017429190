// general: 1/6 of the customers component - parent - (1st).

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
// core files
import UserInfoWrapper from '../../core/wrappers/userinfowrapper'
// components
import CustomersTable from './components/customerstable'
// css styles
import '../styles/universalstyles.scss'

type Props = {
  userInfo: Object,
}

export class CustomersComponent extends Component {
  constructor(props: Props) {
    super(props)
    this.state = {
      userInfo: this.props.userInfo,
    }
  }

  state: {
    userInfo: Object,
  }

  componentWillReceiveProps(newProps: Props) {
    // ^get updated props as they come in.
    this.setState({ userInfo: newProps.userInfo })
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.setState({ userInfo: {} })
  }

  props: Props

  render() {
    // states
    const { userInfo } = this.state

    return (
      <div style={{ animation: ` 1500ms fadeIn` }}>
        <CustomersTable DJtoken={userInfo.dj_token} storeId={userInfo.default_store_id} />
      </div>
    )
  }
}

const Customers = UserInfoWrapper(CustomersComponent)

export default Customers
