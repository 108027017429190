import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from '../../layouts/modal'
import WebLinkDisplay from './weblinkdisplay'

class WebLinkDisplayModal extends Component {
  render() {
    const { share_uuids, webLinkNames } = this.props
    let vdpBaseURL = 'http://dev.carpacket.info'
    if (window.location.hostname === 'dpapp.autoipacket.com') {
      vdpBaseURL = 'https://www.ipacket.info'
    }
    return (
      <Modal>
        <WebLinkDisplay
          vdpBaseURL={vdpBaseURL}
          share_uuids={share_uuids}
          webLinkNames={webLinkNames}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  share_uuids: state.modal.props && state.modal.props.share_uuids,
})
export default connect(mapStateToProps, null)(WebLinkDisplayModal)
