import { logoutUser } from '../../core/actions/users/useractions'

function unauthorizedIntercept(response) {
  if (response.status === 401) {
    //Start token refresh workflow here
    logoutUser()
  }
}
function suspendedIntercept(response) {
  let res = response.json()
  //TODO: Work with API Team to update this to improved error response standard - Khris
  if (
    response.status === 401 &&
    res &&
    res.detail ===
      'Service for your default store has been suspended. Please contact Support at 1-855-893-9340 or support@autoipacket.com for more information.'
  ) {
    logoutUser(true)
  }
}
export default async function AIPDjapiIntercepts(response) {
  let intercepts = [unauthorizedIntercept]
  return new Promise(() => {
    intercepts.map(intercept => {
      try {
        intercept(response)
      } catch (e) {
        console.log(e)
      }
    })
  })
}
