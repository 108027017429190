import React, { Component } from 'react'
import { connect } from 'react-redux'

import UserInfoWrapper from '../../../core/wrappers/userinfowrapper'

class PageInfoComponent extends Component {
  renderSearchInfo() {
    const { searchSubmitted, isLoading, correctedSearchQuery } = this.props
    if (!isLoading && searchSubmitted && searchSubmitted.length) {
      return (
        <div className="result-style">
          <h1>
            {`showing results for `}
            <b>{` ${correctedSearchQuery ? correctedSearchQuery : searchSubmitted}`}</b>
          </h1>
          <i>
            <b> {correctedSearchQuery && searchSubmitted}</b>
          </i>
          {correctedSearchQuery && ` produced no results `}
        </div>
      )
    }
    return <React.Fragment />
  }
  render() {
    return <div className="page-info-container">{this.renderSearchInfo()}</div>
  }
}
const mapStateToProps = state => ({
  searchQuery: state.inventory.searchQuery,
  searchSubmitted: state.inventory.searchSubmitted,
  correctedSearchQuery: state.inventory.correctedSearchQuery,
  isLoading: state.inventory.isLoading,
})
const PageInfo = UserInfoWrapper(PageInfoComponent)
export default connect(mapStateToProps, null)(PageInfo)
