// general: dashboard views list modal.

// TODO:
// 1. mobile support
// 2. cross browser check support
// 3. user simulated using test to find bugs

// @flow
import React, { Component } from 'react'
// node packages
import { connect } from 'react-redux'
import format from 'date-fns/format'
// core files
import DashActions from '../../../core/actions/features/dashboardactions'
import { hideModal } from '../../../core/_actions/modalActions'
// helpers
import runtimeEnv from '../../../core/config/envConstants'
import Helper from '../../../core/helpers/platformhelper'
// components
import Modal from '../../layouts/modal'
import LoadingSpinner from '../../helpers/loadingspinner'

// css styles
import '../styles/dashboardViewsModal.scss'

type Props = {
  title: string,
  id: number,
  token: string,
  hideModal: boolean,
}

export class DashboardViewsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      views: [],
    }

    this._onClose = this.onClose.bind(this)
  }

  state: {
    views: Object,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      this.onMountGetSentInfo()
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false
    this.setState({ views: [] })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  onMountGetSentInfo() {
    // props
    const { id, token } = this.props
    if (id !== null && id !== undefined) {
      DashActions.getDashboardCPVH(id, token)
        .then(json => {
          if (json !== null && json !== undefined) {
            this.setState({ views: json })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************
  onClose() {
    this.props.hideModal()
  }
  // ***********************LETS*CALL*OTHER*FUNCTIONS***********************

  props: Props
  _onClose: Function // function

  render() {
    // states
    const { views } = this.state

    // arrays
    const viewsArray = []

    // counters
    let count = 0

    if (views && views.length > 0) {
      for (const viewsEntry of views) {
        viewsArray.push(
          <li key={count++}>
            {`${format(Helper.localizeTime(viewsEntry.viewed), 'M/d/yyyy - h:mm a')}`}
          </li>,
        )
      }
    }
    if (views && views.length) {
      return (
        <Modal>
          <div className="row, dashboardViewsBox">
            <h1>Views ({runtimeEnv.timezoneAbbr})</h1>
            <ul id="view-array">{viewsArray}</ul>
            <p className="views-insights-info">
              More information about packet views can be found on the Insights page
            </p>
          </div>
        </Modal>
      )
    }
    return (
      <Modal>
        <div className="row, dashboardViewsBox">
          <LoadingSpinner loading wheel />
        </div>
      </Modal>
    )
  }
}

// ***********************LETS*EXPORT*SENT*MODAL***********************
function mapStateToProps(state) {
  return {
    title: state.modal.props.title,
    id: state.modal.props.id_object.main_id,
    token: state.modal.props.token,
  }
}

export default connect(mapStateToProps, {
  hideModal,
})(DashboardViewsModal)
