// general: 1/6 of the admin component - child of adminsettings - (2nd).

// @flow
import React, { Component } from 'react'
import Helper from '../../../core/helpers/platformhelper'
// node packages
// --- redux
import { connect } from 'react-redux'
// core files
import AdminActions from '../../../core/actions/users/adminactions'
// css styles
import '../../styles/universalstyles.scss'
import '../styles/adminsettings.scss'
// images
import IconX from '../../../core/assets/images/icons/icon-x'
import IconTrash from '../../../core/assets/images/icons/icon-trash'
import LoadingSpinner from '../../helpers/loadingspinner'
import { ThemeProvider } from 'styled-components'
import moment from 'moment-timezone'

// global variable
let setTimeoutId

type Props = {
  DJtoken: string, // token to send to get the data.
  storeId: number, // sent for the admin overview data.
}

class AdminOverview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      storeId: this.props.storeId,

      information: {},
      settings: {},
      img: '',
      store_phone: '',

      franchise: [],
      franchiseAddActive: false,
      franchiseSearch: '',
      franchiseSearchRes: [],

      overviewLoaded: true,
      franchiseLoaded: true,
    }

    // input container onChange event that is stalled with a 'bind'.
    this._FranchiseUpdateField = this.FranchiseUpdateField.bind(this)
  }

  state: {
    storeId: number,

    information: Object,
    settings: Object,
    img: string,
    store_phone: string,

    franchise: Object,
    franchiseAddActive: boolean,
    franchiseSearch: string,
    franchiseSearchRes: Object,
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      const { storeId } = this.state
      if (storeId !== null && storeId !== undefined) {
        this.onMountGetOverviewInfo(storeId)
        //this.onMountGetStoreLogo(storeId)
        this.onMountGetFranchises(storeId)
      }
    }
  }

  // componentWillReceiveProps
  // - get updated props as they come in.
  componentWillReceiveProps(newProps: Props) {
    const { storeId } = newProps
    if (storeId !== null && storeId !== undefined) {
      if (storeId !== this.props.storeId) {
        this.setState({ storeId })
        this.onMountGetOverviewInfo(storeId)
        //this.onMountGetStoreLogo(storeId)
        this.onMountGetFranchises(storeId)
      }
    }
  }

  componentWillUnmount() {
    // ^resets all values and states and things.
    this.mounted = false

    // clear global timeout variable
    clearTimeout(setTimeoutId)

    // reset the states on unmount
    this.setState({
      storeId: null,

      information: {},
      settings: {},
      img: '',
      store_phone: '',

      franchise: [],
      franchiseAddActive: false,
      franchiseSearch: '',
      franchiseSearchRes: [],

      overviewLoaded: true,
      franchiseLoaded: true,
    })
  }

  // ***********************LETS*CALL*ONMOUNT*SETUP***********************
  // onMountGetOverviewInfo
  onMountGetOverviewInfo(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      AdminActions.getAdminOverview(storeId, DJtoken).then(json => {
        console.log(json)
        if (json.company_store_id !== null && json.company_store_id !== undefined) {
          this.setState({
            information: json.information,
            settings: json.settings,
          })
        } else {
          this.setState({ overviewLoaded: false })
        }
        console.log(this.state)
      })
    }
    if (storeId !== null && storeId !== undefined) {
      AdminActions.getStoreDetail(storeId, DJtoken).then(json => {
        this.setState({
          store_phone: json.store_phone,
          overviewLoaded: false,
        })
      })
    }
  }

  // onMountGetStoreLogo
  onMountGetStoreLogo(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      AdminActions.getAdminOverviewImg(storeId, DJtoken).then(json => {
        if (
          json.store_logo_url !== null &&
          json.store_logo_url !== undefined &&
          json.store_logo_url !== ''
        ) {
          this.setState({ img: json.store_logo_url })
        }
      })
    }
  }

  // onMountGetFranchises
  onMountGetFranchises(storeId: number) {
    const { DJtoken } = this.props
    if (storeId !== null && storeId !== undefined) {
      AdminActions.getAdminFranchiseList(storeId, DJtoken).then(json => {
        if (json && json.length > 0) {
          this.setState({ franchise: json, franchiseLoaded: false })
        } else {
          this.setState({ franchise: [], franchiseLoaded: false })
        }
        console.log(this.state)
      })
    }
  }
  // ***********************LETS*CALL*ONMOUNT*SETUP***********************

  // ***********************LETS*CALL*API***********************
  // FranchiseGetAPIData
  // sends manipulated states to the API as the correct request params to get the
  // returned searched franchise data.
  FranchiseGetAPIData(value: string, type: string) {
    // props
    const { storeId, DJtoken } = this.props

    //state
    const { franchiseAddActive } = this.state

    if (type === 'search') {
      // search: string, token: string
      AdminActions.getAdminFranchiseSearch(value, DJtoken)
        .then(json => {
          if (json && json.length > 0) {
            this.setState({ franchiseSearchRes: json })
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    } else if (type === 'add') {
      // storeId: number, makeID: number, token: string
      AdminActions.postAdminFranchise(storeId, value, DJtoken)
        .then(json => {
          if (json.make_id !== null && json.make_id !== undefined) {
            // ^if the api is successful.
            this.setState({ franchiseSearch: '' })

            AdminActions.getAdminFranchiseList(storeId, DJtoken)
              .then(json => {
                if (json && json.length > 0) {
                  this.setState({ franchise: json, franchiseLoaded: false })
                } else {
                  this.setState({ franchise: [], franchiseLoaded: false })
                }
                console.log(this.state)
              })
              .then(
                this.setState({
                  franchiseAddActive: !franchiseAddActive,
                  franchiseSearchRes: [],
                }),
              )
          }
        })
        .catch(error => {
          console.log(error.toString())
        })
    }
  }
  // ***********************LETS*CALL*API***********************

  // ***********************LETS*DO*FUNCTIONS***********************
  // FranchiseUpdateField
  // - updates the 'search' state on keystrokes.
  // - there is a timeout here for fast typers.
  FranchiseUpdateField(stateName: string, text: string) {
    // clear global timeout variable
    clearTimeout(setTimeoutId)

    const textSearch = text.target.value

    const newState = {}
    newState[stateName] = textSearch
    this.setState(newState)

    if (text !== '' && text !== undefined) {
      setTimeoutId = setTimeout(() => {
        this.FranchiseGetAPIData(textSearch, 'search')
      }, 400)
    } else if (text === '') {
      this.FranchiseGetAPIData('', 'search')
    }
  }
  // ***********************LETS*DO*FUNCTIONS***********************

  // ***********************LETS*SETUP*CONTAINERS***********************
  // AdminInformationContainer
  AdminInformationContainer() {
    // states
    const { information, store_phone } = this.state
    const city = information.store_city
    const email = information.store_email_address
    const state = information.store_state
    const street1 = information.store_street_one
    const street2 = information.store_street_two
    const zip = information.store_zip
    const phone = store_phone
    return (
      <div>
        <table style={{ width: '230px', margin: '0px 1.5%' }}>
          <tbody>
            <tr>
              <td align="left" style={{ width: '30%' }}>
                <b>Store Phone Number</b>
              </td>
            </tr>
            <tr className="basics-row-pop">
              <td align="right" style={{ width: '63%' }}>
                {Helper.prettyPhoneNumber(phone ? phone : '')}
              </td>
            </tr>
            <tr>
              <td align="left" style={{ width: '30%' }}>
                <b>Store Lead Email</b>
              </td>
            </tr>
            <tr className="basics-row-pop">
              <td align="right" style={{ width: '63%' }}>
                {email}
              </td>
            </tr>
            <tr>
              <td align="left">
                <b>Store Address</b>
              </td>
            </tr>
            <tr className="basics-row-pop">
              <td align="right">
                {street1}
                <br />
                {street2}
                <br />
                {city}, {state} {zip}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  // AdminSettingsContainer
  AdminSettingsContainer() {
    // states
    const { settings } = this.state
    const { storeId, isStaff, companyId } = this.props
    const timezone = Math.abs(parseInt(settings.timezone))
    const observeDst = settings.observe_dst
    const showVehiclePrice = settings.show_vehicle_price
    console.log('SETTINGS HERE')
    console.log(settings)
    // variables
    let timezoneType = 'EST'

    if (timezone === 1) {
      timezoneType = 'CST'
    } else if (timezone === 2) {
      timezoneType = 'MST'
    } else if (timezone === 3) {
      timezoneType = 'PST'
    } else if (timezone === 4) {
      timezoneType = 'AKST'
    } else if (timezone === 6) {
      timezoneType = 'HST'
    }
    return (
      <div>
        <table style={{ width: '230px', margin: '0px 1.5%' }}>
          <tbody>
            <tr className="basics-row-pop">
              <td>
                <b>Observe DST</b>
              </td>
              <td align="right">{observeDst !== undefined ? observeDst.toString() : 'false'}</td>
            </tr>
            <tr className="basics-row-pop">
              <td>
                <b>Show Vehicle Price</b>
              </td>
              <td align="right">
                {showVehiclePrice !== undefined ? showVehiclePrice.toString() : 'false'}
              </td>
            </tr>
            <tr className="basics-row-pop">
              <td>
                <b>Timezone</b>
              </td>
              <td align="right">{timezoneType}</td>
            </tr>
            {isStaff ? (
              <React.Fragment>
                <tr className="basics-row-pop">
                  <td>
                    <b>Store ID</b>
                  </td>
                  <td align="right">{storeId}</td>
                </tr>
                <tr className="basics-row-pop">
                  <td>
                    <b>Company ID</b>
                  </td>
                  <td align="right">{companyId}</td>
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </tbody>
        </table>
      </div>
    )
  }

  deleteFranchise(entry) {
    const { franchise } = this.state

    const { storeId, DJtoken } = this.props

    var newFranchiseList = franchise
    newFranchiseList.splice(
      newFranchiseList.findIndex(v => v.make_id === entry.make_id),
      1,
    )

    console.log(newFranchiseList)

    AdminActions.deleteAdminFranchise(storeId, entry.make_id, DJtoken)

    if (franchise && franchise.length > 0) {
      this.setState({
        franchise: newFranchiseList,
        franchiseLoaded: false,
      })
    } else {
      this.setState({ franchise: [], franchiseLoaded: false })
    }
  }

  // AdminFranchiseContainer
  AdminFranchiseContainer() {
    // props/states
    const { franchise, franchiseAddActive } = this.state

    return (
      <div align="center">
        <h2>Associated Franchises</h2>
        <table>
          <tbody>
            {franchise &&
              franchise.length > 0 &&
              franchise.map((entry, i) => (
                <tr className="list-item chip" key={i}>
                  <td className="list-item-content">
                    <span className="list-item-value">{entry.make}</span>
                  </td>

                  <td className="list-item-actions">
                    <button
                      className="franchise-remove"
                      onClick={() => {
                        this.deleteFranchise(entry)
                      }}
                    >
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <button
          id="add_button"
          onClick={() => {
            this.setState({ franchiseAddActive: !franchiseAddActive })
          }}
          className={franchiseAddActive ? '' : ''}
        >
          {franchiseAddActive ? 'Cancel' : 'Add Franchise'}
        </button>

        {franchiseAddActive ? this.AdminAddFranchise() : null}
      </div>
    )
  }

  // AdminAddFranchise
  AdminAddFranchise() {
    // styles

    // state
    const { franchiseSearch, franchiseSearchRes } = this.state

    // containers
    let list = null

    // arrays
    const array = []

    // counters
    let counter = 0

    if (franchiseSearchRes && franchiseSearchRes.length > 0) {
      for (const entry of franchiseSearchRes) {
        array.push(
          <button
            className="franchise_selections"
            key={counter++}
            onClick={() => {
              this.FranchiseGetAPIData(entry.make_id, 'add')
            }}
          >
            {entry.make}
          </button>,
        )
      }

      list = <div id="franchise_options">{array}</div>
    }

    return (
      <div id="add_franchise_mssg">
        Type the franchise you wish to add below, then select the appropriate option from the
        results that appear.
        <br />
        <input
          ref={text => {
            this._inputSearch = text
          }}
          type="text"
          name="franchiseSearch"
          placeholder="ex: Ford"
          className="form-control-override"
          onChange={text => {
            this._FranchiseUpdateField('franchiseSearch', text)
          }}
          value={franchiseSearch}
        />
        {list}
      </div>
    )
  }

  // AdminImgContainer
  AdminImgContainer() {
    return (
      <div id="store_logo" className={this.state.img ? '' : 'd-none'}>
        <img alt="store-logo" src={this.state.img} />
      </div>
    )
  }
  // ***********************LETS*SETUP*CONTAINERS***********************

  props: Props
  _FranchiseUpdateField: Function // function
  _inputSearch: Function // ref

  render() {
    if (this.state.storeId !== null && this.state.storeId !== undefined) {
      if (this.state.franchiseLoaded && this.state.overviewLoaded) {
        return (
          <div align="center" className="white_box">
            <div id="basics-bloc">
              <LoadingSpinner loading wheel />
            </div>
          </div>
        )
      } else {
        return (
          <div align="center" className="white_box">
            <div id="basics-bloc">
              <h2>Store Information</h2>
              <hr />
              <div className="infoBlurb">
                To modify the information found here, contact our support.
              </div>
              <div id="settingsBloc">
                <div id="admin-info-bloc">{this.AdminInformationContainer()}</div>
                <div id="admin-settings-bloc">{this.AdminSettingsContainer()}</div>
              </div>
              <div id="franchiseBloc">
                <div id="admin-franchise-bloc">{this.AdminFranchiseContainer()}</div>
              </div>
            </div>
          </div>
        )
      }
    }

    return null
  }
}

const mapStateToProps = state => ({
  companyId:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.profile.legacy_user.company_id,
  isStaff:
    state.authentication.user &&
    state.authentication.user.user &&
    state.authentication.user.user.is_staff,
})
export default connect(mapStateToProps, {})(AdminOverview)
