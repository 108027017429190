import { STASH_URL_HISTORY } from '../_constants/url_infoTypes'

const initState = {
  user_history: [],
}

export function stashUrlHistory(state, action) {
  if (!state) {
    state = initState
  }

  switch (action.type) {
    case STASH_URL_HISTORY:
      let urls = [...state.user_history]
      if (urls.length == 0) {
        urls.push({
          href: action.user_history.href,
          pathname: action.user_history.pathname,
          origin: action.user_history.origin,
        })
      } else if (urls.length > 0 && urls[urls.length - 1].href !== action.user_history.href) {
        urls.push({
          href: action.user_history.href,
          pathname: action.user_history.pathname,
          origin: action.user_history.origin,
        })
      } else if (urls.length > 0 && urls[urls.length - 1].href == action.user_history.href) {
        urls = [...urls]
      }
      return {
        ...state,
        user_history: [...urls],
      }

    default:
      return state
  }
}
